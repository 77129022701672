import {
    ON_CHAT_MESSAGE_EVENT,
    ON_CHAT_SEEN_EVENT,
    ON_CHAT_TYPING_EVENT,
} from './actionTypes';

export const onChatMessageEvent = (data) => {
    return {
        type: ON_CHAT_MESSAGE_EVENT,
        payload: { data },
    }
};

export const onChatSeenEvent = (data) => {
    return {
        type: ON_CHAT_SEEN_EVENT,
        payload: { data },
    }
};

export const onChatTypingEvent = (data) => {
    return {
        type: ON_CHAT_TYPING_EVENT,
        payload: { data },
    }
};