export const FETCH_USERS_FROM_RECEIVED_INVITES = 'FETCH_USERS_FROM_RECEIVED_INVITES';
export const FETCH_USERS_FROM_RECEIVED_INVITES_SUCCESS = 'FETCH_USERS_FROM_RECEIVED_INVITES_SUCCESS';
export const FETCH_USERS_FROM_RECEIVED_INVITES_ERROR = 'FETCH_USERS_FROM_RECEIVED_INVITES_ERROR';
export const CLEAN_USERS_FROM_RECEIVED_INVITES = 'CLEAN_USERS_FROM_RECEIVED_INVITES';
export const USERS_FROM_RECEIVED_INVITES_SET_SORT = 'USERS_FROM_RECEIVED_INVITES_SET_SORT';
export const USERS_FROM_RECEIVED_INVITES_SET_FILTER = 'USERS_FROM_RECEIVED_INVITES_SET_FILTER';
export const USERS_FROM_RECEIVED_INVITES_RESET_FILTER = 'USERS_FROM_RECEIVED_INVITES_RESET_FILTER';
export const USERS_FROM_RECEIVED_INVITES_SET_PAGE = 'USERS_FROM_RECEIVED_INVITES_SET_PAGE';

export const FETCH_USERS_FROM_SENT_INVITES = 'FETCH_USERS_FROM_SENT_INVITES';
export const FETCH_USERS_FROM_SENT_INVITES_SUCCESS = 'FETCH_USERS_FROM_SENT_INVITES_SUCCESS';
export const FETCH_USERS_FROM_SENT_INVITES_ERROR = 'FETCH_USERS_FROM_SENT_INVITES_ERROR';
export const CLEAN_USERS_FROM_SENT_INVITES = 'CLEAN_USERS_FROM_SENT_INVITES';
export const USERS_FROM_SENT_INVITES_SET_SORT = 'USERS_FROM_SENT_INVITES_SET_SORT';
export const USERS_FROM_SENT_INVITES_SET_FILTER = 'USERS_FROM_SENT_INVITES_SET_FILTER';
export const USERS_FROM_SENT_INVITES_RESET_FILTER = 'USERS_FROM_SENT_INVITES_RESET_FILTER';
export const USERS_FROM_SENT_INVITES_SET_PAGE = 'USERS_FROM_SENT_INVITES_SET_PAGE';

export const FETCH_USERS_FROM_COMPANY_SENT_INVITES = 'FETCH_USERS_FROM_COMPANY_SENT_INVITES';
export const FETCH_USERS_FROM_COMPANY_SENT_INVITES_SUCCESS = 'FETCH_USERS_FROM_COMPANY_SENT_INVITES_SUCCESS';
export const FETCH_USERS_FROM_COMPANY_SENT_INVITES_ERROR = 'FETCH_USERS_FROM_COMPANY_SENT_INVITES_ERROR';
export const CLEAN_USERS_FROM_COMPANY_SENT_INVITES = 'CLEAN_USERS_FROM_COMPANY_SENT_INVITES';
export const USERS_FROM_COMPANY_SENT_INVITES_SET_SORT = 'USERS_FROM_COMPANY_SENT_INVITES_SET_SORT';
export const USERS_FROM_COMPANY_SENT_INVITES_SET_FILTER = 'USERS_FROM_COMPANY_SENT_INVITES_SET_FILTER';
export const USERS_FROM_COMPANY_SENT_INVITES_RESET_FILTER = 'USERS_FROM_COMPANY_SENT_INVITES_RESET_FILTER';
export const USERS_FROM_COMPANY_SENT_INVITES_SET_PAGE = 'USERS_FROM_COMPANY_SENT_INVITES_SET_PAGE';