import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { Pagination } from "../../../../../components/Custom";
import { InvoicesGrid } from '../InvoicesGrid';
import {
    fetchBillingInvoices,
    cleanBillingInvoices,
    billingInvoicesSetPage,
} from '../../../../../store/actions';

import * as HOC from '../../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const InvoicesComponent = (props) => {
    useEffect(() => {
        let params = {
            page: props.page,
            per_page: 9,
        };

        props.fetchBillingInvoices(params);
    }, [props.page]);

    useEffect(() => {
        return () => {
            props.cleanBillingInvoices();
        }
    }, []);

    const handlePageChange = (item) => {
        props.billingInvoicesSetPage(item.selected + 1);
    };

    return (
        <TableDataLoading isLoading={props.listLoading} isFailed={props.listError} error={props.listError}>
            <>
                <InvoicesGrid
                    list={props.list}
                    isLoading={props.listLoading}
                    isFetched={props.listIsFetched}
                />
                <Pagination data={props.meta} onPageChange={handlePageChange} />
            </>
        </TableDataLoading>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError, page, meta, listIsFetched } = state.billing.invoice.list;
    return {
        list,
        listLoading,
        listError,
        page,
        meta,
        listIsFetched,
    };
};

const mapDispatchToProps = {
    fetchBillingInvoices,
    cleanBillingInvoices,
    billingInvoicesSetPage,
};

export const Invoices = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvoicesComponent)));