import {
    FETCH_USERS_FROM_RECEIVED_INVITES,
    FETCH_USERS_FROM_RECEIVED_INVITES_SUCCESS,
    FETCH_USERS_FROM_RECEIVED_INVITES_ERROR,
    CLEAN_USERS_FROM_RECEIVED_INVITES,
    USERS_FROM_RECEIVED_INVITES_SET_SORT,
    USERS_FROM_RECEIVED_INVITES_SET_FILTER,
    USERS_FROM_RECEIVED_INVITES_RESET_FILTER,
    USERS_FROM_RECEIVED_INVITES_SET_PAGE,

    FETCH_USERS_FROM_SENT_INVITES,
    FETCH_USERS_FROM_SENT_INVITES_SUCCESS,
    FETCH_USERS_FROM_SENT_INVITES_ERROR,
    CLEAN_USERS_FROM_SENT_INVITES,
    USERS_FROM_SENT_INVITES_SET_SORT,
    USERS_FROM_SENT_INVITES_SET_FILTER,
    USERS_FROM_SENT_INVITES_RESET_FILTER,
    USERS_FROM_SENT_INVITES_SET_PAGE,

    FETCH_USERS_FROM_COMPANY_SENT_INVITES,
    FETCH_USERS_FROM_COMPANY_SENT_INVITES_SUCCESS,
    FETCH_USERS_FROM_COMPANY_SENT_INVITES_ERROR,
    CLEAN_USERS_FROM_COMPANY_SENT_INVITES,
    USERS_FROM_COMPANY_SENT_INVITES_SET_SORT,
    USERS_FROM_COMPANY_SENT_INVITES_SET_FILTER,
    USERS_FROM_COMPANY_SENT_INVITES_RESET_FILTER,
    USERS_FROM_COMPANY_SENT_INVITES_SET_PAGE,
} from './actionTypes';

const initialState = {
    receivedUsers: [],
    receivedUsersLoading: null,
    receivedUsersError: null,
    receivedUsersPage: 1,
    receivedUsersMeta: null,

    receivedUsersSort: [],
    receivedUsersFilter: {
        isActive: false,
        values: {
            companies: null,
        },
    },

    sentUsers: [],
    sentUsersLoading: false,
    sentUsersError: null,
    sentUsersPage: 1,
    sentUsersMeta: null,

    sentUsersSort: [],
    sentUsersFilter: {
        isActive: false,
        values: {
            period: null,
        },
    },

    companyUsers: [],
    companyUsersLoading: null,
    companyUsersError: null,
    companyUsersPage: 1,
    companyUsersMeta: null,

    companyUsersSort: [],
    companyUsersFilter: {
        isActive: false,
        values: {
            period: null,
        },
    },
};

const invite = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS_FROM_RECEIVED_INVITES:
            state = {
                ...state,
                receivedUsersLoading: true,
                receivedUsersError: null,
            };
            break;

        case FETCH_USERS_FROM_RECEIVED_INVITES_SUCCESS:
            state = {
                ...state,
                receivedUsersLoading: false,
                receivedUsers: action.payload.data,
                receivedUsersMeta: action.payload.meta,
            };
            break;

        case FETCH_USERS_FROM_RECEIVED_INVITES_ERROR:
            state = {
                ...state,
                receivedUsersLoading: false,
                receivedUsersError: action.payload,
            };
            break;

        case USERS_FROM_RECEIVED_INVITES_SET_SORT:
            state = {
                ...state,
                receivedUsersSort: action.payload,
                receivedUsersPage: initialState.receivedUsersPage,
            };
            break;

        // FILTER: USERS FROM RECEIVED INVITES
        case USERS_FROM_RECEIVED_INVITES_SET_FILTER:
            state = {
                ...state,
                receivedUsersFilter: {
                    isActive: true,
                    values: {
                        ...initialState.receivedUsersFilter.values,
                        ...action.payload,
                    },
                },
                receivedUsersPage: initialState.receivedUsersPage,
            };
            break;

        case USERS_FROM_RECEIVED_INVITES_RESET_FILTER:
            state = {
                ...state,
                receivedUsersFilter: initialState.receivedUsersFilter,
                receivedUsersPage: initialState.receivedUsersPage,
            };
            break;

        // SET PAGE
        case USERS_FROM_RECEIVED_INVITES_SET_PAGE:
            state = {
                ...state,
                receivedUsersPage: action.payload,
            };
            break;

        case CLEAN_USERS_FROM_RECEIVED_INVITES:
            state = {
                ...state,
                receivedUsers: [],
                receivedUsersLoading: null,
                receivedUsersError: null,
                receivedUsersSort: [],
                receivedUsersPage: initialState.receivedUsersPage,
                receivedUsersMeta: null,
            };
            break;


        // USERS FROM SENT INVITES
        case FETCH_USERS_FROM_SENT_INVITES:
            state = {
                ...state,
                sentUsersLoading: true,
                sentUsersError: null,
            };
            break;

        case FETCH_USERS_FROM_SENT_INVITES_SUCCESS:
            state = {
                ...state,
                sentUsersLoading: false,
                sentUsers: action.payload.data,
                sentUsersMeta: action.payload.meta,
            };
            break;

        case FETCH_USERS_FROM_SENT_INVITES_ERROR:
            state = {
                ...state,
                sentUsersLoading: false,
                sentUsersError: action.payload,
            };
            break;

        case USERS_FROM_SENT_INVITES_SET_SORT:
            state = {
                ...state,
                sentUsersSort: action.payload,
                sentUsersPage: initialState.sentUsersPage,
            };
            break;

        // FILTER
        case USERS_FROM_SENT_INVITES_SET_FILTER:
            state = {
                ...state,
                sentUsersFilter: {
                    isActive: true,
                    values: {
                        ...initialState.sentUsersFilter.values,
                        ...action.payload,
                    },
                },
                sentUsersPage: initialState.sentUsersPage,
            };
            break;

        case USERS_FROM_SENT_INVITES_RESET_FILTER:
            state = {
                ...state,
                sentUsersFilter: initialState.sentUsersFilter,
                sentUsersPage: initialState.sentUsersPage,
            };
            break;

        // SET PAGE
        case USERS_FROM_SENT_INVITES_SET_PAGE:
            state = {
                ...state,
                sentUsersPage: action.payload,
            };
            break;

        case CLEAN_USERS_FROM_SENT_INVITES:
            state = {
                ...state,
                sentUsers: [],
                sentUsersLoading: false,
                sentUsersError: null,
                sentUsersSort: [],
                sentUsersPage: initialState.sentUsersPage,
                sentUsersMeta: null,
            };
            break;


        case FETCH_USERS_FROM_COMPANY_SENT_INVITES:
            state = {
                ...state,
                companyUsersLoading: true,
                companyUsersError: null,
            };
            break;

        case FETCH_USERS_FROM_COMPANY_SENT_INVITES_SUCCESS:
            state = {
                ...state,
                companyUsersLoading: false,
                companyUsers: action.payload.data,
                companyUsersMeta: action.payload.meta,
            };
            break;

        case FETCH_USERS_FROM_COMPANY_SENT_INVITES_ERROR:
            state = {
                ...state,
                companyUsersLoading: false,
                companyUsersError: action.payload,
            };
            break;

        case USERS_FROM_COMPANY_SENT_INVITES_SET_SORT:
            state = {
                ...state,
                companyUsersSort: action.payload,
                companyUsersPage: initialState.companyUsersPage,
            };
            break;

        // FILTER
        case USERS_FROM_COMPANY_SENT_INVITES_SET_FILTER:
            state = {
                ...state,
                companyUsersFilter: {
                    isActive: true,
                    values: {
                        ...initialState.companyUsersFilter.values,
                        ...action.payload,
                    },
                },
                companyUsersPage: initialState.companyUsersPage,
            };
            break;

        case USERS_FROM_COMPANY_SENT_INVITES_RESET_FILTER:
            state = {
                ...state,
                companyUsersFilter: initialState.companyUsersFilter,
                companyUsersPage: initialState.companyUsersPage,
            };
            break;

        // SET PAGE
        case USERS_FROM_COMPANY_SENT_INVITES_SET_PAGE:
            state = {
                ...state,
                companyUsersPage: action.payload,
            };
            break;

        case CLEAN_USERS_FROM_COMPANY_SENT_INVITES:
            state = {
                ...state,
                companyUsers: [],
                companyUsersLoading: false,
                companyUsersError: null,
                companyUsersSort: [],
                companyUsersPage: initialState.companyUsersPage,
                companyUsersMeta: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default invite;