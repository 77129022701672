import React from 'react';

import * as format from '../../../utils/format';

export const PhoneFormat = ({ value }) => {
    return (
        <>
            {value !== null ? format.phone(value) : '-'}
        </>
    );
};