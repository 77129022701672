import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { LEAD_FETCH_ALL_PROPERTIES } from './actionTypes';
import {
    leadFetchAllPropertiesSuccess,
    leadFetchAllPropertiesError,
} from './actions';

import { API } from '../../../../api';
import { getMapRequestParams } from '../../../../utils/request';

function* fetchAllProperties({ payload: { params } }) {
    try {
        const includes = ['project', 'city', 'city.region', 'receivedLinkWithUser', 'receivedLinkWithLead'];

        let requestParams = {
            includes,
            receiver_id: params.receiver_id,
            lead_id: params.lead_id,
        };

        requestParams = getMapRequestParams(params, requestParams);

        const response = yield call(API.property.getPropertyList, requestParams);

        const { data } = response;
        yield put(leadFetchAllPropertiesSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(leadFetchAllPropertiesError(message));
    }
}

export function* watchAllPropertiesFetch() {
    yield takeLatest(LEAD_FETCH_ALL_PROPERTIES, fetchAllProperties)
}

function* listSaga() {
    yield all([
        fork(watchAllPropertiesFetch),
    ]);
}

export default listSaga;