import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { destroy, getFormValues } from 'redux-form'

import { InvestmentWizardStep1 } from './InvestmentWizardStep1';
import { InvestmentWizardStep2 } from './InvestmentWizardStep2';
import { InvestmentWizardStepConfirm } from './InvestmentWizardStepConfirm';

import { WizardProgress } from '../WizardProgress';
import { FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD } from '../../../../../consts/forms';
import { propertyRequestHelper } from '../../../../../helpers/propertyRequest';

const InvestmentWizardComponent = (props) => {
    const [step, setStep] = useState(1);

    useEffect(() => {
        return () => {
            // destroy wizard form
            props.destroy(FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD);
        };
    }, []);

    const nextStep = () => {
        setStep(step + 1);
    };

    const previousPage = () => {
        setStep(step - 1);
    };

    const percent = useMemo(() => {
        return propertyRequestHelper.common.getInvestWizardPercent(props.formValues);
    }, [props.formValues]);

    return (
        <>
            <h5 className={'mr-2'}>{props.t('investment')}</h5>

            <div className={'pt-2 pb-2'}>
                <WizardProgress percent={percent} />
            </div>

            <div className={'wizard-wrap'}>
                {step === 1 && (
                    <InvestmentWizardStep1 back={() => props.onStart()} onSubmit={nextStep} />
                )}

                {step === 2 && (
                    <InvestmentWizardStep2 back={() => previousPage()} onSubmit={nextStep} />
                )}

                {step === 3 && (
                    <InvestmentWizardStepConfirm back={() => previousPage()} onSubmit={nextStep} percent={percent} />
                )}
            </div>
        </>
    );
};

const mapStateToProps = state => {
    const formValues = getFormValues(FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD)(state);

    return {
        formValues,
    };
};

const mapDispatchToProps = {
    destroy,
};

export const InvestmentWizard = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvestmentWizardComponent)));