import React from 'react';
import { Field } from "redux-form";
import { CustomInput, FormGroup, Label, Col } from "reactstrap";

const CheckboxGroupField = (props) => {
    const { checkboxClassName = '' } = props;

    const field = ({input, meta, options}) => {

        const {name, onChange} = input;
        const inputValue = input.value;

        const checkboxes = options.map(({label, value}, index) => {

            const handleChange = (event) => {
                const arr = [...inputValue];
                if (event.target.checked) {
                    arr.push(value);
                }
                else {
                    arr.splice(arr.indexOf(value), 1);
                }
                return onChange(arr);
            };


            const checked = inputValue.includes(value);

            return (
                <div className="form-check" key={`checkbox-${value}-${index}`}>
                    <CustomInput
                        type="checkbox"
                        className={'form-check-input ' + checkboxClassName}
                        id={`checkbox-${value}-${index}`}
                        label={label}
                        htmlFor={`checkbox-${value}-${index}`}
                        name={`${name}[${index}]`} value={value} checked={checked} onChange={handleChange}
                    />
                </div>
            )
        });

        return (
            <FormGroup tag="fieldset" className={'mb-0'}>
                <Label className={'col-form-label col-lg-12 pl-0'}>{props.label}</Label>

                <Col lg="12" className={'pl-0'}>
                    <div className={'form-control flex-column h-100 border-0 p-0'}>
                        {checkboxes}
                    </div>
                </Col>
            </FormGroup>
        );
    };

    return (
        <Field {...props} type="checkbox" component={field} />
    );
};

export default CheckboxGroupField;