import { all } from 'redux-saga/effects';

import cancelSaga from './cancel/saga';
import resumeSaga from './resume/saga';
import swapSaga from './swap/saga';

function* reactionsSaga() {
    yield all([
        cancelSaga(),
        resumeSaga(),
        swapSaga(),
    ])
}

export default reactionsSaga;