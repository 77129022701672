import React, { useEffect } from 'react';
import { Alert } from "reactstrap";
import { Field, reduxForm } from 'redux-form'
import { connect } from "react-redux";
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FORM_ACCOUNT_ACTIVATE } from '../../consts/forms';
import ButtonLoadingIcon from '../../components/Common/ButtonLoadingIcon';
import TextInput from '../../components/Common/TextInput';
import { LanguageSelectField } from '../../components/Custom';
import { activateValidation } from './validations/validate';
import { useTitle, useQuery } from "../../hooks";
import { activateAccount, activateAccountClean } from "../../store/actions";
import { Header } from './components';

const DEFAULT_LANGUAGE_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE;

const ActivationComponent = (props) => {
    const { t } = useTranslation();

    useTitle(t('title.activation'));
    let query = useQuery();

    let { id, hash } = useParams();

    const history = useHistory();

    const { handleSubmit } = props;

    const onSubmit = (values) => {
        let formData = {
            ...values,
        };

        let params = {
            signature: query.get('signature'),
        };

        props.activateAccount(id, hash, params, formData, history);
    };

    useEffect(() => {
        return () => {
            props.activateAccountClean();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="d-flex flex-column h-100">
            <Header />

            <div className="my-auto">
                <div>
                    <h5 className="text-primary">{t('activate_account')}</h5>
                    <p className="text-muted">{t('get.account')}</p>
                </div>

                <div className="mt-4">
                    {props.requestError ? (
                        <Alert color="danger">
                            {props.requestError}
                        </Alert>
                    ) : null}

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="form-horizontal"
                        noValidate={true}
                    >
                        <Field
                            name="name"
                            label={t('field.label.fullname')}
                            placeholder={t('field.placeholder.fullname')}
                            id={'name'}
                            type="text"
                            component={TextInput}
                        />

                        <Field
                            name="password"
                            label={t('field.label.password')}
                            placeholder={t('field.placeholder.password')}
                            id={'password'}
                            type="password"
                            component={TextInput}
                            translateParams={{ count: 8 }}
                        />

                        <Field
                            name="password_confirmation"
                            label={t('field.label.confirm.password')}
                            placeholder={t('field.label.confirm.password')}
                            id={'password_confirmation'}
                            type="password"
                            component={TextInput}
                            translateParams={{ count: 8 }}
                        />

                        <Field
                            name="lang"
                            label={t('field.label.language')}
                            id={'lang'}
                            component={LanguageSelectField}
                        />

                        <div>
                            <p className="mb-0">
                                {t('activation.agree')}{" "}
                                <Link to={{ pathname: '/terms', state: { from: props.location } }} className="text-primary">
                                    {t('terms.of.use')}
                                </Link>
                            </p>
                        </div>

                        <div className="mt-4">
                            <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                                disabled={props.loading}
                            >
                                <ButtonLoadingIcon loading={props.loading} />
                                {t('activate')}
                            </button>
                        </div>
                    </form>

                    <div className="mt-5 text-center">
                        <p>
                            {t('already_have_an_account')}{" "}
                            <Link
                                to="/login"
                                className="font-weight-medium text-primary"
                            >
                                {" "}
                                {t('login')}{" "}
                            </Link>{" "}
                        </p>
                    </div>
                </div>
            </div>

            <div className="mt-4 mt-md-5 text-center">
                <p className="mb-0">
                    © {new Date().getFullYear()} Brikk.
                </p>
            </div>
        </div>
    );
};


const Activation = reduxForm({
    form: FORM_ACCOUNT_ACTIVATE,
    validate: activateValidation,
    enableReinitialize: true,
})(ActivationComponent);

const mapStateToProps = state => {
    const { success, error, loading } = state.AccountActivation;
    return {
        success,
        requestError: error,
        loading,
        initialValues: {
            lang: DEFAULT_LANGUAGE_CODE || 'nl',
        },
    };
};

export default connect(mapStateToProps, { activateAccount, activateAccountClean })(Activation);
