import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Row, Col, } from "reactstrap";

const AttachmentComponent = (props) => {
    const { item } = props;

    return (
        <div className="pb-2">
            <Row className="align-items-center no-gutters">
                <Col className="col-auto">
                    <div className="avatar-select-wrap">
                        <div className="avatar-sm">
                            <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                                <i className="bx bxs-file" />
                            </span>
                        </div>
                    </div>
                </Col>
                <Col className={'ml-2 text-left'}>
                    <span className="text-muted font-weight-bold">
                        {item.name}
                    </span>
                    <div className={'mt-1'}>
                        <a target="_blank" href={item.src} download={item.name} >
                            {props.t('download')}
                        </a>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const Attachment = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AttachmentComponent)));