import {
    FETCH_PROPERTY_REQUEST_LIST,
    FETCH_PROPERTY_REQUEST_LIST_SUCCESS,
    FETCH_PROPERTY_REQUEST_LIST_ERROR,
    CLEAN_PROPERTY_REQUEST_LIST,
    PROPERTY_REQUEST_SET_PAGE,
} from './actionTypes';

export const fetchPropertyRequestList = (params) => {
    return {
        type: FETCH_PROPERTY_REQUEST_LIST,
        payload: { params }
    }
};

export const fetchPropertyRequestListSuccess = (data) => {
    return {
        type: FETCH_PROPERTY_REQUEST_LIST_SUCCESS,
        payload: data,
    }
};

export const fetchPropertyRequestListError = (data) => {
    return {
        type: FETCH_PROPERTY_REQUEST_LIST_ERROR,
        payload: data,
    }
};

export const cleanPropertyRequestListPage = () => {
    return { type: CLEAN_PROPERTY_REQUEST_LIST };
};

// SET PAGE
export const propertyRequestSetPage = (data) => {
    return {
        type: PROPERTY_REQUEST_SET_PAGE,
        payload: data,
    }
};