import {
    FETCH_PROPERTY_REQUEST,
    FETCH_PROPERTY_REQUEST_SUCCESS,
    FETCH_PROPERTY_REQUEST_ERROR,
    CLEAN_PROPERTY_REQUEST,
} from './actionTypes';

const initialState = {
    propertyRequest: null,
    propertyRequestLoading: false,
    propertyRequestError: null,
};

const view = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROPERTY_REQUEST:
            state = {
                ...state,
                propertyRequestLoading: true,
                propertyRequestError: null,
            };
            break;

        case FETCH_PROPERTY_REQUEST_SUCCESS:
            state = {
                ...state,
                propertyRequestLoading: false,
                propertyRequest: action.payload,
            };
            break;

        case FETCH_PROPERTY_REQUEST_ERROR:
            state = {
                ...state,
                propertyRequestLoading: false,
                propertyRequestError: action.payload,
            };
            break;

        case CLEAN_PROPERTY_REQUEST:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default view;