export const getApplyFilterCount = (data) => {
    let count = 0;

    Object.entries(data).forEach(entry => {
        const [key, value] = entry;
        if (value !== null && value !== '') {
            count += 1;
        }
    });

    return count;
};