import React, { useEffect } from 'react';

import { Switch, BrowserRouter as Router, Route, useLocation, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import 'toastr/build/toastr.min.css';
import 'leaflet/dist/leaflet.css';

// Import Routes all
import { publicRoutes, utilityRoutes, accountOnboardingRoutes, } from "./routes/allRoutes";

// layouts Format
import { BlankLayout } from "./components/Custom";
import { CommonRoute }  from "./routes/middleware/CommonRoute";
import PrivateRoute from "./routes/middleware/PrivateRoute";

import { AuthIndex, Main } from './pages';

import { setLanguage, setOnboardingStatus, setAppIsVisible } from "./store/actions";
import { API } from './api';

import { useQuery, useAppVisible } from './hooks';

// Import scss
import "./assets/scss/theme.scss";

export const AppRouteSwitch = (props) => {
    let location = useLocation();
    let query = useQuery();

    let path = '';

    if (props.isLoggedIn) {
        if (query.get('verified') === '1' && props.isOnboarding) {
            path = '/dashboard';
            props.setOnboardingStatus(false);
        } else {
            path = props.isOnboarding ? '/account/complete' : '/dashboard';
        }
    } else {
        path = '/login';
    }

    return (
        <Redirect to={{ ...location, pathname: path}} />
    )
};

const App = (props) => {
    const { i18n } = useTranslation();
    const appVisible = useAppVisible();

    useEffect(() => {
        if (!props.isLoggedIn) {
            props.setLanguage(i18n.language);
            API.setLang(i18n.language);
        }
    }, []);

    useEffect(() => {
        props.setAppIsVisible(appVisible);
    }, [appVisible]);

    return (
        <Router>
            <Switch>
                <Route exact path={'/'}>
                    <AppRouteSwitch isLoggedIn={props.isLoggedIn} isOnboarding={props.isOnboarding} setOnboardingStatus={props.setOnboardingStatus} />
                </Route>

                <Route {...props}
                       path={[
                           ...publicRoutes.map(item => item.path),
                           ...accountOnboardingRoutes.map(item => item.path)
                       ]}
                >
                    <AuthIndex {...props} />
                </Route>

                {utilityRoutes.map((route, idx) => (
                    <CommonRoute path={route.path} layout={BlankLayout} component={route.component} key={idx}/>
                ))}

                <PrivateRoute
                    path={'/'}
                    component={Main}
                />
            </Switch>
        </Router>
    );
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.Login.isLoggedIn,
        isOnboarding: state.Login.isOnboarding,
    };
};

const mapDispatchToProps = {
    setLanguage,
    setOnboardingStatus,
    setAppIsVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
