import {
    FETCH_CHAT_MESSAGES,
    FETCH_CHAT_MESSAGES_SUCCESS,
    FETCH_CHAT_MESSAGES_ERROR,
    CLEAN_CHAT_MESSAGES,

    CHAT_ADD_MESSAGE,
    CHAT_MESSAGES_DELETE_MESSAGE,
    CHAT_MARK_MESSAGES_SEEN,
    CHAT_MARK_RECEIVED_MESSAGES_SEEN,
} from './actionTypes';

const initialState = {
    list: [],
    error: null,
    loading: false,
    isMoreLoading: false,
    meta: null,
};

const messages = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHAT_MESSAGES:
            state = {
                ...state,
                loading: !action.payload.data.isMoreLoading,
                isMoreLoading: action.payload.data.isMoreLoading,
                error: null,
            };
            break;

        case FETCH_CHAT_MESSAGES_SUCCESS:
            state = {
                ...state,
                loading: false,
                isMoreLoading: false,
                list: [...state.list, ...action.payload.data],
                meta: action.payload.meta,
            };
            break;

        case FETCH_CHAT_MESSAGES_ERROR:
            state = {
                ...state,
                loading: false,
                isMoreLoading: false,
                error: action.payload,
            };
            break;

        case CLEAN_CHAT_MESSAGES:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case CHAT_ADD_MESSAGE:
            state = {
                ...state,
                list: [action.payload, ...state.list],
            };
            break;

        case CHAT_MARK_MESSAGES_SEEN:
            state = {
                ...state,
                list: [...state.list.map(item => ({ ...item, seen: true }))],
            };
            break;

        case CHAT_MESSAGES_DELETE_MESSAGE:
            state = {
                ...state,
                list: state.list.filter((item) => item.id !== action.payload.id)
            };
            break;

        case CHAT_MARK_RECEIVED_MESSAGES_SEEN:
            state = {
                ...state,
                list: [...state.list.map(item => {
                    return !item.is_my ? { ...item, seen: true } : {...item};
                })],
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default messages;