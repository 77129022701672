import React from 'react';
import {useTranslation} from 'react-i18next';

import {crmSystemHelper} from "helpers";
import s from "./CrmInfo.module.scss"

export const CrmInfo = ({data, crm}) => {
  const {t} = useTranslation()

  return (
    <div className={s.info}>
      <img src={crmSystemHelper.general.getConfig(crm)?.logo} alt={"crm-logo"} className={s.logo}/>

      <h4 className={"mt-2"}>{data.name}</h4>
    </div>
  );
};