import {combineReducers} from "redux";

import list from './list/reducer';
import account from './account/reducers';
import sync from './sync/reducers';

const crmSystemReducer = combineReducers({
  list,
  account,
  sync,
});

export default crmSystemReducer;