import { all } from 'redux-saga/effects';

import companyLeadsSaga from './companyLeads/sagas';
import viewSaga from './view/saga';
import statusSaga from './status/saga';
import leadsStatusesSaga from './leadsStatuses/saga';
import assignSaga from './assign/saga';
import userLeadsSaga from './userLeads/saga';
import propertySaga from './property/sagas';
import preferencesSaga from './preferences/sagas';

function* leadSaga() {
    yield all([
        companyLeadsSaga(),
        viewSaga(),
        statusSaga(),
        leadsStatusesSaga(),
        assignSaga(),
        userLeadsSaga(),
        propertySaga(),
        preferencesSaga(),
    ])
}

export default leadSaga;