import React, { useState, useEffect, useMemo } from 'react';
import { Table } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { withRouter, Link, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import NumberFormat from 'react-number-format';
import { useTable, useSortBy } from 'react-table';

import { InviteStatusBadge, OfferPriceValue } from '../../../../components/Custom';

const ReceivedPropertyListTableComponent = (props) => {
    const location = useLocation();

    const { items } = props;

    const data = useMemo(() => {
        return items;
    }, [items]);

    const [columns, setColumns] = useState([{
        Header: props.t('vendor'),
        accessor: 'company',
        id: 'company',
        Cell: ({ row: { original } }) => (
            <div className={'text-dark font-weight-bold'}>{original.myReceivedLink?.sender?.company ? original.myReceivedLink.sender.company.name : '-'}</div>
        ),
    }, {
        Header: props.t('name'),
        accessor: 'name',
        id: 'sender_name',
        Cell: ({ row: { original } }) => {
            const { myReceivedLink: { sender }} = original;
            return (
                <Link to={`/contacts/${sender?.id}`} className="text-dark font-weight-bold text-decoration-underline">{sender?.name}</Link>
            )
        },
    }, {
        Header: props.t('city'),
        accessor: 'city.name',
        id: 'city',
    }, {
        Header: props.t('address'),
        accessor: 'address',
        id: 'address',
        Cell: ({ row, value }) => {
            const { original } = row;
            return (
                <Link to={{ pathname: `/properties/${original.id}`, state: { from: location } }} className="text-dark font-weight-bold text-decoration-underline">{value || "-"}</Link>
            );
        },
    }, {
        Header: props.t('unit'),
        accessor: 'unit',
        id: 'unit',
        Cell: ({ row, value }) => {
            return value ? value : '-';
        },
    }, {
        Header: () => (
            <div className={'w-100 text-center'}>{props.t('price')}</div>
        ),
        accessor: 'price',
        id: 'price',
        Cell: ({ row: { original }, value }) => {
            const { myReceivedLink } = original;
            return (
                <div className={'text-center text-dark font-weight-bold text-nowrap'}>
                    <div>
                        {value !== null && value >= 0 ? (
                            <NumberFormat
                                value={value}
                                thousandSeparator={' '}
                                prefix={'€ '}
                                displayType={'text'}
                            />
                        ) : '-' }
                    </div>

                    {myReceivedLink && myReceivedLink?.price && (
                        <OfferPriceValue offerPrice={myReceivedLink?.price} price={value} />
                    )}
                </div>
            );
        },
    }, {
        Header: () => (
            <div className={'w-100 text-center'}>{props.t('status')}</div>
        ),
        accessor: 'status',
        id: 'link_status',
        Cell: ({ row: { original } }) => {
            const { myReceivedLink: { status }} = original;
            return (
                <div className={'text-center'}>
                    <InviteStatusBadge status={status} />
                </div>
            );
        },
    }]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: props.initialSort,
            },
            defaultCanSort: false,
            disableSortBy: data.length === 0,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
        },
        useSortBy,
    );

    useEffect(() => {
        props.onSort(sortBy);
    }, [props.onSort, sortBy]);

    return (
        <div className="table-responsive table-centered">
            <Table className={'table-bordered table-part-bordered'} {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps({ title: null }))}
                                >
                                    <div className={'d-flex'}>
                                        <div className={'d-flex flex-fill'}>
                                            {column.render('Header')}
                                        </div>

                                        <div>
                                            {column.canSort && (
                                                column.isSorted ? (
                                                    <i className={'bx font-size-16 align-middle' + (column.isSortedDesc ? " bx-sort-down" : " bx-sort-up")} />
                                                ) : <i className="bx bx-sort align-middle" style={{ color: '#adb5bd' }} />
                                            )}
                                        </div>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {(
                        rows.length > 0 && rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })
                    ) || (
                        <tr>
                            <td colSpan={columns.length}>
                                {!props.isLoading ? props.t('empty') : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}} />}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const ReceivedPropertyListTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReceivedPropertyListTableComponent)));