import { combineReducers } from "redux";

import configReducer from './config/reducer';
import actualUnpaidReducer from './actualUnpaid/reducer';
import previousUnpaidReducer from './previousUnpaid/reducer';
import paidReducer from './paid/reducer';
import notInterestedReducer from './notInterested/reducer';

const companyLeadsReducer = combineReducers({
    config: configReducer,
    actualUnpaid: actualUnpaidReducer,
    previousUnpaid: previousUnpaidReducer,
    paid: paidReducer,
    notInterested: notInterestedReducer,
});

export default companyLeadsReducer;