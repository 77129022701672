import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { PROPERTY_REQUEST_CHECK_AVAILABLE } from './actionTypes';
import {
    propertyRequestCheckAvailableSuccess,
    propertyRequestCheckAvailableError,
} from './actions';

import { API } from '../../../api';

function* checkAvailable() {
    try {
        const response = yield call(API.propertyRequest.checkAvailableCreatePropertyRequest);

        const { data } = response;

        yield put(propertyRequestCheckAvailableSuccess(true));

    } catch (error) {
        // const errors = error.response?.data?.errors ? error.response.data.errors : {};

        let message = null;

        let payload = {};

        if (error.response && error.response.status === 403) {
            payload = {
                error: null,
                available: false,
            }
        } else {
            message = error.response?.data?.message ? error.response.data.message : 'Server Error';
            payload = {
                error: message,
                available: null,
            }
        }

        yield put(propertyRequestCheckAvailableError(payload));
    }
}

export function* watchCheckAvailable() {
    yield takeEvery(PROPERTY_REQUEST_CHECK_AVAILABLE, checkAvailable)
}

function* checkSaga() {
    yield all([
        fork(watchCheckAvailable),
    ]);
}

export default checkSaga;