import {
    FETCH_CLIENTS_ADDED_PROPERTY_FAVORITE,
    FETCH_CLIENTS_ADDED_PROPERTY_FAVORITE_SUCCESS,
    FETCH_CLIENTS_ADDED_PROPERTY_FAVORITE_ERROR,
    CLEAN_CLIENTS_ADDED_PROPERTY_FAVORITE_PAGE,
} from './actionTypes';

export const fetchClientsAddedFavorite = (id, params) => {
    return {
        type: FETCH_CLIENTS_ADDED_PROPERTY_FAVORITE,
        payload: { id, params }
    }
};

export const fetchClientsAddedFavoriteSuccess = (data) => {
    return {
        type: FETCH_CLIENTS_ADDED_PROPERTY_FAVORITE_SUCCESS,
        payload: data,
    }
};

export const fetchClientsAddedFavoriteError = (data) => {
    return {
        type: FETCH_CLIENTS_ADDED_PROPERTY_FAVORITE_ERROR,
        payload: data,
    }
};

export const cleanClientsAddedFavoritePage = () => {
    return { type: CLEAN_CLIENTS_ADDED_PROPERTY_FAVORITE_PAGE };
};