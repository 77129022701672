import React from 'react';
import { Progress } from "reactstrap";
import { useTranslation } from 'react-i18next';

export const WizardProgress = (props) => {
    const { t } = useTranslation();

    const getColor = () => {
        let result = 'primary';

        if (props.percent < 30) {
            result = 'danger';
        } else if (props.percent >= 30 && props.percent < 60) {
            result = 'warning';
        } else {
            result = 'success';
        }

        return result;
    };

    return (
        <>
            <p className={'mb-1'}>{t('fill_level')}</p>

            <Progress
                value={props.percent}
                color={getColor()}
                className={'progress-xxl'}
            >
                {`${props.percent}%`}
            </Progress>
        </>
    );
};