import {
    LEAD_ASSIGN_CONSULTANT,
    LEAD_ASSIGN_CONSULTANT_SUCCESS,
    LEAD_ASSIGN_CONSULTANT_ERROR,
    CLEAN_LEAD_ASSIGN_CONSULTANT,
} from './actionTypes';

export const leadAssignConsultant = (id, params) => {
    return {
        type: LEAD_ASSIGN_CONSULTANT,
        payload: { id, params },
    }
};

export const leadAssignConsultantSuccess = (data) => {
    return {
        type: LEAD_ASSIGN_CONSULTANT_SUCCESS,
        payload: data,
    }
};

export const leadAssignConsultantError = (data) => {
    return {
        type: LEAD_ASSIGN_CONSULTANT_ERROR,
        payload: data,
    }
};

export const cleanLeadAssignConsultant = () => {
    return { type: CLEAN_LEAD_ASSIGN_CONSULTANT };
};