import React from 'react';
import { Card, CardBody, Col, Row } from "reactstrap";
import NumberFormat from 'react-number-format';

import './PriceBlock.scss';
import { OfferPriceDiff, PropertyStatusLabel } from "../../../../../../components/Custom";

export const PriceBlock = ({ property }) => {
    const offerPrice = property.myReceivedLink?.price;

    const renderOfferPrice = () => {
        return (
            <div className={'d-flex align-items-center'}>
                <NumberFormat
                    value={offerPrice}
                    thousandSeparator={' '}
                    prefix={'€ '}
                    displayType={'text'}
                    className={'property-price-info-block__price'}
                />
                {property.price !== null && (
                    <sup className={'ml-1'}>
                        {'('}
                        <OfferPriceDiff offerPrice={offerPrice} price={property.price} reverse suffix={false} textClass={'font-size-13'} />
                        {')'}
                    </sup>
                )}
            </div>
        );
    };

    const renderPrice = () => {
        return (
            property.price !== null && property.price >= 0 ? (
                    <NumberFormat
                        value={property.price}
                        thousandSeparator={' '}
                        prefix={'€ '}
                        displayType={'text'}
                        className={'property-price-info-block__price'}
                    />
                ) : '-'
        )
    }

    return (
        <Row>
            <Col>
                <Card>
                    <CardBody className={'card_pv_sm'}>
                        <div className="property-price-info-block">
                            {offerPrice ? renderOfferPrice() : renderPrice()}

                            <PropertyStatusLabel status={property.status} classNames={'font-size-16'} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};