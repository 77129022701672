import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { SWAP_BILLING_SUBSCRIPTION } from './actionTypes';
import {
    swapBillingSubscriptionSuccess,
    swapBillingSubscriptionError,
} from './actions';

import { API } from '../../../../../api';

function* swapSubscription({ payload: { tariffId, callback } }) {
    try {
        const params = {
            tariff: tariffId,
        };

        const response = yield call(API.billing.swapSubscription, params);

        const { data } = response;

        const { success, type, message, url } = data.data;

        if (success) {
            yield put(swapBillingSubscriptionSuccess(true));

            if (callback) {
                callback();
            }

        } else if (url) {
            window.location.href = url;
        } else if (message) {
            // error
            yield put(swapBillingSubscriptionError(message));
        }
    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(swapBillingSubscriptionError(message));
    }
}

export function* watchSwapSubscription() {
    yield takeEvery(SWAP_BILLING_SUBSCRIPTION, swapSubscription)
}

function* swapSaga() {
    yield all([
        fork(watchSwapSubscription),
    ]);
}

export default swapSaga;