import {
    PROPERTY_CLIENTS_INVITE_STAFF_LIST_GET_DATA,
    PROPERTY_CLIENTS_INVITE_STAFF_LIST_GET_DATA_SUCCESS,
    PROPERTY_CLIENTS_INVITE_STAFF_LIST_GET_DATA_ERROR,
    PROPERTY_CLIENTS_INVITE_STAFF_LIST_CLEAN_STATE,
} from './actionTypes';

export const propertyClientsInviteStaffListGetData = (role, property, params) => {
    return {
        type: PROPERTY_CLIENTS_INVITE_STAFF_LIST_GET_DATA,
        payload: { role, property, params }
    }
};

export const propertyClientsInviteStaffListGetDataSuccess = (data) => {
    return {
        type: PROPERTY_CLIENTS_INVITE_STAFF_LIST_GET_DATA_SUCCESS,
        payload: data,
    }
};

export const propertyClientsInviteStaffListGetDataError = (data) => {
    return {
        type: PROPERTY_CLIENTS_INVITE_STAFF_LIST_GET_DATA_ERROR,
        payload: data,
    }
};

export const propertyClientsInviteStaffListCleanState = () => {
    return { type: PROPERTY_CLIENTS_INVITE_STAFF_LIST_CLEAN_STATE };
};