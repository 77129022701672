import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from "reactstrap";
import {connect} from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

import ButtonLoadingIcon from "components/Common/ButtonLoadingIcon";
import {
  crmSystemAccountRemove,
} from "store/actions";

const CrmAccountRemoveComponent = ({id, crm, disabled = false, loading = false, ...props}) => {
  const {t} = useTranslation()

  const [confirmModal, setConfirmModal] = useState(false);

  const onCancel = () => {
    setConfirmModal(true);
  };

  const handleConfirm = () => {
    setConfirmModal(false);
    props.crmSystemAccountRemove({id, crm});
  };

  return (
    <div>
      <Button
        color={'secondary'}
        outline
        className="btn-sm waves-effect waves-light"
        disabled={disabled || loading}
        onClick={onCancel}
      >
        <ButtonLoadingIcon loading={loading}/>
        {t('remove_account')}
      </Button>

      {confirmModal && (
        <SweetAlert
          title={t('confirm.sure')}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="light"
          onConfirm={() => {
            handleConfirm();
          }}
          onCancel={() => {
            setConfirmModal(false);
          }}
          cancelBtnText={t('cancel')}
        >
        </SweetAlert>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {}
};

const mapDispatchToProps = {
  crmSystemAccountRemove,
};

export const CrmAccountRemove = connect(mapStateToProps, mapDispatchToProps)(CrmAccountRemoveComponent);