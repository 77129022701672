import React, { useState, useEffect, useMemo } from 'react';
import { Table } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useTable, useSortBy } from 'react-table';
import { useRouteMatch, withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import { ROLE_MANAGER } from '../../../../consts/roles';
import { PhoneFormat, ReferralStatusBadge } from '../../../../components/Custom';
import { ConsultantCell } from './ConsultantCell';

import placeholder from '../../../../assets/images/custom/avatar-placeholder.png';

const ClientsTableComponent = (props) => {
    const { items } = props;

    let { url } = useRouteMatch();

    const data = useMemo(() => {
        return items;
    }, [items]);

    const [columns, setColumns] = useState([]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: props.initialSort,
            },
            defaultCanSort: false,
            disableSortBy: data.length === 0,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
        },
        useSortBy,
    );

    useEffect(() => {
        let config = [{
            Header: '',
            accessor: 'avatar',
            id: 'avatar',
            Cell: ({ value }) => (
                <img src={value ? value : placeholder} alt="" className="avatar-xs rounded-circle avatar-cover" />
            ),
            disableSortBy: true,
        }, {
            Header: props.t('name'),
            accessor: 'name',
            id: 'name',
            Cell: ({ row: { original }, value }) => {
                return <Link to={`${url}/${original.id}`} className="text-dark font-weight-bold">{value || '-'}</Link>;
            },
        }, {
            Header: props.t('email'),
            accessor: 'email',
            id: 'email',
            Cell: ({ row: { original }, value }) => {
                return (
                    <>
                        <div className={'mb-1'}>
                            <Link to={`${url}/${original.id}`} className="text-dark text-decoration-underline">{value}</Link>
                        </div>

                        {_.has(original, 'activated') ? (
                            <ReferralStatusBadge status={original.activated} />
                        ) : null}
                    </>
                );
            },
        }, {
            Header: props.t('phone'),
            accessor: 'phone',
            id: 'phone',
            Cell: ({ value }) => {
                return <PhoneFormat value={value} />;
            },
        }, {
            Header: props.t('date.added'),
            accessor: 'created_at',
            id: 'created_at',
            Cell: ({ value }) => {
                return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
        }];

        if (props.user.role === ROLE_MANAGER) {
            config = [
                ...config,
                {
                    Header: props.t('consultant'),
                    id: 'consultant',
                    Cell: ({ row: { original } }) => (
                        <ConsultantCell items={original.myCompanySenders} />
                    ),
                    disableSortBy: true,
                }
            ];
        }

        setColumns(config);
    }, [data]);

    useEffect(() => {
        props.onSort(sortBy);
    }, [props.onSort, sortBy]);

    return (
        <div className="table-responsive table-centered">
            <Table className={'table-bordered table-part-bordered'} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps({ title: null }))}
                            >
                                <div className={'d-flex'}>
                                    <div className={'d-flex flex-fill'}>
                                        {column.render('Header')}
                                    </div>

                                    <div>
                                        {column.canSort && (
                                            column.isSorted ? (
                                                <i className={'bx font-size-16 align-middle' + (column.isSortedDesc ? " bx-sort-down" : " bx-sort-up")} />
                                            ) : <i className="bx bx-sort align-middle" style={{ color: '#adb5bd' }} />
                                        )}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {(
                    rows.length > 0 && rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                ) || (
                    <tr>
                        <td colSpan={columns.length}>
                            {!props.isLoading ? props.t('empty') : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}} />}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};

const mapStateToProps = state => {
    const { sentUsersSort } = state.invite.users;
    return {
        initialSort: sentUsersSort,
        user: state.user.data,
    };
};

const mapDispatchToProps = {
};

export const ClientsTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientsTableComponent)));