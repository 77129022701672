import _ from 'lodash';

import {
    PROPERTY_INVESTMENT_LOAN_TYPE_NONE,
    PROPERTY_INVESTMENT_LOAN_TYPE_NORMAL_CREDIT,
    PROPERTY_INVESTMENT_LOAN_TYPE_BULLET_CREDIT,
} from '../../consts/property';

export const getLoanTypeOptions = (t) => {
    return [{
        label: t('none'),
        value: PROPERTY_INVESTMENT_LOAN_TYPE_NONE,
    }, {
        label: t('normal_credit'),
        value: PROPERTY_INVESTMENT_LOAN_TYPE_NORMAL_CREDIT,
    }, {
        label: t('bullet_credit'),
        value: PROPERTY_INVESTMENT_LOAN_TYPE_BULLET_CREDIT,
    }];
};

export const getLoanType = (value, t) => {
    const options = getLoanTypeOptions(t);
    const item = _.find(options, { value: value });

    return item || {
        label: '',
        value: '',
    };
};

export default {
    getLoanTypeOptions,
    getLoanType,
};