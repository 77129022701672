import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import { FavoritePropertyCard } from '../FavoritePropertyCard';
import { SwiperNavigationButton } from '../../../../../../components/Custom';

import './FavoritePropertyCarousel.scss';

// install Swiper modules
SwiperCore.use([Navigation]);

export const FavoritePropertyCarousel = ({ items, onSwiper, onChange }) => {
    return (
        <>
            <Swiper
                navigation={{
                    nextEl: '.carousel-navigation__button_direction_next', // todo: make ref to component
                    prevEl: '.carousel-navigation__button_direction_prev', // todo: make ref to component
                }}
                loop={false}
                allowTouchMove={true} // enable/disable swipe
                onSlideChange={() => onChange()}
                onSwiper={(swiper) => onSwiper(swiper)}
                slidesPerView={1}
                spaceBetween={8}
                breakpoints={{
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 2,
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 3,
                    },
                    // when window width is >= 992px
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 16,
                    },
                    // when window width is >= 1200px
                    1200: {
                        slidesPerView: 2,
                        spaceBetween: 16,
                    },
                    // when window width is >= 1320px
                    1320: {
                        slidesPerView: 3,
                        spaceBetween: 16,
                    },
                    // when window width is >= 1700px
                    1700: {
                        slidesPerView: 4,
                        spaceBetween: 16,
                    },
                    // when window width is >= 2036px
                    2036: {
                        slidesPerView: 5,
                        spaceBetween: 16,
                    }
                }}
            >
                {items.map((item, index) => (
                    <SwiperSlide key={item.id + '-' + index}>
                        <FavoritePropertyCard
                            item={item}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            <SwiperNavigationButton
                direction={'prev'}
                size={'s'}
                extraClass={'favorite-property-carousel-navigation_direction_prev'}
            />
            <SwiperNavigationButton
                direction={'next'}
                size={'s'}
                extraClass={'favorite-property-carousel-navigation_direction_next'}
            />
        </>
    );
};