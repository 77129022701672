import {
    LEAD_LIST_UPDATE_LEAD_STATUS_DECLINE,
    LEAD_LIST_UPDATE_LEAD_STATUS_BUY,

    LEAD_LIST_UPDATE_LEAD_STATUS_SUCCESS,
    LEAD_LIST_UPDATE_LEAD_STATUS_ERROR,
    CLEAN_LEAD_LIST_UPDATE_LEAD_STATUS,
} from './actionTypes';

// UPDATE LEAD STATUS IN LEAD LIST
export const leadListUpdateLeadStatusDecline = (id, lead) => {
    return {
        type: LEAD_LIST_UPDATE_LEAD_STATUS_DECLINE,
        payload: { id, lead },
    }
};

export const leadListUpdateLeadStatusBuy = (id, lead) => {
    return {
        type: LEAD_LIST_UPDATE_LEAD_STATUS_BUY,
        payload: { id, lead },
    }
};

export const leadListUpdateLeadStatusSuccess = (id) => {
    return {
        type: LEAD_LIST_UPDATE_LEAD_STATUS_SUCCESS,
        payload: { id },
    }
};

export const leadListUpdateLeadStatusError = (id) => {
    return {
        type: LEAD_LIST_UPDATE_LEAD_STATUS_ERROR,
        payload: { id },
    }
};

export const cleanLeadListUpdateLeadStatus = () => {
    return { type: CLEAN_LEAD_LIST_UPDATE_LEAD_STATUS };
};