import React from 'react';
import { Button, FormGroup } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from 'redux-form'

import { FORM_CREDITS_BUY } from '../../../../../../consts/forms';
import { creditHelper } from '../../../../../../helpers';
import { CreditsPickerField } from './CreditsPickerField';

const creditsOptions = creditHelper.buy.getPickerData();

const CreditsBuyFormComponent = (props) => {
    const onSubmit = (values) => {
        if (values.credits) {
            const priceItem = creditsOptions.find(item => item.credits === Number(values.credits));
            props.onSubmit(values, priceItem.totalPrice);
        }
    };

    return (
        <>
            <form
                onSubmit={props.handleSubmit(onSubmit)}
                className="form-horizontal"
                noValidate={true}
            >

                <FormGroup tag="fieldset">
                    <div className="pricing-block__credits-list">
                        {creditsOptions.map((item) => (
                            <Field
                                key={'credits-' + item.credits}
                                name={'credits'}
                                type="radio"
                                component={CreditsPickerField}
                                value={item.credits.toString()}
                                id={item.credits.toString()}
                                option={item}
                            />
                        ))}
                    </div>
                </FormGroup>

                <div className="text-center mb-4 mt-4">
                    <Button type="submit" color="success" className={'btn-lg'} disabled={!props.creditsValue}>
                        {props.t('buy')}
                    </Button>
                </div>
            </form>
        </>
    );
};

const selector = formValueSelector(FORM_CREDITS_BUY);

const CreditsBuy = reduxForm({
    form: FORM_CREDITS_BUY,
    enableReinitialize: true,
})(CreditsBuyFormComponent);

const mapStateToProps = state => {
    const creditsValue = selector(state, 'credits');
    return {
        creditsValue,
    };
};

const mapDispatchToProps = {
};

export const CreditsBuyForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreditsBuy)));