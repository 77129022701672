import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Alert, Card, CardHeader, CardBody, Row, } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

import {
    fetchUsersFromSentInvites,
    cleanUsersFromSentInvites,
    usersFromSentInvitesSetSort,
    usersFromSentInvitesSetFilter,
    usersFromSentInvitesResetFilter,
    usersFromSentInvitesSetPage,
} from '../../../../store/actions';
import { ClientsTable } from '../ClientsTable/ClientsTable';
import { UsersFilter, Pagination, SearchInput } from '../../../../components/Custom';

import * as HOC from '../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const ConsultantClientsComponent = (props) => {
    let { url } = useRouteMatch();

    const [search, setSearch] = useState(null);

    useEffect(() => {
        let params = {
            page: props.page,
        };

        if (props.sort.length > 0) {
            params.sort = props.sort;
        }

        if (props.filter.isActive) {
            params.filter = props.filter.values;
        }

        if (search) {
            params.filter = {
                ...params.filter,
                keywords: search,
                search_fields: ['name', 'email', 'phone'],
            };
        }

        props.fetchUsersFromSentInvites(params);

    }, [props.sort, props.filter, props.page, search]);

    useEffect(() => {
        return () => {
            props.cleanUsersFromSentInvites();
            props.usersFromSentInvitesResetFilter();
        }
    }, []);

    const filterWarningVisible = useMemo(() => {
        return (props.filter.isActive || search) && props.list.length === 0 && !props.loading;
    }, [props.filter, props.list, props.loading, search]);

    const handleSort = useCallback(sortBy => {
        if (!_.isEqual(sortBy, props.sort)) {
            props.usersFromSentInvitesSetSort(sortBy);
        }
    }, [props.sort]);

    const handleResetFilter = () => {
        props.usersFromSentInvitesResetFilter()
    };

    const handleApplyFilter = (values) => {
        props.usersFromSentInvitesSetFilter(values);
    };

    const handlePageChange = (item) => {
        props.usersFromSentInvitesSetPage(item.selected + 1);
    };

    const handleSearchChange = (value) => {
        props.usersFromSentInvitesSetPage(1);
        setSearch(value);
    };

    return (
        <Card>
            <CardHeader className="bg-transparent">
                <Row className="no-gutters justify-content-between">
                    <h5 className={'mt-2 mr-2'}>{props.t('clients.your.list')}</h5>

                    <div className="button-items">
                        <UsersFilter
                            filter={props.filter}
                            onReset={handleResetFilter}
                            onApply={handleApplyFilter}
                        />

                        <Link to={`${url}/invite`} className="btn btn-primary">
                            {props.t('invite')}
                            <i className="fas fa-angle-right font-size-16 align-middle ml-2"></i>
                        </Link>
                    </div>
                </Row>
            </CardHeader>
            <CardBody>
                <Row className={'no-gutters justify-content-between'}>
                    <SearchInput
                        onChange={handleSearchChange}
                        searchFields={
                            ['search.field.name', 'search.field.email', 'search.field.phone']
                        }
                        hintExample={'John Doe'}
                    />
                </Row>

                <TableDataLoading isLoading={props.loading} isFailed={props.error} error={props.t(props.error)}>
                    {filterWarningVisible ? (
                        <Alert color="primary">{props.t('clients.filter.empty')}</Alert>
                    ) : (
                        <>
                            <ClientsTable
                                items={props.list}
                                isLoading={props.loading}
                                onSort={handleSort}
                            />

                            <Pagination data={props.meta} onPageChange={handlePageChange} />
                        </>
                    )}
                </TableDataLoading>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => {
    const { sentUsers, sentUsersLoading, sentUsersError, sentUsersSort, sentUsersFilter, sentUsersPage, sentUsersMeta } = state.invite.users;
    return {
        list: sentUsers,
        loading: sentUsersLoading,
        error: sentUsersError,
        sort: sentUsersSort,
        filter: sentUsersFilter,
        meta: sentUsersMeta,
        page: sentUsersPage,
    };
};

const mapDispatchToProps = {
    fetchUsersFromSentInvites,
    cleanUsersFromSentInvites,
    usersFromSentInvitesSetSort,
    usersFromSentInvitesSetFilter,
    usersFromSentInvitesResetFilter,
    usersFromSentInvitesSetPage,
};

export const ConsultantClients = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConsultantClientsComponent)));