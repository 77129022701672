import { all } from 'redux-saga/effects';

import translationSaga from './translation/saga';

function* staticSaga() {
    yield all([
        translationSaga(),
    ])
}

export default staticSaga;