import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_USERS_FROM_RECEIVED_INVITES, FETCH_USERS_FROM_SENT_INVITES, FETCH_USERS_FROM_COMPANY_SENT_INVITES } from './actionTypes';
import {
    fetchUsersFromReceivedInvitesSuccess,
    fetchUsersFromReceivedInvitesError,
    fetchUsersFromSentInvitesSuccess,
    fetchUsersFromSentInvitesError,
    fetchUsersFromCompanySentInvitesSuccess,
    fetchUsersFromCompanySentInvitesError,
} from './actions';

import { API } from '../../../api';

import { getMapRequestParams } from '../../../utils/request';

const getRequestParams = (params) => {
    let requestParams = {
        includes: ['company'],
    };

    return getMapRequestParams(params, requestParams);
};

function* fetchUsersFromReceivedInvites({ payload: { params } }) {
    try {
        const requestParams = getRequestParams(params);

        const response = yield call(API.invite.getUsersFromReceivedInvites, requestParams );

        const { data } = response;
        yield put(fetchUsersFromReceivedInvitesSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchUsersFromReceivedInvitesError(message));
    }
}

function* fetchUsersFromSentInvites({ payload: { params } }) {
    try {
        const requestParams = getRequestParams(params);

        const response = yield call(API.invite.getUsersFromSentInvites, requestParams );

        const { data } = response;
        yield put(fetchUsersFromSentInvitesSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchUsersFromSentInvitesError(message));
    }
}

function* fetchUsersFromCompanySentInvites({ payload: { params } }) {
    try {
        const initialParams = {
            includes: ['company', 'myCompanySenders'],
        };

        const requestParams = getMapRequestParams(params, initialParams);

        const response = yield call(API.invite.getUsersFromCompanySentInvites, requestParams);

        const { data } = response;
        yield put(fetchUsersFromCompanySentInvitesSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchUsersFromCompanySentInvitesError(message));
    }
}

export function* watchUsersReceivedInvitesFetch() {
    yield takeEvery(FETCH_USERS_FROM_RECEIVED_INVITES, fetchUsersFromReceivedInvites)
}

export function* watchUsersSentInvitesFetch() {
    yield takeEvery(FETCH_USERS_FROM_SENT_INVITES, fetchUsersFromSentInvites)
}

export function* watchUsersCompanySentInvitesFetch() {
    yield takeEvery(FETCH_USERS_FROM_COMPANY_SENT_INVITES, fetchUsersFromCompanySentInvites)
}

function* usersSaga() {
    yield all([
        fork(watchUsersReceivedInvitesFetch),
        fork(watchUsersSentInvitesFetch),
        fork(watchUsersCompanySentInvitesFetch),
    ]);
}

export default usersSaga;