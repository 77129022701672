import {
    COMPANY_LEADS_SET_ACTIVE_TAB,
    COMPANY_LEADS_PREFERENCES_STATUS_ERROR,
    COMPANY_LEADS_RESET_CONFIG,
} from './actionTypes';

import { COMPANY_LEADS_TAB_ALL_LEADS } from '../../../../consts/lead';

const initialState = {
    activeTab: COMPANY_LEADS_TAB_ALL_LEADS,

    preferencesError: false,
};

const config = (state = initialState, action) => {
    switch (action.type) {

        case COMPANY_LEADS_SET_ACTIVE_TAB:
            state = {
                ...state,
                activeTab: action.payload,
            };
            break;

        case COMPANY_LEADS_PREFERENCES_STATUS_ERROR:
            state = {
                ...state,
                preferencesError: action.payload,
            };
            break;

        case COMPANY_LEADS_RESET_CONFIG:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default config;