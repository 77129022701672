import { all } from 'redux-saga/effects';

import propertyRequestSaga from './propertyRequest/saga';
import activeListSaga from './activeList/saga';
import inactiveListSaga from './inactiveList/saga';

function* suggestedPropertySaga() {
    yield all([
        propertyRequestSaga(),
        activeListSaga(),
        inactiveListSaga(),
    ])
}

export default suggestedPropertySaga;