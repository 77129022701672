import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_SUBSCRIPTION_PAYMENT_LINK } from './actionTypes';
import {
    fetchSubscriptionPaymentLinkSuccess,
    fetchSubscriptionPaymentLinkError,
} from './actions';

import { API } from '../../../../api';

function* fetchPaymentLink() {
    try {
        const response = yield call(API.billing.getSubscriptionPaymentLink);

        const { data } = response;

        yield put(fetchSubscriptionPaymentLinkSuccess(data?.data));

        window.location.href = data?.data?.url

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchSubscriptionPaymentLinkError(message));
    }
}

export function* watchPaymentLinkFetch() {
    yield takeEvery(FETCH_SUBSCRIPTION_PAYMENT_LINK, fetchPaymentLink)
}

function* paymentLinkSaga() {
    yield all([
        fork(watchPaymentLinkFetch),
    ]);
}

export default paymentLinkSaga;