import React, { useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";

import { StripeWrapper } from '../../../../../components/Custom';
import { BillingPayForm } from '../BillingPayForm';

const BillingPaySectionComponent = (props) => {
    const location = useLocation();

    useEffect(() => {
        return () => {
        };
    }, []);

    const onSubmit = (values) => {

    };

    return (
        <StripeWrapper>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-4">{props.t('pay')}</CardTitle>

                            <BillingPayForm
                                onSubmit={onSubmit}
                                isLoading={false}
                                isDisabled={false}
                                priceInfo={location.state?.priceInfo || {}}
                            />

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </StripeWrapper>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const BillingPaySection = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingPaySectionComponent)));