import React from 'react';

export const Bathrooms = ({
    width = "14",
    height = "13",
    viewBox = "0 0 14 13",
    color = '#74788D',
}) => (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.7385 0.171328C11.1798 -0.0643529 11.7178 -0.0677562 12.1393 0.229184C12.563 0.510384 12.8272 1.03365 12.9695 1.5701C13.2007 2.45581 13.1967 3.39853 13.1429 4.31531C13.3042 4.31786 13.4756 4.29063 13.6265 4.37699C13.8609 4.49356 14.0168 4.79347 13.9985 5.09467C13.9903 5.38778 13.8161 5.65834 13.5828 5.75194C13.448 5.80681 13.3032 5.78895 13.1634 5.7898C10.3152 5.77916 7.46381 5.68387 4.62746 5.36013C3.25965 5.19294 1.88861 5.00235 0.551983 4.60884C0.388534 4.568 0.228669 4.47782 0.128306 4.31403C-0.0218812 4.09026 -0.0412371 3.76099 0.0756147 3.51212C0.196768 3.239 0.473843 3.0782 0.72977 3.13903C1.63376 3.41172 2.55782 3.57721 3.48188 3.7193C6.33041 4.13195 9.20153 4.26341 12.0691 4.30637C12.1099 3.79162 12.1142 3.27304 12.078 2.75828C12.0418 2.36818 11.9898 1.96786 11.8296 1.61944C11.745 1.43694 11.5948 1.26635 11.4081 1.27188C11.1769 1.28081 10.9815 1.45013 10.8073 1.61434C10.5722 1.84534 10.3672 2.11846 10.1944 2.4171C10.0822 2.61704 9.86787 2.72808 9.66822 2.68681C9.48362 2.6515 9.31587 2.4958 9.25207 2.28607C9.18683 2.08783 9.21264 1.85215 9.32089 1.68241C9.69008 1.06513 10.1457 0.482307 10.7385 0.171328Z" fill={color} />
        <path d="M0.621932 5.30945C1.12877 5.46813 1.64421 5.58639 2.16144 5.68679C3.76295 5.99182 5.3806 6.16198 6.9986 6.28323C9.12201 6.4402 11.2501 6.48955 13.3774 6.49764C13.3771 7.5348 13.2846 8.58515 12.9975 9.56957C12.8089 10.2005 12.519 10.8275 12.0325 11.1989C12.1247 11.4542 12.2666 11.6992 12.2666 11.9872C12.2809 12.413 12.0358 12.8291 11.6906 12.9512C11.3228 13.1022 10.8816 12.8916 10.7049 12.4807C10.5705 12.1514 10.445 11.8175 10.3138 11.4861C8.58757 11.4814 6.86132 11.4869 5.13507 11.4839C4.85656 11.4716 4.57661 11.4912 4.2999 11.4469C4.16942 11.7788 4.04254 12.1123 3.90991 12.4428C3.81278 12.6862 3.6271 12.8746 3.41096 12.9538C3.10163 13.075 2.73638 12.9495 2.5292 12.6509C2.32704 12.3752 2.27435 11.9613 2.39873 11.6269C2.49837 11.3555 2.60949 11.0904 2.71236 10.8207C2.02523 10.3353 1.47502 9.59764 1.12554 8.74681C0.68466 7.68838 0.535906 6.48019 0.621932 5.30945Z" fill={color} />
    </svg>
);