import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import toastr from 'toastr';
import i18n from '../../../i18n';

import { CREATE_PROPERTY } from './actionTypes';
import {
    createPropertySuccess,
    createPropertyError,
} from './actions';

import { FORM_PROPERTY_COMMON } from '../../../consts/forms';

import { API } from '../../../api';

function* createProperty({ payload: { params, history } }) {
    try {
        const response = yield call(API.property.createProperty, params);

        const { data } = response;
        yield put(createPropertySuccess(true));
        history.push('/properties');

        toastr.success(i18n.t('property.create.success'));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(createPropertyError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_PROPERTY_COMMON, errors));
    }
}

export function* watchCreateProperty() {
    yield takeEvery(CREATE_PROPERTY, createProperty)
}

function* createSaga() {
    yield all([
        fork(watchCreateProperty),
    ]);
}

export default createSaga;