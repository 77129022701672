import React from 'react';
import { Label, FormFeedback, FormGroup, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

const SwitchField = ({
    label,
    placeholder,
    type,
    input,
    meta: { touched, error, warning },
    defaultValue,
    reference,
    children,
    ...props
}) => {
    const { t } = useTranslation();
    const showError = touched && Boolean(error);

    return (
        <FormGroup row className={'mb-4 ' + (showError ? 'text-danger' : '')}>
            <Label className={'col-form-label col-lg-2'}>{label}</Label>

            <Col lg="10">
                <div className="custom-control custom-switch custom-switch-filed-wrap custom-switch-md mb-2" dir="ltr">
                    <input
                        {...input}
                        type="checkbox"
                        className="custom-control-input"
                        disabled={props.disabled}
                    />
                    <label className="custom-control-label" htmlFor={props.id} onClick={(e) => { input.onChange(!input.value); }}>{placeholder}</label>
                </div>
                <FormFeedback invalid={showError ? error : null }>
                    { t(error, props.translateParams ? { ...props.translateParams } : {}) }
                </FormFeedback>
            </Col>
        </FormGroup>
    );
};

export default SwitchField;