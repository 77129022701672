import {
    FETCH_CHATS,
    FETCH_CHATS_SUCCESS,
    FETCH_CHATS_ERROR,
    CLEAN_CHATS,

    CHATS_ADD_CHAT,
    CHATS_UPDATE_CHAT,

    CHATS_UPDATE_CHAT_WITH_SORT_LIST,
} from './actionTypes';

export const fetchChats = () => {
    return {
        type: FETCH_CHATS,
    }
};

export const fetchChatsSuccess = (data) => {
    return {
        type: FETCH_CHATS_SUCCESS,
        payload: data,
    }
};

export const fetchChatsError = (data) => {
    return {
        type: FETCH_CHATS_ERROR,
        payload: data,
    }
};

export const cleanChats = () => {
    return { type: CLEAN_CHATS };
};

export const chatsAddChat = (data) => {
    return {
        type: CHATS_ADD_CHAT,
        payload: { data },
    }
};

export const chatsUpdateChat = (id, data) => {
    return {
        type: CHATS_UPDATE_CHAT,
        payload: { id, data },
    }
};

// need refactoring
export const chatsUpdateChatWithSort = (id, data) => {
    return {
        type: CHATS_UPDATE_CHAT_WITH_SORT_LIST,
        payload: { id, data },
    }
};

// mark chat read
export const chatsMarkChatRead = (id) => {
    return {
        type: CHATS_UPDATE_CHAT,
        payload: { id, data: { unread_count: 0 } },
    }
};