import * as roleMiddleware from './role';
import * as subscriptionMiddleware from './subscription';
import * as propertyMiddleware from './property';
import * as previewMode from './previewMode';

export const middlewareHelper = {
  roleMiddleware,
  subscriptionMiddleware,
  propertyMiddleware,
  previewMode,
};