import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Button, TabContent, TabPane } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { reduxForm, formValueSelector } from 'redux-form'
import {
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import NumberFormat from 'react-number-format';

import { FORM_BILLING_PAYMENT } from '../../../../../consts/forms';
import ButtonLoadingIcon from '../../../../../components/Common/ButtonLoadingIcon';
import { BillingPaymentTypeNav } from '../BillingPaySection/BillingPaymentTypeNav';
import { StripCardFields } from '../../../../../components/Custom';
import { required as requiredValidator } from "../../../../../utils/validations";
import { ValidationErrors, PaymentCardSelectField } from '../../../../../components/Custom';
import { billingStripePayNewCard, cleanBillingPay, billingStripePayCard } from '../../../../../store/actions';
import { BILLING_TYPE_CREDITS, BILLING_TYPE_SUBSCRIPTION } from '../../../../../consts/billing/config';

const PAYMENT_TYPE_SELECT_CARD = 'SELECT_CARD';
const PAYMENT_TYPE_NEW_CARD = 'NEW_CARD';

const BillingPayFormComponent = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const history = useHistory();

    const [paymentType, setPaymentType] = useState(PAYMENT_TYPE_SELECT_CARD);

    const [cardCompleteStatus, setCardCompleteStatus] = useState({
        cardNumber: false, cardExpiry: false, cardCvc: false
    });
    useEffect(() => {
        return () => {
            props.cleanBillingPay()
        };
    }, []);

    const isDisabled = useMemo(() => {
        if (paymentType === PAYMENT_TYPE_NEW_CARD) {
            if (!stripe || !elements) {
                return true;
            }

            const isCardFilled = Object.keys(cardCompleteStatus).every((key) => cardCompleteStatus[key] === true);
            if (!isCardFilled) {
                return true;
            }
        }

        return props.isDisabled || props.processing;
    }, [paymentType, stripe, elements, cardCompleteStatus, props.isDisabled, props.processing]);

    const onSubmit = (values) => {
        if (isDisabled) {
            return;
        }

        const { billingType, credits, tariff } = props.priceInfo;
        let payData = {
            billingType,
        };
        payData = billingType === BILLING_TYPE_CREDITS ? { ...payData, credits } : { ...payData, tariffId: tariff?.id };

        if (paymentType === PAYMENT_TYPE_NEW_CARD) {
            props.billingStripePayNewCard(stripe, elements, payData, history);
        } else {
            const payment_method = values.card.value.id;
            props.billingStripePayCard(payment_method, payData, history);
        }
    };

    const handleCardFieldsChange = (e) => {
        setCardCompleteStatus({ ...cardCompleteStatus, [e.elementType]: e.complete });
    };

    return (
        <>
            {props.payError && <ValidationErrors data={props.payError} />}

            <form
                onSubmit={props.handleSubmit(onSubmit)}
                className="form-horizontal"
                noValidate={true}
            >
                <BillingPaymentTypeNav
                    data={[
                        { label: 'your_cards', value: PAYMENT_TYPE_SELECT_CARD },
                        { label: 'new_card', value: PAYMENT_TYPE_NEW_CARD },
                    ]}
                    active={paymentType}
                    onChange={value => setPaymentType(value)}
                />

                <TabContent
                    activeTab={paymentType}
                    className="mt-4"
                >
                    <TabPane tabId={PAYMENT_TYPE_SELECT_CARD} id={PAYMENT_TYPE_SELECT_CARD}>
                        <PaymentCardSelectField
                            name="card"
                            label={props.t('field.label.card')}
                            placeholder={props.t('field.placeholder.select_card')}
                            id={'card'}
                            validate={paymentType === PAYMENT_TYPE_SELECT_CARD ? [requiredValidator] : []}
                            getOptionValue={(option) => `${option.value.id}`}
                            isSearchable={false}
                        />
                    </TabPane>

                    <TabPane tabId={PAYMENT_TYPE_NEW_CARD} id={PAYMENT_TYPE_NEW_CARD}>
                        <StripCardFields
                            handleChange={handleCardFieldsChange}
                        />
                    </TabPane>
                </TabContent>

                <Row className="justify-content-end">
                    <Col lg="10">
                        <div className="button-items">
                            <Button type="submit" color="success" disabled={isDisabled || props.processing}>
                                <ButtonLoadingIcon loading={props.processing} />

                                {props.t('pay')}

                                {props.priceInfo.price && (
                                    <>
                                        {' '}
                                        <NumberFormat
                                            value={props.priceInfo.price}
                                            thousandSeparator={' '}
                                            prefix={'€ '}
                                            displayType={'text'}
                                        />
                                    </>
                                )}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
};

const selector = formValueSelector(FORM_BILLING_PAYMENT);

const BillingPay = reduxForm({
    form: FORM_BILLING_PAYMENT,
    enableReinitialize: true,
})(BillingPayFormComponent);

const mapStateToProps = state => {
    const { processing, payError } = state.billing.pay;

    const cardValue = selector(state, 'card');

    return {
        processing,
        payError,
        cardValue,
    };
};

const mapDispatchToProps = {
    billingStripePayNewCard,
    cleanBillingPay,
    billingStripePayCard,
};

export const BillingPayForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingPay)));