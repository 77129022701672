import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"
import moment from "moment";
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

export const InvoiceCard = (props) => {
    const { id, number, created_at, total } = props.item;

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Col xl="4" sm="6">
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg="12">
                                <div>
                                    <Link to={"/billing/invoices/" + id} className="d-block text-primary text-decoration-underline mb-2">
                                        {t('invoice')} {number}
                                    </Link>
                                    {/*<h5 className="text-truncate mb-4 mb-lg-5"></h5>*/}
                                    <ul className="list-inline mt-lg-5 mb-0">
                                        <li className="list-inline-item mr-3">
                                            <h5 className="font-size-14 d-flex align-items-center">
                                                <i className="bx bx-money mr-1 text-muted" />
                                                <NumberFormat
                                                    value={total}
                                                    thousandSeparator={' '}
                                                    prefix={' € '}
                                                    displayType={'text'}
                                                />
                                            </h5>
                                        </li>
                                        <li className="list-inline-item">
                                            <h5 className="font-size-14 d-flex align-items-center">
                                                <i className="bx bx-calendar mr-1 text-muted" />
                                                {moment(created_at).format('DD/MM/YYYY')}
                                            </h5>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}