export const FETCH_PROPERTY_EDIT_INFO = 'FETCH_PROPERTY_EDIT_INFO';
export const FETCH_PROPERTY_EDIT_INFO_SUCCESS = 'FETCH_PROPERTY_EDIT_INFO_SUCCESS';
export const FETCH_PROPERTY_EDIT_INFO_ERROR = 'FETCH_PROPERTY_EDIT_INFO_ERROR';
export const SET_PROPERTY_COORDINATES = 'SET_PROPERTY_COORDINATES';
export const REMOVE_PROPERTY_REMOTE_IMAGE = 'REMOVE_PROPERTY_REMOTE_IMAGE';
export const CLEAN_PROPERTY_EDIT_PAGE = 'CLEAN_PROPERTY_EDIT_PAGE';

export const EDIT_PROPERTY = 'EDIT_PROPERTY';
export const EDIT_PROPERTY_SUCCESS = 'EDIT_PROPERTY_SUCCESS';
export const EDIT_PROPERTY_ERROR = 'EDIT_PROPERTY_ERROR';

export const REMOVE_PROPERTY = 'REMOVE_PROPERTY';
export const REMOVE_PROPERTY_SUCCESS = 'REMOVE_PROPERTY_SUCCESS';
export const REMOVE_PROPERTY_ERROR = 'REMOVE_PROPERTY_ERROR';