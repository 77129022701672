import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_OFFERS, } from './actionTypes';
import {
    fetchOffersSuccess,
    fetchOffersError,
} from './actions';

import { API } from '../../../api';
import { getMapRequestParams } from '../../../utils/request';

function* fetchOffers({ payload: { params } }) {
    try {
        const includes = ['sender', 'receiver', 'property'];

        const initialParams = {
            includes,
            filter: {
                price_status: ['unconfirmed', 'confirmed', 'declined'],
            }
        };

        const requestParams = getMapRequestParams(params, initialParams);

        const response = yield call(API.invite.getSentCompanyInvites, requestParams);

        const { data } = response;
        yield put(fetchOffersSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchOffersError(message));
    }
}

export function* watchOffersFetch() {
    yield takeEvery(FETCH_OFFERS, fetchOffers)
}

function* offerListsSaga() {
    yield all([
        fork(watchOffersFetch),
    ]);
}

export default offerListsSaga;