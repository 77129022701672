import React, { useEffect, useMemo, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Badge, Spinner } from "reactstrap";
import moment from 'moment';
import _ from 'lodash';

import { Message } from '../Message';
import { fetchChatMessages, cleanChatMessages, chatMarkReceivedMessagesSeen } from '../../../../../../store/actions';
import { usePrevious } from '../../../../../../hooks';
import * as HOC from '../../../../../../HOC';
import { isCloseToTop, scrollToTarget, isCloseToBottom } from "../../../../../../utils/scrollbar";

const DataShower = HOC.withDataShower();

const MessageListComponent = (props) => {
    let { parentRef } = props;

    const prevLoading = usePrevious(props.loading);
    const prevIsMoreLoading = usePrevious(props.isMoreLoading);
    const prevMessages = usePrevious(props.list);

    const messagesRef = useRef([]);

    useEffect(() => {
        fetchData({});

        return () => {
            props.cleanChatMessages();
        }
    }, [props.participantId]);

    useEffect(() => {
        // if app set in background
        if (!props.appIsVisible) {
            // mark received messages seen
            props.chatMarkReceivedMessagesSeen();
        }

        return () => {
        }
    }, [props.appIsVisible]);

    const fetchData = (params, data) => {
        props.fetchChatMessages(props.participantId, params, data);
    };

    // save scroll position before messages rerender
    const scrollPosition = useMemo(() => {
        if (props.list.length > 0) {
            return {
                offsetTop: parentRef.current.scrollTop,
                offsetBottom: parentRef.current.scrollHeight - parentRef.current.scrollTop,
                isCloseToBottom: isCloseToBottom(parentRef.current, 100),
            }
        }

        return null;
    }, [props.list]);

    useEffect(() => {
        if (props.list.length > 0) {

            if (prevLoading === true) {
                const list = [...props.list].reverse();

                // if has unread message => scroll to unread
                let unreadMessage = _.find(list, { is_my: false, seen: false });
                if (unreadMessage && messagesRef.current[unreadMessage.id]) {
                    scrollToTarget(
                        messagesRef.current[unreadMessage.id],
                        parentRef.current,
                    );
                } else {
                    props.scrollToBottom();
                }

                return;
            }

            if (prevIsMoreLoading === true) {
                if (scrollPosition) {
                    scrollToTarget(
                        parentRef.current.scrollHeight - scrollPosition.offsetBottom,
                        parentRef.current,
                    );
                }

                return;
            }

            // scroll to bottom if receive new message and prev position was in bottom
            if (props.list.length !== prevMessages.length) {
                const previousLastMessage = prevMessages[0];
                const currentLastMessage = props.list[0];

                if (previousLastMessage && previousLastMessage.id !== currentLastMessage.id && currentLastMessage.is_my === false) {
                    if (scrollPosition && scrollPosition.isCloseToBottom) {
                        props.scrollToBottom();
                    }
                }
            }
        }
    }, [props.list]);

    const loadMore = () => {
        const { meta } = props;

        if (!meta) {
            return;
        }

        const { current_first_id, chat_first_id } = meta;

        if (current_first_id && chat_first_id && current_first_id !== chat_first_id && !props.loading && !props.isMoreLoading) {
            fetchData({ last_message_id: meta.current_first_id }, { isMoreLoading: true });
        }
    };

    const onScroll = (e) => {
        if (isCloseToTop(e, 300)) {
            loadMore();
        }
    };

    const renderMessages = () => {
        if (messagesRef.current) {
            messagesRef.current = [];
        }

        const list = [...props.list].reverse();

        let messages = [];

        for (let i = 0; i < list.length; i++) {
            let message = list[i];

            let prevMessage = list[i - 1];

            let withDay = true;
            if (prevMessage) {
                // check if prev message has day separator
                withDay = !moment(prevMessage.created_at).isSame(moment(message.created_at), 'day');
            }

            let withUnread = !message.is_my && !message.seen; // if message not mine and user not seen the message
            if (prevMessage && withUnread) {
                // check if prev message has unread separator
                if (!prevMessage.is_my) {
                    withUnread = prevMessage.seen;
                }
            }

            messages.push((
                <Message item={message} key={`message-${message.id}`} withDay={withDay} withUnread={withUnread} forwardRef={el => messagesRef.current[message.id] = el} />
            ));
        }

        return messages.map(item => item);
    };

    return (
        <div className="chat-conversation p-3">
            <PerfectScrollbar
                style={{ height: "470px" }}
                containerRef={ref => parentRef.current = ref}
                onScrollY={onScroll}
            >
                <DataShower
                    isLoading={props.loading}
                    customLoaderClass={'data-shower-loader-center'}
                >
                    {props.isMoreLoading && (
                        <div className={'text-center mt-2 mb-4'}>
                            <Spinner size={'sm'} color="primary" />
                        </div>
                    )}

                    {props.list.length > 0 ? (
                        <ul className="list-unstyled">
                            {renderMessages()}
                        </ul>
                    ) : (
                        <div className={'h-100 d-flex align-items-center justify-content-center'}>
                            <Badge pill className={'font-size-12 badge-soft-secondary'}>
                                {props.t('no_messages')}
                            </Badge>
                        </div>
                    )}
                </DataShower>
            </PerfectScrollbar>
        </div>
    );
};

const mapStateToProps = state => {
    const { participant } = state.chat.conversation.data;
    const { list, loading, isMoreLoading, meta } = state.chat.conversation.messages;
    return {
        participantId: participant?.id,
        list, loading, isMoreLoading, meta,
        appIsVisible: state.common.appIsVisible,
    };
};

const mapDispatchToProps = {
    fetchChatMessages,
    cleanChatMessages,
    chatMarkReceivedMessagesSeen,
};

export const MessageList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MessageListComponent)));