import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_RECEIVED_INVITE_LIST, FETCH_RECEIVED_ACTIVE_INVITE_LIST, FETCH_RECEIVED_INACTIVE_INVITE_LIST, FETCH_PROPERTY_INVITES, } from './actionTypes';
import {
    fetchReceivedInviteListSuccess,
    fetchReceivedInviteListError,
    fetchReceivedActiveInviteListSuccess,
    fetchReceivedActiveInviteListError,
    fetchReceivedInactiveInviteListSuccess,
    fetchReceivedInactiveInviteListError,
    fetchPropertyInvitesSuccess,
    fetchPropertyInvitesError,
} from './actions';

import { API } from '../../../api';
import { getMapRequestParams } from '../../../utils/request';

function* fetchReceivedInviteList() {
    try {
        const includes = ['sender', 'property', 'property.project', 'property.city']; // 'receiver', 'property.images'

        const response = yield call(API.invite.getInviteReceivedList, { includes });

        const { data } = response;
        yield put(fetchReceivedInviteListSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchReceivedInviteListError(message));
    }
}

function* fetchReceivedActiveInviteList() {
    try {
        const includes = ['sender', 'sender.company', 'property', 'property.project', 'property.city']; // 'receiver', 'property.images'
        const filter = {
            status: ['unconfirmed', 'active'],
        };

        const response = yield call(API.invite.getInviteReceivedList, { includes, filter, });

        const { data } = response;
        yield put(fetchReceivedActiveInviteListSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchReceivedActiveInviteListError(message));
    }
}

function* fetchReceivedInactiveInviteList() {
    try {
        const includes = ['sender', 'sender.company', 'property', 'property.project', 'property.city']; // 'receiver', 'property.images'
        const filter = {
            status: ['declined', 'closed', 'frozen', 'deactivated'],
        };

        const response = yield call(API.invite.getInviteReceivedList, { includes, filter });

        const { data } = response;
        yield put(fetchReceivedInactiveInviteListSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchReceivedInactiveInviteListError(message));
    }
}

function* fetchPropertyInvites({ payload: { id, params } }) {
    try {
        let requestParams = {
            includes: ['receiver'],
        };

        requestParams = getMapRequestParams(params, requestParams);

        const response = yield call(API.invite.getPropertyInvites, id, requestParams);

        const { data } = response;
        yield put(fetchPropertyInvitesSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchPropertyInvitesError(message));
    }
}

export function* watchReceivedListFetch() {
    yield takeEvery(FETCH_RECEIVED_INVITE_LIST, fetchReceivedInviteList)
}

export function* watchReceivedActiveListFetch() {
    yield takeEvery(FETCH_RECEIVED_ACTIVE_INVITE_LIST, fetchReceivedActiveInviteList)
}

export function* watchReceivedInactiveListFetch() {
    yield takeEvery(FETCH_RECEIVED_INACTIVE_INVITE_LIST, fetchReceivedInactiveInviteList)
}

export function* watchPropertyInvitesFetch() {
    yield takeEvery(FETCH_PROPERTY_INVITES, fetchPropertyInvites)
}

function* inviteListsSaga() {
    yield all([
        fork(watchReceivedListFetch),
        fork(watchReceivedActiveListFetch),
        fork(watchReceivedInactiveListFetch),
        fork(watchPropertyInvitesFetch),
    ]);
}

export default inviteListsSaga;