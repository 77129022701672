import { all } from 'redux-saga/effects';

import listSaga from './list/saga';
import suggestSaga from './suggest/saga';

function* propertySaga() {
    yield all([
        listSaga(),
        suggestSaga(),
    ])
}

export default propertySaga;