import React, { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import { Card, Row, Col, Spinner } from "reactstrap";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { removePhoto, removePhotoSuccess, removePhotoError } from '../../../store/upload/property/actions';
import { removePropertyRemoteImage } from '../../../store/property/actions';
import { API } from '../../../api';
import { ValidationErrors } from '../../../components/Custom/ValidationErrors';

const PhotoItemComponent = ({
    item,
    removePhoto,
    removePhotoSuccess,
    removePhotoError,
    removePropertyRemoteImage,
}) => {
    const { t } = useTranslation();

    const [localState, setLocalState] = useState({
        loading: false,
        error: null,
    });

    const requestCancelToken = useRef(null);

    const cancelRequest = () => {
        if (requestCancelToken.current) {
            requestCancelToken.current.cancel("Request canceled.");
        }
    };

    const deletePhoto = async (e) => {
        e.preventDefault();

        removePhoto(item.id);

        setLocalState({
            loading: true,
            error: null,
        });

        cancelRequest();

        requestCancelToken.current = axios.CancelToken.source();

        try {
            const response = await API.property.removeImage(item.id, { cancelToken: requestCancelToken.current.token });

            const { data } = response;

            removePhotoSuccess(item.id);
            removePropertyRemoteImage(item.id);

        } catch (error) {
            removePhotoError(item.id);

            if (axios.isCancel(error)) {
                console.log(error.message);
            } else {
                requestCancelToken.current = null;

                const errors = error.response?.data?.errors ? error.response.data.errors : {};
                const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

                setLocalState({
                    ...localState,
                    loading: false,
                    error: {
                        errors,
                        message
                    },
                });
            }
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        cancelRequest()
    };

    useEffect(() => {
        return () => {
            cancelRequest();
        }
    }, []);

    return (
        <Col md={6} lg={6} xl={3}>
            <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
            >
                <div className="p-2">
                    <Row className="align-items-center">
                        <Col className="col-auto">
                            <div className="avatar-select-wrap">
                                <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light avatar-cover"
                                    alt={'photo'}
                                    src={item.src}
                                />

                                <div className={'avatar-loading'} style={{ display: localState.loading ? 'flex' : 'none' }}>
                                    <Spinner size={'sm'} color="primary" />
                                </div>
                            </div>
                        </Col>
                        <div className={'pl-3 pr-4'}>
                            {localState.loading ? (
                                <Link to="#" className="text-primary" onClick={handleCancel}>{t('cancel')}</Link>
                            ) : !localState.loading ? (
                                <Link to="#" className="text-primary" onClick={deletePhoto}>{t('remove')}</Link>
                            ) : null}
                        </div>
                    </Row>

                    {localState.error ? <ValidationErrors data={localState.error} /> : null}
                </div>
            </Card>
        </Col>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
    removePhoto,
    removePhotoSuccess,
    removePhotoError,
    removePropertyRemoteImage,
};

export const PhotoItem = connect(mapStateToProps, mapDispatchToProps)(PhotoItemComponent);