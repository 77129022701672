import {
    REFERAL_STATUS_ACTIVATED,
    REFERAL_STATUS_UNACTIVATED,
} from '../../consts/refferal';

export const getReferralStatusOptions = (t) => {
    return [{
        label: t('referral.status.activated'),
        value: true,
    }, {
        label: t('offer.status.unactivated'),
        value: false,
    }];
};

export const getReferralStatusConfig = (status, t) => {
    let data = {
        label: '',
        color: '',
        value: '',
    };

    if (status) {
        data = {
            label: t('referral.status.activated'),
            value: true,
            color: 'success',
        };
    } else {
        data = {
            label: t('referral.status.unactivated'),
            value: false,
            color: 'secondary',
        };
    }
    return data;
};