import React, { useEffect, useMemo } from 'react';
import { Row, Card, CardHeader, CardBody, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import {
    fetchReceivedActivePropertyList,
    cleanReceivedActivePropertyList,
} from '../../../../../store/actions';
import { PropertyOverviewTable } from '../../../../../components/Custom';

import * as HOC from '../../../../../HOC';
const DataShower = HOC.withDataShower();

const InvitedPropertyComponent = (props) => {

    useEffect(() => {
        let params = {
            page: 1,
            per_page: 5,
        };

        props.fetchReceivedActivePropertyList(params);

        return () => {
            props.cleanReceivedActivePropertyList();
        };
    }, []);

    const emptyAlert = useMemo(() => {
        return !props.listLoading && props.list.length === 0;
    }, [props.listLoading, props.list]);

    return (
        <Card>
            <CardHeader className="bg-transparent">
                <Row className="no-gutters justify-content-between">
                    <h5 className={'mt-2 mr-2'}>{props.t('invites.active.your.list')}</h5>

                    <Link to={`/properties/invites`} className="align-self-center text-secondary">
                        {props.t('show_all')}
                    </Link>
                </Row>
            </CardHeader>
            <CardBody>
                <DataShower
                    isLoading={props.listLoading}
                    isFailed={props.error}
                    error={props.t(props.error)}
                    customLoaderClass={'data-shower-loader-center'}
                >
                    {emptyAlert ? (
                        <Alert color="primary">{props.t('active_invites_list.empty')}</Alert>
                    ) : (
                        <PropertyOverviewTable
                            items={props.list}
                            tableHeaderStatus={false}
                        />
                    )}
                </DataShower>

                <div className={'d-flex justify-content-end mt-2'}>
                    <Link to={`/property-requests/create`} className="btn btn-primary">
                        {props.t('search_house_new')}
                    </Link>
                </div>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    const {
        receivedActive, receivedActiveLoading, receivedActiveError,
    } = state.property.invites;

    return {
        user: data,
        list: receivedActive,
        listLoading: receivedActiveLoading,
        error: receivedActiveError,
    };
};

const mapDispatchToProps = {
    fetchReceivedActivePropertyList,
    cleanReceivedActivePropertyList,
};

export const InvitedProperty = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvitedPropertyComponent)));