import { takeEvery, takeLatest, fork, put, all, call } from 'redux-saga/effects';
import toastr from "toastr";

import { FETCH_PROPERTY, PROPERTY_VIEW_SEND_FAVORITE_STATUS } from './actionTypes';
import {
    fetchPropertySuccess,
    fetchPropertyError,
    propertyViewUpdateFavoriteStatus,
} from './actions';

import { sendInviteSeenStatus } from '../../actions';

import { API } from '../../../api';
import i18n from "../../../i18n";
import { isPropertyNewStatus } from "../../../helpers";

function* fetchProperty({ payload: { id } }) {
    try {
        const includes = ['project', 'city', 'city.region', 'images', 'myReceivedLink', 'myReceivedLink.sender', 'myReceivedLink.sender.company', 'myReceivedLink.lead', 'crmProperty.crmSystemAccount.crmSystem']; // 'myReceivedLink.receiver', 'mySentLinks'

        const response = yield call(API.property.getProperty, id, { includes });

        const { data } = response;

        const property = data?.data;

        yield put(fetchPropertySuccess(property));

        const isNew = isPropertyNewStatus(property);
        if (isNew) {
            const inviteId = property.myReceivedLink.id;
            yield put(sendInviteSeenStatus(inviteId));
        }

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchPropertyError(message));
    }
}

function* propertySendFavoriteStatus({ payload: { id, params } }) {
    const { status } = params;

    yield put(propertyViewUpdateFavoriteStatus(status));

    try {
        const response = yield call(API.property.sendFavoriteStatus, id, params);

        const { data } = response;

        let message = status ? 'property.favorite.add' : 'property.favorite.remove';

        toastr.success(i18n.t(message));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        toastr.error(message);

        yield put(propertyViewUpdateFavoriteStatus(!status));
    }
}

export function* watchPropertyFetch() {
    yield takeEvery(FETCH_PROPERTY, fetchProperty)
}

export function* watchSendFavoriteStatus() {
    yield takeLatest(PROPERTY_VIEW_SEND_FAVORITE_STATUS, propertySendFavoriteStatus)
}

function* viewSaga() {
    yield all([
        fork(watchPropertyFetch),
        fork(watchSendFavoriteStatus),
    ]);
}

export default viewSaga;