import React from 'react';
import { Modal, ModalHeader, ModalBody, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { OfferUpdateForm } from './OfferUpdateForm';
import { sendOffer, cleanSendOffer } from '../../../../../store/actions'

const OfferUpdateComponent = (props) => {
    const { visible, toggleVisible, onClose, invite, propertyPrice, propertyId } = props;

    const onSubmit = (values) => {
        const params = {
            ...values,
            property: propertyId,
        };

        props.sendOffer(invite.id, params, onClose);
    };

    const onClosed = () => {
        props.cleanSendOffer();
    };

    return (
        <Modal
            size="lg"
            isOpen={visible}
            toggle={toggleVisible}
            onClosed={onClosed}
        >
            <ModalHeader
                toggle={toggleVisible}
            >
                {props.t('edit')}
            </ModalHeader>

            <ModalBody>
                {props.error ? <Alert color="danger">{props.error.message}</Alert> : null}

                <OfferUpdateForm
                    initialValues={{
                        price: invite?.price,
                    }}
                    onSubmit={onSubmit}
                    propertyPrice={propertyPrice}
                    offerStatus={invite?.price_status}
                    currentOfferPrice={invite?.price}
                    isLoading={props.loading}
                />
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = state => {
    const { offerLoading, offerError } = state.invite.reaction;
    return {
        loading: offerLoading,
        error: offerError,
    };
};

const mapDispatchToProps = {
    sendOffer,
    cleanSendOffer,
};

export const OfferUpdate = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OfferUpdateComponent));