import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import toastr from "toastr";

import i18n from '../../../i18n';

import { ACTIVATE_ACCOUNT } from './actionTypes';
import { activateAccountSuccess, activateAccountError } from './actions';
import { loginSuccess } from '../login/actions';

import { FORM_ACCOUNT_ACTIVATE } from '../../../consts/forms';

import { API } from '../../../api'

function* activateAccount({ payload: { id, hash, params, formData, history } }) {
    try {
        const response = yield call(API.auth.activate, id, hash, params, formData);

        const { data } = response;

        yield put(activateAccountSuccess(data));
        yield put(loginSuccess(data));

        history.push('/dashboard');

        toastr.success(i18n.t(`account.activate.success`));

    } catch (error) {
        const errors = error.response.data.errors;
        const message = error.response.data.message ? error.response.data.message : 'Server Error';
        yield put(activateAccountError(message));

        if (errors) {
            yield put(stopSubmit(FORM_ACCOUNT_ACTIVATE, errors));
        }
    }
}

export function* watchActivateAccount() {
    yield takeEvery(ACTIVATE_ACCOUNT, activateAccount);
}

function* accountActivationSaga() {
    yield all([fork(watchActivateAccount)]);
}

export default accountActivationSaga;