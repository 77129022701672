import {
    CREATE_PROPERTY,
    CREATE_PROPERTY_SUCCESS,
    CREATE_PROPERTY_ERROR,
    CLEAN_CREATE_PROPERTY_PAGE,

    CREATE_PROPERTY_SET_VALUES,
} from './actionTypes';

// CREATE PROPERTY
export const createProperty = (params, history) => {
    return {
        type: CREATE_PROPERTY,
        payload: { params, history },
    }
};

export const createPropertySuccess = (data) => {
    return {
        type: CREATE_PROPERTY_SUCCESS,
        payload: data,
    }
};

export const createPropertyError = (data) => {
    return {
        type: CREATE_PROPERTY_ERROR,
        payload: data,
    }
};

export const cleanCreatePropertyPage = () => {
    return { type: CLEAN_CREATE_PROPERTY_PAGE };
};

// PROPERTY SET FORM VALUE
export const propertySetFormValue = (data) => {
    return {
        type: CREATE_PROPERTY_SET_VALUES,
        payload: data,
    }
};