import {
    EDIT_BILLING_INFO,
    EDIT_BILLING_INFO_SUCCESS,
    EDIT_BILLING_INFO_ERROR,
    CLEAN_BILLING_INFO_EDIT,
} from './actionTypes';

export const editBillingInfo = (params, callback) => {
    return {
        type: EDIT_BILLING_INFO,
        payload: { params, callback },
    }
};

export const editBillingInfoSuccess = (data) => {
    return {
        type: EDIT_BILLING_INFO_SUCCESS,
        payload: data,
    }
};

export const editBillingInfoError = (data) => {
    return {
        type: EDIT_BILLING_INFO_ERROR,
        payload: data,
    }
};

export const cleanBillingInfoEdit = () => {
    return { type: CLEAN_BILLING_INFO_EDIT };
};