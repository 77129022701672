import {
    INVITE_STATUS_UNCONFIRMED,
    INVITE_STATUS_ACTIVE,
    INVITE_STATUS_DECLINED,
    INVITE_STATUS_CLOSED,
    INVITE_STATUS_FROZEN,
    INVITE_STATUS_DEACTIVATED,
} from '../../consts/invite';

export const getActiveStatusOptions = (t) => {
    return [{
        label: t('invite.status.unconfirmed'),
        value: INVITE_STATUS_UNCONFIRMED,
    }, {
        label: t('invite.status.active'),
        value: INVITE_STATUS_ACTIVE,
    }];
};

export const getInactiveStatusOptions = (t) => {
    return [{
        label: t('invite.status.declined'),
        value: INVITE_STATUS_DECLINED,
    }, {
        label: t('invite.status.closed'),
        value: INVITE_STATUS_CLOSED,
    }, {
        label: t('invite.status.deactivated'),
        value: INVITE_STATUS_DEACTIVATED,
    }];
};

export const getInviteStatusOptions = (t) => {
    return [
        ...getActiveStatusOptions(t),
        ...getInactiveStatusOptions(t),
    ];
};

export const getInviteStatusConfig = (status, t) => {
    let data = {
        label: '',
        color: '',
    };
    switch (status) {
        case INVITE_STATUS_UNCONFIRMED:
            data = {
                label: t('invite.status.unconfirmed'),
                value: INVITE_STATUS_UNCONFIRMED,
                color: 'warning',
            };
            break;

        case INVITE_STATUS_ACTIVE:
            data = {
                label: t('invite.status.active'),
                value: INVITE_STATUS_ACTIVE,
                color: 'success',
            };
            break;

        case INVITE_STATUS_DECLINED:
            data = {
                label: t('invite.status.declined'),
                value: INVITE_STATUS_DECLINED,
                color: 'secondary',
            };
            break;

        case INVITE_STATUS_CLOSED:
            data = {
                label: t('invite.status.closed'),
                value: INVITE_STATUS_CLOSED,
                color: 'secondary',
            };
            break;

        case INVITE_STATUS_FROZEN:
            data = {
                label: t('invite.status.frozen'),
                value: INVITE_STATUS_FROZEN,
                color: 'primary',
            };
            break;

        case INVITE_STATUS_DEACTIVATED:
            data = {
                label: t('invite.status.deactivated'),
                value: INVITE_STATUS_DEACTIVATED,
                color: 'danger',
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};