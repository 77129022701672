import {all} from 'redux-saga/effects';

import startSaga from './start/saga';

function* syncSaga() {
  yield all([
    startSaga(),
  ])
}

export default syncSaga;