import axios from 'axios';

import {paramsSerializer} from "../../utils/request"

class CrmSystemAPI {
  getCrmSystems = async (params) => {
    return axios.get('api/crm-systems', {
      params,
      paramsSerializer: () => paramsSerializer(params),
    });
  };

  createAccount = async (id, params) => {
    return axios.post(`api/crm-systems/${id}`, params);
  };

  deleteAccount = async (id) => {
    return axios.delete(`api/crm-systems/${id}`);
  };

  startSync = async (id) => {
    return axios.post(`api/crm-systems/${id}/sync`);
  };
}

export const crmSystem = new CrmSystemAPI();
