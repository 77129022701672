import {takeLatest, fork, put, all, call} from 'redux-saga/effects';

import {CRM_SYSTEM_LIST_GET_DATA} from './actionTypes';
import {
  crmSystemListGetDataSuccess,
  crmSystemListGetDataError
} from './actions';

import {API} from 'api';

function* getData({payload: {params}}) {
  try {
    const response = yield call(API.crmSystem.getCrmSystems, params);

    const {data} = response;

    yield put(crmSystemListGetDataSuccess(data));

  } catch (error) {
    const message = error.response?.data?.message || 'Server Error';
    yield put(crmSystemListGetDataError(message));
  }
}


export function* watchGetData() {
  yield takeLatest(CRM_SYSTEM_LIST_GET_DATA, getData)
}

function* listSaga() {
  yield all([
    fork(watchGetData),
  ]);
}

export default listSaga;