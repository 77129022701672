import {
    FETCH_PROPERTY_LIST,
    FETCH_PROPERTY_LIST_SUCCESS,
    FETCH_PROPERTY_LIST_ERROR,
    CLEAN_PROPERTY_LIST_PAGE,
    PROPERTY_SET_FILTER,
    PROPERTY_RESET_FILTER,
    PROPERTY_SET_SORT,
    PROPERTY_RESET_SORT,
    PROPERTY_SET_PAGE,
    PROPERTY_LIST_UPDATE_ITEM, TRIGGER_FETCH_PROPERTY_LIST,
} from './actionTypes';

import { PROPERTY_FILTER_INITIAL_VALUES } from '../../../consts/property';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    page: 1,
    meta: null,

    filter: PROPERTY_FILTER_INITIAL_VALUES,
    filterIsActive: false,
    sort: [],
};

function updateObjectInArray(array, payload) {
    return array.map((item, index) => {
        if (item.id !== payload.id) {
            return item
        }

        return {
            ...item,
            ...payload.data,
        }
    })
}

const property = (state = initialState, action) => {
    switch (action.type) {

        // PROPERTY LIST
        case FETCH_PROPERTY_LIST:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case FETCH_PROPERTY_LIST_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case FETCH_PROPERTY_LIST_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case CLEAN_PROPERTY_LIST_PAGE:
            state = {
                ...state,
                list: [],
                listLoading: false,
                listError: null,
                page: initialState.page,
                meta: null,
            };
            break;


        // PROPERTY FILTER
        case PROPERTY_SET_FILTER:
            state = {
                ...state,
                filter: {
                    ...initialState.filter,
                    ...action.payload,
                },
                filterIsActive: true,
                page: initialState.page,
            };
            break;

        case PROPERTY_RESET_FILTER:
            state = {
                ...state,
                filter: initialState.filter,
                filterIsActive: false,
                page: initialState.page,
            };
            break;


        // PROPERTY SORT
        case PROPERTY_SET_SORT:
            state = {
                ...state,
                sort: action.payload,
                page: initialState.page,
            };
            break;

        case PROPERTY_RESET_SORT:
            state = {
                ...state,
                sort: initialState.sort,
                page: initialState.page,
            };
            break;

        // PROPERTY SET PAGE
        case PROPERTY_SET_PAGE:
            state = {
                ...state,
                page: action.payload,
            };
            break;


        // UPDATE ITEM IN LIST
        case PROPERTY_LIST_UPDATE_ITEM:
            state = {
                ...state,
                list: [...updateObjectInArray([...state.list], action.payload)],
            };
            break;
        case TRIGGER_FETCH_PROPERTY_LIST: {
            state = {
                ...state,
                page: {...state.page}
            }
            break;
        }
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default property;