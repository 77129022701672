import React, { useEffect } from 'react';
import { Row, Col, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

import { ProfileMainInfo, ConsultantInvitesChartBar, PageTitle } from '../../../../components/Custom';
import { ROLE_CLIENT, ROLE_CONSULTANT, ROLE_MANAGER } from '../../../../consts/roles';
import { DashboardDataLayout, TopFavoriteProperties } from '../../components';
import { middlewareHelper } from '../../../../helpers';

const DashboardStaffComponent = (props) => {

    useEffect(() => {
    }, []);

    const getCompany = () => {
        return props.user.company ? props.user.company.name : null;
    };

    if (props.user?.role === ROLE_CONSULTANT && !middlewareHelper.subscriptionMiddleware.isAllow(props.user)) {
        return <Alert color={'warning'}>{props.t('subscription.required.consultant_warning')}</Alert>;
    } else if (props.user?.role === ROLE_MANAGER && !middlewareHelper.subscriptionMiddleware.isAllow(props.user)) {
        return <Alert color={'warning'}>{props.t('subscription.required.manager_warning')}</Alert>;
    } else {
        return <Redirect to={"/properties"} />
    }

    // return (
    //     <React.Fragment>
    //         <PageTitle title={props.t('dashboard')}/>
    //
    //         <div className={'profile-info-container'}>
    //             <Row>
    //                 <Col xl="4">
    //                     <ProfileMainInfo
    //                         title={'details'}
    //                         name={props.user.name}
    //                         avatar={props.user.avatar}
    //                         date={props.user.created_at}
    //                         role={props.user.role === ROLE_MANAGER ? 'manager' : props.user.role === ROLE_CONSULTANT ? 'consultant' : null}
    //                         company={getCompany()}
    //                     />
    //                 </Col>
    //
    //                 <Col xl="8">
    //                     {props.user.role === ROLE_CONSULTANT && (
    //                         <ConsultantInvitesChartBar />
    //                     )}
    //                 </Col>
    //             </Row>
    //         </div>
    //
    //         {props.user.role && props.user.role !== ROLE_CLIENT && (
    //             <DashboardDataLayout>
    //                 {props.user.role && props.user.role !== ROLE_CLIENT && (
    //                     <TopFavoriteProperties/>
    //                 )}
    //             </DashboardDataLayout>
    //         )}
    //     </React.Fragment>
    // );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

const mapDispatchToProps = {
};

export const DashboardStaff = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardStaffComponent)));