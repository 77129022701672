import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_PROPERTY_REQUEST_LIST } from './actionTypes';
import {
    fetchPropertyRequestListSuccess,
    fetchPropertyRequestListError,
} from './actions';

import { API } from '../../../api';
import { getMapRequestParams } from '../../../utils/request';

function* fetchPropertyList({ payload: { params } }) {
    try {
        const includes = ['cities', 'regions'];

        let requestParams = {
            includes,
        };

        requestParams = getMapRequestParams(params, requestParams);

        const response = yield call(API.propertyRequest.getPropertyRequestList, requestParams);

        const { data } = response;
        yield put(fetchPropertyRequestListSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchPropertyRequestListError(message));
    }
}

export function* watchPropertyRequestListFetch() {
    yield takeLatest(FETCH_PROPERTY_REQUEST_LIST, fetchPropertyList)
}

function* listSaga() {
    yield all([
        fork(watchPropertyRequestListFetch),
    ]);
}

export default listSaga;