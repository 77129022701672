import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_CLIENT_DASHBOARD_STATISTICS } from './actionTypes';
import { fetchClientDashboardStatisticsSuccess, fetchClientDashboardStatisticsError } from './actions';

import { API } from '../../../api';

function* fetchStatistics() {
    try {
        const response = yield call(API.statistics.getClientDashboardStatistics);

        const { data } = response;
        yield put(fetchClientDashboardStatisticsSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchClientDashboardStatisticsError(message));
    }
}

export function* watchStatisticsFetch() {
    yield takeEvery(FETCH_CLIENT_DASHBOARD_STATISTICS, fetchStatistics)
}

function* clientSaga() {
    yield all([
        fork(watchStatisticsFetch),
    ]);
}

export default clientSaga;