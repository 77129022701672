import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from 'classnames'

import { ROLE_CONSULTANT } from '../../../../../../consts/roles';
import { CHAT_TABS } from "../../../../../../consts/chat";
import { setChatActiveTab } from "../../../../../../store/actions";

const TabNavComponent = (props) => {

    useEffect(() => {
        return () => {
            props.setChatActiveTab(CHAT_TABS.CHAT)
        };
    }, []);

    return (
        <Nav pills justified>
            <NavItem>
                <NavLink
                    className={classNames({
                        active: props.activeTab === CHAT_TABS.CHAT,
                    })}
                    onClick={() => {
                        props.setChatActiveTab(CHAT_TABS.CHAT);
                    }}
                >
                    <i className="bx bx-chat font-size-20 d-sm-none"/>
                    <span className="d-none d-sm-block">{props.t('chat')}</span>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classNames({
                        active: props.activeTab === CHAT_TABS.CONTACTS,
                    })}
                    onClick={() => {
                        props.setChatActiveTab(CHAT_TABS.CONTACTS);
                    }}
                >
                    <i className="bx bx-book-content font-size-20 d-sm-none"/>
                    <span className="d-none d-sm-block">
                        {props.t(props.user.role === ROLE_CONSULTANT ? 'clients' : 'contacts')}
                    </span>
                </NavLink>
            </NavItem>
        </Nav>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    const { activeTab } = state.chat.tabs
    return {
        user: data,
        activeTab
    };
};

const mapDispatchToProps = {
    setChatActiveTab
};

export const TabNav = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TabNavComponent)));