import { all } from 'redux-saga/effects';

import listSaga from './list/saga';
import viewSaga from './view/saga';
import createSaga from './create/saga';
import editSaga from './edit/saga';
import receivedSaga from './invites/saga';
import clientsSaga from './clients/sagas';
import parserSaga from './parser/saga';
import clientPropertiesSaga from './clientProperties/sagas';
import generalSaga from './general/saga';
import favoriteSaga from './favorite/sagas';
import importSaga from './import/saga';

function* propertySaga() {
    yield all([
        listSaga(),
        viewSaga(),
        createSaga(),
        editSaga(),
        receivedSaga(),
        clientsSaga(),
        parserSaga(),
        clientPropertiesSaga(),
        generalSaga(),
        favoriteSaga(),
        importSaga()
    ])
}

export default propertySaga;