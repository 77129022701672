import { all } from 'redux-saga/effects';

import listSaga from './list/saga';
import viewSaga from './view/saga';
import createSaga from './create/saga';
import reactionSaga from './reaction/saga';
import suggestedPropertySaga from './suggestedProperty/sagas';
import availableSaga from './available/saga';

function* propertyRequestSaga() {
    yield all([
        listSaga(),
        viewSaga(),
        createSaga(),
        reactionSaga(),
        suggestedPropertySaga(),
        availableSaga(),
    ])
}

export default propertyRequestSaga;