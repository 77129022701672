import React, { useEffect } from 'react';
import { Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import './CreditsBuy.scss';

import { CreditsBuyForm } from './CreditsBuyForm';
import { BILLING_TYPE_CREDITS } from '../../../../../../consts/billing/config';

const CreditsBuyComponent = (props) => {
    const history = useHistory();

    useEffect(() => {
        return () => {
        };
    }, []);

    const onSubmit = (values, price) => {
        history.push('/billing/pay', {
            priceInfo: {
                price: price,
                credits: values.credits,
                billingType: BILLING_TYPE_CREDITS,
            }
        });
    };

    return (
        <Row>
            <Col lg="12">
                <CreditsBuyForm
                    onSubmit={onSubmit}
                />
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const CreditsBuy = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreditsBuyComponent)));