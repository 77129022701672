import {
    FETCH_CLIENT_DASHBOARD_STATISTICS,
    FETCH_CLIENT_DASHBOARD_STATISTICS_SUCCESS,
    FETCH_CLIENT_DASHBOARD_STATISTICS_ERROR,
    CLEAN_CLIENT_DASHBOARD_STATISTICS
} from './actionTypes';

export const fetchClientDashboardStatistics = () => {
    return {
        type: FETCH_CLIENT_DASHBOARD_STATISTICS,
    }
};

export const fetchClientDashboardStatisticsSuccess = (data) => {
    return {
        type: FETCH_CLIENT_DASHBOARD_STATISTICS_SUCCESS,
        payload: data,
    }
};

export const fetchClientDashboardStatisticsError = (data) => {
    return {
        type: FETCH_CLIENT_DASHBOARD_STATISTICS_ERROR,
        payload: data,
    }
};

export const cleanClientDashboardStatistics = () => {
    return { type: CLEAN_CLIENT_DASHBOARD_STATISTICS };
};