import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import axios from "axios";
import { Alert } from "reactstrap";
import { stopSubmit, reset } from "redux-form";

import { PropertyParserForm } from './PropertyParserForm';
import { PropertyParserModal } from './PropertyParserModal';
import { API } from "../../../../api";
import { propertySetFormValue } from '../../../../store/actions';
import { PROPERTY_AVAILABLE_PARSE_SITES } from '../../../../consts/property';
import { FORM_PROPERTY_PARSER, FORM_PROPERTY_COMMON } from "../../../../consts/forms";

const PropertyParserComponent = (props) => {
    const [modalVisible, setModalVisible] = useState(false);

    const [localState, setLocalState] = useState({
        loading: false,
        error: null,
    });

    const modalRef = useRef(null);
    const requestCancelToken = useRef(null);

    useEffect(() => {

        return () => {
            if (modalVisible) {
                cancelRequest();
            }
        }
    }, [modalVisible]);

    // refactoring: make saga
    const parseProperty = async (params) => {
        setLocalState({
            loading: true,
            error: null,
        });

        try {
            cancelRequest();

            requestCancelToken.current = axios.CancelToken.source();

            const response = await API.property.parseProperty(params, { cancelToken: requestCancelToken.current.token });

            const { data } = response;

            requestCancelToken.current = null;

            // reset create property form
            props.reset(FORM_PROPERTY_COMMON);

            // set values to create property form
            props.propertySetFormValue(data.data);

            hideModal();

            setLocalState({
                loading: false,
                error: null,
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                setLocalState({
                    loading: false,
                    error: null,
                });
            } else {
                requestCancelToken.current = null;

                const errors = error.response?.data?.errors ? error.response.data.errors : {};
                const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

                setLocalState({
                    ...localState,
                    loading: false,
                    error: {
                        errors,
                        message
                    },
                });

                props.stopSubmit(FORM_PROPERTY_PARSER, errors);
            }
        }
    };

    const onSubmit = async (values) => {
        if (localState.loading) {
            return;
        }

        let params = {
            ...values,
        };

        parseProperty(params);
    };

    const cancelRequest = () => {
        if (requestCancelToken.current) {
            requestCancelToken.current.cancel("Request canceled.");
            requestCancelToken.current = null;
        }
    };

    const hideModal = () => {
        if (modalRef.current) {
            modalRef.current.hide();
        }
    };

    const handleCancel = () => {
        // stop parse

        hideModal();
    };

    return (
        <PropertyParserModal
            onVisibleChange={(value) => setModalVisible(value)}
            ref={modalRef}
        >
            <p className={'font-size-14 mb-1'}>{props.t('property.parser.hint')}:</p>

            <p className={''}>
                {PROPERTY_AVAILABLE_PARSE_SITES.map((item, index) => (
                    <span key={'site-' + index}>
                        {`· `}
                        <a href={item.url} target={'_blank'}>
                            {item.title}
                        </a>
                        <br/>
                    </span>
                ))}
            </p>

            {localState.error && <Alert color="danger">{localState.error.message}</Alert>}

            <PropertyParserForm
                onSubmit={onSubmit}
                loading={localState.loading}
            />
        </PropertyParserModal>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
    stopSubmit,
    reset,
    propertySetFormValue,
};

export const PropertyParser = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyParserComponent));