import {
    FETCH_USER_LEADS,
    FETCH_USER_LEADS_SUCCESS,
    FETCH_USER_LEADS_ERROR,
    CLEAN_USER_LEADS,
} from './actionTypes';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    meta: null,
};

const userLeads = (state = initialState, action) => {
    switch (action.type) {

        // USER LEAD LIST
        case FETCH_USER_LEADS:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case FETCH_USER_LEADS_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case FETCH_USER_LEADS_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case CLEAN_USER_LEADS:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default userLeads;