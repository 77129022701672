import React from 'react';

export const BlankLayout = (props) => {
    return (
        <React.Fragment>

            {props.children}

        </React.Fragment>
    );
};