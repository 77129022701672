import {
    FETCH_BILLING_INFO,
    FETCH_BILLING_INFO_SUCCESS,
    FETCH_BILLING_INFO_ERROR,
    CLEAN_BILLING_INFO,
    BILLING_INFO_UPDATE_DATA,
} from './actionTypes';

export const fetchBillingInfo = () => {
    return {
        type: FETCH_BILLING_INFO,
    }
};

export const fetchBillingInfoSuccess = (data) => {
    return {
        type: FETCH_BILLING_INFO_SUCCESS,
        payload: data,
    }
};

export const fetchBillingInfoError = (data) => {
    return {
        type: FETCH_BILLING_INFO_ERROR,
        payload: data,
    }
};

export const cleanBillingInfo = () => {
    return { type: CLEAN_BILLING_INFO };
};

export const billingInfoUpdateData = (data) => {
    return {
        type: BILLING_INFO_UPDATE_DATA,
        payload: data,
    }
};