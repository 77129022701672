import React from 'react';
import { Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { ProfileInfoBox } from './ProfileInfoBox';
import { PhoneFormat } from '../PhoneFormat';
import { ROLE_CLIENT } from '../../../consts/roles';
import { ClientInviteStatistics } from './ClientInviteStatistics';

const ClientInfoDetailsComponent = (props) => {
    const { t } = useTranslation();

    const { data } = props;

    return (
        <div className={'profile-info-boxes-container'}>
            <Row className={'profile-info-boxes-row'}>
                <Col md="4">
                    <ProfileInfoBox>
                        <p className="text-muted font-weight-medium">{t('phone')}</p>
                        <h4 className={'mb-0 text-truncate'}>
                            <PhoneFormat value={data.phone} />
                        </h4>
                    </ProfileInfoBox>
                </Col>

                <Col md="4">
                    <ProfileInfoBox>
                        <p className="text-muted font-weight-medium">{t('email')}</p>
                        <h4 className={'mb-0 text-truncate font-size-14'}>{data.email}</h4>
                    </ProfileInfoBox>
                </Col>
            </Row>

            {props.user.role === ROLE_CLIENT && (
                <ClientInviteStatistics />
            )}
        </div>
    );
};

const mapStateToProps = state => {
    const user = state.user.data;
    return {
        user,
    };
};

const mapDispatchToProps = {
};

export const ClientInfoDetails = connect(mapStateToProps, mapDispatchToProps)(ClientInfoDetailsComponent);