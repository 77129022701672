import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { useTitle } from "../../hooks";
import { BackButton, PageTitle } from '../../components/Custom';
import { ActiveInvites, InactiveInvites  } from './components';

const InviteListComponent = (props) => {
    useTitle(props.t('title.invite_propositions'));

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className = {'d-flex align-items-center mb-4'}>
                        <BackButton />
                        <PageTitle title={props.t('title.invite_propositions')} wrapClass={"mb-0 ml-2"} />
                    </div>


                    <Row>
                        <Col lg="12">
                            <ActiveInvites />

                            <InactiveInvites />
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const InviteList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InviteListComponent)));