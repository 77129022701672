import {
    STATUS_FOR_SALE,
    STATUS_ON_HOLD,
    STATUS_SOLD,

    PROPERTY_TYPE_OWNED,
    PROPERTY_TYPE_RECEIVED,
    PROPERTY_TYPE_SUGGESTED,

    PROPERTY_SHOW_STATUS_VISIBLE,
    PROPERTY_SHOW_STATUS_HIDDEN,
} from '../../consts/property';
import { INVITE_STATUS_ACTIVE } from "../../consts/invite";
import {
    CLIENT_PROPERTIES_ACCEPTED_TAB,
    CLIENT_PROPERTIES_ALL_TAB, CLIENT_PROPERTIES_FAVORITE_TAB,
    CLIENT_PROPERTIES_OWNED_TAB
} from "../../consts/property/clientProperties";

export const getPropertyStatusOptions = (t) => {
    return [{
        label: t('for_sale'),
        value: STATUS_FOR_SALE,
    }, {
        label: t('on_hold'),
        value: STATUS_ON_HOLD,
    }, {
        label: t('sold'),
        value: STATUS_SOLD,
    }];
};

export const getPropertyStatusConfig = (status, t) => {
    let data = {
        label: '',
        color: '',
        value: '',
    };
    switch (status) {
        case STATUS_FOR_SALE:
            data = {
                label: t('for_sale'),
                value: STATUS_FOR_SALE,
                color: 'primary',
                background: "primary"
            };
            break;

        case STATUS_ON_HOLD:
            data = {
                label: t('on_hold'),
                value: STATUS_ON_HOLD,
                color: 'warning',
                background: 'warning'
            };
            break;

        case STATUS_SOLD:
            data = {
                label: t('sold'),
                value: STATUS_SOLD,
                color: 'danger',
                background: 'danger'
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};

export const getPropertyOwnerTypeConfig = (status, t) => {
    let data = {
        label: '',
        value: '',
    };
    switch (status) {
        case PROPERTY_TYPE_OWNED:
            data = {
                label: t('property.type.owner'),
                value: PROPERTY_TYPE_OWNED,
            };
            break;

        case PROPERTY_TYPE_RECEIVED:
            data = {
                label: t('property.type.linked'),
                value: PROPERTY_TYPE_RECEIVED,
            };
            break;

        case PROPERTY_TYPE_SUGGESTED:
            data = {
                label: t('property.type.suggested'),
                value: PROPERTY_TYPE_SUGGESTED,
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};

export const getPropertyOwnerTypeOptions = (t) => {
    return [{
        label: t('property.type.owner'),
        value: PROPERTY_TYPE_OWNED,
    }, {
        label: t('property.type.linked'),
        value: PROPERTY_TYPE_RECEIVED,
    }, {
        label: t('property.type.suggested'),
        value: PROPERTY_TYPE_SUGGESTED,
    }];
};

export const getPropertyReceivedTypeConfig = (status, t) => {
    return getPropertyOwnerTypeConfig(status, t);
};

export const getPropertyReceivedTypeOptions = (t) => {
    return [{
        label: t('property.type.linked'),
        value: PROPERTY_TYPE_RECEIVED,
    }, {
        label: t('property.type.suggested'),
        value: PROPERTY_TYPE_SUGGESTED,
    }];
};

export const getPropertyVisibilityOptions = (t) => {
    return [{
        label: t('visible'),
        value: PROPERTY_SHOW_STATUS_VISIBLE,
    }, {
        label: t('hidden'),
        value: PROPERTY_SHOW_STATUS_HIDDEN,
    }];
};

export const getPropertyVisibilityConfig = (status, t) => {
    let data = {
        label: '',
        value: '',
    };
    switch (status) {
        case PROPERTY_SHOW_STATUS_VISIBLE:
            data = {
                label: t('visible'),
                value: PROPERTY_SHOW_STATUS_VISIBLE,
            };
            break;

        case PROPERTY_SHOW_STATUS_HIDDEN:
            data = {
                label: t('hidden'),
                value: PROPERTY_SHOW_STATUS_HIDDEN,
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};

export const isPropertyNewStatus = (property) => {
    if (property && property.myReceivedLink) {
        return property?.myReceivedLink?.status === INVITE_STATUS_ACTIVE && !property.myReceivedLink.seen;
    }
    return false;
};

export const getPropertyStatistics = (statistics, tab) =>{
    switch (tab) {
        case CLIENT_PROPERTIES_ALL_TAB: {
            return statistics?.total
        }
        case CLIENT_PROPERTIES_ACCEPTED_TAB: {
            return  statistics?.suggested
        }
        case CLIENT_PROPERTIES_OWNED_TAB: {
            return  statistics?.my
        }
        case CLIENT_PROPERTIES_FAVORITE_TAB: {
            return statistics?.favorites
        }
        default: {
            return null
        }
    }
}

export const getPropertyFilter = (properties, tab) => {

    switch (tab) {
        case CLIENT_PROPERTIES_ALL_TAB: {
            return properties?.all.filter
        }
        case CLIENT_PROPERTIES_ACCEPTED_TAB: {
            return  properties?.accepted.filter
        }
        case CLIENT_PROPERTIES_OWNED_TAB: {
            return  properties?.owned.filter
        }
        case CLIENT_PROPERTIES_FAVORITE_TAB: {
            return properties?.favorite.filter
        }
        default: {
            return null
        }
    }
}


export const getPropertySearchValue = (properties, tab) => {

    switch (tab) {
        case CLIENT_PROPERTIES_ALL_TAB: {
            return properties?.all.search
        }
        case CLIENT_PROPERTIES_ACCEPTED_TAB: {
            return  properties?.accepted.search
        }
        case CLIENT_PROPERTIES_OWNED_TAB: {
            return  properties?.owned.search
        }
        case CLIENT_PROPERTIES_FAVORITE_TAB: {
            return properties?.favorite.search
        }
        default: {
            return null
        }
    }
}