import React, { useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Alert, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import toastr from 'toastr';

import { leadUpdateStatusDecline, leadUpdateStatusBuy, cleanLeadUpdateStatus } from '../../../../store/actions';
import { LEAD_COST_IN_CREDIT, LEAD_STATUS_UNPAID} from '../../../../consts/lead';

const LeadBuySectionComponent = (props) => {
    useEffect(() => {
        return () => {
            props.cleanLeadUpdateStatus();
        };
    }, []);

    const buy = () => {
        if (props.user?.credits < LEAD_COST_IN_CREDIT) {
            toastr.warning(props.t('buy_lead.not_enough_credits'));
        } else {
            props.leadUpdateStatusBuy(props.lead.id);
        }
    };

    const decline = () => {
        props.leadUpdateStatusDecline(props.lead.id);
    };

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <CardTitle className={'text-center'}>{props.t('buy_lead')}</CardTitle>

                        {props.updateStatusError && props.updateStatusError ? <Alert color="danger">{props.updateStatusError}</Alert> : null}

                        <div className="mt-3 button-items text-center">
                            <Button
                                type="button"
                                color="success"
                                disabled={props.updateStatusLoading}
                                onClick={buy}
                            >
                                {props.t('buy')}
                            </Button>

                            {props.lead.status === LEAD_STATUS_UNPAID && (
                                <Button
                                    type="button"
                                    color="light"
                                    disabled={props.updateStatusLoading}
                                    onClick={decline}
                                >
                                    {props.t('decline')}
                                </Button>
                            )}
                        </div>

                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const { lead } = state.lead.view;
    const { updateStatusLoading, updateStatusError } = state.lead.status;
    return {
        lead,
        updateStatusLoading,
        updateStatusError,
        user: state.user.data
    };
};

const mapDispatchToProps = {
    leadUpdateStatusDecline,
    leadUpdateStatusBuy,
    cleanLeadUpdateStatus,
};

export const LeadBuySection = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadBuySectionComponent));