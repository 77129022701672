import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';
import i18n from '../../../i18n';

import { LINK_SOCIAL_ACCOUNT, UNLINK_SOCIAL_ACCOUNT } from './actionTypes';
import {
    linkSocialAccountSuccess,
    linkSocialAccountError,
    unlinkSocialAccountSuccess,
    unlinkSocialAccountError,
} from './actions';

import { userAddSocialAccount, userRemoveSocialAccount } from '../../user/actions';

import { API } from '../../../api';

function* linkAccount({ payload: { params } }) {
    const { provider } = params;

    try {
        const response = yield call(API.social.linkAccount, params);

        const { data } = response;
        yield put(linkSocialAccountSuccess({ provider }));

        yield put(userAddSocialAccount(data.data));

        toastr.success(i18n.t(`social.link.success.${provider}`));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(linkSocialAccountError({ errors, message }, provider));
    }
}

function* unlinkAccount({ payload: { id, provider } }) {
    try {
        const response = yield call(API.social.unlinkAccount, id);

        const { data } = response;
        yield put(unlinkSocialAccountSuccess({ provider }));

        yield put(userRemoveSocialAccount({ id }));

        toastr.success(i18n.t(`social.remove.link.success`));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(unlinkSocialAccountError({ errors, message }, provider));
    }
}

export function* watchLinkAccount() {
    yield takeEvery(LINK_SOCIAL_ACCOUNT, linkAccount)
}

export function* watchUnlinkAccount() {
    yield takeEvery(UNLINK_SOCIAL_ACCOUNT, unlinkAccount)
}

function* linkingSaga() {
    yield all([
        fork(watchLinkAccount),
        fork(watchUnlinkAccount),
    ]);
}

export default linkingSaga;