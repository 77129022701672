import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import moment from 'moment';
import classNames from "classnames";

import { Attachment } from './Attachment';
import { DaySeparator } from './DaySeparator';
import { UnreadSeparator } from './UnreadSeparator';
import { chatDeleteMessage } from '../../../../../../store/actions';

const MessageComponent = (props) => {
    const { item } = props;

    const handleRemove = () => {
        const { id, chat_id } = item;
        props.chatDeleteMessage(id, props.participant.id, chat_id);
    };

    const name = item.is_my ? props.user.name : props.participant?.name;

    return (
        <li className={item.is_my ? "right" : ""} ref={props.forwardRef} >
            <div className={'w-100'}>
                {props.withDay && (
                    <DaySeparator date={item.created_at} />
                )}

                {props.withUnread && (
                    <UnreadSeparator />
                )}

                <div className="conversation-list">
                    {item.is_my && (
                        <UncontrolledDropdown>
                            <DropdownToggle href="#" className="btn nav-btn" tag="i">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu direction="right">
                                <DropdownItem onClick={handleRemove}>
                                    {props.t('delete')}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )}

                    <div className="ctext-wrap">
                        <div className="conversation-name">
                            {name}
                        </div>

                        {item?.attachments && !!item?.attachments.length && (
                            item.attachments.map(item => (
                                <Attachment item={item} key={'message-attachment-' + item.id} />
                            ))
                        )}

                        {item?.body && (
                            <p className={'text-left'}>
                                {item?.body}
                            </p>
                        )}

                        <div className={'d-flex w-100 justify-content-between'}>
                            <p className="chat-time mb-0">
                                <i className="bx bx-time-five align-middle mr-1" />
                                {moment(item.created_at).format("DD/MM/YY HH:mm")}
                            </p>

                            {item.is_my && (
                                <i
                                    className={
                                        classNames(
                                            'bx ml-2 font-size-18 text-primary',
                                            {
                                                'bx-check': !item.seen,
                                                'bx-check-double': item.seen,
                                            }
                                        )
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    const { meta } = state.chat.conversation.messages;

    return {
        user: data,
        participant: meta?.user,
    };
};

const mapDispatchToProps = {
    chatDeleteMessage,
};

export const Message = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MessageComponent)));