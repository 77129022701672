import { all } from 'redux-saga/effects';

import actualUnpaidSaga from './actualUnpaid/saga';
import previousUnpaidSaga from './previousUnpaid/saga';
import paidSaga from './paid/saga';
import notInterestedSaga from './notInterested/saga';

function* companyLeadsSaga() {
    yield all([
        actualUnpaidSaga(),
        previousUnpaidSaga(),
        paidSaga(),
        notInterestedSaga(),
    ])
}

export default companyLeadsSaga;