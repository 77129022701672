import {
    LEAD_UPDATE_STATUS_DECLINE,
    LEAD_UPDATE_STATUS_BUY,

    LEAD_UPDATE_STATUS_SUCCESS,
    LEAD_UPDATE_STATUS_ERROR,
    CLEAN_LEAD_UPDATE_STATUS,
} from './actionTypes';

const initialState = {
    updateStatusLoading: false,
    updateStatusError: null,
};

const status = (state = initialState, action) => {
    switch (action.type) {
        case LEAD_UPDATE_STATUS_DECLINE:
            state = {
                ...state,
                updateStatusLoading: true,
                updateStatusError: null,
            };
            break;

        case LEAD_UPDATE_STATUS_BUY:
            state = {
                ...state,
                updateStatusLoading: true,
                updateStatusError: null,
            };
            break;

        case LEAD_UPDATE_STATUS_SUCCESS:
            state = {
                ...state,
                updateStatusLoading: false,
            };
            break;

        case LEAD_UPDATE_STATUS_ERROR:
            state = {
                ...state,
                updateStatusLoading: false,
                updateStatusError: action.payload,
            };
            break;

        case CLEAN_LEAD_UPDATE_STATUS:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default status;