import React from 'react';

export const ShapePolygon = ({
    width = "14",
    height = "14",
    viewBox = "0 0 14 14",
    color = '#74788D',
}) => (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="12" height="12" rx="5" stroke={color} strokeOpacity="0.5" strokeWidth="2"/>
        <rect x="4" y="4" width="6" height="6" rx="1" fill={color}/>
    </svg>
);