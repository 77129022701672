import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_FAVORITE_PROPERTIES } from './actionTypes';
import {
    fetchFavoritePropertiesSuccess,
    fetchFavoritePropertiesError,
} from './actions';

import { API } from '../../../../api';
import { getMapRequestParams } from '../../../../utils/request';

function* fetchFavoriteProperties({ payload: { params } }) {
    try {
        const includes = ['project', 'city', 'myReceivedLink', 'myReceivedLink.sender', 'myReceivedLink.sender.company'];

        let requestParams = {
            includes,
            filter: {
                links_statuses: ['active'],
                leads_links_statuses: ['active'],
                is_favorite: true,
            }
        };

        requestParams = getMapRequestParams(params, requestParams);

        const response = yield call(API.property.getPropertyList, requestParams);

        const { data } = response;
        yield put(fetchFavoritePropertiesSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchFavoritePropertiesError(message));
    }
}

export function* watchFavoritePropertiesFetch() {
    yield takeLatest(FETCH_FAVORITE_PROPERTIES, fetchFavoriteProperties)
}

function* listSaga() {
    yield all([
        fork(watchFavoritePropertiesFetch),
    ]);
}

export default listSaga;