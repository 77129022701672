import React, { useState } from 'react';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const ImageLightbox = ({ items, isOpen, currentIndex, onClose }) => {
    const [activeIndex, setActiveIndex] = useState(currentIndex);

    React.useEffect(() => {
        setActiveIndex(currentIndex)
    }, [currentIndex, isOpen]);

    return (
        <>
            {isOpen && (
                <Lightbox
                    mainSrc={items[activeIndex]}
                    nextSrc={items[(activeIndex + 1) % items.length]}
                    prevSrc={
                        items[(activeIndex + items.length - 1) % items.length]
                    }
                    enableZoom={true}
                    onCloseRequest={() => { onClose() }}
                    onMovePrevRequest={() => { setActiveIndex((activeIndex + items.length - 1) % items.length) } }
                    onMoveNextRequest={() => { setActiveIndex((activeIndex + 1) % items.length) } }
                />
            )}
        </>
    );
};