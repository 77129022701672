import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import toastr from 'toastr';
import { useDispatch } from "react-redux";

import { OFFER_STATUS_CONFIRMED, OFFER_STATUS_DECLINED } from "../../../consts/offer";
import { API } from '../../../api';
import { updateOfferItem } from '../../../store/actions';

export const OfferUpdateActionButtons = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { inviteId } = props;

    const [loading, setLoading] = useState(false);

    const isMounted = useRef(null);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            if (isMounted.current) {
                isMounted.current = null;
            }
        };
    }, []);

    const handleChangeStatus = async (status) => {
        setLoading(true);

        try {
            const response = await API.invite.updateInvitePriceStatus(inviteId, { price_status: status });
            const { data } = response;

            toastr.success(t(`offer.update.${status}`));

            if (isMounted.current) {
                setLoading(false);

                dispatch(updateOfferItem(inviteId, { price_status: status }))
            }
        } catch (error) {
            if (isMounted) {
                setLoading(false);
            }

            const message = error.response?.data?.message ? error.response.data.message : 'Error';
            toastr.error(message);
        }
    };

    return (
        <div className="button-items text-center">
            <Button
                type="button"
                color="success"
                disabled={loading}
                onClick={() => handleChangeStatus(OFFER_STATUS_CONFIRMED)}
            >
                {t('confirm')}
            </Button>

            <Button
                type="button"
                color="light"
                disabled={loading}
                onClick={() => handleChangeStatus(OFFER_STATUS_DECLINED)}
            >
                {t('decline')}
            </Button>
        </div>
    );
};