import {
    FETCH_LEAD,
    FETCH_LEAD_SUCCESS,
    FETCH_LEAD_ERROR,
    CLEAN_LEAD,

    LEAD_UPDATE_DATA,
} from './actionTypes';

const initialState = {
    lead: null,
    leadLoading: false,
    leadError: null,
};

const view = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LEAD:
            state = {
                ...state,
                leadLoading: true,
                leadError: null,
            };
            break;

        case FETCH_LEAD_SUCCESS:
            state = {
                ...state,
                leadLoading: false,
                lead: action.payload,
            };
            break;

        case FETCH_LEAD_ERROR:
            state = {
                ...state,
                leadLoading: false,
                leadError: action.payload,
            };
            break;

        case CLEAN_LEAD:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case LEAD_UPDATE_DATA:
            state = {
                ...state,
                lead: {
                    ...state.lead,
                    ...action.payload,
                },
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default view;