export const SUBSCRIPTION_TARIFF_MONTHLY_PERIOD = 'monthly';
export const SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD = 'annually';

export const SUBSCRIPTION_TARIFF_ALIAS_BASIC = 'basic';
export const SUBSCRIPTION_TARIFF_ALIAS_PROFESSIONAL = 'professional';
export const SUBSCRIPTION_TARIFF_ALIAS_ENTERPRISE = 'enterprise';

export const SUBSCRIPTION_STATUS_ACTIVE = 'active';
export const SUBSCRIPTION_STATUS_INACTIVE = 'inactive';
export const SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT = 'incomplete_payment';
export const SUBSCRIPTION_STATUS_TRIALING = 'trialing';
export const SUBSCRIPTION_STATUS_ON_GRACE_PERIOD = 'on_grace_period';