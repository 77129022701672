import React, { useEffect } from 'react';
import { Col, FormGroup, Label } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, getFormValues, reduxForm } from 'redux-form'

import { WizardPager } from '../../WizardPager';
import RadioField from '../../../../../../components/Common/RadioField';
import { FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD } from '../../../../../../consts/forms';
import { SLIDER_MODE_INPUT } from "../../../../../../consts/common";
import { propertyRequestHelper } from '../../../../../../helpers';
import {
    PROPERTY_EXPECTATION_PERCENT_MIN,
    PROPERTY_EXPECTATION_PERCENT_MAX,
} from "../../../../../../consts/property";
import { SliderField } from "../../../../../../components/Custom";

const range = {
    'min': [PROPERTY_EXPECTATION_PERCENT_MIN], // 0
    'max': [PROPERTY_EXPECTATION_PERCENT_MAX],
};

const InvestmentWizardStep1Component = (props) => {
    useEffect(() => {
        return () => {
        };
    }, []);

    const goals = propertyRequestHelper.goal.getGoalOptions(props.t);

    return (
        <>
            <form
                onSubmit={props.handleSubmit(props.onSubmit)}
                className="form-horizontal"
                noValidate={true}
            >
                <FormGroup row tag="fieldset">
                    <Label className={'col-form-label col-lg-2'}>{props.t('goal')}</Label>

                    <Col lg="10">
                        <div className={'form-control custom-switch-filed-wrap border-0 p-0 flex-wrap'}>
                            {goals.map((item) => (
                                <div className={'pt-1 pb-1'} key={`property-goal-${item.value}`}>
                                    <Field
                                        name="goal"
                                        value={item.value}
                                        label={item.label}
                                        id={`property-goal-${item.value}`}
                                        type="radio"
                                        component={RadioField}
                                    />
                                </div>
                            ))}
                        </div>
                    </Col>
                </FormGroup>

                <FormGroup row className={'mb-4'}>
                    <Label className={'col-form-label col-lg-2'}>{props.t('expectation_/_year')}</Label>

                    <Col lg="10">
                        <Field
                            id={'expectation'}
                            name="expectation"
                            component={SliderField}
                            limit={{
                                min: PROPERTY_EXPECTATION_PERCENT_MIN,
                                max: PROPERTY_EXPECTATION_PERCENT_MAX,
                            }}
                            mode={SLIDER_MODE_INPUT}
                            labelPrefix={'% '}
                            range={range}
                        />
                    </Col>
                </FormGroup>
            </form>

            <WizardPager
                next={props.t('next')}
                prev={props.t('previous')}
                onNext={props.handleSubmit(props.onSubmit)}
                onPrev={props.back}
            />
        </>
    );
};

const InvestmentWizardForm = reduxForm({
    form: FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD,
    enableReinitialize: true,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(InvestmentWizardStep1Component);

const mapStateToProps = state => {
    const formValues = getFormValues(FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD)(state);
    return {
        initialValues: {
            ...formValues,
            expectation: formValues?.expectation || [PROPERTY_EXPECTATION_PERCENT_MIN],
        },
    };
};

const mapDispatchToProps = {
};

export const InvestmentWizardStep1 = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvestmentWizardForm)));