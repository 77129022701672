import {
    FETCH_PROJECT_LIST,
    FETCH_PROJECT_LIST_SUCCESS,
    FETCH_PROJECT_LIST_ERROR,
    CLEAN_PROJECT_LIST_PAGE,

    ADD_PROJECT,
    ADD_PROJECT_SUCCESS,
    ADD_PROJECT_ERROR,
    CLEAN_ADD_PROJECT_PAGE,

    FETCH_PROJECT,
    FETCH_PROJECT_SUCCESS,
    FETCH_PROJECT_ERROR,

    EDIT_PROJECT,
    EDIT_PROJECT_SUCCESS,
    EDIT_PROJECT_ERROR,

    REMOVE_PROJECT,
    REMOVE_PROJECT_SUCCESS,
    REMOVE_PROJECT_ERROR,

    CLEAN_PROJECT_PAGE,

    PROJECT_SET_SORT,
    PROJECT_RESET_SORT,

    PROJECT_SET_PAGE,
} from './actionTypes';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    page: 1,
    meta: null,

    sort: [],

    addLoading: false,
    addSuccess: null,
    addError: null,

    project: null,
    projectLoading: false,
    projectError: null,

    editLoading: false,
    editSuccess: null,
    editError: null,

    removeLoading: false,
    removeSuccess: null,
    removeError: null,
};

const project = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROJECT_LIST:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case FETCH_PROJECT_LIST_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case FETCH_PROJECT_LIST_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case CLEAN_PROJECT_LIST_PAGE:
            state = {
                ...state,
                list: [],
                istLoading: false,
                listError: null,
                sort: [],
                page: initialState.page,
                meta: null,
            };
            break;

        case ADD_PROJECT:
            state = {
                ...state,
                addLoading: true,
                addSuccess: null,
                addError: null,
            };
            break;

        case ADD_PROJECT_SUCCESS:
            state = {
                ...state,
                addLoading: false,
                addSuccess: action.payload,
            };
            break;

        case ADD_PROJECT_ERROR:
            state = {
                ...state,
                addLoading: false,
                addError: action.payload,
            };
            break;

        case CLEAN_ADD_PROJECT_PAGE:
            state = {
                ...state,
                addLoading: false,
                addSuccess: null,
                addError: null,
            };
            break;

        case FETCH_PROJECT:
            state = {
                ...state,
                projectLoading: true,
                projectError: null,
            };
            break;

        case FETCH_PROJECT_SUCCESS:
            state = {
                ...state,
                projectLoading: false,
                project: action.payload,
            };
            break;

        case FETCH_PROJECT_ERROR:
            state = {
                ...state,
                projectLoading: false,
                projectError: action.payload,
            };
            break;

        case EDIT_PROJECT:
            state = {
                ...state,
                editLoading: true,
                editSuccess: null,
                editError: null,
            };
            break;

        case EDIT_PROJECT_SUCCESS:
            state = {
                ...state,
                editLoading: false,
                editSuccess: action.payload,
            };
            break;

        case EDIT_PROJECT_ERROR:
            state = {
                ...state,
                editLoading: false,
                editError: action.payload,
            };
            break;

        case REMOVE_PROJECT:
            state = {
                ...state,
                removeLoading: true,
                removeSuccess: null,
                removeError: null,
            };
            break;

        case REMOVE_PROJECT_SUCCESS:
            state = {
                ...state,
                removeLoading: false,
                removeSuccess: action.payload,
            };
            break;

        case REMOVE_PROJECT_ERROR:
            state = {
                ...state,
                removeLoading: false,
                removeError: action.payload,
            };
            break;

        case CLEAN_PROJECT_PAGE:
            state = {
                ...state,
                project: null,
                projectLoading: false,
                projectError: null,

                editLoading: false,
                editSuccess: null,
                editError: null,

                removeLoading: false,
                removeSuccess: null,
                removeError: null,
            };
            break;

        case PROJECT_SET_SORT:
            state = {
                ...state,
                sort: action.payload,
                page: initialState.page,
            };
            break;

        case PROJECT_RESET_SORT:
            state = {
                ...state,
                sort: initialState.sort,
                page: initialState.page,
            };
            break;

        // PROJECT SET PAGE
        case PROJECT_SET_PAGE:
            state = {
                ...state,
                page: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default project;