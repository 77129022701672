import {
    SEND_INVITE,
    SEND_INVITE_SUCCESS,
    SEND_INVITE_ERROR,
    CLEAN_SEND_INVITE_ERROR,

    UPDATE_INVITE,
    UPDATE_INVITE_SUCCESS,
    UPDATE_INVITE_ERROR,
    CLEAN_UPDATE_INVITE,

    CHANGE_INVITE,
    CHANGE_INVITE_SUCCESS,
    CHANGE_INVITE_ERROR,
    CLEAN_CHANGE_INVITE,

    SEND_OFFER,
    SEND_OFFER_SUCCESS,
    SEND_OFFER_ERROR,
    CLEAN_SEND_OFFER,
} from './actionTypes';

const initialState = {
    sendInviteLoading: false,
    sendInviteError: null,
    sendInviteSuccess: null,

    updateInviteLoading: false,
    updateInviteSuccess: null,
    updateInviteError: null,

    changeInviteLoading: false,
    changeInviteSuccess: null,
    changeInviteError: null,

    offerLoading: false,
    offerSuccess: null,
    offerError: null,
};

const invite = (state = initialState, action) => {
    switch (action.type) {
        case SEND_INVITE:
            state = {
                ...state,
                sendInviteLoading: true,
                sendInviteError: null,
            };
            break;

        case SEND_INVITE_SUCCESS:
            state = {
                ...state,
                sendInviteLoading: false,
                sendInviteSuccess: action.payload,
            };
            break;

        case SEND_INVITE_ERROR:
            state = {
                ...state,
                sendInviteLoading: false,
                sendInviteError: action.payload,
            };
            break;

        case CLEAN_SEND_INVITE_ERROR:
            state = {
                ...state,
                sendInviteLoading: false,
                sendInviteSuccess: null,
                sendInviteError: null,
            };
            break;

        case UPDATE_INVITE:
            state = {
                ...state,
                updateInviteLoading: true,
                updateInviteError: null,
            };
            break;

        case UPDATE_INVITE_SUCCESS:
            state = {
                ...state,
                updateInviteLoading: false,
                updateInviteSuccess: action.payload,
            };
            break;

        case UPDATE_INVITE_ERROR:
            state = {
                ...state,
                updateInviteLoading: false,
                updateInviteError: action.payload,
            };
            break;

        case CLEAN_UPDATE_INVITE:
            state = {
                ...state,
                updateInviteLoading: false,
                updateInviteSuccess: null,
                updateInviteError: null,
            };
            break;

        case CHANGE_INVITE:
            state = {
                ...state,
                changeInviteLoading: true,
                changeInviteError: null,
            };
            break;

        case CHANGE_INVITE_SUCCESS:
            state = {
                ...state,
                changeInviteLoading: false,
                changeInviteSuccess: action.payload,
            };
            break;

        case CHANGE_INVITE_ERROR:
            state = {
                ...state,
                changeInviteLoading: false,
                changeInviteError: action.payload,
            };
            break;

        case CLEAN_CHANGE_INVITE:
            state = {
                ...state,
                changeInviteLoading: false,
                changeInviteSuccess: null,
                changeInviteError: null,
            };
            break;

        case SEND_OFFER:
            state = {
                ...state,
                offerLoading: true,
                offerError: null,
            };
            break;

        case SEND_OFFER_SUCCESS:
            state = {
                ...state,
                offerLoading: false,
                offerSuccess: action.payload,
            };
            break;

        case SEND_OFFER_ERROR:
            state = {
                ...state,
                offerLoading: false,
                offerError: action.payload,
            };
            break;

        case CLEAN_SEND_OFFER:
            state = {
                ...state,
                offerLoading: false,
                offerSuccess: null,
                offerError: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default invite;