import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ImageGallery, PropertyFavoriteButton, PropertyRibbon } from '../../../../../../components/Custom';
import { propertyViewSendFavoriteStatus } from '../../../../../../store/actions';
import { INVITE_STATUS_ACTIVE } from "../../../../../../consts/invite";
import { ROLE_CLIENT } from "../../../../../../consts/roles";
import { isPropertyNewStatus } from "../../../../../../helpers";

import './PropertyImageGalleryBlock.scss';

const PropertyImageGalleryBlockComponent = (props) => {
    const canFavorite = useMemo(() => {
        if (props.user?.role === ROLE_CLIENT) {
            if (props.property && props.property?.myReceivedLink) {
                return [INVITE_STATUS_ACTIVE].includes(props.property?.myReceivedLink?.status)
            }

            return true;
        }
        return false;
    }, [props.property, props.user]);

    const isNew = useMemo(() => {
        return isPropertyNewStatus(props.property);
    }, [props.property]);

    const toggleFavorite = (status) => {
        props.propertyViewSendFavoriteStatus(props.property.id, { status: status })
    };

    return (
        <div className={'property-images-block'}>
            <ImageGallery images={props.property.images}/>

            {isNew && <PropertyRibbon/>}

            {canFavorite && (
                <PropertyFavoriteButton className={"property-images-block__favorite_badge"}
                                        status={props.property.is_favorite} onChange={toggleFavorite}
                                        size={'large'}/>
            )}
        </div>
    );
};

const mapStateToProps = state => {
    const {
        property,
    } = state.property.view;
    return {
        property,
        user: state.user.data,
    };
};

const mapDispatchToProps = {
    propertyViewSendFavoriteStatus,
};

export const PropertyImageGalleryBlock = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyImageGalleryBlockComponent)));