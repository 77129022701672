import React from "react"

export const Logo = ({size, width, height}) => {
  width = width || "120"
  height = height || "100%"

  if (size === "sm") {
    width = "90"
  }

  return (
    <svg width={width} height={height} viewBox="0 0 801 203" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M254.736 37.4785C275.162 36.8634 295.588 37.3725 316.014 37.1392C330.522 37.1604 346.091 38.0724 358.647 46.1325C367.45 53.1108 367.832 65.7099 367.832 76.0183C367.789 87.4084 358.52 95.214 350.099 101.344C357.566 106.35 365.583 112.373 367.492 121.748C369.804 132.863 369.486 146.204 360.705 154.561C350.778 164.615 335.634 165.336 322.377 165.591C299.83 165.464 277.283 165.867 254.736 165.294C254.927 122.682 254.948 80.0908 254.736 37.4785ZM281.844 60.6618C281.462 70.5035 281.504 80.3453 282.225 90.187C294.909 89.7628 307.615 90.2082 320.298 89.9961C326.195 89.8477 333.067 88.978 336.779 83.8026C339.409 79.3696 339.452 73.6639 337.882 68.8491C335.549 62.698 328.443 60.683 322.483 60.5345C308.951 59.983 295.376 60.4497 281.844 60.6618ZM282.034 112.522C281.525 122.427 281.504 132.354 281.886 142.259C295.397 142.556 308.93 142.832 322.441 142.493C328.04 142.302 334.997 141.305 337.67 135.62C341.085 128.43 339.579 116.234 330.14 114.876C314.338 111.589 298.07 113.095 282.034 112.522Z"
        fill="#495057"/>
      <path
        d="M389.894 37.4359C407.711 36.9481 425.507 37.3511 443.303 37.1602C457.726 37.3723 473.337 36.2481 486.36 43.5234C501.59 54.2347 504.517 76.4423 498.429 92.9866C495.014 102.956 485.47 108.322 476.476 112.479C485.873 130.466 497.093 147.413 506.956 165.145C497.199 165.442 487.357 166.482 477.685 164.912C466.168 150.171 459.486 132.014 448.796 116.615C438.573 113.985 427.777 115.83 417.341 115.767C416.832 132.163 417.044 148.58 417.235 164.976C408.135 165.676 399.015 165.739 389.894 165.379C389.47 122.724 389.47 80.0693 389.894 37.4359ZM417.362 60.6616C416.895 71.2881 416.895 81.9359 417.405 92.5836C429.601 92.4351 441.797 92.8169 453.993 92.4563C459.932 92.117 466.931 91.6928 470.961 86.6446C474.822 81.448 474.673 74.0667 472.467 68.2126C470.049 62.7402 463.517 61.4251 458.193 60.8525C444.597 59.7495 430.958 60.4919 417.362 60.6616Z"
        fill="#495057"/>
      <path
        d="M523.988 37.4349C533.3 37.1167 542.632 37.1167 551.944 37.4349C552.368 80.0683 552.389 122.702 551.944 165.335C542.611 165.653 533.3 165.653 523.967 165.335C523.924 122.702 523.903 80.0683 523.988 37.4349Z"
        fill="#495057"/>
      <path
        d="M570.183 37.3932C578.986 37.1175 587.788 37.1387 596.612 37.4568C597.312 57.4797 596.527 77.5238 597.036 97.5466C615.447 79.157 629.658 56.907 647.687 38.1992C658.038 35.9085 668.813 37.4356 679.312 37.6265C663.192 58.8584 645.566 78.9449 628.682 99.5829C644.739 122.087 663.68 142.407 679.418 165.124C669.322 165.506 659.183 166.269 649.129 165.251C646.69 165.145 644.76 164.106 643.36 162.133C627.643 142.683 613.665 121.748 597.036 103.061C596.527 123.805 597.312 144.571 596.612 165.315C587.809 165.633 578.986 165.654 570.183 165.378C569.759 122.724 569.78 80.0478 570.183 37.3932Z"
        fill="#495057"/>
      <path
        d="M689.939 37.7751C698.996 37.0963 708.096 37.0539 717.195 37.4145C717.683 57.5222 717.153 77.6087 717.428 97.7164C729.222 85.3506 739.318 71.5212 750.39 58.5191C756.371 51.7317 761.144 43.7141 768.249 37.9872C778.621 36.0994 789.248 37.3933 799.705 37.6478C783.86 57.8404 767.04 77.2481 750.92 97.2073C747.993 99.392 750.538 102.234 752.002 104.44C767.931 124.718 784.581 144.443 800.002 165.124C788.633 165.781 777.264 165.739 765.895 165.315C749.626 144.634 734.333 123.19 717.45 102.998C717.132 123.784 717.683 144.571 717.195 165.357C708.096 165.718 698.996 165.654 689.939 164.997C690.194 122.597 690.194 80.1752 689.939 37.7751Z"
        fill="#495057"/>
      <path
        d="M4.52884 75.0906C7.6924 71.5291 12.389 69.3876 17.1597 69.3993C52.9145 69.3993 88.6732 69.3993 124.432 69.3993C128.504 69.3213 132.581 70.7451 135.694 73.382C139.676 76.6743 142.021 81.8039 141.99 86.9647C141.978 96.7245 142.017 106.484 141.97 116.24C141.759 123.285 136.879 129.924 130.158 132.105C127.077 133.228 123.761 132.955 120.547 132.983C86.0831 132.983 51.6155 132.983 17.1519 132.983C13.3135 132.975 9.49067 131.617 6.52606 129.175C2.57453 126.043 0.198941 121.124 0 116.108V86.2547C0.198941 82.1667 1.76317 78.1293 4.52884 75.0906Z"
        fill="#EA4335"/>
      <path
        d="M88.3499 0.23847C89.3056 0.0824374 90.2769 0.0200244 91.2443 0.000520324C126.621 0.000520324 161.997 0.000520324 197.374 0.000520324C199.328 -0.00338049 201.321 0.000520291 203.217 0.550535C210.437 2.36441 215.863 9.31956 216.043 16.7311C216.086 26.4948 216.051 36.2586 216.062 46.0223C216.109 50.9841 213.913 55.9304 210.176 59.1992C207.141 61.9454 203.073 63.5369 198.973 63.5135C163.078 63.5135 127.182 63.5135 91.2911 63.5135C84.8002 63.611 78.5589 59.5113 75.8634 53.6445C74.7127 51.2728 74.1198 48.6397 74.1432 45.9989C74.1549 36.2469 74.1198 26.4909 74.1627 16.735C74.3343 8.83196 80.5132 1.52964 88.3499 0.23847Z"
        fill="#58BAAB"/>
      <path
        d="M88.3419 139.478C89.0479 139.345 89.7579 139.236 90.4795 139.224C125.723 139.224 160.967 139.224 196.215 139.224C198.302 139.244 200.416 139.099 202.468 139.591C210.195 141.135 216.105 148.547 216.105 156.388C216.097 165.874 216.101 175.365 216.101 184.852C216.269 189.86 214.159 194.92 210.426 198.278C207.547 201.001 203.665 202.62 199.71 202.807C163.436 202.807 127.163 202.803 90.8852 202.811C84.6166 202.737 78.6445 198.789 75.949 193.157C74.6696 190.598 74.0103 187.715 74.1117 184.848C74.1117 175.357 74.1156 165.863 74.1078 156.372C74.1156 148.328 80.3491 140.753 88.3419 139.478Z"
        fill="#F6BC00"/>
    </svg>
  )
}