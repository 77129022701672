import { takeLatest, takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import toastr from 'toastr';
import _ from 'lodash';

import { FETCH_CHAT_MESSAGES, CHAT_SEND_CHAT_READ } from './actionTypes';
import { fetchChatMessagesSuccess, fetchChatMessagesError } from './actions';
import { chatsMarkChatRead } from '../../chats/actions';
import { updateUnreadMessagesCount } from "../../../user/actions";

import { API } from '../../../../api';

export const userState = (state) => state.user;
export const chatsState = (state) => state.chat.chats;

function* fetchChatMessages({ payload: { id, params, data: { isMoreLoading } } }) {
    try {
        const response = yield call(API.chat.getMessages, id, params);

        const { data } = response;
        yield put(fetchChatMessagesSuccess(data));

        const messages = data.data;

        if (messages.length > 0 && !isMoreLoading) {
            let unSeenMessage = _.find(messages, { seen: false, is_my: false });

            // todo: if unread_count > 0 (in response not has this field)
            // if has unread messages
            if (unSeenMessage) {
                let chatUnreadMessagesCount = 0;

                const chats = yield select(chatsState);
                if (chats.isLoaded) {
                    let chat = _.find(chats.list, { id: unSeenMessage.chat_id });
                    if (chat) {
                        chatUnreadMessagesCount = chat.unread_count;
                    }
                }

                // read chat (seen messages)
                const readChatResponse = yield call(API.chat.readChat, id);
                if (readChatResponse) {
                    // mark chat read
                    yield put(chatsMarkChatRead(unSeenMessage.chat_id));
                }

                if (chatUnreadMessagesCount && readChatResponse) {
                    const user = yield select(userState);
                    const count = user.data.unread_messages_count - chatUnreadMessagesCount;

                    // update count all of unread messages (- badge in side menu)
                    yield put(updateUnreadMessagesCount(count));
                }
            }
        }

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchChatMessagesError(message));

        toastr.error(message);
    }
}

function* sendReadChat({ payload: { userId, chatId } }) {
    try {
        const response = yield call(API.chat.readChat, userId);

        const { data } = response;

        if (chatId) {
            yield put(chatsMarkChatRead(chatId));
        }

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        toastr.error(message);
    }
}

export function* watchChatMessagesFetch() {
    yield takeLatest(FETCH_CHAT_MESSAGES, fetchChatMessages)
}

export function* watchSendChatRead() {
    yield takeEvery(CHAT_SEND_CHAT_READ, sendReadChat)
}

function* messagesSaga() {
    yield all([
        fork(watchChatMessagesFetch),
        fork(watchSendChatRead),
    ]);
}

export default messagesSaga;