import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { SEND_INVITE_SEEN_STATUS } from './actionTypes';

import { API } from '../../../api';

function* sendInviteSeenStatus({ payload: { id } }) {
    try {
        const response = yield call(API.invite.sendInviteSeenStatus, id);

        const { data } = response;

    } catch (error) {
    }
}

export function* watchSendInviteSeenStatus() {
    yield takeEvery(SEND_INVITE_SEEN_STATUS, sendInviteSeenStatus)
}

function* seenSaga() {
    yield all([
        fork(watchSendInviteSeenStatus),
    ]);
}

export default seenSaga;