import { combineReducers } from "redux";

import propertyRequestReducer from './propertyRequest/reducer';
import activeListReducer from './activeList/reducer';
import inactiveListReducer from './inactiveList/reducer';

const suggestedPropertyReducer = combineReducers({
    propertyRequest: propertyRequestReducer,
    activeList: activeListReducer,
    inactiveList: inactiveListReducer,
});

export default suggestedPropertyReducer;