import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { reset } from "redux-form";
import { withRouter } from "react-router-dom";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';

import { BillingAddCardForm } from './BillingAddCardForm';
import { billingCleanStripeAddCard } from '../../../../../store/actions';
import { FORM_BILLING_ADD_PAYMENT_CARD } from '../../../../../consts/forms';

const BillingAddCardComponent = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        return () => {
            props.billingCleanStripeAddCard();
        };
    }, []);

    const resetForm = () => {
        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);

        cardNumberElement.clear();
        cardExpiryElement.clear();
        cardCvcElement.clear();

        props.reset(FORM_BILLING_ADD_PAYMENT_CARD);
    };

    const handleCloseForm = () => {
        setVisible(false);

        props.billingCleanStripeAddCard();
        resetForm();
    };

    const onSubmit = (values) => {};

    const onSuccess = () => {
        props.onSuccess();

        resetForm();
    };

    return (
        <Row>
            <Col>
                <div className="mb-3">
                    {visible ? (
                        props.t('add_payment_card')
                    ) : (
                        <Button
                            type="button" color="light" outline className="waves-effect"
                            onClick={() => setVisible(true)}
                        >
                            {props.t('add_payment_card')}
                        </Button>
                    )}
                </div>

                <div className={!visible ? 'd-none' : ''}>
                    <BillingAddCardForm
                        stripe={stripe}
                        elements={elements}
                        onCancel={handleCloseForm}
                        onSubmit={onSubmit}
                        onSuccess={onSuccess}
                    />
                </div>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
    billingCleanStripeAddCard,
    reset,
};

export const BillingAddCard = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingAddCardComponent)));