import {
    FETCH_CLIENT_PROPERTIES_STATISTICS_ERROR,
    FETCH_CLIENT_PROPERTIES_STATISTICS_SUCCESS,
    CLEAN_CLIENT_PROPERTIES_STATISTICS,
    FETCH_CLIENT_PROPERTIES_STATISTICS, CLIENT_PROPERTIES_STATISTICS_UPDATE
} from './actionTypes';

export const fetchClientPropertiesStatistics = () => {
    return {
        type: FETCH_CLIENT_PROPERTIES_STATISTICS,
    }
};

export const fetchClientPropertiesStatisticsSuccess = (data) => {
    return {
        type: FETCH_CLIENT_PROPERTIES_STATISTICS_SUCCESS,
        payload: data,
    }
};

export const fetchClientPropertiesStatisticsError = (data) => {
    return {
        type: FETCH_CLIENT_PROPERTIES_STATISTICS_ERROR,
        payload: data,
    }
};

export const cleanClientPropertiesStatistics = () => {
    return { type: CLEAN_CLIENT_PROPERTIES_STATISTICS };
};

export const clientPropertiesStatisticsUpdate = (data) => {
    return {
        type: CLIENT_PROPERTIES_STATISTICS_UPDATE,
        payload: data
    };
};