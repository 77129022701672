import React from 'react';
import {connect} from "react-redux";
import {useTranslation} from 'react-i18next';

import {RegisterContainer} from './components';
import {useTitle} from "../../hooks";
import {ROLE_MANAGER} from "../../consts/roles";
import {registerUser, registerUserClean, socialRegisterUser} from "../../store/actions";

const RegisterManagerComponent = (props) => {
  const {t} = useTranslation();

  useTitle(t('title.signup.manager'));

  return (
    <RegisterContainer role={ROLE_MANAGER}/>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  registerUser,
  registerUserClean,
  socialRegisterUser
})(RegisterManagerComponent);
