import {createSelector} from 'reselect'

const getPending = state => state.crmSystem.account.remove.pending;

export const selectCrmAccountRemoveIsLoading = createSelector(
  [
    getPending,
    (state, crm) => crm,
  ], (pending, crm) => {
    return pending.includes(crm);
  });