import { combineReducers } from "redux";

import favoritesReducer from './favorites/reducer';
import inviteReducer from './invite/reducers';

const clientsReducer = combineReducers({
    favorites: favoritesReducer,
    invite: inviteReducer,
});

export default clientsReducer;