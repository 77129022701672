import {
    FETCH_COMPANY_PAID_LEADS,
    FETCH_COMPANY_PAID_LEADS_SUCCESS,
    FETCH_COMPANY_PAID_LEADS_ERROR,
    CLEAN_COMPANY_PAID_LEADS,
    COMPANY_PAID_LEADS_SET_PAGE,
} from './actionTypes';

export const fetchCompanyPaidLeads = (params) => {
    return {
        type: FETCH_COMPANY_PAID_LEADS,
        payload: { params }
    }
};

export const fetchCompanyPaidLeadsSuccess = (data) => {
    return {
        type: FETCH_COMPANY_PAID_LEADS_SUCCESS,
        payload: data,
    }
};

export const fetchCompanyPaidLeadsError = (data) => {
    return {
        type: FETCH_COMPANY_PAID_LEADS_ERROR,
        payload: data,
    }
};

export const cleanCompanyPaidLeads = () => {
    return { type: CLEAN_COMPANY_PAID_LEADS };
};

export const companyPaidLeadsSetPage = (data) => {
    return {
        type: COMPANY_PAID_LEADS_SET_PAGE,
        payload: data,
    }
};