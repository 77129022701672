import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const DaySeparator = ({ date }) => {
    const { t } = useTranslation();

    const renderDay = () => {
        return moment(date).format('DD/MM/YYYY');
    };

    return (
        <div className="chat-day-title">
            <span className="title">
                {renderDay()}
            </span>
        </div>
    );
};