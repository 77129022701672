import {
    FETCH_CONSULTANT_LIST,
    FETCH_CONSULTANT_LIST_SUCCESS,
    FETCH_CONSULTANT_LIST_ERROR,
    CLEAN_CONSULTANT_LIST_PAGE,

    CONSULTANT_SET_SORT,
    CONSULTANT_SET_FILTER,
    CONSULTANT_RESET_FILTER,

    CONSULTANT_SET_PAGE,

    CREATE_CONSULTANT,
    CREATE_CONSULTANT_SUCCESS,
    CREATE_CONSULTANT_ERROR,
    CLEAN_CREATE_CONSULTANT_PAGE,

    FETCH_CONSULTANT,
    FETCH_CONSULTANT_SUCCESS,
    FETCH_CONSULTANT_ERROR,

    EDIT_CONSULTANT,
    EDIT_CONSULTANT_SUCCESS,
    EDIT_CONSULTANT_ERROR,
    CLEAN_EDIT_CONSULTANT_PAGE,

    REMOVE_CONSULTANT,
    REMOVE_CONSULTANT_SUCCESS,
    REMOVE_CONSULTANT_ERROR,

    CLEAN_CONSULTANT_PAGE,
} from './actionTypes';

export const fetchConsultantList = (params) => {
    return {
        type: FETCH_CONSULTANT_LIST,
        payload: { params },
    }
};

export const fetchConsultantListSuccess = (data) => {
    return {
        type: FETCH_CONSULTANT_LIST_SUCCESS,
        payload: data,
    }
};

export const fetchConsultantListError = (data) => {
    return {
        type: FETCH_CONSULTANT_LIST_ERROR,
        payload: data,
    }
};

export const consultantSetSort = (data) => {
    return {
        type: CONSULTANT_SET_SORT,
        payload: data,
    }
};

export const consultantSetFilter = (data) => {
    return {
        type: CONSULTANT_SET_FILTER,
        payload: data,
    }
};

export const consultantResetFilter = (data) => {
    return {
        type: CONSULTANT_RESET_FILTER,
        payload: data,
    }
};

// SET CONSULTANT PAGE
export const consultantSetPage = (data) => {
    return {
        type: CONSULTANT_SET_PAGE,
        payload: data,
    }
};

export const cleanConsultantListPage = () => {
    return { type: CLEAN_CONSULTANT_LIST_PAGE };
};

// CREATE CONSULTANT
export const createConsultant = (params, history) => {
    return {
        type: CREATE_CONSULTANT,
        payload: { params, history },
    }
};

export const createConsultantSuccess = (data) => {
    return {
        type: CREATE_CONSULTANT_SUCCESS,
        payload: data,
    }
};

export const createConsultantError = (data) => {
    return {
        type: CREATE_CONSULTANT_ERROR,
        payload: data,
    }
};

export const cleanCreateConsultantPage = () => {
    return { type: CLEAN_CREATE_CONSULTANT_PAGE };
};

// FETCH CONSULTANT
export const fetchConsultant = (id) => {
    return {
        type: FETCH_CONSULTANT,
        payload: { id },
    }
};

export const fetchConsultantSuccess = (data) => {
    return {
        type: FETCH_CONSULTANT_SUCCESS,
        payload: data,
    }
};

export const fetchConsultantError = (data) => {
    return {
        type: FETCH_CONSULTANT_ERROR,
        payload: data,
    }
};

// EDIT CONSULTANT
export const editConsultant = (id, params) => {
    return {
        type: EDIT_CONSULTANT,
        payload: { id, params },
    }
};

export const editConsultantSuccess = (data) => {
    return {
        type: EDIT_CONSULTANT_SUCCESS,
        payload: data,
    }
};

export const editConsultantError = (data) => {
    return {
        type: EDIT_CONSULTANT_ERROR,
        payload: data,
    }
};

export const cleanEditConsultantPage = () => {
    return { type: CLEAN_EDIT_CONSULTANT_PAGE };
};

// REMOVE CONSULTANT
export const removeConsultant = (id, history) => {
    return {
        type: REMOVE_CONSULTANT,
        payload: { id, history },
    }
};

export const removeConsultantSuccess = (data) => {
    return {
        type: REMOVE_CONSULTANT_SUCCESS,
        payload: data,
    }
};

export const removeConsultantError = (data) => {
    return {
        type: REMOVE_CONSULTANT_ERROR,
        payload: data,
    }
};


export const cleanConsultantPage = () => {
    return { type: CLEAN_CONSULTANT_PAGE };
};