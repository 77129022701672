import React from 'react';
import { useTranslation } from 'react-i18next';

export const UnreadSeparator = ({ date }) => {
    const { t } = useTranslation();

    return (
        <div className="chat-day-title">
            <span className="title">
                {t('unread_messages')}
            </span>
        </div>
    );
};