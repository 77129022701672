import {
  CRM_SYSTEM_ACCOUNT_REMOVE,
  CRM_SYSTEM_ACCOUNT_REMOVE_SUCCESS,
  CRM_SYSTEM_ACCOUNT_REMOVE_ERROR,
  CRM_SYSTEM_ACCOUNT_REMOVE_CLEAN_STATE,
} from './actionTypes';

export const crmSystemAccountRemove = ({id, crm}) => {
  return {
    type: CRM_SYSTEM_ACCOUNT_REMOVE,
    payload: {id, crm},
  }
};

export const crmSystemAccountRemoveSuccess = (crm) => {
  return {
    type: CRM_SYSTEM_ACCOUNT_REMOVE_SUCCESS,
    payload: {crm},
  }
};

export const crmSystemAccountRemoveError = (crm) => {
  return {
    type: CRM_SYSTEM_ACCOUNT_REMOVE_ERROR,
    payload: {crm},
  }
};

export const crmSystemAccountRemoveCleanState = () => {
  return {type: CRM_SYSTEM_ACCOUNT_REMOVE_CLEAN_STATE};
};