import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classnames from "classnames"

import './TariffCard.scss';

import {
    SUBSCRIPTION_TARIFF_ALIAS_BASIC,
    SUBSCRIPTION_TARIFF_ALIAS_PROFESSIONAL,
    SUBSCRIPTION_TARIFF_ALIAS_ENTERPRISE,
    SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD,
    SUBSCRIPTION_STATUS_ON_GRACE_PERIOD,
    SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT,
} from '../../../../../../../consts/billing/subscription';
import { subscriptionHelper } from "../../../../../../../helpers";

const TariffCardComponent = (props) => {
    const { data, selected, onSelect } = props;

    const isAnnual = data?.period === SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD;

    const isCurrent = useMemo(() => {
        return data.id === props.currentTariff?.id;
    }, [data, props.currentTariff]);

    const isDowngrade = useMemo(() => {
        if (data && props.subscription?.tariff) {
            const { isDowngrade } = subscriptionHelper.tariff.compareTariff(data, props.subscription?.tariff);
            return isDowngrade;
        }
        return false;
    }, [data, props.subscription?.tariff]);

    const isDisabled = useMemo(() => {
        if (
            props.subscription?.status === SUBSCRIPTION_STATUS_ON_GRACE_PERIOD ||
            props.subscription?.status === SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT
            || isDowngrade
        ) {
            return true;
        }

        return isCurrent;
    }, [data, isCurrent, props.subscription, isDowngrade]);

    const handleSelect = (item) => {
      if (isDisabled) {
          return;
      }

      onSelect(item);
    };

    return (
        <div className={classnames({
                'pricing-block__card-block': true,
                'small': data.alias === SUBSCRIPTION_TARIFF_ALIAS_BASIC,
                'medium': data.alias === SUBSCRIPTION_TARIFF_ALIAS_PROFESSIONAL,
                'large': data.alias === SUBSCRIPTION_TARIFF_ALIAS_ENTERPRISE,
                active: data.id === selected?.id || isCurrent,
            })}
            onClick={() => handleSelect(data)}
        >
            <div className="pricing-block__card-overhead">
                <span className="plans-name">{data.name}</span>
                <svg width="18" height="9" className="pricing-block__card-overhead-icon">
                    <svg id="card_arrow" viewBox="0 0 18 9" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.68674 8.3512C9.30132 8.71533 8.69868 8.71533 8.31326 8.3512L1.3016 1.7269C0.644001 1.10563 1.08368 0 1.98835 0L16.0117 0C16.9163 0 17.356 1.10563 16.6984 1.7269L9.68674 8.3512Z"></path>
                    </svg>
                </svg>
            </div>
            <div className="pricing-block__card-wrapper">
                <div className="pricing-block__card-price">
                    <span className="currency">€</span>
                    <span className="price">{data.price_monthly}</span>
                </div>
                <div className="pricing-block__card-billing">{isAnnual ? props.t('billed_annually') : props.t('billed_per_month')}</div>
                {/*<a href="#" className="pricing-block__card-free-block">Try now for FREE</a>*/}
                {data.bonus_text && data.bonus_text !== '' && (
                    <div className="pricing-block__card-bonus" dangerouslySetInnerHTML={{__html: data?.bonus_text}}>
                    </div>
                )}
            </div>
            <div className="pricing-block__card-wrapper">
                <div className="pricing-block__card-list" dangerouslySetInnerHTML={{__html: data?.benefits_text}}>
                </div>
            </div>
            <div className="pricing-block__card-bottom">
                <div className="pricing-block__card-inner-title">{props.t('tariff_modules')}</div>
                <div className="pricing-block__card-list" dangerouslySetInnerHTML={{__html: data?.modules_text}}>
                </div>
            </div>
            <a href="#" onClick={(e) => {e.preventDefault(); handleSelect(data)}}
               className={classnames({
                   'tariff-btn tariff-btn--select': true,
                   'tariff-btn--select_disabled': isDisabled,
               })}
            >
                <svg width="16" height="16" className="selected">
                    <svg id="selected" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="16" height="16" rx="8" fill="#fff"></rect><path d="M4.8 8.693L7.115 10.4 11.2 4.8" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                </svg>
                {isCurrent ? (
                    <span className="disabled">{props.t('subscribed')}</span>
                ) : (
                    <>
                        <span className="active">{props.t('select')}</span>
                        <span className="disabled">{props.t('selected')}</span>
                    </>
                )}
            </a>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        currentTariff: state.user.data?.subscription?.tariff,
        subscription: state.user.data?.subscription,
    };
};

const mapDispatchToProps = {};

export const TariffCard = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TariffCardComponent)));