import {
    FETCH_LEAD,
    FETCH_LEAD_SUCCESS,
    FETCH_LEAD_ERROR,
    CLEAN_LEAD,

    LEAD_UPDATE_DATA,
} from './actionTypes';

// FETCH LEAD
export const fetchLead = (id, params) => {
    return {
        type: FETCH_LEAD,
        payload: { id, params },
    }
};

export const fetchLeadSuccess = (data) => {
    return {
        type: FETCH_LEAD_SUCCESS,
        payload: data,
    }
};

export const fetchLeadError = (data) => {
    return {
        type: FETCH_LEAD_ERROR,
        payload: data,
    }
};

export const cleanLead = () => {
    return { type: CLEAN_LEAD };
};

// UPDATE LEAD DATA
export const leadUpdateData = (data) => {
    return {
        type: LEAD_UPDATE_DATA,
        payload: data,
    }
};