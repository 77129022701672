import {
    SET_CHAT_CONVERSATION,
    UNSET_CHAT_CONVERSATION,

    UPDATE_CHAT_CONVERSATION_PARTICIPANT_DATA,

    SET_BACKGROUND_UNREAD_MESSAGES_STATUS,
} from './actionTypes';

const initialState = {
    participant: null,
    backgroundUnreadMessagesStatus: false,
};

const data = (state = initialState, action) => {
    switch (action.type) {

        case SET_CHAT_CONVERSATION:
            state = {
                ...state,
                participant: action.payload,
            };
            break;

        case UNSET_CHAT_CONVERSATION:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case UPDATE_CHAT_CONVERSATION_PARTICIPANT_DATA:
            state = {
                ...state,
                participant: {
                    ...state.participant,
                    ...action.payload,
                },
            };
            break;

        case SET_BACKGROUND_UNREAD_MESSAGES_STATUS:
            state = {
                ...state,
                backgroundUnreadMessagesStatus: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default data;