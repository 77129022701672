import { all } from 'redux-saga/effects';

import sendSaga from './send/saga';
import messagesSaga from './messages/saga';
import messageSaga from './message/saga';

function* conversationSaga() {
    yield all([
        sendSaga(),
        messagesSaga(),
        messageSaga(),
    ])
}

export default conversationSaga;