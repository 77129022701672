import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';
import i18n from '../../../i18n';

import { CREATE_PROPERTY_REQUEST } from './actionTypes';
import {
    createPropertyRequestSuccess,
    createPropertyRequestError,
} from './actions';

import { API } from '../../../api';

function* createPropertyRequest({ payload: { params, history } }) {
    try {
        const response = yield call(API.propertyRequest.createPropertyRequest, params);

        const { data } = response;

        yield put(createPropertyRequestSuccess(true));
        history.push('/property-requests');

        toastr.success(i18n.t('property_request.create.success'));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(createPropertyRequestError({
            errors,
            message
        }));
    }
}

export function* watchCreatePropertyRequest() {
    yield takeEvery(CREATE_PROPERTY_REQUEST, createPropertyRequest)
}

function* createSaga() {
    yield all([
        fork(watchCreatePropertyRequest),
    ]);
}

export default createSaga;