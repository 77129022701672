import _ from 'lodash';

import {
    PROPERTY_OUTSIDE_FACING_NORTH,
    PROPERTY_OUTSIDE_FACING_EAST,
    PROPERTY_OUTSIDE_FACING_SOUTH,
    PROPERTY_OUTSIDE_FACING_WEST,
} from '../../consts/property';

export const getOutsideFacingOptions = (t) => {
    return [{
        label: t('north'),
        value: PROPERTY_OUTSIDE_FACING_NORTH,
    }, {
        label: t('east'),
        value: PROPERTY_OUTSIDE_FACING_EAST,
    }, {
        label: t('south'),
        value: PROPERTY_OUTSIDE_FACING_SOUTH,
    }, {
        label: t('west'),
        value: PROPERTY_OUTSIDE_FACING_WEST,
    }];
};

export const getOutsideFacing = (value, t) => {
    const options = getOutsideFacingOptions(t);
    const item = _.find(options, { value: value });

    return item || {
        label: '',
        value: '',
    };
};

export default {
    getOutsideFacingOptions,
    getOutsideFacing,
};