import {
    ECHO_ADD_CHANNEL_LISTENER,
    ECHO_LEAVE_CHANNEL,

    ECHO_CHANNEL_ADD_WHISPER_LISTENER,
    ECHO_CHANNEL_WHISPER,
} from './actionTypes';

import { EchoClient } from '../../../services';

export const echoMiddleware = store => next => action => {
    switch (action.type) {
        case ECHO_ADD_CHANNEL_LISTENER:
            EchoClient.echo.private(action.payload.channel)
                .listen(action.payload.event, action.payload.callback);
            break;

        case ECHO_LEAVE_CHANNEL:
            EchoClient.leave(action.payload.channel);
            break;

        case ECHO_CHANNEL_ADD_WHISPER_LISTENER:
            EchoClient.echo.private(action.payload.channel)
                .listenForWhisper(action.payload.event, action.payload.callback);
            break;

        case ECHO_CHANNEL_WHISPER:
            EchoClient.echo.private(action.payload.channel)
                .whisper(action.payload.event, action.payload.data);
            break;

        default:
            break;
    }

    return next(action);
};