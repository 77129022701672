export const LEAD_FETCH_ALL_PROPERTIES = 'LEAD_FETCH_ALL_PROPERTIES';
export const LEAD_FETCH_ALL_PROPERTIES_SUCCESS = 'LEAD_FETCH_ALL_PROPERTIES_SUCCESS';
export const LEAD_FETCH_ALL_PROPERTIES_ERROR = 'LEAD_FETCH_ALL_PROPERTIES_ERROR';
export const LEAD_CLEAN_ALL_PROPERTIES = 'LEAD_CLEAN_ALL_PROPERTIES';

export const LEAD_ALL_PROPERTIES_SET_FILTER = 'LEAD_ALL_PROPERTIES_SET_FILTER';
export const LEAD_ALL_PROPERTIES_RESET_FILTER = 'LEAD_ALL_PROPERTIES_RESET_FILTER';

export const LEAD_ALL_PROPERTIES_SET_SORT = 'LEAD_ALL_PROPERTIES_SET_SORT';
export const LEAD_ALL_PROPERTIES_RESET_SORT = 'LEAD_ALL_PROPERTIES_RESET_SORT';

export const LEAD_ALL_PROPERTIES_SET_PAGE = 'LEAD_ALL_PROPERTIES_SET_PAGE';

export const LEAD_ALL_PROPERTIES_UPDATE_PROPERTY = 'LEAD_ALL_PROPERTIES_UPDATE_PROPERTY';