import React, { useEffect } from 'react';
import { Row, Card, CardHeader, CardBody } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { UserLeadList } from './UserLeadList';

const UserLeadsComponent = (props) => {
    useEffect(() => {
        return () => {
        }
    }, []);

    return (
        <Card>
            <CardHeader className="bg-transparent">
                <Row className="no-gutters justify-content-between">
                    <h5 className={'mt-2 mr-2'}>{props.t('lead.list')}</h5>
                </Row>
            </CardHeader>
            <CardBody>

                    <UserLeadList />

            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => {
    const { activeTab } = state.lead.companyLeads;
    return {
        activeTab,
    };
};

const mapDispatchToProps = {
};

export const UserLeads = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserLeadsComponent)));