import {
    FETCH_CHAT_CONTACTS,
    FETCH_CHAT_CONTACTS_SUCCESS,
    FETCH_CHAT_CONTACTS_ERROR,
    CLEAN_CHAT_CONTACTS,
} from './actionTypes';

export const fetchChatContacts = (params) => {
    return {
        type: FETCH_CHAT_CONTACTS,
        payload: { params },
    }
};

export const fetchChatContactsSuccess = (data) => {
    return {
        type: FETCH_CHAT_CONTACTS_SUCCESS,
        payload: data,
    }
};

export const fetchChatContactsError = (data) => {
    return {
        type: FETCH_CHAT_CONTACTS_ERROR,
        payload: data,
    }
};

export const cleanChatContacts = () => {
    return { type: CLEAN_CHAT_CONTACTS };
};