import {
    EDIT_BILLING_INFO,
    EDIT_BILLING_INFO_SUCCESS,
    EDIT_BILLING_INFO_ERROR,
    CLEAN_BILLING_INFO_EDIT,
} from './actionTypes';

const initialState = {
    editLoading: false,
    editSuccess: null,
    editError: null,
};

const edit = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_BILLING_INFO:
            state = {
                ...state,
                editLoading: true,
                editSuccess: null,
                editError: null,
            };
            break;

        case EDIT_BILLING_INFO_SUCCESS:
            state = {
                ...state,
                editLoading: false,
                editSuccess: action.payload,
            };
            break;

        case EDIT_BILLING_INFO_ERROR:
            state = {
                ...state,
                editLoading: false,
                editError: action.payload,
            };
            break;

        case CLEAN_BILLING_INFO_EDIT:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default edit;