import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';

import placeholder from 'assets/images/custom/property-placeholder.jpg';
import { PropertyFavoriteButton, PropertyRibbon, SuggestedPropertyLabel } from 'components/Custom';
import { clientPropertiesSendFavoriteStatus } from 'store/actions';
import { isPropertyNewStatus } from "helpers";
import { PropertyStatusMark } from "./PropertyStatusMark";
import { PropertyDetailsTable } from "./PropertyDetailsTable";
import { CompanyDetails } from "./CompanyDetails";
import { PriceBlock } from "./PriceBlock";
import { useMedia } from "react-use";

const PropertyCardComponent = (props) => {
    const isWide = useMedia(`(min-width: 1340px)`);

    const { item } = props;

    const toggleFavorite = (status) => {
        props.clientPropertiesSendFavoriteStatus(item.id, { status: status })
    };

    const isNew = useMemo(() => {
        return isPropertyNewStatus(item);
    }, [item]);

    return (
            <div className="property-card">
                <div className="property-card__image-block">
                    {isNew && <PropertyRibbon/>}
                    {!isWide &&

                    <div className={"property-card__label-block"}>
                        <PropertyFavoriteButton className={"label"} status={item.is_favorite}
                                                onChange={toggleFavorite}/>
                        {item.myReceivedLink?.sender &&
                        <SuggestedPropertyLabel className={"label"} sender={item.myReceivedLink?.sender}/>}
                    </div>}
                    <PropertyStatusMark status={item.status} className={"property-card__status_mark"}/>
                    <Link to={`properties/${item.id}`} className={"property-card__image-link"} >
                        <img className="property-card__image" src={item.avatar ? item.avatar : placeholder}
                             alt={"Property Image"}/>
                    </Link>
                </div>

                <div className="property-card__info-block">
                    <div className={"table-responsive"}>
                        <PropertyDetailsTable item={item}/>
                    </div>

                    <div className={"d-flex justify-content-between flex-wrap"}>
                        <PriceBlock item={item} price={item.price} offerPrice={item.myReceivedLink?.price}/>
                        <CompanyDetails company={item.myReceivedLink?.sender?.company}/>
                    </div>
                </div>
                {isWide && <div className={"property-card__label-block"}>
                    <PropertyFavoriteButton status={item.is_favorite} onChange={toggleFavorite}/>
                    {item.myReceivedLink?.sender && <SuggestedPropertyLabel sender={item.myReceivedLink?.sender}/>}
                </div>}
            </div>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    clientPropertiesSendFavoriteStatus,
};

export const PropertyCard = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyCardComponent)));