import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { useTitle } from "../../hooks";
import {
    fetchUsersFromReceivedInvites,
    cleanUsersFromReceivedInvites,
    usersFromReceivedInvitesSetSort,
    usersFromReceivedInvitesSetFilter,
    usersFromReceivedInvitesResetFilter,
    usersFromReceivedInvitesSetPage,
} from '../../store/actions';
import { ContactsTable, ContactsFilter } from './components';
import { Pagination, SearchInput, PageTitle } from '../../components/Custom';

import * as HOC from '../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const ContactListComponent = (props) => {
    useTitle(props.t('title.contacts'));

    const [search, setSearch] = useState(null);

    useEffect(() => {
        let params = {
            page: props.page,
        };

        if (props.sort.length > 0) {
            params.sort = props.sort;
        }

        if (props.filter.isActive) {
            params.filter = props.filter.values;
        }

        if (search) {
            params.filter = {
                ...params.filter,
                keywords: search,
                search_fields: ['name', 'email', 'phone'],
            };
        }

        props.fetchUsersFromReceivedInvites(params);

    }, [props.sort, props.filter, props.page, search]);

    useEffect(() => {
        return () => {
            props.cleanUsersFromReceivedInvites();
            props.usersFromReceivedInvitesResetFilter();
        }
    }, []);

    const filterWarningVisible = useMemo(() => {
        return (props.filter.isActive || search) && props.list.length === 0 && !props.loading;
    }, [props.filter, props.list, props.loading, search]);

    const handleSort = useCallback(sortBy => {
        if (!_.isEqual(sortBy, props.sort)) {
            props.usersFromReceivedInvitesSetSort(sortBy);
        }
    }, [props.sort]);

    const handleResetFilter = () => {
        props.usersFromReceivedInvitesResetFilter()
    };

    const handleApplyFilter = (values) => {
        props.usersFromReceivedInvitesSetFilter(values);
    };

    const handlePageChange = (item) => {
        props.usersFromReceivedInvitesSetPage(item.selected + 1);
    };

    const handleSearchChange = (value) => {
        props.usersFromReceivedInvitesSetPage(1);
        setSearch(value);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('title.contacts')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <h5 className={'mt-2 mr-2'}>{props.t('vendors.your.list')}</h5>

                                        <div className="button-items">
                                            <ContactsFilter
                                                filter={props.filter}
                                                onReset={handleResetFilter}
                                                onApply={handleApplyFilter}
                                            />
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row className={'no-gutters justify-content-between'}>
                                        <SearchInput
                                            onChange={handleSearchChange}
                                            searchFields={
                                                ['search.field.consultants_name', 'search.field.email', 'search.field.phone']
                                            }
                                            hintExample={'John Doe'}
                                        />
                                    </Row>

                                    <TableDataLoading isLoading={props.loading} isFailed={props.error} error={props.t(props.error)}>

                                        {filterWarningVisible ? (
                                            <Alert color="primary">{props.t('contacts.filter.empty')}</Alert>
                                        ) : (
                                            <>
                                                <ContactsTable
                                                    items={props.list}
                                                    isLoading={props.loading}
                                                    onSort={handleSort}
                                                />

                                                <Pagination data={props.meta} onPageChange={handlePageChange} />
                                            </>
                                        )}
                                    </TableDataLoading>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { receivedUsers, receivedUsersLoading, receivedUsersError, receivedUsersSort, receivedUsersFilter, receivedUsersPage, receivedUsersMeta } = state.invite.users;
    return {
        language: state.common.language,
        list: receivedUsers,
        loading: receivedUsersLoading,
        error: receivedUsersError,
        sort: receivedUsersSort,
        filter: receivedUsersFilter,
        meta: receivedUsersMeta,
        page: receivedUsersPage,
    };
};

const mapDispatchToProps = {
    fetchUsersFromReceivedInvites,
    cleanUsersFromReceivedInvites,
    usersFromReceivedInvitesSetSort,
    usersFromReceivedInvitesSetFilter,
    usersFromReceivedInvitesResetFilter,
    usersFromReceivedInvitesSetPage,
};

export const ContactList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactListComponent)));