export const FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST = 'FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST';
export const FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST_SUCCESS = 'FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST_SUCCESS';
export const FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST_ERROR = 'FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST_ERROR';
export const CLEAN_SUGGESTED_PROPERTY_PROPERTY_REQUEST = 'CLEAN_SUGGESTED_PROPERTY_PROPERTY_REQUEST';

export const SUGGESTED_PROPERTY_PROPERTY_REQUEST_SET_FILTER = 'SUGGESTED_PROPERTY_PROPERTY_REQUEST_SET_FILTER';
export const SUGGESTED_PROPERTY_PROPERTY_REQUEST_RESET_FILTER = 'SUGGESTED_PROPERTY_PROPERTY_REQUEST_RESET_FILTER';

export const SUGGESTED_PROPERTY_PROPERTY_REQUEST_SET_SORT = 'SUGGESTED_PROPERTY_PROPERTY_REQUEST_SET_SORT';
export const SUGGESTED_PROPERTY_PROPERTY_REQUEST_RESET_SORT = 'SUGGESTED_PROPERTY_PROPERTY_REQUEST_RESET_SORT';

export const SUGGESTED_PROPERTY_PROPERTY_REQUEST_SET_PAGE = 'SUGGESTED_PROPERTY_PROPERTY_REQUEST_SET_PAGE';