import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getPropertyStatusConfig } from '../../../helpers';

export const PropertyStatusLabel = ({ status, classNames = 'font-size-12'  }) => {
    const { t } = useTranslation();

    const config = useMemo(() => {
        return getPropertyStatusConfig(status, t);
    }, [status]);

    return (
        <span
            className={classNames + ' text-' + (config.color)}
        >
            {config.label}
        </span>
    );
};