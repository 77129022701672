import React from 'react';
import PropTypes from 'prop-types';

import NumberFormat from "react-number-format";
import classNames from "classnames";

import s from "./PriceBlock.module.scss"


export const PriceBlock = ({ price, offerPrice }) => {

    const hasOfferPrice = Boolean(offerPrice)

    return (
        <div className={s.price_block}>
            {hasOfferPrice && (
                <>
                    <NumberFormat
                        value={offerPrice}
                        thousandSeparator={' '}
                        prefix={'€ '}
                        displayType={'text'}
                        className={'font-size-17 font-weight-bold'}
                    />
                    {` `}
                </>
            )}

            {price !== null && price >= 0 ? (
                <NumberFormat
                    value={price}
                    thousandSeparator={' '}
                    prefix={'€ '}
                    displayType={'text'}
                    className={classNames("font-weight-bold", {
                        'font-size-11': hasOfferPrice,
                        'font-size-17': !hasOfferPrice,
                        [s.price_with_offer]: hasOfferPrice,
                    })}
                />
            ) : '-'}
        </div>
    );
};

PriceBlock.propTypes = {
    price: PropTypes.number,
    offerPrice: PropTypes.number
};


