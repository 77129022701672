import {
    FETCH_OFFERS,
    FETCH_OFFERS_SUCCESS,
    FETCH_OFFERS_ERROR,
    CLEAN_OFFERS_PAGE,

    OFFERS_SET_SORT,

    OFFERS_SET_FILTER,
    OFFERS_RESET_FILTER,

    OFFERS_SET_PAGE,

    UPDATE_OFFER_ITEM,
} from './actionTypes';

import { OFFERS_FILTER_INITIAL_VALUES } from "../../../consts/offer";

const initialState = {
    offers: [],
    offersLoading: false,
    offersError: null,
    offersSort: [{id: "price_status", desc: false}],
    page: 1,
    meta: null,
    filter: {
        isActive: false,
        values: OFFERS_FILTER_INITIAL_VALUES,
    },
};

function updateObjectInArray(array, payload) {
    return array.map((item, index) => {
        if (item.id !== payload.id) {
            // This isn't the item we care about - keep it as-is
            return item
        }

        // Otherwise, this is the one we want - return an updated value
        return {
            ...item,
            ...payload.data,
        }
    })
}

const lists = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_OFFERS:
            state = {
                ...state,
                offersLoading: true,
                offersError: null,
            };
            break;

        case FETCH_OFFERS_SUCCESS:
            state = {
                ...state,
                offersLoading: false,
                offers: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case FETCH_OFFERS_ERROR:
            state = {
                ...state,
                offersLoading: false,
                offersError: action.payload,
            };
            break;

        case CLEAN_OFFERS_PAGE:
            state = {
                ...state,
                offers: [],
                offersLoading: false,
                offersError: null,
                offersSort: initialState.offersSort,
                page: initialState.page,
                meta: null,
            };
            break;

        case OFFERS_SET_SORT:
            state = {
                ...state,
                offersSort: action.payload,
                page: initialState.page,
            };
            break;

        // SET FILTER
        case OFFERS_SET_FILTER:
            state = {
                ...state,
                filter: {
                    isActive: true,
                    values: {
                        ...initialState.filter.values,
                        ...action.payload,
                    },
                },
                page: initialState.page,
            };
            break;

        case OFFERS_RESET_FILTER:
            state = {
                ...state,
                filter: initialState.filter,
                page: initialState.page,
            };
            break;

        // SET PAGE
        case OFFERS_SET_PAGE:
            state = {
                ...state,
                page: action.payload,
            };
            break;

        case UPDATE_OFFER_ITEM:
            state = {
                ...state,
                offers: updateObjectInArray([...state.offers], action.payload),
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default lists;