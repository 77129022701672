import { all } from 'redux-saga/effects';

import paySaga from './pay/saga';
import cardSaga from './card/sagas';
import billingInfoSaga from './info/sagas';
import subscriptionSaga from './subscription/sagas';
import invoiceSaga from './invoice/sagas';

function* billingSaga() {
    yield all([
        paySaga(),
        cardSaga(),
        billingInfoSaga(),
        subscriptionSaga(),
        invoiceSaga(),
    ])
}

export default billingSaga;