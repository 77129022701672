import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from "reactstrap";

import { ContactListItem } from './ContactListItem';

export const ContactsList = (props) => {
    const { t } = useTranslation();
    return (
        <>
            {props.items.length > 0 ? (
                <ul className="list-unstyled chat-list">
                    {props.items.map(item => (
                        <ContactListItem item={item} key={'contact-item-' + item.id}/>
                    ))}
                </ul>
            ) : (
                <Alert color="primary">{t('chat.no_contacts')}</Alert>
            )}
        </>
    );
};
