import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from "reactstrap";

import ButtonLoadingIcon from "components/Common/ButtonLoadingIcon";

export const AccountAdd = ({onAdd, disabled = false, loading = false}) => {
  const {t} = useTranslation()

  return (
    <Button
      color={'primary'}
      className="btn-sm waves-effect waves-light"
      disabled={disabled}
      onClick={onAdd}
    >
      <ButtonLoadingIcon loading={loading}/>
      {t('add_account')}
    </Button>
  );
};