import React, { useEffect } from 'react';
import { Container } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { useTitle } from "../../hooks";
import { PageTitle } from '../../components/Custom';
import { LeadPreferences as PreferencesSection } from './components';

const LeadPreferencesComponent = (props) => {
    useTitle(props.t('title.brikk_preferences'));

    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <React.Fragment>
            <div className="page-content min-vh-100">
                <Container fluid>

                    <PageTitle title={props.t('title.brikk_preferences')} />

                    <PreferencesSection />

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const LeadPreferences = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadPreferencesComponent)));