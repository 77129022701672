import {
    LEAD_FETCH_ALL_PROPERTIES,
    LEAD_FETCH_ALL_PROPERTIES_SUCCESS,
    LEAD_FETCH_ALL_PROPERTIES_ERROR,
    LEAD_CLEAN_ALL_PROPERTIES,

    LEAD_ALL_PROPERTIES_SET_FILTER,
    LEAD_ALL_PROPERTIES_RESET_FILTER,

    LEAD_ALL_PROPERTIES_SET_SORT,
    LEAD_ALL_PROPERTIES_RESET_SORT,

    LEAD_ALL_PROPERTIES_SET_PAGE,

    LEAD_ALL_PROPERTIES_UPDATE_PROPERTY,
} from './actionTypes';

import { PROPERTY_FILTER_INITIAL_VALUES } from '../../../../consts/property';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    page: 1,
    meta: null,

    filter: {
        isActive: false,
        values: PROPERTY_FILTER_INITIAL_VALUES,
    },
    sort: [],
};

function updateObjectInArray(array, payload) {
    return array.map((item, index) => {
        if (item.id !== payload.id) {
            return item
        }

        return {
            ...item,
            ...payload.data,
        }
    })
}

const list = (state = initialState, action) => {
    switch (action.type) {

        // PROPERTY LIST
        case LEAD_FETCH_ALL_PROPERTIES:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case LEAD_FETCH_ALL_PROPERTIES_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case LEAD_FETCH_ALL_PROPERTIES_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case LEAD_CLEAN_ALL_PROPERTIES:
            state = {
                ...state,
                ...initialState,
            };
            break;


        // PROPERTY FILTER
        case LEAD_ALL_PROPERTIES_SET_FILTER:
            state = {
                ...state,
                filter: {
                    isActive: true,
                    values: {
                        ...initialState.filter.values,
                        ...action.payload,
                    },
                },
                page: initialState.page,
            };
            break;

        case LEAD_ALL_PROPERTIES_RESET_FILTER:
            state = {
                ...state,
                filter: initialState.filter,
                page: initialState.page,
            };
            break;


        // PROPERTY SORT
        case LEAD_ALL_PROPERTIES_SET_SORT:
            state = {
                ...state,
                sort: action.payload,
                page: initialState.page,
            };
            break;

        case LEAD_ALL_PROPERTIES_RESET_SORT:
            state = {
                ...state,
                sort: initialState.sort,
                page: initialState.page,
            };
            break;

        // PROPERTY SET PAGE
        case LEAD_ALL_PROPERTIES_SET_PAGE:
            state = {
                ...state,
                page: action.payload,
            };
            break;


        // UPDATE PROPERTY DATA IN LIST
        case LEAD_ALL_PROPERTIES_UPDATE_PROPERTY:
            state = {
                ...state,
                list: updateObjectInArray([...state.list], action.payload),
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default list;