import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';
import i18n from '../../../i18n';

import { PROPERTY_REQUEST_VIEW_CLOSE_REQUEST } from './actionTypes';
import {
    closePropertyRequestSuccess,
    closePropertyRequestError,
} from './actions';

import { API } from '../../../api/index';

function* closePropertyRequest({ payload: { id, history } }) {
    try {
        const response = yield call(API.propertyRequest.closePropertyRequest, id);

        const { data } = response;
        yield put(closePropertyRequestSuccess(true));
        history.push('/property-requests');

        toastr.success(i18n.t('property_request.closed.success'))

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(closePropertyRequestError(message));

        toastr.error(message);
    }
}

export function* watchClosePropertyRequest() {
    yield takeEvery(PROPERTY_REQUEST_VIEW_CLOSE_REQUEST, closePropertyRequest)
}

function* reactionSaga() {
    yield all([
        fork(watchClosePropertyRequest),
    ]);
}

export default reactionSaga;