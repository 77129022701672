import {
    FETCH_PROPERTY_REQUEST,
    FETCH_PROPERTY_REQUEST_SUCCESS,
    FETCH_PROPERTY_REQUEST_ERROR,
    CLEAN_PROPERTY_REQUEST,
} from './actionTypes';

// FETCH PROPERTY REQUEST
export const fetchPropertyRequest = (id) => {
    return {
        type: FETCH_PROPERTY_REQUEST,
        payload: { id },
    }
};

export const fetchPropertyRequestSuccess = (data) => {
    return {
        type: FETCH_PROPERTY_REQUEST_SUCCESS,
        payload: data,
    }
};

export const fetchPropertyRequestError = (data) => {
    return {
        type: FETCH_PROPERTY_REQUEST_ERROR,
        payload: data,
    }
};

export const cleanPropertyRequest = () => {
    return { type: CLEAN_PROPERTY_REQUEST };
};