import {
    FETCH_CONSULTANT_LIST,
    FETCH_CONSULTANT_LIST_SUCCESS,
    FETCH_CONSULTANT_LIST_ERROR,
    CLEAN_CONSULTANT_LIST_PAGE,

    CONSULTANT_SET_SORT,
    CONSULTANT_SET_FILTER,
    CONSULTANT_RESET_FILTER,

    CONSULTANT_SET_PAGE,

    CREATE_CONSULTANT,
    CREATE_CONSULTANT_SUCCESS,
    CREATE_CONSULTANT_ERROR,
    CLEAN_CREATE_CONSULTANT_PAGE,

    FETCH_CONSULTANT,
    FETCH_CONSULTANT_SUCCESS,
    FETCH_CONSULTANT_ERROR,

    EDIT_CONSULTANT,
    EDIT_CONSULTANT_SUCCESS,
    EDIT_CONSULTANT_ERROR,
    CLEAN_EDIT_CONSULTANT_PAGE,

    REMOVE_CONSULTANT,
    REMOVE_CONSULTANT_SUCCESS,
    REMOVE_CONSULTANT_ERROR,

    CLEAN_CONSULTANT_PAGE,
} from './actionTypes';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    page: 1,
    meta: null,

    sort: [],

    filter: {
        isActive: false,
        values: {
            period: null,
        },
    },

    createLoading: false,
    createSuccess: null,
    createError: null,

    consultant: null,
    consultantLoading: false,
    consultantError: null,

    editLoading: false,
    editSuccess: null,
    editError: null,

    removeLoading: false,
    removeSuccess: null,
    removeError: null,
};

const consultant = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONSULTANT_LIST:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case FETCH_CONSULTANT_LIST_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case FETCH_CONSULTANT_LIST_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case CONSULTANT_SET_SORT:
            state = {
                ...state,
                sort: action.payload,
                page: initialState.page,
            };
            break;

        // FILTER
        case CONSULTANT_SET_FILTER:
            state = {
                ...state,
                filter: {
                    isActive: true,
                    values: {
                        ...initialState.filter.values,
                        ...action.payload,
                    },
                },
                page: initialState.page,
            };
            break;

        case CONSULTANT_RESET_FILTER:
            state = {
                ...state,
                filter: initialState.filter,
                page: initialState.page,
            };
            break;

        // CONSULTANT SET PAGE
        case CONSULTANT_SET_PAGE:
            state = {
                ...state,
                page: action.payload,
            };
            break;

        case CLEAN_CONSULTANT_LIST_PAGE:
            state = {
                ...state,
                list: [],
                listLoading: false,
                listError: null,
                sort: [],
                page: initialState.page,
                meta: null,
            };
            break;

        case CREATE_CONSULTANT:
            state = {
                ...state,
                createLoading: true,
                createSuccess: null,
                createError: null,
            };
            break;

        case CREATE_CONSULTANT_SUCCESS:
            state = {
                ...state,
                createLoading: false,
                createSuccess: action.payload,
            };
            break;

        case CREATE_CONSULTANT_ERROR:
            state = {
                ...state,
                createLoading: false,
                createError: action.payload,
            };
            break;

        case CLEAN_CREATE_CONSULTANT_PAGE:
            state = {
                ...state,
                createLoading: false,
                createSuccess: null,
                createError: null,
            };
            break;

        case FETCH_CONSULTANT:
            state = {
                ...state,
                consultantLoading: true,
                consultantError: null,
            };
            break;

        case FETCH_CONSULTANT_SUCCESS:
            state = {
                ...state,
                consultantLoading: false,
                consultant: action.payload,
            };
            break;

        case FETCH_CONSULTANT_ERROR:
            state = {
                ...state,
                consultantLoading: false,
                consultantError: action.payload,
            };
            break;

        case EDIT_CONSULTANT:
            state = {
                ...state,
                editLoading: true,
                editSuccess: null,
                editError: null,
            };
            break;

        case EDIT_CONSULTANT_SUCCESS:
            state = {
                ...state,
                editLoading: false,
                editSuccess: action.payload,
            };
            break;

        case EDIT_CONSULTANT_ERROR:
            state = {
                ...state,
                editLoading: false,
                editError: action.payload,
            };
            break;

        case CLEAN_EDIT_CONSULTANT_PAGE:
            state = {
                ...state,
                editLoading: false,
                editSuccess: null,
                editError: null,
                // consultant: null,
            };
            break;

        case REMOVE_CONSULTANT:
            state = {
                ...state,
                removeLoading: true,
                removeSuccess: null,
                removeError: null,
            };
            break;

        case REMOVE_CONSULTANT_SUCCESS:
            state = {
                ...state,
                removeLoading: false,
                removeSuccess: action.payload,
            };
            break;

        case REMOVE_CONSULTANT_ERROR:
            state = {
                ...state,
                removeLoading: false,
                removeError: action.payload,
            };
            break;

        case CLEAN_CONSULTANT_PAGE:
            state = {
                ...state,
                consultant: null,
                consultantError: null,

                removeLoading: false,
                removeSuccess: null,
                removeError: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default consultant;