import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";

import { InvoiceDetailsCard } from './InvoiceDetailsCard';
import {
    fetchBillingInvoice,
    cleanBillingInvoice,
} from '../../../../../store/actions';

import * as HOC from '../../../../../HOC';
const DataShower = HOC.withDataShower();

const InvoiceDetailsComponent = (props) => {
    let { id } = useParams();

    useEffect(() => {
        props.fetchBillingInvoice(id);

        return () => {
            props.cleanBillingInvoice();
        }
    }, []);

    return (
        <DataShower
            isLoading={props.invoiceLoading}
            isFailed={props.invoiceError}
            error={props.t(props.invoiceError)}
            customLoaderClass={'mt-5'}
        >
            {props.invoice && (
                <InvoiceDetailsCard invoice={props.invoice} />
            )}
        </DataShower>
    );
};

const mapStateToProps = state => {
    const { invoice, invoiceLoading, invoiceError } = state.billing.invoice.view;
    return {
        invoice,
        invoiceLoading,
        invoiceError,
    };
};

const mapDispatchToProps = {
    fetchBillingInvoice,
    cleanBillingInvoice,
};

export const InvoiceDetails = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvoiceDetailsComponent)));