import {
    BILLING_STRIPE_PAY_NEW_CARD,
    BILLING_STRIPE_PAY_CARD,
    BILLING_STRIPE_PAY_CREDITS,
    BILLING_STRIPE_PAY_SUBSCRIPTION,

    BILLING_PAY_SUCCESS,
    BILLING_PAY_ERROR,
    CLEAN_BILLING_PAY,
} from './actionTypes';

// STRIPE PAY
export const billingStripePayNewCard = (stripe, elements, payData, history) => {
    return {
        type: BILLING_STRIPE_PAY_NEW_CARD,
        payload: { stripe, elements, payData, history },
    };
};

export const billingStripePayCard = (payment_method, payData, history) => {
    return {
        type: BILLING_STRIPE_PAY_CARD,
        payload: { payment_method, payData, history },
    };
};

export const billingStripePayCredits = (payment_method, credits, history) => {
    return {
        type: BILLING_STRIPE_PAY_CREDITS,
        payload: { payment_method, credits, history },
    };
};

export const billingStripePaySubscription = (payment_method, tariffId, history) => {
    return {
        type: BILLING_STRIPE_PAY_SUBSCRIPTION,
        payload: { payment_method, tariffId, history },
    };
};


// PAY PAGE STATES
export const billingPaySuccess = () => {
    return {
        type: BILLING_PAY_SUCCESS,
    };
};

export const billingPayError = (data) => {
    return {
        type: BILLING_PAY_ERROR,
        payload: data,
    };
};
export const cleanBillingPay = () => {
    return { type: CLEAN_BILLING_PAY };
};