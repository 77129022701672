import React from 'react';
import { Container } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { useTitle } from "../../hooks";
import { ROLE_CLIENT } from '../../consts/roles';
import { PageTitle } from '../../components/Custom';
import { GeneralProperties } from './components';

const PropertyListComponent = (props) => {
    useTitle(props.t('title.property.list'));

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('property_list')} />
                    {props.user.role !== ROLE_CLIENT &&  <GeneralProperties />}

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user.data,
    };
};

const mapDispatchToProps = {
};

export const PropertyList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyListComponent)));