import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_CLIENT_ACCEPTED_PROPERTIES } from './actionTypes';
import {
    fetchClientAcceptedPropertiesSuccess,
    fetchClientAcceptedPropertiesError,
} from './actions';

import { API } from '../../../../api';
import { getMapRequestParams } from '../../../../utils/request';
import { PROPERTY_TYPE_RECEIVED, PROPERTY_TYPE_SUGGESTED } from '../../../../consts/property';
import { clientPropertiesStatisticsUpdate } from "../statistics/actions";

function* fetchAcceptedProperties({ payload: { params } }) {
    try {
        const includes = ['city', 'myReceivedLink', 'myReceivedLink.sender', 'myReceivedLink.sender.company'];

        let requestParams = {
            includes,
            filter: {
                links_statuses: ['active'],
                leads_links_statuses: ['active'],
                types: [PROPERTY_TYPE_RECEIVED, PROPERTY_TYPE_SUGGESTED],
            }
        };

        requestParams = getMapRequestParams(params, requestParams);

        const response = yield call(API.property.getPropertyList, requestParams);

        const { data } = response;
        yield put(fetchClientAcceptedPropertiesSuccess(data));

        //Update statistics of accepted properties
        yield put(clientPropertiesStatisticsUpdate({ suggested: data.meta.total }))

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchClientAcceptedPropertiesError(message));
    }
}

export function* watchPropertyListFetch() {
    yield takeLatest(FETCH_CLIENT_ACCEPTED_PROPERTIES, fetchAcceptedProperties)
}

function* acceptedSaga() {
    yield all([
        fork(watchPropertyListFetch),
    ]);
}

export default acceptedSaga;