import {
    SUBSCRIPTION_STATUS_ACTIVE,
    SUBSCRIPTION_STATUS_INACTIVE,
    SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT,
    SUBSCRIPTION_STATUS_TRIALING,
    SUBSCRIPTION_STATUS_ON_GRACE_PERIOD,
} from '../../consts/billing/subscription';

export const getStatus = (value, t) => {
    let data = {
        label: '',
        value: '',
        color: '',
    };
    switch (value) {
        case SUBSCRIPTION_STATUS_ACTIVE:
            data = {
                label: t('subscription.status.active'),
                value: SUBSCRIPTION_STATUS_ACTIVE,
                color: 'success'
            };
            break;

        case SUBSCRIPTION_STATUS_INACTIVE:
            data = {
                label: t('subscription.status.inactive'),
                value: SUBSCRIPTION_STATUS_INACTIVE,
                color: 'secondary'
            };
            break;

        case SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT:
            data = {
                label: t('subscription.status.incomplete_payment'),
                value: SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT,
                color: 'secondary'
            };
            break;

        case SUBSCRIPTION_STATUS_TRIALING:
            data = {
                label: t('subscription.status.trialing'),
                value: SUBSCRIPTION_STATUS_TRIALING,
                color: 'warning'
            };
            break;

        case SUBSCRIPTION_STATUS_ON_GRACE_PERIOD:
            data = {
                label: t('subscription.status.on_grace_period'),
                value: SUBSCRIPTION_STATUS_ON_GRACE_PERIOD,
                color: 'secondary'
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};

export default {
    getStatus,
};