import React from 'react';

export const WorkInstruments = ({
    width = "14",
    height = "14",
    viewBox = "0 0 14 14",
    color = '#74788D',
}) => (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.84031 0.301799C9.69982 0.263707 9.5438 0.307549 9.44123 0.409965C8.83305 1.0144 8.22594 1.62027 7.61703 2.22435C7.51663 2.32641 7.38986 2.42415 7.36566 2.574C7.33316 2.712 7.38733 2.85933 7.48918 2.95528C8.76551 4.22488 10.0418 5.49485 11.3182 6.76517C11.4204 6.87477 11.5822 6.92544 11.7281 6.88304C11.8599 6.85321 11.9473 6.74397 12.039 6.65449C12.6032 6.09102 13.1687 5.5297 13.7332 4.96695C13.8275 4.87029 13.9459 4.78296 13.9831 4.64713C14.0283 4.49943 13.9806 4.33197 13.8658 4.2292C12.6259 2.99589 11.3864 1.76258 10.1477 0.528552C10.0566 0.440151 9.97105 0.330907 9.84031 0.301799Z" fill={color} />
        <path d="M3.45202 1.15193C2.76546 0.975129 2.01643 1.03442 1.37104 1.32945C1.21719 1.38515 1.2096 1.62412 1.35912 1.68953C1.88605 1.99246 2.41297 2.2954 2.93918 2.59906C3.04897 2.6659 3.18152 2.71225 3.25266 2.82617C3.37654 3.00118 3.35885 3.23476 3.2942 3.42881C3.18549 3.7436 3.0255 4.04654 2.79183 4.28767C2.64448 4.43572 2.44692 4.5615 2.22915 4.54748C2.09877 4.54676 1.99403 4.46016 1.88532 4.40051C1.35876 4.09721 0.831469 3.79535 0.303819 3.49385C0.167663 3.39719 -0.0316963 3.53626 0.00441933 3.69581C0.0882077 4.53167 0.529181 5.3316 1.20202 5.84044C1.65743 6.19189 2.24504 6.33276 2.8135 6.32557C3.18585 6.33312 3.59215 6.36582 3.89191 6.61018C4.07791 6.77944 4.24946 6.96415 4.43076 7.13844C5.00933 6.5588 5.58899 5.97952 6.17334 5.40562C5.9375 5.12928 5.60127 4.92229 5.46908 4.57048C5.35713 4.2812 5.33293 3.96784 5.33473 3.66059C5.33257 3.11509 5.19208 2.56096 4.87173 2.11285C4.53983 1.63059 4.01796 1.29531 3.45202 1.15193Z" fill={color} />
        <path d="M8.07588 4.56924C7.93792 4.49881 7.82957 4.63464 7.74506 4.72269C5.28955 7.1663 2.83188 9.60813 0.375652 12.0514C0.313533 12.12 0.214577 12.1782 0.214938 12.2821C0.210965 12.3493 0.255387 12.4028 0.300532 12.447C0.801818 12.9429 1.30058 13.4417 1.8015 13.938C1.86723 14.0124 1.99689 14.0253 2.06587 13.9484C4.59577 11.4351 7.12351 8.91924 9.65341 6.40555C9.7372 6.3344 9.73865 6.1964 9.65341 6.12597C9.15393 5.62718 8.65192 5.13055 8.15244 4.63177C8.12933 4.60805 8.10369 4.58721 8.07588 4.56924Z" fill={color} />
        <path d="M8.86368 8.07532C8.27969 8.65208 7.70184 9.23496 7.1164 9.80993C7.29626 9.99751 7.50934 10.1643 7.62238 10.4039C7.79863 10.764 7.78057 11.1748 7.79574 11.5647C7.82355 12.1878 8.06949 12.8098 8.51805 13.2522C8.88426 13.6209 9.3776 13.8441 9.88503 13.9414C10.5145 14.0536 11.1805 13.9795 11.762 13.7107C11.9079 13.654 11.9097 13.424 11.7692 13.3589C11.1765 13.0121 10.577 12.6769 9.98399 12.3308C9.78354 12.1986 9.74237 11.9251 9.80449 11.708C9.90959 11.3286 10.1111 10.9692 10.3979 10.6957C10.5687 10.5344 10.8352 10.4255 11.0595 10.5409C11.6496 10.8758 12.234 11.2208 12.8256 11.5532C12.9462 11.6329 13.1279 11.5287 13.1224 11.3868C13.061 10.6659 12.7313 9.97451 12.2195 9.46243C11.8504 9.09301 11.3679 8.82709 10.8457 8.7563C10.4127 8.67903 9.9569 8.76564 9.5376 8.6068C9.25698 8.51193 9.07026 8.27188 8.86368 8.07532Z" fill={color} />
    </svg>
);