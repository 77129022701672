import React, { useEffect } from 'react';
import { Tab } from "./Tab";
import s from "./ClientPropertiesTabs.module.scss"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getPropertyStatistics } from "helpers";
import { fetchClientPropertiesStatistics } from "store/actions";
import { useMedia } from "react-use";
import { TabsMenu } from "./TabsMenu";

export const ClientPropertiesTabsComponent = ({
                                                  tabs,
                                                  activeTab,
                                                  onTabChange,
                                                  statistics,
                                                  fetchClientPropertiesStatistics
                                              }) => {
    const isWide = useMedia(`(min-width: 700px)`);

    useEffect(() => {
        fetchClientPropertiesStatistics()
    }, []);


    if (isWide) {
        return (
            <ul className={s.navigation}>
                {tabs.map((tab, index) => {
                    return <Tab key={`property-tab-${index}`}
                                tab={tab}
                                count={getPropertyStatistics(statistics, tab.value)}
                                onClick={onTabChange}
                                isActive={activeTab === tab.value}/>
                })}
            </ul>
        );
    } else  {
        return  (<TabsMenu tabs={tabs} activeTab={activeTab} onTabChange={onTabChange} statistics={statistics} />)
    }

};


const mapStateToProps = state => {
    const { data: statistics } = state.property.clientProperties.statistics
    return {
        statistics
    }
};

const mapDispatchToProps = {
    fetchClientPropertiesStatistics
};

export const ClientPropertiesTabs = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientPropertiesTabsComponent)));