import React from 'react';
import classnames from "classnames";

export const PageTitle = ({ title, wrapClass = "mb-4" }) => {
    return (
        <div className={classnames("page-title-box d-flex-inline align-items-center justify-content-between", {
            [wrapClass]: wrapClass
        })}>
            <h3 className="mb-0 font-weight-bold">{title}</h3>
        </div>
    );
}