import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Button, FormGroup, Label } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, formValueSelector, change } from 'redux-form'

import { API } from '../../../../api/index'
import { ROLE_CONSULTANT, ROLE_MANAGER } from '../../../../consts/roles';
import { useAllowed } from '../../../../hooks';
import { MODE_EDIT } from '../../../../consts/common';
import { PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX } from '../../../../consts/property';
import {
    required as requiredValidator,
    number as numberValidator,
    minValue as minValueValidator,
    maxValue as maxValueValidator,
    length as lengthValidator,
} from "../../../../utils/validations";
import { onlyDecimal, formatPrice, normalizePrice } from '../../../../utils/normalize';
import { FORM_PROPERTY_COMMON } from "../../../../consts/forms";
import TextInput from "../../../../components/Common/TextInput";
import SelectInput from "../../../../components/Common/SelectInput";
import SwitchField from "../../../../components/Common/SwitchField";
import ButtonLoadingIcon from '../../../../components/Common/ButtonLoadingIcon';
import { MapComponent, UploadPhotos, EditPropertyPhotos, DatePickerField, CitySelectField } from '../../../../components/Custom';
import { getPropertyStatusOptions } from '../../../../helpers';

const priceMinValue = minValueValidator(PROPERTY_PRICE_MIN);
const priceMaxValue = maxValueValidator(PROPERTY_PRICE_MAX);

const fieldMinValue = minValueValidator(0);

const yearLength = lengthValidator(4);

const PropertyFormComponent = (props) => {
    const [projectOptions, setProjectOptions] = useState([]);
    const [provinceOptions, setProvinceOptions] = useState([]);

    let isStaff = useAllowed([ROLE_MANAGER, ROLE_CONSULTANT], props.user.role);

    const fetchProjects = async () => {
        try {
            const response = await API.project.getProjectList({ pagination: false });
            const { data } = response;

            setProjectOptions(data.data)
        } catch (e) {
        }
    };

    const fetchProvinces = async () => {
        try {
            const response = await API.common.getRegions({ pagination: false });
            const { data } = response;

            setProvinceOptions(data.data)
        } catch (e) {
        }
    };

    const cleanCityValue = (value) => {
        if (props.provinceValue && props.provinceValue.id !== value.id) {
            props.change('city', null);
            props.change('zip', null);
        }
    };

    const handleCityChange = (value) => {
        props.change('zip', value.zip);
    };

    useEffect(() => {
        if (isStaff) {
            fetchProjects();
        }
        fetchProvinces();
    }, []);

    return (
        <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="form-horizontal"
            noValidate={true}
        >
            {isStaff && (
                <Field
                    name="project"
                    label={props.t('field.label.project')}
                    placeholder={props.t('field.placeholder.project')}
                    id={'project'}
                    component={SelectInput}
                    validate={[ requiredValidator ]}
                    options={projectOptions}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.name}`}
                />
            )}

            <Field
                name="region"
                label={props.t('field.label.province')}
                placeholder={props.t('field.placeholder.province')}
                id={'region'}
                component={SelectInput}
                validate={[ requiredValidator ]}
                options={provinceOptions}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                onChange={cleanCityValue}
            />

            {props.provinceValue && (
                <CitySelectField
                    name="city"
                    label={props.t('field.label.city')}
                    placeholder={props.t('field.placeholder.search_select_city')}
                    id={'city'}
                    validate={[ requiredValidator ]}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.id}`}
                    onChange={handleCityChange}
                    byRegion
                    regionId={props.provinceValue?.id}
                />
            )}

            {props.cityValue && (
                <Field
                    name="zip"
                    label={props.t('field.label.property.zip_code')}
                    placeholder={props.t('field.placeholder.property.zip_code')}
                    id={'zip'}
                    type="text"
                    component={TextInput}
                    validate={[ requiredValidator, numberValidator ]}
                    row
                    mode={'number'}
                />
            )}

            <Field
                name="address"
                label={props.t('field.label.address')}
                placeholder={props.t('field.placeholder.address')}
                id={'address'}
                type="text"
                component={TextInput}
                validate={[ requiredValidator ]}
                row
            />

            {/*<FormGroup row className={'mb-4'}>*/}
                {/*<Label className={'col-form-label col-lg-2'}>{props.t('field.label.location')}</Label>*/}

                {/*<Col lg="5">*/}
                    {/*<div className={"property-map-edit"}>*/}
                        {/*<MapComponent*/}
                            {/*setMarker={props.onSetCoordinates}*/}
                            {/*marker={props.coordinates}*/}
                            {/*zoom={props.mode === MODE_EDIT ? 12 : 8}*/}
                        {/*/>*/}
                    {/*</div>*/}
                {/*</Col>*/}
            {/*</FormGroup>*/}

            <Field
                name="unit"
                label={props.t('field.label.unit')}
                placeholder={props.t('field.placeholder.unit')}
                id={'unit'}
                type="text"
                component={TextInput}
                row
            />

            <Field
                name="description"
                label={props.t('field.label.property.description')}
                placeholder={props.t('field.placeholder.property.description')}
                id={'description'}
                type="textarea"
                component={TextInput}
                validate={[ requiredValidator ]}
                row
            />

            <Field
                name="price"
                label={props.t('field.label.property.price')}
                placeholder={props.t('field.placeholder.property.price')}
                id={'price'}
                type="text"
                component={TextInput}
                validate={[ numberValidator, priceMinValue, priceMaxValue ]}
                row
                translateParams={{ min: 0, max: PROPERTY_PRICE_MAX }}
                prependSymbol={'€'}
                format={formatPrice}
                normalize={normalizePrice}
            />

            {props.mode === MODE_EDIT && (
                <FormGroup row className={'mb-4'}>
                    <Label className={'col-form-label col-lg-2'}>{props.t('field.label.images')}</Label>

                    <Col lg="10">
                        <EditPropertyPhotos />
                    </Col>
                </FormGroup>
            )}

            <FormGroup row className={'mb-4'}>
                <Label className={'col-form-label col-lg-2'}>{props.t('field.label.upload_images')}</Label>

                <Col lg="10">
                    <UploadPhotos modelId={props.propertyId} />
                </Col>
            </FormGroup>

            <Field
                name="bedrooms"
                label={props.t('field.label.property.bedrooms')}
                placeholder={props.t('field.placeholder.property.bedrooms')}
                id={'bedrooms'}
                type="text"
                component={TextInput}
                validate={[ numberValidator, fieldMinValue ]}
                translateParams={{ min: 0 }}
                mode={'number'}
                row
            />

            <Field
                name="bathrooms"
                label={props.t('field.label.property.bathrooms')}
                placeholder={props.t('field.placeholder.property.bathrooms')}
                id={'bathrooms'}
                type="text"
                component={TextInput}
                validate={[ numberValidator, fieldMinValue ]}
                translateParams={{ min: 0 }}
                mode={'number'}
                row
            />

            <Field
                name="size"
                label={props.t('field.label.property.size')}
                placeholder={props.t('field.placeholder.property.size')}
                id={'size'}
                type="text"
                component={TextInput}
                validate={[ numberValidator, fieldMinValue ]}
                translateParams={{ min: 0 }}
                row
                prependSymbol={'m²'}
                normalize={onlyDecimal}
            />

            <Field
                name="available_from"
                label={props.t('field.label.property.available_from')}
                placeholder={props.t('field.placeholder.property.available_from')}
                id={'available_from'}
                row
                component={DatePickerField}
                options={{}}
                withClear
            />

            <Field
                name="year"
                label={props.t('field.label.property.year')}
                placeholder={props.t('field.placeholder.property.year')}
                id={'year'}
                type="text"
                component={TextInput}
                validate={[ numberValidator, yearLength ]}
                mode={'number'}
                row
            />

            <Field
                name="size_garden"
                label={props.t('field.label.property.size_garden')}
                placeholder={props.t('field.placeholder.property.size_garden')}
                id={'size_garden'}
                type="text"
                component={TextInput}
                validate={[ numberValidator, fieldMinValue ]}
                translateParams={{ min: 0 }}
                row
                prependSymbol={'m²'}
                normalize={onlyDecimal}
            />

            <Field
                name="size_terrace"
                label={props.t('field.label.property.size_terrace')}
                placeholder={props.t('field.placeholder.property.size_terrace')}
                id={'size_terrace'}
                type="text"
                component={TextInput}
                validate={[ numberValidator, fieldMinValue ]}
                translateParams={{ min: 0 }}
                row
                prependSymbol={'m²'}
                normalize={onlyDecimal}
            />

            <Field
                name="garden_orientation"
                label={props.t('field.label.property.garden_orientation')}
                placeholder={props.t('field.placeholder.property.garden_orientation')}
                id={'garden_orientation'}
                type="text"
                component={TextInput}
                row
            />

            <Field
                name="garages"
                label={props.t('field.label.property.garages')}
                placeholder={props.t('field.placeholder.property.garages')}
                id={'garages'}
                type="text"
                component={TextInput}
                validate={[ numberValidator, fieldMinValue ]}
                translateParams={{ min: 0 }}
                mode={'number'}
                row
            />

            <Field
                name="epc"
                label={props.t('field.label.property.epc')}
                placeholder={props.t('field.placeholder.property.epc')}
                id={'epc'}
                type="text"
                component={TextInput}
                validate={[ numberValidator, fieldMinValue ]}
                translateParams={{ min: 0 }}
                row
                normalize={onlyDecimal}
                prependSymbol={'kWh/m²'}
            />

            <Field
                name="status"
                label={props.t('field.label.property_status')}
                placeholder={props.t('field.placeholder.property_status')}
                id={'property-status'}
                component={SelectInput}
                validate={[ requiredValidator ]}
                options={getPropertyStatusOptions(props.t)}
                isSearchable={false}
            />

            {isStaff && (
                <Field
                    name="enabled"
                    label={props.t('field.label.show')}
                    placeholder={props.t('field.placeholder.visible')}
                    id={'enabled'}
                    type="checkbox"
                    component={SwitchField}
                />
            )}

            <Row className="justify-content-end">
                <Col lg="10">
                    <Row className="no-gutters justify-content-between">
                        <Button
                            type="submit"
                            color="primary"
                            disabled={props.disabled}
                        >
                            <ButtonLoadingIcon loading={props.loading} />
                            {props.mode === MODE_EDIT ? props.t('save') : props.t('create')}
                        </Button>

                        {props.mode === MODE_EDIT && (
                            <Button
                                type="button"
                                color="danger"
                                disabled={props.loading || props.removeLoading}
                                onClick={props.onRemove}
                            >
                                <ButtonLoadingIcon loading={props.removeLoading} />
                                {props.t('remove')}
                            </Button>
                        )}
                    </Row>
                </Col>
            </Row>
        </form>
    );
};

const selector = formValueSelector(FORM_PROPERTY_COMMON);

const mapStateToProps = state => {
    const provinceValue = selector(state, 'region');
    const cityValue = selector(state, 'city');
    const zipValue = selector(state, 'zip');

    return {
        language: state.common.language,
        user: state.user.data,
        provinceValue,
        cityValue,
        zipValue,
    };
};

const mapDispatchToProps = {
    change,
};

const PropertyFormCommon = reduxForm({
    form: FORM_PROPERTY_COMMON,
    enableReinitialize: true,
})(PropertyFormComponent);

export const PropertyForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyFormCommon)));