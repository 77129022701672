import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import toastr from 'toastr';
import i18n from '../../i18n';

import { FETCH_CONSULTANT_LIST, CREATE_CONSULTANT, FETCH_CONSULTANT, EDIT_CONSULTANT, REMOVE_CONSULTANT } from './actionTypes';
import {
    fetchConsultantListSuccess,
    fetchConsultantListError,
    createConsultantSuccess,
    createConsultantError,
    fetchConsultantSuccess,
    fetchConsultantError,
    editConsultantSuccess,
    editConsultantError,
    removeConsultantSuccess,
    removeConsultantError,
} from './actions';

import { FORM_CREATE_CONSULTANT, FORM_EDIT_CONSULTANT } from '../../consts/forms';

import { API } from '../../api'
import { getMapRequestParams } from '../../utils/request';

function* fetchConsultantList({ payload: { params } }) {
    try {
        const requestParams = getMapRequestParams(params);

        const response = yield call(API.consultant.getConsultantList, requestParams);

        const { data } = response;
        yield put(fetchConsultantListSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchConsultantListError(message));
    }
}

function* createConsultant({ payload: { params, history } }) {
    try {
        const response = yield call(API.consultant.createConsultant, params);

        const { data } = response;
        yield put(createConsultantSuccess(true));
        history.push('/consultants');

        toastr.success(i18n.t('consultant.create.success'));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(createConsultantError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_CREATE_CONSULTANT, errors));
    }
}

function* fetchConsultant({ payload: { id } }) {
    try {
        const response = yield call(API.consultant.getConsultant, id);

        const { data } = response;
        yield put(fetchConsultantSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchConsultantError(message)); // Need: Project not found or access denied
    }
}

function* editConsultant({ payload: { id, params } }) {
    try {
        const response = yield call(API.consultant.editConsultant, id, params);

        const { data } = response;
        yield put(editConsultantSuccess('consultant.edit.success'));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(editConsultantError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_EDIT_CONSULTANT, errors));
    }
}

function* removeConsultant({ payload: { id, history } }) {
    try {
        const response = yield call(API.consultant.removeConsultant, id);

        const { data } = response;
        yield put(removeConsultantSuccess(true));
        history.push('/consultants');

        toastr.success(i18n.t('consultant.delete.success'))

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(removeConsultantError(message)); // Need: Project not found or access denied
    }
}

export function* watchConsultantListFetch() {
    yield takeEvery(FETCH_CONSULTANT_LIST, fetchConsultantList)
}

export function* watchCreateConsultant() {
    yield takeEvery(CREATE_CONSULTANT, createConsultant)
}

export function* watchConsultantFetch() {
    yield takeEvery(FETCH_CONSULTANT, fetchConsultant)
}

export function* watchEditConsultant() {
    yield takeEvery(EDIT_CONSULTANT, editConsultant)
}

export function* watchRemoveConsultant() {
    yield takeEvery(REMOVE_CONSULTANT, removeConsultant)
}

function* consultantSaga() {
    yield all([
        fork(watchConsultantListFetch),
        fork(watchCreateConsultant),
        fork(watchConsultantFetch),
        fork(watchEditConsultant),
        fork(watchRemoveConsultant),
    ]);
}

export default consultantSaga;