import {
    BILLING_REMOVE_PAYMENT_CARD,
    BILLING_REMOVE_PAYMENT_CARD_SUCCESS,
    BILLING_REMOVE_PAYMENT_CARD_ERROR,
    BILLING_CLEAN_REMOVE_PAYMENT_CARD,
} from './actionTypes';

const initialState = {
    removeLoading: false,
    removeError: null,
};

const remove = (state = initialState, action) => {
    switch (action.type) {
        case BILLING_REMOVE_PAYMENT_CARD:
            state = {
                ...state,
                removeLoading: true,
                removeError: null,
            };
            break;

        case BILLING_REMOVE_PAYMENT_CARD_SUCCESS:
            state = {
                ...state,
                removeLoading: false,
            };
            break;

        case BILLING_REMOVE_PAYMENT_CARD_ERROR:
            state = {
                ...state,
                removeLoading: false,
                removeError: action.payload,
            };
            break;

        case BILLING_CLEAN_REMOVE_PAYMENT_CARD:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default remove;