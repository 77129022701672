import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';

import i18n from '../../../i18n';

import { LEAD_ASSIGN_CONSULTANT } from './actionTypes';
import {
    leadAssignConsultantSuccess,
    leadAssignConsultantError,
} from './actions';

import { API } from '../../../api';

import { FORM_LEAD_ASSIGN_CONSULTANT } from '../../../consts/forms';

function* assignConsultant({ payload: { id, params } }) {
    try {
        const response = yield call(API.lead.updateLead, id, params);

        const { data } = response;

        yield put(leadAssignConsultantSuccess(i18n.t('lead.assign.success')));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(leadAssignConsultantError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_LEAD_ASSIGN_CONSULTANT, errors));
    }
}

export function* watchAssignConsultant() {
    yield takeEvery(LEAD_ASSIGN_CONSULTANT, assignConsultant)
}

function* assignSaga() {
    yield all([
        fork(watchAssignConsultant),
    ]);
}

export default assignSaga;