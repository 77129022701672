import {
    CANCEL_BILLING_SUBSCRIPTION,
    CANCEL_BILLING_SUBSCRIPTION_SUCCESS,
    CANCEL_BILLING_SUBSCRIPTION_ERROR,
    CLEAN_CANCEL_BILLING_SUBSCRIPTION,
} from './actionTypes';

const initialState = {
    cancelLoading: false,
    cancelSuccess: null,
    cancelError: null,
};

const cancel = (state = initialState, action) => {
    switch (action.type) {
        case CANCEL_BILLING_SUBSCRIPTION:
            state = {
                ...state,
                cancelLoading: true,
                cancelSuccess: null,
                cancelError: null,
            };
            break;

        case CANCEL_BILLING_SUBSCRIPTION_SUCCESS:
            state = {
                ...state,
                cancelLoading: false,
                cancelSuccess: action.payload,
            };
            break;

        case CANCEL_BILLING_SUBSCRIPTION_ERROR:
            state = {
                ...state,
                cancelLoading: false,
                cancelError: action.payload,
            };
            break;

        case CLEAN_CANCEL_BILLING_SUBSCRIPTION:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default cancel;