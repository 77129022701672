import {
    CLIENT_PROPERTIES_SEND_FAVORITE_STATUS
} from './actionTypes';

// PROPERTY SEND FAVORITE STATUS
export const clientPropertiesSendFavoriteStatus = (id, params) => {
    return {
        type: CLIENT_PROPERTIES_SEND_FAVORITE_STATUS,
        payload: { id, params },
    }
};