import React, { useState, useEffect } from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {useTranslation} from "react-i18next";
import {stopSubmit} from 'redux-form';
import {useDispatch} from "react-redux";

import {CredentialsForm} from "./CredentialsForm";
import {useAccountCreate} from "../hooks";
import {FORM_CRM_SYSTEM_ACCOUNT_CREATE_CREDENTIALS} from "consts/forms";

export const AccountCreateCredentials = ({id, crm, visible, toggleVisible}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch()

  const {onSubmit, loading} = useAccountCreate({ crm })

  const [submitError, setSubmitError] = useState(null)

  useEffect(() => {
    return () => {
      if (visible) {
        setSubmitError(null)
      }
    }
  }, [visible])

  const onSuccess = (data) => {
    toggleVisible()
  }

  const onError = (error) => {
    const message = error.response?.data?.message || 'Error';
    const errors = error.response?.data?.errors ? error.response.data.errors : {};

    setSubmitError(message)
    dispatch(stopSubmit(FORM_CRM_SYSTEM_ACCOUNT_CREATE_CREDENTIALS, errors))
  }

  const handleSubmit = async (values) => {
    let params = {
      ...values,
    };

    onSubmit({id, crm, params, onSuccess, onError})
  };

  return (
    <>
      <Modal
        size="lg"
        isOpen={visible}
        toggle={toggleVisible}
      >
        <ModalHeader
          toggle={toggleVisible}
        >
          {t('login')}
        </ModalHeader>

        <ModalBody>
          <CredentialsForm
            onSubmit={handleSubmit}
            onCancel={toggleVisible}
            loading={loading}
            errorMessage={submitError}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

