import axios from 'axios';
import qs from "qs";

class ConsultantAPI {
    getConsultantList = async (params) => {
        return axios.get('api/company/consultants', {
            params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { encode: false });
            },
        });
    };

    getConsultant = async (id) => {
        return axios.get(`api/company/consultants/${id}`);
    };

    createConsultant = async (params) => {
        return axios.post('api/company/consultants', params);
    };

    editConsultant = async (id, params) => {
        return axios.put(`api/company/consultants/${id}`, params);
    };

    removeConsultant = async (id) => {
        return axios.delete(`api/users/${id}`);
    };
}

export const consultant = new ConsultantAPI();
