import {
    SWAP_BILLING_SUBSCRIPTION,
    SWAP_BILLING_SUBSCRIPTION_SUCCESS,
    SWAP_BILLING_SUBSCRIPTION_ERROR,
    CLEAN_SWAP_BILLING_SUBSCRIPTION,
} from './actionTypes';

export const swapBillingSubscription = (tariffId, callback) => {
    return {
        type: SWAP_BILLING_SUBSCRIPTION,
        payload: { tariffId, callback },
    }
};

export const swapBillingSubscriptionSuccess = (data) => {
    return {
        type: SWAP_BILLING_SUBSCRIPTION_SUCCESS,
        payload: data,
    }
};

export const swapBillingSubscriptionError = (data) => {
    return {
        type: SWAP_BILLING_SUBSCRIPTION_ERROR,
        payload: data,
    }
};

export const cleanSwapBillingSubscription = () => {
    return { type: CLEAN_SWAP_BILLING_SUBSCRIPTION };
};