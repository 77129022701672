import {
    RESUME_BILLING_SUBSCRIPTION,
    RESUME_BILLING_SUBSCRIPTION_SUCCESS,
    RESUME_BILLING_SUBSCRIPTION_ERROR,
    CLEAN_RESUME_BILLING_SUBSCRIPTION,
} from './actionTypes';

export const resumeBillingSubscription = () => {
    return {
        type: RESUME_BILLING_SUBSCRIPTION,
    }
};

export const resumeBillingSubscriptionSuccess = (data) => {
    return {
        type: RESUME_BILLING_SUBSCRIPTION_SUCCESS,
        payload: data,
    }
};

export const resumeBillingSubscriptionError = (data) => {
    return {
        type: RESUME_BILLING_SUBSCRIPTION_ERROR,
        payload: data,
    }
};

export const cleanResumeBillingSubscription = () => {
    return { type: CLEAN_RESUME_BILLING_SUBSCRIPTION };
};