import {
    SET_CHAT_CONVERSATION,
    UNSET_CHAT_CONVERSATION,

    UPDATE_CHAT_CONVERSATION_PARTICIPANT_DATA,

    SET_BACKGROUND_UNREAD_MESSAGES_STATUS,
} from './actionTypes';

export const setChatConversation = (data) => {
    return {
        type: SET_CHAT_CONVERSATION,
        payload: data,
    }
};

export const unsetChatConversation = () => {
    return {
        type: UNSET_CHAT_CONVERSATION,
    }
};

export const updateChatConversationParticipantData = (data) => {
    return {
        type: UPDATE_CHAT_CONVERSATION_PARTICIPANT_DATA,
        payload: data,
    }
};

export const setBackgroundUnreadMessagesStatus = (data) => {
    return {
        type: SET_BACKGROUND_UNREAD_MESSAGES_STATUS,
        payload: data,
    }
};