import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';

import { UPDATE_LEAD_PREFERENCES_DATA } from './actionTypes';
import {
    updateLeadPreferencesSuccess,
    updateLeadPreferencesError,
} from './actions';

import { FORM_LEAD_PREFERENCES } from '../../../../consts/forms';

import { API } from '../../../../api';

function* updatePreferences({ payload: { params } }) {
    try {
        const response = yield call(API.lead.updateLeadPreferences, params);

        const { data } = response;
        yield put(updateLeadPreferencesSuccess('lead_preferences.saved.success'));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(updateLeadPreferencesError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_LEAD_PREFERENCES, errors));
    }
}

export function* watchUpdatePreferences() {
    yield takeEvery(UPDATE_LEAD_PREFERENCES_DATA, updatePreferences)
}

function* updateSaga() {
    yield all([
        fork(watchUpdatePreferences),
    ]);
}

export default updateSaga;