import React from 'react';
import { FormGroup, Label, FormFeedback, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

import { DatePicker } from '../DatePicker';

export const DatePickerField = ({
    label,
    placeholder,
    input,
    meta: { touched, error, warning },
    prependSymbol,
    rowLayoutSize = {
        label: '2',
        column: '10',
    },
    options,
    withClear,
    ...props
}) => {
    const { t } = useTranslation();
    const showError = Boolean(error);

    return (
        <FormGroup row className={'mb-4' + (showError ? ' text-danger' : '')}>
            {label && (
                <Label htmlFor={props.id} className={'col-form-label col-lg-' + (rowLayoutSize.label)}>{label}</Label>
            )}

            <Col lg={label ? rowLayoutSize.column : '12'}>
                <div className={'input-group' + (showError ? ' is-invalid' : '')}>
                    {prependSymbol && (
                        <div className="input-group-prepend">
                            <span className="input-group-text input-prepend-custom">{prependSymbol}</span>
                        </div>
                    )}

                    <DatePicker
                        value={input.value}
                        onChange={(e) => input.onChange(e)}
                        placeholder={placeholder}
                        options={options}
                    />
                </div>
                <FormFeedback invalid={showError ? error : null }>
                    { t(error, props.translateParams ? { ...props.translateParams } : {}) }
                </FormFeedback>

                {withClear && input.value && (
                    <div className={'date-input-clear'} onClick={() => input.onChange([])} />
                )}
            </Col>
        </FormGroup>
    );
};