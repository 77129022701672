import {
    FETCH_LEAD_PREFERENCES_DATA,
    FETCH_LEAD_PREFERENCES_DATA_SUCCESS,
    FETCH_LEAD_PREFERENCES_DATA_ERROR,
    CLEAN_LEAD_PREFERENCES_DATA,
} from './actionTypes';

export const fetchLeadPreferences = () => {
    return {
        type: FETCH_LEAD_PREFERENCES_DATA,
    }
};

export const fetchLeadPreferencesSuccess = (data) => {
    return {
        type: FETCH_LEAD_PREFERENCES_DATA_SUCCESS,
        payload: data,
    }
};

export const fetchLeadPreferencesError = (data) => {
    return {
        type: FETCH_LEAD_PREFERENCES_DATA_ERROR,
        payload: data,
    }
};

export const cleanLeadPreferences = () => {
    return { type: CLEAN_LEAD_PREFERENCES_DATA };
};