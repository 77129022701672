import React, { useState, useEffect, useMemo } from 'react';
import { Table } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { connect } from "react-redux";
import NumberFormat from 'react-number-format';
import { useTable, useSortBy } from 'react-table';

import { Icon, PropertyStatusLabel } from '../../../components/Custom';

import placeholder from '../../../assets/images/custom/property-placeholder.jpg';

import './PropertyOverviewTable.scss';

const PropertyOverviewTableComponent = (props) => {
    const { items, tableHeaderStatus = true } = props;

    const location = useLocation();

    const data = useMemo(() => {
        return items;
    }, [items]);

    const [columns, setColumns] = useState([{
        Header: '',
        accessor: 'avatar',
        id: 'avatar',
        Cell: ({ value }) => (
            <div style={{ backgroundImage: `url("${value ? value : placeholder}")` }} className="property-overview-table__image" />
        ),
        className: 'property-overview-table__image-cell',
        disableSortBy: true,
    }, {
        Header: props.t('address'),
        accessor: 'address',
        id: 'address',
        Cell: ({ row, value }) => {
            const { original } = row;
            return (
                <Link to={{ pathname: `/properties/${original.id}`, state: { from: location } }} className="property-overview-table__address font-size-15">
                    {value || "-"}
                </Link>
            );
        },
        className: 'property-overview-table__address-cell',
        disableSortBy: true,
    }, {
        Header: props.t('details'),
        accessor: 'details',
        id: 'details',
        Cell: ({ row, value }) => {
            const { original } = row;
            return (
                <>
                    {original.size !== null && (
                        <div className="property-overview-table__details-item mb-1">
                            <Icon name='size' width={12} height={12} />
                            <div className="property-overview-table__details-item-value ml-1 font-size-12 text-lowercase text-secondary">
                                {original.size} m²
                            </div>
                        </div>
                    )}
                    {original.bedrooms !== null && (
                        <div className="property-overview-table__details-item">
                            <Icon name='bed' width={12} height={12} />
                            <div className="property-overview-table__details-item-value ml-1 font-size-12 text-lowercase text-secondary">
                                {original.bedrooms} {props.t('rooms')}
                            </div>
                        </div>
                    )}
                </>
            )
        },
        disableSortBy: true,
    }, {
        Header: null,
        accessor: 'status',
        id: 'status',
        Cell: ({ row, value }) => {
            const { original } = row;
            const hasOfferPrice = original.myReceivedLink && original.myReceivedLink?.price;

            return (
                <>
                    <div className={'property-overview-table__price-block text-nowrap text-right'}>
                        {hasOfferPrice ? (
                            <NumberFormat
                                value={original.myReceivedLink?.price}
                                thousandSeparator={' '}
                                prefix={'€ '}
                                displayType={'text'}
                                className={'font-size-17 font-weight-medium'}
                            />
                        ) : original.price !== null && original.price >= 0 ? (
                            <NumberFormat
                                value={original.price}
                                thousandSeparator={' '}
                                prefix={'€ '}
                                displayType={'text'}
                                className={'font-size-17 font-weight-medium'}
                            />
                        ) : '-'}
                    </div>

                    <div className={'text-right'}>
                        <PropertyStatusLabel status={value} classNames={'font-size-15'} />
                    </div>
                </>
            )
        },
        disableSortBy: true,
    }]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: props.sort || [],
            },
            defaultCanSort: false,
            disableSortBy: data.length === 0,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
        },
        useSortBy,
    );

    useEffect(() => {
        if (props.onSort) {
            props.onSort(sortBy);
        }
    }, [props.onSort, sortBy]);

    return (
        <div className="table-responsive table-centered">
            <Table className={'property-overview-table'} {...getTableProps()}>

                {tableHeaderStatus && (
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps([
                                        {
                                            className: column.headerClassName,
                                        },
                                        column.getSortByToggleProps({ title: null })
                                    ])}
                                >
                                    <div className={'d-flex'}>
                                        <div className={'d-flex flex-fill'}>
                                            {column.render('Header')}
                                        </div>

                                        <div>
                                            {column.canSort && (
                                                column.isSorted ? (
                                                    <i className={'bx font-size-16 align-middle' + (column.isSortedDesc ? " bx-sort-down" : " bx-sort-up")} />
                                                ) : <i className="bx bx-sort align-middle" style={{ color: '#adb5bd' }} />
                                            )}
                                        </div>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                )}

                <tbody {...getTableBodyProps()}>
                {(
                    rows.length > 0 && rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps([
                                                {
                                                    className: cell.column.className,
                                                },
                                            ])}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                ) || (
                    <tr>
                        <td colSpan={columns.length}>
                            {!props.isLoading ? props.t('empty') : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}} />}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
};

export const PropertyOverviewTable = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyOverviewTableComponent));