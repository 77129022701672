import React, { useEffect, useState } from 'react';
import { Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import { closePropertyRequest, cleanClosePropertyRequest } from '../../../../store/actions';
import ButtonLoadingIcon from '../../../../components/Common/ButtonLoadingIcon';

const PropertyRequestCloseComponent = (props) => {
    const history = useHistory();

    const [confirmModal, setConfirmModal] = useState(false);

    useEffect(() => {
        return () => {
            props.cleanClosePropertyRequest();
        };
    }, []);

    const handleClose = () => {
        setConfirmModal(true);
    };

    const handleConfirm = () => {
        setConfirmModal(false);
        props.closePropertyRequest(props.id, history);
    };

    return (
        <div>
            <Button
                color={'primary'}
                className="btn btn-primary waves-effect waves-light"
                onClick={handleClose}
                disabled={props.closeLoading}
            >
                <ButtonLoadingIcon loading={props.closeLoading} />
                {props.t('close_request')}
            </Button>

            {confirmModal && (
                <SweetAlert
                    title={props.t('confirm.sure')}
                    warning
                    showCancel
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    onConfirm={() => {
                        handleConfirm();
                    }}
                    onCancel={() => {
                        setConfirmModal(false);
                    }}
                    cancelBtnText={props.t('cancel')}
                >
                    {props.t('not.revert')}
                </SweetAlert>
            )}
        </div>
    );
};

const mapStateToProps = state => {
    const {
        closeLoading,
    } = state.propertyRequest.reaction;
    return {
        closeLoading,
    };
};

const mapDispatchToProps = {
    closePropertyRequest,
    cleanClosePropertyRequest,
};

export const PropertyRequestClose = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyRequestCloseComponent)));