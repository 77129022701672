import {
    PROPERTY_REQUEST_TYPE_NEW_HOME,
    PROPERTY_REQUEST_TYPE_INVESTMENT,
} from '../../consts/propertyRequest';

export const getType = (value, t) => {
    let data = {
        label: '',
        value: '',
    };
    switch (value) {
        case PROPERTY_REQUEST_TYPE_NEW_HOME:
            data = {
                label: t('new_home'),
                value: PROPERTY_REQUEST_TYPE_NEW_HOME,
            };
            break;

        case PROPERTY_REQUEST_TYPE_INVESTMENT:
            data = {
                label: t('investment'),
                value: PROPERTY_REQUEST_TYPE_INVESTMENT,
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};

export default {
    getType,
};