import {
    CHAT_ATTACH_FILES,

    CHAT_UPLOAD_FILE,
    CHAT_UPLOAD_FILE_SUCCESS,
    CHAT_UPLOAD_FILE_ERROR,

    CHAT_REMOVE_FILE,
    CHAT_REMOVE_FILE_SUCCESS,
    CHAT_REMOVE_FILE_ERROR,

    CHAT_CLEAN_ATTACHMENTS,
} from './actionTypes';

export const chatAttachFiles = (data) => {
    return {
        type: CHAT_ATTACH_FILES,
        payload: data,
    }
};

export const chatUploadFile = (data) => {
    return {
        type: CHAT_UPLOAD_FILE,
        payload: data,
    }
};

export const chatUploadFileSuccess = (fileId, params) => {
    return {
        type: CHAT_UPLOAD_FILE_SUCCESS,
        payload: { fileId, params },
    }
};

export const chatUploadFileError = (data) => {
    return {
        type: CHAT_UPLOAD_FILE_ERROR,
        payload: data,
    }
};

export const chatRemoveFile = (data) => {
    return {
        type: CHAT_REMOVE_FILE,
        payload: data,
    }
};

export const chatRemoveFileSuccess = (data) => {
    return {
        type: CHAT_REMOVE_FILE_SUCCESS,
        payload: data,
    }
};

export const chatRemoveFileError = (data) => {
    return {
        type: CHAT_REMOVE_FILE_ERROR,
        payload: data,
    }
};

export const chatCleanAttachments = () => {
    return { type: CHAT_CLEAN_ATTACHMENTS };
};