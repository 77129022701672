import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import { ChatListItem } from './ChatListItem';
import { fetchChats, cleanChats } from '../../../../../../store/actions';
import * as HOC from '../../../../../../HOC';

const DataShower = HOC.withDataShower();

const ChatListComponent = (props) => {

    useEffect(() => {
        fetchData();

        return () => {
            props.cleanChats();
        }
    }, []);

    const fetchData = () => {
        props.fetchChats();
    };

    const scrollBarRef = useRef(null);

    return (
        <PerfectScrollbar
            style={{ height: "455px" }}
            ref={scrollBarRef}
        >
            <DataShower
                isLoading={props.loading}
                isFailed={props.error}
                error={props.t(props.error)}
                customLoaderClass={'data-shower-loader-center'}
                spinnerSize={'sm'}
            >
                {props.list.length > 0 ? (
                    <ul className="list-unstyled chat-list">
                        {props.list.map(item => (
                            <ChatListItem item={item} key={'chat-item-' + item.id} />
                        ))}
                    </ul>
                ) : (
                    props.t('chat.no_chats')
                )}
            </DataShower>
        </PerfectScrollbar>
    );
};

const mapStateToProps = state => {
    const { list, loading, error, } = state.chat.chats;

    return {
        list, loading, error,
    };
};

const mapDispatchToProps = {
    fetchChats,
    cleanChats,
};

export const ChatList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatListComponent)));