import React, { useEffect, useRef, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sticky from 'sticky-js';
import { useResizeDetector } from "react-resize-detector";

import { BackButton } from "../../../../../components/Custom";
import { PropertyImageGalleryBlock } from "./PropertyImageGalleryBlock";
import { SUBSCRIPTION_STATUS_TRIALING } from "../../../../../consts/billing/subscription";

import stylesVariables from '../../../../../assets/scss/custom-variables.module.scss';

const PropertyOverviewSectionComponent = (props) => {
    // Sticky logic (move to hook or wrapper component)
    const { headerHeight, gridGutterWidth, footerHeight, trialAlertHeight } = stylesVariables;
    const stickyTopOffset = useMemo(() => {
        let offset = parseFloat(headerHeight) + parseFloat(gridGutterWidth);

        if (props.user?.subscription?.status === SUBSCRIPTION_STATUS_TRIALING) {
            offset = offset + parseFloat(trialAlertHeight)
        }

        return offset;
    }, [props.user])

    const stickyRef = useRef();

    useEffect(() => {
        // make sticky wrapper component
        stickyRef.current = new Sticky('.property-overview__sticky');

        return () => {
            if (stickyRef.current) {
                stickyRef.current.destroy();
                stickyRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (stickyRef.current) {
            stickyRef.current.update();
        }
    }, [props.property]) // <- and update sticky on container height change


    // title (address div) height
    const titleRef = useRef();
    useResizeDetector({
        targetRef: titleRef,
        onResize: (width, height) => props.onTitleResize(width, height),
        // onResize: props.onTitleResize
    });

    // overview size
    const overviewRef = useRef();

    return (
        <div className={'property-overview'} data-sticky-container ref={overviewRef}>
            <div className={'property-overview__sticky'} data-margin-top={stickyTopOffset} data-margin-bottom={24} data-sticky-for="1199">
                <div className={'property-title-block mb-4'} ref={titleRef}>
                    <div className={'d-flex align-self-start'}>
                        <BackButton />
                    </div>

                    <div className={'property-title-block__address font-size-20'}>
                        {props.property.address}
                    </div>
                </div>

                <PropertyImageGalleryBlock />
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const {
        property,
    } = state.property.view;
    const { data } = state.user;
    return {
        property,
        user: data,
    };
};

const mapDispatchToProps = {
};

export const PropertyOverviewSection = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyOverviewSectionComponent)));