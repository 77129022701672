import { all } from 'redux-saga/effects';

import listSaga from './list/saga';
import removeSaga from './remove/saga';
import addSaga from './add/saga';
import defaultSaga from './default/saga';

function* cardSaga() {
    yield all([
        listSaga(),
        removeSaga(),
        addSaga(),
        defaultSaga(),
    ])
}

export default cardSaga;