import React, { useMemo, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { UsersFilterForm } from './UsersFilterForm';
import { getApplyFilterCount } from '../../../utils/filter';
import { FilterModal } from '../FilterModal';

const UsersFilterComponent = (props) => {
    const modalRef = createRef();

    const initialFormValues = useMemo(() => {
        let values = {
            ...props.filter?.values,
        };

        return values;
    }, [props.filter]);

    const onSubmit = (values) => {
        let params = {
            period: values.period && values.period.length === 2 ? values.period : null,
        };

        props.onApply(params);

        if (modalRef.current) {
            modalRef.current.hide();
        }
    };

    const handleReset = () => {
        props.onReset();

        if (modalRef.current) {
            modalRef.current.hide();
        }
    };

    const count = useMemo(() => {
        return props.filter?.isActive ? getApplyFilterCount(props.filter?.values) : 0;
    }, [props.filter]);

    return (
        <FilterModal
            onVisibleChange={(value) => {}}
            count={count}
            ref={modalRef}
        >
            <UsersFilterForm
                initialValues={initialFormValues}
                onSubmit={onSubmit}
                onReset={handleReset}
            />
        </FilterModal>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const UsersFilter = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UsersFilterComponent));