import React, { useEffect, useState, useRef } from 'react';
import toastr from "toastr";

import { API } from '../../../../api';
import { useOAuthPopup } from '../../../../hooks';

export const SocialAuth = (props) => {
    const { component: Component } = props;

    const [loading, setLoading] = useState(false);
    const [currentProvider, setCurrentProvider ] = useState(null);
    const [result, setResult] = useState(null);

    const handleResult = (data) => {
        setResult(data);
    };

    const {
        openPopup,
        focusPopup,
        closePopup,
        isOpen,
    } = useOAuthPopup({
        onSuccess: handleResult,
    });

    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;

        return () => {
            closePopup();

            if (isMountedRef.current) {
                isMountedRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (result) {
            props.onSuccess({
                provider: currentProvider,
                ...result,
            });

            setResult(null);
        }
    }, [result]);

    const fetchData = async (provider) => {
        setLoading(true);

        try {
            const response = await API.auth.getOAuthUrl({ provider: provider }); // redirect_uri: REDIRECT_URL + props.provider
            const { data } = response;

            openPopup(data.data.url);

            if (isMountedRef.current) {
                setLoading(false);
            }
        } catch (error) {
            if (isMountedRef.current) {
                setLoading(false);
            }

            const message = error.response?.data?.message ? error.response.data.message : 'Error';
            toastr.error(message);
        }
    };

    const handleClick = async (provider) => {
        if (loading) {
            return;
        }

        if (provider === currentProvider && isOpen) {
            focusPopup();
        } else {
            setCurrentProvider(provider);

            await fetchData(provider);
        }
    };

    return (
        <Component onLogin={handleClick} {...props} />
    );
};