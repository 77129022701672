import {
    LEAD_FETCH_ALL_PROPERTIES,
    LEAD_FETCH_ALL_PROPERTIES_SUCCESS,
    LEAD_FETCH_ALL_PROPERTIES_ERROR,
    LEAD_CLEAN_ALL_PROPERTIES,

    LEAD_ALL_PROPERTIES_SET_FILTER,
    LEAD_ALL_PROPERTIES_RESET_FILTER,

    LEAD_ALL_PROPERTIES_SET_SORT,
    LEAD_ALL_PROPERTIES_RESET_SORT,

    LEAD_ALL_PROPERTIES_SET_PAGE,

    LEAD_ALL_PROPERTIES_UPDATE_PROPERTY,
} from './actionTypes';

export const leadFetchAllProperties = (params) => {
    return {
        type: LEAD_FETCH_ALL_PROPERTIES,
        payload: { params }
    }
};

export const leadFetchAllPropertiesSuccess = (data) => {
    return {
        type: LEAD_FETCH_ALL_PROPERTIES_SUCCESS,
        payload: data,
    }
};

export const leadFetchAllPropertiesError = (data) => {
    return {
        type: LEAD_FETCH_ALL_PROPERTIES_ERROR,
        payload: data,
    }
};

export const leadCleanAllProperties = () => {
    return { type: LEAD_CLEAN_ALL_PROPERTIES };
};


// SET PROPERTY FILTER
export const leadAllPropertiesSetFilter = (data) => {
    return {
        type: LEAD_ALL_PROPERTIES_SET_FILTER,
        payload: data,
    }
};

export const leadAllPropertiesResetFilter = () => {
    return { type: LEAD_ALL_PROPERTIES_RESET_FILTER };
};

// SET PROPERTY SORTING
export const leadAllPropertiesSetSort = (data) => {
    return {
        type: LEAD_ALL_PROPERTIES_SET_SORT,
        payload: data,
    }
};

export const leadAllPropertiesResetSort = () => {
    return { type: LEAD_ALL_PROPERTIES_RESET_SORT };
};


// SET PROPERTY PAGE
export const leadAllPropertiesSetPage = (data) => {
    return {
        type: LEAD_ALL_PROPERTIES_SET_PAGE,
        payload: data,
    }
};


// UPDATE PROPERTY DATA IN LIST
export const leadAllPropertiesUpdateProperty = (id, data) => {
    return {
        type: LEAD_ALL_PROPERTIES_UPDATE_PROPERTY,
        payload: { id, data },
    }
};