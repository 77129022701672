import React, { useState, useRef } from 'react';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import { Input, FormGroup, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

import { SLIDER_MODE_INPUT } from "../../../consts/common";

export const PriceSliderRangeField = ({
    input,
    limit,
    inputMinConfig,
    inputMaxConfig,
    mode = 'default',
    step = false,
}) => {
    const { t } = useTranslation();

    const [initialValue, setInitialValue] = useState(input.value);

    const [min, setMin] = useState(input.value[0]);
    const [max, setMax] = useState(input.value[1]);

    const sliderRef = useRef(null);

    const onSliderUpdate = (value) => {
        const minValue = Math.round(parseInt(value[0]));
        const maxValue = Math.round(parseInt(value[1]));

        setMin(minValue);
        setMax(maxValue);

        return input.onChange([minValue, maxValue]);
    };

    const under = (value) => {
        let numberValue = Number(value);
        if (numberValue < limit.min) {
            return limit.min;
        } else if (numberValue > limit.max) {
            return limit.max;
        } else {
            return value;
        }
    };

    const onMinChange = (e) => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '') {
            return setMin(e.target.value);
        }

        if (re.test(e.target.value)) {
            let val = under(e.target.value);
            return setMin(val);
        }
    };

    const onMinBlur = (e) => {
        let min;
        let max = input.value[1];

        if (e.target.value === '') {
            min = limit.min;
        } else {
            const number = e.target.value.replace(/ /g, '');
            min = number > max ? max : number;
        }

        if (sliderRef.current) {
            sliderRef.current.noUiSlider.set([min, max]);
        }
    };

    const onMaxChange = (e) => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '') {
            return setMax(e.target.value);
        }

        if (re.test(e.target.value)) {
            let val = under(e.target.value);

            return setMax(val);
        }
    };

    const onMaxBlur = (e) => {
        let min = input.value[0];
        let max;

        if (e.target.value === '') {
            max = limit.min;
        } else {
            const number = e.target.value.replace(/ /g, '');
            max = number < min ? min : number;
        }

        if (sliderRef.current) {
            sliderRef.current.noUiSlider.set([min, max]);
        }
    };

    return (
        <Col>
            <Row className="justify-content-between">
                {mode === SLIDER_MODE_INPUT ? (
                    <>
                        <NumberFormat
                            value={min}
                            thousandSeparator={' '}
                            prefix={'€ '}
                            displayType={'text'}
                            className={'font-size-15 mb-3'}
                        />

                        <NumberFormat
                            value={max}
                            thousandSeparator={' '}
                            prefix={'€ '}
                            displayType={'text'}
                            className={'font-size-15 mb-3'}
                        />
                    </>
                ) : (
                    <>
                        <FormGroup row className={'mb-4'}>
                            <Col lg={'12'}>
                                <div className={'input-group'}>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text input-prepend-custom">{'€'}</span>
                                    </div>

                                    <NumberFormat
                                        value={min}
                                        thousandSeparator={' '}
                                        onValueChange={values => {
                                            onMinChange({
                                                target: {
                                                    value: values.value,
                                                },
                                            });
                                        }}
                                        onBlur={(value) => onMinBlur(value)}
                                        placeholder={t(inputMinConfig?.placeholder || 'range_from')}
                                        className={'form-control'}
                                        allowNegative={false}
                                    />
                                </div>
                            </Col>
                        </FormGroup>

                        <FormGroup row className={'mb-4'}>
                            <Col lg={'12'}>
                                <div className={'input-group'}>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text input-prepend-custom">{'€'}</span>
                                    </div>

                                    <NumberFormat
                                        value={max}
                                        thousandSeparator={' '}
                                        onValueChange={values => {
                                            onMaxChange({
                                                target: {
                                                    value: values.value,
                                                },
                                            });
                                        }}
                                        onBlur={(value) => onMaxBlur(value)}
                                        placeholder={t(inputMaxConfig?.placeholder || 'range_to')}
                                        className={'form-control'}
                                        allowNegative={false}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                    </>
                )}
            </Row>

            <Row>
                <div className={'price-range-wrap'}>
                    <Nouislider
                        instanceRef={sliderRef}
                        range={{
                            'min': [limit.min, step], // 0
                            '30%': [(limit.max + 1) * 0.05, step], // 100 thousand
                            '50%': [(limit.max + 1) * 0.25, step], // 500 thousand
                            '75%': [(limit.max + 1) * 0.5, step], // 1 mln
                            'max': [limit.max, step]
                        }}
                        start={initialValue}
                        onUpdate={(value) => onSliderUpdate(value)}
                        connect
                    />
                </div>
            </Row>
        </Col>
    );
};