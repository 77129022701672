import { all } from 'redux-saga/effects';

import inviteListsSaga from './lists/saga';
import reactionSaga from './reaction/saga';
import usersSaga from './users/saga';
import seenSaga from './seen/saga';

function* inviteSaga() {
    yield all([
        inviteListsSaga(),
        reactionSaga(),
        usersSaga(),
        seenSaga(),
    ])
}

export default inviteSaga;