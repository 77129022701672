import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { Sidebar } from './Sidebar';
import { Room } from './Room';

const ChatComponent = (props) => {
    useEffect(() => {
        return () => {
        }
    }, []);

    return (
        <div className="d-lg-flex">
            <Sidebar />

            <Room />
        </div>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const Chat = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatComponent)));