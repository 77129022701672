import React, { useEffect, useState, useMemo, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Spinner, Alert } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import { ContactListItem } from './ContactListItem';
import { fetchChatContacts, cleanChatContacts } from '../../../../../../store/actions';
import { isCloseToBottom } from '../../../../../../utils/scrollbar';
import { ChatSearch } from '../ChatSearch';
import { ROLE_CONSULTANT } from '../../../../../../consts/roles';

import * as HOC from '../../../../../../HOC';

const DataShower = HOC.withDataShower();

const ContactListComponent = (props) => {
    const [search, setSearch] = useState(null);
    const [page, setPage] = useState(1);

    useEffect(() => {
        let params = {
            page,
        };

        if (search) {
            params.filter = {
                keywords: search,
                search_fields: ['name'],
            };
        }

        fetchData(params);

    }, [page, search]);

    useEffect(() => {
        return () => {
            props.cleanChatContacts();
        }
    }, []);

    const fetchData = (params) => {
        props.fetchChatContacts(params);
    };

    const filterWarningVisible = useMemo(() => {
        return search && props.list.length === 0 && !props.loading;
    }, [props.list, props.loading, search]);

    const handleSearchChange = (value) => {
        if (value !== search) {
            setPage(1);
        }
        setSearch(value);
    };

    const isMoreLoading = useMemo(() => {
        return props.loading && page !== 1;
    }, [props.loading, page]);

    const loadMore = () => {
        const { meta } = props;
        if (meta && meta.current_page !== meta.last_page && !props.loading) {
            setPage(meta.current_page + 1);
        }
    };

    const onScroll = (e) => {
        if (isCloseToBottom(e, 50)) {
            loadMore();
        }
    };

    const scrollBarRef = useRef(null);

    return (
        <>
            <ChatSearch onChange={handleSearchChange} />

            <PerfectScrollbar
                style={{ height: "390px" }}
                onScrollY={onScroll}
                ref={scrollBarRef}
            >
                <DataShower
                    isLoading={props.loading && !isMoreLoading}
                    isFailed={props.error}
                    error={props.t(props.error)}
                    customLoaderClass={'data-shower-loader-center'}
                    spinnerSize={'sm'}
                >
                    {filterWarningVisible ? (
                        <Alert color="primary">
                            {props.t(props.user.role === ROLE_CONSULTANT ? 'chat.clients.filter.empty' : 'chat.contacts.filter.empty')}
                        </Alert>
                    ) : (
                        <>
                            {props.list.length > 0 ? (
                                <ul className="list-unstyled chat-list">
                                    {props.list.map(item => (
                                        <ContactListItem item={item} key={'contact-item-' + item.id} />
                                    ))}
                                </ul>
                            ) : (
                                props.t(props.user.role === ROLE_CONSULTANT ? 'chat.no_clients' : 'chat.no_contacts')
                            )}

                            {isMoreLoading && (
                                <div className={'text-center'}>
                                    <Spinner size={'sm'} color="primary" />
                                </div>
                            )}
                        </>
                    )}
                </DataShower>
            </PerfectScrollbar>
        </>
    );
};

const mapStateToProps = state => {
    const { list, loading, error, meta, } = state.chat.contacts;
    const { data } = state.user;
    return {
        list, loading, error, meta,
        user: data,
    };
};

const mapDispatchToProps = {
    fetchChatContacts,
    cleanChatContacts,
};

export const ContactList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactListComponent)));