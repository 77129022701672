import {
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    FORGET_PASSWORD_CLEAN,
} from "./actionTypes";

export const userForgetPassword = (params, history) => {
    return {
        type: FORGET_PASSWORD,
        payload: { params, history }
    };
};

export const userForgetPasswordSuccess = data => {
    return {
        type: FORGET_PASSWORD_SUCCESS,
        payload: data,
    };
};

export const userForgetPasswordError = data => {
    return {
        type: FORGET_PASSWORD_ERROR,
        payload: data
    };
};

export const userForgetPasswordClean = () => {
    return {
        type: FORGET_PASSWORD_CLEAN,
    };
};