import React from 'react';
import { Row, Col, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form'
import { connect } from "react-redux";

import { USERS_FILTER_FORM } from "../../../consts/forms";
import { DatePickerField } from "../../Custom";

const UsersFilterFormComponent = (props) => {
    const handleReset = () => {
        props.reset(USERS_FILTER_FORM);

        props.onReset();
    };

    const options = {
        mode: "range",
    };

    return (
        <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="form-horizontal"
            noValidate={true}
        >
            <Field
                name="period"
                label={props.t('field.label.dates.added')}
                placeholder={props.t('field.placeholder.dates.added')}
                id={'period'}
                component={DatePickerField}
                options={options}
            />

            <Row className="justify-content-end">
                <Col lg="10">
                    <div className="button-items">
                        <Button type="submit" color="primary">
                            {props.t('apply')}
                        </Button>

                        <Button type="button" color="light" outline onClick={handleReset}>
                            {props.t('reset')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};

const UsersFilter = reduxForm({
    form: USERS_FILTER_FORM,
    enableReinitialize: true,
})(UsersFilterFormComponent);

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const UsersFilterForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UsersFilter));