import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../i18n';

import { LEAD_UPDATE_STATUS_DECLINE, LEAD_UPDATE_STATUS_BUY } from './actionTypes';
import {
    leadUpdateStatusSuccess,
    leadUpdateStatusError,
} from './actions';

import { leadUpdateData } from '../view/actions';

import { API } from '../../../api';

function* updateStatusDecline({ payload: { id } }) {
    try {
        const response = yield call(API.lead.declineLead, id);

        const { data } = response;
        yield put(leadUpdateStatusSuccess(true));

        yield put(leadUpdateData(data.data));

        toastr.success(i18n.t(`lead.status.updated.decline`));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(leadUpdateStatusError(message));
    }
}

function* updateStatusBuy({ payload: { id } }) {
    try {
        const response = yield call(API.lead.buyLead, id);

        const { data } = response;
        yield put(leadUpdateStatusSuccess(true));

        yield put(leadUpdateData(data.data));

        toastr.success(i18n.t(`lead.status.updated.buy`));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(leadUpdateStatusError(message));
    }
}

export function* watchUpdateStatusDecline() {
    yield takeEvery(LEAD_UPDATE_STATUS_DECLINE, updateStatusDecline)
}

export function* watchUpdateStatusBuy() {
    yield takeEvery(LEAD_UPDATE_STATUS_BUY, updateStatusBuy)
}

function* statusSaga() {
    yield all([
        fork(watchUpdateStatusDecline),
        fork(watchUpdateStatusBuy),
    ]);
}

export default statusSaga;