import React from 'react';
import { Spinner } from "reactstrap";

export const TableDataLoading = ({ isLoading }) => {
    return (
        <>
            {isLoading ? (
                <div className={'table-loader'}>
                    <Spinner size={'lg'} color="primary" />
                </div>
            ) : (
                null
            )}
        </>
    );
};