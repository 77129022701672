import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { InvoicesTable } from '../InvoicesTable';
import {
    fetchBillingInvoices,
    cleanBillingInvoices,
} from '../../../../../store/actions';

import * as HOC from '../../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const LatestInvoicesComponent = (props) => {
    useEffect(() => {
        let params = {
            per_page: 5,
        };

        props.fetchBillingInvoices(params);

        return () => {
            props.cleanBillingInvoices();
        }
    }, []);

    return (
        <TableDataLoading isLoading={props.listLoading} isFailed={props.listError} error={props.listError}>
            <InvoicesTable
                items={props.list}
                isLoading={props.listLoading}
                onSort={() => {}}
            />
        </TableDataLoading>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError } = state.billing.invoice.list;
    return {
        list,
        listLoading,
        listError,
    };
};

const mapDispatchToProps = {
    fetchBillingInvoices,
    cleanBillingInvoices,
};

export const LatestInvoices = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LatestInvoicesComponent)));