import {
    LEAD_PROPERTY_LIST_SUGGEST_PROPERTY,
    LEAD_PROPERTY_LIST_SUGGEST_PROPERTY_SUCCESS,
    LEAD_PROPERTY_LIST_SUGGEST_PROPERTY_ERROR,
    LEAD_CLEAN_PROPERTY_LIST_SUGGEST_PROPERTY,
} from './actionTypes';

const initialState = {
    pending: [],
};

const suggest = (state = initialState, action) => {
    switch (action.type) {
        case LEAD_PROPERTY_LIST_SUGGEST_PROPERTY:
            state = {
                ...state,
                pending: [...state.pending, action.payload.property],
            };
            break;

        case LEAD_PROPERTY_LIST_SUGGEST_PROPERTY_SUCCESS:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.property),
            };
            break;

        case LEAD_PROPERTY_LIST_SUGGEST_PROPERTY_ERROR:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.property),
            };
            break;

        case LEAD_CLEAN_PROPERTY_LIST_SUGGEST_PROPERTY:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default suggest;