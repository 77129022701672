export const FETCH_RECEIVED_ACTIVE_PROPERTY_LIST = 'FETCH_RECEIVED_ACTIVE_PROPERTY_LIST';
export const FETCH_RECEIVED_ACTIVE_PROPERTY_LIST_SUCCESS = 'FETCH_RECEIVED_ACTIVE_PROPERTY_LIST_SUCCESS';
export const FETCH_RECEIVED_ACTIVE_PROPERTY_LIST_ERROR = 'FETCH_RECEIVED_ACTIVE_PROPERTY_LIST_ERROR';
export const CLEAN_RECEIVED_ACTIVE_PROPERTY_LIST = 'CLEAN_RECEIVED_ACTIVE_PROPERTY_LIST';

export const RECEIVED_ACTIVE_PROPERTY_LIST_SET_SORT = 'RECEIVED_ACTIVE_PROPERTY_LIST_SET_SORT';

export const RECEIVED_ACTIVE_PROPERTY_LIST_SET_FILTER = 'RECEIVED_ACTIVE_PROPERTY_LIST_SET_FILTER';
export const RECEIVED_ACTIVE_PROPERTY_LIST_RESET_FILTER = 'RECEIVED_ACTIVE_PROPERTY_LIST_RESET_FILTER';

export const RECEIVED_ACTIVE_PROPERTY_LIST_SET_PAGE = 'RECEIVED_ACTIVE_PROPERTY_LIST_SET_PAGE';


export const FETCH_RECEIVED_INACTIVE_PROPERTY_LIST = 'FETCH_RECEIVED_INACTIVE_PROPERTY_LIST';
export const FETCH_RECEIVED_INACTIVE_PROPERTY_LIST_SUCCESS = 'FETCH_RECEIVED_INACTIVE_PROPERTY_LIST_SUCCESS';
export const FETCH_RECEIVED_INACTIVE_PROPERTY_LIST_ERROR = 'FETCH_RECEIVED_INACTIVE_PROPERTY_LIST_ERROR';
export const CLEAN_RECEIVED_INACTIVE_PROPERTY_LIST = 'CLEAN_RECEIVED_INACTIVE_PROPERTY_LIST';

export const RECEIVED_INACTIVE_PROPERTY_LIST_SET_SORT = 'RECEIVED_INACTIVE_PROPERTY_LIST_SET_SORT';

export const RECEIVED_INACTIVE_PROPERTY_LIST_SET_FILTER = 'RECEIVED_INACTIVE_PROPERTY_LIST_SET_FILTER';
export const RECEIVED_INACTIVE_PROPERTY_LIST_RESET_FILTER = 'RECEIVED_INACTIVE_PROPERTY_LIST_RESET_FILTER';

export const RECEIVED_INACTIVE_PROPERTY_LIST_SET_PAGE = 'RECEIVED_INACTIVE_PROPERTY_LIST_SET_PAGE';