import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_CHAT_CONTACTS } from './actionTypes';
import { fetchChatContactsSuccess, fetchChatContactsError } from './actions';

import { API } from '../../../api';

import { getMapRequestParams } from '../../../utils/request';

function* fetchChatContacts({ payload: { params } }) {
    try {
        const requestParams = getMapRequestParams(params);

        const response = yield call(API.chat.getContacts, requestParams);

        const { data } = response;
        yield put(fetchChatContactsSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchChatContactsError(message));
    }
}

export function* watchChatContactFetch() {
    yield takeLatest(FETCH_CHAT_CONTACTS, fetchChatContacts)
}

function* contactsSaga() {
    yield all([
        fork(watchChatContactFetch),
    ]);
}

export default contactsSaga;