import { combineReducers } from "redux";

import listReducer from './list/reducer';
import viewReducer from './view/reducer';
import createReducer from './create/reducer';
import reactionReducer from './reaction/reducer';
import suggestedPropertyReducer from './suggestedProperty/reducers';
import availableReducer from './available/reducer';

const propertyRequestReducer = combineReducers({
    list: listReducer,
    view: viewReducer,
    create: createReducer,
    reaction: reactionReducer,
    suggestedProperty: suggestedPropertyReducer,
    available: availableReducer,
});

export default propertyRequestReducer;