import { combineReducers } from "redux";

import listReducer from './list/reducer';
import removeReducer from './remove/reducer';
import addReducer from './add/reducer';
import defaultReducer from './default/reducer';

const cardReducer = combineReducers({
    list: listReducer,
    remove: removeReducer,
    add: addReducer,
    default: defaultReducer,
});

export default cardReducer;