import {
    CREATE_PROPERTY_REQUEST,
    CREATE_PROPERTY_REQUEST_SUCCESS,
    CREATE_PROPERTY_REQUEST_ERROR,
    CLEAN_CREATE_PROPERTY_REQUEST,
} from './actionTypes';

// CREATE PROPERTY REQUEST
export const createPropertyRequest = (params, history) => {
    return {
        type: CREATE_PROPERTY_REQUEST,
        payload: { params, history },
    }
};

export const createPropertyRequestSuccess = (data) => {
    return {
        type: CREATE_PROPERTY_REQUEST_SUCCESS,
        payload: data,
    }
};

export const createPropertyRequestError = (data) => {
    return {
        type: CREATE_PROPERTY_REQUEST_ERROR,
        payload: data,
    }
};

export const cleanCreatePropertyRequest = () => {
    return { type: CLEAN_CREATE_PROPERTY_REQUEST };
};