import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { CustomButton } from "../CustomButton";

export const PickFileButton = ({ accept, onChange, title, disabled }) => {
    const { t } = useTranslation();

    const inputRef = useRef()

    const handleFilePicked = event => {
        const file = event.target.files[0]

        onChange({ file: file })

        if (inputRef.current) {
            inputRef.current.value = null
        }
    }

    const pickFile = event => {
        event.preventDefault()

        if (inputRef.current) {
            inputRef.current.click()
        }
    }


    return (
        <>
            <input
                type="file"
                id={"file"}
                className="d-none"
                onChange={handleFilePicked}
                ref={inputRef}
                accept={accept}
            />
            <CustomButton
                disabled={disabled}
                color="primary"
                onClick={pickFile}
                title={title ? title : 'pick-file'}
                leftIcon={"bx bxs-file-blank font-size-16"}
            />
        </>
    );
};

PickFileButton.propTypes = {
    accept: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    disabled: PropTypes.bool
};


