import React from 'react';
import PropTypes from 'prop-types';

import s from "./CompanyDetails.module.scss"

export const CompanyDetails = ({ company }) => {
    if (!company) return null

    return (
        <div className={s.block}>
            {company.avatar && <img className={`rounded-circle avatar-cover ${s.company_avatar}`} src={company.avatar}
                                    alt="Company Avatar"/>}
            <div className={s.name}>{company.name}</div>
        </div>
    );
};

CompanyDetails.propTypes = {
    company: PropTypes.object
};


