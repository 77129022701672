import {
    FETCH_RECEIVED_INVITE_LIST,
    FETCH_RECEIVED_INVITE_LIST_SUCCESS,
    FETCH_RECEIVED_INVITE_LIST_ERROR,
    CLEAN_INVITE_LIST_PAGE,

    FETCH_RECEIVED_ACTIVE_INVITE_LIST,
    FETCH_RECEIVED_ACTIVE_INVITE_LIST_SUCCESS,
    FETCH_RECEIVED_ACTIVE_INVITE_LIST_ERROR,
    CLEAN_ACTIVE_INVITE_LIST,

    FETCH_RECEIVED_INACTIVE_INVITE_LIST,
    FETCH_RECEIVED_INACTIVE_INVITE_LIST_SUCCESS,
    FETCH_RECEIVED_INACTIVE_INVITE_LIST_ERROR,
    CLEAN_INACTIVE_INVITE_LIST,

    FETCH_PROPERTY_INVITES,
    FETCH_PROPERTY_INVITES_SUCCESS,
    FETCH_PROPERTY_INVITES_ERROR,
    CLEAN_PROPERTY_INVITES,
    PROPERTY_INVITES_SET_SORT,
    PROPERTY_INVITES_SET_PAGE,
    PROPERTY_INVITES_SET_SEARCH,
} from './actionTypes';

// FETCH ALL RECEIVED INVITES
export const fetchReceivedInviteList = () => {
    return {
        type: FETCH_RECEIVED_INVITE_LIST,
    }
};

export const fetchReceivedInviteListSuccess = (data) => {
    return {
        type: FETCH_RECEIVED_INVITE_LIST_SUCCESS,
        payload: data,
    }
};

export const fetchReceivedInviteListError = (data) => {
    return {
        type: FETCH_RECEIVED_INVITE_LIST_ERROR,
        payload: data,
    }
};

export const cleanInviteListPage = () => {
    return { type: CLEAN_INVITE_LIST_PAGE };
};

// FETCH RECEIVED ACTIVE INVITES
export const fetchReceivedActiveInviteList = () => {
    return {
        type: FETCH_RECEIVED_ACTIVE_INVITE_LIST,
    }
};

export const fetchReceivedActiveInviteListSuccess = (data) => {
    return {
        type: FETCH_RECEIVED_ACTIVE_INVITE_LIST_SUCCESS,
        payload: data,
    }
};

export const fetchReceivedActiveInviteListError = (data) => {
    return {
        type: FETCH_RECEIVED_ACTIVE_INVITE_LIST_ERROR,
        payload: data,
    }
};

export const cleanActiveInviteList = () => {
    return { type: CLEAN_ACTIVE_INVITE_LIST };
};

// FETCH RECEIVED INACTIVE INVITES
export const fetchReceivedInactiveInviteList = () => {
    return {
        type: FETCH_RECEIVED_INACTIVE_INVITE_LIST,
    }
};

export const fetchReceivedInactiveInviteListSuccess = (data) => {
    return {
        type: FETCH_RECEIVED_INACTIVE_INVITE_LIST_SUCCESS,
        payload: data,
    }
};

export const fetchReceivedInactiveInviteListError = (data) => {
    return {
        type: FETCH_RECEIVED_INACTIVE_INVITE_LIST_ERROR,
        payload: data,
    }
};

export const cleanInactiveInviteList = () => {
    return { type: CLEAN_INACTIVE_INVITE_LIST };
};

// FETCH PROPERTY INVITES
export const fetchPropertyInvites = (id, params) => {
    return {
        type: FETCH_PROPERTY_INVITES,
        payload: { id, params },
    }
};

export const fetchPropertyInvitesSuccess = (data) => {
    return {
        type: FETCH_PROPERTY_INVITES_SUCCESS,
        payload: data,
    }
};

export const fetchPropertyInvitesError = (data) => {
    return {
        type: FETCH_PROPERTY_INVITES_ERROR,
        payload: data,
    }
};

export const propertyInvitesSetSort = (data) => {
    return {
        type: PROPERTY_INVITES_SET_SORT,
        payload: data,
    }
};

// SET PAGE
export const propertyInvitesSetPage = (data) => {
    return {
        type: PROPERTY_INVITES_SET_PAGE,
        payload: data,
    }
};

// SET SEARCH
export const propertyInvitesSetSearch = (data) => {
    return {
        type: PROPERTY_INVITES_SET_SEARCH,
        payload: data,
    }
};

export const cleanPropertyInvites = () => {
    return { type: CLEAN_PROPERTY_INVITES };
};