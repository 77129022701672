import {
    CREATE_PROPERTY_REQUEST,
    CREATE_PROPERTY_REQUEST_SUCCESS,
    CREATE_PROPERTY_REQUEST_ERROR,
    CLEAN_CREATE_PROPERTY_REQUEST,
} from './actionTypes';

const initialState = {
    loading: false,
    success: null,
    createError: null,
};

const create = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PROPERTY_REQUEST:
            state = {
                ...state,
                loading: true,
                success: null,
                createError: null,
            };
            break;

        case CREATE_PROPERTY_REQUEST_SUCCESS:
            state = {
                ...state,
                loading: false,
                success: action.payload,
            };
            break;

        case CREATE_PROPERTY_REQUEST_ERROR:
            state = {
                ...state,
                loading: false,
                createError: action.payload,
            };
            break;

        case CLEAN_CREATE_PROPERTY_REQUEST:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default create;