import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { Pagination } from '../../../../../components/Custom';
import { LeadsTable } from '../LeadsTable';
import {
    fetchUserLeads,
    cleanUserLeads,
} from '../../../../../store/actions';
import { consultantLeadsColumns } from '../../../../../consts/lead';

import * as HOC from '../../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const UserLeadListComponent = (props) => {
    const [page, setPage] = useState(1);

    useEffect(() => {
        let params = {
            page,
            filter: {
                status: ['paid'],
            },
        };

        props.fetchUserLeads(params);

    }, [page]);

    useEffect(() => {
        return () => {
            props.cleanUserLeads();
        }
    }, []);

    const handlePageChange = (item) => {
        setPage(item.selected + 1);
    };

    return (
        <TableDataLoading isLoading={props.listLoading} isFailed={props.listError} error={props.t(props.listError)}>
            <>
                <LeadsTable
                    keys={consultantLeadsColumns}
                    items={props.list}
                    isLoading={props.listLoading}
                    onSort={() => {}}
                />

                <Pagination data={props.meta} onPageChange={handlePageChange} />
            </>
        </TableDataLoading>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError, meta } = state.lead.userLeads;
    return {
        list,
        listLoading,
        listError,
        meta,
    };
};

const mapDispatchToProps = {
    fetchUserLeads,
    cleanUserLeads,
};

export const UserLeadList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserLeadListComponent)));