import React, { useState, useEffect, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { API } from '../../../../api';
import { PropertyFilterForm } from './PropertyFilterForm';
import { ROLE_CLIENT, ROLE_MANAGER } from '../../../../consts/roles';
import { propertySetFilter, propertyResetFilter } from '../../../../store/actions';
import { getPropertyStatusConfig, getPropertyOwnerTypeConfig, getPropertyVisibilityConfig } from "../../../../helpers";
import { getApplyFilterCount } from '../../../../utils/filter';

const PropertyFilterComponent = (props) => {
    const [modalVisible, setModalVisible] = useState(false);

    const [companyOptions, setCompanyOptions] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);

    const fetchCompanies = async () => {
        try {
            const response = await API.invite.getCompaniesFromInvites({ pagination: false });
            const { data } = response;

            setCompanyOptions(data.data)
        } catch (e) {
        }
    };

    const fetchProjects = async () => {
        try {
            const response = await API.project.getProjectList({ pagination: false });
            const { data } = response;

            setProjectOptions(data.data)
        } catch (e) {
        }
    };

    useEffect(() => {
        if (modalVisible) {
            if (props.user.role !== ROLE_CLIENT) {
                fetchProjects();
            } else {
                fetchCompanies();
            }
        } else {
            setCompanyOptions([]);
            setProjectOptions([]);
        }

    }, [modalVisible]);

    const initialFormValues = useMemo(() => {
        let values = {
            ...props.filter,
        };

        if (props.filter?.statuses) {
            values.statuses = props.filter?.statuses.map(value => getPropertyStatusConfig(value, props.t));
        }

        if (props.user.role === ROLE_CLIENT && props.filter?.types) {
            values.types = props.filter?.types.map(value => getPropertyOwnerTypeConfig(value, props.t));
        }

        if (props.user.role === ROLE_MANAGER && props.filter?.enabled) {
            values.enabled = props.filter?.enabled.map(value => getPropertyVisibilityConfig(value, props.t));
        }

        return values;
    }, [props.filter, props.user]);

    const showFilter = () => {
        setModalVisible(true);
    };

    const hideFilter = () => {
        setModalVisible(false);
    };

    const toggleFilter = () => {
        setModalVisible(!modalVisible);
    };

    const onSubmit = (values) => {
        let params = {
            ...values,
        };

        if (values.types) {
            params.types = values.types.map(item => item.value);
        }

        if (values.statuses) {
            params.statuses = values.statuses.map(item => item.value);
        }

        if (values.enabled) {
            params.enabled = values.enabled.map(item => item.value);
        }

        props.propertySetFilter(params);

        hideFilter();
    };

    const handleReset = () => {
        props.propertyResetFilter();

        hideFilter();
    };

    const count = useMemo(() => {
        return props.filterIsActive ? getApplyFilterCount(props.filter) : 0;
    }, [props.filterIsActive, props.filter]);

    return (
        <>
            <Button
                color="primary"
                onClick={showFilter}
            >
                <i className={`font-size-16 align-middle mr-2 bx ` + (count ? `bxs-filter-alt` : 'bx-filter-alt')} />
                {props.t('filter')}
                {count ? ` (${count})` : ''}
            </Button>

            <Modal
                size="lg"
                isOpen={modalVisible}
                toggle={toggleFilter}
            >
                <ModalHeader
                    toggle={toggleFilter}
                >
                    {props.t('filter')}
                </ModalHeader>

                <ModalBody>
                    <PropertyFilterForm
                        initialValues={initialFormValues}
                        onSubmit={onSubmit}
                        companyOptions={companyOptions}
                        projectOptions={projectOptions}
                        onReset={handleReset}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    const { filter, filterIsActive } = state.property.list;
    return {
        user: state.user.data,
        filter,
        filterIsActive,
    };
};

const mapDispatchToProps = {
    propertySetFilter,
    propertyResetFilter,
};

export const PropertyFilter = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyFilterComponent));