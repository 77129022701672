import React, { useCallback, useEffect, useMemo } from 'react';
import { Alert, Col, Row } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import _ from "lodash";

import { Pagination } from "components/Custom";
import { PropertyGrid } from "../PropertyGrid";
import {
    cleanClientAcceptedProperties,
    clientAcceptedPropertiesResetSort,
    clientAcceptedPropertiesSetPage,
    clientAcceptedPropertiesSetSearch,
    clientAcceptedPropertiesSetSort,
    fetchClientAcceptedProperties,
} from 'store/actions';

import * as HOC from 'HOC';

const TableDataLoading = HOC.withTableDataLoading();

const AcceptedPropertiesComponent = (props) => {

    useEffect(() => {
        let params = {
            page: props.page,
            per_page: 12,
            sort: [{ id: 'is_favorite', desc: true }],
        };

        if (props.filter.isActive) {
            params.filter = props.filter.values;
        }

        if (props.sort.length > 0) {
            params.sort = props.sort;
        }

        if (props.search) {
            params.filter = {
                ...params.filter,
                keywords: props.search,
                search_fields: ['address'],
            };
        }

        props.fetchClientAcceptedProperties(params);
    }, [props.filter, props.sort, props.page, props.search]);

    useEffect(() => {
        return () => {
            props.cleanClientAcceptedProperties();
            props.clientAcceptedPropertiesResetSort();
        }
    }, []);

    const filterWarningVisible = useMemo(() => {
        return (props.filter.isActive || props.search) && props.list.length === 0 && !props.listLoading;
    }, [props.filter, props.list, props.listLoading, props.search]);


    const handleSort = useCallback(sortBy => {
        if (!_.isEqual(sortBy, props.sort)) {
            props.clientAcceptedPropertiesSetSort(sortBy);
        }
    }, [props.sort]);

    const handlePageChange = (item) => {
        props.clientAcceptedPropertiesSetPage(item.selected + 1);
    };

    return (
        <Row>
            <Col lg="12">
                <TableDataLoading isLoading={props.listLoading} isFailed={props.listError}
                                  error={props.t(props.listError)}>
                    {filterWarningVisible ? (
                        <Alert color="primary">{props.t('property.filter.empty')}</Alert>
                    ) : (
                        <>
                            <PropertyGrid
                                page={props.page}
                                list={props.list}
                                isLoading={props.listLoading}
                                isFetched={props.listIsFetched}
                            />
                            <div className={"mb-3"}/>
                            <Pagination data={props.meta} onPageChange={handlePageChange}/>
                        </>
                    )}
                </TableDataLoading>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const {
        list, listLoading, listError, listIsFetched,
        sort,
        filter,
        page,
        meta,
        search
    } = state.property.clientProperties.accepted;

    return {
        user: state.user.data,
        list, listLoading, listError, listIsFetched,
        sort,
        filter,
        page,
        meta,
        search
    };
};

const mapDispatchToProps = {
    fetchClientAcceptedProperties,
    cleanClientAcceptedProperties,
    clientAcceptedPropertiesSetSort,
    clientAcceptedPropertiesResetSort,
    clientAcceptedPropertiesSetPage,
    clientAcceptedPropertiesSetSearch
};

export const AcceptedProperties = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AcceptedPropertiesComponent)));