import { all } from 'redux-saga/effects';

import staffListSaga from './staffList/saga';

function* inviteSaga() {
    yield all([
        staffListSaga(),
    ])
}

export default inviteSaga;