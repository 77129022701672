import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { useTitle } from "../../../../../hooks";
import { PageTitle } from '../../../../../components/Custom';
import { CreditsBuy } from './CreditsBuy';

const BillingCreditsComponent = (props) => {
    useTitle(props.t('title.credits.buy'));

    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <React.Fragment>

            <PageTitle title={props.t('title.credits.buy')} />

            <CreditsBuy />
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export const BillingCredits = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingCreditsComponent)));