import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import Layout from "./layout/reducer";
import commonReducer from './common/reducer';

import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import ResetPassword from "./auth/resetpwd/reducer";
import Verify from "./auth/verify/reducer";
import AccountActivation from "./auth/activation/reducer";
import completeRegister from "./auth/complete/reducer";

import userReducer from './user/reducer';

import profileReducer from './profile/reducer';
import projectReducer from './project/reducer';
import consultantReducer from './consultant/reducer';
import propertyReducer from './property/reducers';
import uploadPropertyPhotosReducer from './upload/property/reducer';
import inviteReducer from './invite/reducers';
import clientReducers from './client/reducers';
import contactReducers from './contact/reducers';
import offerReducers from './offer/reducers';
import statisticsReducers from './statistics/reducers';
import userDataReducers from './userData/reducers';
import socialReducers from './social/reducers';
import chatReducers from './chat/reducers';
import socketReducers from './socket/reducers';
import propertyRequestReducers from './propertyRequest/reducers';
import leadReducers from './lead/reducers';
import billingReducers from './billing/reducers';
import staticReducers from './static/reducers';
import crmSystemReducers from './crmSystem/reducers';

const authPersistConfig = {
    key: 'Login',
    storage,
    whitelist: ['isLoggedIn', 'isOnboarding'],
};

const rootReducer = combineReducers({
    form: formReducer,

    // public
    Layout,
    common: commonReducer,
    Login: persistReducer(authPersistConfig, Login),
    Account,
    ForgetPassword,
    ResetPassword,
    Verify,
    AccountActivation,
    completeRegister,
    user: userReducer,
    profile: profileReducer,
    project: projectReducer,
    consultant: consultantReducer,
    property: propertyReducer,
    uploadPropertyPhotos: uploadPropertyPhotosReducer,
    invite: inviteReducer,
    client: clientReducers,
    contact: contactReducers,
    offer: offerReducers,
    statistics: statisticsReducers,
    userData: userDataReducers,
    social: socialReducers,
    chat: chatReducers,
    socket: socketReducers,
    propertyRequest: propertyRequestReducers,
    lead: leadReducers,
    billing: billingReducers,
    static: staticReducers,
    crmSystem: crmSystemReducers,
});

export default rootReducer;
