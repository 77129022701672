import React from 'react';
import { Label, Input, FormFeedback, FormGroup, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';
import InputMask from "react-input-mask";

const TextInput = ({
    label,
    placeholder,
    type,
    input,
    meta: { touched, error, warning },
    defaultValue,
    reference,
    children,
    mode,
    row,
    prependSymbol,
    rowLayoutSize = {
        label: '2',
        column: '10',
    },
    mask,
    inputProps,
    ...props
}) => {
    const { t } = useTranslation();
    const showError = touched && Boolean(error);

    const onChange = (e) => {
        if (mode) {
            if (mode === 'number') {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                    input.onChange(e)
                }
            }
        } else {
            input.onChange(e)
        }
    };

    const renderRow = () => (
        <FormGroup row className={'mb-4' + (showError ? ' text-danger' : '')}>
            {label && (
                <Label htmlFor={props.id} className={'col-form-label col-lg-' + (rowLayoutSize.label)}>{label}</Label>
            )}

            <Col lg={label ? rowLayoutSize.column : '12'}>
                <div className={'input-group' + (showError ? ' is-invalid' : '')}>
                    {prependSymbol && (
                        <div className="input-group-prepend">
                            <span className="input-group-text input-prepend-custom">{prependSymbol}</span>
                        </div>
                    )}

                    {mask ? (
                        <InputMask
                            mask={mask}
                            value={input.value}
                            onChange={(value) => input.onChange(value)}
                            maskChar={props.maskChar || null}
                        >
                            {inputMaskProps => (
                                <Input
                                    id={props.id}
                                    type={type}
                                    className="form-control"
                                    placeholder={placeholder}
                                    invalid={showError}
                                    {...inputMaskProps}
                                />
                            )}
                        </InputMask>
                    ) : (
                        <Input
                            id={props.id}
                            type={type}
                            className="form-control"
                            onChange={(value) => onChange(value)}
                            placeholder={placeholder}
                            invalid={showError}
                            value={input.value}
                            disabled={props.disabled}
                            {...inputProps}
                        />
                    )}
                </div>
                <FormFeedback invalid={showError ? error : null }>
                    { t(error, props.translateParams ? { ...props.translateParams } : {}) }
                </FormFeedback>

                {props.hint && (
                    <div className={'text-muted form-text'}>
                        { t(props.hint) }
                    </div>
                )}
            </Col>
        </FormGroup>
    );

    const renderDefault = () => (
        <FormGroup className={showError ? 'text-danger' : ''}>
            <Label htmlFor={props.id}>{label}</Label>
            <Input
                id={props.id}
                type={type}
                className="form-control"
                onChange={(value) => onChange(value)}
                placeholder={placeholder}
                invalid={showError}
                value={input.value}
                disabled={props.disabled}
                {...inputProps}
            />
            <FormFeedback invalid={showError ? error : null }>
                { t(error, props.translateParams ? { ...props.translateParams } : {}) }
            </FormFeedback>
        </FormGroup>
    );

    return (
        <>
            {row ? (
                renderRow()
            ) : (
                renderDefault()
            )}
        </>
    );
};

export default TextInput;