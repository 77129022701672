import {
    PROPERTY_REQUEST_CHECK_AVAILABLE,
    PROPERTY_REQUEST_CHECK_AVAILABLE_SUCCESS,
    PROPERTY_REQUEST_CHECK_AVAILABLE_ERROR,
    CLEAN_PROPERTY_REQUEST_CHECK_AVAILABLE,
} from './actionTypes';

export const propertyRequestCheckAvailable = () => {
    return {
        type: PROPERTY_REQUEST_CHECK_AVAILABLE,
    }
};

export const propertyRequestCheckAvailableSuccess = (data) => {
    return {
        type: PROPERTY_REQUEST_CHECK_AVAILABLE_SUCCESS,
        payload: data,
    }
};

export const propertyRequestCheckAvailableError = (data) => {
    return {
        type: PROPERTY_REQUEST_CHECK_AVAILABLE_ERROR,
        payload: data,
    }
};

export const cleanPropertyRequestCheckAvailable = () => {
    return { type: CLEAN_PROPERTY_REQUEST_CHECK_AVAILABLE };
};