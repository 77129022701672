import {
  CRM_SYSTEM_LIST_GET_DATA,
  CRM_SYSTEM_LIST_GET_DATA_SUCCESS,
  CRM_SYSTEM_LIST_GET_DATA_ERROR,
  CRM_SYSTEM_LIST_CLEAN_STATE,
  CRM_SYSTEM_LIST_UPDATE_ITEM,
} from './actionTypes';

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
};

const list = (state = initialState, action) => {
  switch (action.type) {

    case CRM_SYSTEM_LIST_GET_DATA:
      state = {
        ...state,
        listLoading: true,
        listError: null,
      };
      break;

    case CRM_SYSTEM_LIST_GET_DATA_SUCCESS:
      state = {
        ...state,
        listLoading: false,
        list: action.payload.data,
      };
      break;

    case CRM_SYSTEM_LIST_GET_DATA_ERROR:
      state = {
        ...state,
        listLoading: false,
        listError: action.payload,
      };
      break;

    case CRM_SYSTEM_LIST_UPDATE_ITEM:
      state = {
        ...state,
        list: [...state.list].map(item => {
          if (item.id !== action.payload.id) {
            return item
          }

          return {
            ...item,
            ...action.payload.data,
          }
        })
      };
      break;

    case CRM_SYSTEM_LIST_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default list;