import React from 'react';
import { Col, Row } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { ClientPropertiesTabs } from './ClientPropertiesTabs';
import {
    CLIENT_PROPERTIES_ACCEPTED_TAB,
    CLIENT_PROPERTIES_ALL_TAB,
    CLIENT_PROPERTIES_FAVORITE_TAB,
    CLIENT_PROPERTIES_OWNED_TAB
} from "consts/property/clientProperties";
import { setClientPropertiesActiveTab } from "store/actions";
import { ClientProperties } from "./ClientProperties";
import { ClientPropertiesFilter } from "./ClientPropertiesFilter";


const tabs = [
    { label: "show_all", value: CLIENT_PROPERTIES_ALL_TAB },
    {
    label: "favorites",
    value: CLIENT_PROPERTIES_FAVORITE_TAB
}, {
    label: 'my_properties',
    value: CLIENT_PROPERTIES_OWNED_TAB,
}, {
    label: 'accepted_properties',
    value: CLIENT_PROPERTIES_ACCEPTED_TAB,
}];

const ClientPropertiesContainerComponent = (props) => {
    const onTabChange = (value) => {
        props.setClientPropertiesActiveTab(value);
    }

    return (
        <>
            <Row className={'mb-4'}>
                <Col xs={11} className={"pr-0"}>
                    <ClientPropertiesTabs tabs={tabs} activeTab={props.activeTab} onTabChange={onTabChange}/>
                </Col>
                <Col xs={1} className={"d-flex justify-content-end pl-0"}>
                    <ClientPropertiesFilter
                        activeTab={props.activeTab}
                        isAcceptedFilter={true}
                        asIcon={true}
                    />
                </Col>
            </Row>

            <ClientProperties activeTab={props.activeTab}/>
        </>
    );
};

const mapStateToProps = state => {
    const { activeTab } = state.property.clientProperties.config;

    return {
        user: state.user.data,
        activeTab,
    };
};

const mapDispatchToProps = {
    setClientPropertiesActiveTab,
};

export const ClientPropertiesContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientPropertiesContainerComponent)));