import React from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import profile1 from "../../../assets/images/profile-img.png"
import placeholder from '../../../assets/images/custom/avatar-placeholder.png';

export const ProfileMainInfo = ({ title, avatar, name, date, role, company }) => {
    const { t } = useTranslation();

    return (
        <Card className="overflow-hidden profile-card-wrap">
            <div className="bg-soft-primary">
                <Row>
                    <Col xs="7">
                        <div className="text-primary p-3">
                            <h5 className="text-primary">{title ? t(title) : t('details')}</h5>
                        </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                        <img src={profile1} alt="" className="img-fluid" />
                    </Col>
                </Row>
            </div>
            <CardBody className="pt-0">
                <Col sm="12">
                    <Row className={'no-gutters justify-content-between'}>
                        <div className="avatar-md profile-user-wid mb-4">
                            <img src={avatar ? avatar : placeholder} alt="" className="rounded-circle img-thumbnail avatar-md avatar-cover" />
                        </div>

                        {date && (
                            <div className={'d-flex text-right align-items-center'}>
                                {t('member.since')}
                                <br/>
                                {moment(date).format('DD/MM/YYYY')}
                            </div>
                        )}
                    </Row>
                    <h5 className="font-size-15 text-truncate">{name}</h5>

                    {role && (
                        <p className="text-truncate mt-4">{t(role)}</p>
                    )}

                    {company && (
                        <h5 className="font-size-15 text-truncate">{company}</h5>
                    )}
                </Col>
            </CardBody>
        </Card>
    );
};