import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';

//Account Redux states
import { REGISTER_USER, SOCIAL_REGISTER_USER, REGISTER_RESEND_CONFIRM_EMAIL } from './actionTypes';
import {
    registerUserSuccessful,
    registerUserFailed,
    socialRegisterUserSuccess,
    socialRegisterUserError,
    registerResendConfirmEmailSuccess,
    registerResendConfirmEmailError,
} from './actions';
import { loginSuccess, setOnboardingStatus } from '../login/actions';
import { updateUser } from '../../user/actions';

import { FORM_SIGN_UP } from '../../../consts/forms';

import { API } from '../../../api'

function* registerUser({ payload: { params } }) {
    try {
        const response = yield call(API.auth.signUp, params);

        const { data } = response;

        yield put(registerUserSuccessful(data));
    } catch (error) {
        const errors = error.response.data.errors;
        const message = error.response.data.message ? error.response.data.message : 'Server Error';
        yield put(registerUserFailed(message));

        if (errors) {
            yield put(stopSubmit(FORM_SIGN_UP, errors));
        }
    }
}

function* socialRegisterUser({ payload: { params, formData, history } }) {
    try {
        const response = yield call(API.auth.socialRegister, params, formData);

        const { data } = response;

        yield put(socialRegisterUserSuccess(data));

        // save user data for form register step 2
        yield put(updateUser({ ...data?.data, filled: false, email_verified: false }));
        // redirect to register step 2
        yield put(setOnboardingStatus(true));

        yield put(loginSuccess(data));
    } catch (error) {
        const errors = error.response.data.errors;
        const message = error.response.data.message ? error.response.data.message : 'Server Error';
        yield put(socialRegisterUserError({
            errors,
            message,
        }));
    }
}

function* resendConfirmEmail({ payload: { params } }) {
    try {
        const response = yield call(API.auth.sendVerifyEmail, params);

        const { data } = response;
        yield put(registerResendConfirmEmailSuccess(true));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(registerResendConfirmEmailError({
            errors,
            message
        }));
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser);
}

export function* watchSocialRegister() {
    yield takeEvery(SOCIAL_REGISTER_USER, socialRegisterUser);
}

export function* watchResendEmail() {
    yield takeEvery(REGISTER_RESEND_CONFIRM_EMAIL, resendConfirmEmail);
}

function* accountSaga() {
    yield all([
        fork(watchUserRegister),
        fork(watchSocialRegister),
        fork(watchResendEmail),
    ]);
}

export default accountSaga;