export const CHAT_ATTACH_FILES = 'CHAT_ATTACH_FILES';

export const CHAT_UPLOAD_FILE = 'CHAT_UPLOAD_FILE';
export const CHAT_UPLOAD_FILE_SUCCESS = 'CHAT_UPLOAD_FILE_SUCCESS';
export const CHAT_UPLOAD_FILE_ERROR = 'CHAT_UPLOAD_FILE_ERROR';

export const CHAT_REMOVE_FILE = 'CHAT_REMOVE_FILE';
export const CHAT_REMOVE_FILE_SUCCESS = 'CHAT_REMOVE_FILE_SUCCESS';
export const CHAT_REMOVE_FILE_ERROR = 'CHAT_REMOVE_FILE_ERROR';


export const CHAT_CLEAN_ATTACHMENTS = 'CHAT_CLEAN_ATTACHMENTS';