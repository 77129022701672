import React, { useState, useRef } from 'react';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import { Input, FormGroup, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

import { SLIDER_MODE_INPUT } from "../../../consts/common";

export const SliderField = ({
    input,
    limit,
    inputConfig,
    mode = 'default',
    labelPrefix = '',
    inputPrefix = '',
    range = {
        'min': [limit.min],
        'max': [limit.max],
    },
    step = 1,
    id = 'slider-field',
}) => {
    const { t } = useTranslation();

    const [initialValue, setInitialValue] = useState(input.value);

    const [sliderValue, setSliderValue] = useState(input.value[0]);

    const sliderRef = useRef(null);

    const onSliderUpdate = (value) => {
        const data = Math.round(parseInt(value[0]));

        setSliderValue(data);

        return input.onChange([data]);
    };

    const under = (value) => {
        let numberValue = Number(value);
        if (numberValue < limit.min) {
            return limit.min;
        } else if (numberValue > limit.max) {
            return limit.max;
        } else {
            return value;
        }
    };

    const onChange = (e) => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '') {
            return setSliderValue(e.target.value);
        }

        if (re.test(e.target.value)) {
            let val = under(e.target.value);
            return setSliderValue(val);
        }
    };

    const onBlur = (e) => {
        let data;
        let max = limit.max;

        if (e.target.value === '') {
            data = limit.min;
        } else {
            data = e.target.value > max ? max : e.target.value;
        }

        if (sliderRef.current) {
            sliderRef.current.noUiSlider.set([data]);
        }
    };

    return (
        <Col>
            <Row className="justify-content-between">
                {mode === SLIDER_MODE_INPUT ? (
                    <p className={'font-size-15'}>{`${labelPrefix}${sliderValue}`}</p>
                ) : (
                    <>
                        <FormGroup row className={'mb-4'}>
                            <Col lg={'12'}>
                                <div className={'input-group'}>
                                    {inputPrefix !== '' && (
                                        <div className="input-group-prepend">
                                            <span className="input-group-text input-prepend-custom">{inputPrefix}</span>
                                        </div>
                                    )}

                                    <Input
                                        name={inputConfig?.id || 'min'}
                                        id={inputConfig?.id || 'min'}
                                        placeholder={t(inputConfig?.placeholder || 'range_from')}
                                        type={'text'}
                                        className="form-control"
                                        onChange={(e) => onChange(e)}
                                        value={sliderValue}
                                        onBlur={(value) => onBlur(value)}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                    </>
                )}
            </Row>

            <Row>
                <div className={'price-range-wrap'}>
                    <Nouislider
                        id={id}
                        instanceRef={sliderRef}
                        range={range}
                        start={initialValue}
                        onUpdate={(value) => onSliderUpdate(value)}
                        connect={[true, false]}
                    />
                </div>
            </Row>
        </Col>
    );
};