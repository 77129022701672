import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_CONTACT } from './actionTypes';
import {
    fetchContactSuccess,
    fetchContactError,
} from './actions';

import { API } from '../../../api/index'

function* fetchContact({ payload: { id } }) {
    try {
        const includes = ['company'];

        const response = yield call(API.user.getUser, id, { includes });

        const { data } = response;
        yield put(fetchContactSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchContactError(message));
    }
}

export function* watchContactFetch() {
    yield takeEvery(FETCH_CONTACT, fetchContact)
}

function* contactProfileSaga() {
    yield all([
        fork(watchContactFetch),
    ]);
}

export default contactProfileSaga;