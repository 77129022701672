import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_LEAD } from './actionTypes';
import {
    fetchLeadSuccess,
    fetchLeadError,
} from './actions';

import { API } from '../../../api';

function* fetchLead({ payload: { id, params } }) {
    try {
        const includes = ['user', 'propertyAd', 'propertyAd.user', 'propertyAd.cities', 'propertyAd.regions'];

        const requestParams = {
            includes,
            ...params,
        };

        const response = yield call(API.lead.getLead, id, requestParams);

        const { data } = response;
        yield put(fetchLeadSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchLeadError(message));
    }
}

export function* watchLeadFetch() {
    yield takeEvery(FETCH_LEAD, fetchLead)
}

function* viewSaga() {
    yield all([
        fork(watchLeadFetch),
    ]);
}

export default viewSaga;