import {
    FETCH_RECEIVED_INVITE_LIST,
    FETCH_RECEIVED_INVITE_LIST_SUCCESS,
    FETCH_RECEIVED_INVITE_LIST_ERROR,
    CLEAN_INVITE_LIST_PAGE,

    FETCH_RECEIVED_ACTIVE_INVITE_LIST,
    FETCH_RECEIVED_ACTIVE_INVITE_LIST_SUCCESS,
    FETCH_RECEIVED_ACTIVE_INVITE_LIST_ERROR,
    CLEAN_ACTIVE_INVITE_LIST,

    FETCH_RECEIVED_INACTIVE_INVITE_LIST,
    FETCH_RECEIVED_INACTIVE_INVITE_LIST_SUCCESS,
    FETCH_RECEIVED_INACTIVE_INVITE_LIST_ERROR,
    CLEAN_INACTIVE_INVITE_LIST,

    FETCH_PROPERTY_INVITES,
    FETCH_PROPERTY_INVITES_SUCCESS,
    FETCH_PROPERTY_INVITES_ERROR,
    CLEAN_PROPERTY_INVITES,
    PROPERTY_INVITES_SET_SORT,
    PROPERTY_INVITES_SET_PAGE,
    PROPERTY_INVITES_SET_SEARCH,
} from './actionTypes';

const initialState = {
    received: [],
    receivedLoading: false,
    receivedError: null,

    receivedActive: [],
    receivedActiveLoading: false,
    receivedActiveError: null,

    receivedInactive: [],
    receivedInactiveLoading: false,
    receivedInactiveError: null,

    property: [],
    propertyLoading: false,
    propertyError: null,
    propertyClientsPage: 1,
    propertyClientsMeta: null,

    propertyClientsSort: [],
    propertyClientsSearch: null,
};

const invite = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RECEIVED_INVITE_LIST:
            state = {
                ...state,
                receivedLoading: true,
                receivedError: null,
            };
            break;

        case FETCH_RECEIVED_INVITE_LIST_SUCCESS:
            state = {
                ...state,
                receivedLoading: false,
                received: action.payload,
            };
            break;

        case FETCH_RECEIVED_INVITE_LIST_ERROR:
            state = {
                ...state,
                receivedLoading: false,
                receivedError: action.payload,
            };
            break;

        case CLEAN_INVITE_LIST_PAGE:
            state = {
                ...state,
                received: [],
                receivedLoading: false,
                receivedError: null,
            };
            break;

        case FETCH_RECEIVED_ACTIVE_INVITE_LIST:
            state = {
                ...state,
                receivedActiveLoading: true,
                receivedActiveError: null,
            };
            break;

        case FETCH_RECEIVED_ACTIVE_INVITE_LIST_SUCCESS:
            state = {
                ...state,
                receivedActiveLoading: false,
                receivedActive: action.payload,
            };
            break;

        case FETCH_RECEIVED_ACTIVE_INVITE_LIST_ERROR:
            state = {
                ...state,
                receivedActiveLoading: false,
                receivedActiveError: action.payload,
            };
            break;

        case CLEAN_ACTIVE_INVITE_LIST:
            state = {
                ...state,
                receivedActive: [],
                receivedActiveLoading: false,
                receivedActiveError: null,
            };
            break;

        case FETCH_RECEIVED_INACTIVE_INVITE_LIST:
            state = {
                ...state,
                receivedInactiveLoading: true,
                receivedInactiveError: null,
            };
            break;

        case FETCH_RECEIVED_INACTIVE_INVITE_LIST_SUCCESS:
            state = {
                ...state,
                receivedInactiveLoading: false,
                receivedInactive: action.payload,
            };
            break;

        case FETCH_RECEIVED_INACTIVE_INVITE_LIST_ERROR:
            state = {
                ...state,
                receivedInactiveLoading: false,
                receivedInactiveError: action.payload,
            };
            break;

        case CLEAN_INACTIVE_INVITE_LIST:
            state = {
                ...state,
                receivedInactive: [],
                receivedInactiveLoading: false,
                receivedInactiveError: null,
            };
            break;

        case FETCH_PROPERTY_INVITES:
            state = {
                ...state,
                propertyLoading: true,
                propertyError: null,
            };
            break;

        case FETCH_PROPERTY_INVITES_SUCCESS:
            state = {
                ...state,
                propertyLoading: false,
                property: action.payload.data,
                propertyClientsMeta: action.payload.meta,
            };
            break;

        case FETCH_PROPERTY_INVITES_ERROR:
            state = {
                ...state,
                propertyLoading: false,
                propertyError: action.payload,
            };
            break;

        case PROPERTY_INVITES_SET_SORT:
            state = {
                ...state,
                propertyClientsSort: action.payload,
                propertyClientsPage: initialState.propertyClientsPage,
            };
            break;

        case PROPERTY_INVITES_SET_PAGE:
            state = {
                ...state,
                propertyClientsPage: action.payload,
            };
            break;

        case PROPERTY_INVITES_SET_SEARCH:
            state = {
                ...state,
                propertyClientsSearch: action.payload,
                propertyClientsPage: initialState.propertyClientsPage,
            };
            break;

        case CLEAN_PROPERTY_INVITES:
            state = {
                ...state,
                property: [],
                propertyLoading: false,
                propertyError: null,
                propertyClientsSort: [],
                propertyClientsPage: initialState.propertyClientsPage,
                propertyClientsMeta: null,
                propertyClientsSearch: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default invite;