import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import rootReducer from './reducers';
import rootSaga from './sagas';
import { echoMiddleware } from './middlewares'

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(
            echoMiddleware,
            sagaMiddleware,
            thunk
        )
    )
);

sagaMiddleware.run(rootSaga);
let persistor = persistStore(store);

export default () => {
    return { store, persistor };
}