export const FETCH_OFFERS = 'FETCH_OFFERS';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_ERROR = 'FETCH_OFFERS_ERROR';
export const CLEAN_OFFERS_PAGE = 'CLEAN_OFFERS_PAGE';

export const OFFERS_SET_SORT = 'OFFERS_SET_SORT';

export const OFFERS_SET_PAGE = 'OFFERS_SET_PAGE';

export const OFFERS_SET_FILTER = 'OFFERS_SET_FILTER';
export const OFFERS_RESET_FILTER = 'OFFERS_RESET_FILTER';

export const UPDATE_OFFER_ITEM = 'UPDATE_OFFER_ITEM';