import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { useTitle } from "../../hooks";
import { PageTitle } from '../../components/Custom';
import { CompanyLeads, UserLeads } from './components';
import { ROLE_CONSULTANT, ROLE_MANAGER } from '../../consts/roles';

const LeadListComponent = (props) => {
    useTitle(props.t('title.leads'));

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('title.leads')} />

                    <Row>
                        <Col lg="12">
                            {props.user.role === ROLE_MANAGER && (
                                <CompanyLeads />
                            )}

                            {props.user.role === ROLE_CONSULTANT && (
                                <UserLeads />
                            )}
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

const mapDispatchToProps = {
};

export const LeadList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadListComponent)));