import axios from 'axios';
import qs from 'qs';

const paramsSerializer = (params) => {
    return qs.stringify(params, { encode: false });
};

class BillingAPI {
    setupIntent = async () => {
        return axios.get('api/billing/setup-intent');
    };

    getPaymentMethods = async () => {
        return axios.get(`api/billing/payment-methods`);
    };

    addPaymentMethod = async (params) => {
        return axios.post(`api/billing/payment-methods`, params);
    };

    deletePaymentMethod = async (id) => {
        return axios.delete(`api/billing/payment-methods/${id}`);
    };

    sendDefaultPaymentMethod = async (params) => {
        return axios.post(`api/billing/payment-methods/default`, params);
    };

    payCredits = async (params) => {
        return axios.post(`api/billing/credits`, params);
    };

    paySubscription = async (params) => {
        return axios.post(`api/billing/subscription`, params);
    };

    getBillingInfo = async () => {
        return axios.get('api/billing-address');
    };

    editBillingInfo = async (params) => {
        return axios.put(`api/billing-address`, params);
    };

    getSubscriptionTariffs = async () => {
        return axios.get(`api/tariffs`);
    };

    getSubscriptionPaymentLink = async () => {
        return axios.get(`api/billing/subscription/payment-link`);
    };

    deleteSubscription = async () => {
        return axios.delete(`api/billing/subscription`);
    };

    resumeSubscription = async () => {
        return axios.post(`api/billing/subscription/resume`);
    };

    swapSubscription = async (params) => {
        return axios.post(`api/billing/subscription/swap`, params);
    };

    getInvoices = async (params) => {
        return axios.get('api/invoices', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    getInvoice = async (id) => {
        return axios.get(`api/invoices/${id}`);
    };
}

export const billing = new BillingAPI();