import { PROPERTY_PRICE_MAX, PROPERTY_PRICE_MIN } from "./property";

export const OFFER_STATUS_UNCONFIRMED = 'unconfirmed';
export const OFFER_STATUS_CONFIRMED = 'confirmed';
export const OFFER_STATUS_DECLINED = 'declined';

export const OFFER_DIFFERENCE_DIRECTION_TYPE_ALL = 'all';
export const OFFER_DIFFERENCE_DIRECTION_TYPE_UP = 'up';
export const OFFER_DIFFERENCE_DIRECTION_TYPE_DOWN = 'down';

export const OFFERS_FILTER_INITIAL_VALUES = {
    property_price: {
        range: [PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX],
        empty_price: false,
    },
    new_price: [PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX],
    difference: {
        range: [PROPERTY_PRICE_MIN + 1, PROPERTY_PRICE_MAX],
        direction: OFFER_DIFFERENCE_DIRECTION_TYPE_ALL,
    },
    price_status: null,
};