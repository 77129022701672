import {all, fork, put, takeEvery} from 'redux-saga/effects';
import toastr from "toastr";

import {ON_UPDATE_CRM_SYSTEM_ACCOUNT_EVENT} from './actionTypes';
import {crmSystemListUpdateItem} from "../../crmSystem/actions";

import i18n from "../../../i18n";

function* onUpdateCrmSystemAccountEvent({payload: {data}}) {
  const { account } = data
  const { last_status, crm_system } = account

  yield put(crmSystemListUpdateItem(crm_system?.id, {my_crm_system_account: {...account}}));

  if (last_status) {
    toastr.success(i18n.t('sync_completed.success'));
  } else {
    toastr.error(i18n.t('sync.failed'));
  }
}

export function* watchUpdateCrmSystemAccountEvent() {
  yield takeEvery(ON_UPDATE_CRM_SYSTEM_ACCOUNT_EVENT, onUpdateCrmSystemAccountEvent)
}

function* crmSystemSaga() {
  yield all([
    fork(watchUpdateCrmSystemAccountEvent),
  ]);
}

export default crmSystemSaga;
