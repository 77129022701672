import React from 'react';
import { Row, Col, Button, FormGroup, Label } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, FormSection } from 'redux-form'
import { connect } from "react-redux";

import { OFFERS_FILTER_FORM } from "../../../../../consts/forms";
import SelectInput from "../../../../../components/Common/SelectInput";
import SwitchField from "../../../../../components/Common/SwitchField";
import RadioField from '../../../../../components/Common/RadioField';
import { PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX } from '../../../../../consts/property';
import { OFFER_DIFFERENCE_DIRECTION_TYPE_ALL, OFFER_DIFFERENCE_DIRECTION_TYPE_UP, OFFER_DIFFERENCE_DIRECTION_TYPE_DOWN } from '../../../../../consts/offer';

import { PriceSliderRangeField } from '../../../../../components/Custom';

const OffersFilterFormComponent = (props) => {
    const handleReset = () => {
        props.reset(OFFERS_FILTER_FORM);

        props.onReset();
    };

    return (
        <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="form-horizontal"
            noValidate={true}
        >
            <FormSection name={'property_price'}>
                <FormGroup row className={'mb-4'}>
                    <Label className={'col-form-label col-lg-2'}>{props.t('field.label.property.price')}</Label>

                    <Col lg="10">
                        <Field
                            name="range"
                            component={PriceSliderRangeField}
                            limit={{
                                min: PROPERTY_PRICE_MIN,
                                max: PROPERTY_PRICE_MAX,
                            }}
                            inputMinConfig={{
                                id: 'price_min',
                                placeholder: 'field.placeholder.price_from'
                            }}
                            inputMaxConfig={{
                                id: 'price_max',
                                placeholder: 'field.placeholder.price_to'
                            }}
                        />
                    </Col>
                </FormGroup>

                <Field
                    name="empty_price"
                    label={''}
                    placeholder={props.t('field.placeholder.property_without_price')}
                    id={'empty_price'}
                    type="checkbox"
                    component={SwitchField}
                />
            </FormSection>

            <br/>

            <FormGroup row className={'mb-4'}>
                <Label className={'col-form-label col-lg-2'}>{props.t('field.label.new_price')}</Label>

                <Col lg="10">
                    <Field
                        name="new_price"
                        component={PriceSliderRangeField}
                        limit={{
                            min: PROPERTY_PRICE_MIN,
                            max: PROPERTY_PRICE_MAX,
                        }}
                        inputMinConfig={{
                            id: 'new_price_min',
                            placeholder: 'field.placeholder.price_from'
                        }}
                        inputMaxConfig={{
                            id: 'new_price_max',
                            placeholder: 'field.placeholder.price_to'
                        }}
                    />
                </Col>
            </FormGroup>

            <br/><br/>

            <FormSection name={'difference'}>
                <FormGroup row className={'mb-4'}>
                    <Label className={'col-form-label col-lg-2'}>{props.t('difference')}</Label>

                    <Col lg="10">
                        <Field
                            name="range"
                            component={PriceSliderRangeField}
                            limit={{
                                min: PROPERTY_PRICE_MIN + 1,
                                max: PROPERTY_PRICE_MAX,
                            }}
                            inputMinConfig={{
                                id: 'difference_min',
                            }}
                            inputMaxConfig={{
                                id: 'difference_max',
                            }}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label className={'col-form-label col-lg-2'} />

                    <Col lg="10">
                        <Row className={'no-gutters'}>
                            <Label className={'col-form-label col-lg-3'}>{props.t('difference_direction')}</Label>

                            <Col lg="9">
                                <div className={'form-control border-0'}>
                                    <Field
                                        name="direction"
                                        value={OFFER_DIFFERENCE_DIRECTION_TYPE_ALL}
                                        label={props.t('difference.direction.all')}
                                        id={'direction-all'}
                                        type="radio"
                                        component={RadioField}
                                    />

                                    <Field
                                        name="direction"
                                        value={OFFER_DIFFERENCE_DIRECTION_TYPE_UP}
                                        label={props.t('difference.direction.up')}
                                        id={'direction-up'}
                                        type="radio"
                                        component={RadioField}
                                    />

                                    <Field
                                        name="direction"
                                        value={OFFER_DIFFERENCE_DIRECTION_TYPE_DOWN}
                                        label={props.t('difference.direction.down')}
                                        id={'direction-down'}
                                        type="radio"
                                        component={RadioField}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </FormGroup>

            </FormSection>

            <br/>

            <Field
                name="price_status"
                label={props.t('field.label.property_statuses')}
                placeholder={props.t('field.placeholder.property_statuses')}
                id={'offer-statuses'}
                component={SelectInput}
                options={props.statusOptions}
                isMulti
                isSearchable={false}
            />

            <Row className="justify-content-end">
                <Col lg="10">
                    <div className="button-items">
                        <Button
                            type="submit"
                            color="primary"
                        >
                            {props.t('apply')}
                        </Button>

                        <Button
                            type="button"
                            color="light"
                            outline
                            onClick={handleReset}
                        >
                            {props.t('reset')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};

const OffersFilter = reduxForm({
    form: OFFERS_FILTER_FORM,
    enableReinitialize: true,
})(OffersFilterFormComponent);

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const OffersFilterForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OffersFilter));