import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { Pagination } from '../../../../../../components/Custom';
import { LeadsTable } from '../../LeadsTable';
import {
    fetchCompanyPaidLeads,
    cleanCompanyPaidLeads,
    companyPaidLeadsSetPage,
} from '../../../../../../store/actions';
import { companyPaidLeadsColumns } from '../../../../../../consts/lead';

import * as HOC from '../../../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const CompanyPaidLeadsComponent = (props) => {
    useEffect(() => {
        let params = {
            page: props.page,
        };

        props.fetchCompanyPaidLeads(params);
    }, [props.page]);

    useEffect(() => {
        return () => {
            props.cleanCompanyPaidLeads();
        }
    }, []);

    const handlePageChange = (item) => {
        props.companyPaidLeadsSetPage(item.selected + 1)
    };

    return (
        <TableDataLoading isLoading={props.listLoading} isFailed={props.listError} error={props.t(props.listError)}>
            <>
                <LeadsTable
                    keys={companyPaidLeadsColumns}
                    items={props.list}
                    isLoading={props.listLoading}
                    onSort={() => {}}
                />

                <Pagination data={props.meta} onPageChange={handlePageChange} />
            </>
        </TableDataLoading>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError, meta, page } = state.lead.companyLeads.paid;
    return {
        list,
        listLoading,
        listError,
        meta,
        page,
    };
};

const mapDispatchToProps = {
    fetchCompanyPaidLeads,
    cleanCompanyPaidLeads,
    companyPaidLeadsSetPage,
};

export const CompanyPaidLeads = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompanyPaidLeadsComponent)));