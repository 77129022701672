import {
    FETCH_COMPANY_PAID_LEADS,
    FETCH_COMPANY_PAID_LEADS_SUCCESS,
    FETCH_COMPANY_PAID_LEADS_ERROR,
    CLEAN_COMPANY_PAID_LEADS,
    COMPANY_PAID_LEADS_SET_PAGE,
} from './actionTypes';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    meta: null,
    page: 1,
};

const paid = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_COMPANY_PAID_LEADS:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case FETCH_COMPANY_PAID_LEADS_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case FETCH_COMPANY_PAID_LEADS_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case CLEAN_COMPANY_PAID_LEADS:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case COMPANY_PAID_LEADS_SET_PAGE:
            state = {
                ...state,
                page: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default paid;