import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, CardBody, Row, Spinner } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { cleanChatContacts, fetchChatContacts, setChatActiveTab } from 'store/actions';

import * as HOC from 'HOC';
import { ContactsList } from "../ContactsList";
import { isCloseToBottom } from "utils/scrollbar";
import { CHAT_TABS } from "consts/chat";

const DataShower = HOC.withDataShower();

const ContactsComponent = (props) => {

    const [page, setPage] = useState(1);

    useEffect(() => {
        let params = {
            page,
        };

        fetchData(params);


    }, [page]);

    useEffect(() => {
        return () => {
            props.cleanChatContacts()
        }
    }, [])

    const fetchData = (params) => {
        props.fetchChatContacts(params);
    };

    const isMoreLoading = useMemo(() => {
        return props.loading && page !== 1;
    }, [props.loading, page]);

    const loadMore = () => {
        const { meta } = props;
        if (meta && meta.current_page !== meta.last_page && !props.loading) {
            setPage(meta.current_page + 1);
        }
    };

    const scrollBarRef = useRef(null);

    const onScroll = (e) => {
        if (isCloseToBottom(e, 50)) {
            loadMore();
        }
    };

    return (
        <>
            <Row className="no-gutters justify-content-between mb-3 align-items-center" style={{ height: "24px" }}>
                <h5 className={'mb-0'}>{props.t('contacts')}</h5>

                <Link to={`/contacts`} className="align-self-center text-secondary">
                    {props.t('title.contacts')}
                </Link>
                <Link to={`/chat`} className="align-self-center text-secondary"
                      onClick={() => props.setChatActiveTab(CHAT_TABS.CONTACTS)}>
                    {props.t('show_all')}
                </Link>

            </Row>
            <Card>
                <CardBody style={{ padding: "0 0.5rem" }}>
                    <PerfectScrollbar
                        style={{ height: props.list.length > 0 ? "294px" : 'auto' }}
                        ref={scrollBarRef}
                        onScrollY={onScroll}
                        options={{ wheelPropagation: false }}
                    >
                        <DataShower
                            isLoading={props.loading && !isMoreLoading}
                            isFailed={props.error}
                            error={props.t(props.error)}
                            customLoaderClass={'data-shower-loader-center'}
                            spinnerSize={'sm'}
                        >
                            <ContactsList items={props.list}/>

                            {isMoreLoading && (
                                <div className={'text-center'}>
                                    <Spinner size={'sm'} color="primary"/>
                                </div>
                            )}
                        </DataShower>
                    </PerfectScrollbar>
                </CardBody>
            </Card>
        </>
    );
};

const mapStateToProps = state => {
    const { list, loading, error, meta } = state.chat.contacts;

    return {
        list, loading, error, meta
    };
};

const mapDispatchToProps = {
    fetchChatContacts,
    cleanChatContacts,
    setChatActiveTab
};

export const Contacts = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactsComponent)));