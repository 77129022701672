import React, { useEffect } from 'react';
import { Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link, useHistory } from 'react-router-dom';

import { ProfileInfoBox } from './ProfileInfoBox';
import {
    cleanClientDashboardStatistics,
    fetchClientDashboardStatistics,
    receivedActivePropertyListSetFilter,
} from "../../../store/actions";
import {
    INVITE_STATUS_UNCONFIRMED,
    INVITES_FILTER_INITIAL_VALUES,
} from "../../../consts/invite";

import * as HOC from '../../../HOC';
const DataShower = HOC.withDataShower();

const ClientInviteStatisticsComponent = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        props.fetchClientDashboardStatistics();

        return () => {
            props.cleanClientDashboardStatistics();
        };
    }, []);

    const navigateToInvites = (e) => {
        e.preventDefault();

        props.receivedActivePropertyListSetFilter({
            price: {
                ...INVITES_FILTER_INITIAL_VALUES.price,
                empty_price: true,
            },
            links_statuses: [INVITE_STATUS_UNCONFIRMED],
        });

        history.push(`/properties/invites`);
    };

    return (
        <Row className={'profile-info-boxes-row'}>
            <Col md="4">
                <ProfileInfoBox>
                    <p className="text-muted font-weight-medium">{t('active_invites')}</p>
                    <DataShower
                        isLoading={props.loading}
                        isFailed={props.error}
                        error={t(props.error)}
                        customLoaderClass={'data-shower-loader-center'}
                        spinnerSize={'sm'}
                    >
                        <h4 className={'mb-0 text-truncate'}>
                            {props.data?.active_links_count !== null ? props.data?.active_links_count : '-'}
                        </h4>
                    </DataShower>
                </ProfileInfoBox>
            </Col>

            <Col md="4">
                <ProfileInfoBox>
                    <p className="text-muted font-weight-medium">{t('unconfirmed_invites')}</p>
                    <Link to={`#`} onClick={(e) => navigateToInvites(e)} className="align-self-start">
                        <h4 className={'mb-0 text-truncate'}>
                            {props.userData?.new_links_count !== null ? props.userData?.new_links_count : '-'}
                        </h4>
                    </Link>
                </ProfileInfoBox>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const { data, loading, error } = state.statistics.client;
    return {
        data,
        loading,
        error,
        userData: state.user.data,
    };
};

const mapDispatchToProps = {
    cleanClientDashboardStatistics,
    fetchClientDashboardStatistics,
    receivedActivePropertyListSetFilter,
};

export const ClientInviteStatistics = connect(mapStateToProps, mapDispatchToProps)(ClientInviteStatisticsComponent);