import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

export const BackButton = withRouter(() => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Button color="primary" outline className={'waves-effect waves-light'} onClick={() => history.goBack()} >
            <i className="fas fa-angle-left font-size-16 align-middle mr-2"></i>
            {t('back')}
        </Button>
    );
});