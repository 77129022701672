import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CustomButton, PickFileButton } from "components/Custom";
import { connect } from "react-redux";
import { importProperty, propertySetPage } from "store/property/actions";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import toastr from "toastr";

const downloadLink = "https://docs.google.com/spreadsheets/d/1Pd4iDl_CqR7Dz8J_jEp8u9gpVJ1pTNCzcex3X1rSm0k/edit?usp=sharing"

const ImportPropertiesComponent = (props) => {
    const { t } = useTranslation();

    const [modalVisible, setModalVisible] = useState(false)

    const onSuccess = () => {
        toggleModal()
    }
    const handleFilePicked = (data) => {
        props.importProperty(data, onSuccess).catch((message) => {
            toastr.error(message);
        })
    }

    const showModal = () => {
        setModalVisible(true)
    }

    const toggleModal = () => setModalVisible(!modalVisible)

    return (
        <>
            <CustomButton
                loading={props.isLoading}
                disabled={props.isLoading}
                color="primary"
                onClick={showModal}
                title={"import"}
                leftIcon={"bx bx-import font-size-16"}
            />
            <Modal
                size="lg"
                isOpen={modalVisible}
                toggle={toggleModal}
            >
                <ModalHeader
                    toggle={toggleModal}
                >
                    {t('import')}
                </ModalHeader>

                <ModalBody>
                    <ol className={"import-properties-list"}>
                        <li>{t("download")} <a
                            href={downloadLink}
                            target="_blank">{t("example")}</a></li>
                        <li>{t("fill-in-the-fields")}</li>
                        <li>
                            <div className={"d-inline-flex align-items-center"}>
                                <div className={"text-nowrap mr-2"}>
                                    {t("upload-file")}
                                </div>
                                <PickFileButton disabled={props.isLoading} accept={".csv"} onChange={handleFilePicked}
                                                title={"CSV"}/>
                            </div>
                        </li>
                    </ol>
                </ModalBody>
            </Modal>
        </>
    );
};

ImportPropertiesComponent.propTypes = {
    importProperty: PropTypes.func,
    propertySetPage: PropTypes.func,
    cleanImportProperty: PropTypes.func,

    isLoading: PropTypes.bool,
    error: PropTypes.any
};


const mapStateToProps = state => {
    const { isLoading } = state.property.import;
    return {
        isLoading
    };
};

const mapDispatchToProps = {
    importProperty,
    propertySetPage
};


export const ImportProperties = connect(mapStateToProps, mapDispatchToProps)(ImportPropertiesComponent);


