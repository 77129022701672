import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { ROLE_CLIENT } from '../../../../../consts/roles';
import { fetchPropertyList, cleanPropertyListPage, propertyResetSort, propertySetPage } from '../../../../../store/actions';
import { PropertyListTableView } from '../PropertyListViews';

const GeneralPropertiesComponent = (props) => {
    const [search, setSearch] = useState(null);

    useEffect(() => {
        let params = {
            page: props.page,
        };

        if (props.filterIsActive) {
            params.filter = props.filter;
        }

        if (props.user.role === ROLE_CLIENT) {
            params.sort = [{ id: 'is_favorite', desc: true }];
            params.per_page = 12;
        }

        if (props.sort.length > 0) {
            params.sort = props.sort;
        }

        if (search) {
            params.filter = {
                ...params.filter,
                keywords: search,
                search_fields: ['address', 'unit'],
            };
        }

        props.fetchPropertyList(params);
    }, [props.filterIsActive, props.filter, props.sort, props.page, search]);

    useEffect(() => {
        return () => {
            props.cleanPropertyListPage();
            props.propertyResetSort();
        }
    }, []);

    const handleSearchChange = (value) => {
        props.propertySetPage(1);
        setSearch(value);
    };

    return (
        <PropertyListTableView
            onSearchChange={handleSearchChange}
            search={search}
        />
    );
};

const mapStateToProps = state => {
    const { filter, filterIsActive, sort, page } = state.property.list;
    return {
        user: state.user.data,
        filter,
        filterIsActive,
        sort,
        page,
    };
};

const mapDispatchToProps = {
    fetchPropertyList,
    cleanPropertyListPage,
    propertyResetSort,
    propertySetPage,
};

export const GeneralProperties = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GeneralPropertiesComponent)));