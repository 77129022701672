import React from 'react';
import { CustomInput } from "reactstrap";

const RadioField = ({
    label,
    placeholder,
    type,
    input,
    meta: { touched, error, warning },
    defaultValue,
    reference,
    children,
    customClass,
    ...props
}) => {
    // return (
    //     <div className="custom-control custom-radio mb-3">
    //         <input
    //             type="radio"
    //             id={props.id}
    //             name={props.name}
    //             className="custom-control-input"
    //             value={input.value}
    //             onChange={(value) => input.onChange(value)}
    //             {...input}
    //         />
    //         <label className="custom-control-label" htmlFor={props.id}>{label}</label>
    //     </div>
    // );
    return (
        <CustomInput
            type="radio"
            className={'custom-control-inline'}
            id={props.id}
            label={label}
            htmlFor={props.id}
            {...input}
        />
    );
};

export default RadioField;