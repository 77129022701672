import React from 'react';

export const MapPointer = ({
    width = "14",
    height = "14",
    viewBox = "0 0 14 14",
    color = '#74788D',
}) => (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 14C7 14 12.25 9.11575 12.25 5.25C12.25 1.38425 9.89975 0 7 0C4.10025 0 1.75 1.38425 1.75 5.25C1.75 9.11575 7 14 7 14ZM4.375 4.375C4.375 2.92513 5.55013 1.75 7 1.75C8.44987 1.75 9.625 2.92513 9.625 4.375C9.625 5.82487 8.44987 7 7 7C5.55013 7 4.375 5.82487 4.375 4.375Z" fill={color} />
    </svg>
);