import {
    CREATE_PROPERTY,
    CREATE_PROPERTY_SUCCESS,
    CREATE_PROPERTY_ERROR,
    CLEAN_CREATE_PROPERTY_PAGE,

    CREATE_PROPERTY_SET_VALUES,
} from './actionTypes';

const initialState = {
    values: {
        enabled: false,
    },

    createLoading: false,
    createSuccess: null,
    createError: null,
};

const property = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PROPERTY:
            state = {
                ...state,
                createLoading: true,
                createSuccess: null,
                createError: null,
            };
            break;

        case CREATE_PROPERTY_SUCCESS:
            state = {
                ...state,
                createLoading: false,
                createSuccess: action.payload,
            };
            break;

        case CREATE_PROPERTY_ERROR:
            state = {
                ...state,
                createLoading: false,
                createError: action.payload,
            };
            break;

        case CREATE_PROPERTY_SET_VALUES:
            state = {
                ...state,
                values: {
                    ...state.values,
                    ...action.payload,
                },
            };
            break;

        case CLEAN_CREATE_PROPERTY_PAGE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default property;