import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { useTitle } from "../../hooks";
import { BackButton, PageTitle } from '../../components/Custom';
import { InvoiceDetails } from './components';

const BillingInvoiceDetailsComponent = (props) => {
    useTitle(props.t('title.invoice_details'));

    return (
        <React.Fragment>
            <div className="page-content min-vh-100">
                <Container fluid>

                    <PageTitle title={props.t('title.invoice_details')} />

                    <Row className={'mb-4 d-print-none'}>
                        <Col>
                            <Row className={'no-gutters justify-content-between'}>
                                <BackButton />
                            </Row>
                        </Col>
                    </Row>

                    <InvoiceDetails />

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export const BillingInvoiceDetails = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingInvoiceDetailsComponent)));