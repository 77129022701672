import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import toastr from 'toastr';
import { stopSubmit, reset } from "redux-form";

import i18n from '../../../i18n';

import { SEND_INVITE, UPDATE_INVITE, CHANGE_INVITE, SEND_OFFER } from './actionTypes';
import {
    sendInviteSuccess,
    sendInviteError,
    updateInviteSuccess,
    updateInviteError,
    changeInviteSuccess,
    changeInviteError,
    sendOfferSuccess,
    sendOfferError,
} from './actions';

import { fetchPropertyInvites } from '../lists/actions';
import { updatePropertyInviteStatus } from '../../property/actions';

import { API } from '../../../api/index'
import { PROPERTY_INVITE_CLIENT, OFFER_UPDATE_FORM } from "../../../consts/forms";
import { INVITE_STATUS_ACTIVE } from "../../../consts/invite";

export const getPropertyClientsSort = (state) => state.invite.lists.propertyClientsSort;
export const getPropertyClientsPage = (state) => state.invite.lists.propertyClientsPage;
export const getPropertyClientsSearch = (state) => state.invite.lists.propertyClientsSearch;

const getMapFetchParams = (data) => {
    const { page, sort, search } = data;
    let params = {
        page: page,
    };

    if (sort.length > 0) {
        params = {
            ...params,
            sort,
        };
    }

    if (search) {
        params = {
            ...params,
            filter: {
                ...params.filter,
                keywords: search,
            },
        }
    }

    return params
};

function* sendInvite({ payload: { params } }) {
    try {
        const { property } = params;

        const response = yield call(API.invite.sendInvite, params);

        const { data } = response;
        yield put(sendInviteSuccess(true));

        toastr.success(i18n.t('invite.send.success'));

        yield put(reset(PROPERTY_INVITE_CLIENT));

        // update property invite list
        let page = yield select(getPropertyClientsPage);
        let sort = yield select(getPropertyClientsSort);
        let search = yield select(getPropertyClientsSearch);

        const fetchParams = getMapFetchParams({ page, sort, search });

        yield put(fetchPropertyInvites(property, fetchParams));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(sendInviteError({
            errors,
            message
        }));

        yield put(stopSubmit(PROPERTY_INVITE_CLIENT, errors));
    }
}

function* updateInvite({ payload: { id, params, currentStatus, history } }) {
    try {
        const response = yield call(API.invite.updateInvite, id, params);

        const { data } = response;
        yield put(updateInviteSuccess(true));

        const { status } = params;

        if (status === INVITE_STATUS_ACTIVE) {
            yield put(updatePropertyInviteStatus(status));
        } else {
            const path = history.location.state?.from?.pathname ? history.location.state.from.pathname : '/properties';
            history.push(path);
        }

        toastr.success(i18n.t(`invite.update.${status}`));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(updateInviteError(message));
    }
}

function* changeInvite({ payload: { id, params } }) {
    try {
        const { property, status } = params;

        const response = yield call(API.invite.consultantChangeInvite, id, params);

        const { data } = response;
        yield put(changeInviteSuccess(true));

        toastr.success(i18n.t(`invite.change.${status}`));

        // update property invite list
        let page = yield select(getPropertyClientsPage);
        let sort = yield select(getPropertyClientsSort);
        let search = yield select(getPropertyClientsSearch);

        const fetchParams = getMapFetchParams({ page, sort, search });

        yield put(fetchPropertyInvites(property, fetchParams));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Error';

        yield put(changeInviteError(message));

        toastr.error(message);
    }
}

function* sendOffer({ payload: { id, params, action } }) {
    try {
        const { property, price } = params;

        const response = yield call(API.invite.consultantChangeInvite, id, params);

        const { data } = response;
        yield put(sendOfferSuccess(true));

        // hide modal
        if (action) {
            action();
        }

        if (price && price !== '') {
            toastr.success(i18n.t(`offer.sent.success`));
        } else {
            toastr.success(i18n.t(`offer.remove.success`));
        }

        // update property invite list
        let page = yield select(getPropertyClientsPage);
        let sort = yield select(getPropertyClientsSort);
        let search = yield select(getPropertyClientsSearch);

        const fetchParams = getMapFetchParams({ page, sort, search });

        yield put(fetchPropertyInvites(property, fetchParams));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(sendOfferError({
            errors,
            message
        }));

        yield put(stopSubmit(OFFER_UPDATE_FORM, errors));
    }
}

export function* watchSendInvite() {
    yield takeEvery(SEND_INVITE, sendInvite)
}

export function* watchUpdateInvite() {
    yield takeEvery(UPDATE_INVITE, updateInvite)
}

export function* watchChangeInvite() {
    yield takeEvery(CHANGE_INVITE, changeInvite)
}

export function* watchSendOffer() {
    yield takeEvery(SEND_OFFER, sendOffer)
}

function* reactionSaga() {
    yield all([
        fork(watchSendInvite),
        fork(watchUpdateInvite),
        fork(watchChangeInvite),
        fork(watchSendOffer),
    ]);
}

export default reactionSaga;