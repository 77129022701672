import {
    FETCH_OFFERS,
    FETCH_OFFERS_SUCCESS,
    FETCH_OFFERS_ERROR,
    CLEAN_OFFERS_PAGE,

    OFFERS_SET_SORT,

    OFFERS_SET_FILTER,
    OFFERS_RESET_FILTER,

    OFFERS_SET_PAGE,

    UPDATE_OFFER_ITEM,
} from './actionTypes';

// FETCH OFFERS
export const fetchOffers = (params) => {
    return {
        type: FETCH_OFFERS,
        payload: { params },
    }
};

export const fetchOffersSuccess = (data) => {
    return {
        type: FETCH_OFFERS_SUCCESS,
        payload: data,
    }
};

export const fetchOffersError = (data) => {
    return {
        type: FETCH_OFFERS_ERROR,
        payload: data,
    }
};

export const cleanOffersPage = () => {
    return { type: CLEAN_OFFERS_PAGE };
};


// OFFERS SET SORT
export const offersSetSort = (data) => {
    return {
        type: OFFERS_SET_SORT,
        payload: data,
    }
};

// OFFERS SET PAGE
export const offersSetPage = (data) => {
    return {
        type: OFFERS_SET_PAGE,
        payload: data,
    }
};

// OFFERS SET FILTER
export const offersSetFilter = (data) => {
    return {
        type: OFFERS_SET_FILTER,
        payload: data,
    }
};

// OFFERS RESET FILTER
export const offersResetFilter = (data) => {
    return { type: OFFERS_RESET_FILTER }
};


// UPDATE OFFER AT LIST
export const updateOfferItem = (id, data) => {
    return {
        type: UPDATE_OFFER_ITEM,
        payload: { id, data },
    }
};