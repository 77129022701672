import { combineReducers } from "redux";

import cancelReducer from './cancel/reducer';
import resumeReducer from './resume/reducer';
import swapReducer from './swap/reducer';

const reactionsReducer = combineReducers({
    cancel: cancelReducer,
    resume: resumeReducer,
    swap: swapReducer,
});

export default reactionsReducer;