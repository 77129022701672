import {
  CRM_SYSTEM_SYNC_START,
  CRM_SYSTEM_SYNC_START_SUCCESS,
  CRM_SYSTEM_SYNC_START_ERROR,
  CRM_SYSTEM_SYNC_START_CLEAN_STATE,
} from './actionTypes';

export const crmSystemSyncStart = ({id, crm}) => {
  return {
    type: CRM_SYSTEM_SYNC_START,
    payload: {id, crm},
  }
};

export const crmSystemSyncStartSuccess = (crm) => {
  return {
    type: CRM_SYSTEM_SYNC_START_SUCCESS,
    payload: {crm},
  }
};

export const crmSystemSyncStartError = (crm) => {
  return {
    type: CRM_SYSTEM_SYNC_START_ERROR,
    payload: {crm},
  }
};

export const crmSystemSyncStartCleanState = () => {
  return {type: CRM_SYSTEM_SYNC_START_CLEAN_STATE};
};