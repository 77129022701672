import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_CLIENT_PROPERTIES_STATISTICS } from './actionTypes';
import {
    fetchClientPropertiesStatisticsSuccess,
    fetchClientPropertiesStatisticsError
} from './actions';

import { API } from '../../../../api';

function* fetchClientPropertiesStatistics() {
    try {

        const response = yield call(API.property.getPropertiesStatistics);

        const { data } = response;
        yield put(fetchClientPropertiesStatisticsSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchClientPropertiesStatisticsError(message));
    }
}

export function* watchPropertiesStatisticsFetch() {
    yield takeEvery(FETCH_CLIENT_PROPERTIES_STATISTICS, fetchClientPropertiesStatistics)
}

function* statisticsSaga() {
    yield all([
        fork(watchPropertiesStatisticsFetch),
    ]);
}

export default statisticsSaga;