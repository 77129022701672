import axios from 'axios';
import qs from "qs";

class ReferralAPI {
    createReferral = async (params) => {
        return axios.post('api/referrals', params);
    };
}

export const referral = new ReferralAPI();
