import React, { useMemo } from 'react';
import { withRouter, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { connect } from "react-redux";

import { PropertyList, PropertyCreate, Property, PropertyEdit, InviteList, PropertyOffers } from './'
import { ROLE_CLIENT, ROLE_MANAGER } from '../../consts/roles';
import { useAllowed } from '../../hooks';
import AllowedRoute from '../../routes/middleware/AllowedRoute';
import { middlewareHelper } from '../../helpers';

const PropertyIndexComponent = (props) => {
    let { path } = useRouteMatch();

    let onlyUser = useAllowed([ROLE_CLIENT], props.user.role);
    let onlyManager = useAllowed([ROLE_MANAGER], props.user.role);

    const managePropertyAllowed = useMemo(() => {
      return middlewareHelper.propertyMiddleware.manageIsAllow(props.user)
    }, [props.user.role, props.user.can_manage_property])

    return (
        <Switch>
            <Route exact path={path}>
                {onlyUser ? <Redirect to={"/dashboard"}/> :
                    <AllowedRoute exact path={path} component={PropertyList} allowed={true}/>}
            </Route>
            <AllowedRoute path={`${path}/create`} component={PropertyCreate} allowed={managePropertyAllowed}/>
            <AllowedRoute path={`${path}/invites`} component={InviteList} allowed={onlyUser}/>
            <AllowedRoute path={`${path}/propositions`} component={PropertyOffers} allowed={onlyManager}/>

            <AllowedRoute path={`${path}/:id/edit`} component={PropertyEdit} allowed={managePropertyAllowed}/>
            <AllowedRoute path={`${path}/:id`} component={Property} allowed={true}/>

            <Route>
                <Redirect to={path}/>
            </Route>
        </Switch>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

export const PropertyIndex = withRouter(connect(mapStateToProps, {})(PropertyIndexComponent));