import React, { useEffect, useMemo } from 'react';
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";

import { useTitle } from "../../hooks";
import { BackButton, PageTitle } from '../../components/Custom';
import { fetchPropertyRequest, cleanPropertyRequest } from '../../store/actions';
import { PropertyRequestClose, PropertyRequestInformation, SuggestedPropertyOnRequest } from './components';
import { PROPERTY_REQUEST_STATUS_UNCONFIRMED, PROPERTY_REQUEST_STATUS_ACTIVE } from '../../consts/propertyRequest';

import * as HOC from '../../HOC';
const DataShower = HOC.withDataShower();

const PropertyRequestComponent = (props) => {
    useTitle(props.t('title.property_request'));
    let { id } = useParams();

    useEffect(() => {
        props.fetchPropertyRequest(id);

        return () => {
            props.cleanPropertyRequest();
        };
    }, []);

    const canClose = useMemo(() => {
        if (props.propertyRequest) {
            return [PROPERTY_REQUEST_STATUS_UNCONFIRMED, PROPERTY_REQUEST_STATUS_ACTIVE].includes(props.propertyRequest.status)
        }
        return false;
    }, [props.propertyRequest]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('title.property_request')} />

                    <DataShower
                        isLoading={props.propertyRequestLoading}
                        isFailed={props.propertyRequestError}
                        error={props.t(props.propertyRequestError)}
                        customLoaderClass={'mt-5'}
                    >
                        {props.propertyRequest && (
                            <>
                                <Row className={'mb-4'}>
                                    <Col>
                                        <Row className={'no-gutters justify-content-between'}>
                                            <BackButton />

                                            {canClose && (
                                                <PropertyRequestClose id={props.propertyRequest.id} />
                                            )}
                                        </Row>
                                    </Col>
                                </Row>

                                <PropertyRequestInformation />

                                <SuggestedPropertyOnRequest propertyRequestId={props.propertyRequest.id} />
                            </>
                        )}
                    </DataShower>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const {
        propertyRequest,
        propertyRequestLoading,
        propertyRequestError,
    } = state.propertyRequest.view;
    return {
        propertyRequest,
        propertyRequestLoading,
        propertyRequestError,
    };
};

const mapDispatchToProps = {
    fetchPropertyRequest,
    cleanPropertyRequest,
};

export const PropertyRequest = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyRequestComponent)));