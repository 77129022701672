import React, { useEffect } from 'react';
import { Col, FormGroup, Label } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, getFormValues, reduxForm } from 'redux-form'

import { WizardPager } from '../../WizardPager';
import RadioField from '../../../../../../components/Common/RadioField';
import { FORM_PROPERTY_REQUEST_HOME_WIZARD } from '../../../../../../consts/forms';
import {
    PROPERTY_GARDEN_SIZE_MIN,
    PROPERTY_GARDEN_SIZE_MAX,
} from "../../../../../../consts/property";
import { SLIDER_MODE_INPUT } from "../../../../../../consts/common";
import { SliderField } from "../../../../../../components/Custom";
import { propertyRequestHelper } from '../../../../../../helpers';

const range = {
    'min': [PROPERTY_GARDEN_SIZE_MIN], // 0
    '30%': [(PROPERTY_GARDEN_SIZE_MAX + 1) * 0.0001], // 100
    '50%': [(PROPERTY_GARDEN_SIZE_MAX + 1) * 0.001], // 1000
    '75%': [(PROPERTY_GARDEN_SIZE_MAX + 1) * 0.01], // 10 th
    '90%': [(PROPERTY_GARDEN_SIZE_MAX + 1) * 0.1], // 100 th
    'max': [PROPERTY_GARDEN_SIZE_MAX], // 999999
};

const HomeWizardStep4Component = (props) => {
    useEffect(() => {
        return () => {
        };
    }, []);

    const outsideFacing = propertyRequestHelper.outsideFacing.getOutsideFacingOptions(props.t);
    const floor = propertyRequestHelper.floor.getFloorOptions(props.t);

    return (
        <>
            <form
                onSubmit={props.handleSubmit(props.onSubmit)}
                className="form-horizontal"
                noValidate={true}
            >
                <FormGroup row className={'mb-4'}>
                    <Label className={'col-form-label col-lg-2'}>{props.t('field.label.property.min_size_garden')}</Label>

                    <Col lg="10">
                        <Field
                            id={'min-size-garden'}
                            name="min_size_garden"
                            component={SliderField}
                            limit={{
                                min: PROPERTY_GARDEN_SIZE_MIN,
                                max: PROPERTY_GARDEN_SIZE_MAX,
                            }}
                            mode={SLIDER_MODE_INPUT}
                            labelPrefix={'m²: '}
                            range={range}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row tag="fieldset">
                    <Label className={'col-form-label col-lg-2'}>{props.t('field.label.outside_facing')}</Label>

                    <Col lg="10">
                        <div className={'form-control custom-switch-filed-wrap border-0 p-0 flex-wrap'}>
                            {outsideFacing.map((item) => (
                                <div className={'pt-1 pb-1'} key={`property-outside-facing-${item.value}`}>
                                    <Field
                                        name="outside_facing"
                                        value={item.value}
                                        label={item.label}
                                        id={`property-outside-facing-${item.value}`}
                                        type="radio"
                                        component={RadioField}
                                    />
                                </div>
                            ))}
                        </div>
                    </Col>
                </FormGroup>

                <FormGroup row tag="fieldset">
                    <Label className={'col-form-label col-lg-2'}>{props.t('field.label.property.floor')}</Label>

                    <Col lg="10">
                        <div className={'form-control custom-switch-filed-wrap border-0 p-0 flex-wrap'}>
                            {floor.map((item) => (
                                <div className={'pt-1 pb-1'} key={`property-floor-${item.value}`}>
                                    <Field
                                        name="floor"
                                        value={item.value}
                                        label={item.label}
                                        id={`property-floor-${item.value}`}
                                        type="radio"
                                        component={RadioField}
                                    />
                                </div>
                            ))}
                        </div>
                    </Col>
                </FormGroup>

            </form>

            <WizardPager
                next={props.t('next')}
                prev={props.t('previous')}
                onNext={props.handleSubmit(props.onSubmit)}
                onPrev={props.back}
            />
        </>
    );
};

const HomeWizardForm = reduxForm({
    form: FORM_PROPERTY_REQUEST_HOME_WIZARD,
    enableReinitialize: true,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(HomeWizardStep4Component);

const mapStateToProps = state => {
    const formValues = getFormValues(FORM_PROPERTY_REQUEST_HOME_WIZARD)(state);
    return {
        initialValues: {
            ...formValues,
            min_size_garden: formValues.min_size_garden || [PROPERTY_GARDEN_SIZE_MIN],
        },
    };
};

const mapDispatchToProps = {
};

export const HomeWizardStep4 = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomeWizardForm)));