import { all } from 'redux-saga/effects';

import acceptedSaga from './accepted/saga';
import ownedSaga from './owned/saga';
import generalSaga from './general/saga';
import favoriteSaga from './favorite/saga';
import allSaga from './all/saga';
import statisticsSaga from './statistics/saga';

function* clientPropertiesSaga() {
    yield all([
        acceptedSaga(),
        ownedSaga(),
        generalSaga(),
        favoriteSaga(),
        allSaga(),
        statisticsSaga()
    ])
}

export default clientPropertiesSaga;