import React from 'react';
import { withRouter, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { connect } from "react-redux";

import { ROLE_MANAGER } from '../../consts/roles';
import { useAllowed } from '../../hooks';
import AllowedRoute from '../../routes/middleware/AllowedRoute';

const CreditIndexComponent = (props) => {
    let { path } = useRouteMatch();
    let isAllowed = useAllowed([ROLE_MANAGER], props.user.role);

    return (
        <Switch>
            <Route>
                <Redirect to={`${path}/buy`} />
            </Route>
        </Switch>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

export const CreditIndex = withRouter(connect(mapStateToProps, {})(CreditIndexComponent));