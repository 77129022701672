import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../i18n';

import { LEAD_PROPERTY_LIST_SUGGEST_PROPERTY } from './actionTypes';
import {
    leadPropertiesSuggestPropertySuccess,
    leadPropertiesSuggestPropertyError,
} from './actions';

import { leadAllPropertiesUpdateProperty } from '../list/actions';

import { API } from '../../../../api';

function* suggestProperty({ payload: { lead, property } }) {
    try {
        const response = yield call(API.invite.sendInvite, { lead, property });

        const { data } = response;

        yield put(leadPropertiesSuggestPropertySuccess(lead, property));
        toastr.success(i18n.t(`lead.suggest_property.success`));

        yield put(leadAllPropertiesUpdateProperty(property, { has_lead: true, has_link: true, }));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(leadPropertiesSuggestPropertyError(lead, property));

        toastr.error(message);
    }
}

export function* watchSuggestProperty() {
    yield takeEvery(LEAD_PROPERTY_LIST_SUGGEST_PROPERTY, suggestProperty)
}

function* suggestSaga() {
    yield all([
        fork(watchSuggestProperty),
    ]);
}

export default suggestSaga;