import React, { useState, useEffect, useMemo } from 'react';
import { Table, Badge } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { connect } from "react-redux";
import NumberFormat from 'react-number-format';
import { useTable, useSortBy } from 'react-table';

import { PropertyStatusLabel, OfferPriceValue, InviteStatusBadge } from '../../../components/Custom';
import { ROLE_MANAGER } from '../../../consts/roles';
import { STATUS_SOLD } from '../../../consts/property';
import { LeadPropertiesSuggestPropertyButton } from '../LeadPropertiesSuggestPropertyButton';

import placeholder from '../../../assets/images/custom/property-placeholder.jpg';

const PropertyListTableComponent = (props) => {
    const { keys, items } = props;

    const location = useLocation();

    const data = useMemo(() => {
        return items;
    }, [items]);

    const [columns, setColumns] = useState([]);

    const columnConfig = {
        'avatar': {
            Header: '',
            accessor: 'avatar',
            id: 'avatar',
            Cell: ({ value }) => (
                <div style={{ backgroundImage: `url("${value ? value : placeholder}")` }} className="property-cell-image" />
            ),
            headerClassName: 'property-table-image-column-header',
            className: 'property-image-cell',
            disableSortBy: true,
        },
        'vendor': {
            Header: props.t('vendor'),
            accessor: 'company',
            id: 'company',
            Cell: ({ row }) => (
                <div className={'text-dark font-weight-bold'}>{row.original.myReceivedLink?.sender?.company ? row.original.myReceivedLink.sender.company.name : '-'}</div>
            ),
            title: '',
        },
        'sender': {
            Header: props.t('name'),
            accessor: 'name',
            id: 'sender_name',
            Cell: ({ row: { original } }) => {
                const { myReceivedLink } = original;
                const sender = myReceivedLink ? myReceivedLink.sender : null;

                if (!sender) {
                    return '-';
                }

                return (
                    <Link to={`/contacts/${sender?.id}`} className="text-dark font-weight-bold text-decoration-underline">{sender?.name}</Link>
                )
            },
        },
        'project': {
            Header: props.t('project'),
            accessor: 'project',
            id: 'project',
            Cell: ({ row }) => (
                <div>{row.original.myReceivedLink ? '-' : row.original.project?.name}</div>
            ),
        },
        'region': {
            Header: props.t('province'),
            accessor: 'city.region.name',
            id: 'region',
        },
        'city': {
            Header: props.t('city'),
            accessor: 'city.name',
            id: 'city',
        },
        'address': {
            Header: props.t('address'),
            accessor: 'address',
            id: 'address',
            Cell: ({ row, value }) => {
                const { original } = row;
                return (<Link to={{ pathname: `/properties/${original.id}`, state: { from: location } }} className="text-dark font-weight-bold text-decoration-underline">{value || "-"}</Link>);
            },
        },
        'unit': {
            Header: props.t('unit'),
            accessor: 'unit',
            id: 'unit',
            Cell: ({ row, value }) => {
                return value ? value : '-';
            },
        },
        'price': {
            Header: () => (
                <div className={'w-100 text-center'}>{props.t('price')}</div>
            ),
            accessor: 'price',
            id: 'price',
            Cell: ({ row: { original }, value }) => {
                const { myReceivedLink } = original;
                return (
                    <div className={'text-center text-dark font-weight-bold text-nowrap'}>
                        <div>
                            {value !== null && value >= 0 ? (
                                <NumberFormat
                                    value={value}
                                    thousandSeparator={' '}
                                    prefix={'€ '}
                                    displayType={'text'}
                                />
                            ) : '-' }
                        </div>

                        {myReceivedLink && myReceivedLink?.price && (
                            <OfferPriceValue offerPrice={myReceivedLink?.price} price={value} />
                        )}
                    </div>
                );
            },
        },
        'status': {
            Header: props.t('status'),
            accessor: 'status',
            id: 'status',
            Cell: ({ value }) => (
                <PropertyStatusLabel status={value} />
            ),
        },
        'enabled': {
            Header: () => (
                <div className={'w-100 text-center'}>{props.t('show')}</div>
            ),
            accessor: 'enabled',
            id: 'enabled',
            Cell: ({ value }) => (
                <div className={'text-center'}>
                    <Badge
                        className={'font-size-12 badge-soft-' + (value ? 'primary' : 'secondary')}
                        pill
                    >
                        {value ? props.t('yes') : props.t('no')}
                    </Badge>
                </div>
            ),
        },
        'favorites_count': {
            Header: () => (
                <div className={'w-100 text-center'}>{props.t('favorites')}</div>
            ),
            accessor: 'favorites_count',
            id: 'users_favorites_count',
            Cell: ({ row: { original } }) => {
                const count = props.role === ROLE_MANAGER ? original.total_favorites_count : original.linked_favorites_count;
                return (
                    <div className={'text-center'}>
                        <Badge className={'font-size-12 badge-soft-primary'} pill>
                            {count}
                        </Badge>
                    </div>
                );
            },
        },
        'favorite': {
            Header: '',
            accessor: 'is_favorite',
            id: 'is_favorite',
            Cell: ({ value }) => {
                if (value) {
                    return (
                        <div className={'text-center'}>
                            <i className={`bx font-size-20 align-middle` + (value ? ' bxs-star text-primary' : ' bx-star text-muted')} />
                        </div>
                    );
                }
                return null;
            },
            disableSortBy: true,
        },
        'invites': {
            Header: () => (
                <div className={'w-100 text-center'}>{props.t('invites')}</div>
            ),
            accessor: 'links_count',
            id: 'links_count',
            Cell: ({ value }) => {
                return (
                    <div className={'text-center'}>
                        <Badge className={'font-size-12 badge-soft-primary'} pill>
                            {value}
                        </Badge>
                    </div>
                );
            },
        },
        'invite_status': {
            Header: () => (
                <div className={'w-100 text-center'}>{props.t('status')}</div>
            ),
            accessor: 'status',
            id: 'link_status',
            Cell: ({ row: { original } }) => {
                const { myReceivedLink } = original;
                const status = myReceivedLink ? myReceivedLink.status : null;

                if (!status) {
                    return '-';
                }

                return (
                    <div className={'text-center'}>
                        <InviteStatusBadge status={status} />
                    </div>
                );
            },
        },
        'suggest': {
            Header: '',
            accessor: 'suggest',
            id: 'suggest',
            Cell: ({ row: { original } }) => {
                const { id, status, has_link, has_lead } = original;
                return (
                    <div className={'text-center'}>
                        {has_lead ? (
                            <p className={'mb-0 text-success'}>{props.t('suggested')}</p>
                        ) : !has_link && status !== STATUS_SOLD ? (
                            <LeadPropertiesSuggestPropertyButton propertyId={id} />
                        ) : '-'}
                    </div>
                );
            },
            disableSortBy: true,
        },
    };

    useEffect(() => {
        let config = [];

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (columnConfig[key]) {
                config.push(columnConfig[key]);
            }
        }

        setColumns(config)
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: props.sort,
            },
            defaultCanSort: false,
            disableSortBy: data.length === 0,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
        },
        useSortBy,
    );

    useEffect(() => {
        props.onSort(sortBy);
    }, [props.onSort, sortBy]);

    return (
        <div className="table-responsive table-centered">
            <Table className={'table-bordered table-part-bordered'} {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps([
                                        {
                                            className: column.headerClassName,
                                        },
                                        column.getSortByToggleProps({ title: null })
                                    ])}
                                >
                                    <div className={'d-flex'}>
                                        <div className={'d-flex flex-fill'}>
                                            {column.render('Header')}
                                        </div>

                                        <div>
                                            {column.canSort && (
                                                column.isSorted ? (
                                                    <i className={'bx font-size-16 align-middle' + (column.isSortedDesc ? " bx-sort-down" : " bx-sort-up")} />
                                                ) : <i className="bx bx-sort align-middle" style={{ color: '#adb5bd' }} />
                                            )}
                                        </div>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {(
                        rows.length > 0 && rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps([
                                                    {
                                                        className: cell.column.className,
                                                    },
                                                ])}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })
                    ) || (
                        <tr>
                            <td colSpan={columns.length}>
                                {!props.isLoading ? props.t('empty') : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}} />}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        role: state.user.data.role
    };
};

const mapDispatchToProps = {
};

export const PropertyListTable = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyListTableComponent));