import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import i18n from '../../i18n';
import { FETCH_USER } from './actionTypes';
import { fetchUserSuccess, fetchUserError } from './actions';
import { setLanguage } from '../common/actions';
import { setOnboardingStatus } from '../auth/login/actions';

import { API } from '../../api';

import { isUserCompleteRegister } from '../../utils/user';

export const isOnboarding = (state) => state.Login.isOnboarding;

function* fetchUser({ payload: { history } }) {
    try {
        const includes = ['company', 'socialAccounts'];

        const response = yield call(API.user.getMe, { includes });

        const { data } = response;

        const { lang } = data.data;

        if (i18n.language !== lang) {
            i18n.changeLanguage(lang);

            yield put(setLanguage(lang));
            API.setLang(lang);
        }

        const onboardingStatus = yield select(isOnboarding);

        if (!isUserCompleteRegister(data?.data)) {
            if (!onboardingStatus) {
                // if user not complete register and he not on onboarding -> redirect to onboarding
                yield put(setOnboardingStatus(true));
            }
        } else {
            if (onboardingStatus) {
                // if user complete register and he now on onboarding -> redirect to dashboard
                yield put(setOnboardingStatus(false));
            }
        }

        yield put(fetchUserSuccess(data?.data));

    } catch (error) {
        yield put(fetchUserError('Server Error'));
    }
}

export function* watchUserFetch() {
    yield takeEvery(FETCH_USER, fetchUser)
}

function* userSaga() {
    yield all([
        fork(watchUserFetch),
    ]);
}

export default userSaga;