import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Button as StrapButton } from "reactstrap"

export const CustomButton = ({
  type,
  loading,
  disabled,
  title,
  leftIcon,
  icon,
  size,
  onClick,
  color = "primary",
  submit = false,
  btnClass,
  outline = false,
  renderTitle,
    asBlock = false,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <StrapButton
      type={type ? type : submit ? "submit" : "button"}
      className={classNames({
        btn: true,
        "btn-block": asBlock,
        [`btn-${color}`]: color && !outline,
        [size]: size,
        [btnClass]: btnClass,
      })}
      disabled={disabled}
      onClick={onClick}
      outline={outline}
      color={color && outline ? color : null}
      {...rest}
    >
      {loading && (
        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
      )}

      {leftIcon && !loading && (
        <i
          className={classNames("align-middle mr-2", {
            [leftIcon]: leftIcon,
          })}
        />
      )}

      {renderTitle ? renderTitle : t(title)}

      {icon && <i className={icon} />}
    </StrapButton>
  )
}

CustomButton.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  leftIcon: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  submit: PropTypes.bool,
  btnClass: PropTypes.string,
  outline: PropTypes.bool,
  renderTitle: PropTypes.any,
  asBlock: PropTypes.bool
}
