import React from 'react';
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Row, Col } from "reactstrap";

const HeaderCreditsAmountComponent = (props) => {
    const location = useLocation();

    const handleClick = (e) => {
        if (location.pathname === `/billing/credits`) {
            e.preventDefault();
        }
    };

    return (
        <Row className={'align-items-center mr-4 ml-4'}>
            <Col>
                <Row className={'no-gutters align-items-center'}>
                    <p className={'mb-0 mr-4 font-size-14'}>
                        {` ${props.t('credits')}: `}
                        <span className={'font-weight-bold'}>{props.user?.credits}</span>
                    </p>

                    <Link
                        to={`/billing/credits`}
                        color={'success'}
                        className="btn btn-success waves-effect waves-light"
                        onClick={handleClick}
                    >
                        {props.t('buy')}
                    </Link>
                </Row>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user.data,
    };
};

const mapDispatchToProps = {
};

export const HeaderCreditsAmount = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HeaderCreditsAmountComponent));
