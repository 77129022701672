import {
    RESUME_BILLING_SUBSCRIPTION,
    RESUME_BILLING_SUBSCRIPTION_SUCCESS,
    RESUME_BILLING_SUBSCRIPTION_ERROR,
    CLEAN_RESUME_BILLING_SUBSCRIPTION,
} from './actionTypes';

const initialState = {
    resumeLoading: false,
    resumeSuccess: null,
    resumeError: null,
};

const resume = (state = initialState, action) => {
    switch (action.type) {
        case RESUME_BILLING_SUBSCRIPTION:
            state = {
                ...state,
                resumeLoading: true,
                resumeSuccess: null,
                resumeError: null,
            };
            break;

        case RESUME_BILLING_SUBSCRIPTION_SUCCESS:
            state = {
                ...state,
                resumeLoading: false,
                resumeSuccess: action.payload,
            };
            break;

        case RESUME_BILLING_SUBSCRIPTION_ERROR:
            state = {
                ...state,
                resumeLoading: false,
                resumeError: action.payload,
            };
            break;

        case CLEAN_RESUME_BILLING_SUBSCRIPTION:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default resume;