import {
  CRM_SYSTEM_LIST_GET_DATA,
  CRM_SYSTEM_LIST_GET_DATA_SUCCESS,
  CRM_SYSTEM_LIST_GET_DATA_ERROR,
  CRM_SYSTEM_LIST_CLEAN_STATE,
  CRM_SYSTEM_LIST_UPDATE_ITEM,
} from './actionTypes';

export const crmSystemListGetData = (params) => {
  return {
    type: CRM_SYSTEM_LIST_GET_DATA,
    payload: {params}
  }
};

export const crmSystemListGetDataSuccess = (data) => {
  return {
    type: CRM_SYSTEM_LIST_GET_DATA_SUCCESS,
    payload: data,
  }
};

export const crmSystemListGetDataError = (data) => {
  return {
    type: CRM_SYSTEM_LIST_GET_DATA_ERROR,
    payload: data,
  }
};

export const crmSystemListUpdateItem = (id, data) => {
  return {
    type: CRM_SYSTEM_LIST_UPDATE_ITEM,
    payload: { id, data },
  }
};

export const crmSystemListCleanState = () => {
  return {type: CRM_SYSTEM_LIST_CLEAN_STATE};
};