import React from 'react';
import { Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";

const PropertyRequestTabsComponent = (props) => {
    const changeTab = (e, value) => {
        e.preventDefault();

        if (props.disabled) {
            return;
        }

        props.onChange(value)
    };

    return (
        <Row>
            <Col>
                <Row className="no-gutters">
                    <h5 className={'mr-2'}>{props.t('request.select_search_type')}</h5>
                </Row>

                <Row className="justify-content-center">
                    <Col lg={6} xl={6}>
                        <div className={'d-flex justify-content-center'}>
                            <Link
                                to={'#'}
                                onClick={e => changeTab(e, 1)}
                                className={
                                    classNames(
                                        'wizard-nav-link',
                                        {
                                            'disabled': props.disabled,
                                        }
                                    )
                                }
                            >
                                <div className={'d-flex wizard-nav-item'}>
                                    <i
                                        className={
                                            classNames(
                                                'bx bx-home d-block text-primary mt-4 mb-2',
                                                {
                                                    'text-secondary': props.disabled,
                                                }
                                            )
                                        }
                                    />
                                    <p className="font-weight-bold mb-4">{props.t('new_home')}</p>
                                </div>
                            </Link>
                        </div>
                    </Col>

                    <Col lg={6} xl={6}>
                        <div className={'d-flex justify-content-center'}>
                            <Link
                                to={'#'}
                                onClick={e => changeTab(e, 2)}
                                className={
                                    classNames(
                                        'wizard-nav-link',
                                        {
                                            'disabled': props.disabled,
                                        }
                                    )
                                }
                            >
                                <div className={'d-flex wizard-nav-item'}>
                                    <i
                                        className={
                                            classNames(
                                                'bx bx-trending-up d-block text-primary mt-4 mb-2',
                                                {
                                                    'text-secondary': props.disabled,
                                                }
                                            )
                                        }
                                    />
                                    <p className="font-weight-bold mb-4">{props.t('investment')}</p>
                                </div>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const PropertyRequestTabs = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyRequestTabsComponent)));