import React, { useEffect, useMemo } from 'react';
import { Row, Card, CardHeader, CardBody, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { FavoritePropertyCarousel } from './FavoritePropertyCarousel';
import {
    fetchFavoriteProperties,
    cleanFavoriteProperties,
} from '../../../../../store/actions';

import * as HOC from '../../../../../HOC';
const DataShower = HOC.withDataShower();

const FavoritePropertiesComponent = (props) => {
    useEffect(() => {
        let params = {
            page: 1,
            per_page: 5,
        };

        props.fetchFavoriteProperties(params);

        return () => {
            props.cleanFavoriteProperties();
        };
    }, []);

    const emptyAlert = useMemo(() => {
        return !props.listLoading && props.list.length === 0;
    }, [props.listLoading, props.list]);

    return (
        <Card>
            <CardHeader className="bg-transparent">
                <Row className="no-gutters justify-content-between">
                    <h5 className={'mt-2 mr-2'}>{props.t('favorite_properties.amount', { amount: 5 })}</h5>

                    {/*<Link to={`/properties`} className="align-self-center text-secondary">*/}
                    {/*    {props.t('show_all')}*/}
                    {/*</Link>*/}
                </Row>
            </CardHeader>
            <CardBody>
                <DataShower
                    isLoading={props.listLoading}
                    isFailed={props.listError}
                    error={props.t(props.listError)}
                    customLoaderClass={'data-shower-loader-center'}
                >

                    {emptyAlert ? (
                        <Alert color="primary">{props.t('favorite_properties.empty')}</Alert>
                    ) : (
                        <div className={'d-flex dashboard-favorite-properties-list'}>
                            <FavoritePropertyCarousel
                                items={props.list}
                                onSwiper={() => {}}
                                onChange={() => {}}
                            />
                        </div>
                    )}

                </DataShower>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    const { list, listLoading, listError } = state.property.favorite.list;

    return {
        user: data,
        list,
        listLoading,
        listError,
    };
};

const mapDispatchToProps = {
    fetchFavoriteProperties,
    cleanFavoriteProperties,
};

export const FavoriteProperties = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FavoritePropertiesComponent)));