import _ from 'lodash';

import {
    PROPERTY_PARKING_OUTSIDE,
    PROPERTY_PARKING_INSIDE,
    PROPERTY_PARKING_CARPORT,
} from '../../consts/property';

export const getParkingOptions = (t) => {
    return [{
        label: t('property.parking.outside'),
        value: PROPERTY_PARKING_OUTSIDE,
    }, {
        label: t('property.parking.inside'),
        value: PROPERTY_PARKING_INSIDE,
    }, {
        label: t('property.parking.carport'),
        value: PROPERTY_PARKING_CARPORT,
    }];
};

export const getParking = (value, t) => {
    const options = getParkingOptions(t);
    const item = _.find(options, { value: value });

    return item || {
        label: '',
        value: '',
    };
};

export default {
    getParkingOptions,
    getParking,
};