import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SwiperCarousel, SwiperThumbs } from './SwiperCarousel';
import { ImageLightbox } from './ImageLightbox';

import './ImageGallery.scss';

export const ImageGallery = ({ images }) => {
    const { t } = useTranslation();

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [lightboxOpen, setLightBoxOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleCloseLightbox = () => {
        setLightBoxOpen(false);
    };

    const handleImageItemClick = (e, id) => {
        e.preventDefault();

        const index = images.findIndex(item => item.id === id);
        setCurrentIndex(index);
        setLightBoxOpen(true);
    };

    return (
        <>
            <div className={'property-gallery__block'}>
                {!!images.length ? (
                    <>
                        <div className={'property-gallery__carousel'}>
                            <SwiperCarousel
                                items={images}
                                onSwiper={() => {}}
                                onChange={() => {}}
                                onItemClick={handleImageItemClick}
                                thumbs={{ swiper: thumbsSwiper }}
                            />
                        </div>

                        <div className={'property-gallery__thumbs'}>
                            <SwiperThumbs
                                items={images}
                                onSwiper={setThumbsSwiper}
                                onChange={() => {}}
                            />
                        </div>

                        <ImageLightbox items={images.map(item => item.src)} isOpen={lightboxOpen} currentIndex={currentIndex} onClose={handleCloseLightbox}/>
                    </>
                ): (
                    <div className={'property-gallery__no-image'}>
                        <div className={'d-flex align-self-center'}>
                            {t('no_images')}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};