import {
    FETCH_CLIENT_ALL_PROPERTIES,
    FETCH_CLIENT_ALL_PROPERTIES_SUCCESS,
    FETCH_CLIENT_ALL_PROPERTIES_ERROR,
    CLEAN_CLIENT_ALL_PROPERTIES,

    CLIENT_ALL_PROPERTIES_SET_FILTER,
    CLIENT_ALL_PROPERTIES_RESET_FILTER,

    CLIENT_ALL_PROPERTIES_SET_SORT,
    CLIENT_ALL_PROPERTIES_RESET_SORT,

    CLIENT_ALL_PROPERTIES_SET_PAGE,

    CLIENT_ALL_PROPERTIES_UPDATE_ITEM, CLIENT_ALL_PROPERTIES_SET_SEARCH,
} from './actionTypes';

export const fetchClientAllProperties = (params) => {
    return {
        type: FETCH_CLIENT_ALL_PROPERTIES,
        payload: { params }
    }
};

export const fetchClientAllPropertiesSuccess = (data) => {
    return {
        type: FETCH_CLIENT_ALL_PROPERTIES_SUCCESS,
        payload: data,
    }
};

export const fetchClientAllPropertiesError = (data) => {
    return {
        type: FETCH_CLIENT_ALL_PROPERTIES_ERROR,
        payload: data,
    }
};

export const cleanClientAllProperties = () => {
    return { type: CLEAN_CLIENT_ALL_PROPERTIES };
};


// FILTER
export const clientAllPropertiesSetFilter = (data) => {
    return {
        type: CLIENT_ALL_PROPERTIES_SET_FILTER,
        payload: data,
    }
};

export const clientAllPropertiesResetFilter = () => {
    return { type: CLIENT_ALL_PROPERTIES_RESET_FILTER };
};

// SORTING
export const clientAllPropertiesSetSort = (data) => {
    return {
        type: CLIENT_ALL_PROPERTIES_SET_SORT,
        payload: data,
    }
};

export const clientAllPropertiesResetSort = () => {
    return { type: CLIENT_ALL_PROPERTIES_RESET_SORT };
};


// PAGE
export const clientAllPropertiesSetPage = (data) => {
    return {
        type: CLIENT_ALL_PROPERTIES_SET_PAGE,
        payload: data,
    }
};

// UPDATE ITEM IN LIST
export const clientAllPropertiesUpdateItem = (id, data) => {
    return {
        type: CLIENT_ALL_PROPERTIES_UPDATE_ITEM,
        payload: { id, data },
    }
};


export const clientAllPropertiesSetSearch = (data) => {
    return {
        type: CLIENT_ALL_PROPERTIES_SET_SEARCH,
        payload: data,
    }
};