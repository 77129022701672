import React from 'react';
import { Spinner } from "reactstrap";

import './LoadingOverlay.scss';

export const LoadingOverlay = (props) => {
    return (
        <div className={'loading-overlay__wrap'}>
            {props.children}

            {props.isLoading && (
                <div className={'loading-overlay__loader'}>
                    <Spinner size={'lg'} color="primary" />
                </div>
            )}
        </div>
    );
};