import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_USER_INFO_AND_DATA } from './actionTypes';
import { fetchUserInfoAndDataSuccess, fetchUserInfoAndDataError } from './actions';

import { API } from '../../../api';

function* fetchUserInfoAndData() {
    try {
        const includes = ['company', 'socialAccounts', 'topProperties', 'topProperties.city', 'topLinkedProperties', 'topLinkedProperties.city'];

        const response = yield call(API.user.getMe, { includes });

        const { data } = response;
        yield put(fetchUserInfoAndDataSuccess(data?.data));

    } catch (error) {
        yield put(fetchUserInfoAndDataError('Server Error'));
    }
}

export function* watchUserInfoAndDataFetch() {
    yield takeEvery(FETCH_USER_INFO_AND_DATA, fetchUserInfoAndData)
}

function* userDataSaga() {
    yield all([
        fork(watchUserInfoAndDataFetch),
    ]);
}

export default userDataSaga;