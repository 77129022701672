export const FETCH_CONSULTANT_LIST = 'FETCH_CONSULTANT_LIST';
export const FETCH_CONSULTANT_LIST_SUCCESS = 'FETCH_CONSULTANT_LIST_SUCCESS';
export const FETCH_CONSULTANT_LIST_ERROR = 'FETCH_CONSULTANT_LIST_ERROR';
export const CLEAN_CONSULTANT_LIST_PAGE = 'CLEAN_CONSULTANT_LIST_PAGE';

export const CREATE_CONSULTANT = 'CREATE_CONSULTANT';
export const CREATE_CONSULTANT_SUCCESS = 'CREATE_CONSULTANT_SUCCESS';
export const CREATE_CONSULTANT_ERROR = 'CREATE_CONSULTANT_ERROR';
export const CLEAN_CREATE_CONSULTANT_PAGE = 'CLEAN_CREATE_CONSULTANT_PAGE';

export const FETCH_CONSULTANT = 'FETCH_CONSULTANT';
export const FETCH_CONSULTANT_SUCCESS = 'FETCH_CONSULTANT_SUCCESS';
export const FETCH_CONSULTANT_ERROR = 'FETCH_CONSULTANT_ERROR';

export const EDIT_CONSULTANT = 'EDIT_CONSULTANT';
export const EDIT_CONSULTANT_SUCCESS = 'EDIT_CONSULTANT_SUCCESS';
export const EDIT_CONSULTANT_ERROR = 'EDIT_CONSULTANT_ERROR';
export const CLEAN_EDIT_CONSULTANT_PAGE = 'CLEAN_EDIT_CONSULTANT_PAGE';

export const REMOVE_CONSULTANT = 'REMOVE_CONSULTANT';
export const REMOVE_CONSULTANT_SUCCESS = 'REMOVE_CONSULTANT_SUCCESS';
export const REMOVE_CONSULTANT_ERROR = 'REMOVE_CONSULTANT_ERROR';

export const CLEAN_CONSULTANT_PAGE = 'CLEAN_CONSULTANT_PAGE';

export const CONSULTANT_SET_SORT = 'CONSULTANT_SET_SORT';

export const CONSULTANT_SET_FILTER = 'CONSULTANT_SET_FILTER';
export const CONSULTANT_RESET_FILTER = 'CONSULTANT_RESET_FILTER';

export const CONSULTANT_SET_PAGE = 'CONSULTANT_SET_PAGE';