import axios from 'axios';
import qs from 'qs';

const paramsSerializer = (params) => {
    return qs.stringify(params, { encode: false });
};

class StatisticsAPI {
    getConsultantDashboardStatistics = async (params = {}) => {
        return axios.get('api/dashboard/consultant', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    getClientDashboardStatistics = async (params = {}) => {
        return axios.get('api/dashboard/client', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };
}

export const statistics = new StatisticsAPI();
