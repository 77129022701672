import React, { useEffect, useRef } from 'react';
import { Card, CardBody, Row } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import { ChatList } from '../ChatList';
import { cleanChats, fetchChats } from '../../../../../store/actions';

import * as HOC from '../../../../../HOC';

const DataShower = HOC.withDataShower();

const LatestMessagesComponent = (props) => {

    useEffect(() => {
        fetchData();

        return () => {
            props.cleanChats();
        }
    }, []);

    const fetchData = () => {
        props.fetchChats();
    };

    const scrollBarRef = useRef(null);

    return (
        <>
            <Row className="no-gutters justify-content-between mb-4 align-items-center" style={{ height: "24px" }}>
                <h5 className={'mb-0'}>{props.t('latest_messages')}</h5>

                <Link to={`/chat`} className="align-self-center text-secondary">
                    {props.t('go_to_chat')}
                </Link>
            </Row>
            <Card>
                <CardBody style={{ padding: "0 0.5rem" }}>
                    <PerfectScrollbar
                        style={{ height: props.list.length > 0 ? "336px" : 'auto' }}
                        ref={scrollBarRef}
                        options={{ wheelPropagation: false }}
                    >
                        <DataShower
                            isLoading={props.loading}
                            isFailed={props.error}
                            error={props.t(props.error)}
                            customLoaderClass={'data-shower-loader-center'}
                        >
                            <ChatList items={props.list}/>
                        </DataShower>
                    </PerfectScrollbar>
                </CardBody>
            </Card>
        </>
    );
};

const mapStateToProps = state => {
    const { list, loading, error, } = state.chat.chats;

    return {
        list, loading, error,
    };
};

const mapDispatchToProps = {
    fetchChats,
    cleanChats,
};

export const LatestMessages = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LatestMessagesComponent)));