import React from 'react';
import s from "./Tab.module.scss"
import { CountBadge } from "components/Custom";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

export const Tab = ({ isActive, onClick, tab, count }) => {
    const { t } = useTranslation();

    const handleClick = () => {
        onClick(tab.value)
    }
    return (
        <li className={s.tab} onClick={handleClick}>
            <div className={classnames(`${s.base_link}`, {
                [s.active]: isActive,
            })}>
                {t(tab.label)}
            </div>
            <div className={s.badge}>
                {(count || count === 0) && <CountBadge count={count} active={isActive}/>}
            </div>
        </li>
    );
};

Tab.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    tab: PropTypes.object,
    count: PropTypes.number
};



