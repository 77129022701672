import {
    FETCH_CLIENT_DASHBOARD_STATISTICS,
    FETCH_CLIENT_DASHBOARD_STATISTICS_SUCCESS,
    FETCH_CLIENT_DASHBOARD_STATISTICS_ERROR,
    CLEAN_CLIENT_DASHBOARD_STATISTICS,
} from './actionTypes';

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const client = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CLIENT_DASHBOARD_STATISTICS:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case FETCH_CLIENT_DASHBOARD_STATISTICS_SUCCESS:
            state = {
                ...state,
                loading: false,
                data: action.payload,
            };
            break;

        case FETCH_CLIENT_DASHBOARD_STATISTICS_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case CLEAN_CLIENT_DASHBOARD_STATISTICS:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default client;