import {
    FETCH_CLIENT,
    FETCH_CLIENT_SUCCESS,
    FETCH_CLIENT_ERROR,
    CLEAN_CLIENT,
} from './actionTypes';

export const fetchClient = (id) => {
    return {
        type: FETCH_CLIENT,
        payload: { id },
    }
};

export const fetchClientSuccess = (data) => {
    return {
        type: FETCH_CLIENT_SUCCESS,
        payload: data,
    }
};

export const fetchClientError = (data) => {
    return {
        type: FETCH_CLIENT_ERROR,
        payload: data,
    }
};

export const cleanClient = () => {
    return { type: CLEAN_CLIENT };
};