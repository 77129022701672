import React from 'react';
import { Field } from "redux-form";
import { FormFeedback, FormGroup, Label, Col, Row } from "reactstrap";
import { useTranslation } from 'react-i18next';

import { propertyRequestHelper } from "../../../helpers";
import CheckboxGroupField from './CheckboxGroupField';

export const PropertyTypesCheckboxField = (props) => {
    const { checkboxClassName = '' } = props;

    const { t } = useTranslation();

    const groups = propertyRequestHelper.propertyType.getPropertyTypeGroups(t);

    const field = ({ input, meta }) => {
        const { touched, error } = meta;
        const showError = touched && Boolean(error);

        return (
            <FormGroup row tag="fieldset" className={'mb-4' + (showError ? ' text-danger' : '')}>
                <Label className={'col-form-label col-lg-2'}>{props.label}</Label>

                <Col lg="10">
                    <div className={(showError ? 'is-invalid' : '')}>
                        <Row className={''}>

                            {groups.map((item, index) => (
                                <Col lg="4" key={'groups-' + index}>
                                    <CheckboxGroupField
                                        name="property_types"
                                        checkboxClassName={'custom-control-inline'}
                                        {...props}
                                        options={item.options}
                                        label={item.label}
                                    />
                                </Col>
                            ))}

                        </Row>
                    </div>
                    <FormFeedback invalid={showError ? error : null }>
                        { t(error, props.translateParams ? { ...props.translateParams } : {}) }
                    </FormFeedback>
                </Col>
            </FormGroup>
        );
    };

    return (
        <Field {...props} type="checkbox" component={field} />
    );
};