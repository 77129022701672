import React from 'react';
import { Table } from "reactstrap";
import { Icon } from "components/Custom";

import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";
import variables from "assets/scss/custom-variables.module.scss"
export const PropertyDetailsTable = ({ item }) => {
    const { t } = useTranslation();
    const isSmallScreen = useMedia(`(max-width: ${variables.sm})`);

    const mainData = [
        {
            icon: <Icon name={'map-pointer'} color={'primary'}/>,
            value: item.address,
            hidden: !item.address,
            colspan: 2
        },
        {
            icon: <Icon name={'size'} color={'primary'}/>,
            label: t('property_size'),
            value: item.size + ' m²',
            hidden: !item.size
        },
        {
            icon: <Icon name={'bed'} color={'primary'}/>,
            label: t('bedrooms'),
            value: item.bedrooms,
            hidden: !item.bedrooms
        },
        {
            icon: <Icon name={'bathrooms'} color={'primary'}/>,
            label: t('bathrooms'),
            value: item.bathrooms,
            hidden: !item.bathrooms
        },
        {
            icon: <Icon name={'work-instruments'} color={'primary'}/>,
            label: t('property_year'),
            value: item.year,
            hidden: !item.year
        }
    ]

    return (
        <Table className="table-row-separator mb-0">
            <tbody>
            {mainData.map((field, index) => {
                if (field.hidden) return null

                return <tr className={"wrap-class"} key={`property${item.id}-details-${index}`}>
                    <th scope="row">
                        <span className={''}>{field.icon}</span>
                    </th>
                    {field.label && !isSmallScreen &&
                    <th className={"text-nowrap"}>
                        {field.label}
                    </th>}
                    <td colSpan={field.colspan ? field.colspan : 1}>{field.value}</td>
                </tr>
            })}
            </tbody>
        </Table>
    );
};

PropertyDetailsTable.propTypes = {};


