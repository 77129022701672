import {
    COMPLETE_REGISTER_USER,
    COMPLETE_REGISTER_USER_SUCCESS,
    COMPLETE_REGISTER_USER_ERROR,
    COMPLETE_REGISTER_CLEAN,
} from './actionTypes';

const initialState = {
    completeRegisterLoading: false,
    completeRegisterSuccess: null,
    completeRegisterError: null,
};

const completeRegister = (state = initialState, action) => {
    switch (action.type) {

        // complete register user
        case COMPLETE_REGISTER_USER:
            state = {
                ...state,
                completeRegisterLoading: true,
                completeRegisterSuccess: null,
                completeRegisterError: null,
            };
            break;

        case COMPLETE_REGISTER_USER_SUCCESS:
            state = {
                ...state,
                completeRegisterLoading: false,
                completeRegisterSuccess: true,
            };
            break;

        case COMPLETE_REGISTER_USER_ERROR:
            state = {
                ...state,
                completeRegisterLoading: false,
                completeRegisterError: action.payload,
            };
            break;

        case COMPLETE_REGISTER_CLEAN:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default completeRegister;