import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Table, Alert, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useParams, useRouteMatch, useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from 'moment';

import { useTitle } from "../../hooks";
import { PhoneFormat, PageTitle } from '../../components/Custom';
import { fetchConsultant, cleanConsultantPage, removeConsultant } from '../../store/actions';

import profile1 from "../../assets/images/profile-img.png"
import placeholder from '../../assets/images/custom/avatar-placeholder.png';

import * as HOC from '../../HOC';

const DataShower = HOC.withDataShower();

const ConsultantComponent = (props) => {
    useTitle(props.t('title.consultant'));
    let { id } = useParams();
    let { url } = useRouteMatch();
    const history = useHistory();

    const [confirmModal, setConfirmModal] = useState(false);

    useEffect(() => {
        props.fetchConsultant(id);

        return () => {
            props.cleanConsultantPage();
        };
    }, []);

    const onRemove = (e) => {
        e.preventDefault();

        setConfirmModal(true);
    };

    const handleConfirm = () => {
        setConfirmModal(false);
        props.removeConsultant(id, history);
    };

    const onEdit = (e) => {
        e.preventDefault();

        history.push(`${url}/edit`);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('details')} />

                    <DataShower
                        isLoading={props.consultantLoading}
                        isFailed={props.consultantError}
                        error={props.t(props.consultantError)}
                        customLoaderClass={'mt-5'}
                    >
                        {props.consultant && (
                            <Row>
                                <Col xl="4">
                                    <Card className="overflow-hidden">
                                        <div className="bg-soft-primary">
                                            <Row>
                                                <Col xs="7">
                                                    <div className="text-primary p-3">
                                                        <h5 className="text-primary">{props.t('details')}</h5>
                                                    </div>
                                                </Col>
                                                <Col xs="5" className="align-self-end">
                                                    <img src={profile1} alt="" className="img-fluid" />
                                                </Col>
                                            </Row>
                                        </div>
                                        <CardBody className="pt-0">
                                            <Col sm="12">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <img src={props.consultant?.avatar ? props.consultant.avatar : placeholder} alt="" className="rounded-circle img-thumbnail avatar-md avatar-cover" />
                                                </div>
                                                <h5 className="font-size-15 text-truncate">{props.consultant.name}</h5>
                                            </Col>

                                            <Col sm={12}>
                                                <div className="pt-4">
                                                    <div className="mt-4">
                                                        <Row className={'no-gutters justify-content-between'}>
                                                            <Button
                                                                onClick={onEdit}
                                                                color={'primary'}
                                                                className="waves-effect waves-light btn-sm"
                                                                disabled={props.removeLoading}
                                                            >
                                                                {props.t('edit')} <i className="mdi mdi-arrow-right ml-1"></i>
                                                            </Button>

                                                            <Button
                                                                onClick={onRemove}
                                                                color={'danger'}
                                                                className="waves-effect waves-light btn-sm"
                                                                disabled={props.removeLoading}
                                                            >
                                                                {props.t('remove')}
                                                            </Button>
                                                        </Row>
                                                    </div>
                                                </div>
                                                {props.removeError && props.removeError ? <Alert color="danger" className={'no-gutters mt-4'}>{props.removeError}</Alert> : null}
                                            </Col>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">{props.t('personal_information')}</CardTitle>

                                            <div className="table-responsive">
                                                <Table className="table-nowrap mb-0">
                                                    <tbody>
                                                    <tr>
                                                        <th scope="row">{props.t('email')} :</th>
                                                        <td>{props.consultant.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{props.t('full_name')} :</th>
                                                        <td>{props.consultant.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{props.t('phone')} :</th>
                                                        <td>
                                                            <PhoneFormat value={props.consultant.phone} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{props.t('date.added')} :</th>
                                                        <td>{moment(props.consultant.created_at).format('DD/MM/YYYY')}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{props.t('manage_property')} :</th>
                                                        <td>{props.consultant?.can_manage_property ? props.t("yes") : props.t("no")}</td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </DataShower>

                </Container>

                {confirmModal && (
                    <SweetAlert
                        title={props.t('confirm.sure')}
                        warning
                        showCancel
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="light"
                        onConfirm={() => {
                            handleConfirm();
                        }}
                        onCancel={() => {
                            setConfirmModal(false);
                        }}
                        cancelBtnText={props.t('cancel')}
                    >
                        {props.t('not.revert')}
                    </SweetAlert>
                )}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const {
        consultant,
        consultantLoading,
        consultantError,
        removeLoading,
        removeError,
    } = state.consultant;
    return {
        consultant,
        consultantLoading,
        consultantError,
        removeLoading,
        removeError,
    };
};

const mapDispatchToProps = {
    fetchConsultant,
    cleanConsultantPage,
    removeConsultant,
};

export const Consultant = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConsultantComponent)));