import React, { useEffect, useMemo, useCallback, useState, useLayoutEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useParams, useRouteMatch, Link } from "react-router-dom";
import classNames from "classnames";

import { useTitle } from "../../hooks";
import { fetchProperty, cleanPropertyPage, setFooterRowDirection } from '../../store/actions';
import { ROLE_CLIENT, ROLE_CONSULTANT } from '../../consts/roles';
import { useAllowed } from '../../hooks';
import { PropertyClients, InviteStatusActivate } from './components';
import { INVITE_STATUS_UNCONFIRMED, INVITE_STATUS_DECLINED, INVITE_STATUS_CLOSED, INVITE_STATUS_ACTIVE } from '../../consts/invite';
import { PropertyOverviewSection, PropertyInfoSection } from './components';
import { middlewareHelper, crmSystemHelper } from '../../helpers';
import { STATUS_SOLD } from "../../consts/property";
import { PropertyLockIcon } from "components/Custom";

import './styles/PropertyPage.scss';

import * as HOC from '../../HOC';
const DataShower = HOC.withDataShower();

const { propertyMiddleware } = middlewareHelper

const PropertyComponent = (props) => {
    useTitle(props.t('title.property'));

    let { id } = useParams();
    let { url } = useRouteMatch();
    let onlyConsultant = useAllowed([ROLE_CONSULTANT], props.user.role);
    let onlyClient = useAllowed([ROLE_CLIENT], props.user.role);

    useLayoutEffect(() => {
        if (!onlyConsultant) {
            props.setFooterRowDirection('flex-row-reverse');
        }

        return () => {
            if (!onlyConsultant) {
                props.setFooterRowDirection('');
            }
        };
    }, [])

    useEffect(() => {
        props.fetchProperty(id);

        return () => {
            props.cleanPropertyPage();
        };
    }, []);

    const canEdit = useMemo(() => {
        return !props.property?.myReceivedLink && propertyMiddleware.manageIsAllow(props.user);
    }, [props.property, props.user]);

    const clientCanAcceptInvite = useMemo(() => {
        if (onlyClient && props.property && props.property.myReceivedLink) {
            return [INVITE_STATUS_UNCONFIRMED, INVITE_STATUS_DECLINED, INVITE_STATUS_CLOSED].includes(props.property?.myReceivedLink?.status)
        }
        return false;
    }, [props.property, onlyClient]);

    const [titleBlockHeight, setTitleBlockHeight] = useState(null);
    const onTitleResize = useCallback((width, height) => {
        setTitleBlockHeight(height)
    }, [setTitleBlockHeight]);

    const consultantInvitesManageAccess = useMemo(() => {
        if (onlyConsultant) {
            let access = props.property?.status !== STATUS_SOLD

            if (props.user.can_manage_property && access) {
                return props.property?.enabled
            }

            return access
        }
        return false
    }, [onlyConsultant, props.user, props.property?.status, props.property?.enabled])

    return (
        <React.Fragment>
            <div className="page-content property-page">
                <Container fluid>

                    <DataShower
                        isLoading={props.propertyLoading}
                        isFailed={props.propertyError}
                        error={props.t(props.propertyError)}
                        customLoaderClass={'mt-5'}
                    >
                        {props.property && (
                            <>
                                {clientCanAcceptInvite && (
                                    <InviteStatusActivate
                                        linkId={props.property?.myReceivedLink.id}
                                        status={props.property?.myReceivedLink?.status}
                                        consultant={props.property.myReceivedLink.sender}
                                    />
                                )}

                                <Row className={''}>
                                    <Col lg={12} xl={6}>
                                        <PropertyOverviewSection onTitleResize={onTitleResize} />
                                    </Col>

                                    <Col lg={12} xl={6}>
                                        <div className={classNames({
                                                 'property-info-section': true,
                                                 'property-info-section_offset': !canEdit,
                                             })}
                                        >
                                            {canEdit && (
                                                <div className={'property-actions-block mb-4'}
                                                     style={{ minHeight: titleBlockHeight || 'auto' }}
                                                >
                                                    <div className={"property-actions-block-wrapper"}>
                                                        {props.property?.crmProperty && (
                                                          <p className={"font-size-14 mb-0 mr-3"}>
                                                              {props.t("exported_from")}{" "}
                                                              {props.property?.crmProperty?.name}{" "}
                                                              {`(#${props.property?.crmProperty?.identifier})`}
                                                          </p>
                                                        )}

                                                        <div className={"d-flex align-self-start"}>
                                                            {props.property?.editable ? (
                                                              <Link
                                                                to={`${url}/edit`}
                                                                color={'primary'}
                                                                className="btn btn-primary waves-effect waves-light"
                                                              >
                                                                  {props.t('edit')} <i className="mdi mdi-arrow-right ml-1" />
                                                              </Link>
                                                            ) : (
                                                              <PropertyLockIcon />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <PropertyInfoSection />

                                        </div>
                                    </Col>
                                </Row>

                                {onlyConsultant && (
                                    <PropertyClients
                                        propertyId={id}
                                        propertyStatus={props.property?.status}
                                        propertyPrice={props.property?.price}
                                        title={'my_clients'}
                                        manageAccess={consultantInvitesManageAccess}
                                    />
                                )}
                            </>
                        )}
                    </DataShower>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const {
        property,
        propertyLoading,
        propertyError,
    } = state.property.view;
    return {
        property,
        propertyLoading,
        propertyError,
        user: state.user.data,
    };
};

const mapDispatchToProps = {
    fetchProperty,
    cleanPropertyPage,
    setFooterRowDirection,
};

export const Property = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyComponent)));