import {
    SWAP_BILLING_SUBSCRIPTION,
    SWAP_BILLING_SUBSCRIPTION_SUCCESS,
    SWAP_BILLING_SUBSCRIPTION_ERROR,
    CLEAN_SWAP_BILLING_SUBSCRIPTION,
} from './actionTypes';

const initialState = {
    swapLoading: false,
    swapSuccess: null,
    swapError: null,
};

const swap = (state = initialState, action) => {
    switch (action.type) {
        case SWAP_BILLING_SUBSCRIPTION:
            state = {
                ...state,
                swapLoading: true,
                swapSuccess: null,
                swapError: null,
            };
            break;

        case SWAP_BILLING_SUBSCRIPTION_SUCCESS:
            state = {
                ...state,
                swapLoading: false,
                swapSuccess: action.payload,
            };
            break;

        case SWAP_BILLING_SUBSCRIPTION_ERROR:
            state = {
                ...state,
                swapLoading: false,
                swapError: action.payload,
            };
            break;

        case CLEAN_SWAP_BILLING_SUBSCRIPTION:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default swap;