import React from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from 'classnames'

export const CompanyLeadsTabs = (props) => {
    const { t } = useTranslation();

    const { tabs, active } = props;

    return (
        <Nav pills justified>
            {tabs.map(item => (
                <NavItem key={'tab-' + item.value}>
                    <NavLink
                        className={classNames({
                            active: item.value === active,
                        })}
                        onClick={() => {
                            props.onChange(item.value);
                        }}
                    >
                        <i className="bx bx-receipt font-size-20 d-sm-none" />
                        <span className="d-none d-sm-block">{t(item.label)}</span>
                    </NavLink>
                </NavItem>
            ))}
        </Nav>
    );
};