import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { PROPERTY_CLIENTS_INVITE_STAFF_LIST_GET_DATA } from './actionTypes';
import {
    propertyClientsInviteStaffListGetDataSuccess,
    propertyClientsInviteStaffListGetDataError,
} from './actions';

import { API } from 'api';
import { getMapRequestParams } from 'utils/request';
import { ROLE_MANAGER } from 'consts/roles';

function* getData({ payload: { role, property, params } }) {
    try {
        let response;

        if (role === ROLE_MANAGER) {
            const includes = ['receiver'];

            const initialParams = {
                includes,
                filter: {
                    properties: [property],
                }
            };

            const requestParams = getMapRequestParams(params, initialParams);

            response = yield call(API.invite.getSentCompanyInvites, requestParams);
        } else {
            let requestParams = {
                includes: ['receiver'],
            };

            requestParams = getMapRequestParams(params, requestParams);

            response = yield call(API.invite.getPropertyInvites, property, requestParams);
        }

        const { data } = response;
        yield put(propertyClientsInviteStaffListGetDataSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(propertyClientsInviteStaffListGetDataError(message));
    }
}

export function* watchGetData() {
    yield takeLatest(PROPERTY_CLIENTS_INVITE_STAFF_LIST_GET_DATA, getData)
}

function* listSaga() {
    yield all([
        fork(watchGetData),
    ]);
}

export default listSaga;