import _ from 'lodash';

import {
    PROPERTY_INVESTMENT_ENTITY_PERSONAL,
    PROPERTY_INVESTMENT_ENTITY_COMPANY,
} from '../../consts/property';

export const getEntityTypeOptions = (t) => {
    return [{
        label: t('entity.personal'),
        value: PROPERTY_INVESTMENT_ENTITY_PERSONAL,
    }, {
        label: t('entity.company'),
        value: PROPERTY_INVESTMENT_ENTITY_COMPANY,
    }];
};

export const getEntityType = (value, t) => {
    const options = getEntityTypeOptions(t);
    const item = _.find(options, { value: value });

    return item || {
        label: '',
        value: '',
    };
};

export default {
    getEntityTypeOptions,
    getEntityType,
};