import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";

const AllowedRoute = ({
    component: Component,
    path,
    subscriptionMiddleware = false,
    subscriptionAccess = false,
    ...rest
}) => {
    return (
        <Route {...rest}>
            {!rest.allowed ? (
                <Redirect to={{ pathname: rest.match.path, state: { from: rest.location } }} />
            ) : subscriptionMiddleware && !subscriptionAccess ? (
                <Redirect to={{ pathname: '/billing', state: { from: rest.location } }} />
            ) : (
                <Component {...rest} />
            )}
        </Route>
    );
};

export default withRouter(AllowedRoute);