import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Button, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { connect } from "react-redux";

import { INVITE_STATUS_CLOSED, INVITE_STATUS_ACTIVE } from '../../../../consts/invite';
import { updateInvite, cleanUpdateInvite } from "../../../../store/actions";

const InviteStatusCloseComponent = (props) => {
    const { linkId } = props;

    const history = useHistory();

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        return () => {
            props.cleanUpdateInvite();
        }
    }, []);

    const handleChangeStatus = (status) => {
        props.updateInvite(linkId, { status }, INVITE_STATUS_ACTIVE, history);
    };

    return (
        <Row>
            <Col>
                <Card>
                    <CardBody>

                        <div className={visible ? "mb-2" : ""}>
                            {visible ? (
                                <Link to={"#"} onClick={e => {e.preventDefault(); setVisible(false)}} className={"text-muted"}>{props.t('unfollow.property')}</Link>
                            ) : (
                                <Link to={'#'} onClick={e => {e.preventDefault(); setVisible(true)}} className="text-danger text-decoration-underline">{props.t('unfollow.property')}</Link>
                            )}
                        </div>

                        {visible && (
                            <>
                                {props.updateInviteError && props.updateInviteError ? <Alert color="danger">{props.updateInviteError}</Alert> : null}

                                <div className="button-items">
                                    <Button
                                        type="button" color="light" outline className="waves-effect"
                                        disabled={props.updateInviteLoading}
                                        onClick={() => setVisible(false)}
                                    >
                                        {props.t('cancel')}
                                    </Button>

                                    <Button
                                        type="button"
                                        color="danger"
                                        disabled={props.updateInviteLoading}
                                        onClick={() => handleChangeStatus(INVITE_STATUS_CLOSED)}
                                    >
                                        {props.t('unfollow')}
                                    </Button>
                                </div>
                            </>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const {
        updateInviteLoading,
        updateInviteError,
    } = state.invite.reaction;
    return {
        updateInviteLoading,
        updateInviteError,
    };
};

const mapDispatchToProps = {
    updateInvite,
    cleanUpdateInvite,
};

export const InviteStatusClose = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InviteStatusCloseComponent));