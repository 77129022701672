import {
    ROLE_CONSULTANT,
} from '../../consts/roles';

export const manageIsAllow = (user) => {
    if (user) {
        const { role, can_manage_property } = user;

        if (role === ROLE_CONSULTANT) {
            return can_manage_property
        }

        return true
    }
    return false;
};

export default {
    manageIsAllow,
};