import axios from 'axios';
import qs from "qs";

const paramsSerializer = (params) => {
    return qs.stringify(params, { encode: false });
};

class SocialAPI {
    linkAccount = async (params) => {
        try {
            return axios.post('api/social/bind', {}, {
                params,
                paramsSerializer: () => paramsSerializer(params),
            });
        } catch (e) {
            throw e;
        }
    };

    unlinkAccount = async (id) => {
        try {
            return axios.delete(`api/social/${id}`);
        } catch (e) {
            throw e;
        }
    };
}

export const social = new SocialAPI();
