import {
    BILLING_STRIPE_PAY_NEW_CARD,
    BILLING_STRIPE_PAY_CARD,
    BILLING_STRIPE_PAY_CREDITS,
    BILLING_STRIPE_PAY_SUBSCRIPTION,

    BILLING_PAY_SUCCESS,
    BILLING_PAY_ERROR,
    CLEAN_BILLING_PAY,
} from './actionTypes';

const initialState = {
    processing: false,
    success: null,
    payError: null,
};

const pay = (state = initialState, action) => {
    switch (action.type) {

        // STRIPE PAY
        case BILLING_STRIPE_PAY_NEW_CARD:
            state = {
                ...state,
                processing: true,
                assignSuccess: null,
                payError: null,
            };
            break;

        // STRIPE PAY
        case BILLING_STRIPE_PAY_CARD:
            state = {
                ...state,
                processing: true,
                assignSuccess: null,
                payError: null,
            };
            break;


        // PAY PAGE STATES
        case BILLING_PAY_SUCCESS:
            state = {
                ...state,
                processing: false,
                success: true,
            };
            break;

        case BILLING_PAY_ERROR:
            state = {
                ...state,
                processing: false,
                payError: action.payload,
            };
            break;

        case CLEAN_BILLING_PAY:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default pay;