import React, { useEffect, useMemo } from 'react';
import { Row, Card, CardHeader, CardBody, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import {
    fetchSuggestedPropertyActiveList,
    cleanSuggestedPropertyActiveList,
} from '../../../../../store/actions';
import { PropertyOverviewTable } from '../../../../../components/Custom';

import * as HOC from '../../../../../HOC';
const DataShower = HOC.withDataShower();

const SuggestedPropertyComponent = (props) => {

    useEffect(() => {
        let params = {
            page: 1,
            per_page: 5,
        };

        props.fetchSuggestedPropertyActiveList(params);

        return () => {
            props.cleanSuggestedPropertyActiveList();
        };
    }, []);

    const emptyAlert = useMemo(() => {
        return !props.listLoading && props.list.length === 0;
    }, [props.listLoading, props.list]);

    return (
        <Card>
            <CardHeader className="bg-transparent">
                <Row className="no-gutters justify-content-between">
                    <h5 className={'mt-2 mr-2'}>{props.t('suggested_property.active.your.list')}</h5>

                    <Link to={`/property-requests/suggested-property`} className="align-self-center text-secondary">
                        {props.t('show_all')}
                    </Link>
                </Row>
            </CardHeader>
            <CardBody>
                <DataShower
                    isLoading={props.listLoading}
                    isFailed={props.error}
                    error={props.t(props.error)}
                    customLoaderClass={'data-shower-loader-center'}
                >
                    {emptyAlert ? (
                        <Alert color="primary">{props.t('suggested_property.empty')}</Alert>
                    ) : (
                        <PropertyOverviewTable
                            items={props.list}
                            tableHeaderStatus={false}
                        />
                    )}
                </DataShower>

                <div className={'d-flex justify-content-end mt-2'}>
                    <Link to={`/property-requests/create`} className="btn btn-primary">
                        {props.t('search_house_new')}
                    </Link>
                </div>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    const { list, listLoading, listError } = state.propertyRequest.suggestedProperty.activeList;

    return {
        user: data,
        list,
        listLoading,
        listError,
    };
};

const mapDispatchToProps = {
    fetchSuggestedPropertyActiveList,
    cleanSuggestedPropertyActiveList,
};

export const SuggestedProperty = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SuggestedPropertyComponent)));