import { all } from 'redux-saga/effects';

import tariffSaga from './tariff/sagas';
import paymentLinkSaga from './paymentLink/saga';
import reactionsSaga from './reactions/sagas';

function* subscriptionSaga() {
    yield all([
        tariffSaga(),
        paymentLinkSaga(),
        reactionsSaga(),
    ])
}

export default subscriptionSaga;