import {
    FETCH_FAVORITE_PROPERTIES,
    FETCH_FAVORITE_PROPERTIES_SUCCESS,
    FETCH_FAVORITE_PROPERTIES_ERROR,
    CLEAN_FAVORITE_PROPERTIES,
} from './actionTypes';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    meta: null,
};

const list = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_FAVORITE_PROPERTIES:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case FETCH_FAVORITE_PROPERTIES_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case FETCH_FAVORITE_PROPERTIES_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case CLEAN_FAVORITE_PROPERTIES:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default list;