import React, {useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {Col, Row} from "reactstrap";

import {CrmSystemProvider} from "../CrmSystemProvider";
import {CrmSystemCard} from "./CrmSystemCard";
import {CRM_SYSTEM} from "consts/crmSystem";

import {
  crmSystemListGetData,
  crmSystemListCleanState,
  crmSystemAccountCreateCleanState,
  crmSystemAccountRemoveCleanState,
  crmSystemSyncStartCleanState,
} from 'store/actions';

import * as HOC from 'HOC';

const DataShower = HOC.withDataShower();

const CrmSystemsContainerComponent = (props) => {
  useEffect(() => {
    props.crmSystemListGetData();

    return () => {
      props.crmSystemListCleanState();
      props.crmSystemAccountCreateCleanState();
      props.crmSystemAccountRemoveCleanState();
      props.crmSystemSyncStartCleanState();
    }
  }, []);

  return (
    <DataShower
      isLoading={props.listLoading}
      isFailed={props.listError}
      error={props.t(props.listError)}
      customLoaderClass={'data-shower-loader-center'}
    >
      <Row>
        {props.list.map((item, i) => (
          <Col lg="4" key={i}>
            <CrmSystemProvider
              component={CrmSystemCard}
              crm={CRM_SYSTEM.WHISE}
              data={item}
            />
          </Col>
        ))}
      </Row>
    </DataShower>
  );
};

const mapStateToProps = state => {
  const {list, listLoading, listError} = state.crmSystem.list;
  return {
    list,
    listLoading,
    listError,
  };
};

const mapDispatchToProps = {
  crmSystemListGetData,
  crmSystemListCleanState,
  crmSystemAccountCreateCleanState,
  crmSystemAccountRemoveCleanState,
  crmSystemSyncStartCleanState,
};

export const CrmSystemsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CrmSystemsContainerComponent)));