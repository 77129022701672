import {
    LEAD_STATUS_UNPAID,
    LEAD_STATUS_PAID,
    LEAD_STATUS_CLOSED,
    LEAD_STATUS_DECLINED,
} from '../../consts/lead';

export const getStatus = (value, t) => {
    let data = {
        label: '',
        value: '',
        color: '',
    };
    switch (value) {
        case LEAD_STATUS_UNPAID:
            data = {
                label: t('lead.status.unpaid'),
                value: LEAD_STATUS_UNPAID,
                color: 'warning'
            };
            break;

        case LEAD_STATUS_PAID:
            data = {
                label: t('lead.status.paid'),
                value: LEAD_STATUS_PAID,
                color: 'success'
            };
            break;

        case LEAD_STATUS_CLOSED:
            data = {
                label: t('lead.status.closed'),
                value: LEAD_STATUS_CLOSED,
                color: 'secondary'
            };
            break;

        case LEAD_STATUS_DECLINED:
            data = {
                label: t('lead.status.declined'),
                value: LEAD_STATUS_DECLINED,
                color: 'secondary'
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};

export default {
    getStatus,
};