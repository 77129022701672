import React from 'react';
import { AcceptedProperties } from './AcceptedProperties';
import { OwnedProperties } from './OwnedProperties';
import { FavoriteProperties } from "./FavoriteProperties";
import {
    CLIENT_PROPERTIES_ACCEPTED_TAB,
    CLIENT_PROPERTIES_FAVORITE_TAB,
    CLIENT_PROPERTIES_OWNED_TAB,
    CLIENT_PROPERTIES_ALL_TAB
} from "consts/property/clientProperties";
import { AllProperties } from "./AllProperties";


export const ClientProperties = ({ activeTab }) => {

    switch (activeTab) {
        case CLIENT_PROPERTIES_ACCEPTED_TAB:
            return <AcceptedProperties/>

        case CLIENT_PROPERTIES_OWNED_TAB:
            return <OwnedProperties/>

        case CLIENT_PROPERTIES_FAVORITE_TAB:
            return <FavoriteProperties/>

        case CLIENT_PROPERTIES_ALL_TAB:
            return <AllProperties />

        default:
            return null
    }
};
