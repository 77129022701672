import { IMPORT_PROPERTY, IMPORT_PROPERTY_COMPLETED, IMPORT_PROPERTY_FAILED } from "./actionTypes";

export const importProperty = (data, onSuccess) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: IMPORT_PROPERTY,
                payload: { params: data, onSuccess, reject },
            })
        })
    }
}

export const importPropertyCompleted = (data) => {
    return {
        type: IMPORT_PROPERTY_COMPLETED,
        payload: data,
    }
};

export const importPropertyFailed = () => {
    return {
        type: IMPORT_PROPERTY_FAILED,
    }
};
