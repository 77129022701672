import React from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { useTitle } from "../../hooks";
import { BackButton, PageTitle } from '../../components/Custom';
import { BillingInfoEdit } from './components';

const BillingInfoComponent = (props) => {
    useTitle(props.t('title.billing_info'));

    const history = useHistory();

    const handleBillingEditSuccess = () => {
        history.push('/billing/overview');
    };

    return (
        <React.Fragment>
            <div className="page-content min-vh-100">
                <Container fluid>

                    <PageTitle title={props.t('title.billing_info')} />

                    <Row className={'mb-4'}>
                        <Col>
                            <Row className={'no-gutters justify-content-between'}>
                                <BackButton />
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <BillingInfoEdit onSuccess={handleBillingEditSuccess} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const BillingInfo = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingInfoComponent)));