import React, { useState, useEffect, useMemo, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { API } from 'api';
import { ClientPropertiesFilterForm } from './ClientPropertiesFilterForm';
import { getPropertyFilter, getPropertyReceivedTypeConfig, getPropertyStatusConfig } from "helpers";
import { getApplyFilterCount } from 'utils/filter';
import { FilterModal } from 'components/Custom';
import {
    CLIENT_PROPERTIES_ACCEPTED_TAB,
    CLIENT_PROPERTIES_ALL_TAB, CLIENT_PROPERTIES_FAVORITE_TAB,
    CLIENT_PROPERTIES_OWNED_TAB
} from "../../../../../../../consts/property/clientProperties";
import {
    clientAcceptedPropertiesResetFilter, clientAcceptedPropertiesSetFilter,
    clientAllPropertiesResetFilter,
    clientAllPropertiesSetFilter,
    clientFavoritePropertiesResetFilter, clientFavoritePropertiesSetFilter,
    clientOwnedPropertiesResetFilter,
    clientOwnedPropertiesSetFilter
} from "../../../../../../../store/actions";


const ClientPropertiesFilterComponent = (props) => {
    const modalRef = createRef();
    const [modalVisible, setModalVisible] = useState(false);

    const [companyOptions, setCompanyOptions] = useState([]);

    const fetchCompanies = async () => {
        try {
            const response = await API.invite.getCompaniesFromInvites({ pagination: false });
            const { data } = response;

            setCompanyOptions(data.data)
        } catch (e) {
        }
    };

    useEffect(() => {
        if (modalVisible) {
            if (props.isAcceptedFilter) {
                fetchCompanies();
            }
        } else {
            setCompanyOptions([]);
        }

    }, [modalVisible]);

    const initialFormValues = useMemo(() => {
        let values = {
            ...props.filter?.values,
        };

        if (props.isAcceptedFilter && props.filter?.values?.types) {
            values.types = props.filter?.values?.types.map(value => getPropertyReceivedTypeConfig(value, props.t));
        }

        if (props.filter?.values?.statuses) {
            values.statuses = props.filter.values?.statuses.map(value => getPropertyStatusConfig(value, props.t));
        }

        return values;
    }, [props.filter]);

    const onSubmit = (values) => {
        let params = {
            ...values,
        };

        if (props.isAcceptedFilter && values.types) {
            params.types = values.types.map(item => item.value);
        }

        if (values.statuses) {
            params.statuses = values.statuses.map(item => item.value);
        }

        setFilter(params)

        if (modalRef.current) {
            modalRef.current.hide();
        }
    };

    const setFilter = (params) => {
        switch (props.activeTab) {
            case CLIENT_PROPERTIES_ALL_TAB: {
                props.clientAllPropertiesSetFilter(params);
                break;
            }
            case CLIENT_PROPERTIES_OWNED_TAB: {
                props.clientOwnedPropertiesSetFilter(params);
                break;
            }
            case CLIENT_PROPERTIES_FAVORITE_TAB: {
                props.clientFavoritePropertiesSetFilter(params);
                break;
            }
            case CLIENT_PROPERTIES_ACCEPTED_TAB: {
                props.clientAcceptedPropertiesSetFilter(params);
                break;
            }
        }
    }

    const handleReset = () => {
        switch (props.activeTab) {
            case CLIENT_PROPERTIES_ALL_TAB: {
                props.clientAllPropertiesResetFilter();
                break;
            }
            case CLIENT_PROPERTIES_OWNED_TAB: {
                props.clientOwnedPropertiesResetFilter();
                break;
            }
            case CLIENT_PROPERTIES_FAVORITE_TAB: {
                props.clientFavoritePropertiesResetFilter();
                break;
            }
            case CLIENT_PROPERTIES_ACCEPTED_TAB: {
                props.clientAcceptedPropertiesResetFilter();
                break;
            }
        }

        if (modalRef.current) {
            modalRef.current.hide();
        }
    };

    const count = useMemo(() => {
        return props.filter?.isActive ? getApplyFilterCount(props.filter?.values) : 0;
    }, [props.filter]);

    return (
        <FilterModal
            onVisibleChange={(value) => setModalVisible(value)}
            count={count}
            ref={modalRef}
            asIcon={props.asIcon}
        >
            <ClientPropertiesFilterForm
                initialValues={initialFormValues}
                onSubmit={onSubmit}
                onReset={handleReset}
                companyOptions={companyOptions}
                isAcceptedFilter={props.isAcceptedFilter}
            />
        </FilterModal>
    );
};

const mapStateToProps = (state, props) => {

    const filter = getPropertyFilter(state.property.clientProperties, props.activeTab)
    return {
        filter
    };
};

const mapDispatchToProps = {
    clientAllPropertiesResetFilter,
    clientOwnedPropertiesResetFilter,
    clientFavoritePropertiesResetFilter,
    clientAcceptedPropertiesResetFilter,
    clientAllPropertiesSetFilter,
    clientOwnedPropertiesSetFilter,
    clientFavoritePropertiesSetFilter,
    clientAcceptedPropertiesSetFilter,
};

export const ClientPropertiesFilter = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientPropertiesFilterComponent));