import {
    PROPERTY_REQUEST_VIEW_CLOSE_REQUEST,
    PROPERTY_REQUEST_VIEW_CLOSE_REQUEST_SUCCESS,
    PROPERTY_REQUEST_VIEW_CLOSE_REQUEST_ERROR,
    CLEAN_PROPERTY_REQUEST_VIEW_CLOSE_REQUEST,
} from './actionTypes';

const initialState = {
    closeLoading: false,
    closeSuccess: null,
    closeError: null,
};

const reaction = (state = initialState, action) => {
    switch (action.type) {
        case PROPERTY_REQUEST_VIEW_CLOSE_REQUEST:
            state = {
                ...state,
                closeLoading: true,
                closeError: null,
            };
            break;

        case PROPERTY_REQUEST_VIEW_CLOSE_REQUEST_SUCCESS:
            state = {
                ...state,
                closeLoading: false,
                closeSuccess: action.payload,
            };
            break;

        case PROPERTY_REQUEST_VIEW_CLOSE_REQUEST_ERROR:
            state = {
                ...state,
                closeLoading: false,
                closeError: action.payload,
            };
            break;

        case CLEAN_PROPERTY_REQUEST_VIEW_CLOSE_REQUEST:
            state = {
                ...state,
                closeLoading: false,
                closeSuccess: null,
                closeError: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reaction;