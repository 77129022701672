export const SEND_INVITE = 'SEND_INVITE';
export const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS';
export const SEND_INVITE_ERROR = 'SEND_INVITE_ERROR';
export const CLEAN_SEND_INVITE_ERROR = 'CLEAN_SEND_INVITE_ERROR';

export const UPDATE_INVITE = 'UPDATE_INVITE';
export const UPDATE_INVITE_SUCCESS = 'UPDATE_INVITE_SUCCESS';
export const UPDATE_INVITE_ERROR = 'UPDATE_INVITE_ERROR';
export const CLEAN_UPDATE_INVITE = 'CLEAN_UPDATE_INVITE';

export const CHANGE_INVITE = 'CHANGE_INVITE';
export const CHANGE_INVITE_SUCCESS = 'CHANGE_INVITE_SUCCESS';
export const CHANGE_INVITE_ERROR = 'CHANGE_INVITE_ERROR';
export const CLEAN_CHANGE_INVITE = 'CLEAN_CHANGE_INVITE';

export const SEND_OFFER = 'SEND_OFFER';
export const SEND_OFFER_SUCCESS = 'SEND_OFFER_SUCCESS';
export const SEND_OFFER_ERROR = 'SEND_OFFER_ERROR';
export const CLEAN_SEND_OFFER = 'CLEAN_SEND_OFFER';