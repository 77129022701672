import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_BILLING_INFO } from './actionTypes';
import {
    fetchBillingInfoSuccess,
    fetchBillingInfoError,
} from './actions';

import { API } from '../../../../api';

function* fetchBillingInfo() {
    try {
        const response = yield call(API.billing.getBillingInfo);

        const { data } = response;

        yield put(fetchBillingInfoSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchBillingInfoError(message));
    }
}

export function* watchBillingInfoFetch() {
    yield takeEvery(FETCH_BILLING_INFO, fetchBillingInfo)
}

function* viewSaga() {
    yield all([
        fork(watchBillingInfoFetch),
    ]);
}

export default viewSaga;