import {
    BILLING_STRIPE_ADD_CARD,
    BILLING_ADD_PAYMENT_METHOD,
    BILLING_STRIPE_ADD_CARD_SUCCESS,
    BILLING_STRIPE_ADD_CARD_ERROR,
    BILLING_CLEAN_STRIPE_ADD_CARD,
} from './actionTypes';

export const billingStripeAddCard = (stripe, elements, params, onSuccess) => {
    return {
        type: BILLING_STRIPE_ADD_CARD,
        payload: { stripe, elements, params, onSuccess },
    };
};

export const billingAddPaymentMethod = (params, onSuccess) => {
    return {
        type: BILLING_ADD_PAYMENT_METHOD,
        payload: { params, onSuccess },
    };
};

export const billingStripeAddCardSuccess = (data) => {
    return {
        type: BILLING_STRIPE_ADD_CARD_SUCCESS,
        payload: data,
    }
};

export const billingStripeAddCardError = (data) => {
    return {
        type: BILLING_STRIPE_ADD_CARD_ERROR,
        payload: data,
    }
};

export const billingCleanStripeAddCard = () => {
    return { type: BILLING_CLEAN_STRIPE_ADD_CARD };
};