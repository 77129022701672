import {
    FETCH_COMPANY_NOT_INTERESTED_LEADS,
    FETCH_COMPANY_NOT_INTERESTED_LEADS_SUCCESS,
    FETCH_COMPANY_NOT_INTERESTED_LEADS_ERROR,
    CLEAN_COMPANY_NOT_INTERESTED_LEADS,
    COMPANY_NOT_INTERESTED_LEADS_SET_PAGE,
} from './actionTypes';

export const fetchCompanyNotInterestedLeads = (params) => {
    return {
        type: FETCH_COMPANY_NOT_INTERESTED_LEADS,
        payload: { params }
    }
};

export const fetchCompanyNotInterestedLeadsSuccess = (data) => {
    return {
        type: FETCH_COMPANY_NOT_INTERESTED_LEADS_SUCCESS,
        payload: data,
    }
};

export const fetchCompanyNotInterestedLeadsError = (data) => {
    return {
        type: FETCH_COMPANY_NOT_INTERESTED_LEADS_ERROR,
        payload: data,
    }
};

export const cleanCompanyNotInterestedLeads = () => {
    return { type: CLEAN_COMPANY_NOT_INTERESTED_LEADS };
};

export const companyNotInterestedLeadsSetPage = (data) => {
    return {
        type: COMPANY_NOT_INTERESTED_LEADS_SET_PAGE,
        payload: data,
    }
};