import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from "react-use";
import variables from "../../../../../../../assets/scss/custom-variables.module.scss";
import PerfectScrollbar from "react-perfect-scrollbar";

export const PropertyGridWrapper = props => {
    const scrollBarRef = useRef(null);
    const isWide = useMedia(`(min-width: ${variables.xl})`);


    useEffect(() => {
       if (scrollBarRef.current && props.page) {
           scrollBarRef.current._container.scrollTop = 0
       }
    }, [props.page]);

    if (isWide) {
        return <PerfectScrollbar style={{ height: "100vh", minHeight: "704px" }} ref={scrollBarRef}>
            {props.children}
        </PerfectScrollbar>
    } else {
        return props.children
    }

};

PropertyGridWrapper.propTypes = {
    children: PropTypes.node
};


