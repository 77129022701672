import React, { useEffect, useMemo, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import {withRouter, useHistory, useParams, Redirect} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from 'moment';

import { useTitle } from "../../hooks";
import { BackButton, PageTitle } from '../../components/Custom';
import { PropertyForm } from './components';
import { fetchPropertyEditInfo, setPropertyCoordinates, editProperty, cleanPropertyEditPage, removePropertyError, editPropertyError, removeProperty } from '../../store/actions';
import { MODE_EDIT } from '../../consts/common';
import { getPropertyStatusConfig } from '../../helpers';

import * as HOC from '../../HOC';
const DataShower = HOC.withDataShower();

const PropertyEditComponent = (props) => {
    useTitle(props.t('title.property.edit'));
    const history = useHistory();
    let { id } = useParams();

    const [confirmModal, setConfirmModal] = useState(false);

    useEffect(() => {
        props.fetchPropertyEditInfo(id);

        return () => {
            props.cleanPropertyEditPage();
        };
    }, []);

    // useEffect(() => {
    //   debugger;
    //     if (props.property && props.property.editable === false) {
    //       debugger;
    //         history.push(`/properties/${props.property.id}`)
    //     }
    // }, [props.property?.editable])

    const disableForm = useMemo(() => {
        return !!props.pending.length;
    }, [props.pending]);

    const onSubmit = (values) => {
        props.removePropertyError();

        if (disableForm) {
            return;
        }

        // get images only with uploaded images detect via remote image id
        const images = props.photos.filter(item => item.id).map(e => e.id);

        const remoteImages = props.propertyRemoteImages.map(e => e.id);

        let params = {
            ...values,
            ...props.coordinates,
            city: values.city.id,
            images: [...remoteImages, ...images],
            status: values.status.value,
        };

        if (values.project) {
            params.project = values.project.id;
        }

        if (values.available_from) {
            params.available_from = values.available_from[0] ? moment(values.available_from[0]).format('YYYY-MM-DD') : null;
        }

        props.editProperty(id, params, history);
    };

    const handleRemove = () => {
        setConfirmModal(true);
    };

    const handleConfirm = () => {
        setConfirmModal(false);
        props.editPropertyError();
        props.removeProperty(id, history);
    };

    if (props.propertyEditInfo && props.propertyEditInfo?.editable === false) {
        return <Redirect to={`/properties/${props.propertyEditInfo.id}`} />
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('title.property.edit')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <BackButton />
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {props.editSuccess && props.editSuccess ? <Alert color="success">{props.t(props.editSuccess)}</Alert> : null}
                                    {props.editError && props.editError.message ? <Alert color="danger">{props.editError.message}</Alert> : null}
                                    {props.removeError && props.removeError ? <Alert color="danger">{props.removeError}</Alert> : null}

                                    <DataShower
                                        isLoading={props.propertyEditInfoLoading}
                                        isFailed={props.propertyEditInfoError}
                                        error={props.t(props.propertyEditInfoError)}
                                    >
                                        {props.propertyEditInfo && (
                                            <PropertyForm
                                                initialValues={{
                                                    ...props.propertyEditInfo,
                                                    region: props.propertyEditInfo.city?.region,
                                                    status: getPropertyStatusConfig(props.propertyEditInfo?.status, props.t),
                                                    available_from: props.propertyEditInfo.available_from ? [moment(props.propertyEditInfo.available_from).toDate()] : null,
                                                }}
                                                onSubmit={onSubmit}
                                                onSetCoordinates={props.setPropertyCoordinates}
                                                coordinates={props.coordinates}
                                                mode={MODE_EDIT}
                                                propertyId={id}
                                                disabled={disableForm || props.editLoading}
                                                loading={props.editLoading}
                                                onRemove={handleRemove}
                                                removeLoading={props.removeLoading}
                                            />
                                        )}
                                    </DataShower>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {confirmModal && (
                    <SweetAlert
                        title={props.t('confirm.sure')}
                        warning
                        showCancel
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="light"
                        onConfirm={() => {
                            handleConfirm();
                        }}
                        onCancel={() => {
                            setConfirmModal(false);
                        }}
                        cancelBtnText={props.t('cancel')}
                    >
                        {props.t('not.revert')}
                    </SweetAlert>
                )}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const {
        editLoading,
        editError,
        editSuccess,
        propertyEditInfo,
        propertyEditInfoLoading,
        propertyEditInfoError,
        coordinates,
        propertyRemoteImages,
        removeLoading,
        removeError,
    } = state.property.edit;
    return {
        photos: state.uploadPropertyPhotos.photos,
        pending: state.uploadPropertyPhotos.pending,
        editLoading,
        editError,
        editSuccess,
        propertyEditInfo,
        propertyEditInfoLoading,
        propertyEditInfoError,
        coordinates,
        propertyRemoteImages,
        removeLoading,
        removeError,
    };
};

const mapDispatchToProps = {
    fetchPropertyEditInfo,
    setPropertyCoordinates,
    editProperty,
    cleanPropertyEditPage,
    removePropertyError,
    editPropertyError,
    removeProperty,
};

export const PropertyEdit = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyEditComponent)));