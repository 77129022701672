export const FETCH_CLIENT_ACCEPTED_PROPERTIES = 'FETCH_CLIENT_ACCEPTED_PROPERTIES';
export const FETCH_CLIENT_ACCEPTED_PROPERTIES_SUCCESS = 'FETCH_CLIENT_ACCEPTED_PROPERTIES_SUCCESS';
export const FETCH_CLIENT_ACCEPTED_PROPERTIES_ERROR = 'FETCH_CLIENT_ACCEPTED_PROPERTIES_ERROR';
export const CLEAN_CLIENT_ACCEPTED_PROPERTIES = 'CLEAN_CLIENT_ACCEPTED_PROPERTIES';

export const CLIENT_ACCEPTED_PROPERTIES_SET_FILTER = 'CLIENT_ACCEPTED_PROPERTIES_SET_FILTER';
export const CLIENT_ACCEPTED_PROPERTIES_RESET_FILTER = 'CLIENT_ACCEPTED_PROPERTIES_RESET_FILTER';

export const CLIENT_ACCEPTED_PROPERTIES_SET_SORT = 'CLIENT_ACCEPTED_PROPERTIES_SET_SORT';
export const CLIENT_ACCEPTED_PROPERTIES_RESET_SORT = 'CLIENT_ACCEPTED_PROPERTIES_RESET_SORT';

export const CLIENT_ACCEPTED_PROPERTIES_SET_PAGE = 'CLIENT_ACCEPTED_PROPERTIES_SET_PAGE';

export const CLIENT_ACCEPTED_PROPERTIES_UPDATE_ITEM = 'CLIENT_ACCEPTED_PROPERTIES_UPDATE_ITEM';


export const CLIENT_ACCEPTED_PROPERTIES_SET_SEARCH = 'CLIENT_ACCEPTED_PROPERTIES_SET_SEARCH';
