import { all } from 'redux-saga/effects';

import listSaga from './list/saga';
import viewSaga from './view/saga';

function* invoiceSaga() {
    yield all([
        listSaga(),
        viewSaga(),
    ])
}

export default invoiceSaga;