import {
    FETCH_PROPERTY_EDIT_INFO,
    FETCH_PROPERTY_EDIT_INFO_SUCCESS,
    FETCH_PROPERTY_EDIT_INFO_ERROR,
    SET_PROPERTY_COORDINATES,
    REMOVE_PROPERTY_REMOTE_IMAGE,
    CLEAN_PROPERTY_EDIT_PAGE,

    EDIT_PROPERTY,
    EDIT_PROPERTY_SUCCESS,
    EDIT_PROPERTY_ERROR,

    REMOVE_PROPERTY,
    REMOVE_PROPERTY_SUCCESS,
    REMOVE_PROPERTY_ERROR,
} from './actionTypes';

const initialState = {
    propertyEditInfo: null,
    propertyEditInfoLoading: false,
    propertyEditInfoError: false,
    coordinates: null,
    propertyRemoteImages: [],

    editLoading: false,
    editSuccess: null,
    editError: null,

    removeLoading: false,
    removeSuccess: null,
    removeError: null,
};

const property = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROPERTY_EDIT_INFO:
            state = {
                ...state,
                propertyEditInfoLoading: true,
                propertyEditInfoError: null,
            };
            break;

        case FETCH_PROPERTY_EDIT_INFO_SUCCESS:
            state = {
                ...state,
                propertyEditInfoLoading: false,
                propertyEditInfo: action.payload,
                coordinates: {
                    lat: action.payload.lat,
                    lng: action.payload.lng,
                },
                propertyRemoteImages: action.payload.images,
            };
            break;

        case FETCH_PROPERTY_EDIT_INFO_ERROR:
            state = {
                ...state,
                propertyEditInfoLoading: false,
                propertyEditInfoError: action.payload,
            };
            break;

        case CLEAN_PROPERTY_EDIT_PAGE:
            state = {
                ...state,
                propertyEditInfo: null,
                propertyEditInfoLoading: false,
                propertyEditInfoError: false,
                coordinates: null,
                propertyRemoteImages: [],

                editLoading: false,
                editSuccess: null,
                editError: null,

                removeLoading: false,
                removeSuccess: null,
                removeError: null,
            };
            break;

        case REMOVE_PROPERTY_REMOTE_IMAGE:
            state = {
                ...state,
                propertyRemoteImages: state.propertyRemoteImages.filter((item) => item.id !== action.payload),
            };
            break;

        case SET_PROPERTY_COORDINATES:
            state = {
                ...state,
                coordinates: action.payload,
            };
            break;

        case EDIT_PROPERTY:
            state = {
                ...state,
                editLoading: true,
                editSuccess: null,
                editError: null,
            };
            break;

        case EDIT_PROPERTY_SUCCESS:
            state = {
                ...state,
                editLoading: false,
                editSuccess: action.payload,
            };
            break;

        case EDIT_PROPERTY_ERROR:
            state = {
                ...state,
                editLoading: false,
                editError: action.payload,
            };
            break;

        case REMOVE_PROPERTY:
            state = {
                ...state,
                removeLoading: true,
                removeSuccess: null,
                removeError: null,
            };
            break;

        case REMOVE_PROPERTY_SUCCESS:
            state = {
                ...state,
                removeLoading: false,
                removeSuccess: action.payload,
            };
            break;

        case REMOVE_PROPERTY_ERROR:
            state = {
                ...state,
                removeLoading: false,
                removeError: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default property;