import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD, SUBSCRIPTION_TARIFF_MONTHLY_PERIOD } from '../../../../../../../consts/billing/subscription';

import './TariffPeriodSwitch.scss';

const TariffPeriodSwitchComponent = ({ period, onChange, t }) => {

    const isChecked = period === SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD;

    const onInputChange = (e) => {
        const value = e.target.checked;
        if (value) {
            onChange(SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD)
        } else {
            onChange(SUBSCRIPTION_TARIFF_MONTHLY_PERIOD)
        }
    }

    return (
        <form id="pricing_form">
            <div className="pricing-block__control-block">
                <label htmlFor="plan_check"
                       className={`pricing-block__label--monthly` + (!isChecked ? ' active' : '')}
                >
                    {t('tariff.period.monthly')}
                </label>

                <div className="pricing-block__checkbox-block">
                    <input className="pricing-block__checkbox" id="plan_check" name="plan_check" type="checkbox"
                           checked={isChecked}
                           onChange={onInputChange}
                    />
                    <label htmlFor="plan_check"/>
                </div>

                <label htmlFor="plan_check"
                       className={`pricing-block__label--annually` + (isChecked ? ' active' : '')}
                >
                    {t('tariff.period.annually')}
                </label>
            </div>
        </form>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const TariffPeriodSwitch = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TariffPeriodSwitchComponent)));