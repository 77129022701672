import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import toastr from "toastr";

import { CHAT_DELETE_MESSAGE } from './actionTypes';
import { chatMessagesDeleteMessage, fetchChatMessages } from '../messages/actions';
import { chatsUpdateChatWithSort } from '../../chats/actions';

import { API } from '../../../../api';
import { updateDataChatItem } from '../../../../utils/chat';

export const participantState = (state) => state.chat.conversation.data.participant;
export const chatsListState = (state) => state.chat.chats.list;
export const messagesState = (state) => state.chat.conversation.messages;

function* deleteMessage({ payload: { id, participantId, chatId } }) {
    try {
        const response = yield call(API.chat.deleteMessage, id);

        const { data } = response;

        const participant = yield select(participantState);
        if (participant?.id === participantId) {
            // delete message in feed
            yield put(chatMessagesDeleteMessage(id));
        }

        const chats = yield select(chatsListState);
        let chat = _.find(chats, { id: chatId });
        if (chat) {
            // if is last message in chat item => delete last message
            if (chat?.last_message?.id === id) {

                const messages = yield select(messagesState);
                const message = messages.list[0]; // get current last message after delete message

                // update last message in chat item (in chat list)
                let items = updateDataChatItem({...chat}, message, chat.participant);
                yield put(chatsUpdateChatWithSort(chat.id, items));

                /* Make logic if delete message is current last message and is was first message in chat at all
                /**/
                if (messages.list[0] === undefined && messages.meta.chat_first_id !== id) {
                    yield put(fetchChatMessages(participantId));

                    // if has messages => set last message to chat list item
                }
            }
        }

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        toastr.error(message);
    }
}

export function* watchDeleteMessage() {
    yield takeEvery(CHAT_DELETE_MESSAGE, deleteMessage)
}

function* messageSaga() {
    yield all([
        fork(watchDeleteMessage),
    ]);
}

export default messageSaga;