import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { PROPERTY_CONFIG_RESET_CONFIGS } from './actionTypes';

import { setClientPropertiesActiveTab } from '../clientProperties/config/actions';
import { clientAcceptedPropertiesResetFilter } from '../clientProperties/accepted/actions';
import { clientOwnedPropertiesResetFilter } from '../clientProperties/owned/actions';
import { propertyResetFilter } from '../list/actions';

import { ROLE_CLIENT } from '../../../consts/roles';
import { CLIENT_PROPERTIES_ACCEPTED_TAB } from '../../../consts/property/clientProperties';

export const userState = (state) => state.user;
export const clientPropertiesActiveTabState = (state) => state.property.clientProperties.config.activeTab;
export const clientPropertiesAcceptedState = (state) => state.property.clientProperties.accepted;
export const clientPropertiesOwnedState = (state) => state.property.clientProperties.owned;
export const propertyListState = (state) => state.property.list;

function* resetClientPropertiesFilters() {
    const accepted = yield select(clientPropertiesAcceptedState);
    const owned = yield select(clientPropertiesAcceptedState);

    if (accepted.filter.isActive) {
        yield put(clientAcceptedPropertiesResetFilter());
    }

    if (owned.filter.isActive) {
        yield put(clientOwnedPropertiesResetFilter());
    }
}

function* resetClientPropertiesActiveTab() {
    const activeTab = yield select(clientPropertiesActiveTabState);
    if (activeTab !== CLIENT_PROPERTIES_ACCEPTED_TAB) {
        yield put(setClientPropertiesActiveTab(CLIENT_PROPERTIES_ACCEPTED_TAB));
    }
}

function* resetPropertyConfigs() {
    const user = yield select(userState);
    const role = user.data.role;

    if (role === ROLE_CLIENT) {
        yield* resetClientPropertiesFilters();
        yield* resetClientPropertiesActiveTab();
    } else {
        const propertyList = yield select(propertyListState);
        if (propertyList.filterIsActive) {
            yield put(propertyResetFilter());
        }
    }
}

export function* watchResetPropertyConfigs() {
    yield takeEvery(PROPERTY_CONFIG_RESET_CONFIGS, resetPropertyConfigs);
}

function* generalSaga() {
    yield all([
        fork(watchResetPropertyConfigs),
    ]);
}

export default generalSaga;