import React from "react"
import {
  crmSystemAccountCreate,
} from "store/actions"
import {useDispatch, useSelector} from "react-redux"

export const useAccountCreate = ({ crm }) => {
  const dispatch = useDispatch()

  const { pending } = useSelector(state => state.crmSystem.account.create)

  const loading = pending.includes(crm)

  const onSubmit = ({id, crm, params, onSuccess, onError}) => {
    dispatch(crmSystemAccountCreate({ id, crm, params, onSuccess, onError }))
  }

  return { onSubmit, loading }
}
