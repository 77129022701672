import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { stopSubmit } from 'redux-form';

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

import { FORM_FORGOT_PASSWORD } from '../../../consts/forms';

import { API } from '../../../api';

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { params, history } }) {
    try {
        const response = yield call(API.auth.forgotPassword, params);
        const { data } = response;

        yield put(
            userForgetPasswordSuccess(
                data?.message ? data?.message : "Reset link are sended to your mailbox, check there first"
            )
        );

    } catch (error) {
        const errors = error.response.data.errors;
        const message = error.response.data.message ? error.response.data.message : 'Server Error';

        yield put(userForgetPasswordError(message));

        if (errors) {
            yield put(stopSubmit(FORM_FORGOT_PASSWORD, errors));
        }
    }
}

export function* watchUserPasswordForget() {
    yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
    yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
