import React from 'react';
import { Row, Col, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form'
import { connect } from "react-redux";

import { FORM_PROPERTY_PARSER } from "../../../../consts/forms";
import TextInput from "../../../../components/Common/TextInput";
import { required as requiredValidator, isURL } from "../../../../utils/validations";
import ButtonLoadingIcon from '../../../../components/Common/ButtonLoadingIcon';

const PropertyParserFormComponent = (props) => {
    return (
        <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="form-horizontal"
            noValidate={true}
        >
            <Field
                name="url"
                label={props.t('field.label.property.parse_url')}
                placeholder={props.t('field.placeholder.property.parse_url')}
                id={'url'}
                type="text"
                component={TextInput}
                validate={[ requiredValidator, isURL ]}
                row
                disabled={props.loading}
                // hint={'property.parser.example'}
            />

            <Row className="justify-content-end">
                <Col lg="10">
                    <div className="button-items">
                        <Button type="submit" color="primary">
                            <ButtonLoadingIcon loading={props.loading} />
                            {props.t('property.pull')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};

const PropertyParser = reduxForm({
    form: FORM_PROPERTY_PARSER,
    enableReinitialize: true,
})(PropertyParserFormComponent);

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const PropertyParserForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyParser));