import { VERIFY_USER, VERIFY_USER_SUCCESS, VERIFY_USER_ERROR, VERIFY_USER_CLEAN } from './actionTypes';

const initialState = {
    loading: true,
    error: null,
    success: null,
};

const verify = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_USER:
            state = {
                ...state,
                loading: true,
                error: null,
                success: null,
            };
            break;

        case VERIFY_USER_SUCCESS:
            state = {
                ...state,
                loading: false,
                success: true,
            };
            break;

        case VERIFY_USER_ERROR:
            state = {
                ...state,
                error: true,
                loading: false,
            };
            break;

        case VERIFY_USER_CLEAN:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default verify;