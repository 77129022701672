import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, Card, Spinner } from "reactstrap";

import { ValidationErrors } from '../../../../../../../components/Custom';

const AttachmentCardComponent = ({
    id,
    item,
    loading,
    error,
    onCancel,
    onDelete,
}) => {
    const { t } = useTranslation();

    return (
        <Card
            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
        >
            <div className="p-2">
                <Row className="align-items-center no-gutters">
                    <Col className="col-auto">
                        <div className="avatar-select-wrap ml-2 mr-2">
                            <div className="avatar-sm">
                                <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                                    <i className="bx bxs-file" />
                                </span>
                            </div>

                            <div className={'avatar-loading'} style={{ display: loading ? 'flex' : 'none' }}>
                                <Spinner size={'sm'} color="primary" />
                            </div>
                        </div>
                    </Col>
                    <Col className={'ml-2 mr-2'}>
                        <span className="text-muted font-weight-bold">
                            {item.file.name}
                        </span>
                        <p className="mb-0">
                            <strong>{item.formattedSize}</strong>
                        </p>
                    </Col>
                    <div className={'pl-3 pr-4'}>
                        {loading ? (
                            <Link to="#" className="text-primary" onClick={onCancel}>{t('cancel')}</Link>
                        ) : !loading && id ? (
                            <Link to="#" className="text-primary" onClick={onDelete}>{t('remove')}</Link>
                        ) : null}
                    </div>
                </Row>

                {error ? <ValidationErrors data={error} /> : null}
            </div>
        </Card>
    );
};

export const AttachmentCard = withRouter((AttachmentCardComponent));