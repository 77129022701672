import {
    FETCH_SUGGESTED_PROPERTY_INACTIVE_LIST,
    FETCH_SUGGESTED_PROPERTY_INACTIVE_LIST_SUCCESS,
    FETCH_SUGGESTED_PROPERTY_INACTIVE_LIST_ERROR,
    CLEAN_SUGGESTED_PROPERTY_INACTIVE_LIST,

    SUGGESTED_PROPERTY_INACTIVE_LIST_SET_FILTER,
    SUGGESTED_PROPERTY_INACTIVE_LIST_RESET_FILTER,

    SUGGESTED_PROPERTY_INACTIVE_LIST_SET_SORT,
    SUGGESTED_PROPERTY_INACTIVE_LIST_RESET_SORT,

    SUGGESTED_PROPERTY_INACTIVE_LIST_SET_PAGE,
} from './actionTypes';

export const fetchSuggestedPropertyInactiveList = (params) => {
    return {
        type: FETCH_SUGGESTED_PROPERTY_INACTIVE_LIST,
        payload: { params }
    }
};

export const fetchSuggestedPropertyInactiveListSuccess = (data) => {
    return {
        type: FETCH_SUGGESTED_PROPERTY_INACTIVE_LIST_SUCCESS,
        payload: data,
    }
};

export const fetchSuggestedPropertyInactiveListError = (data) => {
    return {
        type: FETCH_SUGGESTED_PROPERTY_INACTIVE_LIST_ERROR,
        payload: data,
    }
};

export const cleanSuggestedPropertyInactiveList = () => {
    return { type: CLEAN_SUGGESTED_PROPERTY_INACTIVE_LIST };
};


// SET PROPERTY FILTER
export const suggestedPropertyInactiveListSetFilter = (data) => {
    return {
        type: SUGGESTED_PROPERTY_INACTIVE_LIST_SET_FILTER,
        payload: data,
    }
};

export const suggestedPropertyInactiveListResetFilter = () => {
    return { type: SUGGESTED_PROPERTY_INACTIVE_LIST_RESET_FILTER };
};


// SET PROPERTY SORTING
export const suggestedPropertyInactiveListSetSort = (data) => {
    return {
        type: SUGGESTED_PROPERTY_INACTIVE_LIST_SET_SORT,
        payload: data,
    }
};

export const suggestedPropertyInactiveListResetSort = () => {
    return { type: SUGGESTED_PROPERTY_INACTIVE_LIST_RESET_SORT };
};


// SET PROPERTY PAGE
export const suggestedPropertyInactiveListSetPage = (data) => {
    return {
        type: SUGGESTED_PROPERTY_INACTIVE_LIST_SET_PAGE,
        payload: data,
    }
};