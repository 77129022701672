import {
    BILLING_REMOVE_PAYMENT_CARD,
    BILLING_REMOVE_PAYMENT_CARD_SUCCESS,
    BILLING_REMOVE_PAYMENT_CARD_ERROR,
    BILLING_CLEAN_REMOVE_PAYMENT_CARD,
} from './actionTypes';

export const billingRemovePaymentCard = (id, onSuccess) => {
    return {
        type: BILLING_REMOVE_PAYMENT_CARD,
        payload: { id, onSuccess },
    }
};

export const billingRemovePaymentCardSuccess = (data) => {
    return {
        type: BILLING_REMOVE_PAYMENT_CARD_SUCCESS,
        payload: data,
    }
};

export const billingRemovePaymentCardError = (data) => {
    return {
        type: BILLING_REMOVE_PAYMENT_CARD_ERROR,
        payload: data,
    }
};

export const billingCleanRemovePaymentCard = () => {
    return { type: BILLING_CLEAN_REMOVE_PAYMENT_CARD };
};