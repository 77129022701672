import React from 'react';
import { Col, Row } from "reactstrap";
import { Contacts, LatestMessages } from '../Chat';
import { DashboardHeader } from "./components/DashboardHeader";
import { ClientPropertiesContainer } from "./components/ClientProperties";
import { useMedia } from "react-use";
import variables from "assets/scss/custom-variables.module.scss"


export const DashboardClient = () => {
    const isWide = useMedia(`(min-width: ${variables.xl})`);

    return (
        <React.Fragment>
            <DashboardHeader wrapClass={"mb-4"}/>

            <Row>
                <Col lg={12} xl={8}>
                    <ClientPropertiesContainer/>
                </Col>

                {isWide && <Col lg={12} xl={4}>
                    <Row>
                        <Col>
                            <LatestMessages/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Contacts/>
                        </Col>
                    </Row>
                </Col>}

            </Row>
        </React.Fragment>
    );
};

