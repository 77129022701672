import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';

import './SwiperThumbs.scss';

export const SwiperThumbs = ({ items, onSwiper, onChange }) => {
    return (
        <Swiper
            slidesPerView={3}
            spaceBetween={12}
            allowTouchMove={true} // enable/disable swipe
            freeMode={true}
            watchSlidesVisibility
            watchSlidesProgress
            onSlideChange={() => onChange()}
            onSwiper={(swiper) => onSwiper(swiper)}
            breakpoints={{
                // when window width is >= 320px
                768: {
                    slidesPerView: 7,
                },
                // when window width is >= 480px
                1200: {
                    slidesPerView: 5,
                },
                // when window width is >= 640px
                1700: {
                    slidesPerView: 7,
                }
            }}
        >
            {items.map((item, index) => (
                <SwiperSlide key={item.src + '-' + index}>
                    <div className="property-gallery-thumbs__block">
                        <div style={{ backgroundImage: `url("${item.src}")` }} className="property-gallery-thumbs__image" />

                        <div className="property-gallery-thumbs__cover" />
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};