import { all } from 'redux-saga/effects';

import chatSaga from './chat/saga';
import inviteSaga from './invite/saga';
import creditsSaga from './credits/saga';
import subscriptionSaga from './subscription/saga';
import importSaga from './import/saga';
import crmSystemSaga from './crmSystem/saga';

function* socketSaga() {
    yield all([
        chatSaga(),
        inviteSaga(),
        creditsSaga(),
        subscriptionSaga(),
        importSaga(),
        crmSystemSaga(),
    ])
}

export default socketSaga;