import React, { useState, useEffect } from 'react';

const browserPrefixes = ['moz', 'ms', 'o', 'webkit'];

// get the correct attribute name
const getHiddenPropertyName = (prefix) => {
    return (prefix ? prefix + 'Hidden' : 'hidden');
};

// get the correct event name
const getVisibilityEvent = (prefix) => {
    return (prefix ? prefix : '') + 'visibilitychange';
};

// get current browser vendor prefix
const getBrowserPrefix = () => {
    for (let i = 0; i < browserPrefixes.length; i++) {
        if(getHiddenPropertyName(browserPrefixes[i]) in document) {
            // return vendor prefix
            return browserPrefixes[i];
        }
    }

    // no vendor prefix needed
    return null;
};

export const useAppVisible = () => {
    const [isVisible, setIsVisible] = useState(true);

    // bind and handle events
    let browserPrefix = getBrowserPrefix(),
        hiddenPropertyName = getHiddenPropertyName(browserPrefix),
        visibilityEventName = getVisibilityEvent(browserPrefix);

    const onVisible = () => {
        // document.title = "Visible" + " | Brikk";

        // change flag value
        setIsVisible(true);
    };

    const onHidden = () => {
        // document.title = "Hidden" + " | Brikk";

        // change flag value
        setIsVisible(false);
    };

    const handleVisibilityChange = (forcedFlag) => {
        // forcedFlag is a boolean when this event handler is triggered by a
        // focus or blur eventotherwise it's an Event object
        if(typeof forcedFlag === "boolean") {
            if(forcedFlag) {
                return onVisible();
            }

            return onHidden();
        }

        if(document[hiddenPropertyName]) {
            return onHidden();
        }

        return onVisible();
    };

    useEffect(() => {

        document.addEventListener(visibilityEventName, handleVisibilityChange, false);

        window.addEventListener('focus', onVisible, false);
        window.addEventListener('blur', onHidden, false);

        return () => {
            document.removeEventListener(visibilityEventName, handleVisibilityChange, false);

            window.removeEventListener('focus', onVisible, false);
            window.removeEventListener('blur', onHidden, false);
        };
    }, []);

    return isVisible;
};