import React from 'react';
import { connect } from "react-redux";
import { Badge } from 'reactstrap';

const ChatUnreadBadgeComponent = (props) => {
    return (
        <>
            {props.count && (
                <Badge
                    className={'font-size-12 bg-primary float-right'}
                    pill
                >
                    {props.count}
                </Badge>
            )}
        </>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        count: data?.unread_messages_count || null,
    };
};

export const ChatUnreadBadge = connect(mapStateToProps, {})(ChatUnreadBadgeComponent);