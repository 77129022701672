import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchUserInfoAndData, cleanUserInfoAndData } from '../../../../store/actions';

const DashboardDataLayoutComponent = (props) => {

    useEffect(() => {
        props.fetchUserInfoAndData();

        return () => {
            props.cleanUserInfoAndData();
        };
    }, []);

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.userData.data.loading,
        data: state.userData.data.data,
        error: state.userData.data.error,
    };
};

const mapDispatchToProps = {
    fetchUserInfoAndData,
    cleanUserInfoAndData,
};

export const DashboardDataLayout = withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardDataLayoutComponent));