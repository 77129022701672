import _ from 'lodash';

import {
    PROPERTY_FLOOR_GROUND_FLOOR,
    PROPERTY_FLOOR_MIDDLE_FLOOR,
    PROPERTY_FLOOR_PENTHOUSE_FLOOR,
} from '../../consts/property';

export const getFloorOptions = (t) => {
    return [{
        label: t('ground_floor'),
        value: PROPERTY_FLOOR_GROUND_FLOOR,
    }, {
        label: t('middle_floor'),
        value: PROPERTY_FLOOR_MIDDLE_FLOOR,
    }, {
        label: t('penthouse_floor'),
        value: PROPERTY_FLOOR_PENTHOUSE_FLOOR,
    }];
};

export const getFloor = (value, t) => {
    const options = getFloorOptions(t);
    const item = _.find(options, { value: value });

    return item || {
        label: '',
        value: '',
    };
};

export default {
    getFloorOptions,
    getFloor,
};