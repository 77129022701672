import React, { useEffect } from 'react';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { connect } from "react-redux";

export const AuthSocialComponent = (props) => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (window.opener) {
            window.opener.postMessage({ location }, '*');
        } else {
            history.push('/');
        }
    }, []);

    return null;
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export const AuthSocial = withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthSocialComponent));