import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_RECEIVED_ACTIVE_PROPERTY_LIST, FETCH_RECEIVED_INACTIVE_PROPERTY_LIST } from './actionTypes';
import {
    fetchReceivedActivePropertyListSuccess,
    fetchReceivedActivePropertyListError,
    fetchReceivedInactivePropertyListSuccess,
    fetchReceivedInactivePropertyListError,
} from './actions';

import { API } from '../../../api';
import { PROPERTY_TYPE_RECEIVED } from '../../../consts/property';
import { getMapRequestParams } from '../../../utils/request';

function* fetchReceivedActivePropertyList({ payload: { params } }) {
    try {
        let initialParams = {
            includes: ['project', 'city', 'myReceivedLink', 'myReceivedLink.sender', 'myReceivedLink.sender.company'], // 'images', 'link.receiver', 'mySentLinks'
            filter: {
                types: [PROPERTY_TYPE_RECEIVED],
                links_statuses: ['unconfirmed', 'active'],
            },
        };

        const requestParams = getMapRequestParams(params, initialParams);

        const response = yield call(API.property.getPropertyList, requestParams);

        const { data } = response;
        yield put(fetchReceivedActivePropertyListSuccess(data));
    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchReceivedActivePropertyListError(message));
    }
}

function* fetchReceivedInactivePropertyList({ payload: { params } }) {
    try {
        let initialParams = {
            includes: ['project', 'city', 'myReceivedLink', 'myReceivedLink.sender', 'myReceivedLink.sender.company'], // 'images', 'link.receiver', 'mySentLinks'
            filter: {
                types: [PROPERTY_TYPE_RECEIVED],
                links_statuses: ['declined', 'closed', 'deactivated'],
            },
        };

        const requestParams = getMapRequestParams(params, initialParams);

        const response = yield call(API.property.getPropertyList, requestParams);

        const { data } = response;
        yield put(fetchReceivedInactivePropertyListSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchReceivedInactivePropertyListError(message));
    }
}

export function* watchReceivedActivePropertyListFetch() {
    yield takeLatest(FETCH_RECEIVED_ACTIVE_PROPERTY_LIST, fetchReceivedActivePropertyList)
}

export function* watchReceivedInactivePropertyListFetch() {
    yield takeLatest(FETCH_RECEIVED_INACTIVE_PROPERTY_LIST, fetchReceivedInactivePropertyList)
}

function* receivedSaga() {
    yield all([
        fork(watchReceivedActivePropertyListFetch),
        fork(watchReceivedInactivePropertyListFetch),
    ]);
}

export default receivedSaga;