import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';

import { InviteClient } from '../InviteClient';
import { PropertyClientsTable } from './PropertyClientsTable';
import { fetchPropertyInvites, cleanPropertyInvites, changeInvite, cleanChangeInvite, propertyInvitesSetSort, propertyInvitesSetPage, propertyInvitesSetSearch } from '../../../../store/actions';
import { INVITE_STATUS_DEACTIVATED, INVITE_STATUS_UNCONFIRMED } from '../../../../consts/invite';
import { STATUS_SOLD } from '../../../../consts/property';
import { OfferUpdate } from './OfferUpdate';
import { Pagination, SearchInput } from '../../../../components/Custom';

import * as HOC from '../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const PropertyClientsComponent = (props) => {
    const { propertyId, propertyPrice, manageAccess } = props;

    const [visible, setVisible] = useState(true);
    const [offerModalVisible, setOfferModalVisible] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const [invite, setInvite] = useState(null);

    useEffect(() => {
        let params = {
            page: props.page,
        };

        if (props.sort.length > 0) {
            params.sort = props.sort;
        }

        if (props.search) {
            params.filter = {
                ...params.filter,
                keywords: props.search,
            };
        }

        props.fetchPropertyInvites(propertyId, params);

    }, [props.sort, props.page, props.search]);

    useEffect(() => {
        return () => {
            props.cleanPropertyInvites();
            props.cleanChangeInvite();
        };
    }, []);

    const changeInviteAction = (inviteId, status) => {
        props.changeInvite(inviteId, { property: propertyId, status: status });
    };

    const handleCloseInvite = (item) => {
        setConfirmModal(true);
        setInvite(item);
    };

    const handleConfirm = () => {
        setConfirmModal(false);

        if (invite) {
            changeInviteAction(invite.id, INVITE_STATUS_DEACTIVATED);
        }

        setInvite(null);
    };

    const handleInvite = (item) => {
        changeInviteAction(item.id, INVITE_STATUS_UNCONFIRMED);
    };

    const toggleVisible = (e) => {
        e.preventDefault();
        setVisible(!visible);

        if (visible) {
            props.cleanChangeInvite();
        }
    };

    const handleEditInvite = (item) => {
        setInvite(item);
        setOfferModalVisible(true);
    };

    const toggleOfferModalVisible = () => {
        setOfferModalVisible(!offerModalVisible);
    };

    const closeOfferModal = () => {
        setOfferModalVisible(false);
    };

    const handleSort = useCallback(sortBy => {
        if (!_.isEqual(sortBy, props.sort)) {
            props.propertyInvitesSetSort(sortBy);
        }
    }, [props.sort]);

    const handlePageChange = (item) => {
        props.propertyInvitesSetPage(item.selected + 1);
    };

    const handleSearchChange = (value) => {
        props.propertyInvitesSetSearch(value);
    };

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Link to={'#'} onClick={toggleVisible} className={'text-gray'}>
                            <Row className="no-gutters justify-content-between">
                                <CardTitle>{props.t(props.title)}</CardTitle>

                                <div>
                                    <i
                                        style={{ transform: `rotate(${visible ? '0deg' : '180deg'})`}}
                                        className="bx bx-chevron-up font-size-24 align-middle"
                                    />
                                </div>
                            </Row>
                        </Link>

                        {visible && (
                            <div className={"property-clients"}>
                                {manageAccess && (
                                    <Row>
                                        <Col>
                                            <InviteClient propertyId={propertyId} />
                                        </Col>
                                    </Row>
                                )}

                                <Row className={'mt-3'}>
                                    <Col>
                                        {/*{props.changeInviteError ? <Alert color="danger">{props.changeInviteError}</Alert> : null}*/}

                                        {/*<Row className={'no-gutters justify-content-between'}>*/}
                                            {/*<SearchInput*/}
                                                {/*onChange={handleSearchChange}*/}
                                                {/*searchFields={*/}
                                                    {/*['search.field.name', 'search.field.email']*/}
                                                {/*}*/}
                                                {/*hintExample={'John Doe'}*/}
                                            {/*/>*/}
                                        {/*</Row>*/}

                                        <TableDataLoading isLoading={props.loading} isFailed={props.error} error={props.t(props.error)}>
                                            <PropertyClientsTable
                                                items={props.list}
                                                onSort={handleSort}
                                                isLoading={props.loading}
                                                manageAccess={manageAccess}
                                                onCloseInvite={handleCloseInvite}
                                                onActivateInvite={handleInvite}
                                                onEdit={handleEditInvite}
                                                propertyPrice={propertyPrice}
                                            />

                                            <Pagination data={props.meta} onPageChange={handlePageChange} />
                                        </TableDataLoading>
                                    </Col>
                                </Row>
                            </div>
                        )}

                    </CardBody>
                </Card>

                {confirmModal && (
                    <SweetAlert
                        title={props.t('confirm.sure')}
                        warning
                        showCancel
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="light"
                        onConfirm={() => {
                            handleConfirm();
                        }}
                        onCancel={() => {
                            setConfirmModal(false);
                            setInvite(null);
                        }}
                        cancelBtnText={props.t('cancel')}
                    >
                        {`${props.t('message.close_invite')} ${invite?.receiver?.name || invite?.receiver?.email}?`}
                    </SweetAlert>
                )}

                <OfferUpdate
                    visible={offerModalVisible}
                    toggleVisible={toggleOfferModalVisible}
                    onClose={closeOfferModal}
                    invite={invite}
                    propertyPrice={propertyPrice}
                    propertyId={propertyId}
                />
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const { property, propertyLoading, propertyError, propertyClientsSort, propertyClientsPage, propertyClientsMeta, propertyClientsSearch } = state.invite.lists;
    const { changeInviteError } = state.invite.reaction;
    return {
        language: state.common.language,
        list: property,
        loading: propertyLoading,
        error: propertyError,
        changeInviteError,
        sort: propertyClientsSort,
        meta: propertyClientsMeta,
        page: propertyClientsPage,
        search: propertyClientsSearch,
    };
};

const mapDispatchToProps = {
    fetchPropertyInvites,
    cleanPropertyInvites,
    changeInvite,
    cleanChangeInvite,
    propertyInvitesSetSort,
    propertyInvitesSetPage,
    propertyInvitesSetSearch,
};

export const PropertyClients = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyClientsComponent));