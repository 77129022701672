import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { useTitle } from "../../hooks";
import { BackButton, PageTitle } from '../../components/Custom';
import { PropertyRequestWizards } from './components';

const PropertyRequestCreateComponent = (props) => {
    useTitle(props.t('title.search_house_new'));

    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <React.Fragment>
            <div className="page-content min-vh-100">
                <Container fluid>

                    <div className = {'d-flex align-items-center mb-4'}>
                        <BackButton />
                        <PageTitle title={props.t('title.search_house_new')} wrapClass={"mb-0 ml-2"} />
                    </div>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>

                                    <PropertyRequestWizards />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const PropertyRequestCreate = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyRequestCreateComponent)));