import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { resumeBillingSubscription, cleanResumeBillingSubscription } from '../../../../../store/actions';
import ButtonLoadingIcon from '../../../../../components/Common/ButtonLoadingIcon';

const SubscriptionResumeComponent = (props) => {
    useEffect(() => {
        return () => {
            props.cleanResumeBillingSubscription();
        };
    }, []);

    const onResume = () => {
        props.resumeBillingSubscription();
    };

    return (
        <div>
            <Button
                color={'success'}
                className="btn-sm waves-effect waves-light"
                disabled={props.resumeLoading}
                onClick={onResume}
            >
                <ButtonLoadingIcon loading={props.resumeLoading} />
                {props.t('resume')}
            </Button>
        </div>
    );
};


const mapStateToProps = state => {
    const { resumeLoading } = state.billing.subscription.reactions.resume;

    return {
        resumeLoading,
    };
};

const mapDispatchToProps = {
    resumeBillingSubscription,
    cleanResumeBillingSubscription,
};

export const SubscriptionResume = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SubscriptionResumeComponent)));