import { all } from 'redux-saga/effects';

import viewSaga from './view/saga';
import updateSaga from './update/saga';

function* preferencesSaga() {
    yield all([
        viewSaga(),
        updateSaga(),
    ])
}

export default preferencesSaga;