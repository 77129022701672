import React from 'react';
import { Field } from "redux-form";
import { CustomInput, FormFeedback, FormGroup, Label, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

const CheckboxGroupField = (props) => {
    const { checkboxClassName = '' } = props;

    const { t } = useTranslation();

    const field = ({input, meta, options}) => {

        const {name, onChange} = input;
        const {touched, error} = meta;
        const inputValue = input.value;

        const showError = touched && Boolean(error);

        const checkboxes = options.map(({label, value}, index) => {

            const handleChange = (event) => {
                const arr = [...inputValue];
                if (event.target.checked) {
                    arr.push(value);
                }
                else {
                    arr.splice(arr.indexOf(value), 1);
                }
                return onChange(arr);
            };


            const checked = inputValue.includes(value);

            return (
                <div className="form-check" key={`checkbox-${value}-${index}`}>
                    <CustomInput
                        type="checkbox"
                        className={'form-check-input ' + checkboxClassName}
                        id={`checkbox-${value}-${index}`}
                        label={label}
                        htmlFor={`checkbox-${value}-${index}`}
                        name={`${name}[${index}]`} value={value} checked={checked} onChange={handleChange}
                    />
                </div>
            )
        });

        return (
            <FormGroup row tag="fieldset" className={'mb-4' + (showError ? ' text-danger' : '')}>
                <Label className={'col-form-label col-lg-2'}>{props.label}</Label>

                <Col lg="10">
                    <div className={'form-control custom-switch-filed-wrap border-0 p-0 flex-wrap' + (showError ? ' is-invalid' : '')}>
                        {checkboxes}
                    </div>
                    <FormFeedback invalid={showError ? error : null }>
                        { t(error, props.translateParams ? { ...props.translateParams } : {}) }
                    </FormFeedback>
                </Col>
            </FormGroup>
        );
    };

    return (
        <Field {...props} type="checkbox" component={field} />
    );
};

export default CheckboxGroupField;