import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { useTranslation } from 'react-i18next';

import './PropertyDescriptionBlock.scss';

export const PropertyDescriptionBlock = ({ description }) => {
    const { t } = useTranslation();

    return (
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle className="font-size-15 text-black mb-3">{t('property_information')}</CardTitle>

                        <p className="text-muted mb-2">{description}</p>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};