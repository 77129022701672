import {
    BILLING_SEND_DEFAULT_PAYMENT_CARD,
    BILLING_SEND_DEFAULT_PAYMENT_CARD_SUCCESS,
    BILLING_SEND_DEFAULT_PAYMENT_CARD_ERROR,
    BILLING_CLEAN_SEND_DEFAULT_PAYMENT_CARD,
} from './actionTypes';

export const billingSendDefaultPaymentCard = (id) => {
    return {
        type: BILLING_SEND_DEFAULT_PAYMENT_CARD,
        payload: { id },
    }
};

export const billingSendDefaultPaymentCardSuccess = (data) => {
    return {
        type: BILLING_SEND_DEFAULT_PAYMENT_CARD_SUCCESS,
        payload: data,
    }
};

export const billingSendDefaultPaymentCardError = (data) => {
    return {
        type: BILLING_SEND_DEFAULT_PAYMENT_CARD_ERROR,
        payload: data,
    }
};

export const billingCleanSendDefaultPaymentCard = () => {
    return { type: BILLING_CLEAN_SEND_DEFAULT_PAYMENT_CARD };
};