import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_PAYMENT_CARDS } from './actionTypes';
import {
    fetchPaymentCardsSuccess,
    fetchPaymentCardsError,
} from './actions';

import { API } from '../../../../api';

function* fetchCards() {
    try {
        const response = yield call(API.billing.getPaymentMethods);

        const { data } = response;
        yield put(fetchPaymentCardsSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchPaymentCardsError(message));
    }
}

export function* watchCardsFetch() {
    yield takeLatest(FETCH_PAYMENT_CARDS, fetchCards)
}

function* cardsSaga() {
    yield all([
        fork(watchCardsFetch),
    ]);
}

export default cardsSaga;