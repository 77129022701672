import { combineReducers } from "redux";

import contactsReducer from './contacts/reducer';
import conversationReducer from './conversation/reducers';
import chatsReducer from './chats/reducer';
import tabsReducer from './tabs/reducer';

const chatReducer = combineReducers({
    contacts: contactsReducer,
    conversation: conversationReducer,
    chats: chatsReducer,
    tabs: tabsReducer
});

export default chatReducer;