import React, { useLayoutEffect, useMemo } from 'react';
import { connect } from "react-redux";
import { Trans, useTranslation } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";
import moment from 'moment';

import './TrialPeriodAlert.scss';

const TrialPeriodAlertComponent = (props) => {
    const { t } = useTranslation();

    const toggleBodyClass = () => {
        if (document.body) document.body.classList.toggle('trial-alert_enabled');
    };

    const trialDays = useMemo(() => {
        if (props.user?.subscription?.trial_ends_at) {
            let start = moment(new Date()).startOf('day');
            let end = moment(props.user.subscription.trial_ends_at);

            // Difference in number of days
            return moment.duration(end.diff(start)).asDays();
        }

        return -1;
    }, [])

    useLayoutEffect(() => {
        toggleBodyClass();

        return () => {
            toggleBodyClass();
        };
    }, [])

    return (
        <div className={'trial-alert'}>
            <div className={'trial-alert__block'}>
                {trialDays > 0 ? (
                    <span className={'font-size-14'}>
                        <Trans i18nKey="trial_period.expired.days" count={trialDays} />{'.'}
                    </span>
                ) : (
                    <span className={'font-size-14'}>
                        {t('subscription.trial_ends_date')} {` : `}
                        <span className={'font-weight-bold'}>
                            {moment(props.user?.subscription?.trial_ends_at).format('DD/MM/YYYY')}{'.'}
                        </span>
                    </span>
                )}
                &nbsp;
                <Link to={'/billing/subscriptions'}
                      className={'text-decoration-underline font-size-14 font-weight-bold text-white'}
                >
                    {t('upgrade')}
                </Link>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

const mapDispatchToProps = {
};

export const TrialPeriodAlert = withRouter(connect(mapStateToProps, mapDispatchToProps)(TrialPeriodAlertComponent));