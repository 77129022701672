import { takeEvery, fork, put, all, select, call } from 'redux-saga/effects';
import _ from 'lodash';

import { ON_CHAT_MESSAGE_EVENT, ON_CHAT_SEEN_EVENT, ON_CHAT_TYPING_EVENT } from './actionTypes';
import { chatAddMessage, chatMarkMessagesSeen } from "../../chat/conversation/messages/actions";
import { chatsAddChat, chatsUpdateChatWithSort, chatsUpdateChat, chatsMarkChatRead } from "../../chat/chats/actions";
import { updateChatConversationParticipantData, setBackgroundUnreadMessagesStatus } from "../../chat/conversation/data/actions";
import { updateUnreadMessagesCount } from "../../user/actions";

import { createChatItemBasedOnMessage, updateDataChatItem } from '../../../utils/chat';
import { API } from "../../../api";

export const participantState = (state) => state.chat.conversation.data.participant;
export const chatsState = (state) => state.chat.chats;
export const appIsVisibleState = (state) => state.common.appIsVisible;

function* onChatMessageEvent({ payload: { data } }) {
    const { message, unread_count } = data;

    const participant = yield select(participantState);

    // if message come from current participant
    const isCurrentChat = participant?.id === message.user.id;

    let readChatResponse = null;

    if (isCurrentChat) {
        // add message to feed
        yield put(chatAddMessage(message));

        if (!participant.is_online) {
            // update participant user data
            yield put(updateChatConversationParticipantData(message.user));
        }

        const appIsVisible = yield select(appIsVisibleState);

        // only if web app is visible (window in focus)
        if (appIsVisible) {

            // read chat (seen messages)
            readChatResponse = yield call(API.chat.readChat, message.user.id);
            if (readChatResponse) {
                // mark chat read
                yield put(chatsMarkChatRead(message.chat_id));
            }

        } else {
            yield put(setBackgroundUnreadMessagesStatus(true));
        }
    } else {
        // update count all of unread messages (- badge in side menu)
        yield put(updateUnreadMessagesCount(unread_count));
    }

    const chats = yield select(chatsState);
    if (chats.isLoaded) {
        // update chat list
        let chat = _.find(chats.list, { id: message?.chat_id });

        if (chat) {
            const chatData = {
                ...chat,
                unread_count: isCurrentChat ? chat.unread_count : chat.unread_count + 1,
            };

            // if exist - update chat item
            let items = updateDataChatItem({...chatData}, message, message.user);
            yield put(chatsUpdateChatWithSort(chat.id, items));

        } else {

            // add new chat item
            let newChat = createChatItemBasedOnMessage(message, message.user, isCurrentChat);
            yield put(chatsAddChat(newChat));

        }
    }
}

function* onChatSeenEvent({ payload: { data } }) {
    const { chat_id, user } = data;

    const participant = yield select(participantState);

    // if message come from current participant
    const isCurrentChat = participant?.id === user.id;

    if (isCurrentChat) {
        // mark messages as seen
        yield put(chatMarkMessagesSeen());

        if (!participant.is_online) {
            // update participant user data
            yield put(updateChatConversationParticipantData(user));
        }
    }

    const chats = yield select(chatsState);
    if (chats.isLoaded) {
        let chat = _.find(chats.list, { id: chat_id });

        if (chat) {

            if (!chat.participant.is_online) {
                // set participant to online
                const item = {
                    ...chat,
                    participant: {
                        ...chat.participant,
                        ...user,
                    }
                };
                yield put(chatsUpdateChat(chat.id, item));
            }
        }
    }
}

function* onChatTypingEvent({ payload: { data } }) {
    const { userId } = data;

    const participant = yield select(participantState);

    const isCurrentChat = participant?.id === userId;

    if (isCurrentChat) {
        // todo: update user online status
    }

    // todo: update user online status in chat list
}

export function* watchChatMessageEvent() {
    yield takeEvery(ON_CHAT_MESSAGE_EVENT, onChatMessageEvent)
}

export function* watchChatSeenEvent() {
    yield takeEvery(ON_CHAT_SEEN_EVENT, onChatSeenEvent)
}

export function* watchChatTypingEvent() {
    yield takeEvery(ON_CHAT_TYPING_EVENT, onChatTypingEvent)
}

function* chatSaga() {
    yield all([
        fork(watchChatMessageEvent),
        fork(watchChatSeenEvent),
        fork(watchChatTypingEvent),
    ]);
}

export default chatSaga;