import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import { API } from './api';
import { SUPPORTED_LANGUAGE_CODES } from './consts/locales';

const DEFAULT_LANGUAGE_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE;

const backendOptions = {
    loadPath: '{{lng}}|{{ns}}',
    request: async (options, url, payload, callback) => {
        try {
            const [lng] = url.split('|');
            const response = await API.common.getLangTranslations(lng);
            const { data } = response;

            callback(null, {
                data: data.data.translation,
                status: 200,
            });
        } catch (e) {
            callback(null, {
                status: 500,
            });
        }
    },
};

i18n
    .use(LanguageDetector)
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['localStorage'],
        },
        fallbackLng: DEFAULT_LANGUAGE_CODE ? DEFAULT_LANGUAGE_CODE : "nl",
        supportedLngs: SUPPORTED_LANGUAGE_CODES,

        backend: backendOptions,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },

        debug: false,

        // react-i18next options
        react: {
            wait: true
        },
    });

export default i18n;