import {all} from 'redux-saga/effects';

import listSaga from './list/saga';
import accountSaga from './account/sagas';
import syncSaga from './sync/sagas';

function* crmSystemSaga() {
  yield all([
    listSaga(),
    accountSaga(),
    syncSaga(),
  ])
}

export default crmSystemSaga;