import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { useTitle } from "../../hooks";
import { fetchOffers, cleanOffersPage, offersSetSort, offersSetPage, offersSetFilter, offersResetFilter, } from '../../store/actions';
import { OffersTable, OffersFilter } from './components';
import { Pagination, SearchInput, PageTitle } from '../../components/Custom';
import { getOfferStatusOptions } from "../../helpers";

import * as HOC from '../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const PropertyOffersComponent = (props) => {
    useTitle(props.t('title.price_alteration'));

    const [search, setSearch] = useState(null);

    useEffect(() => {
        let params = {
            page: props.page,
        };

        if (props.sort.length > 0) {
            params.sort = props.sort;
        }

        if (props.filter.isActive) {
            params.filter = props.filter.values;
        }

        if (search) {
            params.filter = {
                ...params.filter,
                keywords: search,
                search_fields: ['sender_name', 'receiver_name', 'address'],
            };
        }

        props.fetchOffers(params);
    }, [props.sort, props.page, props.filter, search]);

    useEffect(() => {
        return () => {
            props.cleanOffersPage();
            props.offersResetFilter();
        }
    }, []);

    const filterWarningVisible = useMemo(() => {
        return (props.filter.isActive || search) && props.offers.length === 0 && !props.offersLoading;
    }, [props.filter, props.offers, props.offersLoading, search]);

    const handleSort = useCallback(sortBy => {
        if (!_.isEqual(sortBy, props.sort)) {
            props.offersSetSort(sortBy);
        }
    }, [props.sort]);

    const handlePageChange = (item) => {
        props.offersSetPage(item.selected + 1);
    };

    const handleResetFilter = () => {
        props.offersResetFilter();
    };

    const handleApplyFilter = (values) => {
        props.offersSetFilter(values);
    };

    const handleSearchChange = (value) => {
        props.offersSetPage(1);
        setSearch(value);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('title.price_alteration')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <h5 className={'mt-2 mr-2'}>{props.t('propositions.table.title')}</h5>

                                        <div className="button-items">
                                            <OffersFilter
                                                filter={props.filter}
                                                onReset={handleResetFilter}
                                                onApply={handleApplyFilter}
                                                statusOptions={getOfferStatusOptions(props.t)}
                                            />
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row className={'no-gutters justify-content-between'}>
                                        <SearchInput
                                            onChange={handleSearchChange}
                                            searchFields={
                                                ['search.field.consultants_name', 'search.field.clients_name', 'search.field.address']
                                            }
                                            hintExample={'Koningsstraat'}
                                        />
                                    </Row>

                                    <TableDataLoading isLoading={props.offersLoading} isFailed={props.offersError} error={props.t(props.offersError)}>
                                        {filterWarningVisible ? (
                                            <Alert color="primary">{props.t('propositions.filter.empty')}</Alert>
                                        ) : (
                                            <>
                                                <OffersTable
                                                    items={props.offers}
                                                    onSort={handleSort}
                                                    isLoading={props.offersLoading}
                                                    initialSort={props.sort}
                                                />

                                                <Pagination data={props.meta} onPageChange={handlePageChange} />
                                            </>
                                        )}
                                    </TableDataLoading>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { offers, offersLoading, offersError, offersSort, page, meta, filter } = state.offer.lists;
    return {
        offers, offersLoading, offersError,
        sort: offersSort,
        meta,
        page,
        filter,
    };
};

const mapDispatchToProps = {
    fetchOffers,
    cleanOffersPage,
    offersSetSort,
    offersSetPage,
    offersSetFilter,
    offersResetFilter,
};

export const PropertyOffers = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyOffersComponent)));