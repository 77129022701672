import React from 'react';
import { Row, Col, FormGroup, Label, FormFeedback } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement
} from '@stripe/react-stripe-js';
import './StripeCardFields.css';

const CARD_ELEMENT_OPTIONS = {
    // For more styling details, see https://stripe.com/docs/js/appendix/style
    style: {
        base: {
            fontSize: '14px',
            fontWeight: 400,
            color: '#495057',
            fontFamily: 'Poppins, sans-serif',
            '::placeholder': {
                color: '#6c757d'
            }
        },
        invalid: {
            color: '#f46a6a'
        }
    }
};

export const StripCardFields = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <FormGroup row className={'mb-4'}>
                <Label className={'col-form-label col-lg-2'}>{t('card.card_number')}</Label>

                <Col lg={'10'}>
                    <div className={''}>
                        <CardNumberElement
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={props.handleChange}
                        />
                    </div>
                    <FormFeedback invalid={null}>
                        {/*{ t(props.error, props.translateParams ? { ...props.translateParams } : {}) }*/}
                    </FormFeedback>
                </Col>
            </FormGroup>

            <FormGroup row className={'mb-4'}>
                <Label className={'col-form-label col-lg-2'}>{t('card.expiry_date')}</Label>

                <Col lg={'10'}>
                    <div className={''}>
                        <CardExpiryElement
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={props.handleChange}
                        />
                    </div>
                    <FormFeedback invalid={null}>
                        {/*{ t(props.error, props.translateParams ? { ...props.translateParams } : {}) }*/}
                    </FormFeedback>
                </Col>
            </FormGroup>

            <FormGroup row className={'mb-4'}>
                <Label className={'col-form-label col-lg-2'}>{t('card.cvc')}</Label>

                <Col lg={'10'}>
                    <div className={''}>
                        <CardCvcElement
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={props.handleChange}
                        />
                    </div>
                    <FormFeedback invalid={null}>
                        {/*{ t(props.error, props.translateParams ? { ...props.translateParams } : {}) }*/}
                    </FormFeedback>
                </Col>
            </FormGroup>
        </>
    );
};