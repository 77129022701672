import {
    SEND_INVITE,
    SEND_INVITE_SUCCESS,
    SEND_INVITE_ERROR,
    CLEAN_SEND_INVITE_ERROR,

    UPDATE_INVITE,
    UPDATE_INVITE_SUCCESS,
    UPDATE_INVITE_ERROR,
    CLEAN_UPDATE_INVITE,

    CHANGE_INVITE,
    CHANGE_INVITE_SUCCESS,
    CHANGE_INVITE_ERROR,
    CLEAN_CHANGE_INVITE,

    SEND_OFFER,
    SEND_OFFER_SUCCESS,
    SEND_OFFER_ERROR,
    CLEAN_SEND_OFFER,
} from './actionTypes';

// SEND INVITE
export const sendInvite = (params) => {
    return {
        type: SEND_INVITE,
        payload: { params },
    }
};

export const sendInviteSuccess = (data) => {
    return {
        type: SEND_INVITE_SUCCESS,
        payload: data,
    }
};

export const sendInviteError = (data) => {
    return {
        type: SEND_INVITE_ERROR,
        payload: data,
    }
};

export const cleanSendInvite = () => {
    return { type: CLEAN_SEND_INVITE_ERROR };
};

// UPDATE INVITE
export const updateInvite = (id, params, currentStatus, history) => {
    return {
        type: UPDATE_INVITE,
        payload: { id, params, currentStatus, history },
    }
};

export const updateInviteSuccess = (data) => {
    return {
        type: UPDATE_INVITE_SUCCESS,
        payload: data,
    }
};

export const updateInviteError = (data) => {
    return {
        type: UPDATE_INVITE_ERROR,
        payload: data,
    }
};

export const cleanUpdateInvite = () => {
    return { type: CLEAN_UPDATE_INVITE };
};


// CHANGE INVITE
export const changeInvite = (id, params) => {
    return {
        type: CHANGE_INVITE,
        payload: { id, params },
    }
};

export const changeInviteSuccess = (data) => {
    return {
        type: CHANGE_INVITE_SUCCESS,
        payload: data,
    }
};

export const changeInviteError = (data) => {
    return {
        type: CHANGE_INVITE_ERROR,
        payload: data,
    }
};

export const cleanChangeInvite = () => {
    return { type: CLEAN_CHANGE_INVITE };
};


// SEND OFFER
export const sendOffer = (id, params, action) => {
    return {
        type: SEND_OFFER,
        payload: { id, params, action },
    }
};

export const sendOfferSuccess = (data) => {
    return {
        type: SEND_OFFER_SUCCESS,
        payload: data,
    }
};

export const sendOfferError = (data) => {
    return {
        type: SEND_OFFER_ERROR,
        payload: data,
    }
};

export const cleanSendOffer = () => {
    return { type: CLEAN_SEND_OFFER };
};