import React from 'react';
import { Row, Col, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form'
import { connect } from "react-redux";

import { CONTACTS_FILTER_FORM } from "../../../../consts/forms";
import SelectInput from "../../../../components/Common/SelectInput";

const ContactsFilterFormComponent = (props) => {
    const handleReset = () => {
        props.reset(CONTACTS_FILTER_FORM);

        props.onReset();
    };

    return (
        <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="form-horizontal"
            noValidate={true}
        >
            <Field
                name="companies"
                label={props.t('field.label.vendors')}
                placeholder={props.t('field.placeholder.vendors')}
                id={'companies'}
                component={SelectInput}
                options={props.companyOptions}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                isMulti
            />

            <Row className="justify-content-end">
                <Col lg="10">
                    <div className="button-items">
                        <Button type="submit" color="primary">
                            {props.t('apply')}
                        </Button>

                        <Button type="button" color="light" outline onClick={handleReset}>
                            {props.t('reset')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};

const ContactsFilter = reduxForm({
    form: CONTACTS_FILTER_FORM,
    enableReinitialize: true,
})(ContactsFilterFormComponent);

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const ContactsFilterForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactsFilter));