import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Container, Alert } from "reactstrap";

import { setAppDataIsLoaded } from "../../../store/actions";

const AppLoaderComponent = (props) => {

    useEffect(() => {
        return () => {
            // props.setAppDataIsLoaded(false);
        };
    }, []);

    return (
        <>
            {props.appDataIsLoaded ? (

                props.children

            ) : props.error ? (
                <Container>
                    <Alert color="danger mt-5">{props.error}</Alert>
                </Container>
            ) : null}
        </>
    );
};

const mapStateToProps = state => {
    const { appDataIsLoaded } = state.common;

    return {
        appDataIsLoaded,
    };
};

const mapDispatchToProps = {
    setAppDataIsLoaded,
};

export const AppLoader = connect(mapStateToProps, mapDispatchToProps)(AppLoaderComponent);