import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_ON_GRACE_PERIOD } from '../../../../../../consts/billing/subscription';
import { subscriptionHelper } from '../../../../../../helpers';
import { SubscriptionCancel, SubscriptionResume } from '../../../Subscription';

const BillingOverviewSubscriptionStatusComponent = (props) => {
    const { status } = props;

    return (
        <div className={'d-flex align-items-center justify-content-between'}>
            {subscriptionHelper.status.getStatus(status, props.t).label}

            {status === SUBSCRIPTION_STATUS_ACTIVE ? (
                <div className={'ml-2 mr-2'}>
                    <SubscriptionCancel />
                </div>
            ) : status === SUBSCRIPTION_STATUS_ON_GRACE_PERIOD ? (
                <div className={'ml-2 mr-2'}>
                    <SubscriptionResume />
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export const BillingOverviewSubscriptionStatus = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingOverviewSubscriptionStatusComponent)));