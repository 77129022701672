import React from 'react';
import { Row, Col, Button, FormGroup, Label } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, FormSection } from 'redux-form'
import { connect } from "react-redux";

import { FORM_LEAD_PROPERTY_FILTER } from "../../../../../consts/forms";
import SelectInput from "../../../../../components/Common/SelectInput";
import SwitchField from "../../../../../components/Common/SwitchField";
import { getPropertyStatusOptions } from "../../../../../helpers";

import { PriceSliderRangeField, CitySelectField } from '../../../../../components/Custom';
import { PROPERTY_PRICE_MAX, PROPERTY_PRICE_MIN } from "../../../../../consts/property";

const PropertyFilterFormComponent = (props) => {
    const handleReset = () => {
        props.reset(FORM_LEAD_PROPERTY_FILTER);

        props.onReset();
    };

    return (
        <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="form-horizontal"
            noValidate={true}
        >
            <Field
                name="projects"
                label={props.t('field.label.projects')}
                placeholder={props.t('field.placeholder.projects')}
                id={'projects'}
                component={SelectInput}
                options={props.projectOptions}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                isMulti
            />

            <CitySelectField
                name="cities"
                label={props.t('field.label.cities')}
                placeholder={props.t('field.placeholder.search_select_cities')}
                id={'cities'}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                isMulti
            />

            <FormSection name={'price'}>
                <FormGroup row className={'mb-4'}>
                    <Label className={'col-form-label col-lg-2'}>{props.t('field.label.property.price')}</Label>

                    <Col lg="10">
                        <Field
                            name="range"
                            component={PriceSliderRangeField}
                            limit={{
                                min: PROPERTY_PRICE_MIN,
                                max: PROPERTY_PRICE_MAX,
                            }}
                            inputMinConfig={{
                                id: 'price_min',
                                placeholder: 'field.placeholder.price_from'
                            }}
                            inputMaxConfig={{
                                id: 'price_max',
                                placeholder: 'field.placeholder.price_to'
                            }}
                        />
                    </Col>
                </FormGroup>

                <Field
                    name="empty_price"
                    label={''}
                    placeholder={props.t('field.placeholder.property_without_price')}
                    id={'empty_price'}
                    type="checkbox"
                    component={SwitchField}
                />
            </FormSection>

            <Field
                name="statuses"
                label={props.t('field.label.property_statuses')}
                placeholder={props.t('field.placeholder.property_statuses')}
                id={'property-statuses'}
                component={SelectInput}
                options={getPropertyStatusOptions(props.t)}
                isMulti
                isSearchable={false}
            />

            <Row className="justify-content-end">
                <Col lg="10">
                    <div className="button-items">
                        <Button
                            type="submit"
                            color="primary"
                        >
                            {props.t('apply')}
                        </Button>

                        <Button
                            type="button"
                            color="light"
                            outline
                            onClick={handleReset}
                        >
                            {props.t('reset')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};

const PropertyFilter = reduxForm({
    form: FORM_LEAD_PROPERTY_FILTER,
    enableReinitialize: true,
})(PropertyFilterFormComponent);

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const PropertyFilterForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyFilter));