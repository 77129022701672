import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ConsultantCell = ({ items }) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const isMultiply = useMemo(() => {
        return items && items.length > 1;
    }, [items]);

    const renderItem = (item) => (
        <div key={'consultant-' + item.id}>
            <Link to={`/consultants/${item.id}`} className="text-dark font-weight-bold">{item.name}</Link>
        </div>
    );

    const renderItems = () => {
        let data = isOpen ? [...items] : [{...items[0]}];

        return data.map(item => (
            renderItem(item)
        ));
    };

    return (
        <>
            {items && renderItems()}

            {isMultiply && (
                <div className={'text-center mt-1'}>
                    <Link
                        to={'#'}
                        className="text-primary"
                        onClick={(e) => {
                            e.preventDefault();
                            setIsOpen(!isOpen)
                        }}
                    >
                        {t(isOpen ? 'button.show_less' : 'button.show_more')}
                    </Link>
                </div>
            )}
        </>
    );
};