import React from 'react';
import {Row, Col, Button, Alert} from "reactstrap";
import {withTranslation} from 'react-i18next';
import {Field, reduxForm} from 'redux-form'
import {connect} from "react-redux";

import {FORM_CRM_SYSTEM_ACCOUNT_CREATE_WHISE} from "consts/forms";
import TextInput from "components/Common/TextInput";
import ButtonLoadingIcon from 'components/Common/ButtonLoadingIcon';
import {
  required as requiredValidator,
} from "utils/validations";

const AccountFormComponent = (props) => {
  return (
    <>
      {props.errorMessage && <Alert color="danger">{props.errorMessage}</Alert>}

      <form
        onSubmit={props.handleSubmit(props.onSubmit)}
        className="form-horizontal"
        noValidate={true}
      >
        <Field
          name="email"
          label={props.t('email')}
          placeholder={props.t('email')}
          id={'email'}
          type="text"
          component={TextInput}
          validate={[requiredValidator]}
          row
        />

        <Field
          name="client_id"
          label={props.t('client_id')}
          placeholder={props.t('client_id')}
          id={'client_id'}
          type="text"
          component={TextInput}
          validate={[requiredValidator]}
          row
        />

        <Field
          name="office_id"
          label={props.t('office_id')}
          placeholder={props.t('office_id')}
          id={'office_id'}
          type="text"
          component={TextInput}
          validate={[requiredValidator]}
          row
        />

        <Row className="justify-content-end">
          <Col lg="10">
            <div className="button-items">
              <Button type="submit" color="primary" disabled={props.loading}>
                <ButtonLoadingIcon loading={props.loading}/>
                {props.t('add')}
              </Button>

              <Button type="button" color="light" outline onClick={props.onCancel}>
                {props.t('cancel')}
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

const Account = reduxForm({
  form: FORM_CRM_SYSTEM_ACCOUNT_CREATE_WHISE,
  enableReinitialize: true,
})(AccountFormComponent);

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export const AccountForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Account));