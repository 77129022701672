import React from 'react';
import { Alert, Spinner } from "reactstrap";

export default () => {
    return ({ isLoading, isFailed = false, error, children, spinnerSize = 'lg', ...rest }) =>
        isLoading ? (
            <div className={'text-center ' + rest.customLoaderClass}>
                <Spinner size={spinnerSize} color="primary" />
            </div>
        ) : !isLoading && !isFailed ? (
					<>{children}</>
        ) : (
						<Alert color={'danger'}>{error || 'Error'}</Alert>
        );
};