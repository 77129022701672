import { all } from 'redux-saga/effects';

import consultantSaga from './consultant/saga';
import clientSaga from './client/saga';

function* statisticsSaga() {
    yield all([
        consultantSaga(),
        clientSaga(),
    ])
}

export default statisticsSaga;