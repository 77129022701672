import { all } from 'redux-saga/effects';

import listSaga from './list/saga';

function* favoriteSaga() {
    yield all([
        listSaga(),
    ])
}

export default favoriteSaga;