import React, { useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';

import { useTitle } from "../../hooks";
import { fetchProjectList, cleanProjectListPage, projectSetSort, projectSetPage } from '../../store/actions';
import { ROLE_CLIENT, ROLE_MANAGER } from '../../consts/roles';
import { useAllowed } from '../../hooks';
import { ProjectsTable } from './components';
import { Pagination, PageTitle } from '../../components/Custom';

import * as HOC from '../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const ProjectListComponent = (props) => {
    useTitle(props.t('title.projects'));
    let isAllowed = useAllowed([ROLE_CLIENT, ROLE_MANAGER], props.user.role);

    useEffect(() => {
        let params = {
            page: props.page,
        };

        if (props.sort.length > 0) {
            params.sort = props.sort;
        }

        props.fetchProjectList(params);
    }, [props.sort, props.page]);

    useEffect(() => {
        return () => {
            props.cleanProjectListPage();
        }
    }, []);

    const handleSort = useCallback(sortBy => {
        if (!_.isEqual(sortBy, props.sort)) {
            props.projectSetSort(sortBy);
        }
    }, [props.sort]);

    const handlePageChange = (item) => {
        props.projectSetPage(item.selected + 1);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('project-list')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <h5 className={'mt-2 mr-2'}>{props.t('projects.your.list')}</h5>

                                        {isAllowed && (
                                            <Link to="/projects/add" className="btn btn-primary">
                                                {props.t('project.add')}
                                                <i className="fas fa-angle-right font-size-16 align-middle ml-2"></i>
                                            </Link>
                                        )}

                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <TableDataLoading isLoading={props.listLoading} isFailed={props.listError} error={props.t(props.listError)}>
                                        <ProjectsTable
                                            items={props.list}
                                            onSort={handleSort}
                                            isLoading={props.listLoading}
                                        />

                                        <Pagination data={props.meta} onPageChange={handlePageChange} />
                                    </TableDataLoading>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError, sort, meta, page } = state.project;
    const { data } = state.user;
    return {
        list, listLoading, listError,
        user: data,
        sort,
        meta,
        page,
    };
};

const mapDispatchToProps = {
    fetchProjectList,
    cleanProjectListPage,
    projectSetSort,
    projectSetPage,
};

export const ProjectList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectListComponent)));