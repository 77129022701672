import {createSelector} from 'reselect'

const getPending = state => state.crmSystem.account.create.pending;

export const selectCrmAccountCreateIsLoading = createSelector(
  [
    getPending,
    (state, crm) => crm,
  ], (pending, crm) => {
    return pending.includes(crm);
  });