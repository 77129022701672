import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr";
import { english } from "flatpickr/dist/l10n/default.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Dutch } from "flatpickr/dist/l10n/nl.js";

const DatePickerComponent = (props) => {
    const { t } = useTranslation();

    const [locale, setLocale] = useState(english);

    useEffect(() => {
        let locale = english;

        if (props.language === 'nl') {
            locale = Dutch;
        } else if (props.language === 'fr') {
            locale = French;
        }

        setLocale(locale);
    }, [props.language]);

    return (
        <Flatpickr
            value={props.value}
            onChange={date => {
                props.onChange(date);
            }}
            options={{
                disableMobile: true,
                dateFormat: "d/m/Y",
                ...props.options,
                locale: {
                    ...locale,
                    rangeSeparator: ' - ',
                },
            }}
            placeholder={props.placeholder}
            className="form-control"
            render={props.render || null}
        />
    );
};

const mapStateToProps = state => {
    return {
        language: state.common.language,
    };
};

const mapDispatchToProps = {
};

export const DatePicker = connect(mapStateToProps, mapDispatchToProps)(DatePickerComponent);