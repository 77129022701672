import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_CLIENT } from './actionTypes';
import {
    fetchClientSuccess,
    fetchClientError,
} from './actions';

import { API } from '../../../api/index'

function* fetchClient({ payload: { id } }) {
    try {
        const response = yield call(API.user.getUser, id);

        const { data } = response;
        yield put(fetchClientSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchClientError(message));
    }
}

export function* watchClientFetch() {
    yield takeEvery(FETCH_CLIENT, fetchClient)
}

function* clientProfileSaga() {
    yield all([
        fork(watchClientFetch),
    ]);
}

export default clientProfileSaga;