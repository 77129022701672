import axios from 'axios';
import qs from 'qs';

const paramsSerializer = (params) => {
    return qs.stringify(params, { encode: false });
};

class InviteAPI {
    getInviteReceivedList = async (params) => {
        return axios.get('api/links/received', {
            params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { encode: false });
            },
        });
    };

    sendInvite = async (params) => {
        return axios.post('api/links', params);
    };

    // rename to update invite status
    updateInvite = async (id, params) => {
        return axios.post(`api/links/${id}/status`, params);
    };

    getPropertyInvites = async (id, params) => {
        return axios.get(`api/properties/${id}/my-sent-links`, {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    getUsersFromReceivedInvites = async (params) => {
        return axios.get('api/links/received/users', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    getUsersFromSentInvites = async (params) => {
        return axios.get('api/links/sent/users', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    // rename to change invite
    consultantChangeInvite = async (id, params) => {
        return axios.put(`api/links/${id}`, params);
    };

    getCompaniesFromInvites = async (params) => {
        return axios.get('api/links/received/companies', { params });
    };

    getUsersFromCompanySentInvites = async (params) => {
        return axios.get('api/company/links/sent/users', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    getSentCompanyInvites = async (params) => {
        return axios.get('api/company/links/sent', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    updateInvitePriceStatus = async (id, params) => {
        return axios.post(`api/links/${id}/price-status`, params);
    };

    sendInviteSeenStatus = async (id) => {
        return axios.post(`api/links/${id}/seen`);
    };
}

export const invite = new InviteAPI();
