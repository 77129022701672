import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Row } from "reactstrap";

import { PhotoItem } from './PhotoItem';

const EditPropertyPhotosContainer = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
        };
    }, []);

    const renderImages = () => {
        return props.images.map((item, i) => {
            return (
                <PhotoItem item={item} key={item.id + 'edit-photo'} />
            );
        })
    };

    return (
        <>
            {props.images && !!props.images.length ? (
                <div id="photos-previews" >
                    <Row>
                        {renderImages()}
                    </Row>
                </div>
            ) : (
                <div className={'input-value-layout-align'}>{t('no_images')}</div>
            )}
        </>
    );
};

const mapStateToProps = state => {
    return {
        images: state.property.edit.propertyRemoteImages,
    };
};

const mapDispatchToProps = {
};

export const EditPropertyPhotos = connect(mapStateToProps, mapDispatchToProps)(EditPropertyPhotosContainer);