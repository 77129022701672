import { IMPORT_PROPERTY, IMPORT_PROPERTY_COMPLETED, IMPORT_PROPERTY_FAILED } from "./actionTypes";


const initialState = {
    isLoading: false,
};


const importProperty = (state = initialState, action) => {
    switch (action.type) {
        case IMPORT_PROPERTY:
            state = {
                ...state,
                isLoading: true,
            };
            break;

        case IMPORT_PROPERTY_COMPLETED:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case IMPORT_PROPERTY_FAILED:
            state = {
                ...state,
                isLoading: false
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default importProperty;