import {
    SUBSCRIPTION_TARIFF_MONTHLY_PERIOD,
    SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD,
} from '../../consts/billing/subscription';

export const getConfig = (value, t) => {
    let data = {
        label: '',
        value: '',
    };
    switch (value) {
        case SUBSCRIPTION_TARIFF_MONTHLY_PERIOD:
            data = {
                label: t('tariff.period.monthly'),
                value: SUBSCRIPTION_TARIFF_MONTHLY_PERIOD,
            };
            break;

        case SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD:
            data = {
                label: t('tariff.period.annually'),
                value: SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD,
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};

export default {
    getConfig,
};