import React, { useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import { withTranslation, useTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { setLanguage } from "../../../store/actions";
import { API } from '../../../api';

import { languages } from '../../../helpers';

const LanguageDropdown = (props) => {
    const { i18n } = useTranslation();

    const [menu, setMenu] = useState(false);

    const getLang = (code) => {
        return languages.find(item => item.code === code);
    };

    function changeLanguageAction(e, lng) {
        e.preventDefault();

        if (lng !== props.language) {
            i18n.changeLanguage(lng);

            props.setLanguage(lng);
            API.setLang(lng);
        }
    }

    const renderLangs = () => {
        return languages.map((item, index) => {
            return (
                <DropdownItem key={'lng-' + index} tag="a" href="#" onClick={(e) => changeLanguageAction(e, item.code)} className="notify-item">
                    <img src={item.flag} alt="" className="mr-1" height="12" />
                    <span className="align-middle">{item.title}</span>
                </DropdownItem>
            )
        })
    };

    return (
        <React.Fragment>
            {props.language && (
                <Dropdown
                    isOpen={menu}
                    toggle={() => setMenu(!menu)}
                    className="d-inline-block"
                >
                    <DropdownToggle
                        className="btn header-item waves-effect"
                        tag="button"
                    >
                        <img
                            src={getLang(props.language).flag}
                            height="16"
                            className="mr-1"
                        />
                        <span className="align-middle">{getLang(props.language).title}</span>
                    </DropdownToggle>
                    <DropdownMenu className="language-switch" right>
                        {renderLangs()}
                    </DropdownMenu>
                </Dropdown>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        language: state.common.language,
    };
};

const mapDispatchToProps = {
    setLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LanguageDropdown));