import {
    FETCH_RECEIVED_ACTIVE_PROPERTY_LIST,
    FETCH_RECEIVED_ACTIVE_PROPERTY_LIST_SUCCESS,
    FETCH_RECEIVED_ACTIVE_PROPERTY_LIST_ERROR,
    CLEAN_RECEIVED_ACTIVE_PROPERTY_LIST,

    RECEIVED_ACTIVE_PROPERTY_LIST_SET_PAGE,

    RECEIVED_ACTIVE_PROPERTY_LIST_SET_SORT,

    RECEIVED_ACTIVE_PROPERTY_LIST_SET_FILTER,
    RECEIVED_ACTIVE_PROPERTY_LIST_RESET_FILTER,


    FETCH_RECEIVED_INACTIVE_PROPERTY_LIST,
    FETCH_RECEIVED_INACTIVE_PROPERTY_LIST_SUCCESS,
    FETCH_RECEIVED_INACTIVE_PROPERTY_LIST_ERROR,
    CLEAN_RECEIVED_INACTIVE_PROPERTY_LIST,

    RECEIVED_INACTIVE_PROPERTY_LIST_SET_PAGE,

    RECEIVED_INACTIVE_PROPERTY_LIST_SET_SORT,

    RECEIVED_INACTIVE_PROPERTY_LIST_SET_FILTER,
    RECEIVED_INACTIVE_PROPERTY_LIST_RESET_FILTER,
} from './actionTypes';

import { INVITES_FILTER_INITIAL_VALUES } from '../../../consts/invite';

const initialState = {
    receivedActive: [],
    receivedActiveLoading: false,
    receivedActiveError: null,
    receivedActivePage: 1,
    receivedActiveMeta: null,

    receivedActiveSort: [],
    receivedActiveFilter: {
        isActive: false,
        values: INVITES_FILTER_INITIAL_VALUES,
    },

    receivedInactive: [],
    receivedInactiveLoading: false,
    receivedInactiveError: null,
    receivedInactivePage: 1,
    receivedInactiveMeta: null,

    receivedInactiveSort: [],
    receivedInactiveFilter: {
        isActive: false,
        values: INVITES_FILTER_INITIAL_VALUES,
    },
};

const received = (state = initialState, action) => {
    switch (action.type) {

        // ACTIVE PROPERTY
        case FETCH_RECEIVED_ACTIVE_PROPERTY_LIST:
            state = {
                ...state,
                receivedActiveLoading: true,
                receivedActiveError: null,
            };
            break;

        case FETCH_RECEIVED_ACTIVE_PROPERTY_LIST_SUCCESS:
            state = {
                ...state,
                receivedActiveLoading: false,
                receivedActive: action.payload.data,
                receivedActiveMeta: action.payload.meta,
            };
            break;

        case FETCH_RECEIVED_ACTIVE_PROPERTY_LIST_ERROR:
            state = {
                ...state,
                receivedActiveLoading: false,
                receivedActiveError: action.payload,
            };
            break;

        case CLEAN_RECEIVED_ACTIVE_PROPERTY_LIST:
            state = {
                ...state,
                receivedActive: [],
                receivedActiveLoading: false,
                receivedActiveError: null,
                receivedActiveSort: [],
                receivedActivePage: initialState.receivedActivePage,
                receivedActiveMeta: null,
            };
            break;

        // ACTIVE PROPERTY PAGE
        case RECEIVED_ACTIVE_PROPERTY_LIST_SET_PAGE:
            state = {
                ...state,
                receivedActivePage: action.payload,
            };
            break;

        // ACTIVE PROPERTY SORT
        case RECEIVED_ACTIVE_PROPERTY_LIST_SET_SORT:
            state = {
                ...state,
                receivedActiveSort: action.payload,
                receivedActivePage: initialState.receivedActivePage,
            };
            break;

        // ACTIVE PROPERTY FILTER
        case RECEIVED_ACTIVE_PROPERTY_LIST_SET_FILTER:
            state = {
                ...state,
                receivedActiveFilter: {
                    isActive: true,
                    values: {
                        ...initialState.receivedActiveFilter.values,
                        ...action.payload,
                    },
                },
                receivedActivePage: initialState.receivedActivePage,
            };
            break;

        case RECEIVED_ACTIVE_PROPERTY_LIST_RESET_FILTER:
            state = {
                ...state,
                receivedActiveFilter: initialState.receivedActiveFilter,
                receivedActivePage: initialState.receivedActivePage,
            };
            break;


        // INACTIVE PROPERTY
        case FETCH_RECEIVED_INACTIVE_PROPERTY_LIST:
            state = {
                ...state,
                receivedInactiveLoading: true,
                receivedInactiveError: null,
            };
            break;

        case FETCH_RECEIVED_INACTIVE_PROPERTY_LIST_SUCCESS:
            state = {
                ...state,
                receivedInactiveLoading: false,
                receivedInactive: action.payload.data,
                receivedInactiveMeta: action.payload.meta,
            };
            break;

        case FETCH_RECEIVED_INACTIVE_PROPERTY_LIST_ERROR:
            state = {
                ...state,
                receivedInactiveLoading: false,
                receivedInactiveError: action.payload,
            };
            break;

        case CLEAN_RECEIVED_INACTIVE_PROPERTY_LIST:
            state = {
                ...state,
                receivedInactive: [],
                receivedInactiveLoading: false,
                receivedInactiveError: null,
                receivedInactiveSort: [],
                receivedInactivePage: initialState.receivedInactivePage,
                receivedInactiveMeta: null,
            };
            break;

        // INACTIVE PROPERTY PAGE
        case RECEIVED_INACTIVE_PROPERTY_LIST_SET_PAGE:
            state = {
                ...state,
                receivedInactivePage: action.payload,
            };
            break;

        // INACTIVE PROPERTY SORT
        case RECEIVED_INACTIVE_PROPERTY_LIST_SET_SORT:
            state = {
                ...state,
                receivedInactiveSort: action.payload,
                receivedInactivePage: initialState.receivedInactivePage,
            };
            break;

        // INACTIVE PROPERTY FILTER
        case RECEIVED_INACTIVE_PROPERTY_LIST_SET_FILTER:
            state = {
                ...state,
                receivedInactiveFilter: {
                    isActive: true,
                    values: {
                        ...initialState.receivedInactiveFilter.values,
                        ...action.payload,
                    },
                },
                receivedInactivePage: initialState.receivedInactivePage,
            };
            break;

        case RECEIVED_INACTIVE_PROPERTY_LIST_RESET_FILTER:
            state = {
                ...state,
                receivedInactiveFilter: initialState.receivedInactiveFilter,
                receivedInactivePage: initialState.receivedInactivePage,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default received;