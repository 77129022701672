import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

const Footer = (props) => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                       <Col className={"d-flex justify-content-end"}>
                           {new Date().getFullYear()} © Brikk.
                       </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { footerRowDirection } = state.Layout;
    return {
        footerRowDirection
    };
};

export default connect(mapStateToProps, {})(Footer);