import React, { useCallback, useMemo } from 'react';
import { Alert, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link, useRouteMatch, withRouter } from 'react-router-dom';
import _ from 'lodash';

import { ROLE_CLIENT, ROLE_CONSULTANT, ROLE_MANAGER } from '../../../../../../consts/roles';
import { useAllowed } from '../../../../../../hooks';
import { propertySetPage, propertySetSort } from '../../../../../../store/actions';
import { PropertyFilter, PropertyListTable } from '../../../index';
import { Pagination, SearchInput } from '../../../../../../components/Custom';
import { middlewareHelper } from '../../../../../../helpers';

import * as HOC from '../../../../../../HOC';
import { ImportProperties } from "../../../ImportProperties";

const TableDataLoading = HOC.withTableDataLoading();

const { propertyMiddleware } = middlewareHelper

const mapColumnsConfig = (user) => {
    const isClient = user.role === ROLE_CLIENT
    const isConsultant = user.role === ROLE_CONSULTANT
    const isManager = user.role === ROLE_MANAGER

    return [
        { key: "avatar" },
        { key: "vendor", hidden: !isClient },
        { key: "project" },
        { key: "city" },
        { key: "address" },
        { key: "unit" },
        { key: "price" },
        { key: "status" },
        { key: "enabled", hidden: isClient || isConsultant && !propertyMiddleware.manageIsAllow(user)},
        { key: "favorite", hidden: !isClient },
        { key: "favorites_count", hidden: isClient },
        { key: "invites", hidden: isClient },
    ]
}

const PropertyListTableViewComponent = (props) => {
    let { url } = useRouteMatch();

    let isManager = useAllowed([ROLE_MANAGER], props.user.role);

    const filterWarningVisible = useMemo(() => {
        return (props.filterIsActive || props.search) && props.list.length === 0 && !props.listLoading;
    }, [props.filterIsActive, props.list, props.listLoading, props.search]);

    const handleSort = useCallback(sortBy => {
        if (!_.isEqual(sortBy, props.sort)) {
            props.propertySetSort(sortBy);
        }
    }, [props.sort]);

    const handlePageChange = (item) => {
        props.propertySetPage(item.selected + 1);
    };

    const handleSearchChange = (value) => {
        props.propertySetPage(1);
        props.onSearchChange(value);
    };

    const getTableKeys = useCallback(() => {
        return mapColumnsConfig(props.user).filter(item => !item.hidden).map(item => item.key)
    }, [props.user])

    return (
        <Row>
            <Col lg="12">
                <Card>
                    <CardHeader className="bg-transparent">
                        <Row className="no-gutters justify-content-between">
                            <h5 className={'mt-2 mr-2'}>{props.t('property.full.list')}</h5>

                            <div className="button-items">
                                {isManager && <ImportProperties />}

                                <PropertyFilter />

                                {propertyMiddleware.manageIsAllow(props.user) && (
                                    <Link to={`${url}/create`} className="btn btn-primary">
                                        {props.t('create.new')}
                                        <i className="fas fa-angle-right font-size-16 align-middle ml-2" />
                                    </Link>
                                )}
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row className={'no-gutters justify-content-between'}>
                            <SearchInput
                                onChange={handleSearchChange}
                                searchFields={
                                    ['search.field.address', 'search.field.unit']
                                }
                                hintExample={'Koningsstraat'}
                            />
                        </Row>

                        <TableDataLoading isLoading={props.listLoading} isFailed={props.listError} error={props.t(props.listError)}>
                            {filterWarningVisible ? (
                                <Alert color="primary">{props.t('property.filter.empty')}</Alert>
                            ) : (
                                <>
                                    <PropertyListTable
                                        keys={getTableKeys()}
                                        items={props.list}
                                        onSort={handleSort}
                                        isLoading={props.listLoading}
                                    />

                                    <Pagination data={props.meta} onPageChange={handlePageChange} />
                                </>
                            )}
                        </TableDataLoading>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError, filter, filterIsActive, sort, meta, page } = state.property.list;
    return {
        user: state.user.data,
        list, listLoading, listError,
        filter,
        filterIsActive,
        sort,
        meta,
        page,
    };
};

const mapDispatchToProps = {
    propertySetSort,
    propertySetPage,
};

export const PropertyListTableView = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyListTableViewComponent)));