import React from 'react';

const ButtonLoadingIcon = ({ loading }) => {
    return (
        <>
            {loading ? (
                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
            ) : null}
        </>
    );
};

export default ButtonLoadingIcon;