import React from 'react';
import { Row, Col, Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from 'redux-form'

import {
    required as requiredValidator,
    number as numberValidator,
    minValue as minValueValidator,
    maxValue as maxValueValidator,
    length as lengthValidator,
    email as emailValidator,
} from "../../../../utils/validations";
import { FORM_BILLING_INFO_EDIT } from "../../../../consts/forms";
import TextInput from "../../../../components/Common/TextInput";
import ButtonLoadingIcon from '../../../../components/Common/ButtonLoadingIcon';
import { phone as formatPhone } from "../../../../utils/normalize";

const BillingInfoEditFormComponent = (props) => {
    return (
        <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="form-horizontal"
            noValidate={true}
        >
            <Field
                name="email"
                label={props.t('field.label.email')}
                placeholder={props.t('field.placeholder.email')}
                id={'email'}
                type="email"
                component={TextInput}
                validate={[ requiredValidator, emailValidator ]}
                row
                disabled={props.isDisabled}
            />

            <Field
                name="first_name"
                label={props.t('field.label.firstName')}
                placeholder={props.t('field.placeholder.firstName')}
                id={'first_name'}
                type="text"
                component={TextInput}
                validate={[ requiredValidator ]}
                row
                disabled={props.isDisabled}
            />

            <Field
                name="last_name"
                label={props.t('field.label.lastName')}
                placeholder={props.t('field.placeholder.lastName')}
                id={'last_name'}
                type="text"
                component={TextInput}
                validate={[ requiredValidator ]}
                row
                disabled={props.isDisabled}
            />

            <Field
                name="company"
                label={props.t('field.label.company')}
                placeholder={props.t('field.placeholder.company')}
                id={'company'}
                type="text"
                component={TextInput}
                validate={[ requiredValidator ]}
                row
                disabled={props.isDisabled}
            />

            <Field
                name="phone"
                label={props.t('field.label.phone')}
                placeholder={props.t('field.placeholder.phone')}
                id={'phone'}
                type="text"
                component={TextInput}
                normalize={formatPhone}
                validate={[ requiredValidator ]}
                row
                disabled={props.isDisabled}
            />

            <Field
                name="address"
                label={props.t('field.label.billing.address')}
                placeholder={props.t('field.placeholder.billing.address')}
                id={'address'}
                type="text"
                component={TextInput}
                validate={[ requiredValidator ]}
                row
                disabled={props.isDisabled}
            />

            <Field
                name="city"
                label={props.t('field.label.billing.city')}
                placeholder={props.t('field.placeholder.billing.city')}
                id={'city'}
                type="text"
                component={TextInput}
                validate={[ requiredValidator ]}
                row
                disabled={props.isDisabled}
            />

            <Field
                name="zip"
                label={props.t('field.label.billing.zip_code')}
                placeholder={props.t('field.placeholder.billing.zip_code')}
                id={'zip'}
                type="text"
                component={TextInput}
                validate={[ requiredValidator, numberValidator ]}
                row
                mode={'number'}
                disabled={props.isDisabled}
            />

            <Row className="justify-content-end">
                <Col lg="10">
                    <Row className="no-gutters justify-content-between">
                        <div className="button-items">
                            {props.disabledMode && (
                                <>
                                    {props.isFormActive ? (
                                        <Button
                                            type="button" color="light" outline className="waves-effect"
                                            onClick={() => {
                                                props.toggleActiveForm();
                                                props.reset(FORM_BILLING_INFO_EDIT);
                                            }}
                                        >
                                            {props.t('cancel')}
                                        </Button>
                                    ) : (
                                        <Button
                                            type="button" color="light" outline className="waves-effect"
                                            onClick={props.toggleActiveForm}
                                        >
                                            {props.t('edit')}
                                        </Button>
                                    )}
                                </>
                            )}

                            {props.isFormActive && (
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={props.isDisabled}
                                >
                                    <ButtonLoadingIcon loading={props.isLoading} />
                                    {props.t('save')}
                                </Button>
                            )}
                        </div>
                    </Row>
                </Col>
            </Row>
        </form>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
};

const BillingInfoEdit = reduxForm({
    form: FORM_BILLING_INFO_EDIT,
    enableReinitialize: true,
})(BillingInfoEditFormComponent);

export const BillingInfoEditForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingInfoEdit)));