import {
    FETCH_BILLING_INVOICE,
    FETCH_BILLING_INVOICE_SUCCESS,
    FETCH_BILLING_INVOICE_ERROR,
    CLEAN_BILLING_INVOICE,
} from './actionTypes';

export const fetchBillingInvoice = (id) => {
    return {
        type: FETCH_BILLING_INVOICE,
        payload: { id },
    }
};

export const fetchBillingInvoiceSuccess = (data) => {
    return {
        type: FETCH_BILLING_INVOICE_SUCCESS,
        payload: data,
    }
};

export const fetchBillingInvoiceError = (data) => {
    return {
        type: FETCH_BILLING_INVOICE_ERROR,
        payload: data,
    }
};

export const cleanBillingInvoice = () => {
    return { type: CLEAN_BILLING_INVOICE };
};