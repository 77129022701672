import { combineReducers } from "redux";

import profileReducer from './profile/reducer';
import inviteReducer from './invite/reducer';

const clientReducer = combineReducers({
    profile: profileReducer,
    invite: inviteReducer,
});

export default clientReducer;
