import { combineReducers } from "redux";

import viewReducer from './view/reducer';
import updateReducer from './update/reducer';

const preferencesReducer = combineReducers({
    view: viewReducer,
    update: updateReducer,
});

export default preferencesReducer;