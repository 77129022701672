import {
    FETCH_USER_INFO_AND_DATA,
    FETCH_USER_INFO_AND_DATA_SUCCESS,
    FETCH_USER_INFO_AND_DATA_ERROR,
    CLEAN_USER_INFO_AND_DATA,
} from './actionTypes';

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const userData = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_INFO_AND_DATA:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case FETCH_USER_INFO_AND_DATA_SUCCESS:
            state = {
                ...state,
                loading: false,
                data: action.payload,
            };
            break;

        case FETCH_USER_INFO_AND_DATA_ERROR:
            state = {
                ...state,
                data: null,
                loading: false,
                error: action.payload,
            };
            break;

        case CLEAN_USER_INFO_AND_DATA:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default userData;