import React from 'react';
import {connect} from "react-redux";
import {useTranslation} from 'react-i18next';

import {RegisterContainer} from './components';
import {useTitle} from "../../hooks";
import {ROLE_CLIENT} from "../../consts/roles";
import {registerUser, registerUserClean, socialRegisterUser} from "../../store/actions";

const RegisterComponent = (props) => {
  const {t} = useTranslation();

  useTitle(t('title.signup'));

  return (
    <RegisterContainer role={ROLE_CLIENT}/>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {registerUser, registerUserClean, socialRegisterUser})(RegisterComponent);
