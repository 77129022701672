import {
    FETCH_RECEIVED_ACTIVE_PROPERTY_LIST,
    FETCH_RECEIVED_ACTIVE_PROPERTY_LIST_SUCCESS,
    FETCH_RECEIVED_ACTIVE_PROPERTY_LIST_ERROR,
    CLEAN_RECEIVED_ACTIVE_PROPERTY_LIST,

    RECEIVED_ACTIVE_PROPERTY_LIST_SET_PAGE,

    RECEIVED_ACTIVE_PROPERTY_LIST_SET_SORT,

    RECEIVED_ACTIVE_PROPERTY_LIST_SET_FILTER,
    RECEIVED_ACTIVE_PROPERTY_LIST_RESET_FILTER,

    FETCH_RECEIVED_INACTIVE_PROPERTY_LIST,
    FETCH_RECEIVED_INACTIVE_PROPERTY_LIST_SUCCESS,
    FETCH_RECEIVED_INACTIVE_PROPERTY_LIST_ERROR,
    CLEAN_RECEIVED_INACTIVE_PROPERTY_LIST,

    RECEIVED_INACTIVE_PROPERTY_LIST_SET_PAGE,

    RECEIVED_INACTIVE_PROPERTY_LIST_SET_SORT,

    RECEIVED_INACTIVE_PROPERTY_LIST_SET_FILTER,
    RECEIVED_INACTIVE_PROPERTY_LIST_RESET_FILTER,
} from './actionTypes';

// FETCH RECEIVED ACTIVE PROPERTY
export const fetchReceivedActivePropertyList = (params) => {
    return {
        type: FETCH_RECEIVED_ACTIVE_PROPERTY_LIST,
        payload: { params }
    }
};

export const fetchReceivedActivePropertyListSuccess = (data) => {
    return {
        type: FETCH_RECEIVED_ACTIVE_PROPERTY_LIST_SUCCESS,
        payload: data,
    }
};

export const fetchReceivedActivePropertyListError = (data) => {
    return {
        type: FETCH_RECEIVED_ACTIVE_PROPERTY_LIST_ERROR,
        payload: data,
    }
};

export const cleanReceivedActivePropertyList = () => {
    return { type: CLEAN_RECEIVED_ACTIVE_PROPERTY_LIST };
};

// SET ACTIVE PROPERTY PAGE
export const receivedActivePropertyListSetPage = (data) => {
    return {
        type: RECEIVED_ACTIVE_PROPERTY_LIST_SET_PAGE,
        payload: data,
    }
};

// SET ACTIVE PROPERTY SORTING
export const receivedActivePropertyListSetSort = (data) => {
    return {
        type: RECEIVED_ACTIVE_PROPERTY_LIST_SET_SORT,
        payload: data,
    }
};


// SET ACTIVE PROPERTY FILTER
export const receivedActivePropertyListSetFilter = (data) => {
    return {
        type: RECEIVED_ACTIVE_PROPERTY_LIST_SET_FILTER,
        payload: data,
    }
};

export const receivedActivePropertyListResetFilter = () => {
    return { type: RECEIVED_ACTIVE_PROPERTY_LIST_RESET_FILTER };
};


// FETCH RECEIVED INACTIVE PROPERTY
export const fetchReceivedInactivePropertyList = (params) => {
    return {
        type: FETCH_RECEIVED_INACTIVE_PROPERTY_LIST,
        payload: { params }
    }
};

export const fetchReceivedInactivePropertyListSuccess = (data) => {
    return {
        type: FETCH_RECEIVED_INACTIVE_PROPERTY_LIST_SUCCESS,
        payload: data,
    }
};

export const fetchReceivedInactivePropertyListError = (data) => {
    return {
        type: FETCH_RECEIVED_INACTIVE_PROPERTY_LIST_ERROR,
        payload: data,
    }
};

export const cleanReceivedInactivePropertyList = () => {
    return { type: CLEAN_RECEIVED_INACTIVE_PROPERTY_LIST };
};

// SET INACTIVE PROPERTY PAGE
export const receivedInactivePropertyListSetPage = (data) => {
    return {
        type: RECEIVED_INACTIVE_PROPERTY_LIST_SET_PAGE,
        payload: data,
    }
};

// SET INACTIVE PROPERTY SORTING
export const receivedInactivePropertyListSetSort = (data) => {
    return {
        type: RECEIVED_INACTIVE_PROPERTY_LIST_SET_SORT,
        payload: data,
    }
};

// SET INACTIVE PROPERTY FILTER
export const receivedInactivePropertyListSetFilter = (data) => {
    return {
        type: RECEIVED_INACTIVE_PROPERTY_LIST_SET_FILTER,
        payload: data,
    }
};

export const receivedInactivePropertyListResetFilter = () => {
    return { type: RECEIVED_INACTIVE_PROPERTY_LIST_RESET_FILTER };
};