import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';

export const OfferPriceValue = ({ offerPrice, price, reverse = false, textClass = 'font-size-13' }) => {
    const config = useMemo(() => {
        if (offerPrice === null || price === null) {
            return { color: ''};
        }

        const color = offerPrice < price ? 'text-success' : offerPrice > price ? 'text-danger' : '';

        return { color };
    }, [offerPrice, price, reverse]);

    return (
        <>
            {offerPrice !== null ? (
                <NumberFormat
                    value={offerPrice}
                    thousandSeparator={' '}
                    prefix={'€ '}
                    displayType={'text'}
                    renderText={(formattedValue) => (
                        <span className={`text-nowrap ` + (config.color) + ` ${textClass}`}>
                            {formattedValue}
                        </span>
                    )}
                />
            ) : '-'}
        </>
    );
};