import React, {useEffect, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {useDebounce} from '../../../hooks';
import {Input} from "reactstrap";
import classnames from "classnames";

export const SearchInput = ({
                                searchValue,
                              placeholder = 'search',
                              delay = 1000,
                              onChange,
                              disableInitialCallback = true,
                              minLimit = 3,
                              hint = true,
                              searchFields = [],
                              hintExample = '',
                              inputClass = "bg-brand-bg",
                            }) => {
  const {t} = useTranslation();

  const [value, setValue] = useState('');

  const debouncedValue = useDebounce(value, delay);

  const disableInitialStatusRef = useRef(disableInitialCallback);

  useEffect(() => {
    if (!disableInitialStatusRef.current) {
      let searchValue = debouncedValue.trim();

      if (searchValue.length < minLimit) {
        searchValue = null;
      }

      onChange(searchValue);
    }

    disableInitialStatusRef.current = false;
  }, [debouncedValue]);

  useEffect(() => {
      if (searchValue) {
          setValue(searchValue)
      } else {
          setValue("")
      }
  }, [searchValue])

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const renderSearchHintFields = () => {
    return searchFields
        .map(item => t(item))
        .join(', ');
  };

  const leftAccessory = () => (
      <div className={"form-left-accessory"}>
        <i className={"mdi mdi-magnify font-size-16"}/>
      </div>
  )

  return (
      <div className={classnames("app-search")}>
        <div className={'d-flex'}>
          <div className={"input-group"}>
            {leftAccessory()}
            <Input
                type="text"
                className={classnames("form-control form-control-left-accessory rounded-lg", {
                      [inputClass]: inputClass
                    }
                )}
                placeholder={`${t(placeholder)} ...`}
                onChange={handleChange}
                value={value}
            />
          </div>
        </div>
        {debouncedValue && debouncedValue.length < minLimit && (
            <div className={'search-hint text-danger font-weight-bold mt-1'}>{t('search.hint', {count: minLimit})}</div>
        )}
        {hint && (
            <div className={'search-hint text-muted mt-1'}>
              {t('search.hint.fields')} {renderSearchHintFields()}. {t('search.hint.enter')} {t('search.hint.for_example', {example: hintExample})}
            </div>
        )}
      </div>
  );
};