import {
    FETCH_SUBSCRIPTION_TARIFFS,
    FETCH_SUBSCRIPTION_TARIFFS_SUCCESS,
    FETCH_SUBSCRIPTION_TARIFFS_ERROR,
    CLEAN_SUBSCRIPTION_TARIFFS,
} from './actionTypes';

export const fetchSubscriptionTariffs = () => {
    return {
        type: FETCH_SUBSCRIPTION_TARIFFS,
    }
};

export const fetchSubscriptionTariffsSuccess = (data) => {
    return {
        type: FETCH_SUBSCRIPTION_TARIFFS_SUCCESS,
        payload: data,
    }
};

export const fetchSubscriptionTariffsError = (data) => {
    return {
        type: FETCH_SUBSCRIPTION_TARIFFS_ERROR,
        payload: data,
    }
};

export const cleanSubscriptionTariffs = () => {
    return { type: CLEAN_SUBSCRIPTION_TARIFFS };
};