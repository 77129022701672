import { takeLatest, fork, put, all, call, select } from 'redux-saga/effects';

import { FETCH_CLIENTS_ADDED_PROPERTY_FAVORITE } from './actionTypes';
import {
    fetchClientsAddedFavoriteSuccess,
    fetchClientsAddedFavoriteError,
} from './actions';

import { API } from '../../../../api';
import { getMapRequestParams } from '../../../../utils/request';
import { ROLE_MANAGER } from '../../../../consts/roles';

export const userRole = (state) => state.user.data.role;

function* fetchClients({ payload: { id, params } }) {
    try {
        const requestParams = getMapRequestParams(params);

        const role = yield select(userRole);
        const apiRequest = role === ROLE_MANAGER ? API.property.getAllClientsAddedPropertyFavorite : API.property.getClientsAddedPropertyFavoriteViaInvite;

        const response = yield call(apiRequest, id, requestParams);

        const { data } = response;
        yield put(fetchClientsAddedFavoriteSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchClientsAddedFavoriteError(message));
    }
}

export function* watchFavoritesFetch() {
    yield takeLatest(FETCH_CLIENTS_ADDED_PROPERTY_FAVORITE, fetchClients)
}

function* favoritesSaga() {
    yield all([
        fork(watchFavoritesFetch),
    ]);
}

export default favoritesSaga;