import { combineReducers } from "redux";

import listReducer from './list/reducer';
import viewReducer from './view/reducer';

const invoiceReducer = combineReducers({
    list: listReducer,
    view: viewReducer,
});

export default invoiceReducer;