import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';

import { COMPLETE_REGISTER_USER } from './actionTypes';
import {
    completeRegisterUserSuccess,
    completeRegisterUserError,
} from './actions';
import { updateUser } from '../../user/actions';

import { FORM_SIGN_UP } from '../../../consts/forms';

import { API } from '../../../api'

function* completeRegisterUser({ payload: { params } }) {
    try {
        const response = yield call(API.auth.socialRegisterComplete, params);

        const { data } = response;

        yield put(completeRegisterUserSuccess(data));
        yield put(updateUser({ ...data?.data, filled: true }));
    } catch (error) {
        const errors = error.response.data.errors;
        const message = error.response.data.message ? error.response.data.message : 'Server Error';
        yield put(completeRegisterUserError(message));

        if (errors) {
            yield put(stopSubmit(FORM_SIGN_UP, errors));
        }
    }
}

export function* watchUserCompleteRegister() {
    yield takeEvery(COMPLETE_REGISTER_USER, completeRegisterUser);
}

function* completeRegisterSaga() {
    yield all([
        fork(watchUserCompleteRegister),
    ]);
}

export default completeRegisterSaga;