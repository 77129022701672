import React, { useEffect } from 'react';
import { Card, CardBody } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './PropertyGrid.scss';
import { PropertyCard } from './PropertyCard';
import { PropertyGridWrapper } from "../PropertyGridWrapper";


const PropertyGridComponent = (props) => {

    useEffect(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })

    }, [props.page])

    return (
        <>
            {props.list.length === 0 && props.isFetched ? (
                <Card>
                    <CardBody>
                        {props.t('empty')}
                    </CardBody>
                </Card>
            ) : (
                <PropertyGridWrapper page={props.page}>
                    <div className={"property-grid-container"}>
                        {props.list.map(item => (
                            <PropertyCard
                                key={`property-${item.id}`}
                                item={item}
                            />
                        ))}
                    </div>
                </PropertyGridWrapper>
            )}
        </>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export const PropertyGrid = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyGridComponent)));