export const LEAD_STATUS_UNPAID = 'unpaid';
export const LEAD_STATUS_PAID = 'paid';
export const LEAD_STATUS_CLOSED = 'closed';
export const LEAD_STATUS_DECLINED = 'declined';

export const COMPANY_LEADS_TAB_ALL_LEADS = 'TAB_ALL_LEADS';
export const COMPANY_LEADS_TAB_MY_LEADS = 'TAB_MY_LEADS';
export const COMPANY_LEADS_TAB_NOT_INTERESTED = 'TAB_NOT_INTERESTED';

export const leadsDefaultColumns = ['date', 'type', 'price', 'regions', 'status'];
export const leadsUnpaidColumns = ['date', 'type', 'price', 'regions', 'status', 'buy'];
export const companyPaidLeadsColumns = ['date', 'client', 'type', 'price', 'regions', 'status'];
export const companyDeclinedLeadsColumns = [...leadsDefaultColumns, 'buy'];
export const consultantLeadsColumns = ['date', 'client', 'type', 'price', 'regions', 'status'];

export const LEAD_COST_IN_CREDIT = 1;