import React, { useEffect, useRef, useState } from 'react';

const popupWidth = 600;
const popupHeight = 600;

const REDIRECT_URL = process.env.REACT_APP_OAUTH_REDIRECT_BASE_URL + 'auth/social';

export const useOAuthPopup = ({ onSuccess }) => {
    const [isOpen, setIsOpen] = useState(false);

    const popupRef = useRef(null);
    const intervalRef = useRef(null);

    useEffect(() => {
        return () => {
            // closePopup();
        }
    }, []);

    // handle data after oauth redirect
    const handleData = (data) => {
        const query = new URLSearchParams(data.location.search);

        let result = {};

        for (let params of query.entries()) {
            result[params[0]] = params[1];
        }

        onSuccess(result);

        closePopup();
    };

    const handlePostMessage = (event) => {
        if (event.origin !== window.location.origin) {
            return;
        }

        handleData(event.data);
    };

    const focusPopup = () => {
        if (popupRef.current) {
            popupRef.current.focus();
        }
    };

    const openPopup = (url) => {
        if (popupRef.current) {
            popupRef.current.focus();
            popupRef.current.location = url;
        } else {
            createPopup(url);

            window.addEventListener("message", handlePostMessage, false);

            checkPopup();
        }
    };

    const closePopup = () => {
        window.removeEventListener("message", handlePostMessage);

        if (popupRef.current) {
            popupRef.current.close();
            popupRef.current = null;
        }

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }

        // todo: check is mounted
        setIsOpen(false);
    };

    const createPopup = (url) => {
        const width = window.innerWidth < popupWidth ? window.innerWidth : popupWidth;
        const height = window.innerHeight < popupHeight ? window.innerHeight : popupHeight;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);

        popupRef.current = window.open(url, '',
            `toolbar=no, location=no, directories=no, status=no, menubar=no,
              scrollbars=no, resizable=no, copyhistory=no, width=${width},
              height=${height}, top=${top}, left=${left}`
        );

        if (popupRef.current) {
        }

        // popupRef.current.addEventListener('beforeunload', () => {
        // }, false);

        setIsOpen(true);
    };

    const checkPopup = () => {
        intervalRef.current = setInterval(() => {
            if (!popupRef.current || popupRef.current.closed || popupRef.current.closed === undefined) {
                clearInterval(intervalRef.current);

                closePopup();
            }
        }, 1000);
    };

    return { openPopup, focusPopup, closePopup, isOpen };
};