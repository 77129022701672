import axios from 'axios';

import { auth, user, project, consultant, property, common, invite, statistics, referral, social, chat, attachment, propertyRequest, lead, billing, crmSystem } from '../requests';

import configureStore from '../../store';
import { logoutUserSuccess } from "../../store/auth/login/actions";

const { store } = configureStore();

const BASE_URL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(
    config => {
        return {
            ...config,
            baseURL: BASE_URL,
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', ...config.headers },
        };
    },
    error => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    (error) => {
        if (error.message === 'Network Error') {
            console.log('Network Error');
        }
        if (error.response?.status) {
            switch (error.response?.status) {
                case 401:
                    console.log('Error - 401');

                    if (store.getState().Login.isLoggedIn) {
                        store.dispatch(logoutUserSuccess(''));
                        window.location.href = '/';
                    }

                    break;
                case 400:
                    console.log('Error - 400');
                    break;
                case 419:
                    console.log('Error - 419');

                    store.dispatch(logoutUserSuccess(''));
                    window.location.href = '/';

                    break;

                default:
                    console.log('Server Error');
                    break;
            }
        }
        return Promise.reject(error);
    },
);

class APIService {
    auth = auth;
    user = user;
    project = project;
    consultant = consultant;
    property = property;
    common = common;
    invite = invite;
    statistics = statistics;
    referral = referral;
    social = social;
    chat = chat;
    attachment = attachment;
    propertyRequest = propertyRequest;
    lead = lead;
    billing = billing;
    crmSystem = crmSystem;

    setToken = (token) => {
        axios.defaults.headers['Access-Token'] = token;
    };

    clearToken = () => {
        axios.defaults.headers['Access-Token'] = null;
    };

    setLang = (lang) => {
        axios.defaults.headers['X-localization'] = lang;
    };

    clearLang = () => {
        axios.defaults.headers['X-localization'] = null;
    };
}

const API = new APIService();
export default API;