import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Card, Badge } from "reactstrap";

import { Header } from './Header';
import { MessageList } from './MessageList';
import { InputToolbar } from './InputToolbar';
import { unsetChatConversation, setBackgroundUnreadMessagesStatus, chatSendChatRead } from '../../../../../store/actions';
import { createWhisperChannel } from '../../../../../utils/chat/channel';
import { setPageTitle } from '../../../../../helpers';

const RoomComponent = (props) => {
    const messageListContainerRef = useRef(null);
    const [roomChannel, setRoomChannel] = useState(null);

    useEffect(() => {
        return () => {
            props.unsetChatConversation();
        }
    }, []);

    useEffect(() => {
        if (props.participant) {
            // create chat channel for typing
            const channel = createWhisperChannel(props.user.id, props.participant.id);
            setRoomChannel(channel);
        }

        return () => {}
    }, [props.participant]);

    // check app in background and received new messages
    useEffect(() => {
        if (props.backgroundUnreadMessagesStatus) {
            if (!props.appIsVisible) {
                setPageTitle(props.t('title.chat.new_messages'))
            } else {
                setPageTitle(props.t('title.chat'));
                props.setBackgroundUnreadMessagesStatus(false);

                // send read chat request
                props.chatSendChatRead(props.participant.id);
            }
        }
    }, [props.appIsVisible, props.backgroundUnreadMessagesStatus]);

    const scrollToBottom = () => {
        if (messageListContainerRef.current) {
            messageListContainerRef.current.scrollTop = messageListContainerRef.current.scrollHeight;
        }
    };

    const onSendSuccess = () => {
        scrollToBottom();
    };

    return (
        <div className="w-100 h-100 user-chat">
            <Card className={'h-100'}>
                {props.participant ? (
                    <div>
                        <Header roomChannel={roomChannel} />

                        <MessageList parentRef={messageListContainerRef} scrollToBottom={scrollToBottom} />

                        <InputToolbar onSendSuccess={onSendSuccess} roomChannel={roomChannel} />
                    </div>
                ) : (
                    <div className={'not-select-chat d-flex align-items-center justify-content-center'}>
                        <Badge pill className={'font-size-12 badge-soft-secondary'}>
                            {props.t('select.chat.hint')}
                        </Badge>
                    </div>
                )}
            </Card>
        </div>
    );
};

const mapStateToProps = state => {
    const { participant, backgroundUnreadMessagesStatus } = state.chat.conversation.data;
    const { data } = state.user;

    return {
        participant,
        backgroundUnreadMessagesStatus,
        user: data,
        appIsVisible: state.common.appIsVisible,
    };
};

const mapDispatchToProps = {
    unsetChatConversation,
    setBackgroundUnreadMessagesStatus,
    chatSendChatRead,
};

export const Room = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RoomComponent)));