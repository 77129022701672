import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useTable, useSortBy } from 'react-table';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';

import placeholder from '../../../../assets/images/custom/avatar-placeholder.png';
import { INVITE_STATUS_DEACTIVATED } from "../../../../consts/invite";
import { InviteStatusBadge, OfferPriceDiff, OfferStatusBadge } from '../../../../components/Custom';

const PropertyClientsTableComponent = (props) => {
    const { items, propertyPrice, manageAccess } = props;

    const data = useMemo(() => {
        return items;
    }, [items]);

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        let config = [{
            Header: '',
            accessor: 'avatar',
            id: 'avatar',
            Cell: ({ row: { original } }) => (
                <img src={original.receiver?.avatar ? original.receiver.avatar : placeholder} alt="" className="avatar-xs rounded-circle avatar-cover" />
            ),
            disableSortBy: true,
        }, {
            Header: props.t('name'),
            accessor: 'name',
            id: 'receiver_name',
            Cell: ({ row: { original } }) => {
                return <Link to={`/clients/${original.receiver.id}`} className="text-dark font-weight-bold">{original.receiver.name || '-'}</Link>;
            },
        }, {
            Header: props.t('email'),
            accessor: 'email',
            id: 'receiver_email',
            Cell: ({ row: { original } }) => {
                return <Link to={`/clients/${original.receiver.id}`} className="text-dark text-decoration-underline">{original.receiver.email}</Link>;
            },
        }, {
            Header: props.t('date.added'),
            accessor: 'created_at',
            id: 'created_at',
            Cell: ({ value }) => {
                return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
        }, {
            Header: () => (
                <div className={'w-100 text-center'}>{props.t('status')}</div>
            ),
            accessor: 'status',
            id: 'status',
            Cell: ({ value }) => {
                return (
                    <div className={'text-center'}>
                        <InviteStatusBadge status={value} />
                    </div>
                );
            },
        }, {
            Header: () => (
                <div className={'w-100 text-center'}>{props.t('difference')}</div>
            ),
            accessor: 'offer',
            id: 'difference',
            Cell: ({ row: { original } }) => (
                <div className={'text-center'}>
                    <div className={'mb-1'}>
                        <OfferPriceDiff offerPrice={original.price} price={propertyPrice} />
                    </div>
                    {original.price_status && <OfferStatusBadge status={original.price_status} />}
                </div>
            ),
        }];

        if (manageAccess) {
            config = [
                ...config,
                {
                    Header: '',
                    id: 'action',
                    Cell: ({ row: { original } }) => (
                        <div className={'text-right'}>
                            <div className="button-items">
                                <Button color="light" outline className={'btn waves-effect btn-rounded btn-sm'}
                                    onClick={() => props.onEdit(original)}
                                >
                                    <i className={'mdi mdi-pencil font-size-18 text-primary'} />
                                </Button>

                                {original.status === INVITE_STATUS_DEACTIVATED ? (
                                    <Button color="light" outline className={'btn waves-effect btn-rounded btn-sm'}
                                        onClick={() => props.onActivateInvite(original)}
                                    >
                                        <i className={'mdi mdi-account-plus-outline font-size-18 text-success'} />
                                    </Button>
                                ) : (
                                    <Button color="light" outline className={'btn waves-effect btn-rounded btn-sm'}
                                        onClick={() => props.onCloseInvite(original)}
                                    >
                                        <i className={'mdi mdi-close font-size-18 text-danger'} />
                                    </Button>
                                )}
                            </div>
                        </div>
                    ),
                    disableSortBy: true,
                },
            ];
        }

        setColumns(config);
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: props.initialSort,
            },
            defaultCanSort: false,
            disableSortBy: data.length === 0,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
        },
        useSortBy,
    );

    useEffect(() => {
        props.onSort(sortBy);
    }, [props.onSort, sortBy]);

    return (
        <div className="table-responsive table-centered">
            <Table className={'table-bordered table-part-bordered'} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps({ title: null }))}
                            >
                                <div className={'d-flex'}>
                                    <div className={'d-flex flex-fill'}>
                                        {column.render('Header')}
                                    </div>

                                    <div>
                                        {column.canSort && (
                                            column.isSorted ? (
                                                <i className={'bx font-size-16 align-middle' + (column.isSortedDesc ? " bx-sort-down" : " bx-sort-up")} />
                                            ) : <i className="bx bx-sort align-middle" style={{ color: '#adb5bd' }} />
                                        )}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {(
                    rows.length > 0 && rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                ) || (
                    <tr>
                        <td colSpan={columns.length}>
                            {!props.isLoading ? props.t('empty') : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}} />}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};

const mapStateToProps = state => {
    const { propertyClientsSort } = state.invite.lists;
    return {
        initialSort: propertyClientsSort,
    };
};

const mapDispatchToProps = {
};

export const PropertyClientsTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyClientsTableComponent)));