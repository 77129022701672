import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

import { useTitle } from "../../hooks";
import { UsersFilter, Pagination, SearchInput, PageTitle } from '../../components/Custom';
import {
    fetchConsultantList,
    cleanConsultantListPage,
    consultantSetSort,
    consultantSetFilter,
    consultantResetFilter,
    consultantSetPage,
} from '../../store/actions';
import { ConsultantsTable } from './components';

import * as HOC from '../../HOC';

const TableDataLoading = HOC.withTableDataLoading();

const ConsultantsListComponent = (props) => {
    useTitle(props.t('title.consultants'));
    let { url } = useRouteMatch();

    const [search, setSearch] = useState(null);

    useEffect(() => {
        let params = {
            page: props.page,
        };

        if (props.sort.length > 0) {
            params.sort = props.sort;
        }

        if (props.filter.isActive) {
            params.filter = props.filter.values;
        }

        if (search) {
            params.filter = {
                ...params.filter,
                keywords: search,
                search_fields: ['name', 'email', 'phone'],
            };
        }

        props.fetchConsultantList(params);

    }, [props.sort, props.filter, props.page, search]);

    useEffect(() => {
        return () => {
            props.cleanConsultantListPage();
            props.consultantResetFilter();
        }
    }, []);

    const filterWarningVisible = useMemo(() => {
        return (props.filter.isActive || search) && props.list.length === 0 && !props.listLoading;
    }, [props.filter, props.list, props.listLoading, search]);

    const handleSort = useCallback(sortBy => {
        if (!_.isEqual(sortBy, props.sort)) {
            props.consultantSetSort(sortBy);
        }
    }, [props.sort]);

    const handleResetFilter = () => {
        props.consultantResetFilter()
    };

    const handleApplyFilter = (values) => {
        props.consultantSetFilter(values);
    };

    const handlePageChange = (item) => {
        props.consultantSetPage(item.selected + 1);
    };

    const handleSearchChange = (value) => {
        props.consultantSetPage(1);
        setSearch(value);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('consultants')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <h5 className={'mt-2 mr-2'}>{props.t('consultants.all.list')}</h5>

                                        <div className="button-items">
                                            <UsersFilter
                                                filter={props.filter}
                                                onReset={handleResetFilter}
                                                onApply={handleApplyFilter}
                                            />

                                            <Link to={`${url}/create`} className="btn btn-primary">
                                                {props.t('create.new')}
                                                <i className="fas fa-angle-right font-size-16 align-middle ml-2"></i>
                                            </Link>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row className={'no-gutters justify-content-between'}>
                                        <SearchInput
                                            onChange={handleSearchChange}
                                            searchFields={
                                                ['search.field.name', 'search.field.email', 'search.field.phone']
                                            }
                                            hintExample={'John Doe'}
                                        />
                                    </Row>

                                    <TableDataLoading isLoading={props.listLoading} isFailed={props.listError} error={props.t(props.listError)}>
                                        {filterWarningVisible ? (
                                            <Alert color="primary">{props.t('consultants.filter.empty')}</Alert>
                                        ) : (
                                            <>
                                                <ConsultantsTable
                                                    items={props.list}
                                                    isLoading={props.listLoading}
                                                    onSort={handleSort}
                                                />

                                                <Pagination data={props.meta} onPageChange={handlePageChange} />
                                            </>
                                        )}
                                    </TableDataLoading>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError, sort, filter, meta, page } = state.consultant;
    return {
        list, listLoading, listError,
        sort,
        filter,
        meta,
        page,
    };
};

const mapDispatchToProps = {
    fetchConsultantList,
    cleanConsultantListPage,
    consultantSetSort,
    consultantSetFilter,
    consultantResetFilter,
    consultantSetPage,
};

export const ConsultantsList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConsultantsListComponent)));