import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from "react-router-dom";

import { FORM_SIGN_UP } from '../../../../consts/forms';
import ButtonLoadingIcon from '../../../../components/Common/ButtonLoadingIcon';
import TextInput from '../../../../components/Common/TextInput';
import { LanguageSelectField } from "../../../../components/Custom";
import { registerValidation } from '../../validations/validate';
import {
    required as requiredValidator,
} from "../../../../utils/validations";
import { ROLE_MANAGER } from "../../../../consts/roles";

const RegisterFormComponent = (props) => {
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="form-horizontal"
            noValidate={true}
        >
            <Field
                name="email"
                label={t('field.label.email')}
                placeholder={t('field.placeholder.email')}
                id={'email'}
                type="email"
                component={TextInput}
            />

            <Field
                name="name"
                label={t('field.label.fullname')}
                placeholder={t('field.placeholder.fullname')}
                id={'name'}
                type="text"
                component={TextInput}
            />

            {props.role === ROLE_MANAGER && (
                <Field
                    name="company_name"
                    label={t('field.label.company')}
                    placeholder={t('field.placeholder.company')}
                    id={'company'}
                    type="text"
                    component={TextInput}
                    validate={[ requiredValidator ]}
                />
            )}

            <Field
                name="password"
                label={t('field.label.password')}
                placeholder={t('field.placeholder.password')}
                id={'password'}
                type="password"
                component={TextInput}
                translateParams={{ count: 8 }}
            />

            <Field
                name="password_confirmation"
                label={t('field.label.confirm.password')}
                placeholder={t('field.label.confirm.password')}
                id={'password_confirmation'}
                type="password"
                component={TextInput}
                translateParams={{ count: 8 }}
            />

            <Field
                name="lang"
                label={t('field.label.language')}
                id={'lang'}
                component={LanguageSelectField}
            />

            <div>
                <p className="mb-0">
                    {t('registering.agree')}{" "}
                    <Link to={{ pathname: '/terms', state: { from: location } }} className="text-primary">
                        {t('terms.of.use')}
                    </Link>
                </p>
            </div>

            <div className="mt-4">
                <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                    disabled={props.loading}
                >
                    <ButtonLoadingIcon loading={props.loading} />
                    {t('register')}
                </button>
            </div>
        </form>
    );
};

const Register = reduxForm({
    form: FORM_SIGN_UP,
    validate: registerValidation,
    enableReinitialize: true,
})(RegisterFormComponent);


const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const RegisterForm = connect(mapStateToProps, mapDispatchToProps)(Register);