import { PROPERTY_PRICE_MAX, PROPERTY_PRICE_MIN } from "../property";

export const CLIENT_PROPERTIES_OWNED_TAB = 'CLIENT_PROPERTIES_OWNED_TAB';
export const CLIENT_PROPERTIES_ACCEPTED_TAB = 'CLIENT_PROPERTIES_ACCEPTED_TAB';
export const CLIENT_PROPERTIES_ALL_TAB = 'CLIENT_PROPERTIES_ALL_TAB';
export const CLIENT_PROPERTIES_FAVORITE_TAB = 'CLIENT_PROPERTIES_FAVORITE_TAB';

export const CLIENT_ACCEPTED_PROPERTIES_FILTER_INITIAL_VALUES = {
    types: null,
    companies: null,
    cities: null,
    price: {
        range: [PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX],
        empty_price: false,
    },
    statuses: null,
};

export const CLIENT_OWNED_PROPERTIES_FILTER_INITIAL_VALUES = {
    cities: null,
    price: {
        range: [PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX],
        empty_price: false,
    },
    statuses: null,
};


export const CLIENT_FAVORITE_PROPERTIES_FILTER_INITIAL_VALUES = {
    cities: null,
    price: {
        range: [PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX],
        empty_price: false,
    },
    statuses: null,
};

export const CLIENT_ALL_PROPERTIES_FILTER_INITIAL_VALUES = {
    cities: null,
    price: {
        range: [PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX],
        empty_price: false,
    },
    statuses: null,
};