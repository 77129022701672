import {
    FETCH_CONTACT,
    FETCH_CONTACT_SUCCESS,
    FETCH_CONTACT_ERROR,
    CLEAN_CONTACT,
} from './actionTypes';

export const fetchContact = (id) => {
    return {
        type: FETCH_CONTACT,
        payload: { id },
    }
};

export const fetchContactSuccess = (data) => {
    return {
        type: FETCH_CONTACT_SUCCESS,
        payload: data,
    }
};

export const fetchContactError = (data) => {
    return {
        type: FETCH_CONTACT_ERROR,
        payload: data,
    }
};

export const cleanContact = () => {
    return { type: CLEAN_CONTACT };
};