import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Alert, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form'
import { withRouter, useHistory } from "react-router-dom";

import { useTitle } from "../../hooks";
import { FORM_CREATE_CONSULTANT } from "../../consts/forms";
import TextInput from "../../components/Common/TextInput";
import SwitchField from "../../components/Common/SwitchField";
import ButtonLoadingIcon from '../../components/Common/ButtonLoadingIcon';
import { BackButton, PageTitle } from '../../components/Custom';
import {
    email as emailValidator,
    required as requiredValidator,
} from "../../utils/validations";
import { createConsultant, cleanConsultantPage } from '../../store/actions';

const ConsultantCreateComponent = (props) => {
    useTitle(props.t('title.consultants.create'));

    const history = useHistory();

    useEffect(() => {
        return () => {
            props.cleanConsultantPage();
        };
    }, []);

    const onSubmit = (values) => {
        props.createConsultant(values, history);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('title.consultants.create')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <BackButton />
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {props.createError && props.createError.message ? <Alert color="danger">{props.createError.message}</Alert> : null}

                                    <form
                                        onSubmit={props.handleSubmit(onSubmit)}
                                        className="form-horizontal"
                                        noValidate={true}
                                    >
                                        <Field
                                            name="email"
                                            label={props.t('field.label.email')}
                                            placeholder={props.t('field.placeholder.email')}
                                            id={'email'}
                                            type="email"
                                            component={TextInput}
                                            validate={[ requiredValidator, emailValidator ]}
                                            row
                                        />

                                        <Field
                                            name="name"
                                            label={props.t('field.label.fullname')}
                                            placeholder={props.t('field.placeholder.fullname')}
                                            id={'name'}
                                            type="text"
                                            component={TextInput}
                                            validate={[ requiredValidator ]}
                                            row
                                        />

                                        <Field
                                          name="can_manage_property"
                                          label={props.t('manage_property')}
                                          placeholder={props.t('allowed')}
                                          id={'can_manage_property'}
                                          type="checkbox"
                                          component={SwitchField}
                                        />

                                        <Row className="justify-content-end">
                                            <Col lg="10">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    disabled={props.createLoading}
                                                >
                                                    <ButtonLoadingIcon loading={props.createLoading} />
                                                    {props.t('create')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { createLoading, createError } = state.consultant;
    return {
        createLoading, createError,
    };
};

const mapDispatchToProps = {
    createConsultant,
    cleanConsultantPage,
};

const ConsultantCreateForm = reduxForm({
    form: FORM_CREATE_CONSULTANT,
})(ConsultantCreateComponent);

export const ConsultantCreate = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConsultantCreateForm)));