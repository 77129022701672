import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_TRANSLATIONS } from './actionTypes';
import {
    fetchTranslationsSuccess,
    fetchTranslationsError,
} from './actions';

import { API } from '../../../api';

function* fetchTranslations() {
    try {
        const response = { data: { data: [] }}
        const { data } = response;
        yield put(fetchTranslationsSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchTranslationsError(message));
    }
}

export function* watchTranslationsFetch() {
    yield takeEvery(FETCH_TRANSLATIONS, fetchTranslations)
}

function* translationSaga() {
    yield all([
        fork(watchTranslationsFetch),
    ]);
}

export default translationSaga;