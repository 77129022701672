import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from "react-i18next";
import { Button, Row, Col, Alert } from "reactstrap";
import { Link } from 'react-router-dom';

import { PROPERTY_INVITE_CLIENT } from "../../../../consts/forms";
import TextInput from "../../../../components/Common/TextInput";
import ButtonLoadingIcon from '../../../../components/Common/ButtonLoadingIcon';
import {
    email as emailValidator,
    required as requiredValidator,
    // maxValue as maxValueValidator,
    // minValue as minValueValidator,
    // number as numberValidator,
} from "../../../../utils/validations";
import { sendInvite, cleanSendInvite } from '../../../../store/actions';

// import { PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX } from '../../../../consts/property';
//
// const priceMinValue = minValueValidator(0);
// const priceMaxValue = maxValueValidator(999999999);

const InviteClientComponent = (props) => {
    const { propertyId } = props;

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        return () => {
            props.cleanSendInvite();
        };
    }, []);

    const onSubmit = (value) => {
        const params = {
            ...value,
            property: propertyId,
        };

        props.sendInvite(params);
    };

    const handleCloseForm = () => {
        setVisible(false);
        props.reset(PROPERTY_INVITE_CLIENT);
        props.cleanSendInvite();
    };

    return (
        <>
            <div className="mb-3">
                {visible ? (
                    props.t('send_property')
                ) : (
                    <Button
                        type="button" color="light" outline className="waves-effect"
                        onClick={() => setVisible(true)}
                    >
                        {props.t('send_property')}
                    </Button>
                )}
            </div>

            {visible && (
                <>
                    {props.sendInviteError && props.sendInviteError ? <Alert color="danger">{props.sendInviteError.message}</Alert> : null}

                    <form
                        onSubmit={props.handleSubmit(onSubmit)}
                        className="form-horizontal"
                        noValidate={true}
                    >
                        <Field
                            name="email"
                            label={props.t('field.label.email')}
                            placeholder={props.t('field.placeholder.email')}
                            id={'email'}
                            type="email"
                            component={TextInput}
                            validate={[ requiredValidator, emailValidator ]}
                            row
                        />

                        {/*<Field*/}
                            {/*name="price"*/}
                            {/*label={props.t('field.label.new_price')}*/}
                            {/*placeholder={props.t('field.placeholder.new_price')}*/}
                            {/*id={'price'}*/}
                            {/*type="text"*/}
                            {/*component={TextInput}*/}
                            {/*validate={[ numberValidator, priceMinValue, priceMaxValue ]}*/}
                            {/*row*/}
                            {/*translateParams={{ min: PROPERTY_PRICE_MIN, max: PROPERTY_PRICE_MAX }}*/}
                            {/*prependSymbol={'€'}*/}
                            {/*mode={'number'}*/}
                        {/*/>*/}

                        <Row className="justify-content-end">
                            <Col lg="10">
                                <div className="button-items">
                                    <Button
                                        type="button" color="light" outline className="waves-effect"
                                        disabled={props.sendInviteLoading}
                                        onClick={handleCloseForm}
                                    >
                                        {props.t('cancel')}
                                    </Button>

                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={props.sendInviteLoading}
                                    >
                                        <ButtonLoadingIcon loading={props.sendInviteLoading} />
                                        {props.t('invite')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </>
            )}
        </>
    );
};

const mapStateToProps = state => {
    const { sendInviteLoading, sendInviteError } = state.invite.reaction;
    return {
        sendInviteLoading,
        sendInviteError,
    };
};

const mapDispatchToProps = {
    sendInvite,
    cleanSendInvite,
};

const InviteClientForm = reduxForm({
    form: PROPERTY_INVITE_CLIENT,
})(InviteClientComponent);

export const InviteClient = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InviteClientForm));