import { takeLatest, fork, put, all, call, take, cancel, race } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import axios from 'axios'

import { PARSE_PROPERTY, CANCEL_PARSE_PROPERTY } from './actionTypes';
import {
    parsePropertySuccess,
    parsePropertyError,
} from './actions';

import { FORM_PROPERTY_PARSER } from '../../../consts/forms';

import { API } from '../../../api';

function* parseProperty({ payload: { params, callback } }) {
    try {
        const response = yield call(API.property.parseProperty, { ...params });

        const { data } = response;
        yield put(parsePropertySuccess(true));

        callback();

    } catch (error) {
        if (axios.isCancel(error)) {
            console.log(error.message);
        } else {
            const errors = error.response?.data?.errors ? error.response.data.errors : {};
            const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

            yield put(parsePropertyError({
                errors,
                message
            }));

            yield put(stopSubmit(FORM_PROPERTY_PARSER, errors));
        }
    }

    // finally {
    //     if (yield cancelled()) {
    //         source.cancel('cancelled');
    //     }
    // }
}

export function* watchParseProperty() {
    yield takeLatest(PARSE_PROPERTY, parseProperty)
}

// export function* watchParseProperty() {
//     const parseTask = yield takeLatest(PARSE_PROPERTY, parseProperty);
//
//     yield take(CANCEL_PARSE_PROPERTY);
//     yield cancel(parseTask);
// }


// export function* watchParseProperty() {
//     while (true) {
//         const payload = yield take(PARSE_PROPERTY);
//         const parseTask = yield fork(parseProperty, payload);
//
//         // yield take(CANCEL_PARSE_PROPERTY);
//         // yield cancel(parseTask);
//     }
// }


// export function *cancelParse(parseTask) {
//     yield cancel(parseTask);
// }
//
// export function *parseOrCancel(payload) {
//     const parseTask = yield fork(parseProperty, payload);
//     yield watchCancelParse(parseTask);
// }
//
// export function *watchCancelParse(parseTask) {
//     yield takeLatest(CANCEL_PARSE_PROPERTY, () => cancelParse(parseTask));
// }
//
// export function *watchParseProperty() {
//     yield takeLatest(PARSE_PROPERTY, parseOrCancel);
// }


function* parserSaga() {
    yield all([
        fork(watchParseProperty),
    ]);
}

export default parserSaga;