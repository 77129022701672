import {
    FETCH_CONSULTANT_DASHBOARD_STATISTICS,
    FETCH_CONSULTANT_DASHBOARD_STATISTICS_SUCCESS,
    FETCH_CONSULTANT_DASHBOARD_STATISTICS_ERROR,
    CLEAN_CONSULTANT_DASHBOARD_STATISTICS
} from './actionTypes';

export const fetchConsultantDashboardStatistics = () => {
    return {
        type: FETCH_CONSULTANT_DASHBOARD_STATISTICS,
    }
};

export const fetchConsultantDashboardStatisticsSuccess = (data) => {
    return {
        type: FETCH_CONSULTANT_DASHBOARD_STATISTICS_SUCCESS,
        payload: data,
    }
};

export const fetchConsultantDashboardStatisticsError = (data) => {
    return {
        type: FETCH_CONSULTANT_DASHBOARD_STATISTICS_ERROR,
        payload: data,
    }
};

export const cleanConsultantDashboardStatistics = () => {
    return { type: CLEAN_CONSULTANT_DASHBOARD_STATISTICS };
};