import React from 'react';
import { withRouter, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { connect } from "react-redux";

import { ProjectList, ProjectAdd, ProjectEdit } from './'
import { ROLE_MANAGER } from '../../consts/roles';
import { useAllowed } from '../../hooks';
import AllowedRoute from '../../routes/middleware/AllowedRoute';

const ProjectIndexComponent = (props) => {
    let { path } = useRouteMatch();
    let isAllowed = useAllowed([ROLE_MANAGER], props.user.role);

    return (
        <Switch>
            <AllowedRoute exact path={path} component={ProjectList} allowed={true} />
            <AllowedRoute path={`${path}/:id/edit`} component={ProjectEdit} allowed={isAllowed} />
            <AllowedRoute path={`${path}/add`} component={ProjectAdd} allowed={isAllowed} />

            <Route>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

export const ProjectIndex = withRouter(connect(mapStateToProps, {})(ProjectIndexComponent));