import {
    FETCH_USER_INFO_AND_DATA,
    FETCH_USER_INFO_AND_DATA_SUCCESS,
    FETCH_USER_INFO_AND_DATA_ERROR,
    CLEAN_USER_INFO_AND_DATA,
} from './actionTypes';

export const fetchUserInfoAndData = () => {
    return {
        type: FETCH_USER_INFO_AND_DATA,
    }
};

export const fetchUserInfoAndDataSuccess = (data) => {
    return {
        type: FETCH_USER_INFO_AND_DATA_SUCCESS,
        payload: data,
    }
};

export const fetchUserInfoAndDataError = (data) => {
    return {
        type: FETCH_USER_INFO_AND_DATA_ERROR,
        payload: data,
    }
};

export const cleanUserInfoAndData = () => {
    return { type: CLEAN_USER_INFO_AND_DATA };
};