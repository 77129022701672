import React, { useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';

const PropertyParserModalComponent = (props) => {
    const [modalVisible, setModalVisible] = useState(false);

    useImperativeHandle(props.parentRef, () => ({
            hide() {
                hideModal();
            }
        }),
    );

    useEffect(() => {
        props.onVisibleChange(modalVisible);
    }, [modalVisible]);

    const showModal = () => {
        setModalVisible(true);
    };

    const hideModal = () => {
        setModalVisible(false);
    };

    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={showModal}
            >
                <i className={`font-size-16 align-middle mr-2 bx bx-link-alt`} />
                {props.t('property.pull')}
            </Button>

            <Modal
                size="lg"
                isOpen={modalVisible}
                toggle={toggleModal}
            >
                <ModalHeader
                    toggle={toggleModal}
                >
                    {props.t('property.pull')}
                </ModalHeader>

                <ModalBody>
                    {props.children}
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

const PropertyParserModalHOC = withTranslation()(PropertyParserModalComponent);

export const PropertyParserModal = forwardRef((props, ref) => (
    <PropertyParserModalHOC {...props} parentRef={ref} />
));