import {
    FETCH_PROJECT_LIST,
    FETCH_PROJECT_LIST_SUCCESS,
    FETCH_PROJECT_LIST_ERROR,
    CLEAN_PROJECT_LIST_PAGE,

    ADD_PROJECT,
    ADD_PROJECT_SUCCESS,
    ADD_PROJECT_ERROR,
    CLEAN_ADD_PROJECT_PAGE,

    FETCH_PROJECT,
    FETCH_PROJECT_SUCCESS,
    FETCH_PROJECT_ERROR,

    EDIT_PROJECT,
    EDIT_PROJECT_SUCCESS,
    EDIT_PROJECT_ERROR,

    REMOVE_PROJECT,
    REMOVE_PROJECT_SUCCESS,
    REMOVE_PROJECT_ERROR,

    CLEAN_PROJECT_PAGE,

    PROJECT_SET_SORT,
    PROJECT_RESET_SORT,
    PROJECT_SET_PAGE,
} from './actionTypes';

export const fetchProjectList = (params) => {
    return {
        type: FETCH_PROJECT_LIST,
        payload: { params },
    }
};

export const fetchProjectListSuccess = (data) => {
    return {
        type: FETCH_PROJECT_LIST_SUCCESS,
        payload: data,
    }
};

export const fetchProjectListError = (data) => {
    return {
        type: FETCH_PROJECT_LIST_ERROR,
        payload: data,
    }
};

export const cleanProjectListPage = () => {
    return { type: CLEAN_PROJECT_LIST_PAGE };
};

// ADD PROJECT
export const addProject = (params, history) => {
    return {
        type: ADD_PROJECT,
        payload: { params, history },
    }
};

export const addProjectSuccess = (data) => {
    return {
        type: ADD_PROJECT_SUCCESS,
        payload: data,
    }
};

export const addProjectError = (data) => {
    return {
        type: ADD_PROJECT_ERROR,
        payload: data,
    }
};

export const cleanAddProjectPage = () => {
    return { type: CLEAN_ADD_PROJECT_PAGE };
};

// FETCH PROJECT
export const fetchProject = (id) => {
    return {
        type: FETCH_PROJECT,
        payload: { id },
    }
};

export const fetchProjectSuccess = (data) => {
    return {
        type: FETCH_PROJECT_SUCCESS,
        payload: data,
    }
};

export const fetchProjectError = (data) => {
    return {
        type: FETCH_PROJECT_ERROR,
        payload: data,
    }
};

// EDIT PROJECT
export const editProject = (id, params) => {
    return {
        type: EDIT_PROJECT,
        payload: { id, params },
    }
};

export const editProjectSuccess = (data) => {
    return {
        type: EDIT_PROJECT_SUCCESS,
        payload: data,
    }
};

export const editProjectError = (data) => {
    return {
        type: EDIT_PROJECT_ERROR,
        payload: data,
    }
};

// REMOVE PROJECT
export const removeProject = (id, history) => {
    return {
        type: REMOVE_PROJECT,
        payload: { id, history },
    }
};

export const removeProjectSuccess = (data) => {
    return {
        type: REMOVE_PROJECT_SUCCESS,
        payload: data,
    }
};

export const removeProjectError = (data) => {
    return {
        type: REMOVE_PROJECT_ERROR,
        payload: data,
    }
};


export const cleanProjectPage = () => {
    return { type: CLEAN_PROJECT_PAGE };
};

// SET PROJECTS SORTING
export const projectSetSort = (data) => {
    return {
        type: PROJECT_SET_SORT,
        payload: data,
    }
};

export const projectResetSort = () => {
    return { type: PROJECT_RESET_SORT };
};


// SET PROJECTS PAGE
export const projectSetPage = (data) => {
    return {
        type: PROJECT_SET_PAGE,
        payload: data,
    }
};