import {
    FETCH_CLIENT_FAVORITE_PROPERTIES,
    FETCH_CLIENT_FAVORITE_PROPERTIES_SUCCESS,
    FETCH_CLIENT_FAVORITE_PROPERTIES_ERROR,
    CLEAN_CLIENT_FAVORITE_PROPERTIES,

    CLIENT_FAVORITE_PROPERTIES_SET_FILTER,
    CLIENT_FAVORITE_PROPERTIES_RESET_FILTER,

    CLIENT_FAVORITE_PROPERTIES_SET_SORT,
    CLIENT_FAVORITE_PROPERTIES_RESET_SORT,

    CLIENT_FAVORITE_PROPERTIES_SET_PAGE,

    CLIENT_FAVORITE_PROPERTIES_UPDATE_ITEM, CLIENT_FAVORITE_PROPERTIES_SET_SEARCH,
} from './actionTypes';

export const fetchClientFavoriteProperties = (params) => {
    return {
        type: FETCH_CLIENT_FAVORITE_PROPERTIES,
        payload: { params }
    }
};

export const fetchClientFavoritePropertiesSuccess = (data) => {
    return {
        type: FETCH_CLIENT_FAVORITE_PROPERTIES_SUCCESS,
        payload: data,
    }
};

export const fetchClientFavoritePropertiesError = (data) => {
    return {
        type: FETCH_CLIENT_FAVORITE_PROPERTIES_ERROR,
        payload: data,
    }
};

export const cleanClientFavoriteProperties = () => {
    return { type: CLEAN_CLIENT_FAVORITE_PROPERTIES };
};


// FILTER
export const clientFavoritePropertiesSetFilter = (data) => {
    return {
        type: CLIENT_FAVORITE_PROPERTIES_SET_FILTER,
        payload: data,
    }
};

export const clientFavoritePropertiesResetFilter = () => {
    return { type: CLIENT_FAVORITE_PROPERTIES_RESET_FILTER };
};

// SORTING
export const clientFavoritePropertiesSetSort = (data) => {
    return {
        type: CLIENT_FAVORITE_PROPERTIES_SET_SORT,
        payload: data,
    }
};

export const clientFavoritePropertiesResetSort = () => {
    return { type: CLIENT_FAVORITE_PROPERTIES_RESET_SORT };
};


// PAGE
export const clientFavoritePropertiesSetPage = (data) => {
    return {
        type: CLIENT_FAVORITE_PROPERTIES_SET_PAGE,
        payload: data,
    }
};

// UPDATE ITEM IN LIST
export const clientFavoritePropertiesUpdateItem = (id, data) => {
    return {
        type: CLIENT_FAVORITE_PROPERTIES_UPDATE_ITEM,
        payload: { id, data },
    }
};

export const clientFavoritePropertiesSetSearch = (data) => {
    return {
        type: CLIENT_FAVORITE_PROPERTIES_SET_SEARCH,
        payload: data,
    }
};