import {
    FETCH_CLIENT_OWNED_PROPERTIES,
    FETCH_CLIENT_OWNED_PROPERTIES_SUCCESS,
    FETCH_CLIENT_OWNED_PROPERTIES_ERROR,
    CLEAN_CLIENT_OWNED_PROPERTIES,

    CLIENT_OWNED_PROPERTIES_SET_FILTER,
    CLIENT_OWNED_PROPERTIES_RESET_FILTER,

    CLIENT_OWNED_PROPERTIES_SET_SORT,
    CLIENT_OWNED_PROPERTIES_RESET_SORT,

    CLIENT_OWNED_PROPERTIES_SET_PAGE,

    CLIENT_OWNED_PROPERTIES_UPDATE_ITEM, CLIENT_OWNED_PROPERTIES_SET_SEARCH,
} from './actionTypes';

import { CLIENT_OWNED_PROPERTIES_FILTER_INITIAL_VALUES } from '../../../../consts/property/clientProperties';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    listIsFetched: false,
    page: 1,
    meta: null,

    filter: {
        isActive: false,
        values: CLIENT_OWNED_PROPERTIES_FILTER_INITIAL_VALUES,
    },

    sort: [],

    search: null
};

function updateObjectInArray(array, payload) {
    return array.map((item, index) => {
        if (item.id !== payload.id) {
            return item
        }

        return {
            ...item,
            ...payload.data,
        }
    })
}

const owned = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_CLIENT_OWNED_PROPERTIES:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case FETCH_CLIENT_OWNED_PROPERTIES_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                listIsFetched: true,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case FETCH_CLIENT_OWNED_PROPERTIES_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case CLEAN_CLIENT_OWNED_PROPERTIES:
            state = {
                ...state,
                list: [],
                listLoading: false,
                listError: null,
                listIsFetched: false,
                page: initialState.page,
                meta: null,
            };
            break;


        // FILTER
        case CLIENT_OWNED_PROPERTIES_SET_FILTER:
            state = {
                ...state,
                filter: {
                    isActive: true,
                    values: {
                        ...initialState.filter.values,
                        ...action.payload,
                    },
                },
                page: initialState.page,
            };
            break;

        case CLIENT_OWNED_PROPERTIES_RESET_FILTER:
            state = {
                ...state,
                filter: initialState.filter,
                page: initialState.page,
            };
            break;


        // SORT
        case CLIENT_OWNED_PROPERTIES_SET_SORT:
            state = {
                ...state,
                sort: action.payload,
                page: initialState.page,
            };
            break;

        case CLIENT_OWNED_PROPERTIES_RESET_SORT:
            state = {
                ...state,
                sort: initialState.sort,
                page: initialState.page,
            };
            break;

        // PAGE
        case CLIENT_OWNED_PROPERTIES_SET_PAGE:
            state = {
                ...state,
                page: action.payload,
            };
            break;


        // UPDATE ITEM IN LIST
        case CLIENT_OWNED_PROPERTIES_UPDATE_ITEM:
            state = {
                ...state,
                list: [...updateObjectInArray([...state.list], action.payload)],
            };
            break;
        case CLIENT_OWNED_PROPERTIES_SET_SEARCH:
            state = {
                ...state,
                page: 1,
                search: action.payload,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default owned;