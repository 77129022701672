import React, { useEffect } from 'react';
import { Col, FormGroup, Label } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, FormSection, getFormValues } from 'redux-form'

import { WizardPager } from '../../WizardPager';
import SelectInput from "../../../../../../components/Common/SelectInput";
import { FORM_PROPERTY_REQUEST_HOME_WIZARD } from '../../../../../../consts/forms';
import {
    PROPERTY_PRICE_MAX,
    PROPERTY_PRICE_MIN,
} from "../../../../../../consts/property";
import { SLIDER_MODE_INPUT } from "../../../../../../consts/common";
import { PriceSliderRangeField, CitySelectField, PropertyTypesCheckboxField } from "../../../../../../components/Custom";
import { PROPERTY_REQUEST_PRICE_RANGE_STEP } from "../../../../../../consts/propertyRequest";

const HomeWizardStep1Component = (props) => {
    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <>
            <form
                onSubmit={props.handleSubmit(props.onSubmit)}
                className="form-horizontal"
                noValidate={true}
            >
                <FormSection name={'price'}>
                    <FormGroup row className={'mb-4'}>
                        <Label className={'col-form-label col-lg-2'}>{props.t('field.label.property.price')}</Label>

                        <Col lg="10">
                            <Field
                                name="range"
                                component={PriceSliderRangeField}
                                limit={{
                                    min: PROPERTY_PRICE_MIN,
                                    max: PROPERTY_PRICE_MAX,
                                }}
                                mode={SLIDER_MODE_INPUT}
                                step={PROPERTY_REQUEST_PRICE_RANGE_STEP}
                            />
                        </Col>
                    </FormGroup>
                </FormSection>

                <PropertyTypesCheckboxField
                    name="property_types"
                    checkboxClassName={'custom-control-inline'}
                    label={props.t('field.label.property_types')}
                />

                <Field
                    name="regions"
                    label={props.t('field.label.provinces')}
                    placeholder={props.t('field.placeholder.provinces')}
                    id={'regions'}
                    component={SelectInput}
                    options={props.provinceOptions}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.id}`}
                    isMulti
                />

                <CitySelectField
                    name="cities"
                    label={props.t('field.label.cities')}
                    placeholder={props.t('field.placeholder.search_select_cities')}
                    id={'cities'}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.id}`}
                    isMulti
                />
            </form>

            <WizardPager
                next={props.t('next')}
                prev={props.t('previous')}
                onNext={props.handleSubmit(props.onSubmit)}
                onPrev={props.back}
            />
        </>
    );
};

const HomeWizardForm = reduxForm({
    form: FORM_PROPERTY_REQUEST_HOME_WIZARD,
    enableReinitialize: true,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(HomeWizardStep1Component);

const mapStateToProps = state => {
    const formValues = getFormValues(FORM_PROPERTY_REQUEST_HOME_WIZARD)(state);
    return {
        initialValues: {
            ...formValues,
            price: {
                range: formValues?.price?.range || [PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX],
            },
        },
    };
};

const mapDispatchToProps = {
};

export const HomeWizardStep1 = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomeWizardForm)));