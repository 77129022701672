import {
    COMPLETE_REGISTER_USER,
    COMPLETE_REGISTER_USER_SUCCESS,
    COMPLETE_REGISTER_USER_ERROR,
    COMPLETE_REGISTER_CLEAN,
} from './actionTypes';

// complete register user
export const completeRegisterUser = (params) => {
    return {
        type: COMPLETE_REGISTER_USER,
        payload: { params }
    }
};

export const completeRegisterUserSuccess = (data) => {
    return {
        type: COMPLETE_REGISTER_USER_SUCCESS,
        payload: data
    }
};

export const completeRegisterUserError = (data) => {
    return {
        type: COMPLETE_REGISTER_USER_ERROR,
        payload: data
    }
};

export const completeRegisterUserClean = () => {
    return {
        type: COMPLETE_REGISTER_CLEAN,
    };
};