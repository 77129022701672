import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Alert, Card, CardHeader, CardBody, Row, } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import _ from 'lodash';

import {
    fetchUsersFromCompanySentInvites,
    cleanUsersFromCompanySentInvites,
    usersFromCompanySentInvitesSetSort,
    usersFromCompanySentInvitesSetFilter,
    usersFromCompanySentInvitesResetFilter,
    usersFromCompanySentInvitesSetPage,
} from '../../../../store/actions';
import { ClientsTable } from '../ClientsTable/ClientsTable';
import { UsersFilter, Pagination, SearchInput } from '../../../../components/Custom';

import * as HOC from '../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const CompanyClientsComponent = (props) => {
    const [search, setSearch] = useState(null);

    useEffect(() => {
        let params = {
            page: props.page,
        };

        if (props.sort.length > 0) {
            params.sort = props.sort;
        }

        if (props.filter.isActive) {
            params.filter = props.filter.values;
        }

        if (search) {
            params.filter = {
                ...params.filter,
                keywords: search,
                search_fields: ['name', 'email', 'phone'],
            };
        }

        props.fetchUsersFromCompanySentInvites(params);

    }, [props.sort, props.filter, props.page, search]);

    useEffect(() => {
        return () => {
            props.cleanUsersFromCompanySentInvites();
            props.usersFromCompanySentInvitesResetFilter();
        }
    }, []);

    const filterWarningVisible = useMemo(() => {
        return (props.filter.isActive || search) && props.list.length === 0 && !props.loading;
    }, [props.filter, props.list, props.loading, search]);

    const handleSort = useCallback(sortBy => {
        if (!_.isEqual(sortBy, props.sort)) {
            props.usersFromCompanySentInvitesSetSort(sortBy);
        }
    }, [props.sort]);

    const handleResetFilter = () => {
        props.usersFromCompanySentInvitesResetFilter()
    };

    const handleApplyFilter = (values) => {
        props.usersFromCompanySentInvitesSetFilter(values);
    };

    const handlePageChange = (item) => {
        props.usersFromCompanySentInvitesSetPage(item.selected + 1);
    };

    const handleSearchChange = (value) => {
        props.usersFromCompanySentInvitesSetPage(1);
        setSearch(value);
    };

    return (
        <Card>
            <CardHeader className="bg-transparent">
                <Row className="no-gutters justify-content-between">
                    <h5 className={'mt-2 mr-2'}>{props.t('clients.your.list')}</h5>

                    <div className="button-items">
                        <UsersFilter
                            filter={props.filter}
                            onReset={handleResetFilter}
                            onApply={handleApplyFilter}
                        />
                    </div>
                </Row>
            </CardHeader>
            <CardBody>
                <Row className={'no-gutters justify-content-between'}>
                    <SearchInput
                        onChange={handleSearchChange}
                        searchFields={
                            ['search.field.name', 'search.field.email', 'search.field.phone']
                        }
                        hintExample={'John Doe'}
                    />
                </Row>

                <TableDataLoading isLoading={props.loading} isFailed={props.error} error={props.t(props.error)}>
                    {filterWarningVisible ? (
                        <Alert color="primary">{props.t('clients.filter.empty')}</Alert>
                    ) : (
                        <>
                            <ClientsTable
                                items={props.list}
                                isLoading={props.loading}
                                onSort={handleSort}
                            />

                            <Pagination data={props.meta} onPageChange={handlePageChange} />
                        </>
                    )}
                </TableDataLoading>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => {
    const { companyUsers, companyUsersLoading, companyUsersError, companyUsersSort, companyUsersFilter, companyUsersPage, companyUsersMeta, } = state.invite.users;
    return {
        list: companyUsers,
        loading: companyUsersLoading,
        error: companyUsersError,
        sort: companyUsersSort,
        filter: companyUsersFilter,
        meta: companyUsersMeta,
        page: companyUsersPage,
    };
};

const mapDispatchToProps = {
    fetchUsersFromCompanySentInvites,
    cleanUsersFromCompanySentInvites,
    usersFromCompanySentInvitesSetSort,
    usersFromCompanySentInvitesSetFilter,
    usersFromCompanySentInvitesResetFilter,
    usersFromCompanySentInvitesSetPage,
};

export const CompanyClients = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompanyClientsComponent));