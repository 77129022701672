import {
} from './actionTypes';

const initialState = {
};

const general = (state = initialState, action) => {
    switch (action.type) {

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default general;