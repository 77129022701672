import { takeLatest, takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from "toastr";

import i18n from "../../../i18n";

import { FETCH_PROPERTY_LIST, PROPERTY_LIST_SEND_FAVORITE_STATUS } from './actionTypes';
import {
    fetchPropertyListSuccess,
    fetchPropertyListError,
    propertyListUpdateItem,
} from './actions';

import { API } from '../../../api';
import { getMapRequestParams } from '../../../utils/request';

function* fetchPropertyList({ payload: { params } }) {
    try {
        const includes = ['project', 'city', 'myReceivedLink', 'myReceivedLink.sender', 'myReceivedLink.sender.company']; // 'images', 'link.receiver', 'mySentLinks'

        let requestParams = {
            includes,
            filter: {
                links_statuses: ['active'],
                leads_links_statuses: ['active'],
            }
        };

        requestParams = getMapRequestParams(params, requestParams);

        const response = yield call(API.property.getPropertyList, requestParams);

        const { data } = response;
        yield put(fetchPropertyListSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchPropertyListError(message));
    }
}

function* sendFavoriteStatus({ payload: { id, params } }) {
    const { status } = params;

    yield put(propertyListUpdateItem(id, { is_favorite: status }));

    try {
        const response = yield call(API.property.sendFavoriteStatus, id, params);

        const { data } = response;

        let message = status ? 'property.favorite.add' : 'property.favorite.remove';

        toastr.success(i18n.t(message));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        toastr.error(message);

        yield put(propertyListUpdateItem(id, { is_favorite: !status }));
    }
}

export function* watchPropertyListFetch() {
    yield takeLatest(FETCH_PROPERTY_LIST, fetchPropertyList)
}

export function* watchSendFavoriteStatus() {
    yield takeEvery(PROPERTY_LIST_SEND_FAVORITE_STATUS, sendFavoriteStatus)
}

function* listSaga() {
    yield all([
        fork(watchPropertyListFetch),
        fork(watchSendFavoriteStatus),
    ]);
}

export default listSaga;