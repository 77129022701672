import {
  CRM_SYSTEM_ACCOUNT_CREATE,
  CRM_SYSTEM_ACCOUNT_CREATE_SUCCESS,
  CRM_SYSTEM_ACCOUNT_CREATE_ERROR,
  CRM_SYSTEM_ACCOUNT_CREATE_CLEAN_STATE,
} from './actionTypes';

export const crmSystemAccountCreate = ({id, crm, params, onSuccess, onError}) => {
  return {
    type: CRM_SYSTEM_ACCOUNT_CREATE,
    payload: {id, crm, params, onSuccess, onError},
  }
};

export const crmSystemAccountCreateSuccess = (crm) => {
  return {
    type: CRM_SYSTEM_ACCOUNT_CREATE_SUCCESS,
    payload: {crm},
  }
};

export const crmSystemAccountCreateError = (crm) => {
  return {
    type: CRM_SYSTEM_ACCOUNT_CREATE_ERROR,
    payload: {crm},
  }
};

export const crmSystemAccountCreateCleanState = () => {
  return {type: CRM_SYSTEM_ACCOUNT_CREATE_CLEAN_STATE};
};