export const FETCH_RECEIVED_INVITE_LIST = 'FETCH_RECEIVED_INVITE_LIST';
export const FETCH_RECEIVED_INVITE_LIST_SUCCESS = 'FETCH_RECEIVED_INVITE_LIST_SUCCESS';
export const FETCH_RECEIVED_INVITE_LIST_ERROR = 'FETCH_RECEIVED_INVITE_LIST_ERROR';
export const CLEAN_INVITE_LIST_PAGE = 'CLEAN_INVITE_LIST_PAGE';

export const FETCH_RECEIVED_ACTIVE_INVITE_LIST = 'FETCH_RECEIVED_ACTIVE_INVITE_LIST';
export const FETCH_RECEIVED_ACTIVE_INVITE_LIST_SUCCESS = 'FETCH_RECEIVED_ACTIVE_INVITE_LIST_SUCCESS';
export const FETCH_RECEIVED_ACTIVE_INVITE_LIST_ERROR = 'FETCH_RECEIVED_ACTIVE_INVITE_LIST_ERROR';
export const CLEAN_ACTIVE_INVITE_LIST = 'CLEAN_ACTIVE_INVITE_LIST';

export const FETCH_RECEIVED_INACTIVE_INVITE_LIST = 'FETCH_RECEIVED_INACTIVE_INVITE_LIST';
export const FETCH_RECEIVED_INACTIVE_INVITE_LIST_SUCCESS = 'FETCH_RECEIVED_INACTIVE_INVITE_LIST_SUCCESS';
export const FETCH_RECEIVED_INACTIVE_INVITE_LIST_ERROR = 'FETCH_RECEIVED_INACTIVE_INVITE_LIST_ERROR';
export const CLEAN_INACTIVE_INVITE_LIST = 'CLEAN_INACTIVE_INVITE_LIST';

export const FETCH_PROPERTY_INVITES = 'FETCH_PROPERTY_INVITES';
export const FETCH_PROPERTY_INVITES_SUCCESS = 'FETCH_PROPERTY_INVITES_SUCCESS';
export const FETCH_PROPERTY_INVITES_ERROR = 'FETCH_PROPERTY_INVITES_ERROR';
export const CLEAN_PROPERTY_INVITES = 'CLEAN_PROPERTY_INVITES';
export const PROPERTY_INVITES_SET_SORT = 'PROPERTY_INVITES_SET_SORT';
export const PROPERTY_INVITES_SET_PAGE = 'PROPERTY_INVITES_SET_PAGE';
export const PROPERTY_INVITES_SET_SEARCH = 'PROPERTY_INVITES_SET_SEARCH';