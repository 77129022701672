import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import AccountActivation from "../pages/Authentication/Activation";
import RegisterComplete from "../pages/Authentication/RegisterComplete";
import RegisterManager from "../pages/Authentication/RegisterManager";

// Pages
import {
    Terms,
    Dashboard,
    Profile,
    ProjectIndex,
    ConsultantIndex,
    PropertyIndex,
    ClientIndex,
    ContactIndex,
    AuthSocial,
    ChatIndex,
    PropertyRequestIndex,
    LeadIndex,
    CreditIndex,
    BillingIndex,
    IntegrationIndex,
} from '../pages';

import { ROUTE_MIDDLEWARE_SUBSCRIPTION } from '../consts/middleware/routes';
import { MIDDLEWARE_PREVIEW_MODE, MIDDLEWARE_PROPERTY_MANAGE } from '../consts/middleware/general';

const publicRoutes = [
    { path: "/login", component: Login },
    { path: "/forgot-password", component: ForgetPwd },
    { path: "/reset-password/:token", component: ResetPassword },
    { path: "/register", component: Register },
    { path: "/account/activate/:id/:hash", component: AccountActivation},
    { path: "/register-manager", component: RegisterManager },
];

const accountOnboardingRoutes = [
    { path: "/account/complete", component: RegisterComplete },
];

const utilityRoutes = [
    { path: "/terms", component: Terms },
    { path: "/auth/social", component: AuthSocial },
];

const clientRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/properties", component: PropertyIndex },
    { path: "/profile", component: Profile },
    { path: "/contacts",  component: ContactIndex },
    { path: "/chat",  component: ChatIndex },
    { path: "/property-requests",  component: PropertyRequestIndex, middlewares: [MIDDLEWARE_PREVIEW_MODE] },
    // { path: "/integrations", component: IntegrationIndex },
    { path: "*", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const consultantRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/properties", component: PropertyIndex, middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION] },
    { path: "/profile", component: Profile },
    { path: "/projects", component: ProjectIndex, middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION] },
    { path: "/clients",  component: ClientIndex, middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION] },
    { path: "/chat",  component: ChatIndex, middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION] },
    { path: "/leads",  component: LeadIndex, middlewares: [MIDDLEWARE_PREVIEW_MODE, ROUTE_MIDDLEWARE_SUBSCRIPTION] },
    // { path: "/integrations", component: IntegrationIndex, middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION, MIDDLEWARE_PROPERTY_MANAGE] },
    { path: "*", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const managerRoutes = [
    // { path: "/dashboard", component: Dashboard, middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION] },
    { path: "/dashboard", component: Dashboard },
    { path: "/properties", component: PropertyIndex, middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION] },
    { path: "/profile", component: Profile },
    { path: "/projects", component: ProjectIndex, middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION] },
    { path: "/consultants", component: ConsultantIndex, middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION] },
    { path: "/clients",  component: ClientIndex, middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION] },
    { path: "/leads",  component: LeadIndex, middlewares: [MIDDLEWARE_PREVIEW_MODE, ROUTE_MIDDLEWARE_SUBSCRIPTION] },
    // { path: "/billing",  component: BillingIndex },
    { path: "/integrations", component: IntegrationIndex, middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION] },
    { path: "*", exact: true, component: () => <Redirect to="/dashboard" /> }
];

export { publicRoutes, accountOnboardingRoutes, utilityRoutes, clientRoutes, consultantRoutes, managerRoutes };
