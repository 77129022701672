import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
	SET_LANGUAGE,
} from './actionTypes';

function* setLanguage({ payload: data }) {
	try {

	} catch (error) { }
}

export function* watchSetLanguage() {
	yield takeEvery(SET_LANGUAGE, setLanguage);
}


function* CommonSaga() {
	yield all([
		fork(watchSetLanguage),
	]);
}

export default CommonSaga;
