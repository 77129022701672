import React, { useEffect } from 'react';
import { Col, FormGroup, Label } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, getFormValues, reduxForm } from 'redux-form'

import { WizardPager } from '../../WizardPager';
import RadioField from '../../../../../../components/Common/RadioField';
import CheckboxGroupField from '../../../../../../components/Common/CheckboxGroupField';
import { FORM_PROPERTY_REQUEST_HOME_WIZARD } from '../../../../../../consts/forms';
import {
    PROPERTY_TERRACE_SIZE_MIN,
    PROPERTY_TERRACE_SIZE_MAX,
} from "../../../../../../consts/property";
import { SLIDER_MODE_INPUT } from "../../../../../../consts/common";
import { SliderField } from "../../../../../../components/Custom";
import { propertyRequestHelper } from '../../../../../../helpers';

const range = {
    'min': [PROPERTY_TERRACE_SIZE_MIN], // 0
    '30%': [(PROPERTY_TERRACE_SIZE_MAX + 1) * 0.0001], // 100
    '50%': [(PROPERTY_TERRACE_SIZE_MAX + 1) * 0.001], // 1000
    '75%': [(PROPERTY_TERRACE_SIZE_MAX + 1) * 0.01], // 10 th
    '90%': [(PROPERTY_TERRACE_SIZE_MAX + 1) * 0.1], // 100 th
    'max': [PROPERTY_TERRACE_SIZE_MAX], // 999999
};

const HomeWizardStep3Component = (props) => {
    useEffect(() => {
        return () => {
        };
    }, []);

    const parking = propertyRequestHelper.parking.getParkingOptions(props.t);
    const ages = propertyRequestHelper.age.getAgeOptions(props.t);

    return (
        <>
            <form
                onSubmit={props.handleSubmit(props.onSubmit)}
                className="form-horizontal"
                noValidate={true}
            >
                <FormGroup row tag="fieldset">
                    <Label className={'col-form-label col-lg-2'}>{props.t('field.label.parking')}</Label>

                    <Col lg="10">
                        <div className={'form-control custom-switch-filed-wrap border-0 p-0 flex-wrap'}>
                            {parking.map((item) => (
                                <div className={'pt-1 pb-1'} key={`property-parking-${item.value}`}>
                                    <Field
                                        name="parking"
                                        value={item.value}
                                        label={item.label}
                                        id={`property-parking-${item.value}`}
                                        type="radio"
                                        component={RadioField}
                                    />
                                </div>
                            ))}
                        </div>
                    </Col>
                </FormGroup>

                <CheckboxGroupField
                    name="property_ages"
                    options={ages}
                    checkboxClassName={'custom-control-inline'}
                    label={props.t('field.label.property_ages')}
                />

                <FormGroup row className={'mb-4'}>
                    <Label className={'col-form-label col-lg-2'}>{props.t('field.label.property.min_size_terrace')}</Label>

                    <Col lg="10">
                        <Field
                            id={'min-size-terrace'}
                            name="min_size_terrace"
                            component={SliderField}
                            limit={{
                                min: PROPERTY_TERRACE_SIZE_MIN,
                                max: PROPERTY_TERRACE_SIZE_MAX,
                            }}
                            mode={SLIDER_MODE_INPUT}
                            labelPrefix={'m²: '}
                            range={range}
                        />
                    </Col>
                </FormGroup>

            </form>

            <WizardPager
                next={props.t('next')}
                prev={props.t('previous')}
                onNext={props.handleSubmit(props.onSubmit)}
                onPrev={props.back}
            />
        </>
    );
};

const HomeWizardForm = reduxForm({
    form: FORM_PROPERTY_REQUEST_HOME_WIZARD,
    enableReinitialize: true,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(HomeWizardStep3Component);

const mapStateToProps = state => {
    const formValues = getFormValues(FORM_PROPERTY_REQUEST_HOME_WIZARD)(state);
    return {
        initialValues: {
            ...formValues,
            min_size_terrace: formValues.min_size_terrace || [PROPERTY_TERRACE_SIZE_MIN],
        },
    };
};

const mapDispatchToProps = {
};

export const HomeWizardStep3 = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomeWizardForm)));