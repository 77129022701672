import {
    FETCH_CLIENT_PROPERTIES_STATISTICS,
    FETCH_CLIENT_PROPERTIES_STATISTICS_SUCCESS,
    FETCH_CLIENT_PROPERTIES_STATISTICS_ERROR,
    CLEAN_CLIENT_PROPERTIES_STATISTICS, CLIENT_PROPERTIES_STATISTICS_UPDATE
} from './actionTypes';

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const statistics = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CLIENT_PROPERTIES_STATISTICS:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case FETCH_CLIENT_PROPERTIES_STATISTICS_SUCCESS:
            state = {
                ...state,
                loading: false,
                data: action.payload,
            };
            break;

        case FETCH_CLIENT_PROPERTIES_STATISTICS_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case CLEAN_CLIENT_PROPERTIES_STATISTICS:
            state = {
                ...state,
                data: null,
                loading: false,
                error: null,
            };
            break;
        case CLIENT_PROPERTIES_STATISTICS_UPDATE:
            state = {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload
                }
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default statistics;