import React, { useEffect } from "react"
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardTitle } from "reactstrap";

import { fetchConsultantDashboardStatistics, cleanConsultantDashboardStatistics } from '../../../../store/actions';
import { ChartBar } from './ChartBar';

import * as HOC from '../../../../HOC';
const DataShower = HOC.withDataShower();

export const ConsultantInvitesChartBarComponent = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        props.fetchConsultantDashboardStatistics();

        return () => {
            props.cleanConsultantDashboardStatistics();
        }
    }, []);

    return (
        <Card className={'profile-card-wrap consultant-dashboard-chart-card'}>
            <CardBody>
                <CardTitle className="mb-4">{t('dashboard.consultant.chart.title')}</CardTitle>

                <DataShower
                    isLoading={props.loading}
                    isFailed={props.error}
                    error={t(props.error)}
                    customLoaderClass={'data-shower-loader-center'}
                >
                    <ChartBar data={props.data} />
                </DataShower>

            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => {
    const { data, loading, error } = state.statistics.consultant;
    return {
        data,
        loading,
        error,
    };
};

const mapDispatchToProps = {
    fetchConsultantDashboardStatistics,
    cleanConsultantDashboardStatistics,
};

export const ConsultantInvitesChartBar = connect(mapStateToProps, mapDispatchToProps)(ConsultantInvitesChartBarComponent);