import React, { useEffect } from 'react';
import { connect } from "react-redux";

import { EchoClient } from '../../../services';
import {
    echoAddChannelListener,
    onChatMessageEvent,
    onChatSeenEvent,
    onInviteNewLinkEvent,
    onUpdateCreditsEvent,
    onUpdatePropertyImportEvent,
    onUpdateSubscriptionEvent,
    onUpdateCrmSystemAccountEvent,
} from "../../../store/actions";
import {
    CHAT_MESSAGE_EVENT,
    CHAT_SEEN_EVENT,
    INVITE_NEW_LINK_EVENT,
    UPDATE_CREDITS_EVENT,
    UPDATE_PROPERTY_IMPORT_EVENT,
    UPDATE_SUBSCRIPTION_EVENT,
    UPDATE_CRM_SYSTEM_ACCOUNT_EVENT,
} from '../../../consts/echo';

const SocketWrapperComponent = (props) => {

    useEffect(() => {
        EchoClient.create();

        props.echoAddChannelListener(`user.${props.user.id}`, CHAT_MESSAGE_EVENT, props.onChatMessageEvent);
        props.echoAddChannelListener(`user.${props.user.id}`, CHAT_SEEN_EVENT, props.onChatSeenEvent);
        props.echoAddChannelListener(`user.${props.user.id}`, INVITE_NEW_LINK_EVENT, props.onInviteNewLinkEvent);
        props.echoAddChannelListener(`user.${props.user.id}`, UPDATE_CREDITS_EVENT, props.onUpdateCreditsEvent);
        props.echoAddChannelListener(`user.${props.user.id}`, UPDATE_SUBSCRIPTION_EVENT, props.onUpdateSubscriptionEvent);
        props.echoAddChannelListener(`user.${props.user.id}`, UPDATE_PROPERTY_IMPORT_EVENT, props.onUpdatePropertyImportEvent);
        props.echoAddChannelListener(`user.${props.user.id}`, UPDATE_CRM_SYSTEM_ACCOUNT_EVENT, props.onUpdateCrmSystemAccountEvent);

        return () => {
            EchoClient.leaveAll();

            EchoClient.destroy();
        };
    }, []);

    return (
        props.children
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

const mapDispatchToProps = {
    echoAddChannelListener,
    onChatMessageEvent,
    onChatSeenEvent,
    onInviteNewLinkEvent,
    onUpdateCreditsEvent,
    onUpdateSubscriptionEvent,
    onUpdatePropertyImportEvent,
    onUpdateCrmSystemAccountEvent,
};

export const SocketWrapper = connect(mapStateToProps, mapDispatchToProps)(SocketWrapperComponent);