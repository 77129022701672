import { combineReducers } from "redux";

import listReducer from './list/reducer';
import suggestReducer from './suggest/reducer';

const propertyReducer = combineReducers({
    list: listReducer,
    suggest: suggestReducer,
});

export default propertyReducer;