import { combineReducers } from "redux";

import dataReducer from './data/reducer';
import sendReducer from './send/reducer';
import messagesReducer from './messages/reducer';
import attachmentReducer from './attachment/reducer';
import messageReducer from './message/reducer';

const conversationReducer = combineReducers({
    data: dataReducer,
    send: sendReducer,
    messages: messagesReducer,
    attachment: attachmentReducer,
    message: messageReducer,
});

export default conversationReducer;