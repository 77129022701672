import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { BillingInfoEdit } from '../../BillingInfoEdit';

const BillingPaymentInfoComponent = (props) => {
    return (
        <Row>
            <Col lg="12">
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">{props.t('billing_info')}</CardTitle>

                        <BillingInfoEdit disabledMode={true} />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const BillingPayBillingInfo = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingPaymentInfoComponent)));