import _ from 'lodash';

import {
    PROPERTY_AGE_BRAND_NEW,
    PROPERTY_AGE_RECENT,
    PROPERTY_AGE_TO_RENOVATE,
} from '../../consts/property';

export const getAgeOptions = (t) => {
    return [{
        label: t('property_age.brand_new'),
        value: PROPERTY_AGE_BRAND_NEW,
    }, {
        label: t('property_age.recent'),
        value: PROPERTY_AGE_RECENT,
    }, {
        label: t('property_age.to_renovate'),
        value: PROPERTY_AGE_TO_RENOVATE,
    }];
};

export const getAge = (value, t) => {
    const options = getAgeOptions(t);
    const item = _.find(options, { value: value });

    return item || {
        label: '',
        value: '',
    };
};

export default {
    getAgeOptions,
    getAge,
};