import {
    PROPERTY_REQUEST_HOME_WIZARD_TOTAL_FIELDS,
    PROPERTY_REQUEST_INVEST_WIZARD_TOTAL_FIELDS,
} from '../../consts/property';

export const getHomeWizardPercent = (fields) => {
    if (fields) {
        const fieldValues = Object.values(fields);
        const filledValues = fieldValues.filter(item => {
            if (item) {
                if (Array.isArray(item) && item.length === 0) {
                    return false;
                }

                return item;
            }

            return false;
        });

        const fieldsCount = filledValues.length;

        return Math.floor((fieldsCount / PROPERTY_REQUEST_HOME_WIZARD_TOTAL_FIELDS) * 100);
    }

    return 0;
};

export const getInvestWizardPercent = (fields) => {
    if (fields) {
        const fieldValues = Object.values(fields);
        const filledValues = fieldValues.filter(item => item);

        const fieldsCount = filledValues.length;

        return Math.floor((fieldsCount / PROPERTY_REQUEST_INVEST_WIZARD_TOTAL_FIELDS) * 100);
    }

    return 0;
};
