export const createWhisperChannel = (userId, participantId) => {
    let a = userId;
    let b = participantId;

    if (participantId < userId) {
        a = participantId;
        b = userId;
    }

    return `users.${a}.${b}`;
};