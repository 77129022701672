import { takeEvery, fork, put, all, select } from 'redux-saga/effects';
import toastr from "toastr";

import { ON_UPDATE_SUBSCRIPTION_EVENT } from './actionTypes';
import { setUserSubscription } from "../../user/actions";
import i18n from "../../../i18n";
import { SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_TRIALING, SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT } from '../../../consts/billing/subscription';

export const userState = (state) => state.user;

function* onUpdateSubscriptionEvent({ payload: { data } }) {
    const { subscription } = data;

    const user = yield select(userState);
    const userSubscription = user?.data?.subscription;

    let toastMessage = null;

    if (subscription) {
        if (subscription?.status === SUBSCRIPTION_STATUS_ACTIVE) {
            if (userSubscription === null || userSubscription?.status === SUBSCRIPTION_STATUS_TRIALING) {
                // if current no sub or was trialing and pay new subscription
                toastMessage = 'billing.subscription.subscribed.success'
            } else if (
                userSubscription?.status === SUBSCRIPTION_STATUS_ACTIVE &&
                userSubscription?.tariff?.id !== subscription?.tariff?.id
            ) {
                // if swap subscription
                toastMessage = 'billing.subscription.swap.success'
            }
        } else if (
            subscription?.status === SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT &&
            (userSubscription === null || userSubscription?.status === SUBSCRIPTION_STATUS_TRIALING)
        ) {
            // if current no sub or trialing and pay new subscription with 3d-secure payment method
            toastMessage = 'billing.subscription.subscribed.success'
        }
    }

    if (toastMessage) {
        toastr.success(i18n.t(toastMessage));
    }

    // set user subscription
    yield put(setUserSubscription(subscription));
}

export function* watchUpdateSubscriptionEvent() {
    yield takeEvery(ON_UPDATE_SUBSCRIPTION_EVENT, onUpdateSubscriptionEvent)
}

function* subscriptionSaga() {
    yield all([
        fork(watchUpdateSubscriptionEvent),
    ]);
}

export default subscriptionSaga;
