import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";

import { useTitle } from "../../../../../hooks";
import { PageTitle } from '../../../../../components/Custom';
import { BillingOverviewInfo } from './BillingOverviewInfo';
import { LatestInvoices } from '../../Invoice';

const BillingOverviewComponent = (props) => {
    useTitle(props.t('title.billing_portal.overview'));

    const history = useHistory();

    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <React.Fragment>

            <PageTitle title={props.t('title.overview')} />

            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <CardTitle className={'mb-4'}>{props.t('billing.overview_info.title')}</CardTitle>

                            <BillingOverviewInfo />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <PageTitle title={props.t('invoices')} />

            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <Row className="no-gutters justify-content-between">
                                <CardTitle className={'mb-4'}>{props.t('latest_invoices')}</CardTitle>

                                <div className="button-items">
                                    <Button color="primary" outline className={'waves-effect waves-light'} onClick={() => history.push('/billing/invoices')} >
                                        {props.t('show_all')}
                                    </Button>
                                </div>
                            </Row>

                            <LatestInvoices />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export const BillingOverview = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingOverviewComponent)));