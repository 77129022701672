import React, { useMemo } from "react"
import ReactApexChart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import variables from "../../../../assets/scss/custom-variables.module.scss"
export const ChartBar = (props) => {
    const { t } = useTranslation();

    const categories = useMemo(() => {
        let label = t('active_invites');
        label = label.split(' ');

        return [
            t('invites'),
            label,
            t('clients'),
        ];
    }, []);

    const series = useMemo(() => {
        if (!props.data) {
            return [{ data: [] }];
        }

        const {
            links_count,
            active_links_count,
            receivers_count,
        } = props.data;

        let values = [
            links_count,
            active_links_count,
            receivers_count,
        ];

        const result = [{
            data: values,
        }];

        return result;
    }, [props.data]);

    const options = useMemo(() => {
        return {
            chart: {
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    barHeight: '70%',
                    distributed: true,
                    horizontal: true,
                }
            },
            dataLabels: {
                enabled: true,
            },
            colors: [variables.mainGreen, variables.mainRed, variables.mainOrange],
            grid: {
                borderColor: "#f1f1f1",
            },
            xaxis: {
                categories: categories,
            },
            yaxis: {
                labels: {
                    show: true,
                },
            },
            tooltip: {
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function () {
                            return ''
                        }
                    }
                }
            }
        };
    }, [categories]);

    return (
        <ReactApexChart options={options} series={series} type="bar" height="219" />
    )
};