import React, { useMemo, useState } from 'react';
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Badge, Card, CardBody, Col, Row, Table } from "reactstrap";
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import './PropertyDetailsBlock.scss';

import { PropertyStatusLabel, OfferPriceValue, OfferPriceDiff, Icon } from '../../../../../../components/Custom';
import { ROLE_CLIENT, ROLE_MANAGER } from '../../../../../../consts/roles';
import { useAllowed } from '../../../../../../hooks';
import { ClientsFavoriteProperty } from '../../../Favorites';

const MAIN_MODE = 'main';
const ADDITIONAL_MODE = 'additional';

export const PropertyDetailsBlock = ({ mode, property, user }) => {
    const { t } = useTranslation();

    let onlyClient = useAllowed([ROLE_CLIENT], user.role);

    const [clientsModalVisible, setClientsModalVisible] = useState(false);

    const favoritesCount = useMemo(() => {
        if (property) {
            return user.role === ROLE_MANAGER ? property.total_favorites_count : property.linked_favorites_count;
        }
        return '';
    }, [property]);

    const handleFavoriteClick = (e) => {
        e.preventDefault();
        setClientsModalVisible(true);
    };

    const renderOfferPrice = () => {
        const offerPrice = property?.myReceivedLink?.price;
        return (
            <>
                <OfferPriceValue offerPrice={offerPrice} />
                {
                    property.price !== null && (
                        <>
                            {' ('}
                            <OfferPriceDiff offerPrice={offerPrice} price={property.price} reverse suffix={false} textClass={'font-size-13'} />
                            {')'}
                        </>
                    )
                }
            </>
        );
    };

    const isNumValueExist = (value) => {
        return value !== null && value >= 0;
    };

    const mainData = [
        {
            icon: () => <Icon name={'shape-polygon'} color={'primary'} />,
            label: () => t('province'),
            value: () => property.city?.region?.name,
            wrapClass: 'font-size-14',
        },
        {
            icon: () => <Icon name={'building'} color={'primary'} />,
            label: () => t('city'),
            value: () => property.city?.name,
            wrapClass: 'font-size-14',
        },
        {
            icon: () => <Icon name={'map-pointer'} color={'primary'} />,
            label: () => t('address'),
            value: () => property.address,
            wrapClass: 'font-size-14',
        },
        {
            label: () => t('price'),
            value: () => (
                property.price !== null && property.price >= 0 ? (
                    <NumberFormat value={property.price} thousandSeparator={' '} prefix={'€ '} displayType={'text'} />
                ) : '-'
            ),
            hidden: true,
        },
        {
            label: () => t('new_price'),
            value: () => renderOfferPrice(),
            hidden: true,
        },
        {
            label: () => t('status'),
            value: () => <PropertyStatusLabel status={property.status} />,
            hidden: true,
        },
        {
            icon: () => <Icon name={'size'} color={'primary'} />,
            label: () => t('property_size'),
            value: () => property.size + ' m²',
            hidden: !isNumValueExist(property?.size),
            wrapClass: 'font-size-14',
        },
        {
            icon: () => <Icon name={'bed'} color={'primary'} />,
            label: () => t('bedrooms'),
            value: () => property.bedrooms,
            hidden: !isNumValueExist(property?.bedrooms),
            wrapClass: 'font-size-14',
        },
        {
            icon: () => <Icon name={'bathrooms'} color={'primary'} />,
            label: () => t('bathrooms'),
            value: () => property.bathrooms,
            hidden: !isNumValueExist(property?.bathrooms),
            wrapClass: 'font-size-14',
        },
        {
            icon: () => <Icon name={'work-instruments'} color={'primary'} />,
            label: () => t('property_year'),
            value: () => property.year,
            hidden: !property.year,
            wrapClass: 'font-size-14',
        },
        {
            icon: () => <Icon name={'calendar'} color={'primary'} />,
            label: () => t('available_from'),
            value: () => moment(property.available_from).format('DD/MM/YYYY'),
            hidden: !property.available_from,
            wrapClass: 'font-size-14',
        },
    ];

    const additionalData = [
        {
            label: () => t('zip_code'),
            value: () => property.zip,
        },
        {
            label: () => t('unit'),
            value: () => property.unit,
            hidden: !property.unit,
        },
        {
            label: () => t('size_garden'),
            value: () => property.size_garden + ' m²',
            hidden: !isNumValueExist(property?.size_garden),
        },
        {
            label: () => t('size_terrace'),
            value: () => property.size_terrace + ' m²',
            hidden: !isNumValueExist(property?.size_terrace),
        },
        {
            label: () => t('garages'),
            value: () => property.garages,
            hidden: !isNumValueExist(property.garages),
        },
        {
            label: () => t('garden_orientation'),
            value: () => property.garden_orientation,
            hidden: !property.garden_orientation,
        },
        {
            label: () => t('epc'),
            value: () => property.epc + ' kWh/m²',
            hidden: !isNumValueExist(property.epc),
        },
        {
            label: () => t('project'),
            value: () => property.project?.name,
            hidden: user.role === ROLE_CLIENT,
        },
        {
            label: () => t('title.consultant'),
            value: () => (
                <Link to={`/contacts/${property.myReceivedLink.sender.id}`} className={'text-dark text-decoration-underline'}>
                    {property.myReceivedLink.sender.name}
                </Link>
            ),
            hidden: !property.myReceivedLink,
        },
        {
            label: () => t('favorites'),
            value: () => (
                <Link to={'#'} onClick={handleFavoriteClick}>
                    <Badge className={'font-size-12 badge-soft-primary'} pill>
                        {favoritesCount}
                    </Badge>
                </Link>
            ),
            hidden: onlyClient,
        },
    ];

    const tableConfig = () => {
        let config = [];

        if (mode.includes(MAIN_MODE)) {
            config = mainData;
        }

        if (mode.includes(ADDITIONAL_MODE)) {
            config = [...config, ...additionalData];
        }

        return config;
    };

    return (
        <Row>
            <Col>
                <Card>
                    <CardBody className={'card_pv_xs'}>
                        <div className="property-details-block">
                            <div className="table-responsive">
                                <Table className="table-row-separator mb-0">
                                    <tbody>
                                        {tableConfig().map((item, index) => (
                                            !item.hidden ? (
                                                <tr key={'details-' + index} className={item.wrapClass}>
                                                    <th scope="row">
                                                        {item.icon && (
                                                            <span className={'mr-2 text-dark'}>{item.icon()}</span>
                                                        )}
                                                        {item.label()} :
                                                    </th>
                                                    <td>{item.value()}</td>
                                                </tr>
                                            ) : null
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </CardBody>

                    {!onlyClient && (
                        <ClientsFavoriteProperty propertyId={property.id} visible={clientsModalVisible} toggleVisible={() => setClientsModalVisible(!clientsModalVisible)} />
                    )}
                </Card>
            </Col>
        </Row>
    );
};