import {
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_CLEAN,
} from "./actionTypes";

export const userResetPassword = (params, history) => {
    return {
        type: RESET_PASSWORD,
        payload: { params, history }
    };
};

export const userResetPasswordSuccess = data => {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: data,
    };
};

export const userResetPasswordError = data => {
    return {
        type: RESET_PASSWORD_ERROR,
        payload: data
    };
};

export const userResetPasswordClean = () => {
    return {
        type: RESET_PASSWORD_CLEAN,
    };
};
