import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Switch, Redirect, Route, withRouter, useHistory } from "react-router-dom";
import { withTranslation, useTranslation } from 'react-i18next';
import { Container, Alert, Button } from "reactstrap";

import {
    clientRoutes,
    consultantRoutes,
    managerRoutes,
} from "../../routes/allRoutes";
import VerticalLayout from "../../components/VerticalLayout";
import { Preloader, SocketWrapper } from '../../components/Custom';
import { fetchUser, logoutUser, setLanguage } from '../../store/actions';
import { API } from '../../api';
import { ROLE_CLIENT, ROLE_CONSULTANT, ROLE_MANAGER } from '../../consts/roles';
import { ProtectedRoute } from '../../routes/middleware/ProtectedRoute';

const MainComponent = (props) => {
    const { t, i18n } = useTranslation();

    const history = useHistory();

    useEffect(() => {
        props.setLanguage(i18n.language);
        API.setLang(i18n.language);

        props.fetchUser(history);
    }, []);

    const logOut = () => {
        props.logoutUser(history)
    };

    const renderRoutesByRole = () => {
        let routes = [];
        switch (props.data.role) {
            case ROLE_CLIENT:
                routes = clientRoutes;
                break;

            case ROLE_CONSULTANT:
                routes = consultantRoutes;
                break;

            case ROLE_MANAGER:
                routes = managerRoutes;
                break;

            default:
                break;
        }

        return routes.map((route, idx) => (
            <ProtectedRoute
                {...route}
                path={route.path}
                key={idx}
                middlewares={route.middlewares}
                component={route.component}
            />
        ))
    };

    return (
        <>
            {props.loading ? (
                <Preloader loading={props.loading} />
            ) : props.data && !props.loading ? (
                <SocketWrapper>
                    <VerticalLayout>
                        <Switch>
                            <Route exact path={'/'}>
                                <Redirect to={`/dashboard`} />
                            </Route>

                            { renderRoutesByRole() }

                        </Switch>
                    </VerticalLayout>
                </SocketWrapper>
            ) : (
                <Container>
                    {props.error && (
                        <>
                            <Alert color="danger mt-5">{props.error}</Alert>
                            <Button type="button" color="primary" onClick={logOut}>
                                {t('go_to')} {t('login')}
                            </Button>
                        </>
                    )}
                </Container>
            )}
        </>
    );
};

const mapStateToProps = state => {
    const { data, loading, error } = state.user;
    return {
        data,
        loading,
        error,
    };
};

const mapDispatchToProps = {
    fetchUser,
    logoutUser,
    setLanguage,
};

export const Main = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MainComponent)));