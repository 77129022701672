import React from 'react';
import { Row, Col, Button, FormGroup, Label } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, FormSection } from 'redux-form'
import { connect } from "react-redux";

import { INVITES_FILTER_FORM } from "../../../../../consts/forms";
import SelectInput from "../../../../../components/Common/SelectInput";
import SwitchField from "../../../../../components/Common/SwitchField";
import { ROLE_CLIENT } from '../../../../../consts/roles';

import { PriceSliderRangeField, CitySelectField } from '../../../../../components/Custom/index';
import { PROPERTY_PRICE_MAX, PROPERTY_PRICE_MIN } from "../../../../../consts/property";

const InvitesFilterFormComponent = (props) => {
    const handleReset = () => {
        props.reset(INVITES_FILTER_FORM);

        props.onReset();
    };

    return (
        <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="form-horizontal"
            noValidate={true}
        >
            {props.user.role === ROLE_CLIENT && (
                <Field
                    name="companies"
                    label={props.t('field.label.vendors')}
                    placeholder={props.t('field.placeholder.vendors')}
                    id={'companies'}
                    component={SelectInput}
                    options={props.companyOptions}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.id}`}
                    isMulti
                />
            )}

            <CitySelectField
                name="cities"
                label={props.t('field.label.cities')}
                placeholder={props.t('field.placeholder.search_select_cities')}
                id={'cities'}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                isMulti
            />

            <FormSection name={'price'}>
                <FormGroup row className={'mb-4'}>
                    <Label className={'col-form-label col-lg-2'}>{props.t('field.label.property.price')}</Label>

                    <Col lg="10">
                        <Field
                            name="range"
                            component={PriceSliderRangeField}
                            limit={{
                                min: PROPERTY_PRICE_MIN,
                                max: PROPERTY_PRICE_MAX,
                            }}
                            inputMinConfig={{
                                id: 'price_min',
                                placeholder: 'field.placeholder.price_from'
                            }}
                            inputMaxConfig={{
                                id: 'price_max',
                                placeholder: 'field.placeholder.price_to'
                            }}
                        />
                    </Col>
                </FormGroup>

                <Field
                    name="empty_price"
                    label={''}
                    placeholder={props.t('field.placeholder.property_without_price')}
                    id={'empty_price'}
                    type="checkbox"
                    component={SwitchField}
                />
            </FormSection>

            <Field
                name="links_statuses"
                label={props.t('field.label.property_statuses')}
                placeholder={props.t('field.placeholder.property_statuses')}
                id={'invite-statuses'}
                component={SelectInput}
                options={props.statusOptions}
                isMulti
                isSearchable={false}
            />

            <Row className="justify-content-end">
                <Col lg="10">
                    <div className="button-items">
                        <Button
                            type="submit"
                            color="primary"
                        >
                            {props.t('apply')}
                        </Button>

                        <Button
                            type="button"
                            color="light"
                            outline
                            onClick={handleReset}
                        >
                            {props.t('reset')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};

const InvitesFilter = reduxForm({
    form: INVITES_FILTER_FORM,
    enableReinitialize: true,
})(InvitesFilterFormComponent);

const mapStateToProps = state => {
    return {
        user: state.user.data,
    };
};

const mapDispatchToProps = {
};

export const InvitesFilterForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvitesFilter));