import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "react-i18next";
import { getPropertyStatusConfig } from "helpers";

import s from "./PropertyStatusMark.module.scss"
import classname from "classnames";

export const PropertyStatusMark = ({ status, className }) => {
    const { t } = useTranslation();
    const config = useMemo(() => {
        return getPropertyStatusConfig(status, t);
    }, [status]);

    return (
        <div className={classname(`${s.property_status_mark} bg-${config.background}`, {
            [className]: className
        })}>
            {config.label}
        </div>
    );
};

PropertyStatusMark.propTypes = {
    status: PropTypes.string,
    classname: PropTypes.string
};


