import React from 'react';
import {connect} from "react-redux";

import {CRM_SYSTEM} from "consts/crmSystem"
import {BasicProvider} from "./BasicProvider";
import {WhiseProvider} from "./WhiseProvider";
import {
  selectCrmAccountCreateIsLoading,
  selectCrmAccountRemoveIsLoading,
  selectCrmAccountSyncStartIsLoading
} from "store/selectors";

const CrmSystemProviderComponent = (props) => {
  switch (props.crm) {
    case CRM_SYSTEM.WHISE:
      return <WhiseProvider {...props} />

    default:
      return <BasicProvider {...props} />
  }
};

const mapStateToProps = (state, {crm}) => {
  return {
    accountCreateProps: {
      loading: selectCrmAccountCreateIsLoading(state, crm),
    },
    accountRemoveProps: {
      loading: selectCrmAccountRemoveIsLoading(state, crm),
    },
    syncProps: {
      loading: selectCrmAccountSyncStartIsLoading(state, crm),
    },
  }
};

const mapDispatchToProps = {};

export const CrmSystemProvider = connect(mapStateToProps, mapDispatchToProps)(CrmSystemProviderComponent);