import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_CLIENT_OWNED_PROPERTIES } from './actionTypes';
import {
    fetchClientOwnedPropertiesSuccess,
    fetchClientOwnedPropertiesError,
} from './actions';

import { API } from '../../../../api';
import { getMapRequestParams } from '../../../../utils/request';
import { PROPERTY_TYPE_OWNED } from '../../../../consts/property';
import { clientPropertiesStatisticsUpdate } from "../statistics/actions";

function* fetchOwnedProperties({ payload: { params } }) {
    try {
        const includes = ['city'];

        let requestParams = {
            includes,
            filter: {
                types: [PROPERTY_TYPE_OWNED],
            }
        };

        requestParams = getMapRequestParams(params, requestParams);

        const response = yield call(API.property.getPropertyList, requestParams);

        const { data } = response;
        yield put(fetchClientOwnedPropertiesSuccess(data));

        //Update statistics of owned properties
        yield put(clientPropertiesStatisticsUpdate({ my: data.meta.total }))

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchClientOwnedPropertiesError(message));
    }
}

export function* watchPropertyListFetch() {
    yield takeLatest(FETCH_CLIENT_OWNED_PROPERTIES, fetchOwnedProperties)
}

function* ownedSaga() {
    yield all([
        fork(watchPropertyListFetch),
    ]);
}

export default ownedSaga;