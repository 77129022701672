import React, { useEffect } from 'react';
import { Col, FormGroup, Label, Row, Button, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, FormSection } from 'redux-form'

import RadioField from '../../../../components/Common/RadioField';
import CheckboxGroupField from '../../../../components/Common/CheckboxGroupField';
import SelectInput from "../../../../components/Common/SelectInput";
import ButtonLoadingIcon from '../../../../components/Common/ButtonLoadingIcon';
import { FORM_LEAD_PREFERENCES } from '../../../../consts/forms';
import {
    PROPERTY_PRICE_MAX,
    PROPERTY_PRICE_MIN,
} from "../../../../consts/property";
import { PriceSliderRangeField, CitySelectField, PropertyTypesCheckboxField } from "../../../../components/Custom";
import { propertyRequestHelper } from '../../../../helpers';
import { PROPERTY_REQUEST_PRICE_RANGE_STEP } from '../../../../consts/propertyRequest';
import { normalizeBoolean } from "../../../../utils/normalize";

const LeadPreferencesFormComponent = (props) => {
    useEffect(() => {
        return () => {
        };
    }, []);

    const ages = propertyRequestHelper.age.getAgeOptions(props.t);

    return (
        <>
            <form
                onSubmit={props.handleSubmit(props.onSubmit)}
                className="form-horizontal"
                noValidate={true}
            >

                <CardTitle className={'mb-4'}>{props.t('new_home')}</CardTitle>

                <FormSection name={'price'}>
                    <FormGroup row className={'mb-4'}>
                        <Label className={'col-form-label col-lg-2'}>{props.t('field.label.property.price')}</Label>

                        <Col lg="10">
                            <Field
                                name="range"
                                component={PriceSliderRangeField}
                                limit={{
                                    min: PROPERTY_PRICE_MIN,
                                    max: PROPERTY_PRICE_MAX,
                                }}
                                inputMinConfig={{
                                    id: 'price_min',
                                    placeholder: 'field.placeholder.price_from'
                                }}
                                inputMaxConfig={{
                                    id: 'price_max',
                                    placeholder: 'field.placeholder.price_to'
                                }}
                                step={PROPERTY_REQUEST_PRICE_RANGE_STEP}
                            />
                        </Col>
                    </FormGroup>
                </FormSection>

                <PropertyTypesCheckboxField
                    name="property_types"
                    checkboxClassName={'custom-control-inline'}
                    label={props.t('field.label.property_types')}
                />

                <Field
                    name="regions"
                    label={props.t('field.label.provinces')}
                    placeholder={props.t('field.placeholder.provinces')}
                    id={'regions'}
                    component={SelectInput}
                    options={props.provinceOptions}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.id}`}
                    isMulti
                />

                <CitySelectField
                    name="cities"
                    label={props.t('field.label.cities')}
                    placeholder={props.t('field.placeholder.search_select_cities')}
                    id={'cities'}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.id}`}
                    isMulti
                />

                <CheckboxGroupField
                    name="property_ages"
                    options={ages}
                    checkboxClassName={'custom-control-inline'}
                    label={props.t('field.label.property_ages')}
                />

                <CardTitle className={'mt-4 mb-4'}>{props.t('investment')}</CardTitle>

                <FormGroup row tag="fieldset">
                    <Label className={'col-form-label col-lg-2'}>{props.t('field.label.show_investment_lead')}</Label>

                    <Col lg="10">
                        <div className={'form-control custom-switch-filed-wrap border-0 p-0 flex-wrap'}>
                            <div className={'pt-1 pb-1'}>
                                <Field
                                    name="show_investments"
                                    value={true}
                                    label={props.t('yes')}
                                    id={`show-investment-yes`}
                                    type="radio"
                                    component={RadioField}
                                    normalize={normalizeBoolean}
                                />
                            </div>

                            <div className={'pt-1 pb-1'}>
                                <Field
                                    name="show_investments"
                                    value={false}
                                    label={props.t('no')}
                                    id={`show-investment-no`}
                                    type="radio"
                                    component={RadioField}
                                    normalize={normalizeBoolean}
                                />
                            </div>
                        </div>
                    </Col>
                </FormGroup>

                <Row className="justify-content-end">
                    <Col lg="10">
                        <Button
                            type="submit"
                            color="primary"
                            disabled={props.loading}
                        >
                            <ButtonLoadingIcon loading={props.loading} />
                            {props.t('save')}
                        </Button>
                    </Col>
                </Row>
            </form>
        </>
    );
};

const LeadPreferences = reduxForm({
    form: FORM_LEAD_PREFERENCES,
    enableReinitialize: true,
})(LeadPreferencesFormComponent);

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const LeadPreferencesForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadPreferences)));