export const requiredMessage = (field) => `The ${field} is required`;

export const isValidEmail = (value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

export const isValidPhone = (value) => /^\+32 \d{2} \d{2} \d{2} \d{2}$/i.test(value);

export const required = value => value ? undefined : 'field.error.required';

export const email = value => value && !isValidEmail(value) ? 'field.error.invalid' : undefined;

export const phone = value => value && !isValidPhone(value) ? 'field.error.invalid' : undefined;

export const deleteAccount = value => value && value === 'DELETE' ? undefined : 'field.error.error';

export const minLength = min => value =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const length = length => value =>
    value && value.toString().length !== length ? `Must be ${length} characters` : undefined;

export const number = value => value && isNaN(Number(value)) ? 'field.error.number' : undefined;

export const minValue = min => value =>
    value && value < min ? `field.error.min_value` : undefined;

export const maxValue = max => value =>
    value && value > max ? `field.error.max_value` : undefined;

export const isURL = value => {
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(value) ? undefined : 'field.error.invalid';
};

export const requiredArray = value => value && value.length > 0 ? undefined : 'field.error.required';