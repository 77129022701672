import React from 'react';

export const Bed = ({
    width = "14",
    height = "14",
    viewBox = "0 0 14 14",
    color = '#74788D',
}) => (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.15786 1.61983C1.35419 1.39862 1.64813 1.27147 1.94344 1.27284C5.3152 1.27311 8.68696 1.27256 12.0587 1.27311C12.263 1.2712 12.4661 1.33108 12.6365 1.44374C12.9288 1.63241 13.1134 1.97475 13.109 2.32284C13.109 3.3444 13.1095 4.36596 13.1087 5.38753C12.4347 5.38725 11.7609 5.38999 11.0869 5.38616C11.0836 5.06241 10.9059 4.77038 10.6874 4.5437C10.3735 4.21776 9.9743 3.98753 9.56333 3.80624C8.65469 3.41905 7.65419 3.27413 6.6709 3.31596C5.98868 3.34768 5.30864 3.47319 4.66825 3.71299C4.26192 3.86885 3.86708 4.06737 3.52883 4.34381C3.21356 4.60303 2.92098 4.95768 2.9136 5.38534C2.23958 5.39081 1.56528 5.38725 0.891255 5.38725C0.889888 4.36706 0.891255 3.34686 0.890708 2.32639C0.889888 2.06881 0.985044 1.81178 1.15786 1.61983Z" fill={color}/>
        <path d="M0.461363 6.80429C0.656871 6.59237 0.9448 6.47616 1.23164 6.47534C5.08601 6.47644 8.94039 6.47452 12.7948 6.47644C13.2889 6.47425 13.7715 6.87565 13.786 7.3837C13.7903 8.81378 13.7868 10.2441 13.7879 11.6745C13.7923 11.9643 13.7184 12.2662 13.5322 12.494C13.4207 12.6323 13.2517 12.7321 13.0701 12.7267C12.6239 12.7258 12.1776 12.7267 11.7314 12.7264C11.5421 12.7267 11.406 12.5727 11.2889 12.4437C10.9999 12.0997 10.7853 11.7018 10.575 11.307C8.19171 11.3051 5.80843 11.3045 3.42515 11.3073C3.21214 11.7078 2.99312 12.1117 2.69753 12.4584C2.58351 12.5812 2.45089 12.7269 2.2696 12.7261C1.82335 12.7275 1.37738 12.725 0.931128 12.7269C0.762691 12.7316 0.602183 12.6474 0.492261 12.5232C0.299214 12.308 0.216636 12.013 0.211988 11.7286C0.211441 10.2975 0.212261 8.86655 0.211441 7.43565C0.210074 7.20323 0.301675 6.973 0.461363 6.80429Z" fill={color}/>
    </svg>
);