import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { TabContent, TabPane } from "reactstrap";

import { ChatList } from '../ChatList';
import { ContactList } from '../ContactList';
import { ROLE_CONSULTANT } from '../../../../../../consts/roles';
import { CHAT_TABS } from "../../../../../../consts/chat";

const TabBodyComponent = (props) => {
    return (
        <TabContent activeTab={props.activeTab} className="py-4">
            <TabPane tabId={CHAT_TABS.CHAT}>
                <div>
                    <h5 className="font-size-14 mb-3">{props.t('recent')}</h5>

                    <ChatList />
                </div>
            </TabPane>

            <TabPane tabId={CHAT_TABS.CONTACTS}>
                <h5 className="font-size-14 mb-3">
                    {props.t(props.user.role === ROLE_CONSULTANT ? 'clients' : 'contacts')}
                </h5>

                <div>
                    <ContactList />
                </div>
            </TabPane>
        </TabContent>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    const { activeTab } = state.chat.tabs;

    return {
        user: data,
        activeTab
    };
};

const mapDispatchToProps = {
};

export const TabBody = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TabBodyComponent)));