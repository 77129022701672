import {
    FETCH_SUBSCRIPTION_PAYMENT_LINK,
    FETCH_SUBSCRIPTION_PAYMENT_LINK_SUCCESS,
    FETCH_SUBSCRIPTION_PAYMENT_LINK_ERROR,
    CLEAN_SUBSCRIPTION_PAYMENT_LINK,
} from './actionTypes';

export const fetchSubscriptionPaymentLink = () => {
    return {
        type: FETCH_SUBSCRIPTION_PAYMENT_LINK,
    }
};

export const fetchSubscriptionPaymentLinkSuccess = (data) => {
    return {
        type: FETCH_SUBSCRIPTION_PAYMENT_LINK_SUCCESS,
        payload: data,
    }
};

export const fetchSubscriptionPaymentLinkError = (data) => {
    return {
        type: FETCH_SUBSCRIPTION_PAYMENT_LINK_ERROR,
        payload: data,
    }
};

export const cleanSubscriptionPaymentLink = () => {
    return { type: CLEAN_SUBSCRIPTION_PAYMENT_LINK };
};