import React, { useState, useEffect, useMemo } from 'react';
import { Table, Badge } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import NumberFormat from 'react-number-format';
import { useTable, useSortBy } from 'react-table';

import { PropertyStatusLabel } from '../../../../../components/Custom';
import { ROLE_CLIENT, ROLE_MANAGER } from '../../../../../consts/roles';

import placeholder from '../../../../../assets/images/custom/property-placeholder.jpg';
import { useAllowed } from "../../../../../hooks";
import { ClientsFavoriteProperty } from "../../../../Property/components";

// self table because don't know fields need to show
const PropertiesTableComponent = (props) => {
    const { keys, items } = props;

    const data = useMemo(() => {
        return items;
    }, [items]);

    const [columns, setColumns] = useState([]);

    let onlyClient = useAllowed([ROLE_CLIENT], props.role);

    const [clientsModal, setClientsModal] = useState({
        isVisible: false,
        propertyId: null
    });

    const handleFavoriteClick = (e, propertyId) => {
        e.preventDefault();
        setClientsModal({
            isVisible: true,
            propertyId
        });
    };

    const toggleVisible = () => {
        setClientsModal({
            isVisible: false,
            propertyId: null
        });
    }

    const columnConfig = {
        'avatar': {
            Header: '',
            accessor: 'avatar',
            id: 'avatar',
            Cell: ({ value }) => (
                <img src={value ? value : placeholder} alt="" className="avatar-xs rounded-circle avatar-cover" />
            ),
            disableSortBy: true,
        },
        'city': {
            Header: props.t('city'),
            accessor: 'city.name',
            id: 'city',
        },
        'address': {
            Header: props.t('address'),
            accessor: 'address',
            id: 'address',
            Cell: ({ row, value }) => {
                const { original } = row;
                return (<Link to={`/properties/${original.id}`} className="text-dark font-weight-bold text-decoration-underline">{value || "-"}</Link>);
            },
        },
        'unit': {
            Header: props.t('unit'),
            accessor: 'unit',
            id: 'unit',
            Cell: ({ row, value }) => {
                return value ? value : '-';
            },
        },
        'price': {
            Header: () => (
                <div className={'w-100 text-center'}>{props.t('price')}</div>
            ),
            accessor: 'price',
            id: 'price',
            Cell: ({ row: { original }, value }) => {
                return (
                    <div className={'text-center text-dark font-weight-bold text-nowrap'}>
                        <div>
                            {value !== null && value >= 0 ? (
                                <NumberFormat
                                    value={value}
                                    thousandSeparator={' '}
                                    prefix={'€ '}
                                    displayType={'text'}
                                />
                            ) : '-' }
                        </div>
                    </div>
                );
            },
        },
        'status': {
            Header: props.t('status'),
            accessor: 'status',
            id: 'status',
            Cell: ({ value }) => (
                <PropertyStatusLabel status={value} />
            ),
        },
        'enabled': {
            Header: () => (
                <div className={'w-100 text-center'}>{props.t('show')}</div>
            ),
            accessor: 'enabled',
            id: 'enabled',
            Cell: ({ value }) => (
                <div className={'text-center'}>
                    <Badge
                        className={'font-size-12 badge-soft-' + (value ? 'primary' : 'secondary')}
                        pill
                    >
                        {value ? props.t('yes') : props.t('no')}
                    </Badge>
                </div>
            ),
        },
        'favorites_count': {
            Header: () => (
                <div className={'w-100 text-center'}>{props.t('favorites')}</div>
            ),
            accessor: 'favorites_count',
            id: 'users_favorites_count',
            Cell: ({ row: { original } }) => {
                const count = props.role === ROLE_MANAGER ? original.total_favorites_count : original.linked_favorites_count;
                return (
                    <Link to={"#"} onClick={(e) => handleFavoriteClick(e, original.id)}>
                        <div className={'text-center'}>
                            <Badge className={'font-size-12 badge-soft-primary'} pill>
                                {count}
                            </Badge>
                        </div>
                    </Link>
                );
            },
        },
        'invites': {
            Header: () => (
                <div className={'w-100 text-center'}>{props.t('invites')}</div>
            ),
            accessor: 'links_count',
            id: 'links_count',
            Cell: ({ value }) => {
                return (
                    <div className={'text-center'}>
                        <Badge className={'font-size-12 badge-soft-primary'} pill>
                            {value}
                        </Badge>
                    </div>
                );
            },
        },
    };

    useEffect(() => {
        let config = [];

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (columnConfig[key]) {
                config.push(columnConfig[key]);
            }
        }

        setColumns(config)
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [],
            },
            defaultCanSort: false,
            disableSortBy: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
        },
        useSortBy,
    );

    useEffect(() => {
        props.onSort(sortBy);
    }, [props.onSort, sortBy]);

    return (
        <div className="table-responsive table-centered">
            <Table className={'table-bordered table-part-bordered'} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps({ title: null }))}
                            >
                                <div className={'d-flex'}>
                                    <div className={'d-flex flex-fill'}>
                                        {column.render('Header')}
                                    </div>

                                    <div>
                                        {column.canSort && (
                                            column.isSorted ? (
                                                <i className={'bx font-size-16 align-middle' + (column.isSortedDesc ? " bx-sort-down" : " bx-sort-up")} />
                                            ) : <i className="bx bx-sort align-middle" style={{ color: '#adb5bd' }} />
                                        )}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {(
                    rows.length > 0 && rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                ) || (
                    <tr>
                        <td colSpan={columns.length}>
                            {!props.isLoading ? props.t('empty') : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}} />}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>

            {!onlyClient && (
                <ClientsFavoriteProperty propertyId={clientsModal.propertyId} visible={clientsModal.isVisible}
                                         toggleVisible={toggleVisible}/>
            )}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        role: state.user.data.role,
    };
};

const mapDispatchToProps = {
};

export const PropertiesTable = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertiesTableComponent));