import React from 'react';
import { PageTitle, SearchInput } from "components/Custom";
import s from "./DashboardHeader.module.scss"
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { connect, useSelector } from "react-redux";
import { useMedia } from "react-use";
import variables from "assets/scss/custom-variables.module.scss"
import {
    clientAcceptedPropertiesSetSearch,
    clientAllPropertiesSetSearch,
    clientFavoritePropertiesSetSearch, clientOwnedPropertiesSetSearch
} from "store/actions";
import { getPropertySearchValue } from "../../../../../../helpers";
import {
    CLIENT_PROPERTIES_ACCEPTED_TAB,
    CLIENT_PROPERTIES_ALL_TAB, CLIENT_PROPERTIES_FAVORITE_TAB,
    CLIENT_PROPERTIES_OWNED_TAB
} from "../../../../../../consts/property/clientProperties";
import { Badge } from "reactstrap";

export const DashboardHeaderComponent = (props) => {
    const { t } = useTranslation();
    const isWide = useMedia(`(min-width: ${variables.md})`);
    const propositionsCount = useSelector(state => state.user.data?.new_links_count)

    const handleSearchChange = (value) => {
        switch (props.activeTab) {
            case CLIENT_PROPERTIES_ALL_TAB: {
                props.clientAllPropertiesSetSearch(value);
                break;
            }
            case CLIENT_PROPERTIES_OWNED_TAB: {
                props.clientOwnedPropertiesSetSearch(value);
                break;
            }
            case CLIENT_PROPERTIES_FAVORITE_TAB: {
                props.clientFavoritePropertiesSetSearch(value);
                break;
            }
            case CLIENT_PROPERTIES_ACCEPTED_TAB: {
                props.clientAcceptedPropertiesSetSearch(value);
                break;
            }
        }
    };

    return (
        <div className={classnames(`${s.wrapper}`, {
            [props.wrapClass]: props.wrapClass
        })}>
            <div className={s.title}>
                <PageTitle title={t('dashboard')} wrapClass="mb-0"/>
            </div>
            <div className={s.search}>
                <SearchInput
                    searchValue={props.search}
                    onChange={handleSearchChange}
                    searchFields={
                        ['search.field.address', 'search.field.unit']
                    }
                    inputClass={"bg-white"}
                    hint={false}
                />
            </div>

            <div className={s.action}>
                <Link to={`/properties/create`} className={classnames("btn btn-primary", {
                    [s.action_create]: true
                })}>
                    <div>
                        <i className='bx bx-plus-circle align-middle'/>
                    </div>
                    {isWide && <div style={{ textTransform: 'capitalize' }}>
                        {t("add-new-property")}
                    </div>}
                </Link>

                <Link to={`/properties/invites`} className={classnames("btn btn-primary", {
                    [s.action_propositions]: true
                })}>

                    {!isWide && <div><i className='bx bx-detail align-middle'/></div>}

                    {isWide &&
                    <div className={"d-flex align-items-center"}>
                        <div>
                            {t("title.invite_propositions")}
                        </div>
                        {propositionsCount ?
                            <Badge className={'d-flex font-size-12 ml-2 badge-warning align-middle'} pill>
                                {propositionsCount}
                            </Badge> : null
                        }
                    </div>
                    }
                </Link>
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    const {
        activeTab
    } = state.property.clientProperties.config;

    const search = getPropertySearchValue(state.property.clientProperties, activeTab)

    return {
        search,
        activeTab
    };
};

const mapDispatchToProps = {
    clientAcceptedPropertiesSetSearch,
    clientAllPropertiesSetSearch,
    clientFavoritePropertiesSetSearch,
    clientOwnedPropertiesSetSearch,
};

export const DashboardHeader = withRouter(connect(mapStateToProps, mapDispatchToProps)((DashboardHeaderComponent)));


