import { takeEvery, fork, put, all } from 'redux-saga/effects';

import { ON_INVITE_NEW_LINK_EVENT } from './actionTypes';
import { updateNewInvitesCount } from "../../user/actions";

function* onInviteNewLinkEvent({ payload: { data } }) {
    const { links_count } = data;

    // update count all of unconfirmed invites (- badge in side menu, - client dashboard)
    yield put(updateNewInvitesCount(links_count));
}

export function* watchInviteNewLinkEvent() {
    yield takeEvery(ON_INVITE_NEW_LINK_EVENT, onInviteNewLinkEvent)
}

function* inviteSaga() {
    yield all([
        fork(watchInviteNewLinkEvent),
    ]);
}

export default inviteSaga;