import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_LEAD_PREFERENCES_DATA } from './actionTypes';
import {
    fetchLeadPreferencesSuccess,
    fetchLeadPreferencesError,
} from './actions';

import { API } from '../../../../api/index';

function* fetchLeadPreferences() {
    try {
        const response = yield call(API.lead.getLeadPreferences);

        const { data } = response;
        yield put(fetchLeadPreferencesSuccess(data.data || {}));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchLeadPreferencesError(message));
    }
}

export function* watchLeadPreferencesFetch() {
    yield takeLatest(FETCH_LEAD_PREFERENCES_DATA, fetchLeadPreferences)
}

function* viewSaga() {
    yield all([
        fork(watchLeadPreferencesFetch),
    ]);
}

export default viewSaga;