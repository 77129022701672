import React, { useEffect } from 'react';
import { Container } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import { useQuery, useTitle } from "../../hooks";
import { ROLE_CLIENT } from '../../consts/roles';
import { DashboardClient, DashboardStaff } from './components';

const DashboardComponent = (props) => {
    useTitle(props.t('title.dashboard'));
    let query = useQuery();

    useEffect(() => {
        if (query.get('verified') === '1') {
            toastr.success(props.t('verification.success'));
        }
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {props.user?.role === ROLE_CLIENT ? (
                        <DashboardClient />
                    ) : (
                        <DashboardStaff />
                    )}

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

const mapDispatchToProps = {
};

export const Dashboard = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardComponent)));