import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_BILLING_INVOICES } from './actionTypes';
import {
    fetchBillingInvoicesSuccess,
    fetchBillingInvoicesError
} from './actions';

import { API } from '../../../../api';
import { getMapRequestParams } from '../../../../utils/request';

function* fetchInvoices({ payload: { params } }) {
    try {
        const requestParams = getMapRequestParams(params);

        const response = yield call(API.billing.getInvoices, requestParams);

        const { data } = response;

        yield put(fetchBillingInvoicesSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchBillingInvoicesError(message));
    }
}


export function* watchBillingInvoicesFetch() {
    yield takeLatest(FETCH_BILLING_INVOICES, fetchInvoices)
}

function* listSaga() {
    yield all([
        fork(watchBillingInvoicesFetch),
    ]);
}

export default listSaga;