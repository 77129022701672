import React, { useMemo } from 'react';
import { Button } from "reactstrap";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import toastr from 'toastr';

import { leadListUpdateLeadStatusDecline, leadListUpdateLeadStatusBuy } from "../../../../../store/actions";
import { LEAD_COST_IN_CREDIT, LEAD_STATUS_UNPAID } from '../../../../../consts/lead';

const LeadUpdateStatusButtonsComponent = (props) => {
    const { lead } = props;

    const buy = () => {
        if (props.user?.credits < LEAD_COST_IN_CREDIT) {
            toastr.warning(props.t('buy_lead.not_enough_credits'));
        } else {
            props.leadListUpdateLeadStatusBuy(lead.id, lead);
        }
    };

    const decline = () => {
        props.leadListUpdateLeadStatusDecline(lead.id, lead);
    };

    const isDisabled = useMemo(() => {
        return props.pending.includes(lead.id);
    }, [lead.id, props.pending]);

    return (
        <>
            <div className="button-items">
                <Button
                    type="button"
                    color="success"
                    disabled={isDisabled}
                    onClick={buy}
                >
                    {props.t('buy')}
                </Button>

                {lead.status === LEAD_STATUS_UNPAID && (
                    <Button
                        type="button"
                        color="light"
                        disabled={isDisabled}
                        onClick={decline}
                    >
                        {props.t('decline')}
                    </Button>
                )}
            </div>
        </>
    );
};

const mapStateToProps = state => {
    const { pending } = state.lead.leadsStatuses;
    return {
        pending,
        user: state.user.data
    };
};

const mapDispatchToProps = {
    leadListUpdateLeadStatusDecline,
    leadListUpdateLeadStatusBuy,
};

export const LeadUpdateStatusButtons = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadUpdateStatusButtonsComponent));