import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_COMPANY_ACTUAL_UNPAID_LEADS } from './actionTypes';
import {
    fetchCompanyActualUnpaidLeadsSuccess,
    fetchCompanyActualUnpaidLeadsError,
    fetchCompanyActualUnpaidLeads as fetchCompanyActualUnpaidLeadsAction,
} from './actions';

import { companyLeadsPreferencesError } from '../config/actions';

import { API } from '../../../../api';
import { getMapRequestParams } from '../../../../utils/request';

function* fetchCompanyActualUnpaidLeads({ payload: { params } }) {
    try {
        const includes = ['propertyAd', 'propertyAd.user', 'propertyAd.cities', 'propertyAd.regions'];

        let requestParams = {
            includes,
            filter: {
                status: ['unpaid'],
                is_actual: true,
            }
        };

        requestParams = getMapRequestParams(params, requestParams);

        const response = yield call(API.lead.getCompanyLeads, requestParams);

        const { data } = response;

        const { meta: { current_page, last_page } } = data;
        if (current_page && last_page && current_page > last_page ) {
            yield put(fetchCompanyActualUnpaidLeadsAction({
                ...params,
                page: last_page,
            }));
        } else {
            yield put(fetchCompanyActualUnpaidLeadsSuccess(data));
        }

    } catch (error) {
        if (error.response && error.response.status === 403) {
            yield put(companyLeadsPreferencesError(true));
        } else {
            const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
            yield put(fetchCompanyActualUnpaidLeadsError(message));
        }
    }
}


export function* watchCompanyActualUnpaidLeadsFetch() {
    yield takeLatest(FETCH_COMPANY_ACTUAL_UNPAID_LEADS, fetchCompanyActualUnpaidLeads)
}

function* actualUnpaidSaga() {
    yield all([
        fork(watchCompanyActualUnpaidLeadsFetch),
    ]);
}

export default actualUnpaidSaga;