import React, { useState, useEffect, useMemo, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { API } from '../../../../api/index';
import { ContactsFilterForm } from './ContactsFilterForm';
import { getApplyFilterCount } from '../../../../utils/filter';
import { FilterModal } from '../../../../components/Custom';

const ContactsFilterComponent = (props) => {
    const [modalVisible, setModalVisible] = useState(false);

    const [companyOptions, setCompanyOptions] = useState([]);

    const fetchCompanies = async () => {
        try {
            const response = await API.invite.getCompaniesFromInvites({ pagination: false });
            const { data } = response;

            setCompanyOptions(data.data)
        } catch (e) {
        }
    };

    useEffect(() => {
        if (modalVisible) {
            fetchCompanies();
        } else {
            setCompanyOptions([]);
        }

    }, [modalVisible]);

    const modalRef = createRef();

    const initialFormValues = useMemo(() => {
        let values = {
            ...props.filter?.values,
        };

        return values;
    }, [props.filter]);

    const onSubmit = (values) => {
        let params = {
            ...values,
        };

        props.onApply(params);

        if (modalRef.current) {
            modalRef.current.hide();
        }
    };

    const handleReset = () => {
        props.onReset();

        if (modalRef.current) {
            modalRef.current.hide();
        }
    };

    const count = useMemo(() => {
        return props.filter?.isActive ? getApplyFilterCount(props.filter?.values) : 0;
    }, [props.filter]);

    return (
        <FilterModal
            onVisibleChange={(value) => setModalVisible(value)}
            count={count}
            ref={modalRef}
        >
            <ContactsFilterForm
                initialValues={initialFormValues}
                onSubmit={onSubmit}
                companyOptions={companyOptions}
                onReset={handleReset}
            />
        </FilterModal>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const ContactsFilter = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactsFilterComponent));