import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';
import i18n from '../../../../../i18n';

import { RESUME_BILLING_SUBSCRIPTION } from './actionTypes';
import {
    resumeBillingSubscriptionSuccess,
    resumeBillingSubscriptionError,
} from './actions';

import { API } from '../../../../../api';

function* resumeSubscription() {
    try {
        const response = yield call(API.billing.resumeSubscription);

        const { data } = response;
        yield put(resumeBillingSubscriptionSuccess(true));

        toastr.success(i18n.t('billing.subscription.resume.success'));
    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(resumeBillingSubscriptionError(message));
        toastr.error(message);
    }
}

export function* watchResumeSubscription() {
    yield takeEvery(RESUME_BILLING_SUBSCRIPTION, resumeSubscription)
}

function* resumeSaga() {
    yield all([
        fork(watchResumeSubscription),
    ]);
}

export default resumeSaga;