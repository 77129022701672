import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from 'react-router-dom';
import { Media } from "reactstrap";

import avatar from 'assets/images/custom/avatar-placeholder.png';
import { setChatConversation } from 'store/actions';

const ContactListItemComponent = (props) => {
    const { item } = props;

    const history = useHistory();

    const handleClick = (e) => {
        e.preventDefault();

        history.push('/chat');
        props.setChatConversation(item);
    };
    return (
        <li
            key={'chat-item-' + item.id}
        >
            <Link
                to="#"
                onClick={handleClick}
            >
                <Media>
                    <div className="align-self-center mr-3">
                        <i
                            className={
                                item.is_online
                                    ? "mdi mdi-circle text-success font-size-10"
                                    : "mdi mdi-circle font-size-10"
                            }
                        />
                    </div>
                    <div className="align-self-center mr-3">
                        <img
                            src={item.avatar ? item.avatar : avatar}
                            className="rounded-circle avatar-xs avatar-cover"
                            alt=""
                        />
                    </div>

                    <Media className="overflow-hidden align-self-center" body>
                        <h5 className="text-truncate font-size-14 mb-1">
                            {item.name}
                        </h5>
                    </Media>
                </Media>
            </Link>
        </li>
    );
};

const mapStateToProps = state => {
    const { participant } = state.chat.conversation.data;

    return {
        selectedConversationUser: participant,
    };
};

const mapDispatchToProps = {
    setChatConversation,
};

export const ContactListItem = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactListItemComponent)));