import {
    FETCH_TRANSLATIONS,
    FETCH_TRANSLATIONS_SUCCESS,
    FETCH_TRANSLATIONS_ERROR,
    CLEAN_TRANSLATIONS,
} from './actionTypes';

export const fetchTranslations = () => {
    return {
        type: FETCH_TRANSLATIONS,
    }
};

export const fetchTranslationsSuccess = (data) => {
    return {
        type: FETCH_TRANSLATIONS_SUCCESS,
        payload: data,
    }
};

export const fetchTranslationsError = (data) => {
    return {
        type: FETCH_TRANSLATIONS_ERROR,
        payload: data,
    }
};

export const cleanTranslations = () => {
    return { type: CLEAN_TRANSLATIONS };
};