import React from 'react';
import { Link } from 'react-router-dom';

import { SocialAuth } from '../SocialAuth';
import { SOCIAL_FACEBOOK_PROVIDER, SOCIAL_GOOGLE_PROVIDER } from "../../../../consts/social";

const SocialLoginComponent = (props) => {
    return (
        <ul className="list-inline">
            <li className="list-inline-item">
                <Link
                    to={"#"}
                    className="social-list-item bg-danger text-white border-danger"
                    onClick={(e) => {
                        e.preventDefault();
                        props.onLogin(SOCIAL_GOOGLE_PROVIDER)
                    }}
                >
                    <i className="mdi mdi-google" />
                </Link>
            </li>

            {/*<li className="list-inline-item">*/}
          {/*    <Link*/}
          {/*        to={"#"}*/}
          {/*        className="social-list-item bg-info text-white border-info"*/}
          {/*        onClick={(e) => {*/}
          {/*            e.preventDefault();*/}
          {/*            props.onLogin(SOCIAL_FACEBOOK_PROVIDER)*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <i className="mdi mdi-facebook" />*/}
          {/*    </Link>*/}
          {/*</li>*/}
        </ul>
    );
};

export const SocialLogin = (props) => (
    <SocialAuth {...props} component={SocialLoginComponent} />
);