export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const UPDATE_USER = 'UPDATE_USER';

export const CLEAN_USER = 'CLEAN_USER';

export const USER_ADD_SOCIAL_ACCOUNT = 'USER_ADD_SOCIAL_ACCOUNT';
export const USER_REMOVE_SOCIAL_ACCOUNT = 'USER_REMOVE_SOCIAL_ACCOUNT';

export const UPDATE_USER_UNREAD_MESSAGES_COUNT = 'UPDATE_USER_UNREAD_MESSAGES_COUNT';
export const UPDATE_USER_NEW_INVITES_COUNT = 'UPDATE_USER_NEW_INVITES_COUNT';

export const UPDATE_USER_CREDITS_COUNT = 'UPDATE_USER_CREDITS_COUNT';

export const UPDATE_USER_COMPANY_LOGO = 'UPDATE_USER_COMPANY_LOGO';

export const SET_USER_SUBSCRIPTION = 'SET_USER_SUBSCRIPTION';