import React, { useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { useTitle } from "../../hooks";
import { PageTitle } from '../../components/Custom';
import { Chat } from './components';

const ChatPageComponent = (props) => {
    useTitle(props.t('title.chat'));

    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('title.chat')} />

                    <Row>
                        <Col lg="12">
                            <Chat />
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const ChatPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatPageComponent)));