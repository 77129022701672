import {
    FETCH_CONTACT,
    FETCH_CONTACT_SUCCESS,
    FETCH_CONTACT_ERROR,
    CLEAN_CONTACT,
} from './actionTypes';

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const profile = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONTACT:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case FETCH_CONTACT_SUCCESS:
            state = {
                ...state,
                loading: false,
                data: action.payload,
            };
            break;

        case FETCH_CONTACT_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case CLEAN_CONTACT:
            state = {
                ...state,
                data: null,
                loading: false,
                error: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default profile;