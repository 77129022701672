import {
    FETCH_FAVORITE_PROPERTIES,
    FETCH_FAVORITE_PROPERTIES_SUCCESS,
    FETCH_FAVORITE_PROPERTIES_ERROR,
    CLEAN_FAVORITE_PROPERTIES,
} from './actionTypes';

export const fetchFavoriteProperties = (params) => {
    return {
        type: FETCH_FAVORITE_PROPERTIES,
        payload: { params }
    }
};

export const fetchFavoritePropertiesSuccess = (data) => {
    return {
        type: FETCH_FAVORITE_PROPERTIES_SUCCESS,
        payload: data,
    }
};

export const fetchFavoritePropertiesError = (data) => {
    return {
        type: FETCH_FAVORITE_PROPERTIES_ERROR,
        payload: data,
    }
};

export const cleanFavoriteProperties = () => {
    return { type: CLEAN_FAVORITE_PROPERTIES };
};