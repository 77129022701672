import React from "react"

export const Short = ({width, height}) => {
  width = width || "33"
  height = height || "31"

  return (
    <svg width={width} height={height} viewBox="0 0 33 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2512 20.9217C13.3571 20.9018 13.4636 20.8854 13.5719 20.8836C18.8584 20.8836 24.145 20.8836 29.4322 20.8836C29.7452 20.8866 30.0623 20.8649 30.3701 20.9386C31.5292 21.1703 32.4157 22.2821 32.4157 23.4582C32.4145 24.8812 32.4151 26.3048 32.4151 27.7278C32.4403 28.4791 32.1237 29.238 31.5638 29.7418C31.132 30.1502 30.5498 30.393 29.9564 30.4211C24.5154 30.4211 19.0744 30.4205 13.6327 30.4217C12.6924 30.4106 11.7966 29.8184 11.3923 28.9735C11.2004 28.5897 11.1015 28.1573 11.1167 27.7272C11.1167 26.3036 11.1173 24.8794 11.1161 23.4558C11.1173 22.2493 12.0523 21.113 13.2512 20.9217Z"
        fill="#F6BC00"/>
      <path
        d="M0.679326 11.2637C1.15386 10.7295 1.85835 10.4082 2.57395 10.41C7.93718 10.41 13.301 10.41 18.6648 10.41C19.2757 10.3983 19.8871 10.6119 20.354 11.0074C20.9514 11.5013 21.3031 12.2707 21.2984 13.0448C21.2967 14.5088 21.3025 15.9728 21.2955 17.4362C21.2639 18.4929 20.5319 19.4888 19.5238 19.8158C19.0615 19.9844 18.5642 19.9434 18.082 19.9475C12.9125 19.9475 7.74233 19.9475 2.57278 19.9475C1.99702 19.9463 1.4236 19.7427 0.978908 19.3764C0.38618 18.9066 0.0298412 18.1687 0 17.4163V12.9383C0.0298412 12.3251 0.264475 11.7195 0.679326 11.2637Z"
        fill="#EA4335"/>
      <path
        d="M13.2526 0.0357705C13.396 0.0123656 13.5417 0.00300366 13.6868 7.80485e-05C18.9932 7.80485e-05 24.2997 7.80485e-05 29.6062 7.80485e-05C29.8993 -0.000507073 30.1983 7.80437e-05 30.4827 0.0825802C31.5657 0.354662 32.3796 1.39793 32.4065 2.50966C32.413 3.97422 32.4077 5.43878 32.4095 6.90334C32.4165 7.64762 32.0871 8.38955 31.5265 8.87988C31.0713 9.29181 30.461 9.53054 29.8461 9.52703C24.4618 9.52703 19.0775 9.52703 13.6938 9.52703C12.7201 9.54166 11.784 8.92669 11.3796 8.04667C11.207 7.69092 11.1181 7.29596 11.1216 6.89983C11.1234 5.43703 11.1181 3.97364 11.1245 2.51025C11.1503 1.32479 12.0771 0.229446 13.2526 0.0357705Z"
        fill="#58BAAB"/>
    </svg>
  )
}