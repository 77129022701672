export const FETCH_PROJECT_LIST = 'FETCH_PROJECT_LIST';
export const FETCH_PROJECT_LIST_SUCCESS = 'FETCH_PROJECT_LIST_SUCCESS';
export const FETCH_PROJECT_LIST_ERROR = 'FETCH_PROJECT_LIST_ERROR';
export const CLEAN_PROJECT_LIST_PAGE = 'CLEAN_PROJECT_LIST_PAGE';

export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_ERROR = 'ADD_PROJECT_ERROR';
export const CLEAN_ADD_PROJECT_PAGE = 'CLEAN_ADD_PROJECT_PAGE';

export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_ERROR = 'FETCH_PROJECT_ERROR';

export const EDIT_PROJECT = 'EDIT_PROJECT';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_ERROR = 'EDIT_PROJECT_ERROR';

export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const REMOVE_PROJECT_SUCCESS = 'REMOVE_PROJECT_SUCCESS';
export const REMOVE_PROJECT_ERROR = 'REMOVE_PROJECT_ERROR';

export const CLEAN_PROJECT_PAGE = 'CLEAN_PROJECT_PAGE';

export const PROJECT_SET_SORT = 'PROJECT_SET_SORT';
export const PROJECT_RESET_SORT = 'PROJECT_RESET_SORT';
export const PROJECT_SET_PAGE = 'PROJECT_SET_PAGE';