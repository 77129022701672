import React, { useEffect } from 'react';
import { Col, FormGroup, Label } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, getFormValues, reduxForm } from 'redux-form'

import { WizardPager } from '../../WizardPager';
import RadioField from '../../../../../../components/Common/RadioField';
import { FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD } from '../../../../../../consts/forms';
import { propertyRequestHelper } from '../../../../../../helpers';
import { normalizeBoolean } from '../../../../../../utils/normalize';

const InvestmentWizardStep2Component = (props) => {
    useEffect(() => {
        return () => {
        };
    }, []);

    const loans = propertyRequestHelper.loan.getLoanTypeOptions(props.t);
    const entity = propertyRequestHelper.entity.getEntityTypeOptions(props.t);

    return (
        <>
            <form
                onSubmit={props.handleSubmit(props.onSubmit)}
                className="form-horizontal"
                noValidate={true}
            >
                <FormGroup row tag="fieldset">
                    <Label className={'col-form-label col-lg-2'}>{props.t('loan_type')}</Label>

                    <Col lg="10">
                        <div className={'form-control custom-switch-filed-wrap border-0 p-0 flex-wrap'}>
                            {loans.map((item) => (
                                <div className={'pt-1 pb-1'} key={`property-loan_type-${item.value}`}>
                                    <Field
                                        name="type_loan"
                                        value={item.value}
                                        label={item.label}
                                        id={`property-loan_type-${item.value}`}
                                        type="radio"
                                        component={RadioField}
                                    />
                                </div>
                            ))}
                        </div>
                    </Col>
                </FormGroup>

                <FormGroup row tag="fieldset">
                    <Label className={'col-form-label col-lg-2'}>{props.t('buying_entity')}</Label>

                    <Col lg="10">
                        <div className={'form-control custom-switch-filed-wrap border-0 p-0 flex-wrap'}>
                            {entity.map((item) => (
                                <div className={'pt-1 pb-1'} key={`property-buying_entity-${item.value}`}>
                                    <Field
                                        name="buying_entity"
                                        value={item.value}
                                        label={item.label}
                                        id={`property-buying_entity-${item.value}`}
                                        type="radio"
                                        component={RadioField}
                                    />
                                </div>
                            ))}
                        </div>
                    </Col>
                </FormGroup>

                <FormGroup row tag="fieldset">
                    <Label className={'col-form-label col-lg-2'}>{props.t('first_time_investor')}</Label>

                    <Col lg="10">
                        <div className={'form-control custom-switch-filed-wrap border-0 p-0 flex-wrap'}>
                            <div className={'pt-1 pb-1'}>
                                <Field
                                    name="first_time_investor"
                                    value={true}
                                    label={props.t('yes')}
                                    id={`property-first_time_investor-yes`}
                                    type="radio"
                                    component={RadioField}
                                    normalize={normalizeBoolean}
                                />
                            </div>

                            <div className={'pt-1 pb-1'}>
                                <Field
                                    name="first_time_investor"
                                    value={false}
                                    label={props.t('no')}
                                    id={`property-first_time_investor-no`}
                                    type="radio"
                                    component={RadioField}
                                    normalize={normalizeBoolean}
                                />
                            </div>
                        </div>
                    </Col>
                </FormGroup>

            </form>

            <WizardPager
                next={props.t('next')}
                prev={props.t('previous')}
                onNext={props.handleSubmit(props.onSubmit)}
                onPrev={props.back}
            />
        </>
    );
};

const InvestmentWizardForm = reduxForm({
    form: FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD,
    enableReinitialize: true,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(InvestmentWizardStep2Component);

const mapStateToProps = state => {
    const formValues = getFormValues(FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD)(state);
    return {
        initialValues: {
            ...formValues,
        },
    };
};

const mapDispatchToProps = {
};

export const InvestmentWizardStep2 = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvestmentWizardForm)));