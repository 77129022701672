import React from 'react';
import { Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

import { ProfileInfoBox } from './ProfileInfoBox';
import { PhoneFormat } from '../../../components/Custom';

export const ConsultantInfoDetails = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className={'profile-info-boxes-container'}>
            <Row className={'profile-info-boxes-row'}>
                <Col md="4">
                    <ProfileInfoBox>
                        <div className="font-weight-medium text-truncate">{t('consultant')}</div>
                        <div className="font-weight-medium text-truncate">{data.company ? data.company.name : '-'}</div>
                    </ProfileInfoBox>
                </Col>
            </Row>

            <Row className={'profile-info-boxes-row'}>
                <Col md="4">
                    <ProfileInfoBox>
                        <div className="font-weight-medium text-truncate">
                            <PhoneFormat value={data.phone} />
                        </div>
                        <div className={'text-truncate'}>
                            <a href={`mailto:${data.email}`}>
                                {data.email}
                            </a>
                        </div>
                    </ProfileInfoBox>
                </Col>
            </Row>
        </div>
    );
};