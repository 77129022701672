import React from 'react';
import { Header } from './Header';
import { Tabs } from './Tabs';

export const Sidebar = () => {
    return (
        <div className="chat-leftsidebar mr-lg-4">
            <div className="">
                <Header/>

                <Tabs/>
            </div>
        </div>
    );
};