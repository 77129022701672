import { all } from 'redux-saga/effects';

import listSaga from './list/saga';

function* tariffSaga() {
    yield all([
        listSaga(),
    ])
}

export default tariffSaga;