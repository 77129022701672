import moment from 'moment';

import {
    FETCH_CHATS,
    FETCH_CHATS_SUCCESS,
    FETCH_CHATS_ERROR,
    CLEAN_CHATS,

    CHATS_ADD_CHAT,
    CHATS_UPDATE_CHAT,

    CHATS_UPDATE_CHAT_WITH_SORT_LIST,
} from './actionTypes';

const initialState = {
    list: [],
    error: null,
    loading: false,
    meta: null,
    isLoaded: false,
};

function updateObjectInArray(array, payload) {
    return array.map((item, index) => {
        if (item.id !== payload.id) {
            return item
        }

        return {
            ...item,
            ...payload.data,
        }
    })
}

function updateChatAndSortList(array, payload) {
    let newList = array.map((item, index) => {
        if (item.id !== payload.id) {
            return item
        }

        return {
            ...item,
            ...payload.data,
        }
    });

    newList.sort((a, b) => {
        if (b.last_message === null && a.last_message) {
            return -1;
        }

        if (a.last_message === null && b.last_message) {
            return 1;
        }

        if (b.last_message === null && a.last_message === null) {
            return 0;
        }

        return (moment(b.last_message.created_at).valueOf() - moment(a.last_message.created_at).valueOf());
    });

    return newList;
}

const chats = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHATS:
            state = {
                ...state,
                loading: true,
                error: null,
                isLoaded: false,
            };
            break;

        case FETCH_CHATS_SUCCESS:
            state = {
                ...state,
                loading: false,
                list: [...action.payload.data],
                isLoaded: true,
            };
            break;

        case FETCH_CHATS_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case CLEAN_CHATS:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case CHATS_ADD_CHAT:
            state = {
                ...state,
                list: [action.payload.data, ...state.list],
            };
            break;

        case CHATS_UPDATE_CHAT:
            state = {
                ...state,
                list: updateObjectInArray([...state.list], action.payload),
            };
            break;

        case CHATS_UPDATE_CHAT_WITH_SORT_LIST:
            state = {
                ...state,
                list: [...updateChatAndSortList([...state.list], action.payload)],
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default chats;