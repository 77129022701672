import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import moment from 'moment';

import { useTitle } from "../../hooks";
import { BackButton, PageTitle } from '../../components/Custom';
import { PropertyForm, PropertyParser } from './components';
import { createProperty, cleanCreatePropertyPage } from '../../store/actions';

const PropertyCreateComponent = (props) => {
    useTitle(props.t('title.property.create'));
    const history = useHistory();

    const [propertyCoordinates, setPropertyCoordinates] = useState({lat: 50.850346, lng: 4.351721});

    useEffect(() => {
        return () => {
            props.cleanCreatePropertyPage();
        };
    }, []);

    const disableForm = useMemo(() => {
        return !!props.pending.length;
    }, [props.pending]);


    const onSubmit = (values) => {
        if (disableForm) {
            return;
        }

        // get images only with uploaded images detect via remote image id
        const images = props.photos.filter(item => item.id).map(e => e.id);

        let params = {
            ...values,
            ...propertyCoordinates,
            city: values.city.id,
            images: images,
            status: values.status.value,
        };

        if (values.project) {
            params.project = values.project.id;
        }

        if (values.available_from) {
            params.available_from = values.available_from[0] ? moment(values.available_from[0]).format('YYYY-MM-DD') : null;
        }

        props.createProperty(params, history);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('title.property.create')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <BackButton />

                                        <PropertyParser />
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {props.createError && props.createError.message ? <Alert color="danger">{props.createError.message}</Alert> : null}

                                    <PropertyForm
                                        initialValues={{
                                            ...props.values,
                                            region: props.values?.city?.region || null,
                                            available_from: props.values.available_from ? [moment(props.values.available_from).toDate()] : null,
                                        }}
                                        onSubmit={onSubmit}
                                        onSetCoordinates={setPropertyCoordinates}
                                        coordinates={propertyCoordinates}
                                        disabled={disableForm || props.createLoading}
                                        loading={props.createLoading}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { createLoading, createError, values } = state.property.create;

    return {
        photos: state.uploadPropertyPhotos.photos,
        pending: state.uploadPropertyPhotos.pending,
        createLoading,
        createError,
        values,
    };
};

const mapDispatchToProps = {
    createProperty,
    cleanCreatePropertyPage,
};

export const PropertyCreate = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyCreateComponent)));