import {
  CRM_SYSTEM_ACCOUNT_CREATE,
  CRM_SYSTEM_ACCOUNT_CREATE_SUCCESS,
  CRM_SYSTEM_ACCOUNT_CREATE_ERROR,
  CRM_SYSTEM_ACCOUNT_CREATE_CLEAN_STATE,
} from './actionTypes';

const initialState = {
  pending: [],
};

const create = (state = initialState, action) => {
  switch (action.type) {
    case CRM_SYSTEM_ACCOUNT_CREATE:
      state = {
        ...state,
        pending: [...state.pending, action.payload.crm],
      };
      break;

    case CRM_SYSTEM_ACCOUNT_CREATE_SUCCESS:
      state = {
        ...state,
        pending: state.pending.filter((item) => item !== action.payload.crm),
      };
      break;

    case CRM_SYSTEM_ACCOUNT_CREATE_ERROR:
      state = {
        ...state,
        pending: state.pending.filter((item) => item !== action.payload.crm),
      };
      break;

    case CRM_SYSTEM_ACCOUNT_CREATE_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default create;