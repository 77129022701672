import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';

import { setChatConversation } from '../../../../../../store/actions';

const ContactListItemComponent = (props) => {
    const { item } = props;

    useEffect(() => {
        return () => {
        }
    }, []);

    const handleClick = () => {
        props.setChatConversation(item);
    };

    return (
        <li>
            <Link
                to="#"
                onClick={handleClick}
            >
                <h5 className="font-size-14 mb-0">
                    {item.name}
                </h5>
            </Link>
        </li>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
    setChatConversation,
};

export const ContactListItem = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactListItemComponent)));