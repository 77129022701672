export const REGISTER_USER = 'register_user';
export const REGISTER_USER_SUCCESSFUL = 'register_user_successfull';
export const REGISTER_USER_FAILED = 'register_user_failed';
export const REGISTER_USER_CLEAN = 'register_user_clean';

export const SOCIAL_REGISTER_USER = 'SOCIAL_REGISTER_USER';
export const SOCIAL_REGISTER_USER_SUCCESS = 'SOCIAL_REGISTER_USER_SUCCESS';
export const SOCIAL_REGISTER_USER_ERROR = 'SOCIAL_REGISTER_USER_ERROR';

export const REGISTER_RESEND_CONFIRM_EMAIL = 'REGISTER_RESEND_CONFIRM_EMAIL';
export const REGISTER_RESEND_CONFIRM_EMAIL_SUCCESS = 'REGISTER_RESEND_CONFIRM_EMAIL_SUCCESS';
export const REGISTER_RESEND_CONFIRM_EMAIL_ERROR = 'REGISTER_RESEND_CONFIRM_EMAIL_ERROR';
export const REGISTER_RESEND_CONFIRM_EMAIL_CLEAN = 'REGISTER_RESEND_CONFIRM_EMAIL_CLEAN';