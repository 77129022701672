import {
    FETCH_BILLING_INVOICES,
    FETCH_BILLING_INVOICES_SUCCESS,
    FETCH_BILLING_INVOICES_ERROR,
    CLEAN_BILLING_INVOICES,
    BILLING_INVOICES_SET_PAGE,
} from './actionTypes';

export const fetchBillingInvoices = (params) => {
    return {
        type: FETCH_BILLING_INVOICES,
        payload: { params }
    }
};

export const fetchBillingInvoicesSuccess = (data) => {
    return {
        type: FETCH_BILLING_INVOICES_SUCCESS,
        payload: data,
    }
};

export const fetchBillingInvoicesError = (data) => {
    return {
        type: FETCH_BILLING_INVOICES_ERROR,
        payload: data,
    }
};

export const cleanBillingInvoices = () => {
    return { type: CLEAN_BILLING_INVOICES };
};

export const billingInvoicesSetPage = (data) => {
    return {
        type: BILLING_INVOICES_SET_PAGE,
        payload: data,
    }
};