import React, { useRef, useState, useMemo } from 'react';
import Leaflet from 'leaflet';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'

Leaflet.Icon.Default.imagePath = '../node_modules/leaflet';

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const initialState = {
    center: [50.850346, 4.351721],
    zoom: 8,
};

function MyComponent({ onClick }) {
    const map = useMapEvents({
        click(e) {
            onClick(e.latlng);
        },
    });

    return null;
}

function DraggableMarker({ draggableStatus, position, setPosition }) {
    const markerRef = useRef(null);
    const eventHandlers = useMemo(() => ({
            dragend() {
                const marker = markerRef.current;
                if (marker !== null) {
                    setPosition(marker.getLatLng());
                }
            },
        }), []);

    return (
        <Marker
            draggable={draggableStatus}
            eventHandlers={eventHandlers}
            position={position}
            ref={markerRef}
        />
    )
}

export const MapComponent = ({ marker, setMarker, zoom, readMode }) => {
    return (
        <MapContainer
            className={'property-map-edit'}
            center={marker}
            zoom={zoom || initialState.zoom}
            scrollWheelZoom={true}
        >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {!readMode && (
                <MyComponent onClick={(latlng) => setMarker(latlng)} />
            )}

            <DraggableMarker draggableStatus={!readMode} position={marker} setPosition={(latlng) => setMarker(latlng)} />
        </MapContainer>
    );
};