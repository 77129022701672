import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Alert } from "reactstrap";

import { PropertyRequestTabs } from './PropertyRequestTabs';
import { HomeWizard } from '../HomeWizard';
import { InvestmentWizard } from '../InvestmentWizard';

import { propertyRequestCheckAvailable, cleanPropertyRequestCheckAvailable } from '../../../../../store/actions';

const PropertyRequestWizardsComponent = (props) => {
    const [activeTab, setActiveTab] = useState(null);

    useEffect(() => {
        props.propertyRequestCheckAvailable();

        return () => {
            props.cleanPropertyRequestCheckAvailable();
        };
    }, []);

    const isDisabled = useMemo(() => {
        return props.loading || !props.data;
    }, [props.loading, props.data, props.available]);

    return (
        <>
            {activeTab === null && (
                <>
                    <Alert color="primary">{props.t('property_request.available_hint', { count: 2 })}</Alert>

                    {props.checkError && <Alert color="danger">{props.checkError}</Alert>}

                    {props.available !== null && !props.available && <Alert color="warning">{props.t('property_request.error.available_limit')}</Alert>}
                </>
            )}

            {!activeTab && <PropertyRequestTabs activeTab={activeTab} onChange={(value) => setActiveTab(value)} disabled={isDisabled} />}

            {activeTab === 1 && <HomeWizard onStart={() => setActiveTab(null)} />}

            {activeTab === 2 && <InvestmentWizard onStart={() => setActiveTab(null)} />}
        </>
    );
};

const mapStateToProps = state => {
    const { loading, data, checkError, available } = state.propertyRequest.available;
    return {
        loading,
        data,
        checkError,
        available,
    };
};

const mapDispatchToProps = {
    propertyRequestCheckAvailable,
    cleanPropertyRequestCheckAvailable,
};

export const PropertyRequestWizards = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyRequestWizardsComponent)));