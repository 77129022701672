import axios from 'axios';
import qs from 'qs';

class PropertyAPI {
    getPropertyList = async (params) => {
        return axios.get('api/properties', {
            params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { encode: false });
            },
        });
    };

    getProperty = async (id, params) => {
        return axios.get(`api/properties/${id}`, { params });
    };

    createProperty = async (params) => {
        return axios.post('api/properties', params);
    };

    sendImage = async (data, config) => {
        let formData = new FormData();
        formData.append('image', data);

        return axios.post('api/properties/images', formData, config);
    };

    removeImage = async (id, config) => {
        return axios.delete(`api/attachments/${id}`, config);
    };

    editProperty = async (id, params) => {
        return axios.put(`api/properties/${id}`, params);
    };

    deleteProperty = async (id) => {
        return axios.delete(`api/properties/${id}`);
    };

    sendPropertyImage = async (id, data, config) => {
        let formData = new FormData();
        formData.append('image', data);

        return axios.post(`api/properties/${id}/images`, formData, config);
    };

    sendFavoriteStatus = async (id, params) => {
        return axios.post(`api/properties/${id}/favorite`, params);
    };

    getAllClientsAddedPropertyFavorite = async (id, params) => {
        return axios.get(`/api/properties/${id}/favorites-users`, {
            params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { encode: false });
            },
        });
    };

    getClientsAddedPropertyFavoriteViaInvite = async (id, params) => {
        return axios.get(`/api/properties/${id}/my-sent-favorites-users`, {
            params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { encode: false });
            },
        });
    };

    parseProperty = async (params, config) => {
        return axios.get('api/property-parser', {
            params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { encode: false });
            },
            ...config,
        });
    };

    importProperty = async (data) => {
        let formData = new FormData();
        formData.append('file', data.file);
        return axios.post('api/property-import', formData)
    }

    getPropertiesStatistics = async () => {
        return axios.get('api/properties-statistics')
    }
}

export const property = new PropertyAPI();
