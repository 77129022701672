import React, { useEffect, useMemo, useState, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { SuggestedPropertyFilterForm } from './SuggestedPropertyFilterForm';
import { getApplyFilterCount } from '../../../../../utils/filter';
import { FilterModal } from '../../../../../components/Custom/FilterModal';
import { API } from "../../../../../api";
import { getInviteStatusConfig } from "../../../../../helpers";

const SuggestedPropertyFilterComponent = (props) => {
    const modalRef = createRef();

    const [modalVisible, setModalVisible] = useState(false);

    const [companyOptions, setCompanyOptions] = useState([]);

    const fetchCompanies = async () => {
        try {
            const response = await API.invite.getCompaniesFromInvites({ pagination: false });
            const { data } = response;

            setCompanyOptions(data.data)
        } catch (e) {
        }
    };

    useEffect(() => {
        if (modalVisible) {
            fetchCompanies();
        } else {
            setCompanyOptions([]);
        }

    }, [modalVisible]);

    const initialFormValues = useMemo(() => {
        let values = {
            ...props.filter?.values,
        };

        if (props.filter?.values?.leads_links_statuses) {
            values.leads_links_statuses = props.filter.values?.leads_links_statuses.map(value => getInviteStatusConfig(value, props.t));
        }

        return values;
    }, [props.filter]);

    const onSubmit = (values) => {
        let params = {
            ...values,
        };

        if (values.leads_links_statuses) {
            params.leads_links_statuses = values.leads_links_statuses.map(item => item.value);
        }

        props.onApply(params);

        if (modalRef.current) {
            modalRef.current.hide();
        }
    };

    const handleReset = () => {
        props.onReset();

        if (modalRef.current) {
            modalRef.current.hide();
        }
    };

    const count = useMemo(() => {
        return props.filter?.isActive ? getApplyFilterCount(props.filter?.values) : 0;
    }, [props.filter]);

    return (
        <FilterModal
            onVisibleChange={(value) => setModalVisible(value)}
            count={count}
            ref={modalRef}
        >
            <SuggestedPropertyFilterForm
                initialValues={initialFormValues}
                onSubmit={onSubmit}
                onReset={handleReset}
                companyOptions={companyOptions}
                statusOptions={props.statusOptions}
            />
        </FilterModal>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const SuggestedPropertyFilter = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SuggestedPropertyFilterComponent));