import * as propertyType from './propertyType';
import * as parking from './parking';
import * as age from './age';
import * as outsideFacing from './outsideFacing';
import * as floor from './floor';
import * as goal from './goal';
import * as loan from './loan';
import * as entity from './entity';
import * as common from './common';
import * as type from './type';
import * as status from './status';

export const propertyRequestHelper = {
    propertyType,
    parking,
    age,
    outsideFacing,
    floor,
    goal,
    loan,
    entity,
    common,
    type,
    status,
};