import React from 'react';
import { Col, FormGroup, Label } from "reactstrap";
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

import {
    SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD,
} from '../../../../../consts/billing/subscription';

export const SwapTariffInfo = (props) => {
    const { t } = useTranslation();

    const {
        price,
        name,
        period,
    } = props.tariff;

    return (
        <>
            <FormGroup row className={'mb-4'}>
                <Label className={'col-form-label col-lg-3'}>{t('subscription_plan')}</Label>

                <Col lg="9">
                    <div className={'form-field-vertical-center font-size-16'}>
                        <span>{name}</span>
                    </div>
                </Col>
            </FormGroup>

            <FormGroup row className={'mb-4'}>
                <Label className={'col-form-label col-lg-3'}>{t('billed_period')}</Label>

                <Col lg="9">
                    <div className={'form-field-vertical-center font-size-16'}>
                        <span>
                            {
                                period === SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD ?
                                    t('tariff.period.annually') :
                                    t('tariff.period.monthly')
                            }
                        </span>
                    </div>
                </Col>
            </FormGroup>

            <FormGroup row className={'mb-4'}>
                <Label className={'col-form-label col-lg-3'}>{t('price')}</Label>

                <Col lg="9">
                    <div className={'form-field-vertical-center font-size-16'}>
                        <NumberFormat
                            value={price}
                            thousandSeparator={' '}
                            prefix={'€ '}
                            displayType={'text'}
                        />
                    </div>
                </Col>
            </FormGroup>
        </>
    );
};