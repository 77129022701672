import React, { useState, useEffect, useMemo } from 'react';
import { Table } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";
import NumberFormat from 'react-number-format';
import { useTable, useSortBy } from 'react-table';

import { OfferPriceDiff, OfferStatusBadge, OfferUpdateActionButtons } from '../../../../../components/Custom';
import { OFFER_STATUS_UNCONFIRMED } from '../../../../../consts/offer';

const OffersTableComponent = (props) => {
    const { items } = props;

    const data = useMemo(() => {
        return items;
    }, [items]);

    const [columns, setColumns] = useState([{
        Header: props.t('consultant'),
        accessor: 'sender_name',
        id: 'sender_name',
        Cell: ({ row: { original } }) => {
            const { sender } = original;
            return (
                <Link to={`/consultants/${sender?.id}`} className="text-dark font-weight-bold">{sender?.name}</Link>
            )
        },
    }, {
        Header: props.t('client'),
        accessor: 'receiver_name',
        id: 'receiver_name',
        Cell: ({ row: { original } }) => {
            const { receiver } = original;
            return (
                <Link to={`/clients/${receiver?.id}`} className="text-dark font-weight-bold">{receiver?.name || '-'}</Link>
            )
        },
    }, {
        Header: props.t('address'),
        accessor: 'address',
        id: 'property_address',
        Cell: ({ row: { original } }) => {
            const { property } = original;
            return (
                <Link to={{ pathname: `/properties/${property.id}` }} className="text-dark font-weight-bold text-decoration-underline">{property.address || "-"}</Link>
            );
        },
    }, {
        Header: () => (
            <div className={'w-100 text-center'}>{props.t('price')}</div>
        ),
        accessor: 'property_price',
        id: 'property_price',
        Cell: ({ row: { original } }) => {
            const { property } = original;
            return (
                <div className={'text-center text-dark font-weight-bold text-nowrap'}>
                    {property.price !== null && property.price >= 0 ? (
                        <NumberFormat
                            value={property.price}
                            thousandSeparator={' '}
                            prefix={'€ '}
                            displayType={'text'}
                        />
                    ) : '-'}
                </div>
            );
        }
    }, {
        Header: () => (
            <div className={'w-100 text-center'}>{props.t('new_price')}</div>
        ),
        accessor: 'price',
        id: 'price',
        Cell: ({ value }) => (
            <div className={'text-center text-dark font-weight-bold text-nowrap'}>
                {value !== null && value >= 0 ? (
                    <NumberFormat
                        value={value}
                        thousandSeparator={' '}
                        prefix={'€ '}
                        displayType={'text'}
                    />
                ) : '-' }
            </div>
        ),
    }, {
        Header: () => (
            <div className={'w-100 text-center'}>{props.t('difference')}</div>
        ),
        accessor: 'difference',
        id: 'difference',
        Cell: ({ row: { original } }) => (
            <div className={'text-center'}>
                <OfferPriceDiff offerPrice={original.price} price={original.property.price} />
            </div>
        ),
    }, {
        Header: () => (
            <div className={'w-100 text-center'}>{props.t('status')}</div>
        ),
        accessor: 'price_status',
        id: 'price_status',
        Cell: ({ row: { original }, value }) => {
            return (
                <div className={'text-center'}>
                    <OfferStatusBadge status={value} />
                </div>
            );
        },
    }, {
        Header: '',
        id: 'action',
        Cell: ({ row: { original } }) => (
            <div className={'text-center'}>
                {original.price_status === OFFER_STATUS_UNCONFIRMED ? (
                    <OfferUpdateActionButtons inviteId={original.id} />
                ) : null}
            </div>
        ),
        disableSortBy: true,
    }]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: props.initialSort,
            },
            defaultCanSort: false,
            disableSortBy: data.length === 0,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
        },
        useSortBy,
    );

    useEffect(() => {
        props.onSort(sortBy);
    }, [props.onSort, sortBy]);

    return (
        <div className="table-responsive table-centered">
            <Table className={'table-bordered table-part-bordered'} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps({ title: null }))}
                            >
                                <div className={'d-flex'}>
                                    <div className={'d-flex flex-fill'}>
                                        {column.render('Header')}
                                    </div>

                                    <div>
                                        {column.canSort && (
                                            column.isSorted ? (
                                                <i className={'bx font-size-16 align-middle' + (column.isSortedDesc ? " bx-sort-down" : " bx-sort-up")} />
                                            ) : <i className="bx bx-sort align-middle" style={{ color: '#adb5bd' }} />
                                        )}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {(
                    rows.length > 0 && rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                ) || (
                    <tr>
                        <td colSpan={columns.length}>
                            {!props.isLoading ? props.t('empty') : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}} />}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const OffersTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OffersTableComponent)));