import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import { ChatUnreadBadge, InviteBadge } from '../Custom';
import { middlewareHelper } from '../../helpers';

const { propertyMiddleware } = middlewareHelper

const previewMode = middlewareHelper.previewMode.isAllow()

const SidebarContent = (props) => {
    const config = [{
        title: props.t('dashboard'),
        icon: 'bx-home-circle',
        route: '/dashboard',
        roles: ['client'],
    }, {
        title: props.t('title.my_list'),
        icon: 'bxs-city',
        route: '#',
        roles: ['consultant', 'manager'],
        submenu: [{
            title: props.t('title.overview'),
            route: '/properties',
            roles: ['consultant', 'manager'],
        }, {
            title: props.t('title.property.create'),
            route: '/properties/create',
            roles: ['consultant', 'manager'],
            hidden: !propertyMiddleware.manageIsAllow(props.user),
        }, {
            title: props.t('projects'),
            route: '/projects',
            roles: ['consultant', 'manager'],
        },
            // {
            //     title: props.t('title.calculations'),
            //     route: '/temp',
            //     roles: ['client', 'consultant'],
            // },
            {
                title: props.t('title.invite_propositions'),
                route: '/properties/invites',
                roles: [],
                badge: () => <InviteBadge/>,
            }, {
                title: props.t('title.price_alteration'),
                route: '/properties/propositions',
                roles: ['manager'],
            }],
    }, {
        title: props.t('title.search_house'),
        icon: 'bx-file-find',
        route: '/property-requests',
        roles: ['client'],
        hidden: !previewMode,
    },
        {
            title: props.t('title.chat'),
            icon: 'bx-message-rounded',
            route: '/chat',
            roles: ['client'],
        },
        {
            title: props.t('title.brikk_leads'),
            icon: 'bx-receipt',
            route: '#',
            roles: ['consultant', 'manager'],
            hidden: !previewMode,
            submenu: [{
                title: props.t('title.overview'),
                route: '/leads',
                roles: ["consultant"],
            }, {
                title: props.t('title.overview_all_leads'),
                route: '/leads',
                roles: ['manager'],
            }, {
                title: props.t('title.brikk_preferences'),
                route: '/leads/preferences',
                roles: ['manager'],
            }],
        }, {
            title: props.t('title.communication'),
            icon: 'bxs-contact',
            route: '#',
            roles: ['consultant', 'manager'],
            submenu: [{
                title: props.t('title.chat'),
                route: '/chat',
                roles: ['consultant'],
                badge: () => <ChatUnreadBadge/>,
            }, {
                title: props.t('title.contacts'),
                route: '/contacts',
                roles: [],
            }, {
                title: props.t('title.consultants'),
                route: '/consultants',
                roles: ['manager'],
            }, {
                title: props.t('title.clients'),
                route: '/clients',
                roles: ['consultant', 'manager'],
            }],
        },
        {
            title: props.t('integrations'),
            icon: 'bx-archive-in',
            route: '/integrations',
            roles: ['manager'],
            // hidden: !propertyMiddleware.manageIsAllow(props.user),
        },
        // {
        //     title: props.t('title.manage_property'),
        //     icon: 'bx-file',
        //     route: '#',
        //     roles: ['client'],
        //     submenu: [{
        //         title: props.t('title.documents_cloud'),
        //         route: '/temp',
        //         roles: ['client'],
        //     }],
        // },
    ];

    // const menu = useRef(null);

    useEffect(() => {
        let pathName = props.location.pathname;

        const initMenu = () => {
            // if (!menu.current) {
            //     menu.current = new MetisMenu("#side-menu");
            // }

            let ul = document.getElementById("side-menu");
            let items = ul.getElementsByTagName("a");

            // let isSubmenuActiveLink = false;
            //
            // // detect if current route is link from submenu => disabled collapse that cannot clean in loop links below
            // for (let i = 0; i < items.length; ++i) {
            //     let el = items[i]; // a
            //     if (pathName === el.pathname) {
            //         let parent = el.parentElement;
            //         if (parent) {
            //             const parent2 = parent.parentElement;
            //             if (parent2 && parent2.classList.contains('sub-menu')) {
            //
            //                 // set flag for not hide submenu
            //                 parent2.classList.add("sub-menu-show-disabled");
            //                 isSubmenuActiveLink = true;
            //             }
            //         }
            //         break;
            //     }
            // }

            // loop of link in menu
            for (let i = 0; i < items.length; ++i) {
                let el = items[i]; // a

                // if current route === link from menu -> set active
                if (pathName === el.pathname) {
                    el.classList.add("active");
                    let parent = el.parentElement; // li

                    if (parent) {
                        parent.classList.add("mm-active");

                        const parent2 = parent.parentElement; // ul - "sub-menu"

                        if (parent2 && parent2.classList.contains('sub-menu')) {
                            // setActive(parent2);
                        }
                    }
                } else {
                    // if current route !== link from menu -> set inactive
                    el.classList.remove("active");
                    let parent = el.parentElement;
                    if (parent) {
                        parent.classList.remove("mm-active");

                        const parent2 = parent.parentElement; // ul - "sub-menu"
                        if (parent2 && parent2.classList.contains('sub-menu')) {
                            // if current link from active submenu -> not inactive submenu
                            if (!parent2.classList.contains('sub-menu-show-disabled')) {
                                // inactive parent submenu link
                                // removeActive(parent2);
                            }
                        }
                    }
                }
            }

            // // hide submenu if current route is not submenu
            // // todo: hide all submenu
            // if (!isSubmenuActiveLink) {
            //     let subMenuDisabled = document.getElementsByClassName("sub-menu-show-disabled");
            //     let disableSubmenu = subMenuDisabled[0];
            //     if (disableSubmenu) {
            //         disableSubmenu.classList.remove('sub-menu-show-disabled');
            //         if (disableSubmenu) {
            //             removeActive(disableSubmenu);
            //         }
            //     }
            // }

        };
        initMenu();
    }, [props.location.pathname]);

    // set active menu link
    const setActive = (el) => {
        el.classList.add('mm-show');
        let parent = el.parentElement;
        if (parent) {
            parent.classList.add("mm-active");
            parent.childNodes[0].classList.add("mm-active"); // a of "parent li"
        }
    };

    // set inactive menu link
    const removeActive = (el) => {
        el.classList.remove('mm-show');
        let parent = el.parentElement;
        if (parent) {
            parent.classList.remove("mm-active");
            parent.childNodes[0].classList.remove("mm-active"); // a of "parent li"
        }
    };

    const renderSubMenu = (submenu) => {
        return submenu.map((item, index) => {
            if (item.roles.includes(props.user.role) && !item.hidden) {
                return (
                    <li key={`sidebar-subnav-${index}`}>
                        <Link to={item.route}>
                            <>
                                {item.badge ? item.badge() : null}
                                <span>{item.title}</span>
                            </>
                        </Link>
                    </li>
                )
            }
        })
    };

    const renderMenuPoints = () => {
        return config.map((item, index) => {
            if (item.roles.includes(props.user.role) && !item.hidden) {
                return (
                    <li key={`sidebar-nav-${index}`} className={(item.submenu ? ' mm-active' : '')}>
                        <Link
                            to={item.route}
                            className={`side-menu-item waves-effect`}
                        >
                            <i className={`bx ${item.icon}`}/>
                            <span>{item.title}</span>
                        </Link>

                        {item.submenu && (
                            <ul className="sub-menu" aria-expanded="false">
                                {renderSubMenu(item.submenu)}
                            </ul>
                        )}
                    </li>
                )
            }
        })
    };

    return (

        <React.Fragment>
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    {renderMenuPoints()}
                </ul>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

export default connect(mapStateToProps, {})(withRouter(withTranslation()(SidebarContent)));