import {
    CHAT_ATTACH_FILES,

    CHAT_UPLOAD_FILE,
    CHAT_UPLOAD_FILE_SUCCESS,
    CHAT_UPLOAD_FILE_ERROR,

    CHAT_REMOVE_FILE,
    CHAT_REMOVE_FILE_SUCCESS,
    CHAT_REMOVE_FILE_ERROR,

    CHAT_CLEAN_ATTACHMENTS,
} from './actionTypes';

const initialState = {
    attachments: [],
    pending: [],
};

function updateObjectInArray(array, payload) {
    return array.map((item, index) => {
        if (item.fileId !== payload.fileId) {
            // This isn't the item we care about - keep it as-is
            return item
        }

        // Otherwise, this is the one we want - return an updated value
        return {
            ...item,
            ...payload.params
        }
    })
}

const attachment = (state = initialState, action) => {
    switch (action.type) {
        case CHAT_ATTACH_FILES:
            state = {
                ...state,
                attachments: [...state.attachments, ...action.payload],
            };
            break;

        case CHAT_UPLOAD_FILE:
            state = {
                ...state,
                pending: [...state.pending, action.payload],
            };
            break;

        case CHAT_UPLOAD_FILE_SUCCESS:
            state = {
                ...state,
                attachments: updateObjectInArray([...state.attachments], action.payload),
                pending: state.pending.filter((item) => item !== action.payload.fileId),
            };
            break;

        case CHAT_UPLOAD_FILE_ERROR:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload),
            };
            break;

        case CHAT_REMOVE_FILE:
            state = {
                ...state,
                pending: [...state.pending, action.payload],
            };
            break;

        case CHAT_REMOVE_FILE_SUCCESS:
            state = {
                ...state,
                attachments: state.attachments.filter((item) => item.fileId !== action.payload),
                pending: state.pending.filter((item) => item !== action.payload),
            };
            break;

        case CHAT_REMOVE_FILE_ERROR:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload),
            };
            break;

        case CHAT_CLEAN_ATTACHMENTS:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default attachment;