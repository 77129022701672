import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';

export const OfferPriceDiff = ({ offerPrice, price = 0, reverse = false, suffix = true, textClass = 'font-size-12' }) => {
    const config = useMemo(() => {
        if (offerPrice === null) {
            return null;
        }

        let diff = offerPrice - price;
        let color = '';
        let value = '-';
        let prefix = '';

        if (diff < 0) {
            color = !reverse ? 'text-danger' : 'text-success';
            value = Math.abs(diff);
            prefix = '- ';
        } else if (diff > 0) {
            color = !reverse ? 'text-success' : 'text-danger';
            value = diff;
            prefix = '+ ';
        } else {
            value = diff;
            prefix = '';
        }

        return { color, value, prefix };
    }, [offerPrice, price, reverse]);

    return (
        <>
            {offerPrice !== null ? (
                <NumberFormat
                    value={config.value}
                    thousandSeparator={' '}
                    suffix={suffix ? ' €' : ''}
                    prefix={config.prefix}
                    displayType={'text'}
                    renderText={(formattedValue) => (
                        <span className={`text-nowrap ${textClass} ` + (config.color)}>
                            {formattedValue}
                        </span>
                    )}
                />
            ) : '-'}
        </>
    );
};