import React, { useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";

import { useTitle } from "../../hooks";
import { ProfileMainInfo, BackButton, ConsultantInfoDetails, PageTitle } from '../../components/Custom';

import { fetchContact, cleanContact } from '../../store/actions';

import * as HOC from '../../HOC';
const DataShower = HOC.withDataShower();

const ContactComponent = (props) => {
    useTitle(props.t('title.vendor'));
    let { id } = useParams();

    useEffect(() => {
        props.fetchContact(id);

        return () => {
            props.cleanContact();
        };
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('details')} />

                    <Row className={'mb-4'}>
                        <Col>
                            <Row className={'no-gutters justify-content-between'}>
                                <BackButton />
                            </Row>
                        </Col>
                    </Row>

                    <DataShower
                        isLoading={props.loading}
                        isFailed={props.error}
                        error={props.t(props.error)}
                        customLoaderClass={'mt-5'}
                    >
                        {props.contact && (
                            <>
                                <div className={'profile-info-container'}>
                                    <Row>
                                        <Col xl="4">
                                            <ProfileMainInfo
                                                title={'details'}
                                                name={props.contact.name}
                                                avatar={props.contact.avatar}
                                            />
                                        </Col>

                                        <Col xl="8">
                                            <ConsultantInfoDetails data={props.contact} />
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        )}
                    </DataShower>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { data, loading, error } = state.contact.profile;
    return {
        contact: data,
        loading,
        error,
    };
};

const mapDispatchToProps = {
    fetchContact,
    cleanContact,
};

export const Contact = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactComponent)));