import React, { useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withTranslation } from 'react-i18next';

const CustomModalComponent = (props) => {
    const [modalVisible, setModalVisible] = useState(false);

    useImperativeHandle(props.parentRef, () => ({
            show() {
                showModal();
            },
            hide() {
                hideModal();
            }
        }),
    );

    useEffect(() => {
        props.onVisibleChange(modalVisible);
    }, [modalVisible]);

    const showModal = () => {
        setModalVisible(true);
    };

    const hideModal = () => {
        setModalVisible(false);
    };

    const toggle = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <Modal
            size="lg"
            isOpen={modalVisible}
            toggle={toggle}
        >
            <ModalHeader
                toggle={toggle}
            >
                {props.title}
            </ModalHeader>

            <ModalBody>
                {props.children}
            </ModalBody>
        </Modal>
    );
};

const CustomModalHOC = withTranslation()(CustomModalComponent);

export const CustomModal = forwardRef((props, ref) => (
    <CustomModalHOC {...props} parentRef={ref} />
));