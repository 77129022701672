import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import toastr from 'toastr';
import i18n from '../../i18n';

import { FETCH_PROJECT_LIST, ADD_PROJECT, FETCH_PROJECT, EDIT_PROJECT, REMOVE_PROJECT } from './actionTypes';
import {
    fetchProjectListSuccess,
    fetchProjectListError,
    addProjectSuccess,
    addProjectError,
    fetchProjectSuccess,
    fetchProjectError,
    editProjectError,
    editProjectSuccess,
    removeProjectSuccess,
    removeProjectError,
} from './actions';

import { FORM_ADD_PROJECT, FORM_EDIT_PROJECT } from '../../consts/forms';

import { API } from '../../api';
import { getMapRequestParams } from '../../utils/request';

function* fetchProjectList({ payload: { params } }) {
    try {
        const requestParams = getMapRequestParams(params);

        const response = yield call(API.project.getProjectList, requestParams);

        const { data } = response;
        yield put(fetchProjectListSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchProjectListError(message));
    }
}

function* addProject({ payload: { params, history } }) {
    try {
        const response = yield call(API.project.createProject, params);

        const { data } = response;
        yield put(addProjectSuccess(true));
        history.push('/projects');

        toastr.success(i18n.t('project.add.success'));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(addProjectError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_ADD_PROJECT, errors));
    }
}

function* fetchProject({ payload: { id } }) {
    try {
        const response = yield call(API.project.getProject, id);

        const { data } = response;
        yield put(fetchProjectSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchProjectError(message)); // Need: Project not found or access denied
    }
}

function* editProject({ payload: { id, params } }) {
    try {
        const response = yield call(API.project.editProject, id, params);

        const { data } = response;
        yield put(editProjectSuccess('project.edit.success'));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(editProjectError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_EDIT_PROJECT, errors));
    }
}

function* removeProject({ payload: { id, history } }) {
    try {
        const response = yield call(API.project.removeProject, id);

        const { data } = response;
        yield put(removeProjectSuccess(true));
        history.push('/projects');

        toastr.success(i18n.t('project.delete.success'))

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(removeProjectError(message)); // Need: Project not found or access denied
    }
}

export function* watchProjectsListFetch() {
    yield takeEvery(FETCH_PROJECT_LIST, fetchProjectList)
}

export function* watchAddProject() {
    yield takeEvery(ADD_PROJECT, addProject)
}

export function* watchProjectFetch() {
    yield takeEvery(FETCH_PROJECT, fetchProject)
}

export function* watchEditProject() {
    yield takeEvery(EDIT_PROJECT, editProject)
}

export function* watchRemoveProject() {
    yield takeEvery(REMOVE_PROJECT, removeProject)
}

function* projectSaga() {
    yield all([
        fork(watchProjectsListFetch),
        fork(watchAddProject),
        fork(watchProjectFetch),
        fork(watchEditProject),
        fork(watchRemoveProject),
    ]);
}

export default projectSaga;