import React from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from 'classnames'

export const BillingPortalTabs = ({ tabs, activeTab, onTabChange }) => {
    const { t } = useTranslation();
    return (
        <Nav pills justified className={'bg-white'}>
            {tabs.map((item) => (
                <NavItem key={item.value}>
                    <NavLink
                        className={classNames({
                            active: item.value === activeTab,
                        })}
                        onClick={() => {
                            if (item.value !== activeTab) {
                                onTabChange(item.value);
                            }
                        }}
                    >
                        <i className="bx bx-receipt font-size-20 d-sm-none" />
                        <span className="d-none d-sm-block text-nowrap">{t(item.label)}</span>
                    </NavLink>
                </NavItem>
            ))}
        </Nav>
    );
};