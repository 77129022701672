import React from 'react';
import { Row, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

import ButtonLoadingIcon from '../../../../../components/Common/ButtonLoadingIcon';

export const WizardPager = ({
    next,
    prev,
    onNext,
    onPrev,
    prevDisabled = false,
    nextDisabled = false,
    onNextLoading = false,
}) => {
    const { t } = useTranslation();

    return (
        <Row className="no-gutters justify-content-end">
            <div className="button-items">
                <Button
                    type="button"
                    color="primary"
                    disabled={prevDisabled}
                    onClick={onPrev}
                >
                    {prev}
                </Button>

                <Button
                    type="button"
                    color="primary"
                    disabled={nextDisabled}
                    onClick={onNext}
                >
                    <ButtonLoadingIcon loading={onNextLoading} />
                    {next}
                </Button>
            </div>
        </Row>
    );
};