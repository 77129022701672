import {
    LEAD_UPDATE_STATUS_DECLINE,
    LEAD_UPDATE_STATUS_BUY,

    LEAD_UPDATE_STATUS_SUCCESS,
    LEAD_UPDATE_STATUS_ERROR,
    CLEAN_LEAD_UPDATE_STATUS,
} from './actionTypes';

// UPDATE LEAD STATUS
export const leadUpdateStatusDecline = (id) => {
    return {
        type: LEAD_UPDATE_STATUS_DECLINE,
        payload: { id },
    }
};

export const leadUpdateStatusBuy = (id) => {
    return {
        type: LEAD_UPDATE_STATUS_BUY,
        payload: { id },
    }
};

export const leadUpdateStatusSuccess = (data) => {
    return {
        type: LEAD_UPDATE_STATUS_SUCCESS,
        payload: data,
    }
};

export const leadUpdateStatusError = (data) => {
    return {
        type: LEAD_UPDATE_STATUS_ERROR,
        payload: data,
    }
};

export const cleanLeadUpdateStatus = () => {
    return { type: CLEAN_LEAD_UPDATE_STATUS };
};