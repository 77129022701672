import {
    REGISTER_USER,
    REGISTER_USER_SUCCESSFUL,
    REGISTER_USER_FAILED,
    REGISTER_USER_CLEAN,
    SOCIAL_REGISTER_USER,
    SOCIAL_REGISTER_USER_SUCCESS,
    SOCIAL_REGISTER_USER_ERROR,

    REGISTER_RESEND_CONFIRM_EMAIL,
    REGISTER_RESEND_CONFIRM_EMAIL_SUCCESS,
    REGISTER_RESEND_CONFIRM_EMAIL_ERROR,
    REGISTER_RESEND_CONFIRM_EMAIL_CLEAN,
} from './actionTypes';

export const registerUser = (params) => {
    return {
        type: REGISTER_USER,
        payload: { params }
    }
};

export const registerUserSuccessful = (data) => {
    return {
        type: REGISTER_USER_SUCCESSFUL,
        payload: data
    }
};

export const registerUserFailed = (data) => {
    return {
        type: REGISTER_USER_FAILED,
        payload: data
    }
};

export const registerUserClean = () => {
    return {
        type: REGISTER_USER_CLEAN,
    };
};

// social register user
export const socialRegisterUser = (params, formData, history) => {
    return {
        type: SOCIAL_REGISTER_USER,
        payload: { params, formData, history }
    }
};

export const socialRegisterUserSuccess = (data) => {
    return {
        type: SOCIAL_REGISTER_USER_SUCCESS,
        payload: data
    }
};

export const socialRegisterUserError = (data) => {
    return {
        type: SOCIAL_REGISTER_USER_ERROR,
        payload: data
    }
};

// resend confirm email
export const registerResendConfirmEmail = (params) => {
    return {
        type: REGISTER_RESEND_CONFIRM_EMAIL,
        payload: { params }
    }
};

export const registerResendConfirmEmailSuccess = (data) => {
    return {
        type: REGISTER_RESEND_CONFIRM_EMAIL_SUCCESS,
        payload: data
    }
};

export const registerResendConfirmEmailError = (data) => {
    return {
        type: REGISTER_RESEND_CONFIRM_EMAIL_ERROR,
        payload: data
    }
};

export const registerResendConfirmEmailClean = () => {
    return {
        type: REGISTER_RESEND_CONFIRM_EMAIL_CLEAN,
    };
};