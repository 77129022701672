import axios from 'axios';
import qs from "qs";

class ProjectAPI {
    getProjectList = async (params) => {
        return axios.get('api/projects', {
            params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { encode: false });
            },
        });
    };

    getProject = async (id) => {
        return axios.get(`api/projects/${id}`);
    };

    createProject = async (params) => {
        return axios.post('api/projects', params);
    };

    editProject = async (id, params) => {
        return axios.put(`api/projects/${id}`, params);
    };

    removeProject = async (id) => {
        return axios.delete(`api/projects/${id}`);
    };
}

export const project = new ProjectAPI();
