import React, { useEffect } from 'react';
import { Col, FormGroup, Label } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, getFormValues, reduxForm } from 'redux-form'

import { WizardPager } from '../../WizardPager';
import { FORM_PROPERTY_REQUEST_HOME_WIZARD } from '../../../../../../consts/forms';
import {
    PROPERTY_SIZE_MIN,
    PROPERTY_SIZE_MAX,
    PROPERTY_BEDROOMS_MIN,
    PROPERTY_BEDROOMS_MAX,
    PROPERTY_BATHROOMS_MIN,
    PROPERTY_BATHROOMS_MAX,
} from "../../../../../../consts/property";
import { SLIDER_MODE_INPUT } from "../../../../../../consts/common";
import { SliderField } from "../../../../../../components/Custom";

const range = {
    'min': [PROPERTY_BEDROOMS_MIN], // 0
    '25%': [(PROPERTY_BEDROOMS_MAX + 1) * 0.00000001], // 10
    '50%': [(PROPERTY_BEDROOMS_MAX + 1) * 0.0000001], // 100
    '75%': [(PROPERTY_BEDROOMS_MAX + 1) * 0.000001], // 1000
    'max': [PROPERTY_BEDROOMS_MAX],
};

const sizeRange = {
    'min': [PROPERTY_SIZE_MIN], // 0
    '30%': [(PROPERTY_SIZE_MAX + 1) * 0.0001], // 100
    '50%': [(PROPERTY_SIZE_MAX + 1) * 0.001], // 1000
    '75%': [(PROPERTY_SIZE_MAX + 1) * 0.01], // 10 th
    '90%': [(PROPERTY_SIZE_MAX + 1) * 0.1], // 100 th
    'max': [PROPERTY_SIZE_MAX],
};

const HomeWizardStep2Component = (props) => {
    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <>
            <form
                onSubmit={props.handleSubmit(props.onSubmit)}
                className="form-horizontal"
                noValidate={true}
            >
                <FormGroup row className={'mb-4'}>
                    <Label className={'col-form-label col-lg-2'}>{props.t('field.label.property.min_size')}</Label>

                    <Col lg="10">
                        <Field
                            id={'min-size'}
                            name="min_size"
                            component={SliderField}
                            limit={{
                                min: PROPERTY_SIZE_MIN,
                                max: PROPERTY_SIZE_MAX,
                            }}
                            mode={SLIDER_MODE_INPUT}
                            labelPrefix={'m²: '}
                            range={sizeRange}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row className={'mb-4'}>
                    <Label className={'col-form-label col-lg-2'}>{props.t('min_bedrooms')}</Label>

                    <Col lg="10">
                        <Field
                            id={'bedrooms'}
                            name="bedrooms"
                            component={SliderField}
                            limit={{
                                min: PROPERTY_BEDROOMS_MIN,
                                max: PROPERTY_BEDROOMS_MAX,
                            }}
                            mode={SLIDER_MODE_INPUT}
                            range={range}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row className={'mb-4'}>
                    <Label className={'col-form-label col-lg-2'}>{props.t('min_bathrooms')}</Label>

                    <Col lg="10">
                        <Field
                            id={'bathrooms'}
                            name="bathrooms"
                            component={SliderField}
                            limit={{
                                min: PROPERTY_BATHROOMS_MIN,
                                max: PROPERTY_BATHROOMS_MAX,
                            }}
                            mode={SLIDER_MODE_INPUT}
                            range={range}
                        />
                    </Col>
                </FormGroup>
            </form>

            <WizardPager
                next={props.t('next')}
                prev={props.t('previous')}
                onNext={props.handleSubmit(props.onSubmit)}
                onPrev={props.back}
            />
        </>
    );
};

const HomeWizardForm = reduxForm({
    form: FORM_PROPERTY_REQUEST_HOME_WIZARD,
    enableReinitialize: true,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(HomeWizardStep2Component);

const mapStateToProps = state => {
    const formValues = getFormValues(FORM_PROPERTY_REQUEST_HOME_WIZARD)(state);
    return {
        initialValues: {
            ...formValues,
            min_size: formValues.min_size || [PROPERTY_SIZE_MIN],
            bedrooms: formValues.bedrooms || [PROPERTY_BEDROOMS_MIN],
            bathrooms: formValues.bathrooms || [PROPERTY_BATHROOMS_MIN],
        },
    };
};

const mapDispatchToProps = {
};

export const HomeWizardStep2 = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomeWizardForm)));