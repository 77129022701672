export const FETCH_CHAT_MESSAGES = 'FETCH_CHAT_MESSAGES';
export const FETCH_CHAT_MESSAGES_SUCCESS = 'FETCH_CHAT_MESSAGES_SUCCESS';
export const FETCH_CHAT_MESSAGES_ERROR = 'FETCH_CHAT_MESSAGES_ERROR';
export const CLEAN_CHAT_MESSAGES = 'CLEAN_CHAT_MESSAGES';

export const CHAT_ADD_MESSAGE = 'CHAT_ADD_MESSAGE';
export const CHAT_MESSAGES_DELETE_MESSAGE = 'CHAT_MESSAGES_DELETE_MESSAGE';
export const CHAT_MARK_MESSAGES_SEEN = 'CHAT_MARK_MESSAGES_SEEN';

export const CHAT_MARK_RECEIVED_MESSAGES_SEEN = 'CHAT_MARK_RECEIVED_MESSAGES_SEEN';

export const CHAT_SEND_CHAT_READ = 'CHAT_SEND_CHAT_READ';