import React, { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import { Card, Row, Col, Spinner } from "reactstrap";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { removePhoto, removePhotoSuccess, removePhotoError, uploadPhotoSuccess, uploadPhoto, uploadPhotoError } from '../../../store/upload/property/actions';
import { API } from '../../../api';
import { ValidationErrors } from '../../../components/Custom/ValidationErrors';

const UploadItemComponent = ({
    item,
    modelId,
    removePhoto,
    removePhotoSuccess,
    removePhotoError,
    uploadPhoto,
    uploadPhotoError,
    uploadPhotoSuccess,
}) => {
    const { t } = useTranslation();

    const [localState, setLocalState] = useState({
        id: null,
        loading: false,
        error: null,
    });

    const requestCancelToken = useRef(null);

    const cancelRequest = () => {
        if (requestCancelToken.current) {
            requestCancelToken.current.cancel("Request canceled.");
        }
    };

    const sendPhoto = async () => {
        uploadPhoto(item.fileId);

        setLocalState({
            id: null,
            loading: true,
            error: null,
        });

        cancelRequest();

        requestCancelToken.current = axios.CancelToken.source();

        try {

            let response = {};

            if (modelId) {
                // edit property page
                response = await API.property.sendPropertyImage(modelId, item.file, { cancelToken: requestCancelToken.current.token });
            } else {
                // create new property
                response = await API.property.sendImage(item.file, { cancelToken: requestCancelToken.current.token });
            }

            const { data } = response;

            uploadPhotoSuccess(item.fileId, { id: data.data.id });

            setLocalState({
                ...localState,
                id: data.data.id,
                loading: false,
            });
        } catch (error) {
            uploadPhotoError(item.fileId);

            if (axios.isCancel(error)) {
                console.log(error.message);
            } else {
                requestCancelToken.current = null;

                const errors = error.response?.data?.errors ? error.response.data.errors : {};
                const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

                setLocalState({
                    ...localState,
                    loading: false,
                    error: {
                        errors,
                        message
                    },
                });
            }
        }
    };

    const deletePhoto = async (e) => {
        e.preventDefault();

        removePhoto(item.fileId);

        setLocalState({
            id: null,
            loading: true,
            error: null,
        });

        cancelRequest();

        requestCancelToken.current = axios.CancelToken.source();

        try {
            const response = await API.property.removeImage(localState.id, { cancelToken: requestCancelToken.current.token });

            const { data } = response;

            removePhotoSuccess(item.fileId);

        } catch (error) {
            removePhotoError(item.fileId);

            if (axios.isCancel(error)) {
                console.log(error.message);
            } else {
                requestCancelToken.current = null;

                const errors = error.response?.data?.errors ? error.response.data.errors : {};
                const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

                setLocalState({
                    ...localState,
                    loading: false,
                    error: {
                        errors,
                        message
                    },
                });
            }
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        cancelRequest()
    };

    useEffect(() => {
        sendPhoto();

        return () => {
            cancelRequest();
        }
    }, []);

    return (
        <Card
            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
        >
            <div className="p-2">
                <Row className="align-items-center">
                    <Col className="col-auto">
                        <div className="avatar-select-wrap">
                            <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light avatar-cover"
                                alt={item.file.name}
                                src={item.preview}
                            />

                            <div className={'avatar-loading'} style={{ display: localState.loading ? 'flex' : 'none' }}>
                                <Spinner size={'sm'} color="primary" />
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <span className="text-muted font-weight-bold">
                            {item.file.name}
                        </span>
                        <p className="mb-0">
                            <strong>{item.formattedSize}</strong>
                        </p>
                    </Col>
                    <div className={'pl-3 pr-4'}>
                        {localState.loading ? (
                            <Link to="#" className="text-primary" onClick={handleCancel}>{t('cancel')}</Link>
                        ) : !localState.loading && localState.id ? (
                            <Link to="#" className="text-primary" onClick={deletePhoto}>{t('image.remove')}</Link>
                        ) : null}
                    </div>
                </Row>

                {localState.error ? <ValidationErrors data={localState.error} /> : null}
            </div>
        </Card>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
    removePhoto,
    removePhotoSuccess,
    removePhotoError,
    uploadPhotoSuccess,
    uploadPhoto,
    uploadPhotoError,
};

export const UploadItem = connect(mapStateToProps, mapDispatchToProps)(UploadItemComponent);