import React, { useEffect, useState } from 'react';
import { Label, FormFeedback, FormGroup, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { Field } from "redux-form";

import { API } from "../../../api";
import variables from "../../../assets/scss/custom-variables.module.scss"

const CitySelect = ({
    label,
    placeholder,
    type,
    input,
    meta: { touched, error, warning },
    defaultValue,
    reference,
    children,
    options,
    filterOption,
    getOptionLabel,
    getOptionValue,
    isOptionSelected,
    byRegion,
    regionId,
    ...props
}) => {
    const { t } = useTranslation();

    const minLimit = 2;

    const [cityOptions, setCityOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [inputValue, setInputValue] = useState('');

    const fetchCity = async (searchValue) => {
        setIsLoading(true);
        try {
            let response = null;

            const params = {
                filter: {
                    keywords: searchValue,
                    search_fields: ['name'],
                },
                pagination: false
            };

            if (byRegion && regionId) {
                response = await API.common.getCitiesByRegion(regionId, params);
            } else {
                response = await API.common.getCities(params);
            }

            const { data } = response;

            setIsLoading(false);
            setCityOptions(data.data);

            return data.data;
        } catch (e) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            setCityOptions([]);
        };
    }, [regionId]);

    useEffect(() => {
        return () => {
            if (props.isMulti) {
                setCityOptions([]);
            }
        };
    }, [input.value, props.isMulti]);

    const handleInputChange = (newValue) => {
        const inputValue = newValue;
        setInputValue(inputValue);
        return inputValue;
    };

    const showError = touched && Boolean(error);

    const customStyles = {
        control: base => ({
            ...base,
            fontWeight: 400
        }),
        menu: base => ({
            ...base,
            fontWeight: 400
        })
    };

    const onChange = (value) => {
        if (value && props.isMulti && Array.isArray(value) && value.length === 0) {
            setInputValue('');
            return input.onChange(null);
        }

        setInputValue('');
        return input.onChange(value);
    };

    // manage in the state your current debounce (function, delay).
    const [debounceState, setDebounceState] = useState({});

    // Listen to changes of debounce (function, delay), when it does clear the previous timeout and set the new one.
    useEffect(() => {
        const { cb, delay } = debounceState;
        if (cb) {
            const timeout = setTimeout(cb, delay);
            return () => clearTimeout(timeout);
        }
    }, [debounceState]);

    const loadOptions = async (value, callback) => {
        if (value.length < minLimit) {
            return callback([]);
        }
        return new Promise((resolve) => {
            setDebounceState({
                cb: async () => {
                    const data = await fetchCity(value);
                    resolve(data);
                },
                delay: 1000 // ms
            });
        });
    };

    const renderNoOptionsMessage = () => {
        if (inputValue.length < minLimit) {
            return t('search.hint', { count: minLimit })
        }

        return t('select.no_options')
    };

    return (
        <FormGroup row className={'mb-4 '}>
            <Label htmlFor={props.id} className={'col-form-label col-lg-2 ' + (showError ? 'text-danger' : '')}>{label}</Label>

            <Col lg="10">
                <AsyncSelect
                    id={props.id}
                    value={input.value}
                    onChange={(value) => onChange(value)}
                    defaultOptions={cityOptions}
                    loadOptions={loadOptions}
                    // cacheOptions
                    placeholder={placeholder}
                    className={'basic-single ' + (showError ? 'is-invalid' : '')}
                    classNamePrefix="select"
                    styles={customStyles}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            text: variables.mainTextColor,
                            primary25: variables.mainBg,
                            primary: variables.mainGreen,
                            primary50: variables.mainGreen
                        },
                    })}
                    noOptionsMessage={renderNoOptionsMessage}
                    loadingMessage={() => `${t('loading')}...`}
                    getOptionLabel={(option) => {
                        if (getOptionLabel) {
                            return getOptionLabel(option);
                        } else {
                            return `${option.label}`
                        }
                    }}
                    getOptionValue={(option) => {
                        if (getOptionValue) {
                            return getOptionValue(option);
                        } else {
                            return `${option.value}`
                        }
                    }}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    // menuIsOpen
                    {...props}
                />

                <FormFeedback invalid={showError ? error : null }>
                    { t(error, props.translateParams ? { ...props.translateParams } : {}) }
                </FormFeedback>
            </Col>
        </FormGroup>
    );
};

export const CitySelectField = (props) => {
    return (
        <Field {...props} type="input" component={CitySelect} />
    );
};