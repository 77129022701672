import {
    FETCH_PROPERTY,
    FETCH_PROPERTY_SUCCESS,
    FETCH_PROPERTY_ERROR,
    CLEAN_PROPERTY_PAGE,

    UPDATE_PROPERTY_INVITE_STATUS,

    PROPERTY_VIEW_UPDATE_FAVORITE,
} from './actionTypes';

const initialState = {
    property: null,
    propertyLoading: false,
    propertyError: null,
};

const property = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROPERTY:
            state = {
                ...state,
                propertyLoading: true,
                propertyError: null,
            };
            break;

        case FETCH_PROPERTY_SUCCESS:
            state = {
                ...state,
                propertyLoading: false,
                property: action.payload,
            };
            break;

        case FETCH_PROPERTY_ERROR:
            state = {
                ...state,
                propertyLoading: false,
                propertyError: action.payload,
            };
            break;

        case CLEAN_PROPERTY_PAGE:
            state = {
                ...state,
                property: null,
                propertyLoading: false,
                propertyError: null,
            };
            break;

        case UPDATE_PROPERTY_INVITE_STATUS:
            state = {
                ...state,
                property: {
                    ...state.property,
                    myReceivedLink: {
                        ...state.property.myReceivedLink,
                        status: action.payload,
                    },
                },
            };
            break;

        case PROPERTY_VIEW_UPDATE_FAVORITE:
            state = {
                ...state,
                property: {
                    ...state.property,
                    is_favorite: action.payload,
                },
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default property;