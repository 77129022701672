import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { stopSubmit } from 'redux-form';

// Login Redux States
import { RESET_PASSWORD } from "./actionTypes";
import { userResetPasswordSuccess, userResetPasswordError } from "./actions";

import { FORM_RESET_PASSWORD } from '../../../consts/forms';

import { API } from '../../../api';

function* resetPassword({ payload: { params, history } }) {
    try {
        const response = yield call(API.auth.resetPassword, params);
        const { data } = response;

        yield put(
            userResetPasswordSuccess(
                data?.message ? data?.message : "Success!"
            )
        );

    } catch (error) {
        const errors = error.response.data.errors;
        const message = error.response.data.message ? error.response.data.message : 'Server Error';

        yield put(userResetPasswordError(message));

        if (errors) {
            yield put(stopSubmit(FORM_RESET_PASSWORD, errors));
        }
    }
}

export function* watchUserPasswordReset() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

function* resetPasswordSaga() {
    yield all([fork(watchUserPasswordReset)]);
}

export default resetPasswordSaga;
