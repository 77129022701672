import Echo from 'laravel-echo';
import axios from 'axios';

const KEY = process.env.REACT_APP_SOCKET_APP_KEY;
const CLUSTER = process.env.REACT_APP_SOCKET_APP_CLUSTER;
const AUTH_URL = process.env.REACT_APP_BASE_URL + 'api/broadcasting/auth';

window.Pusher = require('pusher-js');

const options = {
    broadcaster: 'pusher',
    key: KEY,
    cluster: CLUSTER,
    forceTLS: true,
    authEndpoint: AUTH_URL,
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                axios.post('/api/broadcasting/auth', {
                    socket_id: socketId,
                    channel_name: channel.name
                })
                .then(response => {
                    callback(false, response.data);
                })
                .catch(error => {
                    callback(true, error);
                });
            }
        };
    },
};

class EchoService {
    echo = null;

    create = () => {
        this.echo = new Echo(options);
    };

    leave = (channel) => {
        if (this.echo) {
            this.echo.leave(channel);
        }
    };

    leaveAll = () => {
        if (this.echo && this.echo.connector && this.echo.connector.channels) {
            for (const key in this.echo.connector.channels) {
                this.echo.leave(key);
            }
        }
    };

    destroy = () => {
        this.echo = null;
    }
}

const EchoClient = new EchoService();
export default EchoClient;