import axios from 'axios';
import qs from "qs";

const paramsSerializer = (params) => {
    return qs.stringify(params, { encode: false });
};

class AuthAPI {
    signIn = async (params) => {
        try {
            await axios.get('sanctum/csrf-cookie');

            return axios.post('api/auth/login', params);
        } catch (e) {
            throw e;
        }
    };

    logOut = () => {
        return axios.post('api/auth/logout');
    };

    signUp = async (params) => {
        try {
            await axios.get('sanctum/csrf-cookie');

            return axios.post('api/auth/register', params);
        } catch (e) {
            throw e;
        }
    };

    forgotPassword = async (params) => {
        try {
            await axios.get('sanctum/csrf-cookie');

            return axios.post('api/auth/forgot-password', params);
        } catch (e) {
            throw e;
        }
    };

    resetPassword = async (params) => {
        try {
            await axios.get('sanctum/csrf-cookie');

            return axios.post('api/auth/reset-password', params);
        } catch (e) {
            throw e;
        }
    };

    sendVerifyEmail = async (params) => {
        try {
            await axios.get('sanctum/csrf-cookie');

            return axios.post('api/auth/email/verification-notification', params);
        } catch (e) {
            throw e;
        }
    };

    verifyUser = async (params) => {
        try {
            await axios.get('sanctum/csrf-cookie');

            return axios.post('api/auth/verify', params);
        } catch (e) {
            throw e;
        }
    };

    activate = async (id, hash, params, formData) => {
        try {
            await axios.get('sanctum/csrf-cookie');

            return axios.post(`api/auth/activate/${id}/${hash}`, formData, {
                params,
                paramsSerializer: () => paramsSerializer(params),
            });
        } catch (e) {
            throw e;
        }
    };

    getOAuthUrl = async (params) => {
        try {
            await axios.get('sanctum/csrf-cookie');

            return axios.get('api/auth/social', {
                params,
                paramsSerializer: () => paramsSerializer(params),
            });
        } catch (e) {
            throw e;
        }
    };

    socialLogin = async (params) => {
        try {
            await axios.get('sanctum/csrf-cookie');

            return axios.get(`api/auth/social/login`, {
                params,
                paramsSerializer: () => paramsSerializer(params),
            });
        } catch (e) {
            throw e;
        }
    };

    socialRegister = async (params, formData) => {
        try {
            await axios.get('sanctum/csrf-cookie');

            return axios.post(`api/auth/social/register/step-1`, formData, {
                params,
                paramsSerializer: () => paramsSerializer(params),
            });
        } catch (e) {
            throw e;
        }
    };

    socialRegisterComplete = async (formData) => {
        try {
            await axios.get('sanctum/csrf-cookie');

            return axios.post(`api/auth/social/register/step-2`, formData);
        } catch (e) {
            throw e;
        }
    };
}

export const auth = new AuthAPI();
