import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { LeadPreferencesForm } from './LeadPreferencesForm';
import { API } from "../../../../api";
import { PROPERTY_PRICE_MAX, PROPERTY_PRICE_MIN } from "../../../../consts/property";
import { fetchLeadPreferences, cleanLeadPreferences, updateLeadPreferences, cleanUpdateLeadPreferences } from "../../../../store/actions";

import * as HOC from '../../../../HOC';
const DataShower = HOC.withDataShower();

const LeadPreferencesComponent = (props) => {
    const [provinceOptions, setProvinceOptions] = useState([]);

    const fetchProvinces = async () => {
        try {
            const response = await API.common.getRegions({ pagination: false });
            const { data } = response;

            setProvinceOptions(data.data)
        } catch (e) {
        }
    };

    useEffect(() => {
        props.fetchLeadPreferences();

        fetchProvinces();

        return () => {
            props.cleanLeadPreferences();
            props.cleanUpdateLeadPreferences();
        };
    }, []);

    const onSubmit = (values) => {
        let params = {
            ...values,
            price_min: values.price.range[0],
            price_max: values.price.range[1],
        };

        if (values.regions) {
            params.regions = values.regions.map(item => item.id);
        }

        if (values.cities) {
            params.cities = values.cities.map(item => item.id);
        }

        delete params.price;

        props.updateLeadPreferences(params);
    };

    const initialValues = useMemo(() => {
        let priceMin = props.data.hasOwnProperty('price_min') ? props.data.price_min || PROPERTY_PRICE_MIN : PROPERTY_PRICE_MIN;
        let priceMax = props.data.hasOwnProperty('price_max') ? props.data.price_max || PROPERTY_PRICE_MAX : PROPERTY_PRICE_MAX;

        return {
            price: {
                range: [priceMin, priceMax],
            },
            property_types: props.data.property_types || [],
            property_ages: props.data.property_ages || [],
            regions: props.data.regions,
            cities: props.data.cities,
            show_investments: props.data.hasOwnProperty('show_investments') ? props.data.show_investments : false,
        };
    }, [props.data]);

    return (
        <Row>
            <Col lg="12">
                <Card>
                    <CardBody>
                        <DataShower isLoading={props.loading} isFailed={props.fetchError} error={props.fetchError}>

                            {props.updateSuccess && <Alert color="success">{props.t(props.updateSuccess)}</Alert>}
                            {props.updateError && props.updateError.message && <Alert color="danger">{props.updateError.message}</Alert>}

                            <LeadPreferencesForm
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                provinceOptions={provinceOptions}
                                loading={props.updateLoading}
                            />

                        </DataShower>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const { data, loading, fetchError} = state.lead.preferences.view;
    const { updateLoading, updateSuccess, updateError } = state.lead.preferences.update;
    return {
        data, loading, fetchError,
        updateLoading, updateSuccess, updateError,
    };
};

const mapDispatchToProps = {
    fetchLeadPreferences,
    cleanLeadPreferences,
    updateLeadPreferences,
    cleanUpdateLeadPreferences,
};

export const LeadPreferences = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadPreferencesComponent)));