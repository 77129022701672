import React from 'react';
import {Row, Col, Button, Alert} from "reactstrap";
import {withTranslation} from 'react-i18next';
import {Field, reduxForm} from 'redux-form'
import {connect} from "react-redux";

import {FORM_CRM_SYSTEM_ACCOUNT_CREATE_CREDENTIALS} from "consts/forms";
import TextInput from "components/Common/TextInput";
import ButtonLoadingIcon from 'components/Common/ButtonLoadingIcon';
import {
  required as requiredValidator,
} from "utils/validations";

const CredentialsFormComponent = (props) => {
  return (
    <>
      {props.errorMessage && <Alert color="danger">{props.errorMessage}</Alert>}

      <form
        onSubmit={props.handleSubmit(props.onSubmit)}
        className="form-horizontal"
        noValidate={true}
      >
        <Field
          name="username"
          label={props.t('username')}
          placeholder={props.t('username')}
          id={'username'}
          type="text"
          component={TextInput}
          validate={[requiredValidator]}
          row
        />

        <Field
          name="password"
          label={props.t('field.label.password')}
          placeholder={props.t('field.placeholder.password')}
          id={'password'}
          type="password"
          component={TextInput}
          validate={[requiredValidator]}
          row
        />

        <Row className="justify-content-end">
          <Col lg="10">
            <div className="button-items">
              <Button type="submit" color="primary" disabled={props.loading}>
                <ButtonLoadingIcon loading={props.loading}/>
                {props.t('login')}
              </Button>

              <Button type="button" color="light" outline onClick={props.onCancel}>
                {props.t('cancel')}
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

const Credentials = reduxForm({
  form: FORM_CRM_SYSTEM_ACCOUNT_CREATE_CREDENTIALS,
  enableReinitialize: true,
})(CredentialsFormComponent);

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export const CredentialsForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Credentials));