import React from 'react';
import { connect } from "react-redux";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { ROLE_MANAGER } from '../../../../consts/roles';
import { SUPPORTED_LANGUAGE_CODES } from '../../../../consts/locales';
import i18n from '../../../../i18n';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || '';

const getLocale = () => {
    // make const key
    const lang = i18n.language;

    if (lang) {
        // make const
        if (SUPPORTED_LANGUAGE_CODES.includes(lang)) {
            return lang;
        }
    }

    return 'auto';
};

// const stripePromise = loadStripe(STRIPE_KEY, {
//     locale: getLocale()
// });

const stripePromise = loadStripe(STRIPE_KEY);

const StripeWrapperComponent = (props) => {

    const fonts = [{
        cssSrc: 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap\''
    }];

    return (
        <>
            {props.user.role === ROLE_MANAGER ? (
                <Elements stripe={stripePromise} options={{ locale: getLocale(), fonts }} >
                    {props.children}
                </Elements>
            ) : (
                props.children
            )}
        </>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

const mapDispatchToProps = {
};

export const StripeWrapper = connect(mapStateToProps, mapDispatchToProps)(StripeWrapperComponent);