import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_USER_LEADS } from './actionTypes';
import {
    fetchUserLeadsSuccess,
    fetchUserLeadsError,
} from './actions';

import { API } from '../../../api';
import { getMapRequestParams } from '../../../utils/request';

function* fetchUserLeads({ payload: { params } }) {
    try {
        const includes = ['propertyAd', 'propertyAd.user', 'propertyAd.cities', 'propertyAd.regions'];

        let requestParams = {
            includes,
        };

        requestParams = getMapRequestParams(params, requestParams);

        const response = yield call(API.lead.getLeads, requestParams);

        const { data } = response;
        yield put(fetchUserLeadsSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchUserLeadsError(message));
    }
}

export function* watchUserLeadsFetch() {
    yield takeLatest(FETCH_USER_LEADS, fetchUserLeads)
}

function* userLeadsSaga() {
    yield all([
        fork(watchUserLeadsFetch),
    ]);
}

export default userLeadsSaga;