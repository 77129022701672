import { all } from 'redux-saga/effects';

import favoritesSaga from './favorites/saga';
import inviteSaga from './invite/sagas';

function* clientsSaga() {
    yield all([
        favoritesSaga(),
        inviteSaga(),
    ])
}

export default clientsSaga;