import React, { useMemo } from 'react';
import { Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { getReferralStatusConfig } from '../../../../helpers';

export const ReferralStatusBadge = ({ status }) => {
    const { t } = useTranslation();

    const config = useMemo(() => {
        return getReferralStatusConfig(status, t);
    }, [status]);

    return (
        <Badge
            className={'font-size-12 badge-soft-' + (config.color)}
            pill
        >
            {config.label}
        </Badge>
    );
};