import { combineReducers } from "redux";

import listReducer from './list/reducer';
import viewReducer from './view/reducer';
import createReducer from './create/reducer';
import editReducer from './edit/reducer';
import invitesReducer from './invites/reducer';
import clientsReducers from './clients/reducers';
import parserReducers from './parser/reducer';
import clientPropertiesReducers from './clientProperties/reducers';
import generalReducer from './general/reducer';
import favoriteReducer from './favorite/reducers';
import importReducer from './import/reducer';

const propertyReducer = combineReducers({
    list: listReducer,
    view: viewReducer,
    create: createReducer,
    edit: editReducer,
    invites: invitesReducer,
    clients: clientsReducers,
    parser: parserReducers,
    clientProperties: clientPropertiesReducers,
    general: generalReducer,
    favorite: favoriteReducer,
    import: importReducer

});

export default propertyReducer;