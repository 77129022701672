import React from 'react';
import { Link } from 'react-router-dom';

import { LogoIcon } from "../../../../components/Custom";

export const Header = (props) => {
  return (
    <div className="mb-4 mb-md-5">
      <Link to="/" className="auth-logo">
        <LogoIcon size={"sm"}/>
      </Link>
    </div>
  )
};