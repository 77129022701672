import React from 'react';
import { withRouter, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { connect } from "react-redux";

import { PropertyRequestCreate, PropertyRequestList, PropertyRequest, SuggestedProperty } from './'
import { ROLE_CLIENT } from '../../consts/roles';
import { useAllowed } from '../../hooks';
import AllowedRoute from '../../routes/middleware/AllowedRoute';

const PropertyRequestIndexComponent = (props) => {
    let { path } = useRouteMatch();
    let isAllowed = useAllowed([ROLE_CLIENT], props.user.role);

    return (
        <Switch>
            <AllowedRoute exact path={path} component={PropertyRequestList} allowed={isAllowed} />
            <AllowedRoute path={`${path}/create`} component={PropertyRequestCreate} allowed={isAllowed} />
            <AllowedRoute path={`${path}/suggested-property`} component={SuggestedProperty} allowed={isAllowed} />
            <AllowedRoute path={`${path}/:id`} component={PropertyRequest} allowed={isAllowed} />

            <Route>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

export const PropertyRequestIndex = withRouter(connect(mapStateToProps, {})(PropertyRequestIndexComponent));