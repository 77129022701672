import React from 'react';
import { Alert } from "reactstrap";

import { TableDataLoading } from '../components/Custom/TableDataLoading';

export default () => {
    return ({ isLoading, isFailed = false, error, children, ...rest }) =>
        !isFailed ? (
            <div className={'table-wrap'}>
                {children}

                <TableDataLoading isLoading={isLoading} />
            </div>
        ) : (
						<Alert color={'danger'}>{error || 'Error'}</Alert>
        );
};