import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { Icon, PropertyStatusLabel } from "../../../../../../components/Custom";
import NumberFormat from "react-number-format";

import './FavoritePropertyCard.scss';
import placeholder from "../../../../../../assets/images/custom/property-placeholder.jpg";

const FavoritePropertyCardComponent = (props) => {
    const { item } = props;

    const hasOfferPrice = item.myReceivedLink && item.myReceivedLink?.price;

    return (
        <div className={'favorite-property-card__block'}>
            <div className={'favorite-property-card__img-block'}>
                <Link to={`/properties/${item.id}`}>
                    <div className={'favorite-property-card__img'}
                         style={{ backgroundImage: `url("${item.avatar ? item.avatar : placeholder}")` }}
                    />
                </Link>
            </div>

            <div className={'favorite-property-card__address-block mt-2 mb-2'}>
                <Link to={`/properties/${item.id}`} className="favorite-property-card__address font-size-13">{item.address}</Link>
            </div>

            <div className="favorite-property-card__details-info-block text-truncate mb-2">
                {item.size !== null && (
                    <div className="favorite-property-card__details-item">
                        <Icon name='size' width={12} height={12} />
                        <div className="favorite-property-card__details-item-value ml-1 font-size-12 text-lowercase">
                            {item.size} m²
                        </div>
                    </div>
                )}

                {item.bedrooms !== null && (
                    <div className="favorite-property-card__details-item">
                        <Icon name='bed' width={12} height={12} />
                        <div className="favorite-property-card__details-item-value ml-1 font-size-12 text-lowercase">
                            {item.bedrooms} {props.t('rooms')}
                        </div>
                    </div>
                )}
            </div>

            <div className="favorite-property-card__separator mt-2 mb-2" />

            <div className="favorite-property-card__footer-block">
                <div className="favorite-property-card__price-block">
                    {hasOfferPrice ? (
                        <NumberFormat
                            value={item.myReceivedLink?.price}
                            thousandSeparator={' '}
                            prefix={'€ '}
                            displayType={'text'}
                            className={'font-size-13 font-weight-medium'}
                        />
                    ) : item.price !== null && item.price >= 0 ? (
                        <NumberFormat
                            value={item.price}
                            thousandSeparator={' '}
                            prefix={'€ '}
                            displayType={'text'}
                            className={'font-size-13 font-weight-medium'}
                        />
                    ) : '-' }
                </div>

                <PropertyStatusLabel status={item.status} classNames={'font-size-13'} />
            </div>

        </div>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
};

export const FavoritePropertyCard = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FavoritePropertyCardComponent)));