import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import { ROLE_MANAGER } from '../../../consts/roles';
import { logoutUser } from '../../../store/actions';

import avatar from '../../../assets/images/custom/avatar-placeholder.png';

const ProfileMenu = (props) => {
    const user = props.data;

    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false);

    const logOut = async () => {
        props.logoutUser(props.history);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block" >
                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                    <img className="rounded-circle header-profile-user avatar-cover" src={user?.avatar ? user.avatar : avatar} alt="Header Avatar" />
                    <span className="d-none d-xl-inline-block ml-2 mr-1">{user?.name ? user.name : ''}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <Link to={'/profile'} className="dropdown-item" onClick={() => setMenu(false)}>
                        <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                        {props.t('profile')}
                    </Link>

                    {/*{user.role === ROLE_MANAGER && (*/}
                    {/*    <Link to={'/billing/overview'} className="dropdown-item" onClick={() => setMenu(false)}>*/}
                    {/*        <i className="bx bx-wallet font-size-16 align-middle mr-1"></i>*/}
                    {/*        {props.t('title.billing_portal')}*/}
                    {/*    </Link>*/}
                    {/*)}*/}

                    <div className="dropdown-divider"></div>

                    <Link to={'#'} className="dropdown-item" onClick={logOut}>
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>{props.t('logout')}</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        ...state.user,
    };
};

const mapDispatchToProps = {
    logoutUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProfileMenu)));

