import {
    SET_CHAT_ACTIVE_TAB,
} from './actionTypes';
import { CHAT_TABS } from "../../../consts/chat";

const initialState = {
    activeTab: CHAT_TABS.CHAT
};

const tabs = (state = initialState, action) => {
    switch (action.type) {

        case SET_CHAT_ACTIVE_TAB:
            state = {
                ...state,
                activeTab: action.payload
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default tabs;