import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../i18n';

import { BILLING_SEND_DEFAULT_PAYMENT_CARD } from './actionTypes';
import {
    billingSendDefaultPaymentCardSuccess,
    billingSendDefaultPaymentCardError,
} from './actions';
import { paymentCardsSetDefault } from '../list/actions';

import { API } from '../../../../api';

function* sendDefaultPaymentCard({ payload: { id } }) {
    try {
        const params = {
            payment_method: id,
        };

        const response = yield call(API.billing.sendDefaultPaymentMethod, params);

        const { data } = response;

        yield put(billingSendDefaultPaymentCardSuccess(true));
        toastr.success(i18n.t(`billing.card.send_default.success`));

        yield put(paymentCardsSetDefault(id));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(billingSendDefaultPaymentCardError(message));

        toastr.error(message);
    }
}

export function* watchSendDefaultPaymentCard() {
    yield takeEvery(BILLING_SEND_DEFAULT_PAYMENT_CARD, sendDefaultPaymentCard)
}

function* sendDefaultSaga() {
    yield all([
        fork(watchSendDefaultPaymentCard),
    ]);
}

export default sendDefaultSaga;