import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_CHATS } from './actionTypes';
import { fetchChatsSuccess, fetchChatsError } from './actions';

import { API } from '../../../api';

function* fetchChats() {
    try {
        const response = yield call(API.chat.getChats);

        const { data } = response;
        yield put(fetchChatsSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchChatsError(message));
    }
}

export function* watchChatsFetch() {
    yield takeLatest(FETCH_CHATS, fetchChats)
}

function* chatsSaga() {
    yield all([
        fork(watchChatsFetch),
    ]);
}

export default chatsSaga;