import React, { useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { reduxForm, getFormValues } from 'redux-form'
import { Alert } from "reactstrap";

import { FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD } from '../../../../../../consts/forms';
import { WizardPager } from '../../WizardPager';
import { PropertyRequestInfoTable, ValidationErrors } from '../../../../../../components/Custom';
import { PROPERTY_REQUEST_WIZARD_FULFILLED_MIN_PERCENT } from '../../../../../../consts/property';
import { createPropertyRequest, cleanCreatePropertyRequest } from '../../../../../../store/actions';

const InvestmentWizardStepConfirmComponent = (props) => {
    const history = useHistory();

    useEffect(() => {
        return () => {
            props.cleanCreatePropertyRequest()
        };
    }, []);

    const onSubmit = (values) => {
        let params = {
            type: 'investment',
            expectation: values.expectation[0],
        };

        if (values.goal) {
            params.goal = values.goal;
        }

        if (values.type_loan) {
            params.type_loan = values.type_loan;
        }

        if (values.buying_entity) {
            params.buying_entity = values.buying_entity;
        }

        if (values.first_time_investor !== undefined) {
            params.first_time_investor = values.first_time_investor;
        }

        props.createPropertyRequest(params, history);
    };

    const percentWarning = useMemo(() => {
        return props.percent < PROPERTY_REQUEST_WIZARD_FULFILLED_MIN_PERCENT;
    }, [props.percent]);

    const isDisabled = useMemo(() => {
        return props.loading;
    }, []);

    return (
        <>
            <h5 className={'mr-2'}>{props.t('confirmation')}</h5>

            <p className="text-muted mb-4">{props.t('check_and_confirm_info')}</p>

            <div className={'mb-4'}>
                <PropertyRequestInfoTable
                    keys={['goal', 'expectation', 'type_loan', 'buying_entity', 'first_time_investor']}
                    data={{
                        ...props.formValues,
                        expectation: props.formValues?.expectation[0],
                    }}
                />
            </div>

            {percentWarning && (
                <Alert color="danger">{props.t('fill_level.percent.error.min', { percent: PROPERTY_REQUEST_WIZARD_FULFILLED_MIN_PERCENT })}</Alert>
            )}

            {props.createError ? <ValidationErrors data={props.createError} /> : null}

            <WizardPager
                next={props.t('confirm')}
                prev={props.t('previous')}
                onNext={props.handleSubmit(onSubmit)}
                onPrev={props.back}
                prevDisabled={isDisabled}
                nextDisabled={isDisabled || percentWarning}
                onNextLoading={props.loading}
            />
        </>
    );
};

const InvestmentWizardForm = reduxForm({
    form: FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(InvestmentWizardStepConfirmComponent);

const mapStateToProps = state => {
    const formValues = getFormValues(FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD)(state);

    const { loading, createError } = state.propertyRequest.create;
    return {
        formValues,
        loading,
        createError,
    };
};

const mapDispatchToProps = {
    createPropertyRequest,
    cleanCreatePropertyRequest,
};

export const InvestmentWizardStepConfirm = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvestmentWizardForm)));