import {
    CANCEL_BILLING_SUBSCRIPTION,
    CANCEL_BILLING_SUBSCRIPTION_SUCCESS,
    CANCEL_BILLING_SUBSCRIPTION_ERROR,
    CLEAN_CANCEL_BILLING_SUBSCRIPTION,
} from './actionTypes';

export const cancelBillingSubscription = () => {
    return {
        type: CANCEL_BILLING_SUBSCRIPTION,
    }
};

export const cancelBillingSubscriptionSuccess = (data) => {
    return {
        type: CANCEL_BILLING_SUBSCRIPTION_SUCCESS,
        payload: data,
    }
};

export const cancelBillingSubscriptionError = (data) => {
    return {
        type: CANCEL_BILLING_SUBSCRIPTION_ERROR,
        payload: data,
    }
};

export const cleanCancelBillingSubscription = () => {
    return { type: CLEAN_CANCEL_BILLING_SUBSCRIPTION };
};