import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

const PrivateRoute = ({
    component: Component,
    path,
    ...rest
}) => {
    return (
        <Route>
            {!rest.isLoggedIn ? (
                <Redirect to={{ pathname: "/login", state: { from: rest.location } }} />
            ) : rest.isLoggedIn && rest.isOnboarding ? (
                <Redirect to={{ pathname: "/account/complete", state: { from: rest.location } }} />
            ) : (
                <Component {...rest} />
            )}
        </Route>
    );
};

const mapStateToProps = state => {
    const { isLoggedIn, isOnboarding } = state.Login;
    return { isLoggedIn, isOnboarding };
};

export default withRouter(connect(mapStateToProps, {})(PrivateRoute));