export const PROPERTY_SET_FILES = 'PROPERTY_SET_FILES';

export const PROPERTY_UPLOAD_PHOTO = 'PROPERTY_UPLOAD_PHOTO';
export const PROPERTY_UPLOAD_PHOTO_SUCCESS = 'PROPERTY_UPLOAD_PHOTO_SUCCESS';
export const PROPERTY_UPLOAD_PHOTO_ERROR = 'PROPERTY_UPLOAD_PHOTO_ERROR';

export const PROPERTY_REMOVE_FILE = 'PROPERTY_REMOVE_FILE';
export const PROPERTY_REMOVE_FILE_SUCCESS = 'PROPERTY_REMOVE_FILE_SUCCESS';
export const PROPERTY_REMOVE_FILE_ERROR = 'PROPERTY_REMOVE_FILE_ERROR';


export const CLEAN_PROPERTY_FILES = 'CLEAN_PROPERTY_FILES';