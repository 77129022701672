import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../i18n';

import { BILLING_REMOVE_PAYMENT_CARD } from './actionTypes';
import {
    billingRemovePaymentCardSuccess,
    billingRemovePaymentCardError,
} from './actions';

import { API } from '../../../../api';

function* removePaymentCard({ payload: { id, onSuccess } }) {
    try {
        const response = yield call(API.billing.deletePaymentMethod, id);

        const { data } = response;

        yield put(billingRemovePaymentCardSuccess(true));
        toastr.success(i18n.t(`billing.card.remove.success`));

        if (onSuccess) {
            onSuccess();
        }

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(billingRemovePaymentCardError(message));

        toastr.error(message);
    }
}

export function* watchRemovePaymentCard() {
    yield takeEvery(BILLING_REMOVE_PAYMENT_CARD, removePaymentCard)
}

function* removeSaga() {
    yield all([
        fork(watchRemovePaymentCard),
    ]);
}

export default removeSaga;