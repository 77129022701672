export const FETCH_SUGGESTED_PROPERTY_INACTIVE_LIST = 'FETCH_SUGGESTED_PROPERTY_INACTIVE_LIST';
export const FETCH_SUGGESTED_PROPERTY_INACTIVE_LIST_SUCCESS = 'FETCH_SUGGESTED_PROPERTY_INACTIVE_LIST_SUCCESS';
export const FETCH_SUGGESTED_PROPERTY_INACTIVE_LIST_ERROR = 'FETCH_SUGGESTED_PROPERTY_INACTIVE_LIST_ERROR';
export const CLEAN_SUGGESTED_PROPERTY_INACTIVE_LIST = 'CLEAN_SUGGESTED_PROPERTY_INACTIVE_LIST';

export const SUGGESTED_PROPERTY_INACTIVE_LIST_SET_FILTER = 'SUGGESTED_PROPERTY_INACTIVE_LIST_SET_FILTER';
export const SUGGESTED_PROPERTY_INACTIVE_LIST_RESET_FILTER = 'SUGGESTED_PROPERTY_INACTIVE_LIST_RESET_FILTER';

export const SUGGESTED_PROPERTY_INACTIVE_LIST_SET_SORT = 'SUGGESTED_PROPERTY_INACTIVE_LIST_SET_SORT';
export const SUGGESTED_PROPERTY_INACTIVE_LIST_RESET_SORT = 'SUGGESTED_PROPERTY_INACTIVE_LIST_RESET_SORT';

export const SUGGESTED_PROPERTY_INACTIVE_LIST_SET_PAGE = 'SUGGESTED_PROPERTY_INACTIVE_LIST_SET_PAGE';