import React, { useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { Media, Badge } from "reactstrap";
import moment from 'moment';

import avatar from '../../../../../../assets/images/custom/avatar-placeholder.png';
import { setChatConversation } from '../../../../../../store/actions';

const ChatListItemComponent = (props) => {
    const { item } = props;

    useEffect(() => {
        return () => {
        }
    }, []);

    const isDeleted = useMemo(() => {
      return item.participant?.id
    }, [item.participant?.id])

    const handleClick = () => {
      if (item.participant) {
        props.setChatConversation(item.participant);
      }
    };

    const dateFormat = useMemo(() => {
        if (item?.last_message?.created_at) {
            let mDate = moment(item?.last_message?.created_at);

            if (mDate.isSame(moment(), 'day')) {
                return mDate.format('HH:mm');
            }
            return mDate.format('DD/MM/YYYY');
        }

        return null;
    }, [item]);

    const isOnlyAttachments = item.last_message?.attachments?.length > 0 && !item.last_message?.body;

    return (
        <li
            key={'chat-item-' + item.id}
            className={
              item.participant && props.selectedConversationUser?.id === item.participant?.id
                    ? "active"
                    : ""
            }
        >
            <Link
                to="#"
                onClick={handleClick}
            >
                <Media>
                    <div className="align-self-center mr-3">
                        <i
                            className={
                                item.participant?.is_online
                                    ? "mdi mdi-circle text-success font-size-10"
                                    : "mdi mdi-circle font-size-10"
                            }
                        />
                    </div>
                    <div className="align-self-center mr-3">
                        <img
                            src={item.participant?.avatar ? item.participant.avatar : avatar}
                            className="rounded-circle avatar-xs avatar-cover"
                            alt=""
                        />
                    </div>

                    <Media className="overflow-hidden" body>
                        <h5 className="text-truncate font-size-14 mb-1">
                            {item.participant ? item.participant?.name : props.t("deleted_account")}
                        </h5>
                        <p className="text-truncate mb-0">
                            {item?.last_message?.is_my && (
                                <span className={'text-primary'}>{props.t('you')}: </span>
                            )}

                            {isOnlyAttachments ? (
                                <span>
                                    <i className={'bx bx-file font-size-16 text-secondary mr-1 chat-attachment-icon'} />
                                    {props.t('attachments')}
                                </span>
                            ) : (
                                item.last_message?.body
                            )}
                        </p>
                    </Media>
                    <div className={'chat-item-right-wrap'}>
                        <div className="font-size-11">
                            {dateFormat}
                        </div>
                        {item.unread_count > 0 && (
                            <div className={'text-right'}>
                                <Badge pill className={'font-size-12 badge-soft-primary'}>
                                    {item.unread_count}
                                </Badge>
                            </div>
                        )}
                    </div>
                </Media>
            </Link>
        </li>
    );
};

const mapStateToProps = state => {
    const { participant } = state.chat.conversation.data;

    return {
        selectedConversationUser: participant,
    };
};

const mapDispatchToProps = {
    setChatConversation,
};

export const ChatListItem = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatListItemComponent)));