import { PHONE_CODE } from '../consts/common';

const phone = value => {
    if (!value) {
        return value
    }

    let prefix = '';

    const firstCh = value[0];

    // if first char is '+' -> add COUNTRY CODE
    if (value.length === 1 && firstCh === "+") {
        prefix = '+';
        value = value + PHONE_CODE;
    } else {
        // if only 2 characters and first is plus -> remove '+'
        if (value.length === 2 && firstCh === "+") {
            prefix = '';
            value = value[1];
        } else if (value.length >= 2 && value.slice(0, 2) === PHONE_CODE) {
            // if value start from country code -> add '+' before
            prefix = '+';
        } else if (value.length >= 2 && firstCh === "+" && value.slice(1, 3) === PHONE_CODE) {
            // if start from plus and than go country code -> add '+' before
            prefix = '+';
        }
    }

    return prefix + value.replace(/[^\d]/g, '');
};

const onlyDecimal = value => {
    value = value
        .replace(/[^0-9.]/g, ''); // Remove all chars except numbers and .

    // Create an array with sections split by .
    const sections = value.split('.');

    // Remove any leading 0s apart from single 0
    if (sections[0] !== '0' && sections[0] !== '00') {
        sections[0] = sections[0].replace(/^0+/, '');
    } else {
        sections[0] = '0';
    }

    // If numbers exist after first .
    if (sections[1]) {
        // Join first two sections and truncate end section to length 2
        return sections[0] + '.' + sections[1].slice(0, 2);
        // If original value had a decimal place at the end, add it back
    } else if (value.indexOf('.') > 0) { // value.indexOf('.') !== -1
        return sections[0] + '.';
        // Otherwise, return only section
    } else {
        return sections[0];
    }
};

const normalizeBoolean = value => {
    if (value === "true") {
        return true;
    }

    if (value === "false") {
        return false;
    }

    return value;
};

const formatPrice = (value) => {
    if (!value) return '';

    value = value.toString();

    value = value
        .replace(/[^0-9]/g, ''); // Remove all chars except numbers

    if (value === '') {
        return value;
    }

    return value
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const normalizePrice = (value) => {
    value = value
        .replace(/[^0-9]/g, ''); // Remove all chars except numbers

    return value.replace(/ /g , '');
};

const normalizePhone = (value) => {
    return value.replace(/[^0-9]/g, '');
};

export {
    phone,
    onlyDecimal,
    normalizeBoolean,
    formatPrice,
    normalizePrice,
    normalizePhone,
};