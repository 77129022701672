import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useTable, useSortBy } from 'react-table';
import { useRouteMatch, withRouter, Link, useHistory } from 'react-router-dom';
import moment from "moment";

import { propertyRequestHelper } from '../../../../helpers';
import { PropertyRequestStatusBadge } from '../../../../components/Custom';

const PropertyRequestTableComponent = (props) => {
    const { items } = props;

    let { url } = useRouteMatch();
    const history = useHistory();

    const data = useMemo(() => {
        return items;
    }, [items]);

    const [columns, setColumns] = useState([{
        Header: props.t('date'),
        accessor: 'created_at',
        id: 'created_at',
        Cell: ({ row: { original }, value }) => {
            return (
                <Link to={`${url}/${original.id}`} className={'text-dark'}>
                    { value ? moment(value).format('DD/MM/YYYY') : '-' }
                </Link>
            )
        },
    }, {
        Header: props.t('type'),
        accessor: 'type',
        id: 'type',
        Cell: ({ row: { original }, value }) => {
            return (
                <Link to={`${url}/${original.id}`}>
                    <span className={'text-dark font-weight-bold text-nowrap text-decoration-underline'}>{propertyRequestHelper.type.getType(value, props.t).label}</span>
                </Link>
            )
        },
    }, {
        Header: props.t('status'),
        accessor: 'status',
        id: 'status',
        Cell: ({ row: { original }, value }) => (
            <Link to={`${url}/${original.id}`}>
                <PropertyRequestStatusBadge status={value} />
            </Link>
        ),
    }]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: props.initialSort,
            },
            defaultCanSort: false,
            disableSortBy: true, // data.length === 0,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
        },
        useSortBy,
    );

    useEffect(() => {
        props.onSort(sortBy);
    }, [props.onSort, sortBy]);

    const getColumnStyle = (column) => {
        return { ...column.style};
    };

    return (
        <div className="table-responsive table-centered">
            <Table className={'table-bordered table-part-bordered'} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps({ title: null }))}
                                style={{ ...column.getHeaderProps(column.getSortByToggleProps().style), ...getColumnStyle(column) }}
                            >
                                <div className={'d-flex'}>
                                    <div className={'d-flex flex-fill'}>
                                        {column.render('Header')}
                                    </div>

                                    <div>
                                        {column.canSort && (
                                            column.isSorted ? (
                                                <i className={'bx font-size-16 align-middle' + (column.isSortedDesc ? " bx-sort-down" : " bx-sort-up")} />
                                            ) : <i className="bx bx-sort align-middle" style={{ color: '#adb5bd' }} />
                                        )}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {(
                    rows.length > 0 && rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                ) || (
                    <tr>
                        <td colSpan={columns.length}>
                            {!props.isLoading ? props.t('empty') : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}} />}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        initialSort: [],
    };
};

const mapDispatchToProps = {
};

export const PropertyRequestTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyRequestTableComponent)));