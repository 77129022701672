import {
    FETCH_USER_LEADS,
    FETCH_USER_LEADS_SUCCESS,
    FETCH_USER_LEADS_ERROR,
    CLEAN_USER_LEADS
} from './actionTypes';

export const fetchUserLeads = (params) => {
    return {
        type: FETCH_USER_LEADS,
        payload: { params }
    }
};

export const fetchUserLeadsSuccess = (data) => {
    return {
        type: FETCH_USER_LEADS_SUCCESS,
        payload: data,
    }
};

export const fetchUserLeadsError = (data) => {
    return {
        type: FETCH_USER_LEADS_ERROR,
        payload: data,
    }
};

export const cleanUserLeads = () => {
    return { type: CLEAN_USER_LEADS };
};