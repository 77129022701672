import React from 'react';
import { Card, CardBody, CardTitle, Col, FormGroup, Label, Row } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NumberFormat from 'react-number-format';

import {
    SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD,
} from '../../../../../consts/billing/subscription';

const BillingPaymentInfoComponent = (props) => {
    const { data: {
        price,
        credits,
        tariff,
    } } = props;

    return (
        <Row>
            <Col lg="12">
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">{props.t('payment_information')}</CardTitle>
                        {credits && (
                            <FormGroup row className={'mb-4'}>
                                <Label className={'col-form-label col-lg-2'}>{props.t('credits')}</Label>

                                <Col lg="10">
                                    <div className={'form-field-vertical-center font-size-16'}>
                                        <span>{credits}</span>
                                    </div>
                                </Col>
                            </FormGroup>
                        )}

                        {tariff && (
                            <>
                                <FormGroup row className={'mb-4'}>
                                    <Label className={'col-form-label col-lg-2'}>{props.t('subscription_plan')}</Label>

                                    <Col lg="10">
                                        <div className={'form-field-vertical-center font-size-16'}>
                                            <span>{tariff.name}</span>
                                        </div>
                                    </Col>
                                </FormGroup>

                                <FormGroup row className={'mb-4'}>
                                    <Label className={'col-form-label col-lg-2'}>{props.t('billed_period')}</Label>

                                    <Col lg="10">
                                        <div className={'form-field-vertical-center font-size-16'}>
                                            <span>
                                                {
                                                    tariff?.period === SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD ?
                                                        props.t('tariff.period.annually') :
                                                        props.t('tariff.period.monthly')
                                                }
                                            </span>
                                        </div>
                                    </Col>
                                </FormGroup>
                            </>
                        )}

                        {price && (
                            <FormGroup row className={'mb-4'}>
                                <Label className={'col-form-label col-lg-2'}>{props.t('price')}</Label>

                                <Col lg="10">
                                    <div className={'form-field-vertical-center font-size-16'}>
                                        <NumberFormat
                                            value={price}
                                            thousandSeparator={' '}
                                            prefix={'€ '}
                                            displayType={'text'}
                                        />
                                    </div>
                                </Col>
                            </FormGroup>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const BillingPaymentInfo = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingPaymentInfoComponent)));