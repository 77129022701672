import React, {useMemo} from 'react';
import {Card, CardBody} from "reactstrap";

import {LoadingOverlay} from 'components/Custom';
import {CrmInfo} from "./CrmInfo"
import {AccountAdd} from "./AccountAdd"
import {CrmDetails} from "./CrmDetails"
import {CrmAccountRemove} from "../../CrmAccountRemove"
import {CrmSync} from "../../CrmSync"

export const CrmSystemCard = (props) => {
  const {crm, data, onAccountCreate, accountCreateProps, accountRemoveProps, syncProps} = props;

  const {id, my_crm_system_account: account} = data
  const {loading: creating} = accountCreateProps
  const {loading: removing} = accountRemoveProps
  const {loading: syncing} = syncProps

  const isLoading = useMemo(() => {
    return false
  }, [])

  const isDisabled = useMemo(() => {
    return isLoading || creating || removing || syncing
  }, [isLoading, creating, removing, syncing])

  return (
    <LoadingOverlay isLoading={isLoading}>
      <Card>
        <CardBody>
          <CrmInfo data={data} crm={crm}/>

          {!account ? (
            <div className={"d-flex justify-content-center"}>
              <AccountAdd onAdd={onAccountCreate} disabled={isDisabled} loading={creating}/>
            </div>
          ) : (
            <>
              <div className={"d-flex justify-content-center"}>
                <CrmSync id={id} crm={crm} disabled={isDisabled || account.active} loading={syncing}/>
              </div>

              <CrmDetails data={account}/>

              <div className={"d-flex justify-content-center"}>
                <CrmAccountRemove id={id} crm={crm} disabled={isDisabled} loading={removing}/>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </LoadingOverlay>
  );
};