import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { Pagination } from '../../../../../components/Custom';
import { ClientsTable } from '../../Client';
import { fetchClientsAddedFavorite, cleanClientsAddedFavoritePage } from '../../../../../store/actions';

import * as HOC from '../../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const ClientsFavoritePropertyComponent = (props) => {
    const { visible } = props;

    const [page, setPage] = useState(1);

    useEffect(() => {
        if (visible) {
            const params = {
                page,
            };

            props.fetchClientsAddedFavorite(props.propertyId, params);
        }
    }, [visible, page]);

    const toggleModal = () => {
        props.toggleVisible();
    };

    const onClosed = () => {
        props.cleanClientsAddedFavoritePage();
        setPage(1);
    };

    const handlePageChange = (item) => {
        setPage(item.selected + 1);
    };

    return (
        <>
            <Modal
                size="lg"
                isOpen={visible}
                toggle={toggleModal}
                onClosed={onClosed}
            >
                <ModalHeader
                    toggle={toggleModal}
                >
                    {props.t('clients')}
                </ModalHeader>

                <ModalBody>
                    <TableDataLoading isLoading={props.loading} isFailed={props.error} error={props.t(props.error)}>
                        <ClientsTable
                            items={props.list}
                            isLoading={props.loading}
                        />

                        <Pagination data={props.meta} onPageChange={handlePageChange} />
                    </TableDataLoading>
                </ModalBody>
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError, meta } = state.property.clients.favorites;
    return {
        list,
        loading: listLoading,
        error: listError,
        meta,
    };
};

const mapDispatchToProps = {
    fetchClientsAddedFavorite,
    cleanClientsAddedFavoritePage,
};

export const ClientsFavoriteProperty = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientsFavoritePropertyComponent));