import React, { useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { AssignConsultantForm } from './AssignConsultantForm';
import { LEAD_STATUS_CLOSED } from '../../../../consts/lead';
import { leadAssignConsultant, cleanLeadAssignConsultant } from '../../../../store/actions';

const AssignConsultantComponent = (props) => {
    useEffect(() => {
        return () => {
            props.cleanLeadAssignConsultant();
        };
    }, []);

    const onSubmit = (values) => {
        const params = {
            user: values.user.id,
        };

        props.leadAssignConsultant(props.lead.id, params);
    };

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <CardTitle>{props.t('send_lead')}</CardTitle>

                        {props.assignSuccess && props.assignSuccess ? <Alert color="success">{props.t(props.assignSuccess)}</Alert> : null}
                        {props.assignError && props.assignError.message ? <Alert color="danger">{props.assignError.message}</Alert> : null}

                        <AssignConsultantForm
                            onSubmit={onSubmit}
                            initialValues={{
                                user: props.lead.user,
                            }}
                            loading={props.assignLoading}
                            disabled={props.lead.status === LEAD_STATUS_CLOSED}
                        />

                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const { assignLoading, assignSuccess, assignError, } = state.lead.assign;
    return {
        assignLoading,
        assignSuccess,
        assignError,
    };
};

const mapDispatchToProps = {
    leadAssignConsultant,
    cleanLeadAssignConsultant,
};

export const AssignConsultant = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AssignConsultantComponent));