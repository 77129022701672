import {
    PROPERTY_SET_FILES,
    PROPERTY_UPLOAD_PHOTO,
    PROPERTY_UPLOAD_PHOTO_SUCCESS,
    PROPERTY_UPLOAD_PHOTO_ERROR,
    PROPERTY_REMOVE_FILE,
    PROPERTY_REMOVE_FILE_SUCCESS,
    PROPERTY_REMOVE_FILE_ERROR,
    CLEAN_PROPERTY_FILES,
} from './actionTypes';

const initialState = {
    photos: [],
    pending: [],
};

function updateObjectInArray(array, payload) {
    return array.map((item, index) => {
        if (item.fileId !== payload.fileId) {
            // This isn't the item we care about - keep it as-is
            return item
        }

        // Otherwise, this is the one we want - return an updated value
        return {
            ...item,
            ...payload.params
        }
    })
}

const propertyPhotosUpload = (state = initialState, action) => {
    switch (action.type) {
        case PROPERTY_SET_FILES:
            state = {
                ...state,
                photos: [...state.photos, ...action.payload],
            };
            break;

        case PROPERTY_UPLOAD_PHOTO:
            state = {
                ...state,
                pending: [...state.pending, action.payload],
            };
            break;

        case PROPERTY_UPLOAD_PHOTO_SUCCESS:
            state = {
                ...state,
                photos: updateObjectInArray([...state.photos], action.payload),
                pending: state.pending.filter((item) => item !== action.payload.fileId),
            };
            break;

        case PROPERTY_UPLOAD_PHOTO_ERROR:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload),
            };
            break;

        case PROPERTY_REMOVE_FILE:
            state = {
                ...state,
                pending: [...state.pending, action.payload],
            };
            break;

        case PROPERTY_REMOVE_FILE_SUCCESS:
            state = {
                ...state,
                photos: state.photos.filter((item) => item.fileId !== action.payload),
                pending: state.pending.filter((item) => item !== action.payload),
            };
            break;

        case PROPERTY_REMOVE_FILE_ERROR:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload),
            };
            break;

        case CLEAN_PROPERTY_FILES:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default propertyPhotosUpload;