import {all} from 'redux-saga/effects';

import createSaga from './create/saga';
import removeSaga from './remove/saga';

function* accountSaga() {
  yield all([
    createSaga(),
    removeSaga(),
  ])
}

export default accountSaga;