import React, { useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import { languages } from '../../../helpers';

export const LanguageSelect = ({
    value,
    error,
    onChange,
}) => {
    const [menu, setMenu] = useState(false);

    const getLang = (code) => {
        return languages.find(item => item.code === code) || languages[0];
    };

    function changeLanguage(e, lng) {
        e.preventDefault();

        onChange(lng);
    }

    const renderLangs = () => {
        return languages.map((item, index) => {
            return (
                <DropdownItem key={'lng-' + index} tag="a" href="#" onClick={(e) => changeLanguage(e, item.code)} className="notify-item">
                    <img src={item.flag} alt="" className="mr-1" height="12" />
                    <span className="align-middle">{item.title}</span>
                </DropdownItem>
            )
        })
    };

    return (
        <Dropdown
            isOpen={menu}
            toggle={(e) => {e.preventDefault(); setMenu(!menu)}}
            className="d-inline-block"
        >
            <DropdownToggle
                className="waves-effect language-select-btn"
                // tag="button"
            >
                <img
                    src={getLang(value).flag}
                    height="16"
                    className="mr-1"
                />
                <span className="align-middle">{getLang(value).title}</span>
            </DropdownToggle>
            <DropdownMenu className="language-switch">
                {renderLangs()}
            </DropdownMenu>
        </Dropdown>
    );
};