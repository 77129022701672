import {
    SET_LANGUAGE,
    SET_APP_IS_VISIBLE,
    SET_APP_DATA_IS_LOADED,
} from './actionTypes';

const INIT_STATE = {
    language: null,
    appIsVisible: true,
    appDataIsLoaded: true,
};

const Common = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            };

        case SET_APP_IS_VISIBLE:
            return {
                ...state,
                appIsVisible: action.payload
            };

        case SET_APP_DATA_IS_LOADED:
            return {
                ...state,
                appDataIsLoaded: action.payload
            };

        default:
            return state;
    }
};

export default Common;