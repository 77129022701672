import React, { useEffect, useState, useMemo, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, UncontrolledTooltip, Button } from "reactstrap";

import { ValidationErrors } from '../../../../../../components/Custom';
import { chatSendMessage, cleanChatSendMessage, chatAttachFiles, chatCleanAttachments } from '../../../../../../store/actions';
import { AttachmentToolbar } from './AttachmentToolbar';
import { formatBytes } from '../../../../../../utils/files';
import { echoChannelWhisper } from '../../../../../../store/middlewares/echo/actions';
import { CHAT_TYPING_EVENT } from '../../../../../../consts/echo';

const InputToolbarComponent = (props) => {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (props.participant) {
            if (inputValue !== '') {
                inputChange('');
            }

            inputFocus();
        }

        return () => {
            props.cleanChatSendMessage();
            props.chatCleanAttachments();
        }
    }, [props.participant]);

    useEffect(() => {
        if (inputValue !== '' && props.roomChannel) {
            // send socket event (whisper) for typing
            props.echoChannelWhisper(props.roomChannel, CHAT_TYPING_EVENT, { userId: props.user.id })
        }

        return () => {
        }
    }, [inputValue]);

    const onKeyPress = e => {
        const { key, value } = e;

        if (key === "Enter") {
            onSend();
        }
    };

    const inputChange = (value) => {
        setInputValue(value);
    };

    const inputFocus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const onSendSuccess = () => {
        setInputValue('');
        props.chatCleanAttachments();

        props.onSendSuccess();

        inputFocus();
    };

    const uploadedFiles = useMemo(() => {
        return props.attachments.filter(item => item.id).map(e => e.id);
    }, [props.attachments]);

    const isDisabled = useMemo(() => {
        return props.loading || props.messagesLoading || (inputValue.trim() === '' && uploadedFiles.length === 0) || !!props.attachmentPending.length;
    }, [inputValue, props.loading, props.messagesLoading, props.attachmentPending, uploadedFiles]);

    const onSend = () => {
        if (isDisabled) {
            return;
        }

        const { id } = props.participant;

        let params = {};

        if (inputValue.trim() !== '') {
            params.body = inputValue.trim();
        }

        if (!!uploadedFiles.length) {
            params.attachments = uploadedFiles;
        }

        props.chatSendMessage(id, params, onSendSuccess);
    };

    const handleAttachFiles = (event) => {
        const files = [...event.target.files];

        let result = files.map(file => ({
            file: file,
            fileId: Math.random(),
            // preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
        }));

        props.chatAttachFiles(result);

        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const attachFiles = (event) => {
        event.preventDefault();

        if (props.loading) {
            return;
        }

        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <>
            <AttachmentToolbar attachments={props.attachments} />

            <div className="p-3 chat-input-section">

                {props.error ? <ValidationErrors data={props.error} /> : null}

                <Row>
                    <Col>
                        <div className="position-relative">
                            <input
                                type="textarea"
                                value={inputValue}
                                onKeyPress={onKeyPress}
                                onChange={e => inputChange(e.target.value)}
                                className="form-control chat-input"
                                placeholder={props.t('chat.input.placeholder')}
                                disabled={props.loading}
                                ref={inputRef}
                                maxLength="10000"
                            />
                            <div className="chat-input-links">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item">
                                        <input
                                            type="file"
                                            className={'d-none'}
                                            onChange={handleAttachFiles}
                                            ref={fileInputRef}
                                            multiple
                                            accept="image/png, image/jpeg, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf"
                                        />

                                        <Link to="#" onClick={attachFiles}>
                                            <i
                                                className="mdi mdi-file-document-outline"
                                                id="Filetooltip"
                                            />
                                            <UncontrolledTooltip
                                                placement="top"
                                                target="Filetooltip"
                                            >
                                                {props.t('chat.tooltip.add_files')}
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-auto">
                        <Button
                            type="button"
                            color="primary"
                            onClick={onSend}
                            className="btn-rounded chat-send w-md waves-effect waves-light"
                            disabled={isDisabled}
                        >
                        <span className="d-none d-sm-inline-block mr-2">
                            {props.t('send')}
                        </span>{" "}
                            <i className="mdi mdi-send" />
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    const { participant } = state.chat.conversation.data;
    const { loading, error } = state.chat.conversation.send;
    const { attachments, pending } = state.chat.conversation.attachment;
    const { data } = state.user;

    return {
        participant,
        loading,
        error,
        messagesLoading: state.chat.conversation.messages.loading,
        attachments,
        attachmentPending: pending,
        user: data,
    };
};

const mapDispatchToProps = {
    chatSendMessage,
    cleanChatSendMessage,
    chatAttachFiles,
    chatCleanAttachments,
    echoChannelWhisper,
};

export const InputToolbar = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InputToolbarComponent)));