import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { CompanyBlock } from './CompanyBlock';
import { PriceBlock } from './PriceBlock';
import { PropertyDetailsBlock } from './PropertyDetailsBlock';
import { PropertyDescriptionBlock } from './PropertyDescriptionBlock';
import { InviteStatusClose } from '../../InviteStatus/InviteStatusClose';
import { useAllowed } from "../../../../../hooks";
import { ROLE_CLIENT } from "../../../../../consts/roles";
import { INVITE_STATUS_ACTIVE } from "../../../../../consts/invite";

const PropertyInfoSectionComponent = (props) => {
    let onlyClient = useAllowed([ROLE_CLIENT], props.user.role);

    return (
        <>
            {!props.property.owned && (
                <CompanyBlock company={props.property.myReceivedLink?.sender?.company} />
            )}

            <PriceBlock property={props.property} />

            <PropertyDetailsBlock mode={['main']} property={props.property} user={props.user} />

            <PropertyDetailsBlock mode={['additional']} property={props.property} user={props.user} />

            <PropertyDescriptionBlock description={props.property.description} />

            {onlyClient && props.property.myReceivedLink && props.property?.myReceivedLink?.status === INVITE_STATUS_ACTIVE && (
                <InviteStatusClose
                    linkId={props.property?.myReceivedLink.id}
                />
            )}
        </>
    );
};

const mapStateToProps = state => {
    const {
        property,
    } = state.property.view;
    return {
        property,
        user: state.user.data,
    };
};

const mapDispatchToProps = {
};

export const PropertyInfoSection = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyInfoSectionComponent)));