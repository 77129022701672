import React, { useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { reduxForm, getFormValues } from 'redux-form'
import { Alert } from "reactstrap";

import { FORM_PROPERTY_REQUEST_HOME_WIZARD } from '../../../../../../consts/forms';
import { WizardPager } from '../../WizardPager';
import { PropertyRequestInfoTable, ValidationErrors } from '../../../../../../components/Custom';
import { PROPERTY_REQUEST_WIZARD_FULFILLED_MIN_PERCENT } from '../../../../../../consts/property';
import { createPropertyRequest, cleanCreatePropertyRequest } from '../../../../../../store/actions';

const HomeWizardStepConfirmComponent = (props) => {
    const history = useHistory();

    useEffect(() => {
        return () => {
            props.cleanCreatePropertyRequest()
        };
    }, []);

    const onSubmit = (values) => {
        let params = {
            type: 'new_home',
            price_min: values.price.range[0],
            price_max: values.price.range[1],
            min_size: values.min_size[0],
            bedrooms: values.bedrooms[0],
            bathrooms: values.bathrooms[0],
            min_size_garden: values.min_size_garden[0],
            min_size_terrace: values.min_size_terrace[0],
        };

        if (values.cities) {
            params.cities = values.cities.map(item => item.id);
        }

        if (values.regions) {
            params.regions = values.regions.map(item => item.id);
        }

        if (values.property_types) {
            params.property_types = values.property_types;
        }

        if (values.parking) {
            params.parking = values.parking;
        }

        if (values.property_ages) {
            params.property_ages = values.property_ages;
        }

        if (values.outside_facing) {
            params.outside_facing = values.outside_facing;
        }

        if (values.floor) {
            params.floor = values.floor;
        }

        props.createPropertyRequest(params, history);
    };

    const percentWarning = useMemo(() => {
        return props.percent < PROPERTY_REQUEST_WIZARD_FULFILLED_MIN_PERCENT;
    }, [props.percent]);

    const isDisabled = useMemo(() => {
        return props.loading;
    }, []);

    return (
        <>
            <h5 className={'mr-2'}>{props.t('confirmation')}</h5>

            <p className="text-muted mb-4">{props.t('check_and_confirm_info')}</p>

            <div className={'mb-4'}>
                <PropertyRequestInfoTable
                    keys={['price', 'property_types', 'regions', 'cities', 'min_size', 'bedrooms', 'bathrooms', 'parking', 'property_ages', 'min_size_terrace', 'min_size_garden', 'outside_facing', 'floor']}
                    data={{
                        ...props.formValues,
                        price_min: props.formValues?.price?.range[0],
                        price_max: props.formValues?.price?.range[1],
                        min_size: props.formValues?.min_size[0],
                        bedrooms: props.formValues?.bedrooms[0],
                        bathrooms: props.formValues?.bathrooms[0],
                        min_size_terrace: props.formValues?.min_size_terrace[0],
                        min_size_garden: props.formValues?.min_size_garden[0],
                    }}
                />
            </div>

            {percentWarning && (
                <Alert color="danger">{props.t('fill_level.percent.error.min', { percent: PROPERTY_REQUEST_WIZARD_FULFILLED_MIN_PERCENT })}</Alert>
            )}

            {props.createError ? <ValidationErrors data={props.createError} /> : null}

            <WizardPager
                next={props.t('confirm')}
                prev={props.t('previous')}
                onNext={props.handleSubmit(onSubmit)}
                onPrev={props.back}
                prevDisabled={isDisabled}
                nextDisabled={isDisabled || percentWarning}
                onNextLoading={props.loading}
            />
        </>
    );
};

const HomeWizardForm = reduxForm({
    form: FORM_PROPERTY_REQUEST_HOME_WIZARD,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(HomeWizardStepConfirmComponent);

const mapStateToProps = state => {
    const formValues = getFormValues(FORM_PROPERTY_REQUEST_HOME_WIZARD)(state);

    const { loading, createError } = state.propertyRequest.create;
    return {
        formValues,
        loading,
        createError,
    };
};

const mapDispatchToProps = {
    createPropertyRequest,
    cleanCreatePropertyRequest,
};

export const HomeWizardStepConfirm = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomeWizardForm)));