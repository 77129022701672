import {
    BILLING_SEND_DEFAULT_PAYMENT_CARD,
    BILLING_SEND_DEFAULT_PAYMENT_CARD_SUCCESS,
    BILLING_SEND_DEFAULT_PAYMENT_CARD_ERROR,
    BILLING_CLEAN_SEND_DEFAULT_PAYMENT_CARD,
} from './actionTypes';

const initialState = {
    sendDefaultLoading: false,
    sendDefaultError: null,
};

const sendDefault = (state = initialState, action) => {
    switch (action.type) {
        case BILLING_SEND_DEFAULT_PAYMENT_CARD:
            state = {
                ...state,
                sendDefaultLoading: true,
                sendDefaultError: null,
            };
            break;

        case BILLING_SEND_DEFAULT_PAYMENT_CARD_SUCCESS:
            state = {
                ...state,
                sendDefaultLoading: false,
            };
            break;

        case BILLING_SEND_DEFAULT_PAYMENT_CARD_ERROR:
            state = {
                ...state,
                sendDefaultLoading: false,
                sendDefaultError: action.payload,
            };
            break;

        case BILLING_CLEAN_SEND_DEFAULT_PAYMENT_CARD:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default sendDefault;