import React, { useEffect, createRef } from 'react';
import { Button, Alert, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import moment from 'moment';

import { swapBillingSubscription, cleanSwapBillingSubscription } from '../../../../../store/actions';
import ButtonLoadingIcon from '../../../../../components/Common/ButtonLoadingIcon';
import { CustomModal } from '../../../../../components/Custom';
import { SwapTariffInfo } from './SwapTariffInfo';

const SubscriptionSwapComponent = (props) => {
    const { tariff, subscription } = props;

    const history = useHistory();

    const modalRef = createRef();

    useEffect(() => {
        return () => {
            props.cleanSwapBillingSubscription();
        };
    }, []);

    const showModal = () => {
        if (modalRef.current) {
            modalRef.current.show();
        }
    };

    const hideModal = () => {
        if (modalRef.current) {
            modalRef.current.hide();
        }
    };

    const handleConfirm = () => {
        hideModal();

        props.swapBillingSubscription(tariff.id, onSuccess);
    };

    const onSwap = () => {
        showModal();
    };

    const onSuccess = () => {
        history.push(`/billing`)
    };

    return (
        <div>
            {props.swapError && <Alert color="danger" className={'text-left mb-4'}>{props.swapError}</Alert>}

            <Button
                color="success"
                className={'btn-lg'}
                disabled={!tariff || props.swapLoading}
                onClick={onSwap}
            >
                <ButtonLoadingIcon loading={props.swapLoading} />
                {props.t('subscription.change_plan')}
            </Button>

            {props.tariff && (
                <CustomModal
                    ref={modalRef}
                    onVisibleChange={() => {}}
                    title={props.t('confirmation')}
                >
                    <SwapTariffInfo tariff={tariff} />

                    <Row className="justify-content-end">
                        <Col lg="9">
                            <div className="button-items">
                                <Button color="primary" onClick={handleConfirm} disabled={props.swapLoading}>
                                    {props.t('confirm')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CustomModal>
            )}
        </div>
    );
};


const mapStateToProps = state => {
    const { swapLoading, swapError } = state.billing.subscription.reactions.swap;

    return {
        swapLoading, swapError,
    };
};

const mapDispatchToProps = {
    swapBillingSubscription,
    cleanSwapBillingSubscription,
};

export const SubscriptionSwap = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SubscriptionSwapComponent)));