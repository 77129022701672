import { combineReducers } from "redux";

import configReducer from './config/reducer';
import acceptedReducer from './accepted/reducer';
import ownedReducer from './owned/reducer';
import generalReducer from './general/reducer';
import favoriteReducer from './favorite/reducer';
import allReducer from './all/reducer';
import statisticsReducer from './statistics/reducer';

const clientPropertiesReducer = combineReducers({
    config: configReducer,
    accepted: acceptedReducer,
    owned: ownedReducer,
    general: generalReducer,
    favorite: favoriteReducer,
    all: allReducer,
    statistics: statisticsReducer
});

export default clientPropertiesReducer;