import React, {useEffect} from 'react';
import {Alert} from "reactstrap";
import {connect} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';

import {Header} from '../Header';
import {RegisterForm} from '../RegisterForm';
import {ROLE_CLIENT} from "consts/roles";
import {ValidationErrors, SocialLogin} from 'components/Custom';
import {registerUser, registerUserClean, socialRegisterUser} from "store/actions";

const DEFAULT_LANGUAGE_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE;

const RegisterContainerComponent = (props) => {
  const {t} = useTranslation();
  const history = useHistory();

  const onSubmit = (values) => {
    let params = {
      ...values,
      role: props.role,
    };

    if (props.role === ROLE_CLIENT && params.company_name) {
      delete params.company_name;
    }

    props.registerUser(params);
  };

  useEffect(() => {
    return () => {
      props.registerUserClean();
    }
    // eslint-disable-next-line
  }, []);

  const onSocialSuccess = (data) => {
    const {provider, ...params} = data;

    let formData = {
      provider: provider,
      role: props.role,
    };

    props.socialRegisterUser(params, formData, history);
  };

  return (
    <div className="d-flex flex-column h-100">
      <Header/>

      <div className="my-auto">
        <div>
          <h5 className="text-primary">
            {props.role === ROLE_CLIENT ? t('register_account') : t('register_manager_account')}</h5>
          <p className="text-muted">{t('get.account')}</p>
        </div>

        <div className="mt-4">
          {props.success && props.success ? (
            <Alert color="success">
              {t('confirmation.email.sent')}
            </Alert>
          ) : null}

          {props.serverError && props.serverError ? (
            <Alert color="danger">
              {props.serverError}
            </Alert>
          ) : null}

          {props.socialError ? <ValidationErrors data={props.socialError}/> : null}

          <RegisterForm
            initialValues={{
              lang: DEFAULT_LANGUAGE_CODE || 'nl',
            }}
            onSubmit={onSubmit}
            loading={props.loading}
            role={props.role}
          />

          <div className="mt-4 text-center">
            <h5 className="font-size-14 mb-3">{t('sign_up_using')}</h5>

            <SocialLogin onSuccess={onSocialSuccess}/>
          </div>

          <div className="mt-5 text-center">
            <p>
              {t('already_have_an_account')}{" "}
              <Link
                to="/login"
                className="font-weight-medium text-primary"
              >
                {" "}
                {t('login')}{" "}
              </Link>{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="mt-4 mt-md-5 text-center">
        <p className="mb-0">
          © {new Date().getFullYear()} Brikk.
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const {success, serverError, loading, socialLoading, socialSuccess, socialError} = state.Account;
  return {
    success, serverError, loading,
    socialLoading, socialSuccess, socialError,
  };
};

export const RegisterContainer = connect(mapStateToProps, {
  registerUser,
  registerUserClean,
  socialRegisterUser
})(RegisterContainerComponent);
