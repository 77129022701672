import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { CardTitle } from "reactstrap";

import { Pagination } from '../../../../../../components/Custom';
import { LeadsTable } from '../../LeadsTable';
import {
    fetchCompanyPreviousUnpaidLeads,
    cleanCompanyPreviousUnpaidLeads,
    companyPreviousUnpaidLeadsSetPage,
} from '../../../../../../store/actions';
import { leadsUnpaidColumns } from '../../../../../../consts/lead';

import * as HOC from '../../../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const CompanyPreviousUnpaidLeadsComponent = (props) => {
    useEffect(() => {
        let params = {
            page: props.page,
        };

        props.fetchCompanyPreviousUnpaidLeads(params);
    }, [props.page]);

    useEffect(() => {
        return () => {
            props.cleanCompanyPreviousUnpaidLeads();
        }
    }, []);

    const handlePageChange = (item) => {
        props.companyPreviousUnpaidLeadsSetPage(item.selected + 1)
    };

    return (
        <>
            <CardTitle className={'mt-2 mb-4'}>{props.t('leads.previous.unpaid.table.title')}</CardTitle>

            <TableDataLoading isLoading={props.listLoading} isFailed={props.listError} error={props.t(props.listError)}>
                <>
                    <LeadsTable
                        keys={leadsUnpaidColumns}
                        items={props.list}
                        isLoading={props.listLoading}
                        onSort={() => {}}
                    />

                    <Pagination data={props.meta} onPageChange={handlePageChange} />
                </>
            </TableDataLoading>
        </>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError, meta, page } = state.lead.companyLeads.previousUnpaid;
    return {
        list,
        listLoading,
        listError,
        meta,
        page,
    };
};

const mapDispatchToProps = {
    fetchCompanyPreviousUnpaidLeads,
    cleanCompanyPreviousUnpaidLeads,
    companyPreviousUnpaidLeadsSetPage,
};

export const CompanyPreviousUnpaidLeads = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompanyPreviousUnpaidLeadsComponent)));