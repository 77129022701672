import React from 'react';
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useTranslation } from 'react-i18next';

import './CompanyBlock.scss';

export const CompanyBlock = ({ company }) => {
    const { t } = useTranslation();

    return (
        <>
            {company && (
                <Row>
                    <Col>
                        <Card>
                            <CardBody className={'card_pv_sm'}>
                                <div className="property-company-info-block">

                                    {company.avatar ? (
                                        <div
                                            className="company-logo company-logo_size_m mr-3"
                                            style={{ backgroundImage: `url("${company.avatar}")` }}
                                        />
                                    ) : (
                                        <span className={'font-size-14 font-weight-medium mr-3'}>{`${t('vendor')} :`}</span>
                                    )}

                                    <div className={'property-company-info-block__name font-size-14'}>
                                        {company.name}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
};