import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from "classnames";

export const LeadPagination = ({
    isDisabled = false,
    prev,
    next,
    onChange,
}) => {
    const { t } = useTranslation();

    const onClick = (e, id) => {
        e.preventDefault();

        if (id) {
            onChange(id);
        }
    }

    return (
        <>
            <ul className="pagination mb-0">
                <li className={classnames({
                        'page-item': true,
                        'disabled': isDisabled || !prev,
                    })}
                >
                    <a className="page-link" tabIndex="0" role="button" aria-disabled="false" aria-label={t('previous')} rel="prev"
                       onClick={e => onClick(e, prev)}
                    >
                        {t('previous')}
                    </a>
                </li>

                <li className={classnames({
                    'page-item': true,
                    'disabled': isDisabled || !next,
                })}
                >
                    <a className="page-link" tabIndex="0" role="button" aria-disabled="false" aria-label={t('next')} rel="next"
                       onClick={e => onClick(e, next)}
                    >
                        {t('next')}
                    </a>
                </li>
            </ul>
        </>
    );
};