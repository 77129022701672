export const EDIT_PROFILE = 'EDIT_PROFILE';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_ERROR = 'EDIT_PROFILE_ERROR';

export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS';
export const UPDATE_AVATAR_ERROR = 'UPDATE_AVATAR_ERROR';
export const REMOVE_AVATAR = 'REMOVE_AVATAR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const REMOVE_USER = 'REMOVE_USER';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR';

export const CLEAN_PROFILE_PAGE_INFO = 'CLEAN_PROFILE_PAGE_INFO';

export const UPDATE_COMPANY_LOGO = 'UPDATE_COMPANY_LOGO';
export const UPDATE_COMPANY_LOGO_SUCCESS = 'UPDATE_COMPANY_LOGO_SUCCESS';
export const UPDATE_COMPANY_LOGO_ERROR = 'UPDATE_COMPANY_LOGO_ERROR';
export const REMOVE_COMPANY_LOGO = 'REMOVE_COMPANY_LOGO';