import { combineReducers } from "redux";

import listsReducer from './lists/reducer';
import reactionReducer from './reaction/reducer';
import usersReducer from './users/reducer';

const inviteReducer = combineReducers({
    lists: listsReducer,
    reaction: reactionReducer,
    users: usersReducer,
});

export default inviteReducer;
