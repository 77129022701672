import {
    LEAD_ASSIGN_CONSULTANT,
    LEAD_ASSIGN_CONSULTANT_SUCCESS,
    LEAD_ASSIGN_CONSULTANT_ERROR,
    CLEAN_LEAD_ASSIGN_CONSULTANT,
} from './actionTypes';

const initialState = {
    assignLoading: false,
    assignSuccess: null,
    assignError: null,
};

const assign = (state = initialState, action) => {
    switch (action.type) {
        case LEAD_ASSIGN_CONSULTANT:
            state = {
                ...state,
                assignLoading: true,
                assignSuccess: null,
                assignError: null,
            };
            break;

        case LEAD_ASSIGN_CONSULTANT_SUCCESS:
            state = {
                ...state,
                assignLoading: false,
                assignSuccess: action.payload,
            };
            break;

        case LEAD_ASSIGN_CONSULTANT_ERROR:
            state = {
                ...state,
                assignLoading: false,
                assignError: action.payload,
            };
            break;

        case CLEAN_LEAD_ASSIGN_CONSULTANT:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default assign;