import React from 'react';
import {Container, Row, Col} from "reactstrap";
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {useTitle} from "../../hooks";
import {PageTitle} from '../../components/Custom';
import {CrmSystemsContainer} from './components';

const IntegrationComponent = (props) => {
  useTitle(props.t('integrations'));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <PageTitle title={props.t('integrations')}/>

          <Row>
            <Col lg="12">
              <CrmSystemsContainer/>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export const Integration = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IntegrationComponent)));