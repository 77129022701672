import _ from 'lodash';

import {
    PROPERTY_TYPE_APARTMENT,
    PROPERTY_TYPE_HOUSE,
    PROPERTY_TYPE_STUDIO,
    PROPERTY_TYPE_LOFT,
    PROPERTY_TYPE_OFFICE,
    PROPERTY_TYPE_RETAIL_SHOP,
    PROPERTY_TYPE_STORE,
    PROPERTY_TYPE_RESTAURANT_BAR,
    PROPERTY_TYPE_SERVICE_FLAT,
    PROPERTY_TYPE_DORMITORY,
    PROPERTY_TYPE_SECOND_HOME,
    PROPERTY_TYPE_CONSTRUCTION_YARD,
    PROPERTY_TYPE_GARAGE,
    PROPERTY_TYPE_HOTEL,
} from '../../consts/property';

export const getPropertyTypeOptions = (t) => {
    return [{
        label: t('apartment'),
        value: PROPERTY_TYPE_APARTMENT,
    }, {
        label: t('house'),
        value: PROPERTY_TYPE_HOUSE,
    }, {
        label: t('studio'),
        value: PROPERTY_TYPE_STUDIO,
    }, {
        label: t('loft'),
        value: PROPERTY_TYPE_LOFT,
    }, {
        label: t('office'),
        value: PROPERTY_TYPE_OFFICE,
    }, {
        label: t('retail_shop'),
        value: PROPERTY_TYPE_RETAIL_SHOP,
    }, {
        label: t('store'),
        value: PROPERTY_TYPE_STORE,
    }, {
        label: t('restaurant_bar'),
        value: PROPERTY_TYPE_RESTAURANT_BAR,
    }, {
        label: t('service_flat'),
        value: PROPERTY_TYPE_SERVICE_FLAT,
    }, {
        label: t('dormitory'),
        value: PROPERTY_TYPE_DORMITORY,
    }, {
        label: t('second_home'),
        value: PROPERTY_TYPE_SECOND_HOME,
    }, {
        label: t('construction_yard'),
        value: PROPERTY_TYPE_CONSTRUCTION_YARD,
    }, {
        label: t('garage'),
        value: PROPERTY_TYPE_GARAGE,
    }, {
        label: t('hotel_room'),
        value: PROPERTY_TYPE_HOTEL,
    }];
};

export const getPropertyTypeGroups = (t) => {
    return [{
        label: t('residential_real_estate'),
        options: [
            {
                label: t('apartment'),
                value: PROPERTY_TYPE_APARTMENT,
            }, {
                label: t('house'),
                value: PROPERTY_TYPE_HOUSE,
            }, {
                label: t('studio'),
                value: PROPERTY_TYPE_STUDIO,
            }, {
                label: t('loft'),
                value: PROPERTY_TYPE_LOFT,
            },
        ],
    }, {
        label: t('commercial_real_estate'),
        options: [
            {
                label: t('office'),
                value: PROPERTY_TYPE_OFFICE,
            }, {
                label: t('retail_shop'),
                value: PROPERTY_TYPE_RETAIL_SHOP,
            }, {
                label: t('store'),
                value: PROPERTY_TYPE_STORE,
            }, {
                label: t('restaurant_bar'),
                value: PROPERTY_TYPE_RESTAURANT_BAR,
            },
        ],
    }, {
        label: t('other_property'),
        options: [
            {
                label: t('service_flat'),
                value: PROPERTY_TYPE_SERVICE_FLAT,
            }, {
                label: t('dormitory'),
                value: PROPERTY_TYPE_DORMITORY,
            }, {
                label: t('second_home'),
                value: PROPERTY_TYPE_SECOND_HOME,
            }, {
                label: t('construction_yard'),
                value: PROPERTY_TYPE_CONSTRUCTION_YARD,
            }, {
                label: t('garage'),
                value: PROPERTY_TYPE_GARAGE,
            }, {
                label: t('hotel_room'),
                value: PROPERTY_TYPE_HOTEL,
            }
        ],
    }];
};

export const getPropertyType = (value, t) => {
    const options = getPropertyTypeOptions(t);
    const item = _.find(options, { value: value });

    return item || {
        label: '',
        value: '',
    };
};

export default {
    getPropertyTypeOptions,
    getPropertyType,
    getPropertyTypeGroups,
};