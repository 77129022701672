import { all } from 'redux-saga/effects';

import contactsSaga from './contacts/saga';
import conversationSaga from './conversation/sagas';
import chatsSaga from './chats/saga';

function* chatSaga() {
    yield all([
        contactsSaga(),
        conversationSaga(),
        chatsSaga(),
    ])
}

export default chatSaga;