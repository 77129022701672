import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import classnames from "classnames";

const FilterModalComponent = (props) => {
    const [modalVisible, setModalVisible] = useState(false);

    useImperativeHandle(props.parentRef, () => ({
            hide() {
                hideFilter();
            }
        }),
    );

    useEffect(() => {
        props.onVisibleChange(modalVisible);
    }, [modalVisible]);

    const showFilter = () => {
        setModalVisible(true);
    };

    const hideFilter = () => {
        setModalVisible(false);
    };

    const toggleFilter = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <React.Fragment>
            {props.asIcon ?
                <Link to={"#"} onClick={showFilter}>
                    <i className={classnames("bx bx-filter-alt font-size-18 align-middle text-primary", {
                        'text-warning': !!props.count
                    })}/>
                </Link>
                : <Button
                    color="primary"
                    onClick={showFilter}
                >
                    <i className={`font-size-16 align-middle mr-2 bx ` + (props.count ? `bxs-filter-alt` : 'bx-filter-alt')}/>
                    {props.t('filter')}
                    {props.count ? ` (${props.count})` : ''}
                </Button>}

            <Modal
                size="lg"
                isOpen={modalVisible}
                toggle={toggleFilter}
            >
                <ModalHeader
                    toggle={toggleFilter}
                >
                    {props.t('filter')}
                </ModalHeader>

                <ModalBody>
                    {props.children}
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

const FilterModalHOC = withTranslation()(FilterModalComponent);

export const FilterModal = forwardRef((props, ref) => (
    <FilterModalHOC {...props} parentRef={ref}/>
));