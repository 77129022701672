import React from 'react';
import { Card, CardBody } from "reactstrap";

export const ProfileInfoBox = ({ children }) => {
    return (
        <Card className="mini-stats-wid profile-card-wrap profile-mini-card">
            <CardBody className="">
                <div className={'profile-mini-card-content'}>
                    {children}
                </div>
            </CardBody>
        </Card>
    );
};