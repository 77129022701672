import React, { useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Button, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { connect } from "react-redux";

import { INVITE_STATUS_UNCONFIRMED, INVITE_STATUS_DECLINED, INVITE_STATUS_ACTIVE, INVITE_STATUS_CLOSED } from '../../../../consts/invite';
import { updateInvite, cleanUpdateInvite } from "../../../../store/actions";

const InviteStatusActivateComponent = (props) => {
    const { linkId, consultant, status } = props;

    const history = useHistory();

    useEffect(() => {
        return () => {
            props.cleanUpdateInvite();
        }
    }, []);

    const handleChangeStatus = (status) => {
        props.updateInvite(linkId, { status }, props.status, history);
    };

    return (
        <Row className={''}>
            <Col>
                <Card>
                    <CardBody className={''}>
                        <CardTitle className="mb-2 text-center">
                            <Link
                                to={`/contacts/${consultant.id}`}
                                className="text-dark font-weight-bold text-decoration-underline"
                            >
                                {consultant.name}
                            </Link>
                            {` ${props.t('invite.message')}`}
                        </CardTitle>

                        <p className="mb-3 text-center">
                            {status === INVITE_STATUS_UNCONFIRMED ? (
                                props.t('invite.action_message.unconfirmed')
                            ) : status === INVITE_STATUS_DECLINED ? (
                                props.t('invite.action_message.declined')
                            ) : status === INVITE_STATUS_CLOSED ? (
                                props.t('invite.action_message.closed')
                            ) : null}
                        </p>

                        {props.updateInviteError && props.updateInviteError ? <Alert color="danger">{props.updateInviteError}</Alert> : null}

                        <div className="button-items text-center">
                            <Button
                                type="button"
                                color="success"
                                disabled={props.updateInviteLoading}
                                onClick={() => handleChangeStatus(INVITE_STATUS_ACTIVE)}
                            >
                                {props.t('accept')}
                            </Button>

                            {status === INVITE_STATUS_UNCONFIRMED && (
                                <Button
                                    type="button"
                                    color="light"
                                    disabled={props.updateInviteLoading}
                                    onClick={() => handleChangeStatus(INVITE_STATUS_DECLINED)}
                                >
                                    {props.t('decline')}
                                </Button>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const {
        updateInviteLoading,
        updateInviteError,
    } = state.invite.reaction;
    return {
        updateInviteLoading,
        updateInviteError,
    };
};

const mapDispatchToProps = {
    updateInvite,
    cleanUpdateInvite,
};

export const InviteStatusActivate = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InviteStatusActivateComponent));