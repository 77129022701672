import {combineReducers} from "redux";

import create from './create/reducer';
import remove from './remove/reducer';

const accountReducer = combineReducers({
  create,
  remove,
});

export default accountReducer;