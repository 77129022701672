import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link, useRouteMatch } from 'react-router-dom';

import { useTitle } from "../../hooks";
import { Pagination, PageTitle } from '../../components/Custom';
import { PropertyRequestTable } from './components';
import {
    fetchPropertyRequestList,
    cleanPropertyRequestListPage,
    propertyRequestSetPage,
} from '../../store/actions';

import * as HOC from '../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const PropertyRequestListComponent = (props) => {
    useTitle(props.t('title.property_requests'));
    let { url } = useRouteMatch();

    useEffect(() => {
        let params = {
            page: props.page,
        };

        props.fetchPropertyRequestList(params);

    }, [props.page]);

    useEffect(() => {
        return () => {
            props.cleanPropertyRequestListPage();
        }
    }, []);

    const handlePageChange = (item) => {
        props.propertyRequestSetPage(item.selected + 1);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('title.property_requests')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <h5 className={'mt-2 mr-2'}>{props.t('property_requests.list')}</h5>

                                        <div className="button-items">
                                            <Link to={`${url}/suggested-property`} className="btn btn-primary">
                                                <i className="bx bxs-city font-size-16 align-middle mr-2"></i>
                                                {props.t('suggested_property')}
                                            </Link>
                                            <Link to={`${url}/create`} className="btn btn-primary">
                                                {props.t('create.new')}
                                                <i className="fas fa-angle-right font-size-16 align-middle ml-2"></i>
                                            </Link>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <TableDataLoading isLoading={props.listLoading} isFailed={props.listError} error={props.t(props.listError)}>
                                        <>
                                            <PropertyRequestTable
                                                items={props.list}
                                                isLoading={props.listLoading}
                                                onSort={() => {}}
                                            />

                                            <Pagination data={props.meta} onPageChange={handlePageChange} />
                                        </>
                                    </TableDataLoading>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError, meta, page } = state.propertyRequest.list;
    return {
        list,
        listLoading,
        listError,
        meta,
        page,
    };
};

const mapDispatchToProps = {
    fetchPropertyRequestList,
    cleanPropertyRequestListPage,
    propertyRequestSetPage,
};

export const PropertyRequestList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyRequestListComponent)));