import {
    FETCH_BILLING_INVOICES,
    FETCH_BILLING_INVOICES_SUCCESS,
    FETCH_BILLING_INVOICES_ERROR,
    CLEAN_BILLING_INVOICES,
    BILLING_INVOICES_SET_PAGE,
} from './actionTypes';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    listIsFetched: false,
    meta: null,
    page: 1,
};

const list = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_BILLING_INVOICES:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case FETCH_BILLING_INVOICES_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                listIsFetched: true,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case FETCH_BILLING_INVOICES_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case CLEAN_BILLING_INVOICES:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case BILLING_INVOICES_SET_PAGE:
            state = {
                ...state,
                page: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default list;