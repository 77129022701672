import {
    PROPERTY_SET_FILES,
    PROPERTY_UPLOAD_PHOTO,
    PROPERTY_UPLOAD_PHOTO_SUCCESS,
    PROPERTY_UPLOAD_PHOTO_ERROR,
    PROPERTY_REMOVE_FILE,
    PROPERTY_REMOVE_FILE_SUCCESS,
    PROPERTY_REMOVE_FILE_ERROR,
    CLEAN_PROPERTY_FILES,
} from './actionTypes';

export const setFiles = (data) => {
    return {
        type: PROPERTY_SET_FILES,
        payload: data,
    }
};

export const uploadPhoto = (data) => {
    return {
        type: PROPERTY_UPLOAD_PHOTO,
        payload: data,
    }
};

export const uploadPhotoSuccess = (fileId, params) => {
    return {
        type: PROPERTY_UPLOAD_PHOTO_SUCCESS,
        payload: { fileId, params },
    }
};

export const uploadPhotoError = (data) => {
    return {
        type: PROPERTY_UPLOAD_PHOTO_ERROR,
        payload: data,
    }
};

export const removePhoto = (data) => {
    return {
        type: PROPERTY_REMOVE_FILE,
        payload: data,
    }
};

export const removePhotoSuccess = (data) => {
    return {
        type: PROPERTY_REMOVE_FILE_SUCCESS,
        payload: data,
    }
};

export const removePhotoError = (data) => {
    return {
        type: PROPERTY_REMOVE_FILE_ERROR,
        payload: data,
    }
};

export const cleanFiles = () => {
    return { type: CLEAN_PROPERTY_FILES };
};