import React from 'react';
import s from './SuggestedPropertyLabel.module.scss';
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setChatConversation } from "../../../store/actions";
import classname from "classnames";

const SuggestedPropertyLabelComponent = (props) => {
    const history = useHistory()

    const handleClick = (e) => {
        e.preventDefault();
        history.push('/chat');
        props.setChatConversation(props.sender);
    };

    return (
        <div
            className={classname(`${s.label}`,{
                [props.className]: props.className
            })}
            onClick={handleClick}
        >
            <i className={`bx bx-message-rounded font-size-20`}/>
        </div>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    setChatConversation
};

export const SuggestedPropertyLabel = withRouter(connect(mapStateToProps, mapDispatchToProps)((SuggestedPropertyLabelComponent)));