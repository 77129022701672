import React from 'react';

import { Size } from './Size';
import { Bed } from './Bed';
import { Calendar } from './Calendar';
import { ShapePolygon } from './ShapePolygon';
import { Building } from './Building';
import { MapPointer } from './MapPointer';
import { Bathrooms } from './Bathrooms';
import { WorkInstruments } from './WorkInstruments';
import colors from "../../../assets/scss/custom-variables.module.scss"

export const Icon = (props) => {
    const color = props.color && props.color === 'primary' ? colors.mainGreen : props.color;

    const data = {
        ...props,
        color,
    };

    switch(props.name) {
        case 'size':
            return <Size {...data} />;
        case 'bed':
            return <Bed {...data} />;
        case 'calendar':
            return <Calendar {...data} />;
        case 'shape-polygon':
            return <ShapePolygon {...data} />;
        case 'building':
            return <Building {...data} />;
        case 'map-pointer':
            return <MapPointer {...data} />;
        case 'bathrooms':
            return <Bathrooms {...data} />;
        case 'work-instruments':
            return <WorkInstruments {...data} />;

        default:
            return <div />;
    }
};