import {
    PARSE_PROPERTY,
    PARSE_PROPERTY_SUCCESS,
    PARSE_PROPERTY_ERROR,
    CANCEL_PARSE_PROPERTY,
    CLEAN_PARSE_PROPERTY,
} from './actionTypes';

export const parseProperty = (params, callback) => {
    return {
        type: PARSE_PROPERTY,
        payload: { params, callback },
    }
};

export const parsePropertySuccess = (data) => {
    return {
        type: PARSE_PROPERTY_SUCCESS,
        payload: data,
    }
};

export const parsePropertyError = (data) => {
    return {
        type: PARSE_PROPERTY_ERROR,
        payload: data,
    }
};

export const cancelParseProperty = () => {
    return { type: CANCEL_PARSE_PROPERTY };
};

export const cleanParseProperty = () => {
    return { type: CLEAN_PARSE_PROPERTY };
};