import React from 'react';
import {
    Label,
    FormFeedback,
    FormGroup,
    Col,
} from "reactstrap";
import { useTranslation } from 'react-i18next';

import { LanguageSelect } from './LanguageSelect';

export const LanguageSelectField = ({
    label,
    type,
    input,
    meta: { touched, error },
    row,
    ...props
}) => {
    const { t } = useTranslation();
    const showError = touched && Boolean(error);

    function changeLanguageAction(lng) {
        input.onChange(lng);
    }

    const renderRow = () => {
        return (
            <FormGroup row className={'mb-4' + (showError ? ' text-danger' : '')}>
                <Label className={'col-form-label col-lg-2'}>{label}</Label>

                <Col lg="10">
                    <div className={'input-group' + (showError ? ' is-invalid' : '')}>
                        <LanguageSelect
                            value={input.value}
                            onChange={changeLanguageAction}
                        />
                    </div>
                    <FormFeedback invalid={showError ? error : null }>
                        { t(error) }
                    </FormFeedback>
                </Col>
            </FormGroup>
        );
    };

    const renderDefault = () => {
        return (
            <FormGroup className={showError ? 'text-danger' : ''}>
                <Label>{label}</Label>

                <div>
                    <LanguageSelect
                        value={input.value}
                        onChange={changeLanguageAction}
                    />
                </div>

                <FormFeedback invalid={showError ? error : null }>
                    { t(error, props.translateParams ? { ...props.translateParams } : {}) }
                </FormFeedback>
            </FormGroup>
        );
    };

    return (
        <>
            {row ? (
                renderRow()
            ) : (
                renderDefault()
            )}
        </>
    );
};