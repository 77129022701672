import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";

import { PROPERTY_REQUEST_TYPE_NEW_HOME } from '../../../../consts/propertyRequest';
import { PropertyRequestInfoTable } from '../../../../components/Custom';

const PropertyRequestInformationComponent = (props) => {
    const [visible, setVisible] = useState(true);

    const getTableLabels = () => {
        const type = props.propertyRequest.type;
        let keys = ['date', 'type', 'status'];
        if (type === PROPERTY_REQUEST_TYPE_NEW_HOME) {
            keys = [...keys, 'price', 'property_types', 'regions', 'cities', 'min_size', 'bedrooms', 'bathrooms', 'parking', 'property_ages', 'min_size_terrace', 'min_size_garden', 'outside_facing', 'floor'];
        } else {
            keys = [...keys, 'goal', 'expectation', 'type_loan', 'buying_entity', 'first_time_investor'];
        }

        return keys;
    };

    const toggleVisible = (e) => {
        e.preventDefault();
        setVisible(!visible);
    };

    return (
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <Link to={'#'} onClick={toggleVisible} className={'text-gray'}>
                            <Row className="no-gutters justify-content-between">
                                <CardTitle>{props.t('property_request.information')}</CardTitle>

                                <div>
                                    <i
                                        style={{ transform: `rotate(${visible ? '0deg' : '180deg'})`}}
                                        className="bx bx-chevron-up font-size-24 align-middle"
                                    />
                                </div>
                            </Row>
                        </Link>

                        <div
                            className={classNames({
                                'd-none': !visible,
                                'mt-4': true,
                            })}
                        >
                            <PropertyRequestInfoTable
                                keys={getTableLabels()}
                                data={props.propertyRequest}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const {
        propertyRequest,
    } = state.propertyRequest.view;
    return {
        propertyRequest,
    };
};

const mapDispatchToProps = {
};

export const PropertyRequestInformation = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyRequestInformationComponent)));