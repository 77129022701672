import React, { useEffect, useState } from 'react';
import { FormGroup, Alert } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next';

// actions
import { loginUser, apiError, loginClean, sendVerifyEmail, apiSuccess, socialLoginUser } from '../../store/actions';

import { FORM_SIGN_IN } from '../../consts/forms';

import ButtonLoadingIcon from '../../components/Common/ButtonLoadingIcon';
import TextInput from '../../components/Common/TextInput';
import { loginValidation } from './validations/validate';
import { useQuery, useTitle } from '../../hooks';
import { ValidationErrors, SocialLogin } from '../../components/Custom';
import { Header } from './components';

const LoginComponent = (props) => {
    let query = useQuery();
    const history = useHistory();
    const { t } = useTranslation();
    useTitle(t('title.login'));

    const { handleSubmit } = props;

    const [verifyEmail, setVerifyEmail] = useState(null);

    useEffect(() => {
        if (query.get('verified') === '1') {
            props.apiSuccess(t('verification.success'));

            history.replace({
                ...history.location,
                search: "",
            });
        }

        return () => {
            props.loginClean();
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (values) => {
        setVerifyEmail(values.email);

        props.loginUser(values, props.history);
    };

    const sendConfirmationEmail = async (e) => {
        e.preventDefault();
        props.clearSubmitErrors(FORM_SIGN_IN);

        props.sendVerifyEmail({ email: verifyEmail });
    };

    const onSocialSuccess = (data) => {
        props.socialLoginUser(data);
    };

    return (
        <div className="d-flex flex-column h-100">
            <Header />

            <div className="my-auto">
                <div>
                    <h5 className="text-primary">{t('welcome.back')}</h5>
                    <p className="text-muted">{t('sign-in.to.continue')} Brikk.</p>
                </div>

                <div className="mt-4">
                    {props.serverError && props.serverError ? <Alert color="danger">{props.serverError.message}</Alert> : null}

                    {props.success && props.success ? <Alert color="success">{props.success}</Alert> : null}

                    {props.serverError && props.serverError?.errors.unverified_email && (
                        <div className="mt-4">
                            <p className={'font-weight-medium'}>
                                <a href='#' onClick={sendConfirmationEmail} className={'font-weight-medium text-primary'}>{t('send.confirmation.email')}</a>
                                {` ${verifyEmail}`}
                            </p>
                        </div>
                    )}

                    {props.socialError ? <ValidationErrors data={props.socialError} /> : null}

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="form-horizontal"
                        noValidate={true}
                    >
                        <Field
                            name="email"
                            label={t('field.label.email')}
                            placeholder={t('field.placeholder.email')}
                            id={'email'}
                            type="email"
                            component={TextInput}
                        />

                        <FormGroup>
                            <div className="float-right">
                                <Link
                                    to="/forgot-password"
                                    className="text-muted"
                                >
                                    {t('forgot.your.password')}
                                </Link>
                            </div>

                            <Field
                                name="password"
                                label={t('field.label.password')}
                                placeholder={t('field.placeholder.password')}
                                id={'password'}
                                type="password"
                                component={TextInput}
                                translateParams={{ count: 8 }}
                            />
                        </FormGroup>

                        <div className="mt-3">
                            <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                                disabled={props.loading}
                            >
                                <ButtonLoadingIcon loading={props.loading} />
                                {t('button.login')}
                            </button>
                        </div>
                    </form>

                    <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">{t('sign_in_with')}</h5>

                        <SocialLogin onSuccess={onSocialSuccess} />
                    </div>

                    <div className="mt-5 text-center">
                        <p>
                            {t('dont.have.account')}{" "}
                            <Link
                                to="register"
                                className="font-weight-medium text-primary"
                            >
                                {" "}
                                {t('signup.now')}{" "}
                            </Link>{" "}
                        </p>
                    </div>
                </div>
            </div>

            <div className="mt-4 mt-md-5 text-center">
                <p className="mb-0">
                    © {new Date().getFullYear()} Brikk.
                </p>
            </div>
        </div>
    );
};

const mapStatetoProps = state => {
    const {
        serverError, loading, success,
        socialLoading, socialSuccess, socialError,
    } = state.Login;
    return {
        serverError, loading, success,
        socialLoading, socialSuccess, socialError
    };
};

const Login = reduxForm({
    form: FORM_SIGN_IN,
    validate: loginValidation,
})(LoginComponent);

export default withRouter(connect(mapStatetoProps, { loginUser, apiError, loginClean, sendVerifyEmail, apiSuccess, socialLoginUser })(Login));

