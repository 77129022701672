import { combineReducers } from "redux";

import consultantReducer from './consultant/reducer';
import clientReducer from './client/reducer';

const statisticsReducer = combineReducers({
    consultant: consultantReducer,
    client: clientReducer,
});

export default statisticsReducer;
