import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../i18n';

import { LEAD_LIST_UPDATE_LEAD_STATUS_DECLINE, LEAD_LIST_UPDATE_LEAD_STATUS_BUY } from './actionTypes';
import {
    leadListUpdateLeadStatusSuccess,
    leadListUpdateLeadStatusError,
} from './actions';

import { fetchCompanyActualUnpaidLeads, fetchCompanyPreviousUnpaidLeads, fetchCompanyNotInterestedLeads } from '../companyLeads/actions';
import {
    COMPANY_LEADS_TAB_ALL_LEADS,
    COMPANY_LEADS_TAB_NOT_INTERESTED,
    LEAD_STATUS_UNPAID,
    LEAD_STATUS_DECLINED,
} from '../../../consts/lead';

import { API } from '../../../api';

export const companyLeadsConfigState = (state) => state.lead.companyLeads.config;
export const actualUnpaidState = (state) => state.lead.companyLeads.actualUnpaid;
export const previousUnpaidState = (state) => state.lead.companyLeads.previousUnpaid;
export const notInterestedState = (state) => state.lead.companyLeads.notInterested;


function* fetchActualUnpaidLeads() {
    const actualUnpaid = yield select(actualUnpaidState);
    const params = {
        page: actualUnpaid.page,
    };
    yield put(fetchCompanyActualUnpaidLeads(params));
}

function* fetchPreviousUnpaidLeads() {
    const previousUnpaid = yield select(previousUnpaidState);
    const params = {
        page: previousUnpaid.page,
    };
    yield put(fetchCompanyPreviousUnpaidLeads(params));
}

function* fetchNotInterestedLeads() {
    const notInterested = yield select(notInterestedState);
    const params = {
        page: notInterested.page,
    };
    yield put(fetchCompanyNotInterestedLeads(params));
}

function* updateStatusDecline({ payload: { id, lead } }) {
    try {
        const response = yield call(API.lead.declineLead, id);

        const { data } = response;

        yield put(leadListUpdateLeadStatusSuccess(id));
        toastr.success(i18n.t(`lead.status.updated.decline`));

        const { status, is_actual } = lead;

        // update table
        const companyLeadsConfig = yield select(companyLeadsConfigState);
        if (companyLeadsConfig.activeTab === COMPANY_LEADS_TAB_ALL_LEADS) {
            if (is_actual) {
                yield* fetchActualUnpaidLeads();
            } else {
                yield* fetchPreviousUnpaidLeads();
            }
        }

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(leadListUpdateLeadStatusError(id));

        toastr.error(message);
    }
}

function* updateStatusBuy({ payload: { id, lead } }) {
    try {
        const response = yield call(API.lead.buyLead, id);

        const { data } = response;
        yield put(leadListUpdateLeadStatusSuccess(id));
        toastr.success(i18n.t(`lead.status.updated.buy`));

        const { status, is_actual } = lead;

        // update table
        const companyLeadsConfig = yield select(companyLeadsConfigState);
        if (status === LEAD_STATUS_UNPAID && companyLeadsConfig.activeTab === COMPANY_LEADS_TAB_ALL_LEADS) {
            if (is_actual) {
                yield* fetchActualUnpaidLeads();
            } else {
                yield* fetchPreviousUnpaidLeads();
            }
        } else if (status === LEAD_STATUS_DECLINED && companyLeadsConfig.activeTab === COMPANY_LEADS_TAB_NOT_INTERESTED) {
            yield* fetchNotInterestedLeads();
        }

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(leadListUpdateLeadStatusError(id));

        toastr.error(message);
    }
}

export function* watchUpdateStatusDecline() {
    yield takeEvery(LEAD_LIST_UPDATE_LEAD_STATUS_DECLINE, updateStatusDecline)
}

export function* watchUpdateStatusBuy() {
    yield takeEvery(LEAD_LIST_UPDATE_LEAD_STATUS_BUY, updateStatusBuy)
}

function* statusSaga() {
    yield all([
        fork(watchUpdateStatusDecline),
        fork(watchUpdateStatusBuy),
    ]);
}

export default statusSaga;