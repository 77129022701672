import {
    ACTIVATE_ACCOUNT,
    ACTIVATE_ACCOUNT_SUCCESS,
    ACTIVATE_ACCOUNT_ERROR,
    ACTIVATE_ACCOUNT_CLEAN,
} from './actionTypes';

const initialState = {
    loading: false,
    success: null,
    error: null,
};

const activation = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVATE_ACCOUNT:
            state = {
                ...state,
                loading: true,
                error: null,
                success: null,
            };
            break;

        case ACTIVATE_ACCOUNT_SUCCESS:
            state = {
                ...state,
                loading: false,
                success: action.payload,
            };
            break;

        case ACTIVATE_ACCOUNT_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case ACTIVATE_ACCOUNT_CLEAN:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default activation;