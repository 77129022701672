import React from 'react';
import classNames from "classnames";

import s from "./PropertyLockIcon.module.scss"

export const PropertyLockIcon = ({ className = "", size = "lg" }) => {
  return (
    <i className={classNames("bx bx-lock align-middle text-primary", {
      [className]: className,
      [`${s.size__lg}`]: size === "lg",s
    })}/>
  );
};