import {
    LEAD_LIST_UPDATE_LEAD_STATUS_DECLINE,
    LEAD_LIST_UPDATE_LEAD_STATUS_BUY,

    LEAD_LIST_UPDATE_LEAD_STATUS_SUCCESS,
    LEAD_LIST_UPDATE_LEAD_STATUS_ERROR,
    CLEAN_LEAD_LIST_UPDATE_LEAD_STATUS,
} from './actionTypes';

const initialState = {
    pending: [],
};

const leadsStatuses = (state = initialState, action) => {
    switch (action.type) {
        case LEAD_LIST_UPDATE_LEAD_STATUS_DECLINE:
            state = {
                ...state,
                pending: [...state.pending, action.payload.id],
            };
            break;

        case LEAD_LIST_UPDATE_LEAD_STATUS_BUY:
            state = {
                ...state,
                pending: [...state.pending, action.payload.id],
            };
            break;

        case LEAD_LIST_UPDATE_LEAD_STATUS_SUCCESS:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.id),
            };
            break;

        case LEAD_LIST_UPDATE_LEAD_STATUS_ERROR:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.id),
            };
            break;

        case CLEAN_LEAD_LIST_UPDATE_LEAD_STATUS:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default leadsStatuses;