import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Row, Card, CardHeader, CardBody, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { ReceivedPropertyListTable, InvitesFilter } from '../../../components';
import {
    fetchReceivedActivePropertyList,
    cleanReceivedActivePropertyList,
    receivedActivePropertyListSetSort,
    receivedActivePropertyListSetFilter,
    receivedActivePropertyListResetFilter,
    receivedActivePropertyListSetPage,
} from '../../../../../store/actions';
import { getActiveStatusOptions } from '../../../../../helpers';
import { Pagination, SearchInput } from '../../../../../components/Custom';

import * as HOC from '../../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const ActiveInvitesComponent = (props) => {
    const [search, setSearch] = useState(null);

    useEffect(() => {
        let params = {
            page: props.page,
        };

        if (props.sort.length > 0) {
            params.sort = props.sort;
        }

        if (props.filter.isActive) {
            params.filter = props.filter.values;
        }

        if (search) {
            params.filter = {
                ...params.filter,
                keywords: search,
                search_fields: ['sender_name', 'address', 'unit'],
            };
        }

        props.fetchReceivedActivePropertyList(params);
    }, [props.sort, props.filter, props.page, search]);

    useEffect(() => {
        return () => {
            props.cleanReceivedActivePropertyList();
            props.receivedActivePropertyListResetFilter();
        }
    }, []);

    const filterWarningVisible = useMemo(() => {
        return (props.filter.isActive || search) && props.list.length === 0 && !props.loading;
    }, [props.filter, props.list, props.loading, search]);

    const handleSort = useCallback(sortBy => {
        if (!_.isEqual(sortBy, props.sort)) {
            props.receivedActivePropertyListSetSort(sortBy);
        }
    }, [props.sort]);

    const handleResetFilter = () => {
        props.receivedActivePropertyListResetFilter();
    };

    const handleApplyFilter = (values) => {
        props.receivedActivePropertyListSetFilter(values);
    };

    const handlePageChange = (item) => {
        props.receivedActivePropertyListSetPage(item.selected + 1);
    };

    const handleSearchChange = (value) => {
        props.receivedActivePropertyListSetPage(1);
        setSearch(value);
    };

    return (
        <Card>
            <CardHeader className="bg-transparent">
                <Row className="no-gutters justify-content-between">
                    <h5 className={'mt-2 mr-2'}>{props.t('invites.active.your.list')}</h5>

                    <div className="button-items">
                        <InvitesFilter
                            filter={props.filter}
                            onReset={handleResetFilter}
                            onApply={handleApplyFilter}
                            statusOptions={getActiveStatusOptions(props.t)}
                        />
                    </div>
                </Row>
            </CardHeader>
            <CardBody>
                <Row className={'no-gutters justify-content-between'}>
                    <SearchInput
                        onChange={handleSearchChange}
                        searchFields={
                            ['search.field.consultants_name', 'search.field.address', 'search.field.unit']
                        }
                        hintExample={'Koningsstraat'}
                    />
                </Row>

                <TableDataLoading isLoading={props.loading} isFailed={props.error} error={props.t(props.error)}>
                    {filterWarningVisible ? (
                        <Alert color="primary">{props.t('invites.filter.empty')}</Alert>
                    ) : (
                        <>
                            <ReceivedPropertyListTable
                                items={props.list}
                                onSort={handleSort}
                                isLoading={props.loading}
                                initialSort={props.sort}
                            />

                            <Pagination data={props.meta} onPageChange={handlePageChange} />
                        </>
                    )}
                </TableDataLoading>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => {
    const {
        receivedActive, receivedActiveLoading, receivedActiveError,
        receivedActiveSort,
        receivedActiveFilter,
        receivedActivePage,
        receivedActiveMeta,
    } = state.property.invites;
    return {
        list: receivedActive,
        loading: receivedActiveLoading,
        error: receivedActiveError,
        sort: receivedActiveSort,
        filter: receivedActiveFilter,
        meta: receivedActiveMeta,
        page: receivedActivePage,
    };
};

const mapDispatchToProps = {
    fetchReceivedActivePropertyList,
    cleanReceivedActivePropertyList,
    receivedActivePropertyListSetSort,
    receivedActivePropertyListSetFilter,
    receivedActivePropertyListResetFilter,
    receivedActivePropertyListSetPage,
};

export const ActiveInvites = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActiveInvitesComponent)));