import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// Redux Store
import { changeSidebarType, showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
import { LogoIcon, HeaderCreditsAmount } from "components/Custom";
import { ROLE_MANAGER } from "consts/roles";
import { middlewareHelper } from 'helpers';

const previewMode = middlewareHelper.previewMode.isAllow()

const Header = (props) => {
    const isMobile =  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    function tToggle() {
        props.toggleLeftmenu(!props.leftMenu);
        if (props.leftSideBarType === "default") {
            props.changeSidebarType("condensed", isMobile);
        } else if (props.leftSideBarType === "condensed") {
            props.changeSidebarType("default", isMobile);
        }
    }

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to={`/dashboard`} className="logo logo-light">
                                <span className="logo-sm">
                                    <LogoIcon type={"short"} />
                                </span>
                                <span className="logo-lg">
                                    <LogoIcon />
                                </span>
                            </Link>
                        </div>

                        <button type="button" onClick={() => {   tToggle() }} className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                            <i className="fa fa-fw fa-bars"></i>
                        </button>

                    </div>

                    <div className="d-flex">
                        {previewMode && (
                          <>
                              {props.user && props.user.role === ROLE_MANAGER && (
                                <HeaderCreditsAmount />
                              )}
                          </>
                        )}


                        {/*<NotificationDropdown />*/}
                        <ProfileMenu />

                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { layoutType,showRightSidebar,leftMenu,leftSideBarType } = state.Layout;
    return {
        layoutType,showRightSidebar,leftMenu,leftSideBarType,
        user: state.user.data,
    };
};

export default connect(mapStatetoProps, { showRightSidebarAction,toggleLeftmenu,changeSidebarType })(withTranslation()(Header));
