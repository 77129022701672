import React from 'react';
import classNames from "classnames";

import './SwiperNavigationButton.scss';

export const SwiperNavigationButton = ({
    direction = 'prev',
    extraClass = '',
    size = '',
}) => {
    return (
        <a href={'#'} onClick={e => e.preventDefault()} className={classNames({
            'carousel-navigation__button': true,
            'carousel-navigation__button_direction_next': direction === 'next',
            'carousel-navigation__button_direction_prev': direction === 'prev',
            'carousel-navigation__button_size_s': size === 's',
            [extraClass]: extraClass,
        })}>

            <i
                className={classNames({
                    'bx bx-arrow-back align-middle text-primary': true,
                    'font-size-24': size === '',
                    'font-size-18': size === 's',
                })}
            />

        </a>
    );
};