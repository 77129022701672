import {takeEvery, fork, put, all, call} from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from 'i18n';

import {CRM_SYSTEM_ACCOUNT_REMOVE} from './actionTypes';
import {
  crmSystemAccountRemoveSuccess,
  crmSystemAccountRemoveError,
} from './actions';

import {crmSystemListUpdateItem} from '../../list/actions';

import {API} from 'api';

function* remove({payload: {id, crm}}) {
  try {
    const response = yield call(API.crmSystem.deleteAccount, id);

    const {data} = response;

    yield put(crmSystemAccountRemoveSuccess(crm));

    yield put(crmSystemListUpdateItem(id, {my_crm_system_account: null}));

    toastr.success(i18n.t(`account.remove.success`));
  } catch (error) {
    const message = error.response?.data?.message || 'Server Error';

    yield put(crmSystemAccountRemoveError(crm));

    toastr.error(message);
  }
}

export function* watchRemove() {
  yield takeEvery(CRM_SYSTEM_ACCOUNT_REMOVE, remove)
}

function* removeSaga() {
  yield all([
    fork(watchRemove),
  ]);
}

export default removeSaga;