import {
    LEAD_PROPERTY_LIST_SUGGEST_PROPERTY,
    LEAD_PROPERTY_LIST_SUGGEST_PROPERTY_SUCCESS,
    LEAD_PROPERTY_LIST_SUGGEST_PROPERTY_ERROR,
    LEAD_CLEAN_PROPERTY_LIST_SUGGEST_PROPERTY,
} from './actionTypes';

// SUGGEST PROPERTY
export const leadPropertiesSuggestProperty = (lead, property) => {
    return {
        type: LEAD_PROPERTY_LIST_SUGGEST_PROPERTY,
        payload: { lead, property },
    }
};

export const leadPropertiesSuggestPropertySuccess = (lead, property) => {
    return {
        type: LEAD_PROPERTY_LIST_SUGGEST_PROPERTY_SUCCESS,
        payload: { lead, property },
    }
};

export const leadPropertiesSuggestPropertyError = (lead, property) => {
    return {
        type: LEAD_PROPERTY_LIST_SUGGEST_PROPERTY_ERROR,
        payload: { lead, property },
    }
};

export const leadCleanPropertiesSuggestProperty = () => {
    return { type: LEAD_CLEAN_PROPERTY_LIST_SUGGEST_PROPERTY };
};