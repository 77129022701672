import {
    FETCH_CLIENT_OWNED_PROPERTIES,
    FETCH_CLIENT_OWNED_PROPERTIES_SUCCESS,
    FETCH_CLIENT_OWNED_PROPERTIES_ERROR,
    CLEAN_CLIENT_OWNED_PROPERTIES,

    CLIENT_OWNED_PROPERTIES_SET_FILTER,
    CLIENT_OWNED_PROPERTIES_RESET_FILTER,

    CLIENT_OWNED_PROPERTIES_SET_SORT,
    CLIENT_OWNED_PROPERTIES_RESET_SORT,

    CLIENT_OWNED_PROPERTIES_SET_PAGE,

    CLIENT_OWNED_PROPERTIES_UPDATE_ITEM, CLIENT_OWNED_PROPERTIES_SET_SEARCH,
} from './actionTypes';

export const fetchClientOwnedProperties = (params) => {
    return {
        type: FETCH_CLIENT_OWNED_PROPERTIES,
        payload: { params }
    }
};

export const fetchClientOwnedPropertiesSuccess = (data) => {
    return {
        type: FETCH_CLIENT_OWNED_PROPERTIES_SUCCESS,
        payload: data,
    }
};

export const fetchClientOwnedPropertiesError = (data) => {
    return {
        type: FETCH_CLIENT_OWNED_PROPERTIES_ERROR,
        payload: data,
    }
};

export const cleanClientOwnedProperties = () => {
    return { type: CLEAN_CLIENT_OWNED_PROPERTIES };
};


// FILTER
export const clientOwnedPropertiesSetFilter = (data) => {
    return {
        type: CLIENT_OWNED_PROPERTIES_SET_FILTER,
        payload: data,
    }
};

export const clientOwnedPropertiesResetFilter = () => {
    return { type: CLIENT_OWNED_PROPERTIES_RESET_FILTER };
};

// SORTING
export const clientOwnedPropertiesSetSort = (data) => {
    return {
        type: CLIENT_OWNED_PROPERTIES_SET_SORT,
        payload: data,
    }
};

export const clientOwnedPropertiesResetSort = () => {
    return { type: CLIENT_OWNED_PROPERTIES_RESET_SORT };
};


// PAGE
export const clientOwnedPropertiesSetPage = (data) => {
    return {
        type: CLIENT_OWNED_PROPERTIES_SET_PAGE,
        payload: data,
    }
};

// UPDATE ITEM IN LIST
export const clientOwnedPropertiesUpdateItem = (id, data) => {
    return {
        type: CLIENT_OWNED_PROPERTIES_UPDATE_ITEM,
        payload: { id, data },
    }
};

export const clientOwnedPropertiesSetSearch = (data) => {
    return {
        type: CLIENT_OWNED_PROPERTIES_SET_SEARCH,
        payload: data,
    }
};