import React from 'react';
import { withRouter, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { ConsultantsList, ConsultantCreate, ConsultantEdit, Consultant } from './'

const ConsultantIndexComponent = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <ConsultantsList />
            </Route>
            <Route path={`${path}/create`}>
                <ConsultantCreate />
            </Route>
            <Route path={`${path}/:id/edit`}>
                <ConsultantEdit />
            </Route>
            <Route path={`${path}/:id`}>
                <Consultant />
            </Route>
            <Route>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
};

export const ConsultantIndex = withRouter(ConsultantIndexComponent);