import React, { useEffect, useRef, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { Col, Row } from "reactstrap";

import { echoChannelAddWhisperListener, echoLeaveChannel } from '../../../../../../store/middlewares/echo/actions';
import { onChatTypingEvent } from '../../../../../../store/actions';
import { CHAT_TYPING_EVENT } from "../../../../../../consts/echo";
import { ROLE_CLIENT } from "../../../../../../consts/roles";

const HeaderComponent = (props) => {
    const [isTyping, setIsTyping] = useState(false);

    const timeoutRef = useRef(null);

    useEffect(() => {
        return () => {
            if (props.participant) {
                if (timeoutRef.current) {
                    setIsTyping(false);

                    clearTimeout(timeoutRef.current);
                    timeoutRef.current = null;
                }
            }
        }
    }, [props.participant]);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    const showTyping = () => {
        resetTimeout();

        setIsTyping(true);

        timeoutRef.current = setTimeout(() => {
            setIsTyping(false);
        }, 1500);
    };

    const handleChatTypingEvent = (e) => {
        const { userId } = e;
        if (props.participant?.id === userId) {
            showTyping();
        }

        props.onChatTypingEvent(e);
    };

    useEffect(() => {
        if (props.roomChannel) {
            // set socket whisper listener
            props.echoChannelAddWhisperListener(props.roomChannel, CHAT_TYPING_EVENT, handleChatTypingEvent);
        }

        return () => {
            if (props.roomChannel) {
                // leave chat (whisper) channel
                props.echoLeaveChannel(props.roomChannel);
            }
        };
    }, [props.roomChannel]);

    const participantRoute = useMemo(() => {
        const id = props.participant?.id;
        return props.user.role === ROLE_CLIENT ? `/contacts/${id}` : `/clients/${id}`;
    }, [props.user, props.participant]);

    return (
        <div className="p-4 border-bottom ">
            <Row>
                <Col md="4" xs="9">
                    <div className={'d-flex align-items-left'}>
                        <Link to={participantRoute}>
                            <h5 className="font-size-15 mb-1">
                                {props.participant?.name}
                            </h5>
                        </Link>
                    </div>

                    <div className={'chat-room-header-user-status text-muted'}>
                        {isTyping ? (
                            <span className={'text-primary'}>{`${props.t('typing')}...`}</span>
                        ) : (
                            <>
                                <i
                                    className={
                                        props.participant?.is_online
                                            ? "mdi mdi-circle text-success align-middle mr-1"
                                            : "mdi mdi-circle align-middle mr-1"
                                    }
                                />
                                {props.t(props.participant?.is_online ? 'online' : 'offline')}
                            </>
                        )}
                    </div>

                </Col>
                <Col md="8" xs="3">
                    {/*<ul className="list-inline user-chat-nav text-right mb-0">*/}
                        {/*<li className="list-inline-item d-none d-sm-inline-block">*/}
                        {/*</li>*/}
                    {/*</ul>*/}
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { participant } = state.chat.conversation.data;
    const { data } = state.user;

    return {
        participant,
        user: data,
    };
};

const mapDispatchToProps = {
    echoChannelAddWhisperListener,
    echoLeaveChannel,
    onChatTypingEvent,
};

export const Header = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HeaderComponent)));