import {
    FETCH_USERS_FROM_RECEIVED_INVITES,
    FETCH_USERS_FROM_RECEIVED_INVITES_SUCCESS,
    FETCH_USERS_FROM_RECEIVED_INVITES_ERROR,
    USERS_FROM_RECEIVED_INVITES_SET_SORT,
    USERS_FROM_RECEIVED_INVITES_SET_FILTER,
    USERS_FROM_RECEIVED_INVITES_RESET_FILTER,
    CLEAN_USERS_FROM_RECEIVED_INVITES,
    USERS_FROM_RECEIVED_INVITES_SET_PAGE,

    FETCH_USERS_FROM_SENT_INVITES,
    FETCH_USERS_FROM_SENT_INVITES_SUCCESS,
    FETCH_USERS_FROM_SENT_INVITES_ERROR,
    USERS_FROM_SENT_INVITES_SET_SORT,
    USERS_FROM_SENT_INVITES_SET_FILTER,
    USERS_FROM_SENT_INVITES_RESET_FILTER,
    CLEAN_USERS_FROM_SENT_INVITES,
    USERS_FROM_SENT_INVITES_SET_PAGE,

    FETCH_USERS_FROM_COMPANY_SENT_INVITES,
    FETCH_USERS_FROM_COMPANY_SENT_INVITES_SUCCESS,
    FETCH_USERS_FROM_COMPANY_SENT_INVITES_ERROR,
    USERS_FROM_COMPANY_SENT_INVITES_SET_SORT,
    USERS_FROM_COMPANY_SENT_INVITES_SET_FILTER,
    USERS_FROM_COMPANY_SENT_INVITES_RESET_FILTER,
    USERS_FROM_COMPANY_SENT_INVITES_SET_PAGE,
    CLEAN_USERS_FROM_COMPANY_SENT_INVITES,
} from './actionTypes';

// FETCH USERS FROM RECEIVED INVITES
export const fetchUsersFromReceivedInvites = (params) => {
    return {
        type: FETCH_USERS_FROM_RECEIVED_INVITES,
        payload: { params },
    }
};

export const fetchUsersFromReceivedInvitesSuccess = (data) => {
    return {
        type: FETCH_USERS_FROM_RECEIVED_INVITES_SUCCESS,
        payload: data,
    }
};

export const fetchUsersFromReceivedInvitesError = (data) => {
    return {
        type: FETCH_USERS_FROM_RECEIVED_INVITES_ERROR,
        payload: data,
    }
};

export const usersFromReceivedInvitesSetSort = (data) => {
    return {
        type: USERS_FROM_RECEIVED_INVITES_SET_SORT,
        payload: data,
    }
};

export const usersFromReceivedInvitesSetFilter = (data) => {
    return {
        type: USERS_FROM_RECEIVED_INVITES_SET_FILTER,
        payload: data,
    }
};

export const usersFromReceivedInvitesResetFilter = (data) => {
    return {
        type: USERS_FROM_RECEIVED_INVITES_RESET_FILTER,
        payload: data,
    }
};

// SET PAGE
export const usersFromReceivedInvitesSetPage = (data) => {
    return {
        type: USERS_FROM_RECEIVED_INVITES_SET_PAGE,
        payload: data,
    }
};

export const cleanUsersFromReceivedInvites = () => {
    return { type: CLEAN_USERS_FROM_RECEIVED_INVITES };
};

// FETCH USERS FROM SENT INVITES
export const fetchUsersFromSentInvites = (params) => {
    return {
        type: FETCH_USERS_FROM_SENT_INVITES,
        payload: { params },
    }
};

export const fetchUsersFromSentInvitesSuccess = (data) => {
    return {
        type: FETCH_USERS_FROM_SENT_INVITES_SUCCESS,
        payload: data,
    }
};

export const fetchUsersFromSentInvitesError = (data) => {
    return {
        type: FETCH_USERS_FROM_SENT_INVITES_ERROR,
        payload: data,
    }
};

export const usersFromSentInvitesSetSort = (data) => {
    return {
        type: USERS_FROM_SENT_INVITES_SET_SORT,
        payload: data,
    }
};

// FILTER
export const usersFromSentInvitesSetFilter = (data) => {
    return {
        type: USERS_FROM_SENT_INVITES_SET_FILTER,
        payload: data,
    }
};

export const usersFromSentInvitesResetFilter = (data) => {
    return {
        type: USERS_FROM_SENT_INVITES_RESET_FILTER,
        payload: data,
    }
};

// SET PAGE
export const usersFromSentInvitesSetPage = (data) => {
    return {
        type: USERS_FROM_SENT_INVITES_SET_PAGE,
        payload: data,
    }
};

export const cleanUsersFromSentInvites = () => {
    return { type: CLEAN_USERS_FROM_SENT_INVITES };
};


// FETCH USERS FROM COMPANY SENT INVITES
export const fetchUsersFromCompanySentInvites = (params) => {
    return {
        type: FETCH_USERS_FROM_COMPANY_SENT_INVITES,
        payload: { params },
    }
};

export const fetchUsersFromCompanySentInvitesSuccess = (data) => {
    return {
        type: FETCH_USERS_FROM_COMPANY_SENT_INVITES_SUCCESS,
        payload: data,
    }
};

export const fetchUsersFromCompanySentInvitesError = (data) => {
    return {
        type: FETCH_USERS_FROM_COMPANY_SENT_INVITES_ERROR,
        payload: data,
    }
};

export const usersFromCompanySentInvitesSetSort = (data) => {
    return {
        type: USERS_FROM_COMPANY_SENT_INVITES_SET_SORT,
        payload: data,
    }
};

// FILTER
export const usersFromCompanySentInvitesSetFilter = (data) => {
    return {
        type: USERS_FROM_COMPANY_SENT_INVITES_SET_FILTER,
        payload: data,
    }
};

export const usersFromCompanySentInvitesResetFilter = (data) => {
    return {
        type: USERS_FROM_COMPANY_SENT_INVITES_RESET_FILTER,
        payload: data,
    }
};

// SET PAGE
export const usersFromCompanySentInvitesSetPage = (data) => {
    return {
        type: USERS_FROM_COMPANY_SENT_INVITES_SET_PAGE,
        payload: data,
    }
};

export const cleanUsersFromCompanySentInvites = () => {
    return { type: CLEAN_USERS_FROM_COMPANY_SENT_INVITES };
};