import React from 'react';
import { withRouter, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';

import { ChatPage } from './'
import { ROLE_CLIENT, ROLE_CONSULTANT } from '../../consts/roles';
import { useAllowed } from '../../hooks';
import AllowedRoute from '../../routes/middleware/AllowedRoute';

const ChatIndexComponent = (props) => {
    let { path } = useRouteMatch();
    let isAllowed = useAllowed([ROLE_CLIENT, ROLE_CONSULTANT], props.user.role);

    return (
        <Switch>

            <AllowedRoute exact path={path} component={ChatPage} allowed={isAllowed} />

            <Route>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

export const ChatIndex = withRouter(connect(mapStateToProps, {})(ChatIndexComponent));