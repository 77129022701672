import axios from 'axios';
import qs from 'qs';

const paramsSerializer = (params) => {
    return qs.stringify(params, { encode: false });
};

class ChatAPI {
    getContacts = async (params) => {
        return axios.get('api/contacts', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    sendMessage = async (id, params) => {
        return axios.post(`api/chats/users/${id}/messages`, params);
    };

    getChats = async () => {
        return axios.get('api/chats');
    };

    getMessages = async (id, params) => {
        return axios.get(`api/chats/users/${id}/messages`, {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    readChat = async (id) => {
        return axios.post(`api/chats/users/${id}/messages/seen`);
    };

    sendAttachment = async (data, config) => {
        let formData = new FormData();
        formData.append('attachment', data);

        return axios.post('api/chats/attachments', formData, config);
    };

    deleteMessage = async (id) => {
        return axios.delete(`api/messages/${id}`);
    };
}

export const chat = new ChatAPI();
