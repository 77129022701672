import { PROPERTY_PRICE_MAX, PROPERTY_PRICE_MIN } from "./property";

export const SUGGEST_STATUS_UNCONFIRMED = 'unconfirmed';
export const SUGGEST_STATUS_ACTIVE = 'active';
export const SUGGEST_STATUS_DECLINED = 'declined';
export const SUGGEST_STATUS_CLOSED = 'closed';
export const SUGGEST_STATUS_FROZEN = 'frozen';
export const SUGGEST_STATUS_DEACTIVATED = 'deactivated';

export const SUGGEST_FILTER_INITIAL_VALUES = {
    companies: null,
    cities: null,
    price: {
        range: [PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX],
        empty_price: false,
    },
    leads_links_statuses: null,
};