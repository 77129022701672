import {
    SET_CLIENT_PROPERTIES_ACTIVE_TAB,
} from './actionTypes';

import {
    CLIENT_PROPERTIES_ALL_TAB
} from '../../../../consts/property/clientProperties';

const initialState = {
    activeTab: CLIENT_PROPERTIES_ALL_TAB,
};

const config = (state = initialState, action) => {
    switch (action.type) {

        case SET_CLIENT_PROPERTIES_ACTIVE_TAB:
            state = {
                ...state,
                activeTab: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default config;