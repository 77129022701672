import { combineReducers } from "redux";

import viewReducer from './view/reducer';
import editReducer from './edit/reducer';

const billingInfoReducer = combineReducers({
    view: viewReducer,
    edit: editReducer,
});

export default billingInfoReducer;