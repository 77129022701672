import {
    FETCH_LEAD_PREFERENCES_DATA,
    FETCH_LEAD_PREFERENCES_DATA_SUCCESS,
    FETCH_LEAD_PREFERENCES_DATA_ERROR,
    CLEAN_LEAD_PREFERENCES_DATA,
} from './actionTypes';

const initialState = {
    data: {},
    loading: false,
    fetchError: null,
};

const view = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_LEAD_PREFERENCES_DATA:
            state = {
                ...state,
                loading: true,
                fetchError: null,
            };
            break;

        case FETCH_LEAD_PREFERENCES_DATA_SUCCESS:
            state = {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
            break;

        case FETCH_LEAD_PREFERENCES_DATA_ERROR:
            state = {
                ...state,
                loading: false,
                fetchError: action.payload,
            };
            break;

        case CLEAN_LEAD_PREFERENCES_DATA:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default view;