import {
    FETCH_PAYMENT_CARDS,
    FETCH_PAYMENT_CARDS_SUCCESS,
    FETCH_PAYMENT_CARDS_ERROR,
    CLEAN_PAYMENT_CARDS,
    PAYMENT_CARDS_SET_DEFAULT,
} from './actionTypes';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
};

const cards = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_PAYMENT_CARDS:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case FETCH_PAYMENT_CARDS_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                list: action.payload.data,
            };
            break;

        case FETCH_PAYMENT_CARDS_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case CLEAN_PAYMENT_CARDS:
            state = {
                ...state,
                ...initialState,
            };
            break;


        // mark default card
        case PAYMENT_CARDS_SET_DEFAULT:
            state = {
                ...state,
                listLoading: false,
                list: state.list.map((item) => ({
                    ...item,
                    default: item.id === action.payload.id,
                })),
            };
            break;


        default:
            state = { ...state };
            break;
    }
    return state;
};

export default cards;