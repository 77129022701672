import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Alert, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form'
import { withRouter, useParams } from "react-router-dom";

import { useTitle } from "../../hooks";
import { FORM_EDIT_CONSULTANT } from "../../consts/forms";
import TextInput from "../../components/Common/TextInput";
import SwitchField from "../../components/Common/SwitchField";
import ButtonLoadingIcon from '../../components/Common/ButtonLoadingIcon';
import { BackButton, PageTitle } from '../../components/Custom';
import * as normalize from '../../utils/normalize';
import * as format from '../../utils/format';
import {
    number as numberValidation,
    required as requiredValidator,
    phone as phoneValidator,
} from "../../utils/validations";
import { fetchConsultant, cleanEditConsultantPage, editConsultant, } from '../../store/actions';

import * as HOC from '../../HOC';
const DataShower = HOC.withDataShower();

const ConsultantEditComponent = (props) => {
    useTitle(props.t('title.consultants.edit'));

    let { id } = useParams();

    useEffect(() => {
        props.fetchConsultant(id);

        return () => {
            props.cleanEditConsultantPage();
        };
    }, []);

    const onSubmit = (values) => {
        let params = {
            ...values,
        };

        if (values.phone) {
            params.phone = values.phone.replace(/[^0-9]/g, '');
        }

        props.editConsultant(id, params);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('title.consultants.edit')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <BackButton />
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <DataShower isLoading={props.consultantLoading} isFailed={props.consultantError} error={props.t(props.consultantError)}>

                                        {props.editSuccess && props.editSuccess ? <Alert color="success">{props.t(props.editSuccess)}</Alert> : null}
                                        {props.editError && props.editError.message ? <Alert color="danger">{props.editError.message}</Alert> : null}

                                        <form
                                            onSubmit={props.handleSubmit(onSubmit)}
                                            className="form-horizontal"
                                            noValidate={true}
                                        >
                                            <Field
                                                name="email"
                                                label={props.t('field.label.email')}
                                                placeholder={props.t('field.placeholder.email')}
                                                id={'email'}
                                                type="email"
                                                component={TextInput}
                                                disabled
                                                row
                                            />

                                            <Field
                                                name="name"
                                                label={props.t('field.label.fullname')}
                                                placeholder={props.t('field.placeholder.fullname')}
                                                id={'name'}
                                                type="text"
                                                component={TextInput}
                                                validate={[ requiredValidator ]}
                                                row
                                            />

                                            <Field
                                                name="phone"
                                                label={props.t('field.label.phone')}
                                                placeholder={props.t('field.placeholder.phone')}
                                                id={'phone'}
                                                type="text"
                                                component={TextInput}
                                                normalize={normalize.phone}
                                                row
                                            />

                                            <Field
                                              name="can_manage_property"
                                              label={props.t('manage_property')}
                                              placeholder={props.t('allowed')}
                                              id={'can_manage_property'}
                                              type="checkbox"
                                              component={SwitchField}
                                            />

                                            <Row className="justify-content-end">
                                                <Col lg="10">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        disabled={props.editLoading}
                                                    >
                                                        <ButtonLoadingIcon loading={props.editLoading} />
                                                        {props.t('save')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </DataShower>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const {
        consultant,
        consultantLoading,
        consultantError,
        editLoading,
        editSuccess,
        editError,
    } = state.consultant;
    return {
        consultant,
        consultantLoading,
        consultantError,
        editLoading,
        editSuccess,
        editError,
        initialValues: {
            email: consultant?.email || '',
            name: consultant?.name || '',
            phone: format.phone(consultant?.phone),
            can_manage_property: consultant?.can_manage_property || false,
        },
    };
};

const mapDispatchToProps = {
    fetchConsultant,
    cleanEditConsultantPage,
    editConsultant,
};

const ConsultantEditForm = reduxForm({
    form: FORM_EDIT_CONSULTANT,
    enableReinitialize: true,
})(ConsultantEditComponent);

export const ConsultantEdit = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConsultantEditForm)));