import React, { useMemo } from 'react';
import { Row, Col, Button, FormGroup, Label } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from "react-redux";
import NumberFormat from 'react-number-format';

import { OFFER_UPDATE_FORM } from "../../../../../consts/forms";
import { OfferPriceDiff } from '../../../../../components/Custom';
import {
    maxValue as maxValueValidator,
    minValue as minValueValidator,
    number as numberValidator,
} from "../../../../../utils/validations";

import { PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX } from '../../../../../consts/property';
import TextInput from "../../../../../components/Common/TextInput";
import ButtonLoadingIcon from '../../../../../components/Common/ButtonLoadingIcon';
import { formatPrice, normalizePrice } from '../../../../../utils/normalize';

const priceMinValue = minValueValidator(PROPERTY_PRICE_MIN);
const priceMaxValue = maxValueValidator(PROPERTY_PRICE_MAX);

const priceSameValidator = propertyPrice => value => {
    if (propertyPrice !== null && value) {
        return value && Number(value) === Number(propertyPrice) ? 'field.error.offer_price.equal_property_price' : undefined;
    }
    return undefined;
};

const currentValueDifferentValidator = offerPriceValue => value => {
    if (offerPriceValue !== null && value !== '') {
        return Number(value) === Number(offerPriceValue) ? 'field.error.offer_price.equal_current' : undefined;
    }
    return undefined;
};

const OfferUpdateFormComponent = (props) => {
    const { propertyPrice, offerStatus, currentOfferPrice, isLoading } = props;

    const formDisabled = useMemo(() => {
        return isLoading || (!props.offerPriceValue && !offerStatus)
    }, [isLoading, props.offerPriceValue, offerStatus]);

    const newPriceValidator = useMemo(() => {
        return priceSameValidator(propertyPrice);
    }, [propertyPrice]);

    const newPriceDifferentValidator = useMemo(() => {
        return currentValueDifferentValidator(currentOfferPrice);
    }, [currentOfferPrice]);

    return (
        <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="form-horizontal"
            noValidate={true}
        >
            <FormGroup row className={'mb-4'}>
                <Label className={'col-form-label col-lg-3'}>{props.t('property_price')}</Label>

                <Col lg="9">
                    <div className={'form-field-vertical-center'}>
                        {propertyPrice !== null ? (
                            <NumberFormat value={propertyPrice} thousandSeparator={' '} prefix={'€ '} displayType={'text'} />
                        ) : '-'}
                    </div>
                </Col>
            </FormGroup>

            <Field
                name="price"
                label={props.t('field.label.new_price')}
                placeholder={props.t('field.placeholder.new_price')}
                id={'price'}
                type="text"
                component={TextInput}
                validate={[ numberValidator, priceMinValue, priceMaxValue, newPriceValidator, newPriceDifferentValidator ]}
                row
                translateParams={{ min: PROPERTY_PRICE_MIN, max: PROPERTY_PRICE_MAX }}
                prependSymbol={'€'}
                rowLayoutSize={{
                    label: 3,
                    column: 9,
                }}
                hint={'offer.remove.tip'}
                format={formatPrice}
                normalize={normalizePrice}
            />

            <FormGroup row className={'mb-4'}>
                <Label className={'col-form-label col-lg-3'}>{props.t('difference')}</Label>

                <Col lg="9">
                    <div className={'form-field-vertical-center'}>
                        <OfferPriceDiff
                            offerPrice={props.offerPriceValue !== '' ? props.offerPriceValue : null }
                            price={propertyPrice}
                            textClass={'font-size-13'}
                        />
                    </div>
                </Col>
            </FormGroup>

            <Row className="justify-content-end">
                <Col lg="9">
                    <div className="button-items">
                        <Button
                            type="submit"
                            color="primary"
                            disabled={formDisabled}
                        >
                            <ButtonLoadingIcon loading={isLoading} />
                            {props.t('save')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};

const selector = formValueSelector(OFFER_UPDATE_FORM);

const OfferUpdate = reduxForm({
    form: OFFER_UPDATE_FORM,
    enableReinitialize: true,
})(OfferUpdateFormComponent);

const mapStateToProps = state => {
    const offerPriceValue = selector(state, 'price');
    return {
        offerPriceValue,
    };
};

const mapDispatchToProps = {
};

export const OfferUpdateForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OfferUpdate));