import {
    BILLING_STRIPE_ADD_CARD,
    BILLING_STRIPE_ADD_CARD_SUCCESS,
    BILLING_STRIPE_ADD_CARD_ERROR,
    BILLING_CLEAN_STRIPE_ADD_CARD,
} from './actionTypes';

const initialState = {
    addLoading: false,
    addSuccess: null,
    addError: null,
};

const remove = (state = initialState, action) => {
    switch (action.type) {
        case BILLING_STRIPE_ADD_CARD:
            state = {
                ...state,
                addLoading: true,
                addSuccess: null,
                addError: null,
            };
            break;

        case BILLING_STRIPE_ADD_CARD_SUCCESS:
            state = {
                ...state,
                addLoading: false,
                addSuccess: true,
            };
            break;

        case BILLING_STRIPE_ADD_CARD_ERROR:
            state = {
                ...state,
                addLoading: false,
                addError: action.payload,
            };
            break;

        case BILLING_CLEAN_STRIPE_ADD_CARD:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default remove;