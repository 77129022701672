import {
    UPDATE_LEAD_PREFERENCES_DATA,
    UPDATE_LEAD_PREFERENCES_DATA_SUCCESS,
    UPDATE_LEAD_PREFERENCES_DATA_ERROR,
    CLEAN_UPDATE_LEAD_PREFERENCES_DATA,
} from './actionTypes';

const initialState = {
    updateLoading: false,
    updateSuccess: null,
    updateError: null,
};

const update = (state = initialState, action) => {
    switch (action.type) {

        case UPDATE_LEAD_PREFERENCES_DATA:
            state = {
                ...state,
                updateLoading: true,
                updateSuccess: null,
                updateError: null,
            };
            break;

        case UPDATE_LEAD_PREFERENCES_DATA_SUCCESS:
            state = {
                ...state,
                updateLoading: false,
                updateSuccess: action.payload,
            };
            break;

        case UPDATE_LEAD_PREFERENCES_DATA_ERROR:
            state = {
                ...state,
                updateLoading: false,
                updateError: action.payload,
            };
            break;

        case CLEAN_UPDATE_LEAD_PREFERENCES_DATA:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default update;