import { isValidEmail } from '../../../utils/validations';

export const loginValidation = values => {
    const errors = {};
    const { email, password } = values;

    if (!email) {
        errors.email = 'field.error.required';
    } else if ((!isValidEmail(email))) {
        errors.email = 'field.error.invalid';
    }

    if (!password) {
        errors.password = 'field.error.required';
    } else if (password && password.length < 8) {
        errors.password = 'field.error.password.length';
    }

    return errors;
};

export const registerValidation = values => {
    const errors = {};
    const { email, name, password, password_confirmation } = values;

    if (!email) {
        errors.email = 'field.error.required';
    } else if ((!isValidEmail(email))) {
        errors.email = 'field.error.invalid';
    }

    if (!name) {
        errors.name = 'field.error.required';
    }

    if (!password) {
        errors.password = 'field.error.required';
    } else if (password && password.length < 8) {
        errors.password = 'field.error.password.length';
    }

    if (!password_confirmation) {
        errors.password_confirmation = 'field.error.required';
    } else if (password_confirmation && password_confirmation.length < 8) {
        errors.password_confirmation = 'field.error.password.length';
    } else if (password_confirmation && password && password_confirmation !== password) {
        errors.password_confirmation = 'field.error.password.no.match';
    }

    return errors;
};

export const resetValidation = values => {
    const errors = {};
    const { password, password_confirmation } = values;

    if (!password) {
        errors.password = 'field.error.required';
    } else if (password && password.length < 8) {
        errors.password = 'field.error.password.length';
    }

    if (!password_confirmation) {
        errors.password_confirmation = 'field.error.required';
    } else if (password_confirmation && password_confirmation.length < 8) {
        errors.password_confirmation = 'field.error.password.length';
    } else if (password_confirmation && password && password_confirmation !== password) {
        errors.password_confirmation = 'field.error.password.no.match';
    }

    return errors;
};

export const activateValidation = values => {
    const errors = {};
    const { name, password, password_confirmation } = values;

    if (!name) {
        errors.name = 'field.error.required';
    }

    if (!password) {
        errors.password = 'field.error.required';
    } else if (password && password.length < 8) {
        errors.password = 'field.error.password.length';
    }

    if (!password_confirmation) {
        errors.password_confirmation = 'field.error.required';
    } else if (password_confirmation && password_confirmation.length < 8) {
        errors.password_confirmation = 'field.error.password.length';
    } else if (password_confirmation && password && password_confirmation !== password) {
        errors.password_confirmation = 'field.error.password.no.match';
    }

    return errors;
};