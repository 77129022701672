import {
    ACTIVATE_ACCOUNT,
    ACTIVATE_ACCOUNT_SUCCESS,
    ACTIVATE_ACCOUNT_ERROR,
    ACTIVATE_ACCOUNT_CLEAN,
} from './actionTypes';

export const activateAccount = (id, hash, params, formData, history) => {
    return {
        type: ACTIVATE_ACCOUNT,
        payload: { id, hash, params, formData, history },
    }
};

export const activateAccountSuccess = (data) => {
    return {
        type: ACTIVATE_ACCOUNT_SUCCESS,
        payload: data
    }
};

export const activateAccountError = (data) => {
    return {
        type: ACTIVATE_ACCOUNT_ERROR,
        payload: data
    }
};

export const activateAccountClean = () => {
    return {
        type: ACTIVATE_ACCOUNT_CLEAN,
    };
};