import React from 'react';
import {useTranslation} from 'react-i18next';
import {Table, Spinner} from "reactstrap";
import moment from "moment";

export const CrmDetails = ({data}) => {
  const {t} = useTranslation()

  const {
    active,
    finished_at,
    last_run_at,
    last_status,
    text,
  } = data

  return (
    <div className="table-responsive table-centered mt-3">
      <Table className="table">
        <tbody>
        <tr>
          <th scope="row">{t('sync_status')} :</th>
          <td>{active ? (
            <>
              {t("active")}
              <Spinner size={"sm"} color={"primary"} className={"ml-2"} />
            </>
          ) : t("inactive")}</td>
        </tr>

        <tr>
          <th scope="row">{t('last_sync')} :</th>
          <td>{finished_at ? moment(finished_at).format('DD/MM/YYYY HH:mm:ss') : "-"}</td>
        </tr>

        <tr>
          <th scope="row">{t('last_run')} :</th>
          <td>{last_run_at ? moment(last_run_at).format('DD/MM/YYYY HH:mm:ss') : "-"}</td>
        </tr>

        <tr>
          <th scope="row">{t('last_status')} :</th>
          <td>{last_status !== null ? (
            last_status ? t("success") : t("failed")
          ) : "-"}</td>
        </tr>

        <tr>
          <th scope="row">{t('info')} :</th>
          <td>{text || "-"}</td>
        </tr>
        </tbody>
      </Table>
    </div>
  );
};