import React from 'react';

import { TabNav } from './TabNav';
import { TabBody } from './TabBody';

export const Tabs = () => {
    return (
        <div className="chat-leftsidebar-nav pt-4">
            <TabNav  />

            <TabBody />
        </div>
    );
};
