import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_SUBSCRIPTION_TARIFFS } from './actionTypes';
import {
    fetchSubscriptionTariffsSuccess,
    fetchSubscriptionTariffsError,
} from './actions';

import { API } from '../../../../../api';

function* fetchTariffs() {
    try {
        const response = yield call(API.billing.getSubscriptionTariffs);

        const { data } = response;

        yield put(fetchSubscriptionTariffsSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchSubscriptionTariffsError(message));
    }
}

export function* watchTariffsFetch() {
    yield takeEvery(FETCH_SUBSCRIPTION_TARIFFS, fetchTariffs)
}

function* listSaga() {
    yield all([
        fork(watchTariffsFetch),
    ]);
}

export default listSaga;