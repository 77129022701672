import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';
import i18n from '../../../../../i18n';

import { CANCEL_BILLING_SUBSCRIPTION } from './actionTypes';
import {
    cancelBillingSubscriptionSuccess,
    cancelBillingSubscriptionError,
} from './actions';

import { API } from '../../../../../api';

function* cancelSubscription() {
    try {
        const response = yield call(API.billing.deleteSubscription);

        const { data } = response;
        yield put(cancelBillingSubscriptionSuccess(true));

        toastr.success(i18n.t('billing.subscription.cancel.success'));
    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(cancelBillingSubscriptionError(message));
        toastr.error(message);
    }
}

export function* watchCancelSubscription() {
    yield takeEvery(CANCEL_BILLING_SUBSCRIPTION, cancelSubscription)
}

function* cancelSaga() {
    yield all([
        fork(watchCancelSubscription),
    ]);
}

export default cancelSaga;