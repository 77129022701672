import { all } from 'redux-saga/effects';

import profileSaga from './profile/saga';
import inviteSaga from './invite/saga';

function* clientSaga() {
    yield all([
        profileSaga(),
        inviteSaga(),
    ])
}

export default clientSaga;