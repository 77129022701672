import React from 'react';
import { Row, Col, Container } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LanguageDropdown from '../../../CommonForBoth/TopbarDropdown/LanguageDropdown'
import { AuthBlankLayout } from '../AuthBlankLayout';

const AuthLayoutComponent = (props) => {
    return (
        <React.Fragment>
            <div className="home-btn auth-lang-wrap">
                <LanguageDropdown />
            </div>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">

                        <AuthBlankLayout/>

                        <Col xl={3}>
                            <div className="auth-full-page-content p-md-5 p-4">
                                <div className="w-100">

                                    {props.children}

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return { };
};

const mapDispatchToProps = {};

export const AuthLayout = withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthLayoutComponent));