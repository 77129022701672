import React from "react"

import { Logo } from "./Logo"
import { Short } from "./Short"

export const LogoIcon = props => {
  switch (props.type) {
    case "short":
      return <Short {...props} />

    default:
      return <Logo {...props} />
  }
}
