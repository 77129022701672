import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useTable, useSortBy } from 'react-table';
import { useRouteMatch, withRouter, Link, useHistory } from 'react-router-dom';
import moment from "moment";
import NumberFormat from 'react-number-format';

import { propertyRequestHelper } from '../../../../../helpers';
import { PROPERTY_REQUEST_TYPE_INVESTMENT } from '../../../../../consts/propertyRequest';
import { LeadStatusBadge } from '../../../../../components/Custom';
import { LeadUpdateStatusButtons } from '../LeadUpdateStatusButtons';

const LeadsTableComponent = (props) => {
    const { keys, items, showEmpty = true } = props;

    let { url } = useRouteMatch();
    const history = useHistory();

    const data = useMemo(() => {
        return items;
    }, [items]);

    const [columns, setColumns] = useState([]);

    const columnConfig = {
        'id': {
            Header: props.t('id'),
            accessor: 'id',
            id: 'id',
            Cell: ({ row: { original }, value }) => {
                return <Link to={`${url}/${original.id}`} className="font-weight-bold text-dark">{value}</Link>;
            },
        },
        'client': {
            Header: props.t('client'),
            accessor: 'client',
            id: 'client',
            Cell: ({ row: { original } }) => {
            const { property_ad: { user } } = original;
                return (
                    <Link to={`/clients/${user?.id}`} className="text-dark font-weight-bold">{user?.name || '-'}</Link>
                )
            },
        },
        'consultant': {
            Header: props.t('consultant'),
            accessor: 'consultant',
            id: 'consultant',
            Cell: ({ row: { original } }) => {
                const { consultant } = original;
                return (
                    <Link to={`/consultants/${consultant?.id}`} className="text-dark font-weight-bold">{consultant?.name || '-'}</Link>
                )
            },
        },
        'type': {
            Header: props.t('type'),
            accessor: 'type',
            id: 'type',
            Cell: ({ row: { original } }) => {
                const { property_ad } = original;
                return (
                    <Link to={`${url}/${original.id}`}>
                        <span className={'text-dark font-weight-bold text-nowrap text-decoration-underline'}>{propertyRequestHelper.type.getType(property_ad.type, props.t).label}</span>
                    </Link>
                )
            },
        },
        'price': {
            Header: props.t('price'),
            accessor: 'price',
            id: 'price',
            Cell: ({ row: { original } }) => {
                const { property_ad } = original;
                if (property_ad.type === PROPERTY_REQUEST_TYPE_INVESTMENT) {
                    return '-';
                }

                return (
                    <Link to={`${url}/${original.id}`} className={'text-dark text-nowrap'}>
                        <NumberFormat
                            value={property_ad.price_min}
                            thousandSeparator={' '}
                            prefix={'€ '}
                            displayType={'text'}
                        />
                        {' - '}
                        <NumberFormat
                            value={property_ad.price_max}
                            thousandSeparator={' '}
                            prefix={'€ '}
                            displayType={'text'}
                        />
                    </Link>
                )
            }
        },
        'regions': {
            Header: props.t('provinces'),
            accessor: 'regions',
            id: 'regions',
            Cell: ({ row: { original } }) => {
                const { property_ad } = original;
                return (
                    <Link to={`${url}/${original.id}`} className={'text-dark'}>
                        { property_ad.regions && !!property_ad.regions.length ? property_ad.regions.map(item => item.name).join(', ') : '-' }
                    </Link>
                )
            }
        },
        'status': {
            Header: props.t('status'),
            accessor: 'status',
            id: 'status',
            Cell: ({ row: { original }, value }) => (
                <Link to={`${url}/${original.id}`}>
                    <LeadStatusBadge status={value} />
                </Link>
            ),
        },
        'date': {
            Header: props.t('date'),
            accessor: 'created_at',
            id: 'created_at',
            Cell: ({ row: { original } }) => {
                const { property_ad } = original;
                return (
                    <Link to={`${url}/${original.id}`} className={'text-dark'}>
                        { property_ad?.created_at ? moment(property_ad.created_at).format('DD/MM/YYYY') : '-' }
                    </Link>
                )
            },
        },
        'buy': {
            Header: () => (
                <div className={'w-100 text-center'}>{props.t('buy_lead')}</div>
            ),
            id: 'buy',
            style: {
                width: '200px',
            },
            Cell: ({ row: { original } }) => {
                return (
                    <div className={'text-center'}>
                        <LeadUpdateStatusButtons lead={original} />
                    </div>
                );
            },
        },
        'action': {
            Header: '',
            id: 'action',
            style: {
                width: '100px',
            },
            Cell: ({ row: { original } }) => {
                return (
                    <div className={'text-center'}>
                        <div className="button-items">
                            <Button color="light" outline className={'btn waves-effect btn-rounded btn-sm'}
                                    onClick={() => history.push(`${url}/${original.id}`)}
                            >
                                <i className={'mdi mdi-arrow-right font-size-18 text-primary'} />
                            </Button>
                        </div>
                    </div>
                );
            },
        },
    };

    useEffect(() => {
        let config = [];

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (columnConfig[key]) {
                config.push(columnConfig[key]);
            }
        }

        setColumns(config)
    }, [keys]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: props.initialSort,
            },
            defaultCanSort: false,
            disableSortBy: true, // data.length === 0,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
        },
        useSortBy,
    );

    useEffect(() => {
        props.onSort(sortBy);
    }, [props.onSort, sortBy]);

    const getColumnStyle = (column) => {
        return { ...column.style};
    };

    return (
        <div className="table-responsive table-centered">
            <Table className={'table-bordered table-part-bordered'} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps({ title: null }))}
                                style={{ ...column.getHeaderProps(column.getSortByToggleProps().style), ...getColumnStyle(column) }}
                            >
                                <div className={'d-flex'}>
                                    <div className={'d-flex flex-fill'}>
                                        {column.render('Header')}
                                    </div>

                                    <div>
                                        {column.canSort && (
                                            column.isSorted ? (
                                                <i className={'bx font-size-16 align-middle' + (column.isSortedDesc ? " bx-sort-down" : " bx-sort-up")} />
                                            ) : <i className="bx bx-sort align-middle" style={{ color: '#adb5bd' }} />
                                        )}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {(
                    rows.length > 0 && rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                ) || (
                    <tr>
                        <td colSpan={columns.length}>
                            {!props.isLoading ? props.t('empty') : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}} />}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        initialSort: [],
    };
};

const mapDispatchToProps = {
};

export const LeadsTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadsTableComponent)));