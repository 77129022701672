import React, { useEffect, useMemo, useState } from 'react';
import { Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { LoadingOverlay } from '../../../../components/Custom';
import { BillingInfoEditForm } from '../BillingInfoEditForm';
import {
    fetchBillingInfo,
    cleanBillingInfo,
    editBillingInfo,
    cleanBillingInfoEdit,
} from '../../../../store/actions';
import * as format from '../../../../utils/format';

const BillingInfoEditComponent = (props) => {
    const { onSuccess = null, disabledMode = false } = props;

    const [isFormActive, setIsFormActive] = useState(!disabledMode);

    useEffect(() => {
        props.fetchBillingInfo();

        return () => {
            props.cleanBillingInfo();
            props.cleanBillingInfoEdit();
        };
    }, []);

    const isDisabled = useMemo(() => {
        return !props.data || props.editLoading || !isFormActive;
    }, [props.data, props.editLoading, isFormActive]);

    const onSubmitSuccess = () => {
        if (disabledMode && isFormActive) {
            setIsFormActive(false);
        }

        if (onSuccess) {
            onSuccess();
        }
    };

    const onSubmit = (values) => {
        if (isDisabled) {
            return;
        }

        let params = {
            ...values,
        };

        if (values.phone) {
            params.phone = values.phone.replace(/[^0-9]/g, '');
        }

        props.editBillingInfo(params, onSubmitSuccess);
    };

    const infoError = useMemo(() => {
        if (props.viewError || props.editError) {
            return props.viewError || props.editError?.message;
        }
        return false;
    }, [props.viewError, props.editError]);

    const toggleActiveForm = () => {
        setIsFormActive(!isFormActive);
    }

    return (
        <>
            {infoError && <Alert color="danger">{infoError}</Alert>}

            <LoadingOverlay isLoading={props.loading}>
                <BillingInfoEditForm
                    initialValues={{
                        ...props.data,
                        phone: format.phone(props.data?.phone),
                    }}
                    onSubmit={onSubmit}
                    isDisabled={isDisabled}
                    isLoading={props.editLoading}
                    disabledMode={disabledMode}
                    isFormActive={isFormActive}
                    toggleActiveForm={toggleActiveForm}
                />
            </LoadingOverlay>
        </>
    );
};

const mapStateToProps = state => {
    const { data, loading, viewError } = state.billing.billingInfo.view;
    const { editLoading, editError } = state.billing.billingInfo.edit;

    return {
        data, loading, viewError,
        editLoading, editError,
    };
};

const mapDispatchToProps = {
        fetchBillingInfo,
        cleanBillingInfo,
        editBillingInfo,
        cleanBillingInfoEdit,
};

export const BillingInfoEdit = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingInfoEditComponent)));