export const FETCH_CLIENT_OWNED_PROPERTIES = 'FETCH_CLIENT_OWNED_PROPERTIES';
export const FETCH_CLIENT_OWNED_PROPERTIES_SUCCESS = 'FETCH_CLIENT_OWNED_PROPERTIES_SUCCESS';
export const FETCH_CLIENT_OWNED_PROPERTIES_ERROR = 'FETCH_CLIENT_OWNED_PROPERTIES_ERROR';
export const CLEAN_CLIENT_OWNED_PROPERTIES = 'CLEAN_CLIENT_OWNED_PROPERTIES';

export const CLIENT_OWNED_PROPERTIES_SET_FILTER = 'CLIENT_OWNED_PROPERTIES_SET_FILTER';
export const CLIENT_OWNED_PROPERTIES_RESET_FILTER = 'CLIENT_OWNED_PROPERTIES_RESET_FILTER';

export const CLIENT_OWNED_PROPERTIES_SET_SORT = 'CLIENT_OWNED_PROPERTIES_SET_SORT';
export const CLIENT_OWNED_PROPERTIES_RESET_SORT = 'CLIENT_OWNED_PROPERTIES_RESET_SORT';

export const CLIENT_OWNED_PROPERTIES_SET_PAGE = 'CLIENT_OWNED_PROPERTIES_SET_PAGE';

export const CLIENT_OWNED_PROPERTIES_UPDATE_ITEM = 'CLIENT_OWNED_PROPERTIES_UPDATE_ITEM';

export const CLIENT_OWNED_PROPERTIES_SET_SEARCH = 'CLIENT_OWNED_PROPERTIES_SET_SEARCH';