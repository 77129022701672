import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_PROPERTY_REQUEST } from './actionTypes';
import {
    fetchPropertyRequestSuccess,
    fetchPropertyRequestError,
} from './actions';

import { API } from '../../../api';

function* fetchPropertyRequest({ payload: { id } }) {
    try {
        const includes = ['cities', 'regions'];

        const response = yield call(API.propertyRequest.getPropertyRequest, id, { includes });

        const { data } = response;
        yield put(fetchPropertyRequestSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchPropertyRequestError(message));
    }
}

export function* watchPropertyRequestFetch() {
    yield takeEvery(FETCH_PROPERTY_REQUEST, fetchPropertyRequest)
}

function* viewSaga() {
    yield all([
        fork(watchPropertyRequestFetch),
    ]);
}

export default viewSaga;