import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import { registerResendConfirmEmail, registerResendConfirmEmailClean } from '../../../../store/actions';

export const VerifyEmailComponent = (props) => {

    useEffect(() => {
        return () => {
            props.registerResendConfirmEmailClean();
        };
    }, []);

    const resend = (e) => {
        e.preventDefault();

        props.registerResendConfirmEmail({ email: props.email });
    };

    return (
        <div className="my-auto">
            <div className="text-center">
                <div className="avatar-md mx-auto">
                    <div className="avatar-title rounded-circle bg-light">
                        <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                    </div>
                </div>
                <div className="p-2 mt-4">
                    {props.resendEmailSuccess ? (
                        <Alert color="success">
                            {props.t('confirmation.email.sent')}
                        </Alert>
                    ) : null}

                    {props.resendEmailError && props.resendEmailError.message ? (
                        <Alert color="danger">
                            {props.resendEmailError.message}
                        </Alert>
                    ) : null}

                    <h4>{props.t('confirm_email')}</h4>
                    <p>
                        {props.t('confirmation.email.sent_to')}{" "}
                        <span className="font-weight-semibold">
                            {props.email}
                            </span>
                        . {props.t('please_check_it')}
                    </p>
                    <div className="mt-5 text-center">
                        <p>
                            {props.t('not_receive_email')}{" "}
                            <Link to="#" className="font-weight-medium text-primary" onClick={(e) => resend(e)}>
                                {" "}
                                {props.t('resend')}{" "}
                            </Link>{" "}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { resendEmailLoading, resendEmailSuccess, resendEmailError } = state.Account;
    return {
        resendEmailLoading, resendEmailSuccess, resendEmailError,
    };
};

const mapDispatchToProps = {
    registerResendConfirmEmail,
    registerResendConfirmEmailClean,
};

export const VerifyEmail = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VerifyEmailComponent)));