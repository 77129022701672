import {normalizeBoolean} from "utils/normalize"

const PREVIEW_MODE = process.env.REACT_APP_PREVIEW_MODE;

export const isAllow = (forceStatus = null) => {
  if (forceStatus !== null) {
    return forceStatus
  }

  const status = normalizeBoolean(PREVIEW_MODE);

  return typeof status === 'boolean' ? status : false
};

export default {
  isAllow,
};