import {
    LINK_SOCIAL_ACCOUNT,
    LINK_SOCIAL_ACCOUNT_SUCCESS,
    LINK_SOCIAL_ACCOUNT_ERROR,

    CLEAN_SOCIAL_ACCOUNT_STATE,

    UNLINK_SOCIAL_ACCOUNT,
    UNLINK_SOCIAL_ACCOUNT_SUCCESS,
    UNLINK_SOCIAL_ACCOUNT_ERROR,
} from './actionTypes';

const initialState = {
    linkingError: null,
    pending: [],
};

const linking = (state = initialState, action) => {
    switch (action.type) {
        case LINK_SOCIAL_ACCOUNT:
            state = {
                ...state,
                pending: [...state.pending, action.payload.params?.provider],
                linkingError: null,
            };
            break;

        case LINK_SOCIAL_ACCOUNT_SUCCESS:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.provider),
            };
            break;

        case LINK_SOCIAL_ACCOUNT_ERROR:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.provider),
                linkingError: action.payload.error,
            };
            break;

        case CLEAN_SOCIAL_ACCOUNT_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;


        // UNLINK ACCOUNT
        case UNLINK_SOCIAL_ACCOUNT:
            state = {
                ...state,
                pending: [...state.pending, action.payload.provider],
                linkingError: null,
            };
            break;

        case UNLINK_SOCIAL_ACCOUNT_SUCCESS:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.provider),
            };
            break;

        case UNLINK_SOCIAL_ACCOUNT_ERROR:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.provider),
                linkingError: action.payload.error,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default linking;