import {
    INVITE_NEW_CLIENT,
    INVITE_NEW_CLIENT_SUCCESS,
    INVITE_NEW_CLIENT_ERROR,
    CLEAN_INVITE_NEW_CLIENT_PAGE,
} from './actionTypes';

const initialState = {
    sendLoading: false,
    sendSuccess: null,
    sendError: null,
};

const invite = (state = initialState, action) => {
    switch (action.type) {
        case INVITE_NEW_CLIENT:
            state = {
                ...state,
                sendLoading: true,
                sendSuccess: null,
                sendError: null,
            };
            break;

        case INVITE_NEW_CLIENT_SUCCESS:
            state = {
                ...state,
                sendLoading: false,
                sendSuccess: action.payload,
            };
            break;

        case INVITE_NEW_CLIENT_ERROR:
            state = {
                ...state,
                sendLoading: false,
                sendError: action.payload,
            };
            break;

        case CLEAN_INVITE_NEW_CLIENT_PAGE:
            state = {
                ...state,
                sendLoading: false,
                sendSuccess: null,
                sendError: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default invite;