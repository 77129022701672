import React from 'react';
import {withRouter, Switch, Route, Redirect, useRouteMatch} from 'react-router-dom';
import {connect} from 'react-redux';

import {Integration} from './'
import AllowedRoute from '../../routes/middleware/AllowedRoute';

const IntegrationIndexComponent = (props) => {
  let {path} = useRouteMatch();

  return (
    <Switch>
      <AllowedRoute exact path={path} component={Integration} allowed={true}/>

      <Route>
        <Redirect to={path}/>
      </Route>
    </Switch>
  );
};

const mapStateToProps = state => {
  const {data} = state.user;
  return {
    user: data,
  };
};

export const IntegrationIndex = withRouter(connect(mapStateToProps, {})(IntegrationIndexComponent));