import {
    INVITE_NEW_CLIENT,
    INVITE_NEW_CLIENT_SUCCESS,
    INVITE_NEW_CLIENT_ERROR,
    CLEAN_INVITE_NEW_CLIENT_PAGE,
} from './actionTypes';

// INVITE NEW CLIENT
export const inviteNewClient = (params, history) => {
    return {
        type: INVITE_NEW_CLIENT,
        payload: { params, history },
    }
};

export const inviteNewClientSuccess = (data) => {
    return {
        type: INVITE_NEW_CLIENT_SUCCESS,
        payload: data,
    }
};

export const inviteNewClientError = (data) => {
    return {
        type: INVITE_NEW_CLIENT_ERROR,
        payload: data,
    }
};

export const cleanInviteNewClientPage = () => {
    return { type: CLEAN_INVITE_NEW_CLIENT_PAGE };
};