import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Alert, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form'
import { withRouter, useHistory } from "react-router-dom";

import { useTitle } from "../../hooks";
import { FORM_ADD_PROJECT } from "../../consts/forms";
import TextInput from "../../components/Common/TextInput";
import ButtonLoadingIcon from '../../components/Common/ButtonLoadingIcon';
import { BackButton, PageTitle } from '../../components/Custom';
import {
    required as requiredValidator,
} from "../../utils/validations";
import { addProject, cleanAddProjectPage } from '../../store/actions';

const ProjectAddComponent = (props) => {
    useTitle(props.t('title.project.add'));

    const history = useHistory();

    useEffect(() => {
        return () => {
            props.cleanAddProjectPage();
        };
    }, []);

    const onSubmit = (values) => {
        props.addProject(values, history);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('project.add')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <BackButton />
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {props.addError && props.addError.message ? <Alert color="danger">{props.addError.message}</Alert> : null}

                                    <form
                                        onSubmit={props.handleSubmit(onSubmit)}
                                        className="form-horizontal"
                                        noValidate={true}
                                    >
                                        <Field
                                            name="name"
                                            label={props.t('field.label.name')}
                                            placeholder={props.t('field.placeholder.name')}
                                            id={'name'}
                                            type="text"
                                            component={TextInput}
                                            validate={[ requiredValidator ]}
                                            row
                                        />

                                        <Row className="justify-content-end">
                                            <Col lg="10">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    disabled={props.addLoading}
                                                >
                                                    <ButtonLoadingIcon loading={props.addLoading} />
                                                    {props.t('create')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { addLoading, addError } = state.project;
    return {
        addLoading, addError,
    };
};

const mapDispatchToProps = {
    addProject,
    cleanAddProjectPage,
};

const ProjectAddForm = reduxForm({
    form: FORM_ADD_PROJECT,
})(ProjectAddComponent);

export const ProjectAdd = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectAddForm)));