import React from 'react';
import { Link } from "react-router-dom";
import classNames from "classnames";
import './PropertyFavoriteButton.scss';

export const PropertyFavoriteButton = ({
                                           status,
                                           onChange,
                                           className
                                       }) => {
    const toggleFavorite = (e) => {
        e.preventDefault();
        onChange(!status);
    };

    return (
        <Link
            to={'#'}
            onClick={toggleFavorite}
            className={classNames("property-favorite-button", {
                'property-favorite-button__active': status,
                'property-favorite-button__inactive': !status,
                [className]: className
            })}
        >
            <i className={`bx align-middle font-size-20` + (status ? ' bxs-star' : ' bx-star')}/>
        </Link>
    );
};