import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { destroy, getFormValues } from 'redux-form'

import { HomeWizardStep1 } from './HomeWizardStep1';
import { HomeWizardStep2 } from './HomeWizardStep2';
import { HomeWizardStep3 } from './HomeWizardStep3';
import { HomeWizardStep4 } from './HomeWizardStep4';
import { HomeWizardStepConfirm } from './HomeWizardStepConfirm';

import { WizardProgress } from '../WizardProgress';
import { FORM_PROPERTY_REQUEST_HOME_WIZARD } from '../../../../../consts/forms';
import { API } from "../../../../../api";
import { propertyRequestHelper } from '../../../../../helpers/propertyRequest';

const HomeWizardComponent = (props) => {
    const [step, setStep] = useState(1);

    const [provinceOptions, setProvinceOptions] = useState([]);

    const fetchProvinces = async () => {
        try {
            const response = await API.common.getRegions({ pagination: false });
            const { data } = response;

            setProvinceOptions(data.data)
        } catch (e) {
        }
    };

    useEffect(() => {
        fetchProvinces();

        return () => {
            // destroy wizard form
            props.destroy(FORM_PROPERTY_REQUEST_HOME_WIZARD);
        };
    }, []);

    const nextStep = () => {
        setStep(step + 1);
    };

    const previousPage = () => {
        setStep(step - 1);
    };

    const percent = useMemo(() => {
        return propertyRequestHelper.common.getHomeWizardPercent(props.formValues);
    }, [props.formValues]);

    return (
        <>
            <h5 className={'mr-2'}>{props.t('new_home')}</h5>

            <div className={'pt-2 pb-2'}>
                <WizardProgress percent={percent} />
            </div>

            <div className={'wizard-wrap'}>
                {step === 1 && (
                    <HomeWizardStep1 back={() => props.onStart()} onSubmit={nextStep} provinceOptions={provinceOptions} />
                )}

                {step === 2 && (
                    <HomeWizardStep2 back={() => previousPage()} onSubmit={nextStep} />
                )}

                {step === 3 && (
                    <HomeWizardStep3 back={() => previousPage()} onSubmit={nextStep} />
                )}

                {step === 4 && (
                    <HomeWizardStep4 back={() => previousPage()} onSubmit={nextStep} />
                )}

                {step === 5 && (
                    <HomeWizardStepConfirm back={() => previousPage()} onSubmit={nextStep} percent={percent} />
                )}
            </div>
        </>
    );
};

const mapStateToProps = state => {
    const formValues = getFormValues(FORM_PROPERTY_REQUEST_HOME_WIZARD)(state);

    return {
        formValues,
    };
};

const mapDispatchToProps = {
    destroy,
};

export const HomeWizard = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomeWizardComponent)));