import {
    CHAT_DELETE_MESSAGE
} from './actionTypes';

const initialState = {
};

const message = (state = initialState, action) => {
    switch (action.type) {
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default message;