import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import toastr from "toastr";

import i18n from "../../../../i18n";

import { CLIENT_PROPERTIES_SEND_FAVORITE_STATUS } from './actionTypes';
import { clientAcceptedPropertiesUpdateItem } from '../accepted/actions';
import { clientOwnedPropertiesUpdateItem } from '../owned/actions';

import { API } from '../../../../api';
import {
    CLIENT_PROPERTIES_ACCEPTED_TAB, CLIENT_PROPERTIES_ALL_TAB, CLIENT_PROPERTIES_FAVORITE_TAB,
    CLIENT_PROPERTIES_OWNED_TAB
} from '../../../../consts/property/clientProperties';
import { clientFavoritePropertiesSetFilter } from "../favorite/actions";
import { clientAllPropertiesUpdateItem } from "../all/actions";
import { clientPropertiesStatisticsUpdate } from "../statistics/actions";

export const clientPropertiesActiveTabState = (state) => state.property.clientProperties.config.activeTab;
export const clientPropertiesFavoritesCount = (state) => state.property.clientProperties.statistics.data.favorites;

function* updatePropertyFavoriteStatus(id, status) {
    const activeTab = yield select(clientPropertiesActiveTabState);

    const favorites = yield select(clientPropertiesFavoritesCount)


    //Update statistics of favorites properties
    if (status) {
        yield put(clientPropertiesStatisticsUpdate({ favorites: favorites + 1 }))
    } else {
        yield put(clientPropertiesStatisticsUpdate({ favorites: favorites - 1 }))
    }

    switch (activeTab) {
        case CLIENT_PROPERTIES_ACCEPTED_TAB: {
            yield put(clientAcceptedPropertiesUpdateItem(id, { is_favorite: status }));
            break
        }
        case CLIENT_PROPERTIES_OWNED_TAB: {
            yield put(clientOwnedPropertiesUpdateItem(id, { is_favorite: status }));
            break;
        }
        case CLIENT_PROPERTIES_ALL_TAB: {
            yield put(clientAllPropertiesUpdateItem(id, { is_favorite: status }));
            break;
        }
        case CLIENT_PROPERTIES_FAVORITE_TAB: {
            //Initialize refetching of list by changing filter value with empty data
            yield put(clientFavoritePropertiesSetFilter({}))
        }
    }

    //Update statistics of favorite properties


}

function* sendFavoriteStatus({ payload: { id, params } }) {
    const { status } = params;

    yield* updatePropertyFavoriteStatus(id, status);

    try {
        const response = yield call(API.property.sendFavoriteStatus, id, params);

        const { data } = response;

        let message = status ? 'property.favorite.add' : 'property.favorite.remove';

        toastr.success(i18n.t(message));


    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        toastr.error(message);

        yield* updatePropertyFavoriteStatus(id, !status);
    }
}

export function* watchSendFavoriteStatus() {
    yield takeEvery(CLIENT_PROPERTIES_SEND_FAVORITE_STATUS, sendFavoriteStatus)
}

function* generalSaga() {
    yield all([
        fork(watchSendFavoriteStatus),
    ]);
}

export default generalSaga;