import {
    PROPERTY_REQUEST_VIEW_CLOSE_REQUEST,
    PROPERTY_REQUEST_VIEW_CLOSE_REQUEST_SUCCESS,
    PROPERTY_REQUEST_VIEW_CLOSE_REQUEST_ERROR,
    CLEAN_PROPERTY_REQUEST_VIEW_CLOSE_REQUEST,
} from './actionTypes';

// CLOSE PROPERTY REQUEST FROM VIEW PAGE
export const closePropertyRequest = (id, history) => {
    return {
        type: PROPERTY_REQUEST_VIEW_CLOSE_REQUEST,
        payload: { id, history },
    }
};

export const closePropertyRequestSuccess = (data) => {
    return {
        type: PROPERTY_REQUEST_VIEW_CLOSE_REQUEST_SUCCESS,
        payload: data,
    }
};

export const closePropertyRequestError = (data) => {
    return {
        type: PROPERTY_REQUEST_VIEW_CLOSE_REQUEST_ERROR,
        payload: data,
    }
};

export const cleanClosePropertyRequest = (data) => {
    return {
        type: CLEAN_PROPERTY_REQUEST_VIEW_CLOSE_REQUEST,
    }
};