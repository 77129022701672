import React from 'react';
import { Row, Card, CardBody } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { InvoiceCard } from './InvoiceCard';

const InvoicesGridComponent = (props) => {
    return (
        <>
            {props.list.length === 0 && props.isFetched ? (
                <Card>
                    <CardBody>
                        {props.t('invoices.empty')}
                    </CardBody>
                </Card>
            ) : (
                <Row>
                    {props.list.map(item => (
                        <InvoiceCard
                            key={`invoice-${item.number}`}
                            item={item}
                        />
                    ))}
                </Row>
            )}
        </>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export const InvoicesGrid = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvoicesGridComponent)));