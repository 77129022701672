import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { getPropertyStatistics } from "../../../../../../../../helpers";

export const TabsMenu = ({
                             tabs,
                             activeTab,
                             onTabChange,
                             statistics
                         }) => {
    const { t } = useTranslation();
    const [menu, setMenu] = useState(false);

    const handleMenuItemClick = (value) => {
        setMenu(false)
        onTabChange(value)
    }
    return (
        <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
            <DropdownToggle className="btn btn-primary" id="dashboard-tabs-menu" tag="button">
                <i className={"bx bx-menu-alt-left font-size-16 align-middle"}/>
            </DropdownToggle>
            <DropdownMenu>
                {tabs.map((tab, index) => {
                    return <Link key={`property-item-tab-${index}`} to={"#"} className={classnames("dropdown-item", { "active": activeTab === tab.value })}
                                 onClick={() => handleMenuItemClick(tab.value)}>
                        {`${t(tab.label)} (${getPropertyStatistics(statistics, tab.value)})`}
                    </Link>
                })}
            </DropdownMenu>
        </Dropdown>
    );
};

TabsMenu.propTypes = {};


