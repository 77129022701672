import {
    FETCH_PROPERTY_REQUEST_LIST,
    FETCH_PROPERTY_REQUEST_LIST_SUCCESS,
    FETCH_PROPERTY_REQUEST_LIST_ERROR,
    CLEAN_PROPERTY_REQUEST_LIST,
    PROPERTY_REQUEST_SET_PAGE,
} from './actionTypes';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    page: 1,
    meta: null,
};

const list = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_PROPERTY_REQUEST_LIST:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case FETCH_PROPERTY_REQUEST_LIST_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case FETCH_PROPERTY_REQUEST_LIST_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case CLEAN_PROPERTY_REQUEST_LIST:
            state = {
                ...state,
                ...initialState,
            };
            break;

        // SET PAGE
        case PROPERTY_REQUEST_SET_PAGE:
            state = {
                ...state,
                page: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default list;