import {
    REGISTER_USER,
    REGISTER_USER_SUCCESSFUL,
    REGISTER_USER_FAILED,
    REGISTER_USER_CLEAN,
    SOCIAL_REGISTER_USER,
    SOCIAL_REGISTER_USER_SUCCESS,
    SOCIAL_REGISTER_USER_ERROR,

    REGISTER_RESEND_CONFIRM_EMAIL,
    REGISTER_RESEND_CONFIRM_EMAIL_SUCCESS,
    REGISTER_RESEND_CONFIRM_EMAIL_ERROR,
    REGISTER_RESEND_CONFIRM_EMAIL_CLEAN,
} from './actionTypes';

const initialState = {
    serverError: null,
    loading: false,
    success: false,

    socialLoading: false,
    socialSuccess: null,
    socialError: null,

    resendEmailLoading: false,
    resendEmailSuccess: null,
    resendEmailError: null,
};

const account = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_USER:
            state = {
                ...state,
                loading: true,
                serverError: null,
                success: false,
                socialError: null,
            };
            break;

        case REGISTER_USER_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                serverError: null,
                success: true,
            };
            break;

        case REGISTER_USER_FAILED:
            state = {
                ...state,
                loading: false,
                serverError: action.payload,
                success: false,
            };
            break;

        case REGISTER_USER_CLEAN:
            state = {
                ...state,
                ...initialState,
            };
            break;

        // social register
        case SOCIAL_REGISTER_USER:
            state = {
                ...state,
                socialLoading: true,
                socialError: null,
                socialSuccess: null,
                serverError: null,
            };
            break;

        case SOCIAL_REGISTER_USER_SUCCESS:
            state = {
                ...state,
                socialLoading: false,
                serverError: null,
                socialSuccess: action.payload,
            };
            break;

        case SOCIAL_REGISTER_USER_ERROR:
            state = {
                ...state,
                socialLoading: false,
                socialError: action.payload,
                socialSuccess: null,
            };
            break;

        // resend confirm email
        case REGISTER_RESEND_CONFIRM_EMAIL:
            state = {
                ...state,
                resendEmailLoading: true,
                resendEmailError: null,
                resendEmailSuccess: null,
            };
            break;

        case REGISTER_RESEND_CONFIRM_EMAIL_SUCCESS:
            state = {
                ...state,
                resendEmailLoading: false,
                resendEmailSuccess: true,
            };
            break;

        case REGISTER_RESEND_CONFIRM_EMAIL_ERROR:
            state = {
                ...state,
                resendEmailLoading: false,
                resendEmailError: action.payload,
            };
            break;

        case REGISTER_RESEND_CONFIRM_EMAIL_CLEAN:
            state = {
                ...state,
                resendEmailLoading: false,
                resendEmailSuccess: null,
                resendEmailError: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default account;