import React, {useState} from 'react';

import {AccountCreate} from "./AccountCreate"

export const WhiseProvider = (props) => {
  const {component: Component, ...restProps} = props;

  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => {
    setModalVisible(true)
  }

  const toggleModal = () => setModalVisible(!modalVisible)

  const onAccountCreate = () => {
    showModal()
  }

  return (
    <>
      <Component onAccountCreate={onAccountCreate} {...restProps} />

      <AccountCreate
        id={props.data.id}
        crm={props.crm}
        visible={modalVisible}
        toggleVisible={toggleModal}
      />
    </>
  );
};