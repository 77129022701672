import { combineReducers } from "redux";

import companyLeadsReducer from './companyLeads/reducers';
import viewReducer from './view/reducer';
import statusReducer from './status/reducer';
import leadsStatusesReducer from './leadsStatuses/reducer';
import assignReducer from './assign/reducer';
import userLeadsReducer from './userLeads/reducer';
import propertyReducer from './property/reducers';
import preferencesReducer from './preferences/reducers';

const leadReducer = combineReducers({
    companyLeads: companyLeadsReducer,
    view: viewReducer,
    status: statusReducer,
    leadsStatuses: leadsStatusesReducer,
    assign: assignReducer,
    userLeads: userLeadsReducer,
    property: propertyReducer,
    preferences: preferencesReducer,
});

export default leadReducer;