import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Row, Alert, Button } from "reactstrap";

import ButtonLoadingIcon from '../../../../components/Common/ButtonLoadingIcon';
import { fetchSubscriptionPaymentLink, cleanSubscriptionPaymentLink } from '../../../../store/actions';

const BillingIncompletePaymentComponent = (props) => {

    useEffect(() => {
        return () => {
            props.cleanSubscriptionPaymentLink();
        };
    }, [])

    const onClick = () => {
        props.fetchSubscriptionPaymentLink();
    }

    return (
        <Row className={'mt-4'}>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <CardTitle className={'mb-4'}>{props.t('billing.incomplete_payment.title')}</CardTitle>

                        <Alert color="primary">{props.t('billing.incomplete_payment.message')}</Alert>

                        {props.dataError && (
                            <Alert color="danger">{props.dataError}</Alert>
                        )}

                        <Button
                            type="button"
                            color="primary"
                            disabled={props.loading}
                            onClick={onClick}
                        >
                            <ButtonLoadingIcon loading={props.loading} />
                            {props.t('button.complete_payment')}
                        </Button>

                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const { data, loading, dataError } = state.billing.subscription.paymentLink;

    return {
        data, loading, dataError,
    };
};

const mapDispatchToProps = {
    fetchSubscriptionPaymentLink,
    cleanSubscriptionPaymentLink,
};

export const BillingIncompletePayment = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingIncompletePaymentComponent)));