import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import moment from "moment";

import { propertyRequestHelper } from '../../../helpers/propertyRequest';
import { PropertyRequestStatusBadge, LeadStatusBadge } from '../StatusBadges';

export const PropertyRequestInfoTable = (props) => {
    const { keys, data } = props;

    const { t } = useTranslation();

    const [labels, setLabels] = useState([]);

    const labelsConfig = {
        'date': {
            label: t('date'),
            value: () => data.created_at ? moment(data.created_at).format('DD/MM/YYYY') : '-',
        },
        'type': {
            label: t('type'),
            value: () => data.type ? propertyRequestHelper.type.getType(data.type, t).label : '',
        },
        'status': {
            label: t('status'),
            value: () => <PropertyRequestStatusBadge status={data.status} />,
        },
        'lead_status': {
            label: t('status'),
            value: () => <LeadStatusBadge status={data.lead_status} />,
        },
        'client': {
            label: t('client'),
            value: () => (
                data.client ? <Link to={`/clients/${data.client?.id}`} className="text-dark text-decoration-underline">{data.client?.name || '-'}</Link> : '-'
            ),
        },
        'price': {
            label: t('price'),
            value: () => (
                <>
                    <NumberFormat
                        value={data.price_min}
                        thousandSeparator={' '}
                        prefix={'€ '}
                        displayType={'text'}
                    />
                    {' - '}
                    <NumberFormat
                        value={data.price_max}
                        thousandSeparator={' '}
                        prefix={'€ '}
                        displayType={'text'}
                    />
                </>
            ),
        },
        'property_types': {
            label: t('property_types'),
            value: () => {
                if (data.property_types && !!data.property_types.length) {
                    return data.property_types.map(item => propertyRequestHelper.propertyType.getPropertyType(item, t).label).join(', ');
                }
                return '-';
            },
        },
        'regions': {
            label: t('provinces'),
            value: () => data.regions && !!data.regions.length ? data.regions.map(item => item.name).join(', ') : '-',
        },
        'cities': {
            label: t('cities'),
            value: () => data.cities && !!data.cities.length ? data.cities.map(item => item.name).join(', ') : '-',
        },
        'min_size': {
            label: t('property.min_size'),
            value: () => data.min_size + ' m²',
        },
        'bedrooms': {
            label: t('min_bedrooms'),
            value: () => data.bedrooms,
        },
        'bathrooms': {
            label: t('min_bathrooms'),
            value: () => data.bathrooms,
        },
        'parking': {
            label: t('parking'),
            value: () => data.parking ? propertyRequestHelper.parking.getParking(data.parking, t).label : '-',
        },
        'property_ages': {
            label: t('property_ages'),
            value: () => {
                if (data.property_ages && !!data.property_ages.length) {
                    return data.property_ages.map(item => propertyRequestHelper.age.getAge(item, t).label).join(', ');
                }
                return '-';
            },
        },
        'min_size_terrace': {
            label: t('min_size_terrace'),
            value: () => data.min_size_terrace + ' m²',
        },
        'min_size_garden': {
            label: t('min_size_garden'),
            value: () => data.min_size_garden + ' m²',
        },
        'outside_facing': {
            label: t('outside_facing'),
            value: () => data.outside_facing ? propertyRequestHelper.outsideFacing.getOutsideFacing(data.outside_facing, t).label : '-',
        },
        'floor': {
            label: t('floor'),
            value: () => data.floor ? propertyRequestHelper.floor.getFloor(data.floor, t).label : '-',
        },
        'goal': {
            label: t('goal'),
            value: () => data.goal ? propertyRequestHelper.goal.getGoal(data.goal, t).label : '-',
        },
        'expectation': {
            label: t('expectation_/_year'),
            value: () => data.expectation + ' %',
        },
        'type_loan': {
            label: t('loan_type'),
            value: () => data.type_loan ? propertyRequestHelper.loan.getLoanType(data.type_loan, t).label : '-',
        },
        'buying_entity': {
            label: t('buying_entity'),
            value: () => data.buying_entity ? propertyRequestHelper.entity.getEntityType(data.buying_entity, t).label : '-',
        },
        'first_time_investor': {
            label: t('first_time_investor'),
            value: () => data.first_time_investor !== null && data.first_time_investor !== undefined ? data.first_time_investor ? t('yes') : t('no') : '-',
        },
    };

    useEffect(() => {
        let config = [];

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (labelsConfig[key]) {
                config.push(labelsConfig[key]);
            }
        }

        setLabels(config)
    }, [keys, data]);

    return (
        <div className="table-responsive">
            <Table className="table mb-0 table-bordered">
                <tbody>
                {labels && labels.map((item, index) => (
                    <tr key={index}>
                        <th
                            scope="row"
                            style={{ width: "400px" }}
                        >
                            {item.label}
                        </th>
                        <td>{item.value()}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
};