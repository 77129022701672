import {
    FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST,
    FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST_SUCCESS,
    FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST_ERROR,
    CLEAN_SUGGESTED_PROPERTY_PROPERTY_REQUEST,

    SUGGESTED_PROPERTY_PROPERTY_REQUEST_SET_FILTER,
    SUGGESTED_PROPERTY_PROPERTY_REQUEST_RESET_FILTER,

    SUGGESTED_PROPERTY_PROPERTY_REQUEST_SET_SORT,
    SUGGESTED_PROPERTY_PROPERTY_REQUEST_RESET_SORT,

    SUGGESTED_PROPERTY_PROPERTY_REQUEST_SET_PAGE,
} from './actionTypes';

export const fetchSuggestedPropertyPropertyRequest = (id, params) => {
    return {
        type: FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST,
        payload: { id, params }
    }
};

export const fetchSuggestedPropertyPropertyRequestSuccess = (data) => {
    return {
        type: FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST_SUCCESS,
        payload: data,
    }
};

export const fetchSuggestedPropertyPropertyRequestError = (data) => {
    return {
        type: FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST_ERROR,
        payload: data,
    }
};

export const cleanSuggestedPropertyPropertyRequest = () => {
    return { type: CLEAN_SUGGESTED_PROPERTY_PROPERTY_REQUEST };
};


// SET PROPERTY FILTER
export const suggestedPropertyPropertyRequestSetFilter = (data) => {
    return {
        type: SUGGESTED_PROPERTY_PROPERTY_REQUEST_SET_FILTER,
        payload: data,
    }
};

export const suggestedPropertyPropertyRequestResetFilter = () => {
    return { type: SUGGESTED_PROPERTY_PROPERTY_REQUEST_RESET_FILTER };
};


// SET PROPERTY SORTING
export const suggestedPropertyPropertyRequestSetSort = (data) => {
    return {
        type: SUGGESTED_PROPERTY_PROPERTY_REQUEST_SET_SORT,
        payload: data,
    }
};

export const suggestedPropertyPropertyRequestResetSort = () => {
    return { type: SUGGESTED_PROPERTY_PROPERTY_REQUEST_RESET_SORT };
};


// SET PROPERTY PAGE
export const suggestedPropertyPropertyRequestSetPage = (data) => {
    return {
        type: SUGGESTED_PROPERTY_PROPERTY_REQUEST_SET_PAGE,
        payload: data,
    }
};