import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button, Badge } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useTable, useSortBy } from 'react-table';
import { withRouter } from 'react-router-dom';

import { hiddenNumber, formatExpiryDate } from '../../../../../utils/card/format';

const BillingCardsTableComponent = (props) => {
    const { items } = props;

    const data = useMemo(() => {
        return items;
    }, [items]);

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        let config = [{
            Header: props.t('card.card_number'),
            accessor: 'last4',
            id: 'last4',
            Cell: ({ row: { original }, value }) => (
                <>
                    <span className="">{hiddenNumber(value)}</span>
                    {original.default && (
                        <Badge className={'ml-2 font-size-12 badge-soft-primary'} pill>
                            {props.t('default')}
                        </Badge>
                    )}
                </>
            ),
        }, {
            Header: props.t('card.expiry_date'),
            accessor: 'expiry_date',
            id: 'expiry_date',
            Cell: ({ row: { original }}) => {
                return formatExpiryDate(original.exp_month, original.exp_year);
            },
        }, {
            Header: props.t('payment_system'),
            accessor: 'brand',
            id: 'brand',
            Cell: ({ row: { original }, value }) => {
                return <span className={'text-capitalize'}>{value}</span>;
            },
        }, {
            Header: '',
            id: 'action',
            Cell: ({ row: { original } }) => (
                !original.default ? (
                    <div className={'text-right'}>
                        <div className="button-items">
                            <Button
                                color={'primary'}
                                outline
                                className="btn waves-effect waves-light"
                                onClick={() => props.onDefault(original)}
                            >
                                {props.t('default')}
                            </Button>

                            <Button color="light" outline className={'btn waves-effect btn-rounded btn-sm'}
                                    onClick={() => props.onRemove(original)}
                            >
                                <i className={'mdi mdi-close font-size-18 text-danger'} />
                            </Button>
                        </div>
                    </div>
                ) : null
            ),
            disableSortBy: true,
        }];

        setColumns(config);
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [],
            },
            defaultCanSort: false,
            disableSortBy: true, // data.length === 0,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
        },
        useSortBy,
    );

    useEffect(() => {
        props.onSort(sortBy);
    }, [props.onSort, sortBy]);

    return (
        <div className="table-responsive table-centered">
            <Table className={'table-bordered table-part-bordered'} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps({ title: null }))}
                            >
                                <div className={'d-flex'}>
                                    <div className={'d-flex flex-fill'}>
                                        {column.render('Header')}
                                    </div>

                                    <div>
                                        {column.canSort && (
                                            column.isSorted ? (
                                                <i className={'bx font-size-16 align-middle' + (column.isSortedDesc ? " bx-sort-down" : " bx-sort-up")} />
                                            ) : <i className="bx bx-sort align-middle" style={{ color: '#adb5bd' }} />
                                        )}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {(
                    rows.length > 0 && rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                ) || (
                    <tr>
                        <td colSpan={columns.length}>
                            {!props.isLoading ? props.t('empty') : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}} />}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const BillingCardsTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingCardsTableComponent)));