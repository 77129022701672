import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';
import {
    CardNumberElement,
} from '@stripe/react-stripe-js';

import i18n from '../../../../i18n';

import { BILLING_STRIPE_ADD_CARD, BILLING_ADD_PAYMENT_METHOD } from './actionTypes';
import {
    billingStripeAddCardSuccess,
    billingStripeAddCardError,
    billingAddPaymentMethod,
} from './actions';

import { API } from '../../../../api';

function* stripeAddCard({ payload: { stripe, elements, params, onSuccess } }) {
    try {
        // get stripe form
        const cardNumberElement = elements.getElement(CardNumberElement);

        const response = yield call(API.billing.setupIntent);
        const { data } = response;

        const clientSecret = data.data.client_secret;

        const { setupIntent, error } = yield stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: cardNumberElement,
                    // billing_details: { },
                }
            }
        );

        if (error) {
            yield put(billingStripeAddCardError(error.message));
            toastr.error(error.message);
        } else {
            // The card has been verified successfully...
            const payment_method = setupIntent.payment_method;

            const values = {
                payment_method,
                default: params.default,
            };
            yield put(billingAddPaymentMethod(values, onSuccess));
        }
    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(billingStripeAddCardError(message));
        toastr.error(message);
    }
}

function* addPaymentMethod({ payload: { params, onSuccess } }) {
    try {
        const response = yield call(API.billing.addPaymentMethod, params);
        const { data } = response;

        yield put(billingStripeAddCardSuccess(true));
        toastr.success(i18n.t(`billing.card.add.success`));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(billingStripeAddCardError({
            errors,
            message
        }));
    }
}

export function* watchStripeAddCard() {
    yield takeEvery(BILLING_STRIPE_ADD_CARD, stripeAddCard)
}

export function* watchAddPaymentMethod() {
    yield takeEvery(BILLING_ADD_PAYMENT_METHOD, addPaymentMethod)
}

function* addSaga() {
    yield all([
        fork(watchStripeAddCard),
        fork(watchAddPaymentMethod),
    ]);
}

export default addSaga;
