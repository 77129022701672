import _ from 'lodash';

import {
    PROPERTY_INVESTMENT_GOAL_RENT,
    PROPERTY_INVESTMENT_GOAL_PROPERTY_VALUE,
    PROPERTY_INVESTMENT_GOAL_BOTH,
} from '../../consts/property';

export const getGoalOptions = (t) => {
    return [{
        label: t('rent'),
        value: PROPERTY_INVESTMENT_GOAL_RENT,
    }, {
        label: t('property_value'),
        value: PROPERTY_INVESTMENT_GOAL_PROPERTY_VALUE,
    }, {
        label: t('both'),
        value: PROPERTY_INVESTMENT_GOAL_BOTH,
    }];
};

export const getGoal = (value, t) => {
    const options = getGoalOptions(t);
    const item = _.find(options, { value: value });

    return item || {
        label: '',
        value: '',
    };
};

export default {
    getGoalOptions,
    getGoal,
};