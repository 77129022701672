import {
    FETCH_PROPERTY_EDIT_INFO,
    FETCH_PROPERTY_EDIT_INFO_SUCCESS,
    FETCH_PROPERTY_EDIT_INFO_ERROR,
    SET_PROPERTY_COORDINATES,
    REMOVE_PROPERTY_REMOTE_IMAGE,
    CLEAN_PROPERTY_EDIT_PAGE,

    EDIT_PROPERTY,
    EDIT_PROPERTY_SUCCESS,
    EDIT_PROPERTY_ERROR,

    REMOVE_PROPERTY,
    REMOVE_PROPERTY_SUCCESS,
    REMOVE_PROPERTY_ERROR,
} from './actionTypes';

// FETCH PROPERTY FOR EDIT PAGE
export const fetchPropertyEditInfo = (id) => {
    return {
        type: FETCH_PROPERTY_EDIT_INFO,
        payload: { id },
    }
};

export const fetchPropertyEditInfoSuccess = (data) => {
    return {
        type: FETCH_PROPERTY_EDIT_INFO_SUCCESS,
        payload: data,
    }
};

export const fetchPropertyEditInfoError = (data) => {
    return {
        type: FETCH_PROPERTY_EDIT_INFO_ERROR,
        payload: data,
    }
};

export const cleanPropertyEditPage = () => {
    return { type: CLEAN_PROPERTY_EDIT_PAGE };
};

// REMOVE PROPERTY REMOTE IMAGE FOR EDIT PAGE
export const removePropertyRemoteImage = (data) => {
    return {
        type: REMOVE_PROPERTY_REMOTE_IMAGE,
        payload: data,
    }
};

// SET PROPERTY COORDINATE FOR MAP
export const setPropertyCoordinates = (data) => {
    return {
        type: SET_PROPERTY_COORDINATES,
        payload: data,
    }
};

// EDIT PROPERTY
export const editProperty = (id, params, history) => {
    return {
        type: EDIT_PROPERTY,
        payload: { id, params, history },
    }
};

export const editPropertySuccess = (data) => {
    return {
        type: EDIT_PROPERTY_SUCCESS,
        payload: data,
    }
};

export const editPropertyError = (data) => {
    return {
        type: EDIT_PROPERTY_ERROR,
        payload: data,
    }
};

// REMOVE PROPERTY
export const removeProperty = (id, history) => {
    return {
        type: REMOVE_PROPERTY,
        payload: { id, history },
    }
};

export const removePropertySuccess = (data) => {
    return {
        type: REMOVE_PROPERTY_SUCCESS,
        payload: data,
    }
};

export const removePropertyError = (data) => {
    return {
        type: REMOVE_PROPERTY_ERROR,
        payload: data,
    }
};