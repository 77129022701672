import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import { Attachment } from './Attachment';

const AttachmentToolbarComponent = (props) => {

    useEffect(() => {
        return () => {
        }
    }, []);

    return (
        <>
            {props.attachments.length > 0 && (
                <div className="border-top p-3">
                    <div className={'w-100'}>
                        <PerfectScrollbar
                            style={{ height: props.attachments.length > 1 ? "150px" : "70px" }}
                        >
                            {props.attachments.map((item, i) => {
                                return (
                                    <Attachment item={item} key={'chat-attachment-' + item.fileId} />
                                );
                            })}
                        </PerfectScrollbar>
                    </div>
                </div>
            )}
        </>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const AttachmentToolbar = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AttachmentToolbarComponent)));