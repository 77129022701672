import React, { useEffect, useMemo } from 'react';
import { Alert, Button, Row } from "reactstrap";
import { formValueSelector } from 'redux-form'
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { FORM_SIGN_UP } from '../../consts/forms';
import { RegisterForm } from './components';
import { useTitle } from "../../hooks";
import { ROLE_CLIENT } from "../../consts/roles";
import { fetchUser, logoutUser, setLanguage, completeRegisterUser, completeRegisterUserClean } from '../../store/actions';
import { VerifyEmail } from './components';
import { API } from '../../api';
import * as HOC from '../../HOC';
import { LogoIcon } from '../../components/Custom';

const DataShower = HOC.withDataShower();

const DEFAULT_LANGUAGE_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE;

const RegisterCompleteComponent = (props) => {
    const { t, i18n } = useTranslation();
    useTitle(t('title.register.complete'));
    const history = useHistory();

    useEffect(() => {
        // if no user in store -> fetch user
        if (!props.user) {
            props.setLanguage(i18n.language);
            API.setLang(i18n.language);

            props.fetchUser(history);
        }
    }, [props.user]);

    useEffect(() => {
        return () => {
            props.completeRegisterUserClean();
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (values) => {
        let params = {
            ...values,
        };

        if (props.user?.role === ROLE_CLIENT && params.company_name) {
            delete params.company_name;
        }

        props.completeRegisterUser(params);
    };

    const logOut = async () => {
        props.logoutUser(props.history);
    };

    const isRegisterStep = useMemo(() => {
        return props.user && !props.user.filled;
    }, [props.user]);

    const isVerifyStep = useMemo(() => {
        return props.user && props.user.filled && !props.user.email_verified;
    }, [props.user]);

    return (
        <div className="d-flex flex-column h-100">
            <div className="mb-4 mb-md-5">
                <Link to="/" className="auth-logo">
                    <LogoIcon size={"sm"} />
                </Link>
            </div>

            <Row className={'justify-content-end'}>
                <Button color="light" outline className="waves-effect mb-2" onClick={logOut}>
                    <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                    {t('logout')}
                </Button>
            </Row>

            <div className="d-flex flex-column h-100">

                <DataShower
                    isLoading={props.loading}
                    isFailed={props.error}
                    error={t(props.error)}
                    customLoaderClass={'data-shower-loader-center'}
                >

                    {isVerifyStep && (
                        <VerifyEmail email={props.user.email} />
                    )}

                    {isRegisterStep && (
                        <div className="my-auto">
                            <div>
                                <h5 className="text-primary">{t('complete_register_account')}</h5>
                                <p className="text-muted">{t('complete_register.to.continue')} Brikk.</p>
                            </div>

                            <div className="mt-4">
                                {props.completeRegisterError && props.completeRegisterError ? (
                                    <Alert color="danger">
                                        {props.completeRegisterError}
                                    </Alert>
                                ) : null}

                                <RegisterForm
                                    initialValues={{
                                        email: props.user?.email,
                                        name: props.user?.name,
                                        lang: DEFAULT_LANGUAGE_CODE || 'nl',
                                    }}
                                    onSubmit={onSubmit}
                                    loading={props.completeRegisterLoading}
                                    role={props.user?.role}
                                />

                            </div>
                        </div>
                    )}
                </DataShower>
            </div>

            <div className="mt-4 mt-md-5 text-center">
                <p className="mb-0">
                    © {new Date().getFullYear()} Brikk.
                </p>
            </div>
        </div>
    );
};

const selector = formValueSelector(FORM_SIGN_UP);

const mapStateToProps = state => {
    const { data, loading, error } = state.user;

    const { completeRegisterLoading, completeRegisterError } = state.completeRegister;

    const emailValue = selector(state, 'email');
    return {
        user: data,
        loading,
        error,

        completeRegisterLoading,
        completeRegisterError,

        emailValue,
    };
};

export default connect(mapStateToProps, { fetchUser, logoutUser, setLanguage, completeRegisterUser, completeRegisterUserClean })(RegisterCompleteComponent);
