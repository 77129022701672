import axios from 'axios';
import qs from 'qs';

const paramsSerializer = (params) => {
    return qs.stringify(params, { encode: false });
};

class LeadAPI {
    getCompanyLeads = async (params) => {
        return axios.get('api/company/leads', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    getLead = async (id, params) => {
        return axios.get(`api/leads/${id}`, {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    buyLead = async (id) => {
        return axios.post(`api/leads/${id}/buy`);
    };

    declineLead = async (id) => {
        return axios.post(`api/leads/${id}/decline`);
    };

    updateLead = async (id, params) => {
        return axios.put(`api/leads/${id}`, params);
    };

    getLeads = async (params) => {
        return axios.get('api/leads', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    getLeadPreferences = async () => {
        return axios.get('api/profile/preferences');
    };

    updateLeadPreferences = async (params) => {
        return axios.put('api/profile/preferences', params);
    };
}

export const lead = new LeadAPI();