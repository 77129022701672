import {
    FETCH_BILLING_INVOICE,
    FETCH_BILLING_INVOICE_SUCCESS,
    FETCH_BILLING_INVOICE_ERROR,
    CLEAN_BILLING_INVOICE,
} from './actionTypes';

const initialState = {
    invoice: null,
    invoiceLoading: false,
    invoiceError: null,
};

const view = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BILLING_INVOICE:
            state = {
                ...state,
                invoiceLoading: true,
                invoiceError: null,
            };
            break;

        case FETCH_BILLING_INVOICE_SUCCESS:
            state = {
                ...state,
                invoiceLoading: false,
                invoice: action.payload,
            };
            break;

        case FETCH_BILLING_INVOICE_ERROR:
            state = {
                ...state,
                invoiceLoading: false,
                invoiceError: action.payload,
            };
            break;

        case CLEAN_BILLING_INVOICE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default view;