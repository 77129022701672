import {
    FETCH_COMPANY_ACTUAL_UNPAID_LEADS,
    FETCH_COMPANY_ACTUAL_UNPAID_LEADS_SUCCESS,
    FETCH_COMPANY_ACTUAL_UNPAID_LEADS_ERROR,
    CLEAN_COMPANY_ACTUAL_UNPAID_LEADS,
    COMPANY_ACTUAL_UNPAID_LEADS_SET_PAGE,
} from './actionTypes';

export const fetchCompanyActualUnpaidLeads = (params) => {
    return {
        type: FETCH_COMPANY_ACTUAL_UNPAID_LEADS,
        payload: { params }
    }
};

export const fetchCompanyActualUnpaidLeadsSuccess = (data) => {
    return {
        type: FETCH_COMPANY_ACTUAL_UNPAID_LEADS_SUCCESS,
        payload: data,
    }
};

export const fetchCompanyActualUnpaidLeadsError = (data) => {
    return {
        type: FETCH_COMPANY_ACTUAL_UNPAID_LEADS_ERROR,
        payload: data,
    }
};

export const cleanCompanyActualUnpaidLeads = () => {
    return { type: CLEAN_COMPANY_ACTUAL_UNPAID_LEADS };
};

export const companyActualUnpaidLeadsSetPage = (data) => {
    return {
        type: COMPANY_ACTUAL_UNPAID_LEADS_SET_PAGE,
        payload: data,
    }
};