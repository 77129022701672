import {takeEvery, fork, put, all, call} from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from 'i18n';

import {CRM_SYSTEM_ACCOUNT_CREATE} from './actionTypes';
import {
  crmSystemAccountCreateSuccess,
  crmSystemAccountCreateError,
} from './actions';

import {crmSystemListUpdateItem} from '../../list/actions';

import {API} from 'api';

function* create({payload: {id, crm, params, onSuccess, onError}}) {
  try {
    const response = yield call(API.crmSystem.createAccount, id, params);

    const {data} = response;

    if (data.data) {
      yield put(crmSystemAccountCreateSuccess(crm));

      yield put(crmSystemListUpdateItem(id, {...data.data}));

      onSuccess && onSuccess(data.data)

      toastr.success(i18n.t(`account.add.success`));
    } else if (data.hasOwnProperty('success') && !data.success) {
      // if account not found
      const message = i18n.t(data.text) || 'Error';
      yield put(crmSystemAccountCreateSuccess(crm));
      toastr.error(message);
    }
  } catch (error) {
    const message = error.response?.data?.message || 'Server Error';

    yield put(crmSystemAccountCreateError(crm));

    onError && onError(error)

    toastr.error(message);
  }
}

export function* watchCreate() {
  yield takeEvery(CRM_SYSTEM_ACCOUNT_CREATE, create)
}

function* createSaga() {
  yield all([
    fork(watchCreate),
  ]);
}

export default createSaga;