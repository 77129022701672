import qs from "qs"
import moment from "moment"

export const paramsSerializer = params => {
    return qs.stringify(params, { encode: false })
}

export const getMapRequestParams = (params, initialValues = {}) => {
    let requestParams = initialValues;

    if (params.page) {
        requestParams = {
            ...requestParams,
            page: params.page,
        }
    }

    if (params.per_page) {
        requestParams = {
            ...requestParams,
            per_page: params.per_page,
        }
    }

    if (params.sort && Array.isArray(params.sort)) {
        const item = params.sort[0];
        const key = item.id;
        const value = item.desc ? 'desc' : 'asc';

        requestParams = {
            ...requestParams,
            sort: {
                [key]: value,
            },
        };
    }

    if (params.filter) {
        Object.entries(params.filter).forEach(entry => {
            const [key, value] = entry;
            if (value !== null && value !== '') {
                requestParams = {
                    ...requestParams,
                    filter: {
                        ...requestParams.filter,
                    }
                };

                if (['projects', 'cities', 'companies'].includes(key)) {
                    requestParams.filter[key] = value.map(item => item.id);

                } else if (['price'].includes(key)) {
                    requestParams.filter['price_min'] = value.range[0];
                    requestParams.filter['price_max'] = value.range[1];
                    requestParams.filter['empty_price'] = value.empty_price;

                } else if (['property_price'].includes(key)) {
                    requestParams.filter['property_price_min'] = value.range[0];
                    requestParams.filter['property_price_max'] = value.range[1];
                    requestParams.filter['empty_property_price'] = value.empty_price;

                } else if (['new_price'].includes(key)) {
                    requestParams.filter['price_min'] = value[0];
                    requestParams.filter['price_max'] = value[1];

                } else if (['difference'].includes(key)) {
                    requestParams.filter['difference_min'] = value.range[0];
                    requestParams.filter['difference_max'] = value.range[1];
                    requestParams.filter['difference_direction'] = value.direction;

                } else if (['period'].includes(key)) {
                    requestParams.filter['date_start'] = moment(value[0]).format('YYYY-MM-DD');
                    requestParams.filter['date_end'] = moment(value[1]).format('YYYY-MM-DD');

                } else {
                    requestParams.filter[key] = value;
                }
            }
        });
    }

    return requestParams;
};