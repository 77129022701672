import React, { useEffect } from 'react';
import { Row, Card, CardHeader, CardBody, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';

import {
    companyLeadsSetActiveTab,
    cleanLeadListUpdateLeadStatus,
    companyLeadsPreferencesError,
} from '../../../../../store/actions';
import {
    COMPANY_LEADS_TAB_ALL_LEADS,
    COMPANY_LEADS_TAB_MY_LEADS,
    COMPANY_LEADS_TAB_NOT_INTERESTED,
} from '../../../../../consts/lead';
import { CompanyLeadsTabs } from './CompanyLeadsTabs';
import { CompanyActualUnpaidLeads } from './CompanyActualUnpaidLeads';
import { CompanyPreviousUnpaidLeads } from './CompanyPreviousUnpaidLeads';
import { CompanyPaidLeads } from './CompanyPaidLeads';
import { CompanyNotInterestedLeads } from './CompanyNotInterestedLeads';

const tabs = [{
    label: 'all_leads',
    value: COMPANY_LEADS_TAB_ALL_LEADS,
}, {
    label: 'my_leads',
    value: COMPANY_LEADS_TAB_MY_LEADS,
}, {
    label: 'leads.not_interested',
    value: COMPANY_LEADS_TAB_NOT_INTERESTED,
}];

const CompanyLeadsComponent = (props) => {
    useEffect(() => {
        if (props.preferencesError) {
            props.companyLeadsPreferencesError(false);
        }

        return () => {
            props.cleanLeadListUpdateLeadStatus();
        }
    }, []);

    const handleTabChange = (value) => {
        if (props.activeTab !== value) {
            props.companyLeadsSetActiveTab(value);
        }
    };

    return (
        <Card>
            <CardHeader className="bg-transparent">
                <Row className="no-gutters justify-content-between">
                    <h5 className={'mt-2 mr-2'}>{props.t('lead.list')}</h5>
                </Row>
            </CardHeader>
            <CardBody>

                {props.preferencesError ? (
                    <>
                        <Alert color="primary">{props.t('lead.no_preferences_warning')}</Alert>

                        <Link to={'/leads/preferences'} className="btn btn-primary">
                            {props.t('title.brikk_preferences')}
                            <i className="fas fa-angle-right font-size-16 align-middle ml-2"></i>
                        </Link>
                    </>
                ) : (
                    <>
                        <div className={'pb-4'}>
                            <CompanyLeadsTabs
                                tabs={tabs}
                                active={props.activeTab}
                                onChange={handleTabChange}
                            />
                        </div>

                        {props.activeTab === COMPANY_LEADS_TAB_ALL_LEADS && (
                            <>
                                <CompanyActualUnpaidLeads />

                                <CompanyPreviousUnpaidLeads />
                            </>
                        )}

                        {props.activeTab === COMPANY_LEADS_TAB_MY_LEADS && (
                            <CompanyPaidLeads />
                        )}

                        {props.activeTab === COMPANY_LEADS_TAB_NOT_INTERESTED && (
                            <CompanyNotInterestedLeads />
                        )}
                    </>
                )}

            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => {
    const { activeTab, preferencesError } = state.lead.companyLeads.config;
    return {
        activeTab,
        preferencesError,
    };
};

const mapDispatchToProps = {
    companyLeadsSetActiveTab,
    cleanLeadListUpdateLeadStatus,
    companyLeadsPreferencesError,
};

export const CompanyLeads = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompanyLeadsComponent)));