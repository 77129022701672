import React from 'react';
import { withRouter, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom';
import { connect } from "react-redux";

import { BillingPay, BillingCard, Billing, BillingInfo, BillingInvoices, BillingInvoiceDetails } from './'
import { ProtectedRoute } from '../../routes/middleware/ProtectedRoute';
import { ROUTE_MIDDLEWARE_SUBSCRIPTION } from "../../consts/middleware/routes";

const BillingIndexComponent = (props) => {
    let { path } = useRouteMatch();
    const location = useLocation();

    const routes = [{
        path: path,
        component: () => <Redirect to={{ ...location, pathname: `${path}/overview`, }} />,
        exact: true,
    }, {
        path: `${path}/pay`,
        component: BillingPay,
    }, {
        path: `${path}/cards`,
        component: BillingCard,
        // middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION]
    }, {
        path: `${path}/info`,
        component: BillingInfo,
        // middlewares: [ROUTE_MIDDLEWARE_SUBSCRIPTION]
    }, {
        path: `${path}/invoices/:id`,
        component: BillingInvoiceDetails,
    }, {
        path: `${path}/invoices`,
        component: BillingInvoices,
    }, {
        path: `${path}/:tab`,
        component: Billing,
    }];

    return (
        <Switch>
            {routes.map((route, index) => (
                <ProtectedRoute
                    {...route}
                    path={route.path}
                    key={index}
                    middlewares={route.middlewares}
                    component={route.component}
                />
            ))}
        </Switch>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

export const BillingIndex = withRouter(connect(mapStateToProps, {})(BillingIndexComponent));