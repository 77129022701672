import { takeEvery, fork, put, all, select } from 'redux-saga/effects';
import toastr from "toastr";

import { ON_UPDATE_CREDITS_EVENT } from './actionTypes';
import { updateUserCreditsCount } from "../../user/actions";
import i18n from "../../../i18n";

export const userState = (state) => state.user;

function* onUpdateCreditsEvent({ payload: { data } }) {
    const { credits } = data;

    const newCount = Number(credits);

    const user = yield select(userState);
    if (user.data.credits < newCount) {
        // if buy credits -> show message
        toastr.success(i18n.t(`credits.add.success`));
    }

    // update credits count
    yield put(updateUserCreditsCount(newCount));
}

export function* watchUpdateCreditsEvent() {
    yield takeEvery(ON_UPDATE_CREDITS_EVENT, onUpdateCreditsEvent)
}

function* creditsSaga() {
    yield all([
        fork(watchUpdateCreditsEvent),
    ]);
}

export default creditsSaga;
