import {
    FETCH_CHAT_MESSAGES,
    FETCH_CHAT_MESSAGES_SUCCESS,
    FETCH_CHAT_MESSAGES_ERROR,
    CLEAN_CHAT_MESSAGES,

    CHAT_ADD_MESSAGE,
    CHAT_MESSAGES_DELETE_MESSAGE,
    CHAT_MARK_MESSAGES_SEEN,

    CHAT_MARK_RECEIVED_MESSAGES_SEEN,

    CHAT_SEND_CHAT_READ,
} from './actionTypes';

export const fetchChatMessages = (id, params, data = { isMoreLoading: false }) => {
    return {
        type: FETCH_CHAT_MESSAGES,
        payload: { id, params, data },
    }
};

export const fetchChatMessagesSuccess = (data) => {
    return {
        type: FETCH_CHAT_MESSAGES_SUCCESS,
        payload: data,
    }
};

export const fetchChatMessagesError = (data) => {
    return {
        type: FETCH_CHAT_MESSAGES_ERROR,
        payload: data,
    }
};

export const cleanChatMessages = () => {
    return { type: CLEAN_CHAT_MESSAGES };
};

export const chatAddMessage = (data) => {
    return {
        type: CHAT_ADD_MESSAGE,
        payload: data,
    }
};

export const chatMarkMessagesSeen = () => {
    return {
        type: CHAT_MARK_MESSAGES_SEEN,
    }
};

export const chatMessagesDeleteMessage = (id) => {
    return {
        type: CHAT_MESSAGES_DELETE_MESSAGE,
        payload: { id },
    }
};

export const chatMarkReceivedMessagesSeen = () => {
    return {
        type: CHAT_MARK_RECEIVED_MESSAGES_SEEN,
    }
};

export const chatSendChatRead = (userId, chatId = null) => {
    return {
        type: CHAT_SEND_CHAT_READ,
        payload: { userId, chatId },
    }
};