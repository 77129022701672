import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';

import { CHAT_SEND_MESSAGE } from './actionTypes';
import {
    chatSendMessageSuccess,
    chatSendMessageError,
} from './actions';
import { chatAddMessage } from '../messages/actions';
import { chatsAddChat, chatsUpdateChatWithSort } from '../../chats/actions';

import { API } from '../../../../api';
import { createChatItemBasedOnMessage, updateDataChatItem } from '../../../../utils/chat';

export const participantState = (state) => state.chat.conversation.data.participant;
export const chatsListState = (state) => state.chat.chats.list;

function* sendMessage({ payload: { id, params, callback } }) {
    try {
        const response = yield call(API.chat.sendMessage, id, params);

        const { data } = response;
        yield put(chatSendMessageSuccess(true));

        const message = data?.data;

        // update message feed after send message
        const participant = yield select(participantState);
        if (participant?.id === id) {
            yield put(chatAddMessage(message));
        }

        callback();

        // update chat list
        const chats = yield select(chatsListState);
        let chat = _.find(chats, { id: message?.chat_id });

        if (chat) {

            // if exist - update chat item
            let items = updateDataChatItem({...chat}, message);
            yield put(chatsUpdateChatWithSort(chat.id, items));

        } else {

            // add new chat item
            let newChat = createChatItemBasedOnMessage(message, participant);
            yield put(chatsAddChat(newChat));

        }
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(chatSendMessageError({
            errors,
            message
        }));
    }
}

export function* watchSendMessage() {
    yield takeEvery(CHAT_SEND_MESSAGE, sendMessage)
}

function* sendSaga() {
    yield all([
        fork(watchSendMessage),
    ]);
}

export default sendSaga;