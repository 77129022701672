import React, { useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";

import { useTitle } from "../../hooks";
import { BackButton, PageTitle } from '../../components/Custom';
import { BillingPaySection, BillingPaymentInfo, BillingPayBillingInfo } from './components';

const BillingPayComponent = (props) => {
    useTitle(props.t('title.payment'));
    const location = useLocation();

    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <React.Fragment>
            <div className="page-content min-vh-100">
                <Container fluid>

                    <PageTitle title={props.t('title.payment')} />

                    <Row className={'mb-4'}>
                        <Col>
                            <Row className={'no-gutters justify-content-between'}>
                                <BackButton />
                            </Row>
                        </Col>
                    </Row>

                    <BillingPaymentInfo data={location.state?.priceInfo || {}} />

                    <BillingPayBillingInfo />

                    <BillingPaySection />

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user.data,
    };
};

const mapDispatchToProps = {
};

export const BillingPay = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingPayComponent)));