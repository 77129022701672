import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '../../../../../../hooks';

// todo: make single search component or hook
export const ChatSearch = ({
    placeholder = 'search',
    delay = 1000,
    onChange,
    disableInitialCallback = true,
    minLimit = 3,
}) => {
    const { t } = useTranslation();

    const [value, setValue] = useState('');

    const debouncedValue = useDebounce(value, delay);

    const disableInitialStatusRef = useRef(disableInitialCallback);

    useEffect(() => {
        if (!disableInitialStatusRef.current) {
            let searchValue = debouncedValue.trim();

            if (searchValue.length < minLimit) {
                searchValue = null;
            }

            onChange(searchValue);
        }

        disableInitialStatusRef.current = false;
    }, [debouncedValue]);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <div className={'search-box chat-search-box'}>
            <div className="position-relative">
                <input type="text" className="form-control" placeholder={`${t(placeholder)} ...`} onChange={handleChange} value={value} />
                <span className="bx bx-search-alt search-icon" />
            </div>
            {debouncedValue && debouncedValue.length < minLimit && (
                <div className={'search-hint text-danger font-weight-bold mt-1'}>{t('search.hint', { count: minLimit })}</div>
            )}
        </div>
    );
};