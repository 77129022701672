import React, { useState, useEffect, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { Pagination } from '../../../../../components/Custom';
import { ClientsTable } from '../ClientsTable';
import {
    propertyClientsInviteStaffListGetData,
    propertyClientsInviteStaffListCleanState,
} from '../../../../../store/actions';

import * as HOC from '../../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const PropertyInvitedClientsComponent = (props) => {
    const { visible } = props;

    const [page, setPage] = useState(1);

    useEffect(() => {
        if (visible) {
            const params = {
                page,
            };

            props.propertyClientsInviteStaffListGetData(props.role, props.propertyId, params);
        }
    }, [visible, page]);

    const toggleModal = () => {
        props.toggleVisible();
    };

    const onClosed = () => {
        props.propertyClientsInviteStaffListCleanState();
        setPage(1);
    };

    const handlePageChange = (item) => {
        setPage(item.selected + 1);
    };

    const clients = useMemo(() => {
        return props.list.map(item => item?.receiver)
    }, [props.list])

    return (
        <>
            <Modal
                size="lg"
                isOpen={visible}
                toggle={toggleModal}
                onClosed={onClosed}
            >
                <ModalHeader
                    toggle={toggleModal}
                >
                    {props.t('clients')}
                </ModalHeader>

                <ModalBody>
                    <TableDataLoading isLoading={props.loading} isFailed={props.error} error={props.t(props.error)}>
                        <ClientsTable
                            items={clients}
                            isLoading={props.loading}
                        />

                        <Pagination data={props.meta} onPageChange={handlePageChange} />
                    </TableDataLoading>
                </ModalBody>
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError, meta } = state.property.clients.invite.staffList;
    return {
        role: state.user.data.role,

        list,
        loading: listLoading,
        error: listError,
        meta,
    };
};

const mapDispatchToProps = {
    propertyClientsInviteStaffListGetData,
    propertyClientsInviteStaffListCleanState,
};

export const PropertyInvitedClients = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyInvitedClientsComponent));