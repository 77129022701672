import React from 'react';
import { useTranslation } from 'react-i18next';

import './PropertyRibbon.scss';

export const PropertyRibbon = (props) => {
    const { t } = useTranslation();

    return (
        <div className={'property-ribbon property-ribbon_cr_top property-ribbon_cr_left'}>
            <span className={'font-size-14 font-weight-medium'}>
                {t('new')}
            </span>
        </div>
    );
};