import {
    ECHO_ADD_CHANNEL_LISTENER,
    ECHO_LEAVE_CHANNEL,

    ECHO_CHANNEL_ADD_WHISPER_LISTENER,
    ECHO_CHANNEL_WHISPER,
} from './actionTypes';

export function echoAddChannelListener(channel, event, callback) {
    return {
        type: ECHO_ADD_CHANNEL_LISTENER,
        payload: { channel, event, callback, },
    };
}

export function echoLeaveChannel(channel) {
    return {
        type: ECHO_LEAVE_CHANNEL,
        payload: { channel },
    };
}

export function echoChannelAddWhisperListener(channel, event, callback) {
    return {
        type: ECHO_CHANNEL_ADD_WHISPER_LISTENER,
        payload: { channel, event, callback, },
    };
}

export function echoChannelWhisper(channel, event, data) {
    return {
        type: ECHO_CHANNEL_WHISPER,
        payload: { channel, event, data, },
    };
}
