import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST } from './actionTypes';
import {
    fetchSuggestedPropertyPropertyRequestSuccess,
    fetchSuggestedPropertyPropertyRequestError,
} from './actions';

import { API } from '../../../../api';
import { getMapRequestParams } from '../../../../utils/request';

function* fetchProperties({ payload: { id, params } }) {
    try {
        const includes = ['city', 'city.region', 'myReceivedLink', 'myReceivedLink.sender', 'myReceivedLink.sender.company'];

        let requestParams = {
            includes,
        };

        requestParams = getMapRequestParams(params, requestParams);

        const response = yield call(API.propertyRequest.getSuggestedProperty, id, requestParams);

        const { data } = response;
        yield put(fetchSuggestedPropertyPropertyRequestSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchSuggestedPropertyPropertyRequestError(message));
    }
}

export function* watchPropertiesFetch() {
    yield takeLatest(FETCH_SUGGESTED_PROPERTY_PROPERTY_REQUEST, fetchProperties)
}

function* listSaga() {
    yield all([
        fork(watchPropertiesFetch),
    ]);
}

export default listSaga;