import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form'
import { connect } from "react-redux";

import { FORM_LEAD_ASSIGN_CONSULTANT } from "../../../../consts/forms";
import SelectInput from "../../../../components/Common/SelectInput";
import ButtonLoadingIcon from '../../../../components/Common/ButtonLoadingIcon';
import { API } from "../../../../api";
import { required as requiredValidator } from "../../../../utils/validations";

const AssignConsultantFormComponent = (props) => {
    const [consultantsOptions, setConsultantsOptions] = useState([]);

    const fetchConsultants = async () => {
        try {
            const response = await API.consultant.getConsultantList({ pagination: false });
            const { data } = response;

            setConsultantsOptions(data.data)
        } catch (e) {
        }
    };

    useEffect(() => {
        fetchConsultants();

        return () => {};
    }, []);

    return (
        <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="form-horizontal"
            noValidate={true}
        >
            <Field
                name="user"
                label={props.t('field.label.consultant')}
                placeholder={props.t('field.placeholder.consultant')}
                id={'user'}
                component={SelectInput}
                validate={[ requiredValidator ]}
                options={consultantsOptions}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                isDisabled={props.disabled}
            />

            <Row className="justify-content-end">
                <Col lg="10">
                    <div className="button-items">
                        <Button type="submit" color="primary" disabled={props.loading || props.disabled}>
                            <ButtonLoadingIcon loading={props.loading} />
                            {props.t('send')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};

const AssignConsultant = reduxForm({
    form: FORM_LEAD_ASSIGN_CONSULTANT,
    enableReinitialize: true,
})(AssignConsultantFormComponent);

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const AssignConsultantForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AssignConsultant));