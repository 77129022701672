import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { VERIFY_USER } from './actionTypes';
import { verifyUserSuccess, verifyUserError } from './actions';
import { API } from '../../../api'

function* verifyUser({ payload: { params, history } }) {
    try {
        const response = yield call(API.auth.verifyUser, params);

        const { data } = response;
        yield put(verifyUserSuccess(true));

        history.push('/dashboard');
    } catch (error) {
        // const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        // console.log('Verify User -> error -> ', message);

        yield put(verifyUserError(true));
    }
}

export function* watchVerifyUser() {
    yield takeEvery(VERIFY_USER, verifyUser)
}

function* verifySaga() {
    yield all([
        fork(watchVerifyUser),
    ]);
}

export default verifySaga;