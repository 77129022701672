import {
    FETCH_PAYMENT_CARDS,
    FETCH_PAYMENT_CARDS_SUCCESS,
    FETCH_PAYMENT_CARDS_ERROR,
    CLEAN_PAYMENT_CARDS,
    PAYMENT_CARDS_SET_DEFAULT,
} from './actionTypes';

export const fetchPaymentCards = () => {
    return {
        type: FETCH_PAYMENT_CARDS,
    }
};

export const fetchPaymentCardsSuccess = (data) => {
    return {
        type: FETCH_PAYMENT_CARDS_SUCCESS,
        payload: data,
    }
};

export const fetchPaymentCardsError = (data) => {
    return {
        type: FETCH_PAYMENT_CARDS_ERROR,
        payload: data,
    }
};

export const cleanPaymentCards = () => {
    return { type: CLEAN_PAYMENT_CARDS };
};


export const paymentCardsSetDefault = (id) => {
    return {
        type: PAYMENT_CARDS_SET_DEFAULT,
        payload: { id },
    }
};