import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import classnames from "classnames"

import { TariffCard } from './TariffCard';
import { TariffPeriodSwitch } from './TariffPeriodSwitch';
import { SubscriptionSwap } from '../../../Subscription';
import { fetchSubscriptionTariffs, cleanSubscriptionTariffs } from '../../../../../../store/actions';
import {
    SUBSCRIPTION_TARIFF_MONTHLY_PERIOD,
    SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD,
    SUBSCRIPTION_STATUS_ACTIVE,
    SUBSCRIPTION_STATUS_TRIALING,
} from '../../../../../../consts/billing/subscription'
import { BILLING_TYPE_SUBSCRIPTION } from "../../../../../../consts/billing/config";
import { subscriptionHelper } from '../../../../../../helpers';

import './SubscriptionTarifs.scss';

import * as HOC from '../../../../../../HOC';
const DataShower = HOC.withDataShower();

const SubscriptionTariffsComponent = (props) => {
    const history = useHistory();

    const initialPeriodSwitch = useMemo(() => {
        if (props.subscription?.tariff) {
            return subscriptionHelper.period.getConfig(props.subscription.tariff.period, props.t).value;
        }
        return SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD;
    }, [])

    const [period, setPeriod] = useState(initialPeriodSwitch);
    const [selectedTariff, setSelectedTariff] = useState(null);

    useEffect(() => {
        props.fetchSubscriptionTariffs();

        return () => {
            props.cleanSubscriptionTariffs();
        };
    }, []);

    const handleChangePeriod = (value) => {
        setPeriod(value);

        // reset selected tariff
        setSelectedTariff(null);
    };

    const onTariffSelect = (value) => {
        setSelectedTariff(value);
    };

    const onPay = () => {
        if (selectedTariff) {
            history.push('/billing/pay', {
                priceInfo: {
                    price: selectedTariff.price,
                    tariff: selectedTariff,
                    billingType: BILLING_TYPE_SUBSCRIPTION,
                }
            });
        }
    }

    const monthlyTariffs = useMemo(() => {
        if (props.data) {
            return props.data.filter(tariff => tariff.period === SUBSCRIPTION_TARIFF_MONTHLY_PERIOD);
        }
        return [];
    }, [props.data]);

    const annuallyTariffs = useMemo(() => {
        if (props.data) {
            return props.data.filter(tariff => tariff.period === SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD);
        }
        return [];
    }, [props.data]);

    return (
        <Row>
            <Col lg="12">
                <DataShower
                    isLoading={props.loading}
                    isFailed={props.dataError}
                    error={props.dataError}
                    customLoaderClass={'mt-5'}
                >
                    {props.data && (
                        <>
                            <TariffPeriodSwitch period={period} onChange={handleChangePeriod} />

                            <div className={classnames({
                                     'pricing-block__panel': true,
                                     'pricing-block__panel_active': period === SUBSCRIPTION_TARIFF_MONTHLY_PERIOD,
                                     'd-none': period !== SUBSCRIPTION_TARIFF_MONTHLY_PERIOD,
                                 })}
                            >
                                <div className="pricing-block__list">
                                    {monthlyTariffs.map((item, index) => (
                                        <TariffCard
                                            key={'tariff-' + index}
                                            data={item}
                                            onSelect={onTariffSelect}
                                            selected={selectedTariff}
                                        />
                                    ))}
                                </div>
                            </div>

                            <div className={classnames({
                                    'pricing-block__panel': true,
                                    'pricing-block__panel_active': period === SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD,
                                    'd-none': period !== SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD,
                                })}
                            >
                                <div className="pricing-block__list">
                                    {annuallyTariffs.map((item, index) => (
                                        <TariffCard
                                            key={'tariff-' + index}
                                            data={item}
                                            onSelect={onTariffSelect}
                                            selected={selectedTariff}
                                        />
                                    ))}
                                </div>
                            </div>

                            <div className="text-center mb-4 mt-4">
                                {!props.subscription || props.subscription?.status === SUBSCRIPTION_STATUS_TRIALING ? (
                                    <Button type="button" color="success" className={'btn-lg'}
                                            disabled={!selectedTariff}
                                            onClick={onPay}
                                    >
                                        {props.t('pay')}
                                    </Button>
                                ) : props.subscription?.status === SUBSCRIPTION_STATUS_ACTIVE ? (
                                    <SubscriptionSwap tariff={selectedTariff} subscription={props.subscription} />
                                ) : null}
                            </div>
                        </>
                    )}
                </DataShower>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const { data, loading, dataError } = state.billing.subscription.tariff.list;

    return {
        data, loading, dataError,
        subscription: state.user.data?.subscription,
    };
};

const mapDispatchToProps = {
    fetchSubscriptionTariffs,
    cleanSubscriptionTariffs,
};

export const SubscriptionTariffs = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SubscriptionTariffsComponent)));