import {
    CHAT_SEND_MESSAGE,
    CHAT_SEND_MESSAGE_SUCCESS,
    CHAT_SEND_MESSAGE_ERROR,
    CLEAN_CHAT_SEND_MESSAGE,
} from './actionTypes';

export const chatSendMessage = (id, params, callback) => {
    return {
        type: CHAT_SEND_MESSAGE,
        payload: { id, params, callback },
    }
};

export const chatSendMessageSuccess = (data) => {
    return {
        type: CHAT_SEND_MESSAGE_SUCCESS,
        payload: data,
    }
};

export const chatSendMessageError = (data) => {
    return {
        type: CHAT_SEND_MESSAGE_ERROR,
        payload: data,
    }
};

export const cleanChatSendMessage = () => {
    return { type: CLEAN_CHAT_SEND_MESSAGE };
};