import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from 'redux-form';

import { FORM_BILLING_ADD_PAYMENT_CARD } from '../../../../../consts/forms';
import ButtonLoadingIcon from '../../../../../components/Common/ButtonLoadingIcon';
import SwitchField from "../../../../../components/Common/SwitchField";
import { StripCardFields, ValidationErrors } from '../../../../../components/Custom/index';
import { billingStripeAddCard } from '../../../../../store/actions';

const BillingAddCardFormComponent = (props) => {
    const { stripe, elements } = props;

    const [cardCompleteStatus, setCardCompleteStatus] = useState({
        cardNumber: false, cardExpiry: false, cardCvc: false
    });

    useEffect(() => {
        return () => {
        };
    }, []);

    const isDisabled = useMemo(() => {
        if (!stripe || !elements) {
            return true;
        }

        const isCardFilled = Object.keys(cardCompleteStatus).every((key) => cardCompleteStatus[key] === true);
        if (!isCardFilled) {
            return true;
        }

        return props.isDisabled || props.addLoading;
    }, [stripe, elements, cardCompleteStatus, props.isDisabled, props.addLoading]);

    const onSubmit = (values) => {
        if (isDisabled) {
            return;
        }

        const params = {
            ...values,
        }

        props.billingStripeAddCard(stripe, elements, params, props.onSuccess)
    };

    const handleCardFieldsChange = (e) => {
        setCardCompleteStatus({ ...cardCompleteStatus, [e.elementType]: e.complete });
    };

    return (
        <>
            {props.addError && props.addError.errors ? <ValidationErrors data={props.addError} /> : null}

            <form
                onSubmit={props.handleSubmit(onSubmit)}
                className="form-horizontal"
                noValidate={true}
            >

                <StripCardFields
                    handleChange={handleCardFieldsChange}
                />

                <Field
                    name="default"
                    label={props.t('field.label.card_default')}
                    placeholder={props.t('default')}
                    id={'default'}
                    type="checkbox"
                    component={SwitchField}
                />

                <Row className="justify-content-end">
                    <Col lg="10">
                        <div className="button-items">
                            <Button
                                type="button" color="light" outline className="waves-effect"
                                disabled={props.addLoading}
                                onClick={props.onCancel}
                            >
                                {props.t('cancel')}
                            </Button>

                            <Button type="submit" color="primary" disabled={isDisabled || props.addLoading}>
                                <ButtonLoadingIcon loading={props.addLoading} />
                                {props.t('add')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
};

const BillingAddCard = reduxForm({
    form: FORM_BILLING_ADD_PAYMENT_CARD,
    enableReinitialize: true,
})(BillingAddCardFormComponent);

const mapStateToProps = state => {
    const { addLoading, addError } = state.billing.card.add;
    return {
        addLoading,
        addError,
        initialValues: {
            default: false,
        }
    };
};

const mapDispatchToProps = {
    billingStripeAddCard,
};

export const BillingAddCardForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingAddCard)));
