import {
    COMPANY_LEADS_SET_ACTIVE_TAB,
    COMPANY_LEADS_PREFERENCES_STATUS_ERROR,
    COMPANY_LEADS_RESET_CONFIG,
} from './actionTypes';

export const companyLeadsSetActiveTab = (data) => {
    return {
        type: COMPANY_LEADS_SET_ACTIVE_TAB,
        payload: data,
    }
};

export const companyLeadsPreferencesError = (data) => {
    return {
        type: COMPANY_LEADS_PREFERENCES_STATUS_ERROR,
        payload: data,
    }
};

export const companyLeadsResetConfig = (data) => {
    return {
        type: COMPANY_LEADS_RESET_CONFIG,
    }
};