import React from "react";
import {Route, Redirect, withRouter} from "react-router-dom";
import {connect} from 'react-redux';

import {middlewareHelper} from 'helpers/middleware';
import {ROLE_MANAGER, ROLE_CONSULTANT} from '../../consts/roles';
import {ROUTE_MIDDLEWARE_SUBSCRIPTION} from '../../consts/middleware/routes';
import {MIDDLEWARE_PREVIEW_MODE, MIDDLEWARE_PROPERTY_MANAGE} from '../../consts/middleware/general';

const ProtectedRouteComponent = ({
  component: Component,
  path,
  middlewares = [],
  ...rest
}) => {

  const withMiddleware = (middleware) => {
    return middlewares.includes(middleware);
  }

  return (
    <Route {...rest}>
      {withMiddleware(MIDDLEWARE_PREVIEW_MODE) && !middlewareHelper.previewMode.isAllow() ? (
        <Redirect
          to={{pathname: "/dashboard", state: {from: rest.location}}}
        />
      ) : withMiddleware(ROUTE_MIDDLEWARE_SUBSCRIPTION) && !middlewareHelper.subscriptionMiddleware.isAllow(rest.user) ? (
        rest.user.role === ROLE_MANAGER ? (
          // <Redirect to={{pathname: "/billing", state: {from: rest.location}}}/>
          <Redirect to={{pathname: "/dashboard", state: {from: rest.location}}}/>
        ) : (
          <Redirect to={{pathname: "/dashboard", state: {from: rest.location}}}/>
        )
      ) : withMiddleware(MIDDLEWARE_PROPERTY_MANAGE) && !middlewareHelper.propertyMiddleware.manageIsAllow(rest.user) ? (
        <Redirect
          to={{pathname: "/dashboard", state: {from: rest.location}}}
        />
      ) : (
        <Component {...rest} />
      )}
    </Route>
  );
};

const mapStateToProps = state => {
  const {data} = state.user;

  return {
    user: data,
  };
};

export const ProtectedRoute = withRouter(connect(mapStateToProps, {})(ProtectedRouteComponent));