import _ from 'lodash';

export const createChatItemBasedOnMessage = (message, participant, isCurrentChat = false) => {
    const { chat_id, ...messageData } = message;
    const { avatar, id, is_online, name } = participant;

    const isReceived = !messageData.is_my;

    return {
        id: chat_id,
        last_message: messageData,
        participant: {
            avatar,
            id,
            is_online,
            name,
        },
        unread_count: isReceived && !isCurrentChat ? 1 : 0,
    };
};

export const updateDataChatItem = (chat, message = {}, participant = {}) => {
    const { chat_id, ...messageData } = message;
    const isReceived = !messageData.is_my;

    let result = {
        last_message: _.isEmpty(messageData) ? null : messageData,
    };

    if (isReceived) {
        result.participant = participant;
        result.unread_count = chat.unread_count;
    }

    return result;
};