import React from 'react';

export const Size = ({
    width = "14",
    height = "14",
    viewBox = "0 0 14 14",
    color = '#74788D'
}) => (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.83958 0.0173904C9.37244 -0.00247203 9.90599 0.00323556 10.4393 0.00026761C11.1774 -0.000188998 11.9158 -0.00133056 12.6541 0.00985632C12.9134 0.0144224 13.1733 0.0162489 13.4321 0.0363396C13.5365 0.0420472 13.6251 0.105287 13.7041 0.168299C13.8438 0.285876 13.9705 0.445688 13.9684 0.637691C14.0027 1.21073 13.9945 1.78537 13.9988 2.35933C14.0018 3.13556 14.0006 3.91179 13.9942 4.68826C13.9881 4.92569 13.9922 5.16473 13.9616 5.40102C13.9536 5.48618 13.8997 5.57339 13.8155 5.6001C13.7187 5.61083 13.6388 5.5412 13.5652 5.48846C13.2824 5.25582 13.0269 4.99213 12.765 4.73643C12.5358 4.50789 12.3061 4.28005 12.0767 4.05197C11.4235 4.69693 10.774 5.34554 10.1224 5.99233C9.8569 6.25214 9.59458 6.51605 9.31148 6.75692C9.164 6.87769 8.99711 7.00851 8.79552 6.99983C8.54118 7.00577 8.35032 6.81308 8.17293 6.6576C7.87134 6.37336 7.5798 6.0784 7.29967 5.77293C7.16177 5.61311 6.99534 5.43983 7.00059 5.21335C6.98575 5.00194 7.1273 4.82866 7.25355 4.67593C7.53117 4.35333 7.83504 4.05494 8.13252 3.75107C8.73798 3.14332 9.34413 2.53604 9.94662 1.92532C9.51536 1.47831 9.06354 1.05069 8.64506 0.591346C8.5558 0.487239 8.45443 0.386329 8.40512 0.255739C8.37018 0.166701 8.451 0.0865666 8.5268 0.0550607C8.62794 0.024468 8.73501 0.0242395 8.83958 0.0173904Z" fill={color}/>
        <path d="M4.78217 7.15855C4.93445 7.00924 5.16869 6.98207 5.37006 7.01974C5.50658 7.04143 5.59813 7.15421 5.70064 7.23595C6.04789 7.55009 6.3803 7.88045 6.69878 8.22382C6.77869 8.31788 6.87001 8.40418 6.93348 8.51103C7.02366 8.69892 7.01521 8.92928 6.92092 9.11375C6.82252 9.25621 6.69947 9.3795 6.58349 9.50712C5.75543 10.3788 4.8945 11.2185 4.05252 12.0767C4.48401 12.5137 4.92532 12.9415 5.34175 13.3931C5.4333 13.5006 5.53877 13.6027 5.5922 13.736C5.63808 13.847 5.52005 13.9456 5.41937 13.9586C5.14632 13.996 4.86962 13.988 4.59497 13.9949C3.41532 14.0008 2.23568 14.0054 1.05626 13.9874C0.868593 13.9787 0.678188 13.9837 0.493262 13.9465C0.318154 13.8821 0.179117 13.7403 0.0843705 13.5828C0.0295776 13.4972 0.0341437 13.3924 0.0272946 13.2949C0.00788879 12.8691 0.00880184 12.4427 0.00286595 12.0166C0.000126302 11.0651 -0.00375461 10.1137 0.00811718 9.16238C0.0133682 8.97608 0.012683 8.78865 0.0371115 8.60372C0.0451021 8.51559 0.0998952 8.42267 0.188934 8.3987C0.282538 8.3914 0.359705 8.4576 0.431392 8.50874C0.670198 8.70212 0.885716 8.92197 1.10626 9.13567C1.38022 9.40461 1.64962 9.67835 1.92473 9.94638C2.57448 9.30827 3.21624 8.66171 3.86531 8.02268C4.16758 7.73114 4.46506 7.43412 4.78217 7.15855Z" fill={color}/>
    </svg>
);