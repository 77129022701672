import React, { useEffect } from 'react';
import { withRouter, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { connect } from "react-redux";

import { LeadList, Lead, LeadPreferences } from './'
import { ROLE_CONSULTANT, ROLE_MANAGER } from '../../consts/roles';
import { useAllowed } from '../../hooks';
import AllowedRoute from '../../routes/middleware/AllowedRoute';
import { companyLeadsResetConfig } from '../../store/actions';

const PropertyRequestIndexComponent = (props) => {
    let { path } = useRouteMatch();
    let isAllowed = useAllowed([ROLE_CONSULTANT, ROLE_MANAGER], props.user.role);
    let isManagerAllowed = useAllowed([ROLE_MANAGER], props.user.role);
    let isConsultantAllowed = useAllowed([ROLE_CONSULTANT], props.user.role);

    useEffect(() => {
        return () => {
            props.companyLeadsResetConfig();
        };
    }, [])

    return (
        <Switch>
            <AllowedRoute exact path={path} component={LeadList} allowed={isAllowed} />
            <AllowedRoute path={`${path}/preferences`} component={LeadPreferences} allowed={isManagerAllowed} />
            <AllowedRoute path={`${path}/:id`} component={Lead} allowed={isAllowed} />

            <Route>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    const { activeTab } = state.lead.companyLeads;
    return {
        user: data,
        companyLeadsActiveTab: activeTab,
    };
};

const mapDispatchToProps = {
    companyLeadsResetConfig,
}

export const LeadIndex = withRouter(connect(mapStateToProps, mapDispatchToProps)(PropertyRequestIndexComponent));