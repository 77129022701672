import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import { BillingAddCard } from '../BillingAddCard';
import { BillingCardsTable } from './BillingCardsTable';
import { StripeWrapper } from '../../../../../components/Custom';
import {
    fetchPaymentCards,
    cleanPaymentCards,
    billingRemovePaymentCard,
    billingCleanRemovePaymentCard,
    billingSendDefaultPaymentCard,
    billingCleanSendDefaultPaymentCard,
} from '../../../../../store/actions';

import * as HOC from '../../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const BillingCardListComponent = (props) => {
    const [confirmModal, setConfirmModal] = useState(false);
    const [card, setCard] = useState(null);

    const fetchData = async () => {
        props.fetchPaymentCards();
    };

    useEffect(() => {
        fetchData();

        return () => {
            props.cleanPaymentCards();
            props.billingCleanRemovePaymentCard();
            props.billingCleanSendDefaultPaymentCard();
        };
    }, []);

    const handleConfirm = () => {
        setConfirmModal(false);

        if (card) {
            props.billingRemovePaymentCard(card.id, fetchData);
        }

        setCard(null);
    };

    const handleRemove = (item) => {
        setConfirmModal(true);
        setCard(item);
    };

    const handleDefault = (item) => {
        props.billingSendDefaultPaymentCard(item.id);
    };

    return (
        <StripeWrapper>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <CardTitle>{props.t('billing.card_list')}</CardTitle>

                            <BillingAddCard onSuccess={fetchData} />

                            <Row className={'mt-3'}>
                                <Col>
                                    <TableDataLoading
                                        isLoading={props.listLoading || props.removeLoading || props.sendDefaultLoading}
                                        isFailed={props.listError}
                                        error={props.listError}
                                    >
                                        <BillingCardsTable
                                            items={props.list}
                                            isLoading={props.listLoading}
                                            onSort={() => {}}
                                            onRemove={handleRemove}
                                            onDefault={handleDefault}
                                        />
                                    </TableDataLoading>
                                </Col>
                            </Row>

                            {confirmModal && (
                                <SweetAlert
                                    title={props.t('confirm.sure')}
                                    warning
                                    showCancel
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="light"
                                    onConfirm={() => {
                                        handleConfirm();
                                    }}
                                    onCancel={() => {
                                        setConfirmModal(false);
                                        setCard(null);
                                    }}
                                    cancelBtnText={props.t('cancel')}
                                >
                                    {/*{props.t('not.revert')}*/}
                                </SweetAlert>
                            )}

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </StripeWrapper>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError } = state.billing.card.list;
    const { removeLoading } = state.billing.card.remove;
    const { sendDefaultLoading } = state.billing.card.default;

    return {
        list, listLoading, listError,
        removeLoading,
        sendDefaultLoading,
    };
};

const mapDispatchToProps = {
    fetchPaymentCards,
    cleanPaymentCards,
    billingRemovePaymentCard,
    billingCleanRemovePaymentCard,
    billingSendDefaultPaymentCard,
    billingCleanSendDefaultPaymentCard,
};

export const BillingCardList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingCardListComponent)));