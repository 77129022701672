import React from 'react';
import { connect } from "react-redux";
import { Switch, withRouter } from "react-router-dom";

import {
    publicRoutes,
    accountOnboardingRoutes,
} from "../../routes/allRoutes";
import { AuthLayout, BlankLayout } from "../../components/Custom";
import { PublicRoute }  from "../../routes/middleware/PublicRoute";
import { RegisterOnboardingRoute }  from "../../routes/middleware/RegisterOnboardingRoute";

const AuthIndexComponent = (props) => {
    return (
        <AuthLayout>
            <Switch>
                {accountOnboardingRoutes.map((route, idx) => (
                    <RegisterOnboardingRoute
                        path={route.path}
                        layout={BlankLayout}
                        component={route.component}
                        key={idx}
                    />
                ))}

                {publicRoutes.map((route, idx) => (
                    <PublicRoute
                        path={route.path}
                        layout={BlankLayout}
                        component={route.component}
                        key={idx}
                        isLoggedIn={props.isLoggedIn}
                        isOnboarding={props.isOnboarding}
                    />
                ))}
            </Switch>
        </AuthLayout>
    );
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.Login.isLoggedIn,
        isOnboarding: state.Login.isOnboarding,
    };
};

const mapDispatchToProps = {
};

export const AuthIndex = withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthIndexComponent));