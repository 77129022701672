import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { formatNumberToCompact } from "../../../utils/format";
import s from "./CountBadge.module.scss"

export const CountBadge = ({ active, count }) => {

    if (typeof count !== "number") return null

    return (
        <div
            className={classNames(`${s.countBadge}`, {
                [`${s.active}`]: active,
            })}
        >
            {formatNumberToCompact(count)}
        </div>
    )
}

CountBadge.propTypes = {
    active: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
}
