import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Media } from "reactstrap";

import avatar from '../../../../../../assets/images/custom/avatar-placeholder.png';

const HeaderComponent = (props) => {
    useEffect(() => {
        return () => {
        }
    }, []);

    return (
        <div className="py-4 border-bottom">
            <Media>
                <div className="align-self-center mr-3">
                    <img
                        src={props.user?.avatar ? props.user.avatar : avatar}
                        className="avatar-xs rounded-circle avatar-cover"
                    />
                </div>
                <Media body>
                    <h5 className="font-size-15 mt-0 mb-1">
                        {props.user.name}
                    </h5>
                    <p className="text-muted mb-0">
                        <i className="mdi mdi-circle text-success align-middle mr-1" />
                        {props.t('online')}
                    </p>
                </Media>
            </Media>
        </div>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

const mapDispatchToProps = {
};

export const Header = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HeaderComponent)));