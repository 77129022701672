import React, { useState, useEffect, useMemo } from 'react';
import { Table, Badge } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useTable, useSortBy } from 'react-table';
import { withRouter, Link, useHistory } from 'react-router-dom';

import { ROLE_CLIENT, ROLE_MANAGER } from "../../../../consts/roles";
import { PROPERTY_FILTER_INITIAL_VALUES } from "../../../../consts/property";
import { useAllowed } from "../../../../hooks";
import { propertySetFilter } from '../../../../store/actions';

const ProjectsTableComponent = (props) => {
    const { items } = props;

    const history = useHistory();

    let isAllowed = useAllowed([ROLE_CLIENT, ROLE_MANAGER], props.user.role);

    const data = useMemo(() => {
        return items;
    }, [items]);

    const [columns, setColumns] = useState([{
        Header: props.t('name'),
        accessor: 'name',
        id: 'name',
        Cell: ({ row, value }) => {
            const { original } = row;

            return (original.is_default || !isAllowed) ? (
                <div className={'text-dark'}>{value}</div>
            ) : (
                <Link to={`/projects/${original.id}/edit`} className="text-dark text-decoration-underline text-nowrap">{value}</Link>
            );
        },
    }, {
        Header: () => (
            <div className={'w-100 text-center'}>{props.t('properties_count')}</div>
        ),
        accessor: 'properties_count',
        id: 'properties_count',
        style: {
            width: '300px',
        },
        Cell: ({ row, value }) => {
            const { original } = row;

            return (
                <div className={'text-center'}>
                    <Link to={`#`} onClick={(e) => navigateToProperties(e, original)} className="text-dark font-weight-bold">
                        <Badge className={'font-size-12 badge-soft-primary'} pill>
                            {value}
                        </Badge>
                    </Link>
                </div>
            );
        },
    }]);

    const navigateToProperties = (e, data) => {
        e.preventDefault();

        props.propertySetFilter({
            price: {
                ...PROPERTY_FILTER_INITIAL_VALUES.price,
                empty_price: true,
            },
            projects: [data],
        });

        history.push(`/properties`);
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: props.initialSort,
            },
            defaultCanSort: false,
            disableSortBy: data.length === 0,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
        },
        useSortBy,
    );

    useEffect(() => {
        props.onSort(sortBy);
    }, [props.onSort, sortBy]);

    const getColumnStyle = (column) => {
        return { ...column.style};
    };

    return (
        <div className="table-responsive table-centered">
            <Table className={'table-bordered table-part-bordered'} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps({ title: null }))}
                                style={{ ...column.getHeaderProps(column.getSortByToggleProps().style), ...getColumnStyle(column) }}
                            >
                                <div className={'d-flex'}>
                                    <div className={'d-flex flex-fill'}>
                                        {column.render('Header')}
                                    </div>

                                    <div>
                                        {column.canSort && (
                                            column.isSorted ? (
                                                <i className={'bx font-size-16 align-middle' + (column.isSortedDesc ? " bx-sort-down" : " bx-sort-up")} />
                                            ) : <i className="bx bx-sort align-middle" style={{ color: '#adb5bd' }} />
                                        )}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {(
                    rows.length > 0 && rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                ) || (
                    <tr>
                        <td colSpan={columns.length}>
                            {!props.isLoading ? props.t('empty') : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}} />}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};

const mapStateToProps = state => {
    const { sort } = state.project;
    return {
        user: state.user.data,
        initialSort: sort,
    };
};

const mapDispatchToProps = {
    propertySetFilter,
};

export const ProjectsTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectsTableComponent)));