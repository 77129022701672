import React, { useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { OffersFilterForm } from './OffersFilterForm';
import { getOfferStatusConfig } from "../../../../../helpers";
import { getApplyFilterCount } from '../../../../../utils/filter';

const OffersFilterComponent = (props) => {
    const [modalVisible, setModalVisible] = useState(false);

    const initialFormValues = useMemo(() => {
        let values = {
            ...props.filter?.values,
        };

        if (props.filter?.values?.price_status) {
            values.price_status = props.filter.values?.price_status.map(value => getOfferStatusConfig(value, props.t));
        }

        return values;
    }, [props.filter]);

    const showFilter = () => {
        setModalVisible(true);
    };

    const hideFilter = () => {
        setModalVisible(false);
    };

    const toggleFilter = () => {
        setModalVisible(!modalVisible);
    };

    const onSubmit = (values) => {
        let params = {
            ...values,
        };

        if (values.price_status) {
            params.price_status = values.price_status.map(item => item.value);
        }

        props.onApply(params);

        hideFilter();
    };

    const handleReset = () => {
        props.onReset();

        hideFilter();
    };

    const count = useMemo(() => {
        return props.filter?.isActive ? getApplyFilterCount(props.filter?.values) : 0;
    }, [props.filter]);

    return (
        <>
            <Button
                color="primary"
                onClick={showFilter}
            >
                <i className={`font-size-16 align-middle mr-2 bx ` + (count ? `bxs-filter-alt` : 'bx-filter-alt')} />
                {props.t('filter')}
                {count ? ` (${count})` : ''}
            </Button>

            <Modal
                size="lg"
                isOpen={modalVisible}
                toggle={toggleFilter}
            >
                <ModalHeader
                    toggle={toggleFilter}
                >
                    {props.t('filter')}
                </ModalHeader>

                <ModalBody>
                    <OffersFilterForm
                        initialValues={initialFormValues}
                        onSubmit={onSubmit}
                        statusOptions={props.statusOptions}
                        onReset={handleReset}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const OffersFilter = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OffersFilterComponent));