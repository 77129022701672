import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

export const CreditsPickerField = ({
    label,
    id,
    type,
    input,
    meta: { touched, error, warning },
    defaultValue,
    reference,
    children,
    option,
    ...props
}) => {
    const { t } = useTranslation();

    const isSelected = input.checked;

    return (
        <label htmlFor={props.id} className={'pricing-block__credits-card-block' + (isSelected ? ' active' : '')}>
            <div className="pricing-block__credits-card-wrapper">
                <div className="pricing-block__validate-wrapper">
                    <input
                        type="radio"
                        name={props.name}
                        className="pricing-block__radio"
                        id={props.id}
                        {...input}
                        // value={option.credits}
                    />
                    <label className="pricing-block__control-label" htmlFor={props.id} onClick={(e) => { input.onChange(option.credits.toString()); }}>
                        <div className="pricing-block__control-check">
                            <div className="pricing-block__control-check-v"></div>
                        </div>
                    </label>
                </div>
                <div className="pricing-block__credits-card-title">{t('add')} <span className="credits--value">{option.credits}</span> {t('credits_to_account')}</div>
                <div className="pricing-block__credits-card-price">
                    <NumberFormat
                        value={option.pricePerCredit}
                        thousandSeparator={' '}
                        prefix={'€'}
                        displayType={'text'}
                    /> {t('per_credit')}
                </div>

                {option.discount > 0 && (
                    <div className="pricing-block__credits-card-discount">
                        <NumberFormat
                            value={option.discount}
                            thousandSeparator={' '}
                            suffix={'%'}
                            displayType={'text'}
                        /> <span className={'text-lowercase font-weight-normal'}>{t('discount')}</span>
                    </div>
                )}

            </div>
            <div className="pricing-block__credits-card-wrapper">
                <div className="pricing-block__credits-card-sum">
                    <NumberFormat
                        value={option.totalPrice}
                        thousandSeparator={' '}
                        prefix={'€'}
                        displayType={'text'}
                    />
                </div>
            </div>
        </label>
    );
};