import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit, reset } from 'redux-form';
import toastr from 'toastr';
import i18n from '../../../i18n';

import { INVITE_NEW_CLIENT } from './actionTypes';
import {
    inviteNewClientSuccess,
    inviteNewClientError,
} from './actions';

import { INVITE_NEW_CLIENT_FORM } from '../../../consts/forms';

import { API } from '../../../api';

function* sendInvite({ payload: { params, history } }) {
    try {
        const response = yield call(API.referral.createReferral, params);

        const { data } = response;
        yield put(inviteNewClientSuccess(true));

        history.push('/clients');
        // yield put(reset(INVITE_NEW_CLIENT_FORM));

        toastr.success(i18n.t('invite.send.success'));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(inviteNewClientError({
            errors,
            message
        }));

        yield put(stopSubmit(INVITE_NEW_CLIENT_FORM, errors));
    }
}

export function* watchSendInvite() {
    yield takeEvery(INVITE_NEW_CLIENT, sendInvite)
}

function* inviteSaga() {
    yield all([
        fork(watchSendInvite),
    ]);
}

export default inviteSaga;