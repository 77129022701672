import React, {useEffect} from 'react';
import {Link, Redirect, useHistory, useParams, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {useTranslation} from 'react-i18next';
import toastr from "toastr";
import {Alert, Col, Container, Row} from "reactstrap";
import {find} from "lodash"

import {useQuery} from "../../hooks";
import {
  BillingIncompletePayment,
  BillingOverview,
  BillingPortalTabs,
  BillingSubscriptions,
  BillingCredits,
} from './components';
import {
  BILLING_PORTAL_OVERVIEW_ROUTE_TAB,
  BILLING_PORTAL_SUBSCRIPTIONS_ROUTE_TAB,
  BILLING_PORTAL_CREDITS_ROUTE_TAB
} from "../../consts/billing/portal";
import {SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT} from "../../consts/billing/subscription";
import {middlewareHelper} from 'helpers';

const previewMode = middlewareHelper.previewMode.isAllow()

const tabsConfig = [{
  label: 'billing_portal.overview_tab',
  value: BILLING_PORTAL_OVERVIEW_ROUTE_TAB,
}, {
  label: 'billing_portal.subscriptions_tab',
  value: BILLING_PORTAL_SUBSCRIPTIONS_ROUTE_TAB,
}, {
  label: 'billing_portal.credits_tab',
  value: BILLING_PORTAL_CREDITS_ROUTE_TAB,
  hidden: !previewMode,
}];

const tabs = tabsConfig.filter(item => !item.hidden)

const BillingComponent = (props) => {
  const history = useHistory();
  let {tab} = useParams();
  let query = useQuery();
  const {t} = useTranslation();

  useEffect(() => {

    if (!find(tabs, {value: tab})) {
      history.replace({
        ...history.location,
        pathname: '/billing/overview',
      });
    }
    return () => {
    }
  }, [tab])

  if (query.get('success')) {
    const status = query.get('success') === 'true';

    if (status) {
      const message = query.get('message') || t('billing.pay.success');
      toastr.success(message);
    } else {
      const message = query.get('message') || t('billing.pay.error');
      toastr.error(message);
    }

    return <Redirect to={'/billing/overview'}/>
  }

  const onTabChange = (tab) => {
    history.push(`/billing/${tab}`);
  };

  return (
    <React.Fragment>
      <div className="page-content min-vh-100">
        <Container fluid>
          <Row className={'mb-4'}>
            <Col lg="6" xl={'6'}>
              <BillingPortalTabs tabs={tabs} activeTab={tab} onTabChange={onTabChange}/>
            </Col>
            <Col lg="6" xl={'6'}>
              <div className="button-items text-right pt-sm-4 pt-lg-0">
                <Link
                  to={`/billing/info`}
                  className="btn btn-outline-primary waves-effect waves-light"
                >
                  {t('billing_info')}
                </Link>
                <Link
                  to={`/billing/cards`}
                  className="btn btn-outline-primary waves-effect waves-light"
                >
                  {t('title.manage_payment_cards')}
                </Link>
              </div>
            </Col>
          </Row>

          {!props.user.subscription && (
            <Alert color="primary">
              {t('subscription.required.message')}{'.'}
              &nbsp;
              <Link to={'/billing/subscriptions'}
                    className={'text-decoration-underline font-size-14 font-weight-bold text-primary'}
              >
                {t('subscribe')}
              </Link>
            </Alert>
          )}

          {props.user?.subscription?.status === SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT && (
            <BillingIncompletePayment/>
          )}

          {tab === BILLING_PORTAL_OVERVIEW_ROUTE_TAB && <BillingOverview/>}

          {tab === BILLING_PORTAL_SUBSCRIPTIONS_ROUTE_TAB && <BillingSubscriptions/>}

          {tab === BILLING_PORTAL_CREDITS_ROUTE_TAB && <BillingCredits/>}

        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const {data} = state.user;

  return {
    user: data,
  };
};

const mapDispatchToProps = {};

export const Billing = withRouter(connect(mapStateToProps, mapDispatchToProps)(BillingComponent));