import React from 'react';
import { Label, FormFeedback, FormGroup, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import variables from "../../assets/scss/custom-variables.module.scss"
const SelectInput = ({
    label,
    placeholder,
    type,
    input,
    meta: { touched, error, warning },
    defaultValue,
    reference,
    children,
    options,
    filterOption,
    getOptionLabel,
    getOptionValue,
    isOptionSelected,
    ...props
}) => {
    const { t } = useTranslation();
    const showError = touched && Boolean(error);

    const customStyles = {
        control: base => ({
            ...base,
            fontWeight: 400
        }),
        menu: base => ({
            ...base,
            fontWeight: 400
        })
    };

    const onChange = (value) => {
        if (value && props.isMulti && Array.isArray(value) && value.length === 0) {
            return input.onChange(null);
        }

        return input.onChange(value);
    };

    return (
        <FormGroup row className={'mb-4 '}>
            <Label htmlFor={props.id} className={'col-form-label col-lg-2 ' + (showError ? 'text-danger' : '')}>{label}</Label>

            <Col lg="10">
                <Select
                    id={props.id}
                    value={input.value}
                    onChange={(value) => onChange(value)}
                    options={options}
                    placeholder={placeholder}
                    className={'basic-single ' + (showError ? 'is-invalid' : '')}
                    classNamePrefix="select"
                    styles={customStyles}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            text: variables.mainTextColor,
                            primary25: variables.mainBg,
                            primary: variables.mainGreen,
                            primary50: variables.mainGreen
                        },
                    })}
                    noOptionsMessage={() => t('select.no_options')}
                    // filterOption
                    getOptionLabel={(option) => {
                        if (getOptionLabel) {
                            return getOptionLabel(option);
                        } else {
                            return `${option.label}`
                        }
                    }}
                    getOptionValue={(option) => {
                        if (getOptionValue) {
                            return getOptionValue(option);
                        } else {
                            return `${option.value}`
                        }
                    }}
                    // isOptionSelected
                    {...props}
                />

                <FormFeedback invalid={showError ? error : null }>
                    { t(error, props.translateParams ? { ...props.translateParams } : {}) }
                </FormFeedback>
            </Col>
        </FormGroup>
    );
};

export default SelectInput;