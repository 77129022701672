import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Alert, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form'
import { withRouter, useParams, useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import { useTitle } from "../../hooks";
import { FORM_EDIT_PROJECT } from "../../consts/forms";
import TextInput from "../../components/Common/TextInput";
import ButtonLoadingIcon from '../../components/Common/ButtonLoadingIcon';
import { BackButton, PageTitle } from '../../components/Custom';
import {
    required as requiredValidator,
} from "../../utils/validations";
import { fetchProject, cleanProjectPage, editProject, removeProject, removeProjectError, editProjectError } from '../../store/actions';

import * as HOC from '../../HOC';

const DataShower = HOC.withDataShower();

const ProjectEditComponent = (props) => {
    useTitle(props.t('title.project.edit'));

    let { id } = useParams();
    const history = useHistory();

    const [confirmModal, setConfirmModal] = useState(false);

    useEffect(() => {
        props.fetchProject(id);

        return () => {
            props.cleanProjectPage();
        };
    }, []);

    const onSubmit = (values) => {
        props.removeProjectError();
        props.editProject(id, values);
    };

    const onRemove = () => {
        setConfirmModal(true);
    };

    const handleConfirm = () => {
        setConfirmModal(false);
        props.editProjectError();
        props.removeProject(id, history);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('project.edit')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <BackButton />

                                        {props.project && (
                                            <Button
                                                type="button"
                                                color="danger"
                                                disabled={props.editLoading || props.removeLoading}
                                                onClick={() => onRemove()}
                                            >
                                                <ButtonLoadingIcon loading={props.removeLoading} />
                                                {props.t('remove')}
                                            </Button>
                                        )}
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <DataShower isLoading={props.projectLoading} isFailed={props.projectError} error={props.t(props.projectError)}>

                                        {props.editSuccess && props.editSuccess ? <Alert color="success">{props.t(props.editSuccess)}</Alert> : null}
                                        {props.editError && props.editError.message ? <Alert color="danger">{props.editError.message}</Alert> : null}
                                        {props.removeError && props.removeError ? <Alert color="danger">{props.removeError}</Alert> : null}

                                        <form
                                            onSubmit={props.handleSubmit(onSubmit)}
                                            className="form-horizontal"
                                            noValidate={true}
                                        >
                                            <Field
                                                name="name"
                                                label={props.t('field.label.name')}
                                                placeholder={props.t('field.placeholder.name')}
                                                id={'name'}
                                                type="text"
                                                component={TextInput}
                                                validate={[ requiredValidator ]}
                                                row
                                            />

                                            <Row className="justify-content-end">
                                                <Col lg="10">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        disabled={props.editLoading || props.removeLoading}
                                                    >
                                                        <ButtonLoadingIcon loading={props.editLoading} />
                                                        {props.t('save')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </DataShower>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {confirmModal && (
                    <SweetAlert
                        title={props.t('confirm.sure')}
                        warning
                        showCancel
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="light"
                        onConfirm={() => {
                            handleConfirm();
                        }}
                        onCancel={() => {
                            setConfirmModal(false);
                        }}
                        cancelBtnText={props.t('cancel')}
                    >
                        {props.t('not.revert')}
                    </SweetAlert>
                )}

            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const {
        project,
        projectLoading,
        projectError,
        editLoading,
        editSuccess,
        editError,
        removeLoading,
        removeError,
    } = state.project;
    return {
        project,
        projectLoading,
        projectError,
        editLoading,
        editSuccess,
        editError,
        removeLoading,
        removeError,
        initialValues: {
            name: project?.name || '',
        },
    };
};

const mapDispatchToProps = {
    fetchProject,
    cleanProjectPage,
    editProject,
    editProjectError,
    removeProject,
    removeProjectError,
};

const ProjectEditForm = reduxForm({
    form: FORM_EDIT_PROJECT,
    enableReinitialize: true,
})(ProjectEditComponent);

export const ProjectEdit = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectEditForm)));