import {
    FETCH_CHAT_CONTACTS,
    FETCH_CHAT_CONTACTS_SUCCESS,
    FETCH_CHAT_CONTACTS_ERROR,
    CLEAN_CHAT_CONTACTS,
} from './actionTypes';

const initialState = {
    list: [],
    error: null,
    loading: false,
    meta: null,
};

const contacts = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHAT_CONTACTS:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case FETCH_CHAT_CONTACTS_SUCCESS:
            state = {
                ...state,
                loading: false,
                list: action.payload.meta.current_page === 1 ? [...action.payload.data] : [...state.list, ...action.payload.data],
                meta: action.payload.meta,
            };
            break;

        case FETCH_CHAT_CONTACTS_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case CLEAN_CHAT_CONTACTS:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default contacts;