import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Alert } from "reactstrap";

import { ChatListItem } from './ChatListItem';

const ChatListComponent = (props) => {
    return (
        <>
            {props.items.length > 0 ? (
                <ul className="list-unstyled chat-list">
                    {props.items.map(item => (
                        <ChatListItem item={item} key={'chat-item-' + item.id} />
                    ))}
                </ul>
            ) : (
                <Alert color="primary">{props.t('chat.no_chats')}</Alert>
            )}
        </>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
};

export const ChatList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatListComponent)));