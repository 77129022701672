import {
    FETCH_SUBSCRIPTION_TARIFFS,
    FETCH_SUBSCRIPTION_TARIFFS_SUCCESS,
    FETCH_SUBSCRIPTION_TARIFFS_ERROR,
    CLEAN_SUBSCRIPTION_TARIFFS,
} from './actionTypes';

const initialState = {
    data: null,
    loading: false,
    dataError: null,
};

const list = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SUBSCRIPTION_TARIFFS:
            state = {
                ...state,
                loading: true,
                dataError: null,
            };
            break;

        case FETCH_SUBSCRIPTION_TARIFFS_SUCCESS:
            state = {
                ...state,
                loading: false,
                data: action.payload,
            };
            break;

        case FETCH_SUBSCRIPTION_TARIFFS_ERROR:
            state = {
                ...state,
                loading: false,
                dataError: action.payload,
            };
            break;

        case CLEAN_SUBSCRIPTION_TARIFFS:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default list;