import {
    CHAT_SEND_MESSAGE,
    CHAT_SEND_MESSAGE_SUCCESS,
    CHAT_SEND_MESSAGE_ERROR,
    CLEAN_CHAT_SEND_MESSAGE,
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
};

const send = (state = initialState, action) => {
    switch (action.type) {
        case CHAT_SEND_MESSAGE:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case CHAT_SEND_MESSAGE_SUCCESS:
            state = {
                ...state,
                loading: false,
            };
            break;

        case CHAT_SEND_MESSAGE_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case CLEAN_CHAT_SEND_MESSAGE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default send;