import React from 'react';
import { Alert } from "reactstrap";

export const ValidationErrors = ({ data }) => {
    const renderErrors = () => {
        if (data.errors) {
            let errors = Object.values(data.errors);
            errors = errors.flat();

            return errors.map((item, index) => (
                <div key={'validation-' + index}>
                    <span>{`· ${item}`}</span>
                </div>
            ));
        }

        return null;
    };

    return (
        <Alert color="danger" className={'mt-2'}>
            {data.message}

            {renderErrors()}
        </Alert>
    );
};