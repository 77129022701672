import React, { useMemo } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Row, Col, Card, CardTitle, CardBody, Alert } from "reactstrap";

import { ROLE_CONSULTANT, ROLE_MANAGER } from "../../../../../consts/roles";
import { PropertiesTable } from '../PropertiesTable';

import * as HOC from '../../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const TopFavoritePropertiesComponent = (props) => {
    const consultantKeys = ['avatar', 'city', 'address', 'unit', 'favorites_count'];
    const managerKeys = ['avatar', 'city', 'address', 'unit', 'favorites_count', 'invites'];

    const keys = useMemo(() => {
        return props.user.role === ROLE_MANAGER ? managerKeys : consultantKeys;
    }, [props.user.role]);

    const list = useMemo(() => {
        let topList = [];

        if (props.user.role === ROLE_MANAGER) {
            topList = props.data?.top_properties || [];
        } else {
            topList = props.data?.top_linked_properties || [];
        }
        return topList;
    }, [props.data]);

    const emptyAlert = useMemo(() => {
        return !props.loading && list.length === 0;
    }, [props.loading, list]);

    return (
        <Row>
            <Col lg="12">
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">{props.t('top.number.favorite.properties', { number: props.user.role === ROLE_MANAGER ? 5 : 20 })}</CardTitle>

                        <TableDataLoading isLoading={props.loading} isFailed={props.error} error={props.t(props.error)}>
                            {emptyAlert ? (
                                <Alert color="primary">{props.t('top.favorites.property.empty')}</Alert>
                            ) : (
                                <PropertiesTable
                                    keys={keys}
                                    items={list}
                                    onSort={() => {}}
                                    isLoading={props.loading}
                                />
                            )}
                        </TableDataLoading>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user.data,
        loading: state.userData.data.loading,
        data: state.userData.data.data,
        error: state.userData.data.error,
    };
};

const mapDispatchToProps = {
};

export const TopFavoriteProperties = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopFavoritePropertiesComponent)));