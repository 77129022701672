export const FORM_SIGN_IN = 'form/FORM_SIGN_IN';
export const FORM_SIGN_UP = 'form/FORM_SIGN_UP';
export const FORM_FORGOT_PASSWORD = 'form/FORM_FORGOT_PASSWORD';
export const FORM_RESET_PASSWORD = 'form/FORM_RESET_PASSWORD';
export const FORM_ACCOUNT_ACTIVATE = 'form/FORM_ACCOUNT_ACTIVATE';

export const FORM_EDIT_PROFILE = 'form/FORM_EDIT_PROFILE';
export const FORM_CHANGE_PASSWORD = 'form/FORM_CHANGE_PASSWORD';
export const FORM_REMOVE_USER = 'form/FORM_REMOVE_USER';

export const FORM_ADD_PROJECT = 'form/FORM_ADD_PROJECT';
export const FORM_EDIT_PROJECT = 'form/FORM_EDIT_PROJECT';

export const FORM_CREATE_CONSULTANT = 'form/FORM_CREATE_CONSULTANT';
export const FORM_EDIT_CONSULTANT = 'form/FORM_EDIT_CONSULTANT';

export const FORM_PROPERTY_COMMON = 'form/FORM_PROPERTY_COMMON';

export const PROPERTY_INVITE_CLIENT = 'form/PROPERTY_INVITE_CLIENT';
export const PROPERTY_FILTER_FORM = 'form/PROPERTY_FILTER_FORM';

export const INVITES_FILTER_FORM = 'form/INVITES_FILTER_FORM';

export const OFFER_UPDATE_FORM = 'form/OFFER_UPDATE_FORM';

export const CONTACTS_FILTER_FORM = 'form/CONTACTS_FILTER_FORM';
export const USERS_FILTER_FORM = 'form/USERS_FILTER_FORM';
export const OFFERS_FILTER_FORM = 'form/OFFERS_FILTER_FORM';

export const INVITE_NEW_CLIENT_FORM = 'form/INVITE_NEW_CLIENT_FORM';

export const FORM_PROPERTY_PARSER = 'form/PROPERTY_PARSER';

export const FORM_PROPERTY_REQUEST_HOME_WIZARD = 'form/FORM_PROPERTY_REQUEST_HOME_WIZARD';
export const FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD = 'form/FORM_PROPERTY_REQUEST_INVESTMENT_WIZARD';

export const FORM_LEAD_ASSIGN_CONSULTANT = 'form/FORM_LEAD_ASSIGN_CONSULTANT';
export const FORM_LEAD_PROPERTY_FILTER = 'form/FORM_LEAD_PROPERTY_FILTER';
export const FORM_LEAD_PREFERENCES = 'form/FORM_LEAD_PREFERENCES';

export const FORM_SUGGESTED_PROPERTY_FILTER = 'form/FORM_SUGGESTED_PROPERTY_FILTER';

export const FORM_CREDITS_BUY = 'form/FORM_CREDITS_BUY';

export const FORM_BILLING_PAYMENT = 'form/FORM_BILLING_PAYMENT';
export const FORM_BILLING_ADD_PAYMENT_CARD = 'form/FORM_BILLING_ADD_PAYMENT_CARD';

export const FORM_CLIENT_PROPERTIES_FILTER = 'form/FORM_CLIENT_PROPERTIES_FILTER';

export const FORM_BILLING_INFO_EDIT = 'form/FORM_BILLING_INFO_EDIT';

export const FORM_CRM_SYSTEM_ACCOUNT_CREATE_CREDENTIALS = 'form/FORM_CRM_SYSTEM_ACCOUNT_CREATE_CREDENTIALS';
export const FORM_CRM_SYSTEM_ACCOUNT_CREATE_WHISE = 'form/FORM_CRM_SYSTEM_ACCOUNT_CREATE_WHISE';