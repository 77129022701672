import _ from "lodash"

import { CRM_SYSTEM } from "consts/crmSystem"

import whise from "assets/images/custom/crm-systems/logo-whise.svg"

export const getConfigs = () => {
  return [
    {
      name: "Whise",
      alias: CRM_SYSTEM.WHISE,
      logo: whise,
    },
  ]
}

export const getConfig = alias => {
  const options = getConfigs()
  return _.find(options, { alias })
}
