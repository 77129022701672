import {
    FETCH_PROPERTY,
    FETCH_PROPERTY_SUCCESS,
    FETCH_PROPERTY_ERROR,
    CLEAN_PROPERTY_PAGE,

    UPDATE_PROPERTY_INVITE_STATUS,

    PROPERTY_VIEW_SEND_FAVORITE_STATUS,
    PROPERTY_VIEW_UPDATE_FAVORITE,
} from './actionTypes';

// FETCH PROPERTY
export const fetchProperty = (id) => {
    return {
        type: FETCH_PROPERTY,
        payload: { id },
    }
};

export const fetchPropertySuccess = (data) => {
    return {
        type: FETCH_PROPERTY_SUCCESS,
        payload: data,
    }
};

export const fetchPropertyError = (data) => {
    return {
        type: FETCH_PROPERTY_ERROR,
        payload: data,
    }
};

export const cleanPropertyPage = () => {
    return { type: CLEAN_PROPERTY_PAGE };
};


// UPDATE PROPERTY INVITE STATUS BY USER
export const updatePropertyInviteStatus = (data) => {
    return {
        type: UPDATE_PROPERTY_INVITE_STATUS,
        payload: data,
    }
};


// PROPERTY SEND FAVORITE STATUS
export const propertyViewSendFavoriteStatus = (id, params) => {
    return {
        type: PROPERTY_VIEW_SEND_FAVORITE_STATUS,
        payload: { id, params },
    }
};

// PROPERTY UPDATE FAVORITE STATUS
export const propertyViewUpdateFavoriteStatus = (data) => {
    return {
        type: PROPERTY_VIEW_UPDATE_FAVORITE,
        payload: data,
    }
};