import { all } from 'redux-saga/effects';

import offerListsSaga from './lists/saga';

function* offerSaga() {
    yield all([
        offerListsSaga(),
    ])
}

export default offerSaga;