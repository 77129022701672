import {takeEvery, fork, put, all, call} from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from 'i18n';

import {CRM_SYSTEM_SYNC_START} from './actionTypes';
import {
  crmSystemSyncStartSuccess,
  crmSystemSyncStartError,
} from './actions';

import {crmSystemListUpdateItem} from '../../list/actions';

import {API} from 'api';

function* start({payload: {id, crm}}) {
  try {
    const response = yield call(API.crmSystem.startSync, id);

    const {data} = response;

    yield put(crmSystemSyncStartSuccess(crm));

    yield put(crmSystemListUpdateItem(id, {...data.data}));

    toastr.success(i18n.t(`crm_system.sync_start.success`));
  } catch (error) {
    const message = error.response?.data?.message || 'Server Error';

    yield put(crmSystemSyncStartError(crm));

    toastr.error(message);
  }
}

export function* watchStart() {
  yield takeEvery(CRM_SYSTEM_SYNC_START, start)
}

function* startSaga() {
  yield all([
    fork(watchStart),
  ]);
}

export default startSaga;