import { all } from 'redux-saga/effects';

import linkingSaga from './linking/saga';

function* socialSaga() {
    yield all([
        linkingSaga(),
    ])
}

export default socialSaga;