import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";

import LanguageDropdown from '../../components/CommonForBoth/TopbarDropdown/LanguageDropdown'
import { useTitle } from "../../hooks";
import { API } from '../../api';

import * as HOC from '../../HOC';
const DataShower = HOC.withDataShower();

export const TermsComponent = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    useTitle(t('title.terms'));

    const from = location.state?.from?.pathname ? location.state?.from?.pathname : '/';

    const [termsLoading, setTermsLoading] = useState(false);
    const [termsContent, setTermsContent] = useState(null);
    const [termsError, setTermsError] = useState(null);

    const fetchTerms = async () => {
        setTermsLoading(true);
        setTermsError(null);

        try {
            const response = await API.common.getStatic('terms');
            const { data } = response;

            setTermsContent(data.data.content);

            setTermsLoading(false);
        } catch (e) {
            setTermsLoading(false);
            setTermsError('error');
        }
    };

    useEffect(() => {
        fetchTerms();
    }, [props.language]);

    const navigate = (e) => {
        e.preventDefault();
        history.push(from);
    };

    return (
        <React.Fragment>
            <div className="home-btn auth-lang-wrap">
                <LanguageDropdown />
            </div>
            <div className="account-pages my-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={12} lg={12} xl={12}>
                            <Card className="overflow-hidden">
                                <div className="bg-soft-primary">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">{t('term_and_conditions')}</h5>
                                                <Link className="font-weight-medium text-primary" to="#" onClick={navigate}>{from === '/' ? `${t('go_to')} Brikk` : t('go_back')}</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-5">

                                    <DataShower isLoading={termsLoading} isFailed={termsError} error={t(termsError)}>
                                        <div dangerouslySetInnerHTML={{__html: termsContent}} />
                                    </DataShower>

                                </CardBody>
                            </Card>

                            <div className="mt-5 text-center">
                                <Link className="font-weight-medium text-primary" to="/">{t('go_to')} Brikk</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        language: state.common.language,
    };
};

const mapDispatchToProps = {};

export const Terms = withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsComponent));