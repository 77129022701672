import { combineReducers } from "redux";

import payReducer from './pay/reducer';
import carReducer from './card/reducers';
import billingInfoReducer from './info/reducers';
import subscriptionReducer from './subscription/reducers';
import invoiceReducer from './invoice/reducers';

const billingReducer = combineReducers({
    pay: payReducer,
    card: carReducer,
    billingInfo: billingInfoReducer,
    subscription: subscriptionReducer,
    invoice: invoiceReducer,
});

export default billingReducer;