import {
    SET_CHAT_ACTIVE_TAB,
} from './actionTypes';

export const setChatActiveTab = (data) => {
    return {
        type: SET_CHAT_ACTIVE_TAB,
        payload: data,
    }
};

