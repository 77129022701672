import {
    OFFER_STATUS_UNCONFIRMED,
    OFFER_STATUS_CONFIRMED,
    OFFER_STATUS_DECLINED,
} from '../../consts/offer';

export const getOfferStatusOptions = (t) => {
    return [{
        label: t('offer.status.unconfirmed'),
        value: OFFER_STATUS_UNCONFIRMED,
    }, {
        label: t('offer.status.confirmed'),
        value: OFFER_STATUS_CONFIRMED,
    }, {
        label: t('offer.status.declined'),
        value: OFFER_STATUS_DECLINED,
    }];
};

export const getOfferStatusConfig = (status, t) => {
    let data = {
        label: '',
        color: '',
        value: '',
    };
    switch (status) {
        case OFFER_STATUS_UNCONFIRMED:
            data = {
                label: t('offer.status.unconfirmed'),
                value: OFFER_STATUS_UNCONFIRMED,
                color: 'warning',
            };
            break;

        case OFFER_STATUS_CONFIRMED:
            data = {
                label: t('offer.status.confirmed'),
                value: OFFER_STATUS_CONFIRMED,
                color: 'success',
            };
            break;

        case OFFER_STATUS_DECLINED:
            data = {
                label: t('offer.status.declined'),
                value: OFFER_STATUS_DECLINED,
                color: 'secondary',
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};