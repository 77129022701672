import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { useTranslation } from 'react-i18next';

import { UploadItem } from './UploadItem';
import { setFiles, cleanFiles } from '../../../store/upload/property/actions';

const UploadPhotosContainer = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            props.cleanFiles()
        };
    }, []);

    function formatBytes(bytes, decimals = 2){
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    function handleAcceptedFiles(files) {
        let result = files.map(file => ({
            file: file,
            fileId: Math.random(),
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
        }));

        props.setFiles(result);
    }

    return (
        <>
            <Dropzone
                onDrop={acceptedFiles => {
                    handleAcceptedFiles(acceptedFiles)
                }}
                accept={'image/jpeg, image/png'}
            >
                {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                        <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                            </div>
                            <h4>{t('drop.files.label')}</h4>
                        </div>
                    </div>
                )}
            </Dropzone>

            <div
                className="dropzone-previews mt-3"
                id="file-previews"
            >
                {props.photos.map((item, i) => {
                    return (
                        <UploadItem modelId={props.modelId} item={item} key={item.fileId + 'upload-photo'} />
                    );
                })}
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        photos: state.uploadPropertyPhotos.photos,
    };
};

const mapDispatchToProps = {
    setFiles,
    cleanFiles,
};

export const UploadPhotos = connect(mapStateToProps, mapDispatchToProps)(UploadPhotosContainer);