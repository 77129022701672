import React, { useEffect } from 'react';
import { Row, Col, Alert } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link, useParams, } from "react-router-dom";
import { Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next';

// action
import {
    userResetPassword,
    userResetPasswordClean,
} from "../../store/actions";

import { FORM_RESET_PASSWORD } from "../../consts/forms";

import ButtonLoadingIcon from '../../components/Common/ButtonLoadingIcon';
import TextInput from '../../components/Common/TextInput';
import { resetValidation } from "./validations/validate";
import { useQuery, useTitle } from '../../hooks';
import { Header } from './components';

const ResetPasswordComponent = (props) => {
    let query = useQuery();
    const { t } = useTranslation();
    useTitle(t('title.reset.password'));

    let { token } = useParams();

    const { handleSubmit } = props;

    useEffect(() => {
        return () => {
            props.userResetPasswordClean();
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (values) => {
        const params = {
            ...values,
            email: query.get('email'),
            token,
        };
        props.userResetPassword(params, props.history);
    };

    return (
        <div className="d-flex flex-column h-100">
            <Header />

            <div className="my-auto">
                <div>
                    <h5 className="text-primary">{t('reset.password')}</h5>
                    <p></p>
                </div>

                <div className="mt-4">
                    {props.resetError && props.resetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                            {props.resetError}
                        </Alert>
                    ) : null}
                    {props.resetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                            {props.resetSuccessMsg}
                        </Alert>
                    ) : null}

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="form-horizontal mt-4"
                        noValidate={true}
                    >
                        <Field
                            name="password"
                            label={t('field.label.password')}
                            placeholder={t('field.placeholder.password')}
                            id={'password'}
                            type="password"
                            component={TextInput}
                            translateParams={{ count: 8 }}
                        />

                        <Field
                            name="password_confirmation"
                            label={t('field.label.confirm.password')}
                            placeholder={t('field.label.confirm.password')}
                            id={'password_confirmation'}
                            type="password"
                            component={TextInput}
                            translateParams={{ count: 8 }}
                        />

                        <Row className="form-group">
                            <Col className="text-right">
                                <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="submit"
                                    disabled={props.loading}
                                >
                                    <ButtonLoadingIcon loading={props.loading} />
                                    {t('reset')}
                                </button>
                            </Col>
                        </Row>
                    </form>

                    <div className="mt-5 text-center">
                        <p>
                            {t('go.back.to')}{" "}
                            <Link
                                to="/login"
                                className="font-weight-medium text-primary"
                            >
                                {" "}
                                {t('login')}
                            </Link>{" "}
                        </p>
                    </div>
                </div>
            </div>

            <div className="mt-4 mt-md-5 text-center">
                <p className="mb-0">
                    © {new Date().getFullYear()} Brikk.
                </p>
            </div>
        </div>
    );
};

const mapStatetoProps = state => {
    const { resetError, resetSuccessMsg, loading } = state.ResetPassword;
    return { resetError, resetSuccessMsg, loading };
};

const ResetPasswordPage = reduxForm({
    form: FORM_RESET_PASSWORD,
    validate: resetValidation,
})(ResetPasswordComponent);

export default withRouter(
    connect(
        mapStatetoProps,
        {
            userResetPassword,
            userResetPasswordClean,
        })
    (ResetPasswordPage)
);
