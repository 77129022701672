import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import axios from "axios";

import { AttachmentCard } from './AttachmentCard';
import { API } from "../../../../../../../api";
import {
    chatUploadFile,
    chatUploadFileSuccess,
    chatUploadFileError,
    chatRemoveFile,
    chatRemoveFileSuccess,
    chatRemoveFileError,
} from '../../../../../../../store/actions';

const AttachmentComponent = (props) => {

    const [localState, setLocalState] = useState({
        id: null,
        loading: false,
        error: null,
    });

    const requestCancelToken = useRef(null);

    const cancelRequest = () => {
        if (requestCancelToken.current) {
            requestCancelToken.current.cancel("Request canceled.");
        }
    };

    const sendFile = async () => {
        props.chatUploadFile(props.item.fileId);

        setLocalState({
            id: null,
            loading: true,
            error: null,
        });

        cancelRequest();

        requestCancelToken.current = axios.CancelToken.source();

        try {

            const response = await API.chat.sendAttachment(props.item.file, { cancelToken: requestCancelToken.current.token });

            const { data } = response;

            props.chatUploadFileSuccess(props.item.fileId, { id: data.data.id });

            setLocalState({
                ...localState,
                id: data.data.id,
                loading: false,
            });
        } catch (error) {
            props.chatUploadFileError(props.item.fileId);

            if (axios.isCancel(error)) {
                console.log(error.message);
            } else {
                requestCancelToken.current = null;

                const errors = error.response?.data?.errors ? error.response.data.errors : {};
                const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

                setLocalState({
                    ...localState,
                    loading: false,
                    error: {
                        errors,
                        message
                    },
                });
            }
        }
    };

    const deleteFile = async (e) => {
        e.preventDefault();

        props.chatRemoveFile(props.item.fileId);

        setLocalState({
            // id: null,
            loading: true,
            error: null,
        });

        cancelRequest();

        requestCancelToken.current = axios.CancelToken.source();

        try {
            const response = await API.attachment.removeAttachment(localState.id, { cancelToken: requestCancelToken.current.token });

            const { data } = response;

            props.chatRemoveFileSuccess(props.item.fileId);

        } catch (error) {
            props.chatRemoveFileError(props.item.fileId);

            if (axios.isCancel(error)) {
                console.log(error.message);
            } else {
                requestCancelToken.current = null;

                const errors = error.response?.data?.errors ? error.response.data.errors : {};
                const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

                setLocalState({
                    ...localState,
                    loading: false,
                    error: {
                        errors,
                        message
                    },
                });
            }
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        cancelRequest()
    };

    useEffect(() => {
        sendFile();

        return () => {
            cancelRequest();
        }
    }, []);

    return (
        <AttachmentCard
            id={localState.id}
            item={props.item}
            loading={localState.loading}
            error={localState.error}
            onCancel={handleCancel}
            onDelete={deleteFile}
        />
    );
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
    chatUploadFile,
    chatUploadFileSuccess,
    chatUploadFileError,
    chatRemoveFile,
    chatRemoveFileSuccess,
    chatRemoveFileError,
};

export const Attachment = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AttachmentComponent)));