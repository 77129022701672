import {
    CREDIT_PRICE,
    CREDITS_BUY_VALUE_MIN,
    CREDITS_BUY_VALUE_MAX,
    CREDITS_BUY_AMOUNT_STEP_1,
    CREDITS_BUY_AMOUNT_STEP_2,
    CREDITS_BUY_AMOUNT_STEP_3,
    CREDITS_BUY_AMOUNT_STEP_4,
} from '../../consts/credits';

const getPricePerCredit = (discount) => {
    return CREDIT_PRICE * (1 - discount / 100);
};

export const getPriceInfo = (amount) => {
    let discount = 0;
    let data = {
        pricePerCredit: CREDIT_PRICE,
        discount: discount,
    };

    if (amount < CREDITS_BUY_AMOUNT_STEP_2) {
        data = {
            pricePerCredit: CREDIT_PRICE,
            discount: discount,
        };
    } else if (amount < CREDITS_BUY_AMOUNT_STEP_3) {
        discount = 10;
        data = {
            pricePerCredit: getPricePerCredit(discount),
            discount: discount,
        };
    } else if (amount < CREDITS_BUY_AMOUNT_STEP_4) {
        discount = 20;
        data = {
            pricePerCredit: getPricePerCredit(discount),
            discount: discount,
        };
    } else if (amount >= CREDITS_BUY_AMOUNT_STEP_4) {
        discount = 30;
        data = {
            pricePerCredit: getPricePerCredit(discount),
            discount: discount,
        };
    }

    return data;
};

export const getPickerData = () => ([
    { credits: 15, discount: 0, pricePerCredit: 10, totalPrice: 150 },
    { credits: 30, discount: 10, pricePerCredit: 9, totalPrice: 270 },
    { credits: 60, discount: 20, pricePerCredit: 8, totalPrice: 480 },
    { credits: 120, discount: 30, pricePerCredit: 7, totalPrice: 840 },
]);

export default {
    getPriceInfo,
    getPickerData,
};