import {
    PROPERTY_REQUEST_CHECK_AVAILABLE,
    PROPERTY_REQUEST_CHECK_AVAILABLE_SUCCESS,
    PROPERTY_REQUEST_CHECK_AVAILABLE_ERROR,
    CLEAN_PROPERTY_REQUEST_CHECK_AVAILABLE,
} from './actionTypes';

const initialState = {
    loading: false,
    data: null,
    available: null,
    checkError: null,
};

const check = (state = initialState, action) => {
    switch (action.type) {
        case PROPERTY_REQUEST_CHECK_AVAILABLE:
            state = {
                ...state,
                loading: true,
                data: null,
                checkError: null,
                available: null,
            };
            break;

        case PROPERTY_REQUEST_CHECK_AVAILABLE_SUCCESS:
            state = {
                ...state,
                loading: false,
                data: action.payload,
                available: true,
            };
            break;

        case PROPERTY_REQUEST_CHECK_AVAILABLE_ERROR:
            state = {
                ...state,
                loading: false,
                checkError: action.payload.error,
                available: action.payload.available,
            };
            break;

        case CLEAN_PROPERTY_REQUEST_CHECK_AVAILABLE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default check;