import { combineReducers } from "redux";

import tariffReducer from './tariff/reducers';
import paymentLinkReducer from './paymentLink/reducer';
import reactionsReducer from './reactions/reducers';

const subscriptionReducer = combineReducers({
    tariff: tariffReducer,
    paymentLink: paymentLinkReducer,
    reactions: reactionsReducer,
});

export default subscriptionReducer;