import React from 'react';
import { FormGroup, Col, Nav, NavItem, NavLink } from "reactstrap";
import { useTranslation } from 'react-i18next';
import classnames from "classnames"

export const BillingPaymentTypeNav = (props) => {
    const { data, active, onChange } = props;

    const { t } = useTranslation();

    return (
        <FormGroup row className={'mb-4'}>
            <Col lg={'10'} className={'offset-lg-2'}>

                <Nav pills role="tablist">
                    {data.map((item) => {
                        return (
                            <NavItem key={item.value}>
                                <NavLink
                                    className={classnames({
                                        active: active === item.value,
                                    })}
                                    onClick={() => {
                                        onChange(item.value)
                                    }}
                                >
                                    {t(item.label)}
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>

            </Col>
        </FormGroup>
    );
};