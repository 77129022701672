import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import { cancelBillingSubscription, cleanCancelBillingSubscription } from '../../../../../store/actions';
import ButtonLoadingIcon from '../../../../../components/Common/ButtonLoadingIcon';

const SubscriptionCancelComponent = (props) => {
    const [confirmModal, setConfirmModal] = useState(false);

    useEffect(() => {
        return () => {
            props.cleanCancelBillingSubscription();
        };
    }, []);

    const onCancel = () => {
        setConfirmModal(true);
    };

    const handleConfirm = () => {
        setConfirmModal(false);
        props.cancelBillingSubscription();
    };

    return (
        <div>
            <Button
                color={'primary'}
                outline
                className="btn-sm waves-effect waves-light"
                disabled={props.cancelLoading}
                onClick={onCancel}
            >
                <ButtonLoadingIcon loading={props.cancelLoading} />
                {props.t('cancel')}
            </Button>

            {confirmModal && (
                <SweetAlert
                    title={props.t('confirm.sure')}
                    warning
                    showCancel
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    onConfirm={() => {
                        handleConfirm();
                    }}
                    onCancel={() => {
                        setConfirmModal(false);
                    }}
                    cancelBtnText={props.t('cancel')}
                >
                </SweetAlert>
            )}
        </div>
    );
};


const mapStateToProps = state => {
    const { cancelLoading } = state.billing.subscription.reactions.cancel;

    return {
        cancelLoading,
    };
};

const mapDispatchToProps = {
    cancelBillingSubscription,
    cleanCancelBillingSubscription,
};

export const SubscriptionCancel = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SubscriptionCancelComponent)));