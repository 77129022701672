import React, { useState } from 'react';
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/thumbs/thumbs.scss';

import './SwiperCarousel.scss';

import { ImageSlide } from './ImageSlide';
import { SwiperNavigationButton } from '../../../Swiper';

// install Swiper modules
SwiperCore.use([Navigation, Thumbs]);

export const SwiperCarousel = ({ items, onSwiper, onChange, onItemClick, thumbs }) => {
    return (
        <Swiper
            thumbs={thumbs}
            slidesPerView={1}
            navigation={{
                nextEl: '.carousel-navigation__button_direction_next', // todo: make ref to component
                prevEl: '.carousel-navigation__button_direction_prev', // todo: make ref to component
            }}
            loop={true}
            allowTouchMove={true} // enable/disable swipe
            onSlideChange={() => onChange()}
            onSwiper={(swiper) => onSwiper(swiper)}
        >
            {items.map((item, index) => (
                <SwiperSlide key={item.src + '-' + index}>
                    <ImageSlide item={item} onClick={onItemClick} />
                </SwiperSlide>
            ))}

            <SwiperNavigationButton direction={'prev'} />
            <SwiperNavigationButton direction={'next'} />
        </Swiper>
    );
};