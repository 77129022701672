import {
    PROPERTY_REQUEST_STATUS_UNCONFIRMED,
    PROPERTY_REQUEST_STATUS_ACTIVE,
    PROPERTY_REQUEST_STATUS_EXPIRED,
    PROPERTY_REQUEST_STATUS_CLOSED,
} from '../../consts/propertyRequest';

export const getStatus = (value, t) => {
    let data = {
        label: '',
        value: '',
        color: '',
    };
    switch (value) {
        case PROPERTY_REQUEST_STATUS_UNCONFIRMED:
            data = {
                label: t('property_request.status.unconfirmed'),
                value: PROPERTY_REQUEST_STATUS_UNCONFIRMED,
                color: 'warning'
            };
            break;

        case PROPERTY_REQUEST_STATUS_ACTIVE:
            data = {
                label: t('property_request.status.active'),
                value: PROPERTY_REQUEST_STATUS_ACTIVE,
                color: 'success'
            };
            break;

        case PROPERTY_REQUEST_STATUS_EXPIRED:
            data = {
                label: t('property_request.status.expired'),
                value: PROPERTY_REQUEST_STATUS_EXPIRED,
                color: 'secondary'
            };
            break;

        case PROPERTY_REQUEST_STATUS_CLOSED:
            data = {
                label: t('property_request.status.closed'),
                value: PROPERTY_REQUEST_STATUS_CLOSED,
                color: 'secondary'
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};

export default {
    getStatus,
};