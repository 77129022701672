import { VERIFY_USER, VERIFY_USER_SUCCESS, VERIFY_USER_ERROR, VERIFY_USER_CLEAN } from './actionTypes';

export const verifyUser = (params, history) => {
    return {
        type: VERIFY_USER,
        payload: { params, history }
    }
};

export const verifyUserSuccess = (data) => {
    return {
        type: VERIFY_USER_SUCCESS,
        payload: data,
    }
};

export const verifyUserError = (error) => {
    return {
        type: VERIFY_USER_ERROR,
        payload: error,
    }
};

export const verifyUserClean = () => {
    return {
        type: VERIFY_USER_CLEAN,
    };
};