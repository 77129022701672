import { all } from 'redux-saga/effects';

import CommonSaga from './common/saga';
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ResetPasswordSaga from './auth/resetpwd/saga';
import LayoutSaga from './layout/saga';
import VerifySaga from './auth/verify/saga';
import AccountActivationSaga from './auth/activation/saga';
import completeRegisterSaga from './auth/complete/saga';

import userSaga from './user/saga';
import profileSaga from './profile/saga';
import projectSaga from './project/saga';
import consultantSaga from './consultant/saga';
import propertySaga from './property/sagas';
import inviteSaga from './invite/sagas';
import clientSagas from './client/sagas';
import contactSagas from './contact/sagas';
import offerSagas from './offer/sagas';
import statisticsSagas from './statistics/sagas';
import userDataSagas from './userData/sagas';
import socialSagas from './social/sagas';
import chatSagas from './chat/sagas';
import socketSagas from './socket/sagas';
import propertyRequestSagas from './propertyRequest/sagas';
import leadSagas from './lead/sagas';
import billingSagas from './billing/sagas';
import staticSagas from './static/sagas';
import crmSystemSagas from './crmSystem/sagas';

export default function* rootSaga() {
    yield all([
        CommonSaga(),
        AccountSaga(),
        AuthSaga(),
        ForgetSaga(),
        ResetPasswordSaga(),
        LayoutSaga(),
        VerifySaga(),
        AccountActivationSaga(),
        completeRegisterSaga(),

        userSaga(),
        profileSaga(),
        projectSaga(),
        consultantSaga(),
        propertySaga(),
        inviteSaga(),
        clientSagas(),
        contactSagas(),
        offerSagas(),
        statisticsSagas(),
        userDataSagas(),
        socialSagas(),
        chatSagas(),
        socketSagas(),
        propertyRequestSagas(),
        leadSagas(),
        billingSagas(),
        staticSagas(),
        crmSystemSagas(),
    ])
}