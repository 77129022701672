import axios from 'axios';
import qs from 'qs';

const paramsSerializer = (params) => {
    return qs.stringify(params, { encode: false });
};

class PropertyRequestAPI {
    getPropertyRequestList = async (params) => {
        return axios.get('api/property-ads', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    getPropertyRequest = async (id, params) => {
        return axios.get(`api/property-ads/${id}`, { params });
    };

    createPropertyRequest = async (params) => {
        return axios.post('api/property-ads', params);
    };

    closePropertyRequest = async (id) => {
        return axios.post(`api/property-ads/${id}/close`);
    };

    getSuggestedProperty = async (id, params) => {
        return axios.get(`api/property-ads/${id}/properties`, {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    checkAvailableCreatePropertyRequest = async () => {
        return axios.post(`api/property-ads/ping`);
    };
}

export const propertyRequest = new PropertyRequestAPI();
