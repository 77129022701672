export const FETCH_PROPERTY_LIST = 'FETCH_PROPERTY_LIST';
export const TRIGGER_FETCH_PROPERTY_LIST = 'TRIGGER_FETCH_PROPERTY_LIST';
export const FETCH_PROPERTY_LIST_SUCCESS = 'FETCH_PROPERTY_LIST_SUCCESS';
export const FETCH_PROPERTY_LIST_ERROR = 'FETCH_PROPERTY_LIST_ERROR';
export const CLEAN_PROPERTY_LIST_PAGE = 'CLEAN_PROPERTY_LIST_PAGE';

export const PROPERTY_SET_FILTER = 'PROPERTY_SET_FILTER';
export const PROPERTY_RESET_FILTER = 'PROPERTY_RESET_FILTER';

export const PROPERTY_SET_SORT = 'PROPERTY_SET_SORT';
export const PROPERTY_RESET_SORT = 'PROPERTY_RESET_SORT';

export const PROPERTY_SET_PAGE = 'PROPERTY_SET_PAGE';

export const PROPERTY_LIST_SEND_FAVORITE_STATUS = 'PROPERTY_LIST_SEND_FAVORITE_STATUS';

export const PROPERTY_LIST_UPDATE_ITEM = 'PROPERTY_LIST_UPDATE_ITEM';