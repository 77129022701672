import {
    LINK_SOCIAL_ACCOUNT,
    LINK_SOCIAL_ACCOUNT_SUCCESS,
    LINK_SOCIAL_ACCOUNT_ERROR,

    CLEAN_SOCIAL_ACCOUNT_STATE,

    UNLINK_SOCIAL_ACCOUNT,
    UNLINK_SOCIAL_ACCOUNT_SUCCESS,
    UNLINK_SOCIAL_ACCOUNT_ERROR,
} from './actionTypes';

// LINK SOCIAL ACCOUNT
export const linkSocialAccount = (params) => {
    return {
        type: LINK_SOCIAL_ACCOUNT,
        payload: { params },
    }
};

export const linkSocialAccountSuccess = (data) => {
    return {
        type: LINK_SOCIAL_ACCOUNT_SUCCESS,
        payload: data,
    }
};

export const linkSocialAccountError = (error, provider) => {
    return {
        type: LINK_SOCIAL_ACCOUNT_ERROR,
        payload: { error, provider },
    }
};

export const cleanSocialAccountState = () => {
    return { type: CLEAN_SOCIAL_ACCOUNT_STATE };
};


// UNLINK SOCIAL ACCOUNT
export const unlinkSocialAccount = (id, provider) => {
    return {
        type: UNLINK_SOCIAL_ACCOUNT,
        payload: { id, provider },
    }
};

export const unlinkSocialAccountSuccess = (data) => {
    return {
        type: UNLINK_SOCIAL_ACCOUNT_SUCCESS,
        payload: data,
    }
};

export const unlinkSocialAccountError = (error, provider) => {
    return {
        type: UNLINK_SOCIAL_ACCOUNT_ERROR,
        payload: { error, provider },
    }
};