import {
    SUBSCRIPTION_TARIFF_ALIAS_BASIC,
    SUBSCRIPTION_TARIFF_ALIAS_PROFESSIONAL,
    SUBSCRIPTION_TARIFF_ALIAS_ENTERPRISE,
    SUBSCRIPTION_TARIFF_MONTHLY_PERIOD,
    SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD,
} from '../../consts/billing/subscription';

export const compareTariff = (tariff, current) => {
    let result = {
        isDowngrade: false,
        isUpgrade: false,
    };

    if (current.id === tariff.id) {
        return result;
    }

    // if is upgrade
    if (current.period === SUBSCRIPTION_TARIFF_MONTHLY_PERIOD && tariff.period === SUBSCRIPTION_TARIFF_ANNUALLY_PERIOD || (
            current.period === tariff.period && (
                current.alias === SUBSCRIPTION_TARIFF_ALIAS_BASIC && tariff.alias !== SUBSCRIPTION_TARIFF_ALIAS_BASIC ||
                current.alias === SUBSCRIPTION_TARIFF_ALIAS_PROFESSIONAL && tariff.alias === SUBSCRIPTION_TARIFF_ALIAS_ENTERPRISE
            )
        )
    ) {
        result = {
            isDowngrade: false,
            isUpgrade: true,
        }
    } else {
        // is downgrade
        result = {
            isDowngrade: true,
            isUpgrade: false,
        }
    }

    return result;
}

export default {
    compareTariff,
};