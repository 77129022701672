import {
    FETCH_CLIENT_ACCEPTED_PROPERTIES,
    FETCH_CLIENT_ACCEPTED_PROPERTIES_SUCCESS,
    FETCH_CLIENT_ACCEPTED_PROPERTIES_ERROR,
    CLEAN_CLIENT_ACCEPTED_PROPERTIES,

    CLIENT_ACCEPTED_PROPERTIES_SET_FILTER,
    CLIENT_ACCEPTED_PROPERTIES_RESET_FILTER,

    CLIENT_ACCEPTED_PROPERTIES_SET_SORT,
    CLIENT_ACCEPTED_PROPERTIES_RESET_SORT,

    CLIENT_ACCEPTED_PROPERTIES_SET_PAGE,

    CLIENT_ACCEPTED_PROPERTIES_UPDATE_ITEM, CLIENT_ACCEPTED_PROPERTIES_SET_SEARCH,
} from './actionTypes';

export const fetchClientAcceptedProperties = (params) => {
    return {
        type: FETCH_CLIENT_ACCEPTED_PROPERTIES,
        payload: { params }
    }
};

export const fetchClientAcceptedPropertiesSuccess = (data) => {
    return {
        type: FETCH_CLIENT_ACCEPTED_PROPERTIES_SUCCESS,
        payload: data,
    }
};

export const fetchClientAcceptedPropertiesError = (data) => {
    return {
        type: FETCH_CLIENT_ACCEPTED_PROPERTIES_ERROR,
        payload: data,
    }
};

export const cleanClientAcceptedProperties = () => {
    return { type: CLEAN_CLIENT_ACCEPTED_PROPERTIES };
};


// FILTER
export const clientAcceptedPropertiesSetFilter = (data) => {
    return {
        type: CLIENT_ACCEPTED_PROPERTIES_SET_FILTER,
        payload: data,
    }
};

export const clientAcceptedPropertiesResetFilter = () => {
    return { type: CLIENT_ACCEPTED_PROPERTIES_RESET_FILTER };
};

// SORTING
export const clientAcceptedPropertiesSetSort = (data) => {
    return {
        type: CLIENT_ACCEPTED_PROPERTIES_SET_SORT,
        payload: data,
    }
};

export const clientAcceptedPropertiesResetSort = () => {
    return { type: CLIENT_ACCEPTED_PROPERTIES_RESET_SORT };
};


// PAGE
export const clientAcceptedPropertiesSetPage = (data) => {
    return {
        type: CLIENT_ACCEPTED_PROPERTIES_SET_PAGE,
        payload: data,
    }
};

// UPDATE ITEM IN LIST
export const clientAcceptedPropertiesUpdateItem = (id, data) => {
    return {
        type: CLIENT_ACCEPTED_PROPERTIES_UPDATE_ITEM,
        payload: { id, data },
    }
};


//SET SEARCH
export const clientAcceptedPropertiesSetSearch = (data) => {
    return {
        type: CLIENT_ACCEPTED_PROPERTIES_SET_SEARCH,
        payload: data,
    }
};