import React  from 'react';

export const ImageSlide = ({ item, onClick }) => {
    return (
        <a href="#" onClick={(e) => onClick(e, item.id)}>
            <div style={{ backgroundImage: `url("${item.src}")` }}
                 className="property-gallery-carousel__image"
            />
        </a>
    );
};