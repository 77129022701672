import React, {useState} from 'react';

import {AccountCreateCredentials} from "../AccountCreateCredentials"

export const BasicProvider = (props) => {
  const {component: Component, ...restProps} = props;

  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => {
    setModalVisible(true)
  }

  const toggleModal = () => setModalVisible(!modalVisible)

  const onAccountCreate = () => {
    showModal()
  }

  return (
    <>
      <Component onAccountCreate={onAccountCreate} {...restProps} />

      <AccountCreateCredentials
        id={props.data.id}
        crm={props.crm}
        visible={modalVisible}
        toggleVisible={toggleModal}
      />
    </>
  );
};