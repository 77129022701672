import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { useTitle } from "../../../../../hooks";
import { PageTitle } from '../../../../../components/Custom';
import { SubscriptionTariffs } from './SubscriptionTariffs';

const BillingSubscriptionsComponent = (props) => {
    useTitle(props.t('billing_portal.subscriptions_tab'));

    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <React.Fragment>

            <PageTitle title={props.t('billing_portal.subscriptions_tab')} />

            <SubscriptionTariffs />
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export const BillingSubscriptions = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingSubscriptionsComponent)));