import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from "redux-form";

import { API } from "../../../api";
import SelectInput from '../../Common/SelectInput';
import { formatExpiryDate, hiddenNumber } from '../../../utils/card/format';

const PaymentCardSelect = ({
    ...props
}) => {
    const { t } = useTranslation();

    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchOptions = async (searchValue) => {
        setIsLoading(true);
        try {
            const response = await API.billing.getPaymentMethods();

            const { data } = response;

            const result = data.data.map(item => {
                return {
                    label: item,
                    value: item,
                }
            });

            setIsLoading(false);
            setOptions(result);

            return { data: data.data, options: result };
        } catch (e) {
            setIsLoading(false);
        }
    };

    const initSelect = async () => {
        const { options } = await fetchOptions();

        if (!!options.length) {
            const defaultItem = options.find(item => {
                return item?.value?.default;
            });

            if (defaultItem) {
                props.input.onChange(defaultItem);
            }
        }

    };

    useEffect(() => {
        initSelect();

        return () => {
        };
    }, []);

    const formatOptionLabel = ({ value, label }) => (
        <div className={'payment-label'}>
            <div className={'payment-label__number'}>
                {t('card.card_number')}<span className={'font-weight-bold'}>{`: ${hiddenNumber(label.last4)}`}</span>
            </div>
            <div className={'payment-label__date'}>
                <span className={''}>{t('card.expiry_date')}: {formatExpiryDate(label.exp_month, label.exp_year)}</span>
            </div>
            <div className={'payment-label__payment-system'}>
                {t('payment_system')}<span className={'text-capitalize'}>{`: ${label.brand}`}</span>
            </div>
        </div>
    );

    return (
        <SelectInput
            options={options}
            formatOptionLabel={formatOptionLabel}
            {...props}
        />
    );
};

export const PaymentCardSelectField = (props) => {
    return (
        <Field {...props} type="input" component={PaymentCardSelect} />
    );
};