import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    CardNumberElement,
} from '@stripe/react-stripe-js';

import i18n from '../../../i18n';

import { BILLING_STRIPE_PAY_NEW_CARD, BILLING_STRIPE_PAY_CARD, BILLING_STRIPE_PAY_CREDITS, BILLING_STRIPE_PAY_SUBSCRIPTION } from './actionTypes';
import {
    billingPaySuccess,
    billingPayError,
    billingStripePayCredits,
    billingStripePaySubscription,
} from './actions';

import { API } from '../../../api';

import { BILLING_TYPE_CREDITS, BILLING_TYPE_SUBSCRIPTION } from '../../../consts/billing/config';

function* stripePayNewCard({ payload: { stripe, elements, payData, history } }) {
    try {
        const { billingType, credits, tariffId } = payData;

        // get stripe form
        const cardNumberElement = elements.getElement(CardNumberElement);

        const response = yield call(API.billing.setupIntent);
        const { data } = response;

        const clientSecret = data.data.client_secret;

        const { setupIntent, error } = yield stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: cardNumberElement,
                    // billing_details: { },
                }
            }
        );

        if (error) {
            yield put(billingPayError({ errors: null, message: error.message, }));
        } else {
            // The card has been verified successfully...
            const payment_method = setupIntent.payment_method;

            if (billingType === BILLING_TYPE_CREDITS) {
                yield put(billingStripePayCredits(payment_method, credits, history));
            } else if (billingType === BILLING_TYPE_SUBSCRIPTION) {
                yield put(billingStripePaySubscription(payment_method, tariffId, history));
            }
        }

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(billingPayError({
            errors,
            message
        }));
    }
}

function* stripePayCard({ payload: { payment_method, payData, history } }) {
    const { billingType, credits, tariffId } = payData;

    if (billingType === BILLING_TYPE_CREDITS) {
        yield put(billingStripePayCredits(payment_method, credits, history));
    } else if (billingType === BILLING_TYPE_SUBSCRIPTION) {
        yield put(billingStripePaySubscription(payment_method, tariffId, history));
    }
}

function* stripePayCredits({ payload: { payment_method, credits, history } }) {
    try {
        const params = {
            payment_method,
            credits,
        };

        const response = yield call(API.billing.payCredits, params);
        const { data } = response;

        const { success, type, message, url } = data.data;

        if (success) {
            yield put(billingPaySuccess());

            history.push(`/billing?success=true&message=${i18n.t('billing.credits.pay.success')}`);
        } else if (url) {
            // redirect to 3d secure page
            window.location.href = url;
        } else if (message) {
            // error
            yield put(billingPayError({ errors: null, message }));
        }

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(billingPayError({
            errors,
            message
        }));
    }
}

function* stripePaySubscription({ payload: { payment_method, tariffId, history } }) {
    try {
        const params = {
            payment_method,
            tariff: tariffId,
        };

        const response = yield call(API.billing.paySubscription, params);
        const { data } = response;

        const { success, type, message, url } = data.data;

        if (success) {
            yield put(billingPaySuccess());

            history.push(`/billing?success=true&message=${i18n.t('billing.pay.subscription.success')}`);
        } else if (url) {
            // redirect to 3d secure page
            window.location.href = url;
        } else if (message) {
            // error
            yield put(billingPayError({ errors: null, message }));
        }

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(billingPayError({
            errors,
            message
        }));
    }
}


export function* watchStripePayNewCard() {
    yield takeEvery(BILLING_STRIPE_PAY_NEW_CARD, stripePayNewCard)
}

export function* watchStripePayCard() {
    yield takeEvery(BILLING_STRIPE_PAY_CARD, stripePayCard)
}

export function* watchStripePayCredits() {
    yield takeEvery(BILLING_STRIPE_PAY_CREDITS, stripePayCredits)
}

export function* watchStripePaySubscription() {
    yield takeEvery(BILLING_STRIPE_PAY_SUBSCRIPTION, stripePaySubscription)
}

function* paySaga() {
    yield all([
        fork(watchStripePayNewCard),
        fork(watchStripePayCard),
        fork(watchStripePayCredits),
        fork(watchStripePaySubscription),
    ]);
}

export default paySaga;