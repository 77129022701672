import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import toastr from 'toastr';
import i18n from '../../../../i18n';

import { EDIT_BILLING_INFO } from './actionTypes';
import {
    editBillingInfoSuccess,
    editBillingInfoError,
} from './actions';
import {
    billingInfoUpdateData,
} from '../view/actions';

import { FORM_BILLING_INFO_EDIT } from '../../../../consts/forms';

import { API } from '../../../../api';

function* editInfo({ payload: { params, callback } }) {
    try {
        const response = yield call(API.billing.editBillingInfo, params);

        const { data } = response;
        yield put(editBillingInfoSuccess(true));

        yield put(billingInfoUpdateData(data?.data));

        if (callback) {
            callback();
        }

        toastr.success(i18n.t('billing_info.success.saved'));
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(editBillingInfoError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_BILLING_INFO_EDIT, errors));
    }
}

export function* watchEditInfo() {
    yield takeEvery(EDIT_BILLING_INFO, editInfo)
}

function* editSaga() {
    yield all([
        fork(watchEditInfo),
    ]);
}

export default editSaga;