import React, { useMemo } from 'react';
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { leadPropertiesSuggestProperty } from "../../../store/actions";

const LeadPropertiesSuggestPropertyButtonComponent = (props) => {

    const onSend = () => {
        const lead = props.lead?.id;
        const property = props.propertyId;

        props.leadPropertiesSuggestProperty(lead, property);
    };

    const isDisabled = useMemo(() => {
        return props.pending.includes(props.propertyId);
    }, [props.propertyId, props.pending]);

    return (
        <>
            <div className="button-items">
                <Button
                    type="button"
                    color="success"
                    disabled={isDisabled}
                    onClick={onSend}
                >
                    {props.t('suggest')}
                </Button>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    const { pending } = state.lead.property.suggest;
    const { lead } = state.lead.view;
    return {
        pending,
        lead,
    };
};

const mapDispatchToProps = {
    leadPropertiesSuggestProperty,
};

export const LeadPropertiesSuggestPropertyButton = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadPropertiesSuggestPropertyButtonComponent));