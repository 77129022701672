import { all } from 'redux-saga/effects';

import viewSaga from './view/saga';
import editSaga from './edit/saga';

function* billingInfoSaga() {
    yield all([
        viewSaga(),
        editSaga(),
    ])
}

export default billingInfoSaga;