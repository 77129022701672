import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";

import { PropertyRequestInfoTable } from '../../../../components/Custom';
import { PROPERTY_REQUEST_TYPE_NEW_HOME } from '../../../../consts/propertyRequest';
import { ROLE_MANAGER } from '../../../../consts/roles';

const LeadInformationComponent = (props) => {
    const [visible, setVisible] = useState(true);

    const getTableLabels = () => {
        const type = props.lead.property_ad.type;

        let keys = ['date', 'type'];

        if (props.lead.property_ad.user) {
            keys = [...keys, 'client'];
        }

        if (props.user.role === ROLE_MANAGER) {
            keys = [...keys, 'lead_status'];
        }

        if (type === PROPERTY_REQUEST_TYPE_NEW_HOME) {
            keys = [...keys, 'price', 'property_types', 'regions', 'cities', 'min_size', 'bedrooms', 'bathrooms', 'parking', 'property_ages', 'min_size_terrace', 'min_size_garden', 'outside_facing', 'floor'];
        } else {
            keys = [...keys, 'goal', 'expectation', 'type_loan', 'buying_entity', 'first_time_investor'];
        }

        return keys;
    };

    const toggleVisible = (e) => {
        e.preventDefault();
        setVisible(!visible);
    };

    return (
        <Col>
            <Card>
                <CardBody>
                    <Link to={'#'} onClick={toggleVisible} className={'text-gray'}>
                        <Row className="no-gutters justify-content-between">
                            <CardTitle>{props.t('lead.information')}</CardTitle>

                            <div>
                                <i
                                    style={{ transform: `rotate(${visible ? '0deg' : '180deg'})`}}
                                    className="bx bx-chevron-up font-size-24 align-middle"
                                />
                            </div>
                        </Row>
                    </Link>

                    <div
                        className={classNames({
                            'd-none': !visible,
                            'mt-4': true,
                        })}
                    >
                        <PropertyRequestInfoTable
                            keys={getTableLabels()}
                            data={{
                                lead_status: props.lead.status,
                                client: props.lead.property_ad.user,
                                ...props.lead.property_ad,
                            }}
                        />
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

const mapStateToProps = state => {
    const { lead } = state.lead.view;
    return {
        lead,
        user: state.user.data,
    };
};

const mapDispatchToProps = {
};

export const LeadInformation = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadInformationComponent)));