import React, { useEffect, useMemo } from 'react';
import { Container, Row, Col, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useParams, useHistory } from "react-router-dom";

import { useTitle } from "../../hooks";
import { PageTitle } from '../../components/Custom';
import { fetchLead, cleanLead } from '../../store/actions';
import { LEAD_STATUS_UNPAID, LEAD_STATUS_PAID, LEAD_STATUS_DECLINED, LEAD_STATUS_CLOSED } from '../../consts/lead';
import { ROLE_MANAGER, ROLE_CONSULTANT } from '../../consts/roles';
import { LeadBuySection, AssignConsultant, LeadInformation, PropertyList, LeadPagination } from './components';

import * as HOC from '../../HOC';
const DataShower = HOC.withDataShower();

const LeadComponent = (props) => {
    useTitle(props.t('title.lead'));

    const history = useHistory();
    let { id } = useParams();

    useEffect(() => {
        const params = {}
        if (props.user.role === ROLE_MANAGER) {
            params.pagination = true;
        }
        props.fetchLead(id, params);

        return () => {
            props.cleanLead();
        };
    }, [id]);

    const canAssignConsultant = useMemo(() => {
        if (props.lead) {
            return [LEAD_STATUS_PAID, LEAD_STATUS_CLOSED].includes(props.lead.status) && props.user.role === ROLE_MANAGER;
        }
        return false;
    }, [props.lead, props.user]);

    const canBuy = useMemo(() => {
        if (props.lead) {
            return [LEAD_STATUS_UNPAID, LEAD_STATUS_DECLINED].includes(props.lead.status) && props.user.role === ROLE_MANAGER;
        }
        return false;
    }, [props.lead, props.user]);

    const handleLeadChange = (id) => {
        history.push(`/leads/${id}`);
    }

    return (
        <React.Fragment>
            <div className="page-content min-vh-100">
                <Container fluid>

                    <PageTitle title={props.t('title.lead')} />

                    <Row className={'mb-4'}>
                        <Col>
                            <Row className={'no-gutters justify-content-between'}>
                                <Button color="primary" outline className={'waves-effect waves-light'} onClick={() => history.push('/leads')} >
                                    <i className="fas fa-angle-left font-size-16 align-middle mr-2" />
                                    {props.t('back_to_list')}
                                </Button>

                                {props.user.role === ROLE_MANAGER && (
                                    <LeadPagination
                                        isDisabled={props.leadLoading || props.leadError}
                                        prev={props.lead?.prev}
                                        next={props.lead?.next}
                                        onChange={handleLeadChange}
                                    />
                                )}
                            </Row>
                        </Col>
                    </Row>

                    <DataShower
                        isLoading={props.leadLoading}
                        isFailed={props.leadError}
                        error={props.t(props.leadError)}
                        customLoaderClass={'mt-5'}
                    >
                        {props.lead && (
                            <>
                                {canBuy && (
                                    <LeadBuySection />
                                )}

                                <Row>
                                    <LeadInformation />
                                </Row>

                                {canAssignConsultant && (
                                    <AssignConsultant lead={props.lead} />
                                )}

                                {props.user.role === ROLE_CONSULTANT && (
                                    <PropertyList lead={props.lead} />
                                )}
                            </>
                        )}
                    </DataShower>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const {
        lead,
        leadLoading,
        leadError,
    } = state.lead.view;
    return {
        lead,
        leadLoading,
        leadError,
        user: state.user.data,
    };
};

const mapDispatchToProps = {
    fetchLead,
    cleanLead,
};

export const Lead = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadComponent)));