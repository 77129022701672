import { all } from 'redux-saga/effects';

import profileSaga from './profile/saga';

function* contactSaga() {
    yield all([
        profileSaga(),
    ])
}

export default contactSaga;