import {
    UPDATE_LEAD_PREFERENCES_DATA,
    UPDATE_LEAD_PREFERENCES_DATA_SUCCESS,
    UPDATE_LEAD_PREFERENCES_DATA_ERROR,
    CLEAN_UPDATE_LEAD_PREFERENCES_DATA,
} from './actionTypes';

export const updateLeadPreferences = (params) => {
    return {
        type: UPDATE_LEAD_PREFERENCES_DATA,
        payload: { params },
    }
};

export const updateLeadPreferencesSuccess = (data) => {
    return {
        type: UPDATE_LEAD_PREFERENCES_DATA_SUCCESS,
        payload: data,
    }
};

export const updateLeadPreferencesError = (data) => {
    return {
        type: UPDATE_LEAD_PREFERENCES_DATA_ERROR,
        payload: data,
    }
};

export const cleanUpdateLeadPreferences = () => {
    return { type: CLEAN_UPDATE_LEAD_PREFERENCES_DATA };
};