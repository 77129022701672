import React from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Table} from "reactstrap";
import moment from "moment";

import {subscriptionHelper, middlewareHelper} from 'helpers';
import {BillingOverviewSubscriptionStatus} from './BillingOverviewSubscriptionStatus';

const previewMode = middlewareHelper.previewMode.isAllow()

const BillingOverviewInfoComponent = (props) => {
  const {user} = props;
  const subscription = user?.subscription;

  return (
    <div className="table-responsive table-centered">
      <Table className="table mb-0 table-bordered">
        <tbody>

        {subscription && (
          <>
            {subscription.tariff?.name && (
              <tr>
                <th scope="row">{props.t('subscription_plan')} :</th>
                <td>{subscription.tariff?.name}</td>
              </tr>
            )}

            <tr>
              <th scope="row">{props.t('subscription_status')} :</th>
              <td>
                <BillingOverviewSubscriptionStatus status={subscription.status}/>
              </td>
            </tr>

            {subscription.tariff?.period && (
              <tr>
                <th scope="row">{props.t('billed_period')} :</th>
                <td>{subscriptionHelper.period.getConfig(subscription.tariff.period, props.t).label}</td>
              </tr>
            )}

            {subscription.current_period_ends_at && (
              <tr>
                <th scope="row">{props.t('subscription.current_period_ends_at')} :</th>
                <td>{moment(subscription.current_period_ends_at).format('DD/MM/YYYY')}</td>
              </tr>
            )}

            {subscription.next_payment_at && (
              <tr>
                <th scope="row">{props.t('subscription.next_payment_date')} :</th>
                <td>{moment(subscription.next_payment_at).format('DD/MM/YYYY')}</td>
              </tr>
            )}

            {subscription.ends_at && (
              <tr>
                <th scope="row">{props.t('subscription.ends_date')} :</th>
                <td>{moment(subscription.ends_at).format('DD/MM/YYYY')}</td>
              </tr>
            )}

            {subscription.trial_ends_at && (
              <tr>
                <th scope="row">{props.t('subscription.trial_ends_date')} :</th>
                <td>{moment(subscription.trial_ends_at).format('DD/MM/YYYY')}</td>
              </tr>
            )}

          </>
        )}

        {previewMode && (
          <tr>
            <th scope="row" style={{width: "400px"}}>{props.t('credits')} :</th>
            <td>{user.credits}</td>
          </tr>
        )}
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.data,
  };
};

const mapDispatchToProps = {};

export const BillingOverviewInfo = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingOverviewInfoComponent)));