import {
    SUBSCRIPTION_STATUS_ACTIVE,
    SUBSCRIPTION_STATUS_TRIALING,
    SUBSCRIPTION_STATUS_ON_GRACE_PERIOD,
} from '../../consts/billing/subscription';
import {
    ROLE_MANAGER,
    ROLE_CONSULTANT,
} from '../../consts/roles';

export const isAllow = (user) => {
    if (user) {
        const { role, subscription, has_subscription } = user;

        if (role === ROLE_MANAGER) {
            if (subscription) {
                const { status } = subscription;
                return (
                    status === SUBSCRIPTION_STATUS_ACTIVE ||
                    status === SUBSCRIPTION_STATUS_TRIALING ||
                    status === SUBSCRIPTION_STATUS_ON_GRACE_PERIOD
                );
            }
            return false;
        } else if (role === ROLE_CONSULTANT) {
            return has_subscription;
        } else {
            return true;
        }
    }
    return false;
};

export default {
    isAllow,
};