import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import toastr from 'toastr';
import i18n from '../../../i18n';

import { FETCH_PROPERTY_EDIT_INFO, EDIT_PROPERTY, REMOVE_PROPERTY } from './actionTypes';
import {
    fetchPropertyEditInfoSuccess,
    fetchPropertyEditInfoError,
    editPropertySuccess,
    editPropertyError,
    removePropertySuccess,
    removePropertyError,
} from './actions';

import { FORM_PROPERTY_COMMON } from '../../../consts/forms';

import { API } from '../../../api';

function* fetchPropertyEditInfo({ payload: { id } }) {
    try {
        const includes = ['project', 'city', 'city.region', 'images', 'myReceivedLink', 'myReceivedLink.sender']; // 'myReceivedLink.receiver', 'mySentLinks'

        const response = yield call(API.property.getProperty, id, { includes });

        const { data } = response;
        yield put(fetchPropertyEditInfoSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchPropertyEditInfoError(message));
    }
}

function* editProperty({ payload: { id, params, history } }) {
    try {
        const response = yield call(API.property.editProperty, id, params);

        const { data } = response;
        yield put(editPropertySuccess(true));

        history.push('/properties');
        toastr.success(i18n.t('property.saved'));
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(editPropertyError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_PROPERTY_COMMON, errors));
    }
}

function* removeProperty({ payload: { id, history } }) {
    try {
        const response = yield call(API.property.deleteProperty, id);

        const { data } = response;
        yield put(removePropertySuccess(true));
        history.push('/properties');

        toastr.success(i18n.t('property.delete.success'))

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(removePropertyError(message));
    }
}

export function* watchPropertyEditInfoFetch() {
    yield takeEvery(FETCH_PROPERTY_EDIT_INFO, fetchPropertyEditInfo)
}

export function* watchEditProperty() {
    yield takeEvery(EDIT_PROPERTY, editProperty)
}

export function* watchRemoveProperty() {
    yield takeEvery(REMOVE_PROPERTY, removeProperty)
}

function* editPageSaga() {
    yield all([
        fork(watchPropertyEditInfoFetch),
        fork(watchEditProperty),
        fork(watchRemoveProperty),
    ]);
}

export default editPageSaga;